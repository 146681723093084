import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalTableTitleText,
} from '../../../../../../components/Modals/styled';

import {
  validateNumber,
  validateString,
} from '../../../../../../utils/StringUtils';

import {
  AdmModalDetailHeader,
  AdmModalDetailInfoContent,
  AdmModalDetailBody,
  ItemContent,
  ItemLabel,
  ItemText,
  SwitchCheckStyled,
} from './styled';



interface IProps {
  show: boolean;
  data: any;
  onEdit?: any;
  onHide?: any;
}



const PStockDetailProductModal: React.FC<IProps> = (props) => {
  const product = props.data;



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Detalhes do Produto'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          <AdmModalDetailHeader>

            <AdmModalDetailInfoContent>
              <ModalTableTitleText>
                Nome: {validateString(product.PRODUTO) || '-'}
              </ModalTableTitleText>
            </AdmModalDetailInfoContent>

          </AdmModalDetailHeader>



          <AdmModalDetailBody>

            <ItemContent>
              <ItemLabel>
                CC:
              </ItemLabel>

              <ItemText>
                {validateString(product.CC) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Cód.:
              </ItemLabel>

              <ItemText>
                {validateString(product.COD) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                NCM:
              </ItemLabel>

              <ItemText>
                {validateString(product.NCM) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Tag:
              </ItemLabel>

              <ItemText>
                {validateString(product.TAG) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Tipo:
              </ItemLabel>

              <ItemText>
                {validateString(product.TIPO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Grupo:
              </ItemLabel>

              <ItemText>
                {validateString(product.GRUPO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Un. Medida:
              </ItemLabel>

              <ItemText>
                {validateString(product.UN) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Preço:
              </ItemLabel>

              <ItemText>
                {validateNumber(product.PRECO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Estoque:
              </ItemLabel>

              <ItemText>
                {validateString(String(product.ESTOQUE)) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Depósito:
              </ItemLabel>

              <ItemText>
                {validateString(product.DEPOSITO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Origem:
              </ItemLabel>

              <ItemText>
                {validateString(product.ORIGEM) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Data/Hora Cadastro:
              </ItemLabel>

              <ItemText>
                {`${product.DTCADASTRO} ${product.HORACADASTRO}`}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Tamanho:
              </ItemLabel>

              <ItemText>
                {validateString(product.Tamanho) ? product.Tamanho : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Un. Medida:
              </ItemLabel>

              <ItemText>
                {validateString(product.UnMedida) ? product.UnMedida : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Lote:
              </ItemLabel>

              <ItemText>
                {validateString(product.Lote) ? product.Lote : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Estoque:
              </ItemLabel>

              <ItemText>
                {validateString(product.Estoque) ? product.Estoque : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Estoque Mín.:
              </ItemLabel>

              <ItemText>
                {validateString(product.Minimo) ? product.Minimo : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Bloqueado:
              </ItemLabel>

              <ItemText>
                <SwitchCheckStyled
                  disabled
                  id={'switch-blocked'}
                  type={'switch'}
                  label={product.Bloqueado === '0' ? 'Bloqueado' : 'Desbloqueado'}
                  checked={product.Bloqueado === '0'}
                />
              </ItemText>
            </ItemContent>

          </AdmModalDetailBody>

        </ModalBodyStyled>



        <ModalFooterStyled>
          {/* <IButton
            backgroundColor={Colors?.secondary}
            text={'Editar'}
            onClick={props.onEdit}
          /> */}


          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default PStockDetailProductModal;
