import styled from 'styled-components';

import {
  Row,
} from 'react-bootstrap';



export const RowPedidosLegends = styled(Row)`
  max-width: 100%;
  align-items: center;
  text-align: left;
`;

export const StatusPedidosView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
