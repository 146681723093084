import styled from 'styled-components';


export const ContainerIFrameStyle = styled.div` 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  
  height: 100%;
  width: 100%;
`;

export const IFrameStyleNormalizeScreen = styled.iframe` 
  height: 70vh;
  width: 100%;
`;

export const IFrameStyleFullScreen = styled.iframe` 
  height: 95%;
  width: 100%;
  
  position: absolute;
  top: 5%;
  left: 0%;
`;

export const AreaButtonStyleNormalizeScreen = styled.div`   
  position: absolute;
  top: 195px;
  left: 78%;
`;

export const AreaButtonStyleFullScreen = styled.div`   
  position: absolute;
  top: 1.5%;
  left: 93%;
`;

export const CenteredStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  margin-top: 15%;
`;


