import React, { useEffect, useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import ApiWS from '../../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const TriaOriDesCRUDSMEModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listGroupDrop, setListGroupDrop] = useState([]);



  const postFetchGroup = () => {
    setLoading(true);

    const url = '/sm/manutencaotriagem/consulta/grupo/';
    const payload = {
      CODIGO: '',
      DESCRICAO: '',
    };

    ApiWS()
      .post(url, payload)
      .then((res) => {
        res.data.forEach((gp, index) => {
          setListGroupDrop((listGroupDrop) => [ ...listGroupDrop, {
            key: index,
            label: `${gp.CODIGO}${gp.DESCRICAO}`,
            value: gp.CODIGO,
          }]);
        });
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveAccess', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  function postSaveSrcDes(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupogrupo/';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Salvo com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível salvar!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveSrcDes', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postRemoveSrcDes(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupogrupo/';
    const payload = [{ ...values, DEL: '*' }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Removido com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não pode ser removido!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveSrcDes', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function showUserForDelete() {
    setListGroupDrop([
      {
        key: 0,
        label: props?.valuesRemove?.DESCRICAOORIGEM,
        value: props?.valuesRemove?.ORIGEM,
      },
      {
        key: 1,
        label: props?.valuesRemove?.DESCRICAODESTINO,
        value: props?.valuesRemove?.DESTINO,
      },
    ]);
  };


  useEffect(() => {
    if(props.type === 'NEW') {
      postFetchGroup();
    }

    if(props.type === 'REMOVE') {
      showUserForDelete();
    }
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ORIGEM: props?.valuesEdit?.ORIGEM || props?.valuesRemove?.ORIGEM || '',
            DESCRICAOORIGEM: props?.valuesEdit?.DESCRICAOORIGEM || props?.valuesRemove?.DESCRICAOORIGEM || '',
            DESTINO: props.valuesEdit?.DESTINO || props?.valuesRemove?.DESTINO || '',
            DESCRICAODESTINO: props.valuesEdit?.DESCRICAODESTINO || props?.valuesRemove?.DESCRICAODESTINO || '',

            DEL: '',
          }}
          validationSchema={
            Yup.object().shape({
              ORIGEM: Yup.string().required('Obrigatório'),
              DESTINO: Yup.string().required('Obrigatório'),

            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveSrcDes(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveSrcDes(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>


              <>
                <DropdownInput
                  disabled={loading || props.type === 'REMOVE'}
                  name={'ORIGEM'}
                  label={'Origem'}
                  placeholder={'Selecione.'}
                  dropArray={listGroupDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ORIGEM}
                />


                <DropdownInput
                  disabled={loading || props.type === 'REMOVE'}
                  name={'DESTINO'}
                  label={'Destino'}
                  placeholder={'Selecione.'}
                  dropArray={listGroupDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.DESTINO}
                />

              </>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default TriaOriDesCRUDSMEModal;
