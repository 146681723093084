import React, {
  useEffect, useState,
} from 'react';

import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContainerView, Content, ContentStyled, Screen,
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconApproval,
  IconCompany,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
  IconTrash,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';

import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import NameRoutes from '../../../../../navigation/name';

import {
  RP_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import { ordenaListaRecentePrimeiro } from '../../../../../utils/ListUtils';
import RPHistoricoAprovacaoModal from '../Modals/HistoricoAprovacoesRPModal';

import RPHistoricoModal from '../Modals/HistoricoRPModal';



const RP_HomeScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [carregando, setCarregando] = useState(true);
  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RP\'s aqui!',
    description: 'Não há nenhuma RP para você',
  });

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [removeRP, setRemoveRP] = useState(null);
  const [telaRemoveConfirmacao, setTelaRemoveConfirmacao] = useState(false);
  const [telaHistorico, setTelaHistorico] = useState(false);
  const [valueHistorico, setValueHistorico] = useState(null);


  const [valueHistoricoAprovacao, setValueHistoricoAprovacao] = useState({});
  const [telaHistoricoAprovacao, setTelaHistoricoAprovacao] = useState(false);



  const findPendentes = async () => {
    try {
      setCarregando(true);

      const url = '/rp/consulta/pendentes/solicitante/';
      const response = await ApiWS().get(url);

      setSolicitacoes(ordenaListaRecentePrimeiro(response.data));
    }
    catch (error) {
      console.error('findPendentes', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };

  const formataData = (date: String) => {
    let newDate = '';
    try {
      newDate = date.slice(8, 10) + '/';
      newDate += date.slice(5, 7) + '/';
      newDate += date.slice(0, 4);
    }
    catch (error) {
      console.error('Erro ao formatar Data: ', error);
    }
    return newDate;
  };

  const removeItem = async (numRP: any) => {
    try {
      const url = '/rp/consulta/pendentes/remover';
      const response = await ApiWS().post(url, { numRP: numRP });

      if (response.status === 200) {
        toast.success('RP Removida com sucesso', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        findPendentes();
      }
      else {
        toast.error('Erro ao tentar deletar RP', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }

      setTelaRemoveConfirmacao(false);
      setTimeout(() => {
        setRemoveRP(null);
      }, 300);
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
  };

  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/rp/impressao/${values.RP}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if(res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  useEffect(() => {
    findPendentes();
  }, []);

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={RP_NavRoutes}
          title={'RP'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}

          {!carregando && solicitacoes.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}

          {solicitacoes.length > 0 && (
            <ContainerView>

              <TitleGroup>
                {'RP\'s Pendentes'.toUpperCase()}
              </TitleGroup>

              <TableDefaultStyled>
                <thead>
                  <tr>
                    <th scope="col">CC</th> {/* 1 */}
                    <th scope="col">Nro. RP</th> {/* 2 */}
                    <th scope="col">Tipo</th> {/* 2 */}
                    <th scope="col">Solicitante</th> {/* 3 */}
                    <th scope="col">Função</th> {/* 4 */}
                    <th scope="col">Dt. de Emissão</th> {/* 5 */}
                    <th scope="col">Dt. Necessidade</th> {/* 6 */}
                    <th scope="col">Status</th> {/* 7 */}
                    <th scope="col" colSpan={5}>Ações</th> {/* 8 - 11 */}
                  </tr>
                </thead>


                <tbody>
                  {solicitacoes.map((rp) => (
                    <tr>
                      <td>{rp.OS.replaceAll(' ', '')}</td>{/* 1 */}
                      <td>{rp.RP}</td>{/* 2 */}
                      <td>{rp.TIPO}</td>{/* 2 */}
                      <td>{rp.Solicitante}</td>{/* 3 */}
                      <td>{rp.FUNCAO}</td>{/* 4 */}
                      <td>{formataData(rp.DataEmissao)}</td>{/* 5 */}
                      <td>{formataData(rp.DataNecess)}</td>{/* 6 */}
                      <td>{rp.Status}</td>{/* 7 */}

                      {/* Observações 8 */}
                      <td>
                        <ButtonTableAction
                          tooltip={
                            rp.QS_OBSERVA
                              && rp.QS_OBSERVA !== null
                              && rp.QS_OBSERVA !== ''
                              && rp.QS_OBSERVA !== undefined
                              ? rp.QS_OBSERVA
                              : TextTooltip.NO_OBSERVATION
                          }
                          icon={
                            <IconInfoSquare
                              color={
                                rp.QS_OBSERVA
                                  && rp.QS_OBSERVA !== null
                                  && rp.QS_OBSERVA !== ''
                                  && rp.QS_OBSERVA !== undefined
                                  ? Colors?.black
                                  : Colors?.gray
                              }
                              size={16}
                            />
                          }
                        />
                      </td>

                      {/* Impressão  9 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={TextTooltip.PRINTER}
                            onClick={() => {
                              postPrinter(rp);
                            }}
                            icon={
                              <IconPrinter
                                color={Colors?.black}
                                size={16}
                              />
                            }
                          />
                        }
                      </td>

                      {/* Remover  10 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={
                              rp.CanEdit === 'True'
                                ? TextTooltip.REMOVE
                                : TextTooltip.ACESSLESS
                            }
                            onClick={() => {
                              if (rp.CanEdit === 'True') {
                                setRemoveRP({ RP: rp.RP });
                                setTelaRemoveConfirmacao(true);
                              }
                            }}
                            icon={
                              <IconTrash
                                color={
                                  rp.CanEdit === 'True'
                                    ? Colors?.black
                                    : Colors?.gray
                                }
                                size={16}
                              />
                            }
                          />
                        }
                      </td>

                      {/* Historico  11 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={TextTooltip.HISTORIC}
                            onClick={() => {
                              setValueHistorico({ QS_VAGA: rp.RP });
                              setTelaHistorico(true);
                            }}
                            icon={
                              <IconHistory
                                color={Colors?.black}
                                size={16}
                              />
                            }
                          />
                        }
                      </td>


                      {/* Historico Aprovacao  12 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={TextTooltip.APPROVALS}
                            onClick={() => {
                              setValueHistoricoAprovacao({ CC: rp.OS, NUM: rp.RP });
                              setTelaHistoricoAprovacao(true);
                            }}
                            icon={
                              <IconApproval
                                color={Colors?.black}
                                size={16}
                              />
                            }
                          />
                        }
                      </td>


                    </tr>
                  ))}
                </tbody>
              </TableDefaultStyled>

            </ContainerView>
          )}

          {/* {solicitacoes.length === 0 && (
            <EmptyContent
              title={'Sem RP\'s aqui!'}
            />
          )} */}


        </ContentStyled>
      </Content>

      {removeRP && (
        <ConfirmationModal
          show={telaRemoveConfirmacao}
          head={`Deletar RP: ${removeRP.RP}`}
          orient={'Tem certeza que deseja deletar a RP ' + removeRP.RP + '?'}
          positive={() => {
            removeItem(removeRP.RP);
          }}
          negative={() => {
            setTelaRemoveConfirmacao(false);
            setTimeout(() => {
              setRemoveRP(null);
            }, 300);
          }}
        />
      )}


      {valueHistorico && (
        <RPHistoricoModal
          show={telaHistorico}
          todo={valueHistorico}
          onHide={() => {
            setTelaHistorico(false);
            setTimeout(() => {
              setValueHistorico(null);
            }, 300);
          }}
        />
      )}


      {valueHistoricoAprovacao && telaHistoricoAprovacao && (
        <RPHistoricoAprovacaoModal
          show={telaHistoricoAprovacao}
          value={valueHistoricoAprovacao}
          onHide={() => {
            setTelaHistoricoAprovacao(false);
            setTimeout(() => {
              setValueHistoricoAprovacao(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default RP_HomeScreen;
