import styled from 'styled-components';



export interface IProps {
  backgroundColor?: string;
}



export const RowButtonColetorView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
`;


export const RowStatusView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const RowStatusBadge = styled.div`
  display: flex;
  flex-direction: column;
  width: 15px;
  height: 15px;
  margin-bottom: 2px;
  margin-right: 6px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 50%;
`;

export const RowSyncStatusText = styled.p`
  display: flex;
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

export const RowStatusText = styled.p`
  display: flex;
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
`;
