import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
} from '../../common/constants';

import {
  Screen,
} from '../../common/styles/styled.layout';

import IButton from '../../components/Buttons/IButton';

import {
  IconHome,
} from '../../components/Icons';

import {
  ErrorScreenContainer,
  ErrorContent,
  ErrorTitle,
  ErrorDescriptionText,
  GoHomeButtonContent,
} from './styled';



const NotFoundScreen: React.FC = () => {
  const history = useHistory();



  return (

    <Screen
      backgroundColor={Colors?.primary}>

      <ErrorScreenContainer>
        <ErrorContent>

          <ErrorTitle>
            {'404'.toUpperCase()}
          </ErrorTitle>

          <ErrorDescriptionText>
            {'Nada Encontrado Aqui'.toUpperCase()}
          </ErrorDescriptionText>

          <GoHomeButtonContent>
            <IButton
              backgroundColor={Colors?.primary}
              text={'Voltar ao ínicio'}
              iconLeft={
                <IconHome
                  color={'#FFFFFF'}
                  size={24}
                />
              }
              onClick={() => {
                history.replace('/');
              }}
            />
          </GoHomeButtonContent>

        </ErrorContent>
      </ErrorScreenContainer>

    </Screen>

  );
};



export default NotFoundScreen;
