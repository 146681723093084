import React from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  YesNoArray,
} from '../../../../../../common/arrays';

import {
  SubTitleGroup,
  TextBold,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import {
  SoldadorFieldHelp,
  SoldadorFieldTextHelp,
} from '../RP-inclusao.styled';



const RPSecaoSoldagem = ({ values, handleChange, handleBlur, setFieldValue }) => {
  return (

    <div>
      <TitleGroup>
        {'Soldagem'.toUpperCase()}
      </TitleGroup>

      <Row>
        <Col>
          <DropdownInput
            name={'soldador'}
            label={'Soldador'}
            dropArray={YesNoArray}
            placeholder={'Selecione ...'}
            onChange={(e: any) => {
              handleChange(e);
              setFieldValue('soldador', e.target.value);
              if(e.target.value === ''){
                setFieldValue('aco_carbono', '');
                setFieldValue('aco_inox', '');
                setFieldValue('chapa', '');
                setFieldValue('ligas_niquel', '');
                setFieldValue('titanio', '');
                setFieldValue('aluminio', '');
                setFieldValue('eletrodo_revestido', '');
                setFieldValue('a_tubular', '');
                setFieldValue('tig', '');
                setFieldValue('mig', '');
                setFieldValue('mag', '');
                setFieldValue('processos_outros', '');
                setFieldValue('materiais_outros', '');
              }
            }}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <SoldadorFieldHelp>
            <SoldadorFieldTextHelp>
              Ao Selecionar <TextBold>Sim</TextBold>, você poderá adicionar os tipos de processos e os materiais utilizados.
            </SoldadorFieldTextHelp>
          </SoldadorFieldHelp>
        </Col>


        <Col sm={1} />

      </Row>



      {values.soldador === '1' && (

        <Row>
          <Col>
            <SubTitleGroup>
              {'Materiais'.toUpperCase()}
            </SubTitleGroup>


            <Row>
              <Col>
                <DropdownInput
                  name={'aco_carbono'}
                  label={'Aço Carbono'}
                  dropArray={YesNoArray}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('aco_carbono', e.target.value);
                  }}
                />
              </Col>


              <Col>
                <DropdownInput
                  name={'aco_inox'}
                  label={'Aço Inox'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                />
              </Col>
            </Row>



            <Row>
              <Col>
                <DropdownInput
                  name={'chapa'}
                  label={'Chapa'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                />
              </Col>


              <Col>
                <DropdownInput
                  name={'ligas_niquel'}
                  label={'Ligas de Niquel'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                />
              </Col>
            </Row>



            <Row>
              <Col>
                <DropdownInput
                  name={'titanio'}
                  label={'Titânio'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                // onSelect={osDropdownFunction}
                />
              </Col>


              <Col>
                <DropdownInput
                  name={'aluminio'}
                  label={'Aluminio'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                // onSelect={osDropdownFunction}
                />
              </Col>
            </Row>



            <Row>
              <Col>
                <TextInput
                  type={'text'}
                  name={'materiais_outros'}
                  label={'Outros Materiais'}
                  placeholder={'Outros Materiais'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
          </Col>



          <Col>
            <SubTitleGroup>
              {'Processos'.toUpperCase()}
            </SubTitleGroup>


            <Row>
              <Col>
                <DropdownInput
                  name={'eletrodo_revestido'}
                  label={'Eletrodo Revistido'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                // onSelect={osDropdownFunction}
                />
              </Col>


              <Col>
                <DropdownInput
                  name={'a_tubular'}
                  label={'A. Tubular'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                // onSelect={osDropdownFunction}
                />
              </Col>
            </Row>



            <Row>
              <Col>
                <DropdownInput
                  name={'tig'}
                  label={'TIG'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                // onSelect={osDropdownFunction}
                />
              </Col>



              <Col>
                <DropdownInput
                  name={'mig'}
                  label={'MIG'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                  // onSelect={osDropdownFunction}
                />
              </Col>


              <Col>
                <DropdownInput
                  name={'mag'}
                  label={'MAG'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                  // onSelect={osDropdownFunction}
                />
              </Col>
            </Row>



            <Row>
              <Col>
                <TextInput
                  type={'text'}
                  name={'processos_outros'}
                  label={'Outros Processos'}
                  placeholder={'Outros Processos de Soldagem'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
          </Col>

        </Row>

      )}
    </div>

  );
};



export default RPSecaoSoldagem;
