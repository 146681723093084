import NameRoutes from '../name';



export const RP_NavRoutes = [
  {
    key: 1,
    label: 'Início',
    path: `/${NameRoutes.RP_Home}`,
  },
  {
    key: 2,
    label: 'Inclusão',
    path: `/${NameRoutes.RP_Inclusao}`,
  },
  {
    key: 3,
    label: 'Aprovação',
    path: `/${NameRoutes.RP_Aprovacao}`,
  },
  {
    key: 4,
    label: 'Atendimento',
    path: `/${NameRoutes.RP_Atendimento}`,
  },
  /*
  {
    key: 5,
    label: 'Triagem',
    path: `/${NameRoutes.RP_Triagem}`,
  },
  */
];

export const PAV_NavRoutes = [
  {
    key: 1,
    label: 'Destaques',
    path: `/${NameRoutes.PAV_Destaques}`,
  },
  {
    key: 2,
    label: 'Reconhecimento',
    path: `/${NameRoutes.PAV_Reconhecimento}`,
  },
  {
    key: 3,
    label: 'Configurações',
    path: `/${NameRoutes.PAV_Configuracoes}`,
  },
  {
    key: 4,
    label: 'Perfil',
    path: `/${NameRoutes.USER_Home}/pav`,
  },
];
