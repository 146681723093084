import {
  configureStore,
} from '@reduxjs/toolkit';

import {
  combineReducers,
} from 'redux';

import {
  persistReducer,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import userReducer from './reducers/user.store';



const reducers = combineReducers({
  user: userReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['navigation'], // navigation will not be persisted
  // whitelist: ['navigation'], // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});



export type RootState = ReturnType<typeof store.getState>;

export default store;
