import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import ButtonAttach from '../../../../../../components/Buttons/ButtonAttach';
import IButton from '../../../../../../components/Buttons/IButton';

import {
  IconArchiveImport,
} from '../../../../../../components/Icons';

import ConfirmationModal from '../../../../../../components/Modals/Confirmation';
import LoadingWaitModal from '../../../../../../components/Modals/LoadingWait';

import ApiWS from '../../../../../../services/api.service';

import {
  CardImportContainer,
  CardImportHeader,
  CardImportTitle,
  CardImportBody,
  CardImportFormCheck,
  ButtonSyncView,
} from './styled';



const Tab_ConfiguracoesScreen: React.FC = () => {
  const [fileProduct, setFileProduct] = useState(null);
  const [fileEmployee, setFileEmployee] = useState(null);
  const [fileSupplier, setFileSupplier] = useState(null);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingConfigList, setLoadingConfigList] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);



  function renderToastType(type: string, message: any) {
    switch (type) {
      case 'SUCCESS':
        toast.success(message, {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        break;

      case 'ERROR':
        toast.error(message, {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        break;

      default:
        break;
    }
  }


  function returnUrlByType(type: string) {
    switch (type) {
      case 'PRODUTO':
        return '/pstock/adm/configuracao/sincronizar/produtos';

      case 'FUNCIONARIO':
        return '/pstock/adm/configuracao/sincronizar/funcionarios';

      case 'FORNECEDOR':
        return '/pstock/adm/configuracao/sincronizar/fornecedores';

      case 'CA':
        return '/pstock/adm/configuracao/sincronizar/ca';

      case 'UNIDADE':
        return '/pstock/adm/configuracao/sincronizar/unidades';

      default:
        return '';
    }
  }


  function returnSuccessMessageByType(type: string) {
    switch (type) {
      case 'PRODUTO':
        return 'Atualização de produtos concluída';

      case 'FUNCIONARIO':
        return 'Atualização de funcionários concluída';

      case 'FORNECEDOR':
        return 'Atualização de fornecedores concluída';

      case 'CA':
        return 'Atualização de CA concluída';

      case 'UNIDADE':
        return 'Atualização de unidades concluída';

      default:
        return '';
    }
  }


  function returnErrorMessageByType(type: string) {
    switch (type) {
      case 'PRODUTO':
        return 'Erro ao atualizar produtos! Contate o suporte.';

      case 'FUNCIONARIO':
        return 'Erro ao atualizar funcionários! Contate o suporte.';

      case 'FORNECEDOR':
        return 'Erro ao atualizar fornecedores! Contate o suporte.';

      case 'CA':
        return 'Erro ao atualizar CA! Contate o suporte.';

      case 'UNIDADE':
        return 'Erro ao atualizar unidades! Contate o suporte.';

      default:
        return '';
    }
  }


  async function postItemSincronizar(typeUrl: string) {
    const payload = {
      'CC': 'MKS0116',
    };

    const url = returnUrlByType(typeUrl);
    const successMessages = returnSuccessMessageByType(typeUrl);
    const errorMessages = returnErrorMessageByType(typeUrl);

    try {
      setLoadingConfigList(true);

      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal(successMessages);
      }
      else {
        renderToastType('ERROR', errorMessages);
      }
    }
    catch (error) {
      console.error('postSincronizar', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal(errorMessages);
    }
    finally {
      setLoadingConfigList(false);
    }
  };



  const onFileProducts = (event: any) => {
    setFileProduct(event.target.files[0]);
  };


  const onFileEmployee = (event: any) => {
    setFileEmployee(event.target.files[0]);
  };


  const onFileSupplier = (event: any) => {
    setFileSupplier(event.target.files[0]);
  };



  // Produtos
  function importProducts(values: any) {
    console.log('valuesProduct', values);
    console.log('fileProduct', fileProduct);

    if (fileProduct) {
      if (fileProduct.name === 'PSTOCK_Layout_Produtos.xlsx') {
        const formData = new FormData();

        formData.append('file', fileProduct);

        setLoadingConfigList(true);
        ApiWS().post('/pstock/adm/configuracao/importar/produtos', formData).then((res) => {
          renderToastType('SUCCESS', res.data);
        }).catch((error) => {
          console.error('ErrorProdutos', JSON.stringify(error.response, null, 2));
        }).finally(() => {
          setLoadingConfigList(false);
        });
      }
      else {
        renderToastType('ERROR', 'Arquivo Invalido');
      }
    }
    else {
      renderToastType('ERROR', 'Selecione um arquivo');
    }
  };



  // Funcionarios
  function importEmployee(values: any) {
    console.log('valuesEmployee', values);
    console.log('fileEmployee', fileEmployee);

    if (fileEmployee) {
      if (fileEmployee.name === 'PSTOCK_Layout_Funcionarios.xlsx') {
        const formData = new FormData();

        formData.append('file', fileEmployee);

        setLoadingConfigList(true);
        ApiWS().post('/pstock/adm/configuracao/importar/funcionarios', formData).then((res) => {
          renderToastType('SUCCESS', res.data);
        }).catch((error) => {
          console.error('ErrorFuncionarios', JSON.stringify(error.response, null, 2));
        }).finally(() => {
          setLoadingConfigList(false);
        });
      }
      else {
        renderToastType('ERROR', 'Arquivo Invalido');
      }
    }
    else {
      renderToastType('ERROR', 'Selecione um arquivo');
    }
  };



  // Fornecedores
  async function importSupplier(values: any) {
    console.log('valuesSupplier', values);
    console.log('fileSupplier', fileSupplier);

    if (fileSupplier) {
      if (fileSupplier.name === 'PSTOCK_Layout_Fornecedores.xlsx') {
        try {
          setLoadingConfigList(true);

          const formData = new FormData();

          formData.append('file', fileSupplier);

          const url = '/pstock/adm/configuracao/importar/fornecedores';
          const response = await ApiWS().post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 200) {
            console.log({ response });

            renderToastType('SUCCESS', response.data);
          }
        }
        catch (error) {
          console.error('ErrorFornecedores', JSON.stringify(error.response, null, 2));
        }
        finally {
          setLoadingConfigList(false);
        }
      }
      else {
        renderToastType('ERROR', 'Arquivo Invalido');
      }
    }
    else {
      renderToastType('ERROR', 'Selecione um arquivo');
    }
  };



  return (

    <ContainerView>

      <SectionMargin>
        <TitleGroup>
          {'Sincronizações'.toUpperCase()}
        </TitleGroup>


        <SectionMargin>

          <Row>

            <Col>
              <CardImportContainer heightAuto>

                <CardImportHeader>
                  <CardImportTitle>
                    Produtos
                  </CardImportTitle>
                </CardImportHeader>


                <CardImportBody>
                  <ButtonSyncView>
                    <IButton
                      type={'button'}
                      disabled={loadingConfigList}
                      backgroundColor={Colors?.secondary}
                      text={'Sincronizar todos os produtos'}
                      onClick={() => {
                        postItemSincronizar('PRODUTO');
                      }}
                      iconLeft={
                        <IconArchiveImport
                          color={'#FFFFFF'}
                          size={20}
                        />
                      }
                    />
                  </ButtonSyncView>
                </CardImportBody>

              </CardImportContainer>
            </Col>



            <Col>
              <CardImportContainer heightAuto>

                <CardImportHeader>
                  <CardImportTitle>
                    Funcionários
                  </CardImportTitle>
                </CardImportHeader>


                <CardImportBody>
                  <ButtonSyncView>
                    <IButton
                      type={'button'}
                      disabled={loadingConfigList}
                      backgroundColor={Colors?.secondary}
                      text={'Sincronizar todos os funcionários'}
                      onClick={() => {
                        postItemSincronizar('FUNCIONARIO');
                      }}
                      iconLeft={
                        <IconArchiveImport
                          color={'#FFFFFF'}
                          size={20}
                        />
                      }
                    />
                  </ButtonSyncView>
                </CardImportBody>

              </CardImportContainer>
            </Col>



            <Col>
              <CardImportContainer heightAuto>

                <CardImportHeader>
                  <CardImportTitle>
                    Fornecedores
                  </CardImportTitle>
                </CardImportHeader>


                <CardImportBody>
                  <ButtonSyncView>
                    <IButton
                      type={'button'}
                      disabled={loadingConfigList}
                      backgroundColor={Colors?.secondary}
                      text={'Sincronizar todos os fornecedores'}
                      onClick={() => {
                        postItemSincronizar('FORNECEDOR');
                      }}
                      iconLeft={
                        <IconArchiveImport
                          color={'#FFFFFF'}
                          size={20}
                        />
                      }
                    />
                  </ButtonSyncView>
                </CardImportBody>

              </CardImportContainer>
            </Col>



            <Col>
              <CardImportContainer heightAuto>

                <CardImportHeader>
                  <CardImportTitle>
                    CA
                  </CardImportTitle>
                </CardImportHeader>


                <CardImportBody>
                  <ButtonSyncView>
                    <IButton
                      type={'button'}
                      disabled={loadingConfigList}
                      backgroundColor={Colors?.secondary}
                      text={'Sincronizar todos os CA'}
                      onClick={() => {
                        postItemSincronizar('CA');
                      }}
                      iconLeft={
                        <IconArchiveImport
                          color={'#FFFFFF'}
                          size={20}
                        />
                      }
                    />
                  </ButtonSyncView>
                </CardImportBody>

              </CardImportContainer>
            </Col>



            <Col>
              <CardImportContainer heightAuto>

                <CardImportHeader>
                  <CardImportTitle>
                    Unidades
                  </CardImportTitle>
                </CardImportHeader>


                <CardImportBody>
                  <ButtonSyncView>
                    <IButton
                      type={'button'}
                      disabled={loadingConfigList}
                      backgroundColor={Colors?.secondary}
                      text={'Sincronizar todas as unidades'}
                      onClick={() => {
                        postItemSincronizar('UNIDADE');
                      }}
                      iconLeft={
                        <IconArchiveImport
                          color={'#FFFFFF'}
                          size={20}
                        />
                      }
                    />
                  </ButtonSyncView>
                </CardImportBody>

              </CardImportContainer>
            </Col>

          </Row>

        </SectionMargin>

      </SectionMargin>



      <SectionMargin>
        <TitleGroup>
          {'Importações'.toUpperCase()}
        </TitleGroup>


        <SectionMargin>

          <Row>

            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={{}}
                // validationSchema={LoginValidationSchema}
                onSubmit={(values) => {
                  importProducts(values);
                }}>
                {({
                  handleSubmit,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Produtos
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>

                      <CardImportFormCheck />


                      <RowButtonFiltersView>
                        <ButtonAttach
                          idButton={'pstock-import-product'}
                          disabled={loadingConfigList}
                          fileGroup={fileProduct}
                          onChangeButton={onFileProducts}
                        />


                        <IButton
                          type={'submit'}
                          disabled={loadingConfigList}
                          backgroundColor={Colors?.excel}
                          text={'Importar'}
                          iconLeft={
                            <IconArchiveImport
                              color={'#FFFFFF'}
                              size={20}
                            />
                          }
                        />

                      </RowButtonFiltersView>

                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>


            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={{}}
                // validationSchema={LoginValidationSchema}
                onSubmit={(values) => {
                  importEmployee(values);
                }}>
                {({
                  handleSubmit,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Funcionários
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>

                      <CardImportFormCheck />


                      <RowButtonFiltersView>
                        <ButtonAttach
                          idButton={'pstock-import-employee'}
                          disabled={loadingConfigList}
                          fileGroup={fileEmployee}
                          onChangeButton={onFileEmployee}
                        />


                        <IButton
                          type={'submit'}
                          disabled={loadingConfigList}
                          backgroundColor={Colors?.excel}
                          text={'Importar'}
                          iconLeft={
                            <IconArchiveImport
                              color={'#FFFFFF'}
                              size={20}
                            />
                          }
                        />

                      </RowButtonFiltersView>

                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>


            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={{}}
                // validationSchema={LoginValidationSchema}
                onSubmit={(values) => {
                  importSupplier(values);
                }}>
                {({
                  // handleChange,
                  // handleBlur,
                  handleSubmit,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Fornecedores
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>

                      <CardImportFormCheck />


                      <RowButtonFiltersView>
                        <ButtonAttach
                          idButton={'pstock-import-supplier'}
                          disabled={loadingConfigList}
                          fileGroup={fileSupplier}
                          onChangeButton={onFileSupplier}
                        />


                        <IButton
                          type={'submit'}
                          disabled={loadingConfigList}
                          backgroundColor={Colors?.excel}
                          text={'Importar'}
                          iconLeft={
                            <IconArchiveImport
                              color={'#FFFFFF'}
                              size={20}
                            />
                          }
                        />

                      </RowButtonFiltersView>

                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>

          </Row>

        </SectionMargin>

      </SectionMargin>



      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'PStock - Sincronização!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}



      <LoadingWaitModal
        show={loadingConfigList}
      />

    </ContainerView>

  );
};



export default Tab_ConfiguracoesScreen;
