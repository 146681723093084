/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';

import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToFloat, maskToFloat2, maskToMoney, maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { returnCalcMarginsAndTaxOfLS_Budgeted, returnCalcMarginsAndTaxOfLS_Planned, returnCalcMarginsAndTaxOfLS_Provider } from '../../PFF-Formula';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, ColFieldsGridTitle, TitleInterGroup, TitleSubSetAccProvider } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffCustosModel, PffCustosModelCAP, PffCustosModelCCE, PffCustosModelCDG, PffCustosModelCEQ, PffCustosModelCMA, PffCustosModelCMC, PffCustosModelCMD, PffCustosModelCMI, PffCustosModelCMM, PffCustosModelCMV, PffCustosModelCRC, PffCustosModelCSB } from '../PFF-grid.model';



interface IProps {
  indexTab?: number;
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_CustosScreen: React.FC<IProps> = (props) => {
  const [total_state, setTotal_state] = useState({ JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 });

  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[7].CUS;
    let total = 0;
    let total_CMD = 0;
    let total_CMI = 0;
    let total_CAP = 0;
    let total_CMM = 0;
    let total_CCE = 0;
    let total_CEQ = 0;
    let total_CMA = 0;
    let total_CMC = 0;
    let total_CMV = 0;
    let total_CSB = 0;
    let total_CDG = 0;
    let total_CRC = 0;


    // ORIGINAL
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      model = {
        ...model,
        CMD01_SALARIOS: { ORCADO: maskToMoney(`${data[0].ORCADO}`) },
        CMD02_ENCARGOS: { ORCADO: maskToMoney(`${data[1].ORCADO}`) },
        CMD03_HORAS_EXTRAS_ADICIONAL: { ORCADO: maskToMoney(`${data[2].ORCADO}`) },
        CMI01_SALARIOS: { ORCADO: maskToMoney(`${data[3].ORCADO}`) },
        CMI02_ENCARGOS: { ORCADO: maskToMoney(`${data[4].ORCADO}`) },
        CMI03_HORAS_EXTRAS_ADICIONAL: { ORCADO: maskToMoney(`${data[5].ORCADO}`) },
        CAP01_TRANSPORTE: { ORCADO: maskToMoney(`${data[6].ORCADO}`) },
        CAP02_ALIMENTACAO: { ORCADO: maskToMoney(`${data[7].ORCADO}`) },
        CAP03_EPI_E_UNIFORMES: { ORCADO: maskToMoney(`${data[8].ORCADO}`) },
        CAP04_ALOJAMENTO: { ORCADO: maskToMoney(`${data[9].ORCADO}`) },
        CAP05_CURSOS_E_TREINAMENTO: { ORCADO: maskToMoney(`${data[10].ORCADO}`) },
        CAP06_ASSISTENCIA_MEDICA: { ORCADO: maskToMoney(`${data[11].ORCADO}`) },
        CAP07_OUTROS: { ORCADO: maskToMoney(`${data[12].ORCADO}`) },
        CMM01_DESPESAS_COM_VIAGENS: { ORCADO: maskToMoney(`${data[13].ORCADO}`) },
        CMM02_FRETES_TRANSPORTES: { ORCADO: maskToMoney(`${data[14].ORCADO}`) },
        CMM03_OUTROS: { ORCADO: maskToMoney(`${data[15].ORCADO}`) },
        CCE01_INSTALACAO_CONSTRUCAO: { ORCADO: maskToMoney(`${data[16].ORCADO}`) },
        CCE02_MANUTENCAO: { ORCADO: maskToMoney(`${data[17].ORCADO}`) },
        CCE03_ALUGUEIS: { ORCADO: maskToMoney(`${data[18].ORCADO}`) },
        CCE04_COMUNICACAO: { ORCADO: maskToMoney(`${data[19].ORCADO}`) },
        CCE05_INFORMATICA_TI: { ORCADO: maskToMoney(`${data[20].ORCADO}`) },
        CCE06_MATERIAIS_DE_ESCRITORIO: { ORCADO: maskToMoney(`${data[21].ORCADO}`) },
        CCE07_OUTROS: { ORCADO: maskToMoney(`${data[22].ORCADO}`) },
        CEQ01_AQUISICAO: { ORCADO: maskToMoney(`${data[23].ORCADO}`) },
        CEQ02_LOCACAO: { ORCADO: maskToMoney(`${data[24].ORCADO}`) },
        CEQ03_MANUTENCAO: { ORCADO: maskToMoney(`${data[25].ORCADO}`) },
        CEQ04_OUTROS: { ORCADO: maskToMoney(`${data[26].ORCADO}`) },
        CMA01_DIVERSOS: { ORCADO: maskToMoney(`${data[27].ORCADO}`) },
        CMA02_CIVIL: { ORCADO: maskToMoney(`${data[28].ORCADO}`) },
        CMA03_TUBULACAO: { ORCADO: maskToMoney(`${data[29].ORCADO}`) },
        CMA04_CALDEIRARIA_ESTRUTURA: { ORCADO: maskToMoney(`${data[30].ORCADO}`) },
        CMA05_ELETRICA_INSTRUMENTACAO: { ORCADO: maskToMoney(`${data[31].ORCADO}`) },
        CMA06_ISOLAMENTO: { ORCADO: maskToMoney(`${data[32].ORCADO}`) },
        CMA07_PINTURA: { ORCADO: maskToMoney(`${data[33].ORCADO}`) },
        CMC01_MATERIAL_DE_CONSUMO: { ORCADO: maskToMoney(`${data[34].ORCADO}`) },
        CMC02_GASES: { ORCADO: maskToMoney(`${data[35].ORCADO}`) },
        CMC03_ELETRODOS_E_VARETAS: { ORCADO: maskToMoney(`${data[36].ORCADO}`) },
        CMV01_MERC_VEND: { ORCADO: maskToMoney(`${data[37].ORCADO}`) },
        CSB01_DIVERSOS: { ORCADO: maskToMoney(`${data[38].ORCADO}`) },
        CSB02_CIVIL: { ORCADO: maskToMoney(`${data[39].ORCADO}`) },
        CSB03_TUBULACAO: { ORCADO: maskToMoney(`${data[40].ORCADO}`) },
        CSB04_CALDEIRARIA_ESTRUTURA: { ORCADO: maskToMoney(`${data[41].ORCADO}`) },
        CSB05_ELETRICA_INSTRUMENTACAO: { ORCADO: maskToMoney(`${data[42].ORCADO}`) },
        CSB06_ISOLAMENTO: { ORCADO: maskToMoney(`${data[43].ORCADO}`) },
        CSB07_PINTURA: { ORCADO: maskToMoney(`${data[44].ORCADO}`) },
        CDG01_TAXAS_DIVERSAS: { ORCADO: maskToMoney(`${data[45].ORCADO}`) },
        CDG02_PREMIACOES_PATROCINIO: { ORCADO: maskToMoney(`${data[46].ORCADO}`) },
        CDG03_SEGUROS_MULTAS_JUROS: { ORCADO: maskToMoney(`${data[47].ORCADO}`) },
        CDG04_OUTROS: { ORCADO: maskToMoney(`${data[48].ORCADO}`) },
        CRC01_CUSTOS_DE_MOD: { ORCADO: maskToMoney(`${data[49].ORCADO}`) },
        CRC02_CUSTOS_DE_MOI: { ORCADO: maskToMoney(`${data[50].ORCADO}`) },
        CRC03_CUSTOS_DE_APOIO: { ORCADO: maskToMoney(`${data[51].ORCADO}`) },
        CRC04_CUSTOS_DE_MOBILIZACAO: { ORCADO: maskToMoney(`${data[52].ORCADO}`) },
        CRC05_CUSTOS_DE_CANTEIRO: { ORCADO: maskToMoney(`${data[53].ORCADO}`) },
        CRC06_CUSTOS_DE_EQUIPAMENTOS: { ORCADO: maskToMoney(`${data[54].ORCADO}`) },
        CRC07_CUSTOS_DE_MATERIAIS: { ORCADO: maskToMoney(`${data[55].ORCADO}`) },
        CRC08_CUSTOS_DE_SUBCONTRATOS: { ORCADO: maskToMoney(`${data[56].ORCADO}`) },
        CRC09_CUSTOS_FINANCEIROS: { ORCADO: maskToMoney(`${data[57].ORCADO}`) },
        CRC10_OUTROS: { ORCADO: maskToMoney(`${data[58].ORCADO}`) },
      };

      total_CMD = total_CMD + maskToFloat(model.CMD01_SALARIOS.ORCADO);
      total_CMD = total_CMD + maskToFloat(model.CMD02_ENCARGOS.ORCADO);
      total_CMD = total_CMD + maskToFloat(model.CMD03_HORAS_EXTRAS_ADICIONAL.ORCADO);

      total_CMI = total_CMI + maskToFloat(model.CMI01_SALARIOS.ORCADO);
      total_CMI = total_CMI + maskToFloat(model.CMI02_ENCARGOS.ORCADO);
      total_CMI = total_CMI + maskToFloat(model.CMI03_HORAS_EXTRAS_ADICIONAL.ORCADO);

      total_CAP = total_CAP + maskToFloat(model.CAP01_TRANSPORTE.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP02_ALIMENTACAO.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP03_EPI_E_UNIFORMES.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP04_ALOJAMENTO.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP05_CURSOS_E_TREINAMENTO.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP06_ASSISTENCIA_MEDICA.ORCADO);
      total_CAP = total_CAP + maskToFloat(model.CAP07_OUTROS.ORCADO);

      total_CMM = total_CMM + maskToFloat(model.CMM01_DESPESAS_COM_VIAGENS.ORCADO);
      total_CMM = total_CMM + maskToFloat(model.CMM02_FRETES_TRANSPORTES.ORCADO);
      total_CMM = total_CMM + maskToFloat(model.CMM03_OUTROS.ORCADO);

      total_CCE = total_CCE + maskToFloat(model.CCE01_INSTALACAO_CONSTRUCAO.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE02_MANUTENCAO.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE03_ALUGUEIS.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE04_COMUNICACAO.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE05_INFORMATICA_TI.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE06_MATERIAIS_DE_ESCRITORIO.ORCADO);
      total_CCE = total_CCE + maskToFloat(model.CCE07_OUTROS.ORCADO);

      total_CEQ = total_CEQ + maskToFloat(model.CEQ01_AQUISICAO.ORCADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ02_LOCACAO.ORCADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ03_MANUTENCAO.ORCADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ04_OUTROS.ORCADO);

      total_CMA = total_CMA + maskToFloat(model.CMA01_DIVERSOS.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA02_CIVIL.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA03_TUBULACAO.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA04_CALDEIRARIA_ESTRUTURA.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA05_ELETRICA_INSTRUMENTACAO.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA06_ISOLAMENTO.ORCADO);
      total_CMA = total_CMA + maskToFloat(model.CMA07_PINTURA.ORCADO);

      total_CMC = total_CMC + maskToFloat(model.CMC01_MATERIAL_DE_CONSUMO.ORCADO);
      total_CMC = total_CMC + maskToFloat(model.CMC02_GASES.ORCADO);
      total_CMC = total_CMC + maskToFloat(model.CMC03_ELETRODOS_E_VARETAS.ORCADO);

      total_CMV = total_CMV + maskToFloat(model.CMV01_MERC_VEND.ORCADO);

      total_CSB = total_CSB + maskToFloat(model.CSB01_DIVERSOS.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB02_CIVIL.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB03_TUBULACAO.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB04_CALDEIRARIA_ESTRUTURA.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB05_ELETRICA_INSTRUMENTACAO.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB06_ISOLAMENTO.ORCADO);
      total_CSB = total_CSB + maskToFloat(model.CSB07_PINTURA.ORCADO);

      total_CDG = total_CDG + maskToFloat(model.CDG01_TAXAS_DIVERSAS.ORCADO);
      total_CDG = total_CDG + maskToFloat(model.CDG02_PREMIACOES_PATROCINIO.ORCADO);
      total_CDG = total_CDG + maskToFloat(model.CDG03_SEGUROS_MULTAS_JUROS.ORCADO);
      total_CDG = total_CDG + maskToFloat(model.CDG04_OUTROS.ORCADO);

      total_CRC = total_CRC + maskToFloat(model.CRC01_CUSTOS_DE_MOD.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC02_CUSTOS_DE_MOI.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC03_CUSTOS_DE_APOIO.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC04_CUSTOS_DE_MOBILIZACAO.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC05_CUSTOS_DE_CANTEIRO.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC06_CUSTOS_DE_EQUIPAMENTOS.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC07_CUSTOS_DE_MATERIAIS.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC08_CUSTOS_DE_SUBCONTRATOS.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC09_CUSTOS_FINANCEIROS.ORCADO);
      total_CRC = total_CRC + maskToFloat(model.CRC10_OUTROS.ORCADO);

      total = (total_CMD + total_CMI + total_CAP + total_CMM + total_CCE + total_CEQ + total_CMA + total_CMC + total_CMV + total_CSB + total_CDG + total_CRC);

      model = {
        ...model,
        TOTAL_CUSTOS: { ORCADO: maskToMoney2(total) },
        TOTAL_CUSTOS_CMD: { ORCADO: maskToMoney2(total_CMD) },
        TOTAL_CUSTOS_CMI: { ORCADO: maskToMoney2(total_CMI) },
        TOTAL_CUSTOS_CAP: { ORCADO: maskToMoney2(total_CAP) },
        TOTAL_CUSTOS_CMM: { ORCADO: maskToMoney2(total_CMM) },
        TOTAL_CUSTOS_CCE: { ORCADO: maskToMoney2(total_CCE) },
        TOTAL_CUSTOS_CEQ: { ORCADO: maskToMoney2(total_CEQ) },
        TOTAL_CUSTOS_CMA: { ORCADO: maskToMoney2(total_CMA) },
        TOTAL_CUSTOS_CMC: { ORCADO: maskToMoney2(total_CMC) },
        TOTAL_CUSTOS_CMV: { ORCADO: maskToMoney2(total_CMV) },
        TOTAL_CUSTOS_CSB: { ORCADO: maskToMoney2(total_CSB) },
        TOTAL_CUSTOS_CDG: { ORCADO: maskToMoney2(total_CDG) },
        TOTAL_CUSTOS_CRC: { ORCADO: maskToMoney2(total_CRC) },
      };
    }


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      model = {
        ...model,
        CMD01_SALARIOS: { PLANEJADO: maskToMoney(`${data[0].PLANEJADO}`) },
        CMD02_ENCARGOS: { PLANEJADO: maskToMoney(`${data[1].PLANEJADO}`) },
        CMD03_HORAS_EXTRAS_ADICIONAL: { PLANEJADO: maskToMoney(`${data[2].PLANEJADO}`) },
        CMI01_SALARIOS: { PLANEJADO: maskToMoney(`${data[3].PLANEJADO}`) },
        CMI02_ENCARGOS: { PLANEJADO: maskToMoney(`${data[4].PLANEJADO}`) },
        CMI03_HORAS_EXTRAS_ADICIONAL: { PLANEJADO: maskToMoney(`${data[5].PLANEJADO}`) },
        CAP01_TRANSPORTE: { PLANEJADO: maskToMoney(`${data[6].PLANEJADO}`) },
        CAP02_ALIMENTACAO: { PLANEJADO: maskToMoney(`${data[7].PLANEJADO}`) },
        CAP03_EPI_E_UNIFORMES: { PLANEJADO: maskToMoney(`${data[8].PLANEJADO}`) },
        CAP04_ALOJAMENTO: { PLANEJADO: maskToMoney(`${data[9].PLANEJADO}`) },
        CAP05_CURSOS_E_TREINAMENTO: { PLANEJADO: maskToMoney(`${data[10].PLANEJADO}`) },
        CAP06_ASSISTENCIA_MEDICA: { PLANEJADO: maskToMoney(`${data[11].PLANEJADO}`) },
        CAP07_OUTROS: { PLANEJADO: maskToMoney(`${data[12].PLANEJADO}`) },
        CMM01_DESPESAS_COM_VIAGENS: { PLANEJADO: maskToMoney(`${data[13].PLANEJADO}`) },
        CMM02_FRETES_TRANSPORTES: { PLANEJADO: maskToMoney(`${data[14].PLANEJADO}`) },
        CMM03_OUTROS: { PLANEJADO: maskToMoney(`${data[15].PLANEJADO}`) },
        CCE01_INSTALACAO_CONSTRUCAO: { PLANEJADO: maskToMoney(`${data[16].PLANEJADO}`) },
        CCE02_MANUTENCAO: { PLANEJADO: maskToMoney(`${data[17].PLANEJADO}`) },
        CCE03_ALUGUEIS: { PLANEJADO: maskToMoney(`${data[18].PLANEJADO}`) },
        CCE04_COMUNICACAO: { PLANEJADO: maskToMoney(`${data[19].PLANEJADO}`) },
        CCE05_INFORMATICA_TI: { PLANEJADO: maskToMoney(`${data[20].PLANEJADO}`) },
        CCE06_MATERIAIS_DE_ESCRITORIO: { PLANEJADO: maskToMoney(`${data[21].PLANEJADO}`) },
        CCE07_OUTROS: { PLANEJADO: maskToMoney(`${data[22].PLANEJADO}`) },
        CEQ01_AQUISICAO: { PLANEJADO: maskToMoney(`${data[23].PLANEJADO}`) },
        CEQ02_LOCACAO: { PLANEJADO: maskToMoney(`${data[24].PLANEJADO}`) },
        CEQ03_MANUTENCAO: { PLANEJADO: maskToMoney(`${data[25].PLANEJADO}`) },
        CEQ04_OUTROS: { PLANEJADO: maskToMoney(`${data[26].PLANEJADO}`) },
        CMA01_DIVERSOS: { PLANEJADO: maskToMoney(`${data[27].PLANEJADO}`) },
        CMA02_CIVIL: { PLANEJADO: maskToMoney(`${data[28].PLANEJADO}`) },
        CMA03_TUBULACAO: { PLANEJADO: maskToMoney(`${data[29].PLANEJADO}`) },
        CMA04_CALDEIRARIA_ESTRUTURA: { PLANEJADO: maskToMoney(`${data[30].PLANEJADO}`) },
        CMA05_ELETRICA_INSTRUMENTACAO: { PLANEJADO: maskToMoney(`${data[31].PLANEJADO}`) },
        CMA06_ISOLAMENTO: { PLANEJADO: maskToMoney(`${data[32].PLANEJADO}`) },
        CMA07_PINTURA: { PLANEJADO: maskToMoney(`${data[33].PLANEJADO}`) },
        CMC01_MATERIAL_DE_CONSUMO: { PLANEJADO: maskToMoney(`${data[34].PLANEJADO}`) },
        CMC02_GASES: { PLANEJADO: maskToMoney(`${data[35].PLANEJADO}`) },
        CMC03_ELETRODOS_E_VARETAS: { PLANEJADO: maskToMoney(`${data[36].PLANEJADO}`) },
        CMV01_MERC_VEND: { PLANEJADO: maskToMoney(`${data[37].PLANEJADO}`) },
        CSB01_DIVERSOS: { PLANEJADO: maskToMoney(`${data[38].PLANEJADO}`) },
        CSB02_CIVIL: { PLANEJADO: maskToMoney(`${data[39].PLANEJADO}`) },
        CSB03_TUBULACAO: { PLANEJADO: maskToMoney(`${data[40].PLANEJADO}`) },
        CSB04_CALDEIRARIA_ESTRUTURA: { PLANEJADO: maskToMoney(`${data[41].PLANEJADO}`) },
        CSB05_ELETRICA_INSTRUMENTACAO: { PLANEJADO: maskToMoney(`${data[42].PLANEJADO}`) },
        CSB06_ISOLAMENTO: { PLANEJADO: maskToMoney(`${data[43].PLANEJADO}`) },
        CSB07_PINTURA: { PLANEJADO: maskToMoney(`${data[44].PLANEJADO}`) },
        CDG01_TAXAS_DIVERSAS: { PLANEJADO: maskToMoney(`${data[45].PLANEJADO}`) },
        CDG02_PREMIACOES_PATROCINIO: { PLANEJADO: maskToMoney(`${data[46].PLANEJADO}`) },
        CDG03_SEGUROS_MULTAS_JUROS: { PLANEJADO: maskToMoney(`${data[47].PLANEJADO}`) },
        CDG04_OUTROS: { PLANEJADO: maskToMoney(`${data[48].PLANEJADO}`) },
        CRC01_CUSTOS_DE_MOD: { PLANEJADO: maskToMoney(`${data[49].PLANEJADO}`) },
        CRC02_CUSTOS_DE_MOI: { PLANEJADO: maskToMoney(`${data[50].PLANEJADO}`) },
        CRC03_CUSTOS_DE_APOIO: { PLANEJADO: maskToMoney(`${data[51].PLANEJADO}`) },
        CRC04_CUSTOS_DE_MOBILIZACAO: { PLANEJADO: maskToMoney(`${data[52].PLANEJADO}`) },
        CRC05_CUSTOS_DE_CANTEIRO: { PLANEJADO: maskToMoney(`${data[53].PLANEJADO}`) },
        CRC06_CUSTOS_DE_EQUIPAMENTOS: { PLANEJADO: maskToMoney(`${data[54].PLANEJADO}`) },
        CRC07_CUSTOS_DE_MATERIAIS: { PLANEJADO: maskToMoney(`${data[55].PLANEJADO}`) },
        CRC08_CUSTOS_DE_SUBCONTRATOS: { PLANEJADO: maskToMoney(`${data[56].PLANEJADO}`) },
        CRC09_CUSTOS_FINANCEIROS: { PLANEJADO: maskToMoney(`${data[57].PLANEJADO}`) },
        CRC10_OUTROS: { PLANEJADO: maskToMoney(`${data[58].PLANEJADO}`) },
      };

      total_CMD = total_CMD + maskToFloat(model.CMD01_SALARIOS.PLANEJADO);
      total_CMD = total_CMD + maskToFloat(model.CMD02_ENCARGOS.PLANEJADO);
      total_CMD = total_CMD + maskToFloat(model.CMD03_HORAS_EXTRAS_ADICIONAL.PLANEJADO);

      total_CMI = total_CMI + maskToFloat(model.CMI01_SALARIOS.PLANEJADO);
      total_CMI = total_CMI + maskToFloat(model.CMI02_ENCARGOS.PLANEJADO);
      total_CMI = total_CMI + maskToFloat(model.CMI03_HORAS_EXTRAS_ADICIONAL.PLANEJADO);

      total_CAP = total_CAP + maskToFloat(model.CAP01_TRANSPORTE.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP02_ALIMENTACAO.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP03_EPI_E_UNIFORMES.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP04_ALOJAMENTO.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP05_CURSOS_E_TREINAMENTO.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP06_ASSISTENCIA_MEDICA.PLANEJADO);
      total_CAP = total_CAP + maskToFloat(model.CAP07_OUTROS.PLANEJADO);

      total_CMM = total_CMM + maskToFloat(model.CMM01_DESPESAS_COM_VIAGENS.PLANEJADO);
      total_CMM = total_CMM + maskToFloat(model.CMM02_FRETES_TRANSPORTES.PLANEJADO);
      total_CMM = total_CMM + maskToFloat(model.CMM03_OUTROS.PLANEJADO);

      total_CCE = total_CCE + maskToFloat(model.CCE01_INSTALACAO_CONSTRUCAO.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE02_MANUTENCAO.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE03_ALUGUEIS.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE04_COMUNICACAO.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE05_INFORMATICA_TI.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE06_MATERIAIS_DE_ESCRITORIO.PLANEJADO);
      total_CCE = total_CCE + maskToFloat(model.CCE07_OUTROS.PLANEJADO);

      total_CEQ = total_CEQ + maskToFloat(model.CEQ01_AQUISICAO.PLANEJADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ02_LOCACAO.PLANEJADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ03_MANUTENCAO.PLANEJADO);
      total_CEQ = total_CEQ + maskToFloat(model.CEQ04_OUTROS.PLANEJADO);

      total_CMA = total_CMA + maskToFloat(model.CMA01_DIVERSOS.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA02_CIVIL.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA03_TUBULACAO.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA04_CALDEIRARIA_ESTRUTURA.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA05_ELETRICA_INSTRUMENTACAO.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA06_ISOLAMENTO.PLANEJADO);
      total_CMA = total_CMA + maskToFloat(model.CMA07_PINTURA.PLANEJADO);

      total_CMC = total_CMC + maskToFloat(model.CMC01_MATERIAL_DE_CONSUMO.PLANEJADO);
      total_CMC = total_CMC + maskToFloat(model.CMC02_GASES.PLANEJADO);
      total_CMC = total_CMC + maskToFloat(model.CMC03_ELETRODOS_E_VARETAS.PLANEJADO);

      total_CMV = total_CMV + maskToFloat(model.CMV01_MERC_VEND.PLANEJADO);

      total_CSB = total_CSB + maskToFloat(model.CSB01_DIVERSOS.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB02_CIVIL.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB03_TUBULACAO.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB04_CALDEIRARIA_ESTRUTURA.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB05_ELETRICA_INSTRUMENTACAO.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB06_ISOLAMENTO.PLANEJADO);
      total_CSB = total_CSB + maskToFloat(model.CSB07_PINTURA.PLANEJADO);

      total_CDG = total_CDG + maskToFloat(model.CDG01_TAXAS_DIVERSAS.PLANEJADO);
      total_CDG = total_CDG + maskToFloat(model.CDG02_PREMIACOES_PATROCINIO.PLANEJADO);
      total_CDG = total_CDG + maskToFloat(model.CDG03_SEGUROS_MULTAS_JUROS.PLANEJADO);
      total_CDG = total_CDG + maskToFloat(model.CDG04_OUTROS.PLANEJADO);

      total_CRC = total_CRC + maskToFloat(model.CRC01_CUSTOS_DE_MOD.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC02_CUSTOS_DE_MOI.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC03_CUSTOS_DE_APOIO.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC04_CUSTOS_DE_MOBILIZACAO.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC05_CUSTOS_DE_CANTEIRO.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC06_CUSTOS_DE_EQUIPAMENTOS.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC07_CUSTOS_DE_MATERIAIS.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC08_CUSTOS_DE_SUBCONTRATOS.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC09_CUSTOS_FINANCEIROS.PLANEJADO);
      total_CRC = total_CRC + maskToFloat(model.CRC10_OUTROS.PLANEJADO);

      total = (total_CMD + total_CMI + total_CAP + total_CMM + total_CCE + total_CEQ + total_CMA + total_CMC + total_CMV + total_CSB + total_CDG + total_CRC);

      model = {
        ...model,
        TOTAL_CUSTOS: { PLANEJADO: maskToMoney2(total) },
        TOTAL_CUSTOS_CMD: { PLANEJADO: maskToMoney2(total_CMD) },
        TOTAL_CUSTOS_CMI: { PLANEJADO: maskToMoney2(total_CMI) },
        TOTAL_CUSTOS_CAP: { PLANEJADO: maskToMoney2(total_CAP) },
        TOTAL_CUSTOS_CMM: { PLANEJADO: maskToMoney2(total_CMM) },
        TOTAL_CUSTOS_CCE: { PLANEJADO: maskToMoney2(total_CCE) },
        TOTAL_CUSTOS_CEQ: { PLANEJADO: maskToMoney2(total_CEQ) },
        TOTAL_CUSTOS_CMA: { PLANEJADO: maskToMoney2(total_CMA) },
        TOTAL_CUSTOS_CMC: { PLANEJADO: maskToMoney2(total_CMC) },
        TOTAL_CUSTOS_CMV: { PLANEJADO: maskToMoney2(total_CMV) },
        TOTAL_CUSTOS_CSB: { PLANEJADO: maskToMoney2(total_CSB) },
        TOTAL_CUSTOS_CDG: { PLANEJADO: maskToMoney2(total_CDG) },
        TOTAL_CUSTOS_CRC: { PLANEJADO: maskToMoney2(total_CRC) },
      };
    }


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sum_CMD = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CMI = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CAP = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CMM = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CCE = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CEQ = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CMA = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CMC = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CMV = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CSB = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CDG = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };
      let sum_CRC = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };

      model = {
        ...model,
        CMD01_SALARIOS: {
          JAN: maskToMoney(`${data[0].JAN}`),
          FEV: maskToMoney(`${data[0].FEV}`),
          MAR: maskToMoney(`${data[0].MAR}`),
          ABR: maskToMoney(`${data[0].ABR}`),
          MAI: maskToMoney(`${data[0].MAI}`),
          JUN: maskToMoney(`${data[0].JUN}`),
          JUL: maskToMoney(`${data[0].JUL}`),
          AGO: maskToMoney(`${data[0].AGO}`),
          SET: maskToMoney(`${data[0].SET}`),
          OUT: maskToMoney(`${data[0].OUT}`),
          NOV: maskToMoney(`${data[0].NOV}`),
          DEZ: maskToMoney(`${data[0].DEZ}`),
        },
        CMD02_ENCARGOS: {
          JAN: maskToMoney(`${data[1].JAN}`),
          FEV: maskToMoney(`${data[1].FEV}`),
          MAR: maskToMoney(`${data[1].MAR}`),
          ABR: maskToMoney(`${data[1].ABR}`),
          MAI: maskToMoney(`${data[1].MAI}`),
          JUN: maskToMoney(`${data[1].JUN}`),
          JUL: maskToMoney(`${data[1].JUL}`),
          AGO: maskToMoney(`${data[1].AGO}`),
          SET: maskToMoney(`${data[1].SET}`),
          OUT: maskToMoney(`${data[1].OUT}`),
          NOV: maskToMoney(`${data[1].NOV}`),
          DEZ: maskToMoney(`${data[1].DEZ}`),
        },
        CMD03_HORAS_EXTRAS_ADICIONAL: {
          JAN: maskToMoney(`${data[2].JAN}`),
          FEV: maskToMoney(`${data[2].FEV}`),
          MAR: maskToMoney(`${data[2].MAR}`),
          ABR: maskToMoney(`${data[2].ABR}`),
          MAI: maskToMoney(`${data[2].MAI}`),
          JUN: maskToMoney(`${data[2].JUN}`),
          JUL: maskToMoney(`${data[2].JUL}`),
          AGO: maskToMoney(`${data[2].AGO}`),
          SET: maskToMoney(`${data[2].SET}`),
          OUT: maskToMoney(`${data[2].OUT}`),
          NOV: maskToMoney(`${data[2].NOV}`),
          DEZ: maskToMoney(`${data[2].DEZ}`),
        },
        CMI01_SALARIOS: {
          JAN: maskToMoney(`${data[3].JAN}`),
          FEV: maskToMoney(`${data[3].FEV}`),
          MAR: maskToMoney(`${data[3].MAR}`),
          ABR: maskToMoney(`${data[3].ABR}`),
          MAI: maskToMoney(`${data[3].MAI}`),
          JUN: maskToMoney(`${data[3].JUN}`),
          JUL: maskToMoney(`${data[3].JUL}`),
          AGO: maskToMoney(`${data[3].AGO}`),
          SET: maskToMoney(`${data[3].SET}`),
          OUT: maskToMoney(`${data[3].OUT}`),
          NOV: maskToMoney(`${data[3].NOV}`),
          DEZ: maskToMoney(`${data[3].DEZ}`),
        },
        CMI02_ENCARGOS: {
          JAN: maskToMoney(`${data[4].JAN}`),
          FEV: maskToMoney(`${data[4].FEV}`),
          MAR: maskToMoney(`${data[4].MAR}`),
          ABR: maskToMoney(`${data[4].ABR}`),
          MAI: maskToMoney(`${data[4].MAI}`),
          JUN: maskToMoney(`${data[4].JUN}`),
          JUL: maskToMoney(`${data[4].JUL}`),
          AGO: maskToMoney(`${data[4].AGO}`),
          SET: maskToMoney(`${data[4].SET}`),
          OUT: maskToMoney(`${data[4].OUT}`),
          NOV: maskToMoney(`${data[4].NOV}`),
          DEZ: maskToMoney(`${data[4].DEZ}`),
        },
        CMI03_HORAS_EXTRAS_ADICIONAL: {
          JAN: maskToMoney(`${data[5].JAN}`),
          FEV: maskToMoney(`${data[5].FEV}`),
          MAR: maskToMoney(`${data[5].MAR}`),
          ABR: maskToMoney(`${data[5].ABR}`),
          MAI: maskToMoney(`${data[5].MAI}`),
          JUN: maskToMoney(`${data[5].JUN}`),
          JUL: maskToMoney(`${data[5].JUL}`),
          AGO: maskToMoney(`${data[5].AGO}`),
          SET: maskToMoney(`${data[5].SET}`),
          OUT: maskToMoney(`${data[5].OUT}`),
          NOV: maskToMoney(`${data[5].NOV}`),
          DEZ: maskToMoney(`${data[5].DEZ}`),
        },
        CAP01_TRANSPORTE: {
          JAN: maskToMoney(`${data[6].JAN}`),
          FEV: maskToMoney(`${data[6].FEV}`),
          MAR: maskToMoney(`${data[6].MAR}`),
          ABR: maskToMoney(`${data[6].ABR}`),
          MAI: maskToMoney(`${data[6].MAI}`),
          JUN: maskToMoney(`${data[6].JUN}`),
          JUL: maskToMoney(`${data[6].JUL}`),
          AGO: maskToMoney(`${data[6].AGO}`),
          SET: maskToMoney(`${data[6].SET}`),
          OUT: maskToMoney(`${data[6].OUT}`),
          NOV: maskToMoney(`${data[6].NOV}`),
          DEZ: maskToMoney(`${data[6].DEZ}`),
        },
        CAP02_ALIMENTACAO: {
          JAN: maskToMoney(`${data[7].JAN}`),
          FEV: maskToMoney(`${data[7].FEV}`),
          MAR: maskToMoney(`${data[7].MAR}`),
          ABR: maskToMoney(`${data[7].ABR}`),
          MAI: maskToMoney(`${data[7].MAI}`),
          JUN: maskToMoney(`${data[7].JUN}`),
          JUL: maskToMoney(`${data[7].JUL}`),
          AGO: maskToMoney(`${data[7].AGO}`),
          SET: maskToMoney(`${data[7].SET}`),
          OUT: maskToMoney(`${data[7].OUT}`),
          NOV: maskToMoney(`${data[7].NOV}`),
          DEZ: maskToMoney(`${data[7].DEZ}`),
        },
        CAP03_EPI_E_UNIFORMES: {
          JAN: maskToMoney(`${data[8].JAN}`),
          FEV: maskToMoney(`${data[8].FEV}`),
          MAR: maskToMoney(`${data[8].MAR}`),
          ABR: maskToMoney(`${data[8].ABR}`),
          MAI: maskToMoney(`${data[8].MAI}`),
          JUN: maskToMoney(`${data[8].JUN}`),
          JUL: maskToMoney(`${data[8].JUL}`),
          AGO: maskToMoney(`${data[8].AGO}`),
          SET: maskToMoney(`${data[8].SET}`),
          OUT: maskToMoney(`${data[8].OUT}`),
          NOV: maskToMoney(`${data[8].NOV}`),
          DEZ: maskToMoney(`${data[8].DEZ}`),
        },
        CAP04_ALOJAMENTO: {
          JAN: maskToMoney(`${data[9].JAN}`),
          FEV: maskToMoney(`${data[9].FEV}`),
          MAR: maskToMoney(`${data[9].MAR}`),
          ABR: maskToMoney(`${data[9].ABR}`),
          MAI: maskToMoney(`${data[9].MAI}`),
          JUN: maskToMoney(`${data[9].JUN}`),
          JUL: maskToMoney(`${data[9].JUL}`),
          AGO: maskToMoney(`${data[9].AGO}`),
          SET: maskToMoney(`${data[9].SET}`),
          OUT: maskToMoney(`${data[9].OUT}`),
          NOV: maskToMoney(`${data[9].NOV}`),
          DEZ: maskToMoney(`${data[9].DEZ}`),
        },
        CAP05_CURSOS_E_TREINAMENTO: {
          JAN: maskToMoney(`${data[10].JAN}`),
          FEV: maskToMoney(`${data[10].FEV}`),
          MAR: maskToMoney(`${data[10].MAR}`),
          ABR: maskToMoney(`${data[10].ABR}`),
          MAI: maskToMoney(`${data[10].MAI}`),
          JUN: maskToMoney(`${data[10].JUN}`),
          JUL: maskToMoney(`${data[10].JUL}`),
          AGO: maskToMoney(`${data[10].AGO}`),
          SET: maskToMoney(`${data[10].SET}`),
          OUT: maskToMoney(`${data[10].OUT}`),
          NOV: maskToMoney(`${data[10].NOV}`),
          DEZ: maskToMoney(`${data[10].DEZ}`),
        },
        CAP06_ASSISTENCIA_MEDICA: {
          JAN: maskToMoney(`${data[11].JAN}`),
          FEV: maskToMoney(`${data[11].FEV}`),
          MAR: maskToMoney(`${data[11].MAR}`),
          ABR: maskToMoney(`${data[11].ABR}`),
          MAI: maskToMoney(`${data[11].MAI}`),
          JUN: maskToMoney(`${data[11].JUN}`),
          JUL: maskToMoney(`${data[11].JUL}`),
          AGO: maskToMoney(`${data[11].AGO}`),
          SET: maskToMoney(`${data[11].SET}`),
          OUT: maskToMoney(`${data[11].OUT}`),
          NOV: maskToMoney(`${data[11].NOV}`),
          DEZ: maskToMoney(`${data[11].DEZ}`),
        },
        CAP07_OUTROS: {
          JAN: maskToMoney(`${data[12].JAN}`),
          FEV: maskToMoney(`${data[12].FEV}`),
          MAR: maskToMoney(`${data[12].MAR}`),
          ABR: maskToMoney(`${data[12].ABR}`),
          MAI: maskToMoney(`${data[12].MAI}`),
          JUN: maskToMoney(`${data[12].JUN}`),
          JUL: maskToMoney(`${data[12].JUL}`),
          AGO: maskToMoney(`${data[12].AGO}`),
          SET: maskToMoney(`${data[12].SET}`),
          OUT: maskToMoney(`${data[12].OUT}`),
          NOV: maskToMoney(`${data[12].NOV}`),
          DEZ: maskToMoney(`${data[12].DEZ}`),
        },
        CMM01_DESPESAS_COM_VIAGENS: {
          JAN: maskToMoney(`${data[13].JAN}`),
          FEV: maskToMoney(`${data[13].FEV}`),
          MAR: maskToMoney(`${data[13].MAR}`),
          ABR: maskToMoney(`${data[13].ABR}`),
          MAI: maskToMoney(`${data[13].MAI}`),
          JUN: maskToMoney(`${data[13].JUN}`),
          JUL: maskToMoney(`${data[13].JUL}`),
          AGO: maskToMoney(`${data[13].AGO}`),
          SET: maskToMoney(`${data[13].SET}`),
          OUT: maskToMoney(`${data[13].OUT}`),
          NOV: maskToMoney(`${data[13].NOV}`),
          DEZ: maskToMoney(`${data[13].DEZ}`),
        },
        CMM02_FRETES_TRANSPORTES: {
          JAN: maskToMoney(`${data[14].JAN}`),
          FEV: maskToMoney(`${data[14].FEV}`),
          MAR: maskToMoney(`${data[14].MAR}`),
          ABR: maskToMoney(`${data[14].ABR}`),
          MAI: maskToMoney(`${data[14].MAI}`),
          JUN: maskToMoney(`${data[14].JUN}`),
          JUL: maskToMoney(`${data[14].JUL}`),
          AGO: maskToMoney(`${data[14].AGO}`),
          SET: maskToMoney(`${data[14].SET}`),
          OUT: maskToMoney(`${data[14].OUT}`),
          NOV: maskToMoney(`${data[14].NOV}`),
          DEZ: maskToMoney(`${data[14].DEZ}`),
        },
        CMM03_OUTROS: {
          JAN: maskToMoney(`${data[15].JAN}`),
          FEV: maskToMoney(`${data[15].FEV}`),
          MAR: maskToMoney(`${data[15].MAR}`),
          ABR: maskToMoney(`${data[15].ABR}`),
          MAI: maskToMoney(`${data[15].MAI}`),
          JUN: maskToMoney(`${data[15].JUN}`),
          JUL: maskToMoney(`${data[15].JUL}`),
          AGO: maskToMoney(`${data[15].AGO}`),
          SET: maskToMoney(`${data[15].SET}`),
          OUT: maskToMoney(`${data[15].OUT}`),
          NOV: maskToMoney(`${data[15].NOV}`),
          DEZ: maskToMoney(`${data[15].DEZ}`),
        },
        CCE01_INSTALACAO_CONSTRUCAO: {
          JAN: maskToMoney(`${data[16].JAN}`),
          FEV: maskToMoney(`${data[16].FEV}`),
          MAR: maskToMoney(`${data[16].MAR}`),
          ABR: maskToMoney(`${data[16].ABR}`),
          MAI: maskToMoney(`${data[16].MAI}`),
          JUN: maskToMoney(`${data[16].JUN}`),
          JUL: maskToMoney(`${data[16].JUL}`),
          AGO: maskToMoney(`${data[16].AGO}`),
          SET: maskToMoney(`${data[16].SET}`),
          OUT: maskToMoney(`${data[16].OUT}`),
          NOV: maskToMoney(`${data[16].NOV}`),
          DEZ: maskToMoney(`${data[16].DEZ}`),
        },
        CCE02_MANUTENCAO: {
          JAN: maskToMoney(`${data[17].JAN}`),
          FEV: maskToMoney(`${data[17].FEV}`),
          MAR: maskToMoney(`${data[17].MAR}`),
          ABR: maskToMoney(`${data[17].ABR}`),
          MAI: maskToMoney(`${data[17].MAI}`),
          JUN: maskToMoney(`${data[17].JUN}`),
          JUL: maskToMoney(`${data[17].JUL}`),
          AGO: maskToMoney(`${data[17].AGO}`),
          SET: maskToMoney(`${data[17].SET}`),
          OUT: maskToMoney(`${data[17].OUT}`),
          NOV: maskToMoney(`${data[17].NOV}`),
          DEZ: maskToMoney(`${data[17].DEZ}`),
        },
        CCE03_ALUGUEIS: {
          JAN: maskToMoney(`${data[18].JAN}`),
          FEV: maskToMoney(`${data[18].FEV}`),
          MAR: maskToMoney(`${data[18].MAR}`),
          ABR: maskToMoney(`${data[18].ABR}`),
          MAI: maskToMoney(`${data[18].MAI}`),
          JUN: maskToMoney(`${data[18].JUN}`),
          JUL: maskToMoney(`${data[18].JUL}`),
          AGO: maskToMoney(`${data[18].AGO}`),
          SET: maskToMoney(`${data[18].SET}`),
          OUT: maskToMoney(`${data[18].OUT}`),
          NOV: maskToMoney(`${data[18].NOV}`),
          DEZ: maskToMoney(`${data[18].DEZ}`),
        },
        CCE04_COMUNICACAO: {
          JAN: maskToMoney(`${data[19].JAN}`),
          FEV: maskToMoney(`${data[19].FEV}`),
          MAR: maskToMoney(`${data[19].MAR}`),
          ABR: maskToMoney(`${data[19].ABR}`),
          MAI: maskToMoney(`${data[19].MAI}`),
          JUN: maskToMoney(`${data[19].JUN}`),
          JUL: maskToMoney(`${data[19].JUL}`),
          AGO: maskToMoney(`${data[19].AGO}`),
          SET: maskToMoney(`${data[19].SET}`),
          OUT: maskToMoney(`${data[19].OUT}`),
          NOV: maskToMoney(`${data[19].NOV}`),
          DEZ: maskToMoney(`${data[19].DEZ}`),
        },
        CCE05_INFORMATICA_TI: {
          JAN: maskToMoney(`${data[20].JAN}`),
          FEV: maskToMoney(`${data[20].FEV}`),
          MAR: maskToMoney(`${data[20].MAR}`),
          ABR: maskToMoney(`${data[20].ABR}`),
          MAI: maskToMoney(`${data[20].MAI}`),
          JUN: maskToMoney(`${data[20].JUN}`),
          JUL: maskToMoney(`${data[20].JUL}`),
          AGO: maskToMoney(`${data[20].AGO}`),
          SET: maskToMoney(`${data[20].SET}`),
          OUT: maskToMoney(`${data[20].OUT}`),
          NOV: maskToMoney(`${data[20].NOV}`),
          DEZ: maskToMoney(`${data[20].DEZ}`),
        },
        CCE06_MATERIAIS_DE_ESCRITORIO: {
          JAN: maskToMoney(`${data[21].JAN}`),
          FEV: maskToMoney(`${data[21].FEV}`),
          MAR: maskToMoney(`${data[21].MAR}`),
          ABR: maskToMoney(`${data[21].ABR}`),
          MAI: maskToMoney(`${data[21].MAI}`),
          JUN: maskToMoney(`${data[21].JUN}`),
          JUL: maskToMoney(`${data[21].JUL}`),
          AGO: maskToMoney(`${data[21].AGO}`),
          SET: maskToMoney(`${data[21].SET}`),
          OUT: maskToMoney(`${data[21].OUT}`),
          NOV: maskToMoney(`${data[21].NOV}`),
          DEZ: maskToMoney(`${data[21].DEZ}`),
        },
        CCE07_OUTROS: {
          JAN: maskToMoney(`${data[22].JAN}`),
          FEV: maskToMoney(`${data[22].FEV}`),
          MAR: maskToMoney(`${data[22].MAR}`),
          ABR: maskToMoney(`${data[22].ABR}`),
          MAI: maskToMoney(`${data[22].MAI}`),
          JUN: maskToMoney(`${data[22].JUN}`),
          JUL: maskToMoney(`${data[22].JUL}`),
          AGO: maskToMoney(`${data[22].AGO}`),
          SET: maskToMoney(`${data[22].SET}`),
          OUT: maskToMoney(`${data[22].OUT}`),
          NOV: maskToMoney(`${data[22].NOV}`),
          DEZ: maskToMoney(`${data[22].DEZ}`),
        },
        CEQ01_AQUISICAO: {
          JAN: maskToMoney(`${data[23].JAN}`),
          FEV: maskToMoney(`${data[23].FEV}`),
          MAR: maskToMoney(`${data[23].MAR}`),
          ABR: maskToMoney(`${data[23].ABR}`),
          MAI: maskToMoney(`${data[23].MAI}`),
          JUN: maskToMoney(`${data[23].JUN}`),
          JUL: maskToMoney(`${data[23].JUL}`),
          AGO: maskToMoney(`${data[23].AGO}`),
          SET: maskToMoney(`${data[23].SET}`),
          OUT: maskToMoney(`${data[23].OUT}`),
          NOV: maskToMoney(`${data[23].NOV}`),
          DEZ: maskToMoney(`${data[23].DEZ}`),
        },
        CEQ02_LOCACAO: {
          JAN: maskToMoney(`${data[24].JAN}`),
          FEV: maskToMoney(`${data[24].FEV}`),
          MAR: maskToMoney(`${data[24].MAR}`),
          ABR: maskToMoney(`${data[24].ABR}`),
          MAI: maskToMoney(`${data[24].MAI}`),
          JUN: maskToMoney(`${data[24].JUN}`),
          JUL: maskToMoney(`${data[24].JUL}`),
          AGO: maskToMoney(`${data[24].AGO}`),
          SET: maskToMoney(`${data[24].SET}`),
          OUT: maskToMoney(`${data[24].OUT}`),
          NOV: maskToMoney(`${data[24].NOV}`),
          DEZ: maskToMoney(`${data[24].DEZ}`),
        },
        CEQ03_MANUTENCAO: {
          JAN: maskToMoney(`${data[25].JAN}`),
          FEV: maskToMoney(`${data[25].FEV}`),
          MAR: maskToMoney(`${data[25].MAR}`),
          ABR: maskToMoney(`${data[25].ABR}`),
          MAI: maskToMoney(`${data[25].MAI}`),
          JUN: maskToMoney(`${data[25].JUN}`),
          JUL: maskToMoney(`${data[25].JUL}`),
          AGO: maskToMoney(`${data[25].AGO}`),
          SET: maskToMoney(`${data[25].SET}`),
          OUT: maskToMoney(`${data[25].OUT}`),
          NOV: maskToMoney(`${data[25].NOV}`),
          DEZ: maskToMoney(`${data[25].DEZ}`),
        },
        CEQ04_OUTROS: {
          JAN: maskToMoney(`${data[26].JAN}`),
          FEV: maskToMoney(`${data[26].FEV}`),
          MAR: maskToMoney(`${data[26].MAR}`),
          ABR: maskToMoney(`${data[26].ABR}`),
          MAI: maskToMoney(`${data[26].MAI}`),
          JUN: maskToMoney(`${data[26].JUN}`),
          JUL: maskToMoney(`${data[26].JUL}`),
          AGO: maskToMoney(`${data[26].AGO}`),
          SET: maskToMoney(`${data[26].SET}`),
          OUT: maskToMoney(`${data[26].OUT}`),
          NOV: maskToMoney(`${data[26].NOV}`),
          DEZ: maskToMoney(`${data[26].DEZ}`),
        },
        CMA01_DIVERSOS: {
          JAN: maskToMoney(`${data[27].JAN}`),
          FEV: maskToMoney(`${data[27].FEV}`),
          MAR: maskToMoney(`${data[27].MAR}`),
          ABR: maskToMoney(`${data[27].ABR}`),
          MAI: maskToMoney(`${data[27].MAI}`),
          JUN: maskToMoney(`${data[27].JUN}`),
          JUL: maskToMoney(`${data[27].JUL}`),
          AGO: maskToMoney(`${data[27].AGO}`),
          SET: maskToMoney(`${data[27].SET}`),
          OUT: maskToMoney(`${data[27].OUT}`),
          NOV: maskToMoney(`${data[27].NOV}`),
          DEZ: maskToMoney(`${data[27].DEZ}`),
        },
        CMA02_CIVIL: {
          JAN: maskToMoney(`${data[28].JAN}`),
          FEV: maskToMoney(`${data[28].FEV}`),
          MAR: maskToMoney(`${data[28].MAR}`),
          ABR: maskToMoney(`${data[28].ABR}`),
          MAI: maskToMoney(`${data[28].MAI}`),
          JUN: maskToMoney(`${data[28].JUN}`),
          JUL: maskToMoney(`${data[28].JUL}`),
          AGO: maskToMoney(`${data[28].AGO}`),
          SET: maskToMoney(`${data[28].SET}`),
          OUT: maskToMoney(`${data[28].OUT}`),
          NOV: maskToMoney(`${data[28].NOV}`),
          DEZ: maskToMoney(`${data[28].DEZ}`),
        },
        CMA03_TUBULACAO: {
          JAN: maskToMoney(`${data[29].JAN}`),
          FEV: maskToMoney(`${data[29].FEV}`),
          MAR: maskToMoney(`${data[29].MAR}`),
          ABR: maskToMoney(`${data[29].ABR}`),
          MAI: maskToMoney(`${data[29].MAI}`),
          JUN: maskToMoney(`${data[29].JUN}`),
          JUL: maskToMoney(`${data[29].JUL}`),
          AGO: maskToMoney(`${data[29].AGO}`),
          SET: maskToMoney(`${data[29].SET}`),
          OUT: maskToMoney(`${data[29].OUT}`),
          NOV: maskToMoney(`${data[29].NOV}`),
          DEZ: maskToMoney(`${data[29].DEZ}`),
        },
        CMA04_CALDEIRARIA_ESTRUTURA: {
          JAN: maskToMoney(`${data[30].JAN}`),
          FEV: maskToMoney(`${data[30].FEV}`),
          MAR: maskToMoney(`${data[30].MAR}`),
          ABR: maskToMoney(`${data[30].ABR}`),
          MAI: maskToMoney(`${data[30].MAI}`),
          JUN: maskToMoney(`${data[30].JUN}`),
          JUL: maskToMoney(`${data[30].JUL}`),
          AGO: maskToMoney(`${data[30].AGO}`),
          SET: maskToMoney(`${data[30].SET}`),
          OUT: maskToMoney(`${data[30].OUT}`),
          NOV: maskToMoney(`${data[30].NOV}`),
          DEZ: maskToMoney(`${data[30].DEZ}`),
        },
        CMA05_ELETRICA_INSTRUMENTACAO: {
          JAN: maskToMoney(`${data[31].JAN}`),
          FEV: maskToMoney(`${data[31].FEV}`),
          MAR: maskToMoney(`${data[31].MAR}`),
          ABR: maskToMoney(`${data[31].ABR}`),
          MAI: maskToMoney(`${data[31].MAI}`),
          JUN: maskToMoney(`${data[31].JUN}`),
          JUL: maskToMoney(`${data[31].JUL}`),
          AGO: maskToMoney(`${data[31].AGO}`),
          SET: maskToMoney(`${data[31].SET}`),
          OUT: maskToMoney(`${data[31].OUT}`),
          NOV: maskToMoney(`${data[31].NOV}`),
          DEZ: maskToMoney(`${data[31].DEZ}`),
        },
        CMA06_ISOLAMENTO: {
          JAN: maskToMoney(`${data[32].JAN}`),
          FEV: maskToMoney(`${data[32].FEV}`),
          MAR: maskToMoney(`${data[32].MAR}`),
          ABR: maskToMoney(`${data[32].ABR}`),
          MAI: maskToMoney(`${data[32].MAI}`),
          JUN: maskToMoney(`${data[32].JUN}`),
          JUL: maskToMoney(`${data[32].JUL}`),
          AGO: maskToMoney(`${data[32].AGO}`),
          SET: maskToMoney(`${data[32].SET}`),
          OUT: maskToMoney(`${data[32].OUT}`),
          NOV: maskToMoney(`${data[32].NOV}`),
          DEZ: maskToMoney(`${data[32].DEZ}`),
        },
        CMA07_PINTURA: {
          JAN: maskToMoney(`${data[33].JAN}`),
          FEV: maskToMoney(`${data[33].FEV}`),
          MAR: maskToMoney(`${data[33].MAR}`),
          ABR: maskToMoney(`${data[33].ABR}`),
          MAI: maskToMoney(`${data[33].MAI}`),
          JUN: maskToMoney(`${data[33].JUN}`),
          JUL: maskToMoney(`${data[33].JUL}`),
          AGO: maskToMoney(`${data[33].AGO}`),
          SET: maskToMoney(`${data[33].SET}`),
          OUT: maskToMoney(`${data[33].OUT}`),
          NOV: maskToMoney(`${data[33].NOV}`),
          DEZ: maskToMoney(`${data[33].DEZ}`),
        },
        CMC01_MATERIAL_DE_CONSUMO: {
          JAN: maskToMoney(`${data[34].JAN}`),
          FEV: maskToMoney(`${data[34].FEV}`),
          MAR: maskToMoney(`${data[34].MAR}`),
          ABR: maskToMoney(`${data[34].ABR}`),
          MAI: maskToMoney(`${data[34].MAI}`),
          JUN: maskToMoney(`${data[34].JUN}`),
          JUL: maskToMoney(`${data[34].JUL}`),
          AGO: maskToMoney(`${data[34].AGO}`),
          SET: maskToMoney(`${data[34].SET}`),
          OUT: maskToMoney(`${data[34].OUT}`),
          NOV: maskToMoney(`${data[34].NOV}`),
          DEZ: maskToMoney(`${data[34].DEZ}`),
        },
        CMC02_GASES: {
          JAN: maskToMoney(`${data[35].JAN}`),
          FEV: maskToMoney(`${data[35].FEV}`),
          MAR: maskToMoney(`${data[35].MAR}`),
          ABR: maskToMoney(`${data[35].ABR}`),
          MAI: maskToMoney(`${data[35].MAI}`),
          JUN: maskToMoney(`${data[35].JUN}`),
          JUL: maskToMoney(`${data[35].JUL}`),
          AGO: maskToMoney(`${data[35].AGO}`),
          SET: maskToMoney(`${data[35].SET}`),
          OUT: maskToMoney(`${data[35].OUT}`),
          NOV: maskToMoney(`${data[35].NOV}`),
          DEZ: maskToMoney(`${data[35].DEZ}`),
        },
        CMC03_ELETRODOS_E_VARETAS: {
          JAN: maskToMoney(`${data[36].JAN}`),
          FEV: maskToMoney(`${data[36].FEV}`),
          MAR: maskToMoney(`${data[36].MAR}`),
          ABR: maskToMoney(`${data[36].ABR}`),
          MAI: maskToMoney(`${data[36].MAI}`),
          JUN: maskToMoney(`${data[36].JUN}`),
          JUL: maskToMoney(`${data[36].JUL}`),
          AGO: maskToMoney(`${data[36].AGO}`),
          SET: maskToMoney(`${data[36].SET}`),
          OUT: maskToMoney(`${data[36].OUT}`),
          NOV: maskToMoney(`${data[36].NOV}`),
          DEZ: maskToMoney(`${data[36].DEZ}`),
        },
        CMV01_MERC_VEND: {
          JAN: maskToMoney(`${data[37].JAN}`),
          FEV: maskToMoney(`${data[37].FEV}`),
          MAR: maskToMoney(`${data[37].MAR}`),
          ABR: maskToMoney(`${data[37].ABR}`),
          MAI: maskToMoney(`${data[37].MAI}`),
          JUN: maskToMoney(`${data[37].JUN}`),
          JUL: maskToMoney(`${data[37].JUL}`),
          AGO: maskToMoney(`${data[37].AGO}`),
          SET: maskToMoney(`${data[37].SET}`),
          OUT: maskToMoney(`${data[37].OUT}`),
          NOV: maskToMoney(`${data[37].NOV}`),
          DEZ: maskToMoney(`${data[37].DEZ}`),
        },
        CSB01_DIVERSOS: {
          JAN: maskToMoney(`${data[38].JAN}`),
          FEV: maskToMoney(`${data[38].FEV}`),
          MAR: maskToMoney(`${data[38].MAR}`),
          ABR: maskToMoney(`${data[38].ABR}`),
          MAI: maskToMoney(`${data[38].MAI}`),
          JUN: maskToMoney(`${data[38].JUN}`),
          JUL: maskToMoney(`${data[38].JUL}`),
          AGO: maskToMoney(`${data[38].AGO}`),
          SET: maskToMoney(`${data[38].SET}`),
          OUT: maskToMoney(`${data[38].OUT}`),
          NOV: maskToMoney(`${data[38].NOV}`),
          DEZ: maskToMoney(`${data[38].DEZ}`),
        },
        CSB02_CIVIL: {
          JAN: maskToMoney(`${data[39].JAN}`),
          FEV: maskToMoney(`${data[39].FEV}`),
          MAR: maskToMoney(`${data[39].MAR}`),
          ABR: maskToMoney(`${data[39].ABR}`),
          MAI: maskToMoney(`${data[39].MAI}`),
          JUN: maskToMoney(`${data[39].JUN}`),
          JUL: maskToMoney(`${data[39].JUL}`),
          AGO: maskToMoney(`${data[39].AGO}`),
          SET: maskToMoney(`${data[39].SET}`),
          OUT: maskToMoney(`${data[39].OUT}`),
          NOV: maskToMoney(`${data[39].NOV}`),
          DEZ: maskToMoney(`${data[39].DEZ}`),
        },
        CSB03_TUBULACAO: {
          JAN: maskToMoney(`${data[40].JAN}`),
          FEV: maskToMoney(`${data[40].FEV}`),
          MAR: maskToMoney(`${data[40].MAR}`),
          ABR: maskToMoney(`${data[40].ABR}`),
          MAI: maskToMoney(`${data[40].MAI}`),
          JUN: maskToMoney(`${data[40].JUN}`),
          JUL: maskToMoney(`${data[40].JUL}`),
          AGO: maskToMoney(`${data[40].AGO}`),
          SET: maskToMoney(`${data[40].SET}`),
          OUT: maskToMoney(`${data[40].OUT}`),
          NOV: maskToMoney(`${data[40].NOV}`),
          DEZ: maskToMoney(`${data[40].DEZ}`),
        },
        CSB04_CALDEIRARIA_ESTRUTURA: {
          JAN: maskToMoney(`${data[41].JAN}`),
          FEV: maskToMoney(`${data[41].FEV}`),
          MAR: maskToMoney(`${data[41].MAR}`),
          ABR: maskToMoney(`${data[41].ABR}`),
          MAI: maskToMoney(`${data[41].MAI}`),
          JUN: maskToMoney(`${data[41].JUN}`),
          JUL: maskToMoney(`${data[41].JUL}`),
          AGO: maskToMoney(`${data[41].AGO}`),
          SET: maskToMoney(`${data[41].SET}`),
          OUT: maskToMoney(`${data[41].OUT}`),
          NOV: maskToMoney(`${data[41].NOV}`),
          DEZ: maskToMoney(`${data[41].DEZ}`),
        },
        CSB05_ELETRICA_INSTRUMENTACAO: {
          JAN: maskToMoney(`${data[42].JAN}`),
          FEV: maskToMoney(`${data[42].FEV}`),
          MAR: maskToMoney(`${data[42].MAR}`),
          ABR: maskToMoney(`${data[42].ABR}`),
          MAI: maskToMoney(`${data[42].MAI}`),
          JUN: maskToMoney(`${data[42].JUN}`),
          JUL: maskToMoney(`${data[42].JUL}`),
          AGO: maskToMoney(`${data[42].AGO}`),
          SET: maskToMoney(`${data[42].SET}`),
          OUT: maskToMoney(`${data[42].OUT}`),
          NOV: maskToMoney(`${data[42].NOV}`),
          DEZ: maskToMoney(`${data[42].DEZ}`),
        },
        CSB06_ISOLAMENTO: {
          JAN: maskToMoney(`${data[43].JAN}`),
          FEV: maskToMoney(`${data[43].FEV}`),
          MAR: maskToMoney(`${data[43].MAR}`),
          ABR: maskToMoney(`${data[43].ABR}`),
          MAI: maskToMoney(`${data[43].MAI}`),
          JUN: maskToMoney(`${data[43].JUN}`),
          JUL: maskToMoney(`${data[43].JUL}`),
          AGO: maskToMoney(`${data[43].AGO}`),
          SET: maskToMoney(`${data[43].SET}`),
          OUT: maskToMoney(`${data[43].OUT}`),
          NOV: maskToMoney(`${data[43].NOV}`),
          DEZ: maskToMoney(`${data[43].DEZ}`),
        },
        CSB07_PINTURA: {
          JAN: maskToMoney(`${data[44].JAN}`),
          FEV: maskToMoney(`${data[44].FEV}`),
          MAR: maskToMoney(`${data[44].MAR}`),
          ABR: maskToMoney(`${data[44].ABR}`),
          MAI: maskToMoney(`${data[44].MAI}`),
          JUN: maskToMoney(`${data[44].JUN}`),
          JUL: maskToMoney(`${data[44].JUL}`),
          AGO: maskToMoney(`${data[44].AGO}`),
          SET: maskToMoney(`${data[44].SET}`),
          OUT: maskToMoney(`${data[44].OUT}`),
          NOV: maskToMoney(`${data[44].NOV}`),
          DEZ: maskToMoney(`${data[44].DEZ}`),
        },
        CDG01_TAXAS_DIVERSAS: {
          JAN: maskToMoney(`${data[45].JAN}`),
          FEV: maskToMoney(`${data[45].FEV}`),
          MAR: maskToMoney(`${data[45].MAR}`),
          ABR: maskToMoney(`${data[45].ABR}`),
          MAI: maskToMoney(`${data[45].MAI}`),
          JUN: maskToMoney(`${data[45].JUN}`),
          JUL: maskToMoney(`${data[45].JUL}`),
          AGO: maskToMoney(`${data[45].AGO}`),
          SET: maskToMoney(`${data[45].SET}`),
          OUT: maskToMoney(`${data[45].OUT}`),
          NOV: maskToMoney(`${data[45].NOV}`),
          DEZ: maskToMoney(`${data[45].DEZ}`),
        },
        CDG02_PREMIACOES_PATROCINIO: {
          JAN: maskToMoney(`${data[46].JAN}`),
          FEV: maskToMoney(`${data[46].FEV}`),
          MAR: maskToMoney(`${data[46].MAR}`),
          ABR: maskToMoney(`${data[46].ABR}`),
          MAI: maskToMoney(`${data[46].MAI}`),
          JUN: maskToMoney(`${data[46].JUN}`),
          JUL: maskToMoney(`${data[46].JUL}`),
          AGO: maskToMoney(`${data[46].AGO}`),
          SET: maskToMoney(`${data[46].SET}`),
          OUT: maskToMoney(`${data[46].OUT}`),
          NOV: maskToMoney(`${data[46].NOV}`),
          DEZ: maskToMoney(`${data[46].DEZ}`),
        },
        CDG03_SEGUROS_MULTAS_JUROS: {
          JAN: maskToMoney(`${data[47].JAN}`),
          FEV: maskToMoney(`${data[47].FEV}`),
          MAR: maskToMoney(`${data[47].MAR}`),
          ABR: maskToMoney(`${data[47].ABR}`),
          MAI: maskToMoney(`${data[47].MAI}`),
          JUN: maskToMoney(`${data[47].JUN}`),
          JUL: maskToMoney(`${data[47].JUL}`),
          AGO: maskToMoney(`${data[47].AGO}`),
          SET: maskToMoney(`${data[47].SET}`),
          OUT: maskToMoney(`${data[47].OUT}`),
          NOV: maskToMoney(`${data[47].NOV}`),
          DEZ: maskToMoney(`${data[47].DEZ}`),
        },
        CDG04_OUTROS: {
          JAN: maskToMoney(`${data[48].JAN}`),
          FEV: maskToMoney(`${data[48].FEV}`),
          MAR: maskToMoney(`${data[48].MAR}`),
          ABR: maskToMoney(`${data[48].ABR}`),
          MAI: maskToMoney(`${data[48].MAI}`),
          JUN: maskToMoney(`${data[48].JUN}`),
          JUL: maskToMoney(`${data[48].JUL}`),
          AGO: maskToMoney(`${data[48].AGO}`),
          SET: maskToMoney(`${data[48].SET}`),
          OUT: maskToMoney(`${data[48].OUT}`),
          NOV: maskToMoney(`${data[48].NOV}`),
          DEZ: maskToMoney(`${data[48].DEZ}`),
        },
        CRC01_CUSTOS_DE_MOD: {
          JAN: maskToMoney(`${data[49].JAN}`),
          FEV: maskToMoney(`${data[49].FEV}`),
          MAR: maskToMoney(`${data[49].MAR}`),
          ABR: maskToMoney(`${data[49].ABR}`),
          MAI: maskToMoney(`${data[49].MAI}`),
          JUN: maskToMoney(`${data[49].JUN}`),
          JUL: maskToMoney(`${data[49].JUL}`),
          AGO: maskToMoney(`${data[49].AGO}`),
          SET: maskToMoney(`${data[49].SET}`),
          OUT: maskToMoney(`${data[49].OUT}`),
          NOV: maskToMoney(`${data[49].NOV}`),
          DEZ: maskToMoney(`${data[49].DEZ}`),
        },
        CRC02_CUSTOS_DE_MOI: {
          JAN: maskToMoney(`${data[50].JAN}`),
          FEV: maskToMoney(`${data[50].FEV}`),
          MAR: maskToMoney(`${data[50].MAR}`),
          ABR: maskToMoney(`${data[50].ABR}`),
          MAI: maskToMoney(`${data[50].MAI}`),
          JUN: maskToMoney(`${data[50].JUN}`),
          JUL: maskToMoney(`${data[50].JUL}`),
          AGO: maskToMoney(`${data[50].AGO}`),
          SET: maskToMoney(`${data[50].SET}`),
          OUT: maskToMoney(`${data[50].OUT}`),
          NOV: maskToMoney(`${data[50].NOV}`),
          DEZ: maskToMoney(`${data[50].DEZ}`),
        },
        CRC03_CUSTOS_DE_APOIO: {
          JAN: maskToMoney(`${data[51].JAN}`),
          FEV: maskToMoney(`${data[51].FEV}`),
          MAR: maskToMoney(`${data[51].MAR}`),
          ABR: maskToMoney(`${data[51].ABR}`),
          MAI: maskToMoney(`${data[51].MAI}`),
          JUN: maskToMoney(`${data[51].JUN}`),
          JUL: maskToMoney(`${data[51].JUL}`),
          AGO: maskToMoney(`${data[51].AGO}`),
          SET: maskToMoney(`${data[51].SET}`),
          OUT: maskToMoney(`${data[51].OUT}`),
          NOV: maskToMoney(`${data[51].NOV}`),
          DEZ: maskToMoney(`${data[51].DEZ}`),
        },
        CRC04_CUSTOS_DE_MOBILIZACAO: {
          JAN: maskToMoney(`${data[52].JAN}`),
          FEV: maskToMoney(`${data[52].FEV}`),
          MAR: maskToMoney(`${data[52].MAR}`),
          ABR: maskToMoney(`${data[52].ABR}`),
          MAI: maskToMoney(`${data[52].MAI}`),
          JUN: maskToMoney(`${data[52].JUN}`),
          JUL: maskToMoney(`${data[52].JUL}`),
          AGO: maskToMoney(`${data[52].AGO}`),
          SET: maskToMoney(`${data[52].SET}`),
          OUT: maskToMoney(`${data[52].OUT}`),
          NOV: maskToMoney(`${data[52].NOV}`),
          DEZ: maskToMoney(`${data[52].DEZ}`),
        },
        CRC05_CUSTOS_DE_CANTEIRO: {
          JAN: maskToMoney(`${data[53].JAN}`),
          FEV: maskToMoney(`${data[53].FEV}`),
          MAR: maskToMoney(`${data[53].MAR}`),
          ABR: maskToMoney(`${data[53].ABR}`),
          MAI: maskToMoney(`${data[53].MAI}`),
          JUN: maskToMoney(`${data[53].JUN}`),
          JUL: maskToMoney(`${data[53].JUL}`),
          AGO: maskToMoney(`${data[53].AGO}`),
          SET: maskToMoney(`${data[53].SET}`),
          OUT: maskToMoney(`${data[53].OUT}`),
          NOV: maskToMoney(`${data[53].NOV}`),
          DEZ: maskToMoney(`${data[53].DEZ}`),
        },
        CRC06_CUSTOS_DE_EQUIPAMENTOS: {
          JAN: maskToMoney(`${data[54].JAN}`),
          FEV: maskToMoney(`${data[54].FEV}`),
          MAR: maskToMoney(`${data[54].MAR}`),
          ABR: maskToMoney(`${data[54].ABR}`),
          MAI: maskToMoney(`${data[54].MAI}`),
          JUN: maskToMoney(`${data[54].JUN}`),
          JUL: maskToMoney(`${data[54].JUL}`),
          AGO: maskToMoney(`${data[54].AGO}`),
          SET: maskToMoney(`${data[54].SET}`),
          OUT: maskToMoney(`${data[54].OUT}`),
          NOV: maskToMoney(`${data[54].NOV}`),
          DEZ: maskToMoney(`${data[54].DEZ}`),
        },
        CRC07_CUSTOS_DE_MATERIAIS: {
          JAN: maskToMoney(`${data[55].JAN}`),
          FEV: maskToMoney(`${data[55].FEV}`),
          MAR: maskToMoney(`${data[55].MAR}`),
          ABR: maskToMoney(`${data[55].ABR}`),
          MAI: maskToMoney(`${data[55].MAI}`),
          JUN: maskToMoney(`${data[55].JUN}`),
          JUL: maskToMoney(`${data[55].JUL}`),
          AGO: maskToMoney(`${data[55].AGO}`),
          SET: maskToMoney(`${data[55].SET}`),
          OUT: maskToMoney(`${data[55].OUT}`),
          NOV: maskToMoney(`${data[55].NOV}`),
          DEZ: maskToMoney(`${data[55].DEZ}`),
        },
        CRC08_CUSTOS_DE_SUBCONTRATOS: {
          JAN: maskToMoney(`${data[56].JAN}`),
          FEV: maskToMoney(`${data[56].FEV}`),
          MAR: maskToMoney(`${data[56].MAR}`),
          ABR: maskToMoney(`${data[56].ABR}`),
          MAI: maskToMoney(`${data[56].MAI}`),
          JUN: maskToMoney(`${data[56].JUN}`),
          JUL: maskToMoney(`${data[56].JUL}`),
          AGO: maskToMoney(`${data[56].AGO}`),
          SET: maskToMoney(`${data[56].SET}`),
          OUT: maskToMoney(`${data[56].OUT}`),
          NOV: maskToMoney(`${data[56].NOV}`),
          DEZ: maskToMoney(`${data[56].DEZ}`),
        },
        CRC09_CUSTOS_FINANCEIROS: {
          JAN: maskToMoney(`${data[57].JAN}`),
          FEV: maskToMoney(`${data[57].FEV}`),
          MAR: maskToMoney(`${data[57].MAR}`),
          ABR: maskToMoney(`${data[57].ABR}`),
          MAI: maskToMoney(`${data[57].MAI}`),
          JUN: maskToMoney(`${data[57].JUN}`),
          JUL: maskToMoney(`${data[57].JUL}`),
          AGO: maskToMoney(`${data[57].AGO}`),
          SET: maskToMoney(`${data[57].SET}`),
          OUT: maskToMoney(`${data[57].OUT}`),
          NOV: maskToMoney(`${data[57].NOV}`),
          DEZ: maskToMoney(`${data[57].DEZ}`),
        },
        CRC10_OUTROS: {
          JAN: maskToMoney(`${data[58].JAN}`),
          FEV: maskToMoney(`${data[58].FEV}`),
          MAR: maskToMoney(`${data[58].MAR}`),
          ABR: maskToMoney(`${data[58].ABR}`),
          MAI: maskToMoney(`${data[58].MAI}`),
          JUN: maskToMoney(`${data[58].JUN}`),
          JUL: maskToMoney(`${data[58].JUL}`),
          AGO: maskToMoney(`${data[58].AGO}`),
          SET: maskToMoney(`${data[58].SET}`),
          OUT: maskToMoney(`${data[58].OUT}`),
          NOV: maskToMoney(`${data[58].NOV}`),
          DEZ: maskToMoney(`${data[58].DEZ}`),
        },
      };

      data.forEach((invoicing) => {
        if (invoicing.TIPO.substring(0, 3) === 'CMD') {
          sum_CMD.JAN = sum_CMD.JAN + maskToFloat2(invoicing.JAN);
          sum_CMD.FEV = sum_CMD.FEV + maskToFloat2(invoicing.FEV);
          sum_CMD.MAR = sum_CMD.MAR + maskToFloat2(invoicing.MAR);
          sum_CMD.ABR = sum_CMD.ABR + maskToFloat2(invoicing.ABR);
          sum_CMD.MAI = sum_CMD.MAI + maskToFloat2(invoicing.MAI);
          sum_CMD.JUN = sum_CMD.JUN + maskToFloat2(invoicing.JUN);
          sum_CMD.JUL = sum_CMD.JUL + maskToFloat2(invoicing.JUL);
          sum_CMD.AGO = sum_CMD.AGO + maskToFloat2(invoicing.AGO);
          sum_CMD.SET = sum_CMD.SET + maskToFloat2(invoicing.SET);
          sum_CMD.OUT = sum_CMD.OUT + maskToFloat2(invoicing.OUT);
          sum_CMD.NOV = sum_CMD.NOV + maskToFloat2(invoicing.NOV);
          sum_CMD.DEZ = sum_CMD.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CMI') {
          sum_CMI.JAN = sum_CMI.JAN + maskToFloat2(invoicing.JAN);
          sum_CMI.FEV = sum_CMI.FEV + maskToFloat2(invoicing.FEV);
          sum_CMI.MAR = sum_CMI.MAR + maskToFloat2(invoicing.MAR);
          sum_CMI.ABR = sum_CMI.ABR + maskToFloat2(invoicing.ABR);
          sum_CMI.MAI = sum_CMI.MAI + maskToFloat2(invoicing.MAI);
          sum_CMI.JUN = sum_CMI.JUN + maskToFloat2(invoicing.JUN);
          sum_CMI.JUL = sum_CMI.JUL + maskToFloat2(invoicing.JUL);
          sum_CMI.AGO = sum_CMI.AGO + maskToFloat2(invoicing.AGO);
          sum_CMI.SET = sum_CMI.SET + maskToFloat2(invoicing.SET);
          sum_CMI.OUT = sum_CMI.OUT + maskToFloat2(invoicing.OUT);
          sum_CMI.NOV = sum_CMI.NOV + maskToFloat2(invoicing.NOV);
          sum_CMI.DEZ = sum_CMI.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CAP') {
          sum_CAP.JAN = sum_CAP.JAN + maskToFloat2(invoicing.JAN);
          sum_CAP.FEV = sum_CAP.FEV + maskToFloat2(invoicing.FEV);
          sum_CAP.MAR = sum_CAP.MAR + maskToFloat2(invoicing.MAR);
          sum_CAP.ABR = sum_CAP.ABR + maskToFloat2(invoicing.ABR);
          sum_CAP.MAI = sum_CAP.MAI + maskToFloat2(invoicing.MAI);
          sum_CAP.JUN = sum_CAP.JUN + maskToFloat2(invoicing.JUN);
          sum_CAP.JUL = sum_CAP.JUL + maskToFloat2(invoicing.JUL);
          sum_CAP.AGO = sum_CAP.AGO + maskToFloat2(invoicing.AGO);
          sum_CAP.SET = sum_CAP.SET + maskToFloat2(invoicing.SET);
          sum_CAP.OUT = sum_CAP.OUT + maskToFloat2(invoicing.OUT);
          sum_CAP.NOV = sum_CAP.NOV + maskToFloat2(invoicing.NOV);
          sum_CAP.DEZ = sum_CAP.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CMM') {
          sum_CMM.JAN = sum_CMM.JAN + maskToFloat2(invoicing.JAN);
          sum_CMM.FEV = sum_CMM.FEV + maskToFloat2(invoicing.FEV);
          sum_CMM.MAR = sum_CMM.MAR + maskToFloat2(invoicing.MAR);
          sum_CMM.ABR = sum_CMM.ABR + maskToFloat2(invoicing.ABR);
          sum_CMM.MAI = sum_CMM.MAI + maskToFloat2(invoicing.MAI);
          sum_CMM.JUN = sum_CMM.JUN + maskToFloat2(invoicing.JUN);
          sum_CMM.JUL = sum_CMM.JUL + maskToFloat2(invoicing.JUL);
          sum_CMM.AGO = sum_CMM.AGO + maskToFloat2(invoicing.AGO);
          sum_CMM.SET = sum_CMM.SET + maskToFloat2(invoicing.SET);
          sum_CMM.OUT = sum_CMM.OUT + maskToFloat2(invoicing.OUT);
          sum_CMM.NOV = sum_CMM.NOV + maskToFloat2(invoicing.NOV);
          sum_CMM.DEZ = sum_CMM.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CCE') {
          sum_CCE.JAN = sum_CCE.JAN + maskToFloat2(invoicing.JAN);
          sum_CCE.FEV = sum_CCE.FEV + maskToFloat2(invoicing.FEV);
          sum_CCE.MAR = sum_CCE.MAR + maskToFloat2(invoicing.MAR);
          sum_CCE.ABR = sum_CCE.ABR + maskToFloat2(invoicing.ABR);
          sum_CCE.MAI = sum_CCE.MAI + maskToFloat2(invoicing.MAI);
          sum_CCE.JUN = sum_CCE.JUN + maskToFloat2(invoicing.JUN);
          sum_CCE.JUL = sum_CCE.JUL + maskToFloat2(invoicing.JUL);
          sum_CCE.AGO = sum_CCE.AGO + maskToFloat2(invoicing.AGO);
          sum_CCE.SET = sum_CCE.SET + maskToFloat2(invoicing.SET);
          sum_CCE.OUT = sum_CCE.OUT + maskToFloat2(invoicing.OUT);
          sum_CCE.NOV = sum_CCE.NOV + maskToFloat2(invoicing.NOV);
          sum_CCE.DEZ = sum_CCE.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CEQ') {
          sum_CEQ.JAN = sum_CEQ.JAN + maskToFloat2(invoicing.JAN);
          sum_CEQ.FEV = sum_CEQ.FEV + maskToFloat2(invoicing.FEV);
          sum_CEQ.MAR = sum_CEQ.MAR + maskToFloat2(invoicing.MAR);
          sum_CEQ.ABR = sum_CEQ.ABR + maskToFloat2(invoicing.ABR);
          sum_CEQ.MAI = sum_CEQ.MAI + maskToFloat2(invoicing.MAI);
          sum_CEQ.JUN = sum_CEQ.JUN + maskToFloat2(invoicing.JUN);
          sum_CEQ.JUL = sum_CEQ.JUL + maskToFloat2(invoicing.JUL);
          sum_CEQ.AGO = sum_CEQ.AGO + maskToFloat2(invoicing.AGO);
          sum_CEQ.SET = sum_CEQ.SET + maskToFloat2(invoicing.SET);
          sum_CEQ.OUT = sum_CEQ.OUT + maskToFloat2(invoicing.OUT);
          sum_CEQ.NOV = sum_CEQ.NOV + maskToFloat2(invoicing.NOV);
          sum_CEQ.DEZ = sum_CEQ.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CMA') {
          sum_CMA.JAN = sum_CMA.JAN + maskToFloat2(invoicing.JAN);
          sum_CMA.FEV = sum_CMA.FEV + maskToFloat2(invoicing.FEV);
          sum_CMA.MAR = sum_CMA.MAR + maskToFloat2(invoicing.MAR);
          sum_CMA.ABR = sum_CMA.ABR + maskToFloat2(invoicing.ABR);
          sum_CMA.MAI = sum_CMA.MAI + maskToFloat2(invoicing.MAI);
          sum_CMA.JUN = sum_CMA.JUN + maskToFloat2(invoicing.JUN);
          sum_CMA.JUL = sum_CMA.JUL + maskToFloat2(invoicing.JUL);
          sum_CMA.AGO = sum_CMA.AGO + maskToFloat2(invoicing.AGO);
          sum_CMA.SET = sum_CMA.SET + maskToFloat2(invoicing.SET);
          sum_CMA.OUT = sum_CMA.OUT + maskToFloat2(invoicing.OUT);
          sum_CMA.NOV = sum_CMA.NOV + maskToFloat2(invoicing.NOV);
          sum_CMA.DEZ = sum_CMA.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CMC') {
          sum_CMC.JAN = sum_CMC.JAN + maskToFloat2(invoicing.JAN);
          sum_CMC.FEV = sum_CMC.FEV + maskToFloat2(invoicing.FEV);
          sum_CMC.MAR = sum_CMC.MAR + maskToFloat2(invoicing.MAR);
          sum_CMC.ABR = sum_CMC.ABR + maskToFloat2(invoicing.ABR);
          sum_CMC.MAI = sum_CMC.MAI + maskToFloat2(invoicing.MAI);
          sum_CMC.JUN = sum_CMC.JUN + maskToFloat2(invoicing.JUN);
          sum_CMC.JUL = sum_CMC.JUL + maskToFloat2(invoicing.JUL);
          sum_CMC.AGO = sum_CMC.AGO + maskToFloat2(invoicing.AGO);
          sum_CMC.SET = sum_CMC.SET + maskToFloat2(invoicing.SET);
          sum_CMC.OUT = sum_CMC.OUT + maskToFloat2(invoicing.OUT);
          sum_CMC.NOV = sum_CMC.NOV + maskToFloat2(invoicing.NOV);
          sum_CMC.DEZ = sum_CMC.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CMV') {
          sum_CMV.JAN = sum_CMV.JAN + maskToFloat2(invoicing.JAN);
          sum_CMV.FEV = sum_CMV.FEV + maskToFloat2(invoicing.FEV);
          sum_CMV.MAR = sum_CMV.MAR + maskToFloat2(invoicing.MAR);
          sum_CMV.ABR = sum_CMV.ABR + maskToFloat2(invoicing.ABR);
          sum_CMV.MAI = sum_CMV.MAI + maskToFloat2(invoicing.MAI);
          sum_CMV.JUN = sum_CMV.JUN + maskToFloat2(invoicing.JUN);
          sum_CMV.JUL = sum_CMV.JUL + maskToFloat2(invoicing.JUL);
          sum_CMV.AGO = sum_CMV.AGO + maskToFloat2(invoicing.AGO);
          sum_CMV.SET = sum_CMV.SET + maskToFloat2(invoicing.SET);
          sum_CMV.OUT = sum_CMV.OUT + maskToFloat2(invoicing.OUT);
          sum_CMV.NOV = sum_CMV.NOV + maskToFloat2(invoicing.NOV);
          sum_CMV.DEZ = sum_CMV.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CSB') {
          sum_CSB.JAN = sum_CSB.JAN + maskToFloat2(invoicing.JAN);
          sum_CSB.FEV = sum_CSB.FEV + maskToFloat2(invoicing.FEV);
          sum_CSB.MAR = sum_CSB.MAR + maskToFloat2(invoicing.MAR);
          sum_CSB.ABR = sum_CSB.ABR + maskToFloat2(invoicing.ABR);
          sum_CSB.MAI = sum_CSB.MAI + maskToFloat2(invoicing.MAI);
          sum_CSB.JUN = sum_CSB.JUN + maskToFloat2(invoicing.JUN);
          sum_CSB.JUL = sum_CSB.JUL + maskToFloat2(invoicing.JUL);
          sum_CSB.AGO = sum_CSB.AGO + maskToFloat2(invoicing.AGO);
          sum_CSB.SET = sum_CSB.SET + maskToFloat2(invoicing.SET);
          sum_CSB.OUT = sum_CSB.OUT + maskToFloat2(invoicing.OUT);
          sum_CSB.NOV = sum_CSB.NOV + maskToFloat2(invoicing.NOV);
          sum_CSB.DEZ = sum_CSB.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CDG') {
          sum_CDG.JAN = sum_CDG.JAN + maskToFloat2(invoicing.JAN);
          sum_CDG.FEV = sum_CDG.FEV + maskToFloat2(invoicing.FEV);
          sum_CDG.MAR = sum_CDG.MAR + maskToFloat2(invoicing.MAR);
          sum_CDG.ABR = sum_CDG.ABR + maskToFloat2(invoicing.ABR);
          sum_CDG.MAI = sum_CDG.MAI + maskToFloat2(invoicing.MAI);
          sum_CDG.JUN = sum_CDG.JUN + maskToFloat2(invoicing.JUN);
          sum_CDG.JUL = sum_CDG.JUL + maskToFloat2(invoicing.JUL);
          sum_CDG.AGO = sum_CDG.AGO + maskToFloat2(invoicing.AGO);
          sum_CDG.SET = sum_CDG.SET + maskToFloat2(invoicing.SET);
          sum_CDG.OUT = sum_CDG.OUT + maskToFloat2(invoicing.OUT);
          sum_CDG.NOV = sum_CDG.NOV + maskToFloat2(invoicing.NOV);
          sum_CDG.DEZ = sum_CDG.DEZ + maskToFloat2(invoicing.DEZ);
        }
        if (invoicing.TIPO.substring(0, 3) === 'CRC') {
          sum_CRC.JAN = sum_CRC.JAN + maskToFloat2(invoicing.JAN);
          sum_CRC.FEV = sum_CRC.FEV + maskToFloat2(invoicing.FEV);
          sum_CRC.MAR = sum_CRC.MAR + maskToFloat2(invoicing.MAR);
          sum_CRC.ABR = sum_CRC.ABR + maskToFloat2(invoicing.ABR);
          sum_CRC.MAI = sum_CRC.MAI + maskToFloat2(invoicing.MAI);
          sum_CRC.JUN = sum_CRC.JUN + maskToFloat2(invoicing.JUN);
          sum_CRC.JUL = sum_CRC.JUL + maskToFloat2(invoicing.JUL);
          sum_CRC.AGO = sum_CRC.AGO + maskToFloat2(invoicing.AGO);
          sum_CRC.SET = sum_CRC.SET + maskToFloat2(invoicing.SET);
          sum_CRC.OUT = sum_CRC.OUT + maskToFloat2(invoicing.OUT);
          sum_CRC.NOV = sum_CRC.NOV + maskToFloat2(invoicing.NOV);
          sum_CRC.DEZ = sum_CRC.DEZ + maskToFloat2(invoicing.DEZ);
        }
      });

      model = {
        ...model,
        TOTAL_CUSTOS_CMD: {
          JAN: maskToMoney3(sum_CMD.JAN),
          FEV: maskToMoney3(sum_CMD.FEV),
          MAR: maskToMoney3(sum_CMD.MAR),
          ABR: maskToMoney3(sum_CMD.ABR),
          MAI: maskToMoney3(sum_CMD.MAI),
          JUN: maskToMoney3(sum_CMD.JUN),
          JUL: maskToMoney3(sum_CMD.JUL),
          AGO: maskToMoney3(sum_CMD.AGO),
          SET: maskToMoney3(sum_CMD.SET),
          OUT: maskToMoney3(sum_CMD.OUT),
          NOV: maskToMoney3(sum_CMD.NOV),
          DEZ: maskToMoney3(sum_CMD.DEZ),
        },
        TOTAL_CUSTOS_CMI: {
          JAN: maskToMoney3(sum_CMI.JAN),
          FEV: maskToMoney3(sum_CMI.FEV),
          MAR: maskToMoney3(sum_CMI.MAR),
          ABR: maskToMoney3(sum_CMI.ABR),
          MAI: maskToMoney3(sum_CMI.MAI),
          JUN: maskToMoney3(sum_CMI.JUN),
          JUL: maskToMoney3(sum_CMI.JUL),
          AGO: maskToMoney3(sum_CMI.AGO),
          SET: maskToMoney3(sum_CMI.SET),
          OUT: maskToMoney3(sum_CMI.OUT),
          NOV: maskToMoney3(sum_CMI.NOV),
          DEZ: maskToMoney3(sum_CMI.DEZ),
        },
        TOTAL_CUSTOS_CAP: {
          JAN: maskToMoney3(sum_CAP.JAN),
          FEV: maskToMoney3(sum_CAP.FEV),
          MAR: maskToMoney3(sum_CAP.MAR),
          ABR: maskToMoney3(sum_CAP.ABR),
          MAI: maskToMoney3(sum_CAP.MAI),
          JUN: maskToMoney3(sum_CAP.JUN),
          JUL: maskToMoney3(sum_CAP.JUL),
          AGO: maskToMoney3(sum_CAP.AGO),
          SET: maskToMoney3(sum_CAP.SET),
          OUT: maskToMoney3(sum_CAP.OUT),
          NOV: maskToMoney3(sum_CAP.NOV),
          DEZ: maskToMoney3(sum_CAP.DEZ),
        },
        TOTAL_CUSTOS_CMM: {
          JAN: maskToMoney3(sum_CMM.JAN),
          FEV: maskToMoney3(sum_CMM.FEV),
          MAR: maskToMoney3(sum_CMM.MAR),
          ABR: maskToMoney3(sum_CMM.ABR),
          MAI: maskToMoney3(sum_CMM.MAI),
          JUN: maskToMoney3(sum_CMM.JUN),
          JUL: maskToMoney3(sum_CMM.JUL),
          AGO: maskToMoney3(sum_CMM.AGO),
          SET: maskToMoney3(sum_CMM.SET),
          OUT: maskToMoney3(sum_CMM.OUT),
          NOV: maskToMoney3(sum_CMM.NOV),
          DEZ: maskToMoney3(sum_CMM.DEZ),
        },
        TOTAL_CUSTOS_CCE: {
          JAN: maskToMoney3(sum_CCE.JAN),
          FEV: maskToMoney3(sum_CCE.FEV),
          MAR: maskToMoney3(sum_CCE.MAR),
          ABR: maskToMoney3(sum_CCE.ABR),
          MAI: maskToMoney3(sum_CCE.MAI),
          JUN: maskToMoney3(sum_CCE.JUN),
          JUL: maskToMoney3(sum_CCE.JUL),
          AGO: maskToMoney3(sum_CCE.AGO),
          SET: maskToMoney3(sum_CCE.SET),
          OUT: maskToMoney3(sum_CCE.OUT),
          NOV: maskToMoney3(sum_CCE.NOV),
          DEZ: maskToMoney3(sum_CCE.DEZ),
        },
        TOTAL_CUSTOS_CEQ: {
          JAN: maskToMoney3(sum_CEQ.JAN),
          FEV: maskToMoney3(sum_CEQ.FEV),
          MAR: maskToMoney3(sum_CEQ.MAR),
          ABR: maskToMoney3(sum_CEQ.ABR),
          MAI: maskToMoney3(sum_CEQ.MAI),
          JUN: maskToMoney3(sum_CEQ.JUN),
          JUL: maskToMoney3(sum_CEQ.JUL),
          AGO: maskToMoney3(sum_CEQ.AGO),
          SET: maskToMoney3(sum_CEQ.SET),
          OUT: maskToMoney3(sum_CEQ.OUT),
          NOV: maskToMoney3(sum_CEQ.NOV),
          DEZ: maskToMoney3(sum_CEQ.DEZ),
        },
        TOTAL_CUSTOS_CMA: {
          JAN: maskToMoney3(sum_CMA.JAN),
          FEV: maskToMoney3(sum_CMA.FEV),
          MAR: maskToMoney3(sum_CMA.MAR),
          ABR: maskToMoney3(sum_CMA.ABR),
          MAI: maskToMoney3(sum_CMA.MAI),
          JUN: maskToMoney3(sum_CMA.JUN),
          JUL: maskToMoney3(sum_CMA.JUL),
          AGO: maskToMoney3(sum_CMA.AGO),
          SET: maskToMoney3(sum_CMA.SET),
          OUT: maskToMoney3(sum_CMA.OUT),
          NOV: maskToMoney3(sum_CMA.NOV),
          DEZ: maskToMoney3(sum_CMA.DEZ),
        },
        TOTAL_CUSTOS_CMC: {
          JAN: maskToMoney3(sum_CMC.JAN),
          FEV: maskToMoney3(sum_CMC.FEV),
          MAR: maskToMoney3(sum_CMC.MAR),
          ABR: maskToMoney3(sum_CMC.ABR),
          MAI: maskToMoney3(sum_CMC.MAI),
          JUN: maskToMoney3(sum_CMC.JUN),
          JUL: maskToMoney3(sum_CMC.JUL),
          AGO: maskToMoney3(sum_CMC.AGO),
          SET: maskToMoney3(sum_CMC.SET),
          OUT: maskToMoney3(sum_CMC.OUT),
          NOV: maskToMoney3(sum_CMC.NOV),
          DEZ: maskToMoney3(sum_CMC.DEZ),
        },
        TOTAL_CUSTOS_CMV: {
          JAN: maskToMoney3(sum_CMV.JAN),
          FEV: maskToMoney3(sum_CMV.FEV),
          MAR: maskToMoney3(sum_CMV.MAR),
          ABR: maskToMoney3(sum_CMV.ABR),
          MAI: maskToMoney3(sum_CMV.MAI),
          JUN: maskToMoney3(sum_CMV.JUN),
          JUL: maskToMoney3(sum_CMV.JUL),
          AGO: maskToMoney3(sum_CMV.AGO),
          SET: maskToMoney3(sum_CMV.SET),
          OUT: maskToMoney3(sum_CMV.OUT),
          NOV: maskToMoney3(sum_CMV.NOV),
          DEZ: maskToMoney3(sum_CMV.DEZ),
        },
        TOTAL_CUSTOS_CSB: {
          JAN: maskToMoney3(sum_CSB.JAN),
          FEV: maskToMoney3(sum_CSB.FEV),
          MAR: maskToMoney3(sum_CSB.MAR),
          ABR: maskToMoney3(sum_CSB.ABR),
          MAI: maskToMoney3(sum_CSB.MAI),
          JUN: maskToMoney3(sum_CSB.JUN),
          JUL: maskToMoney3(sum_CSB.JUL),
          AGO: maskToMoney3(sum_CSB.AGO),
          SET: maskToMoney3(sum_CSB.SET),
          OUT: maskToMoney3(sum_CSB.OUT),
          NOV: maskToMoney3(sum_CSB.NOV),
          DEZ: maskToMoney3(sum_CSB.DEZ),
        },
        TOTAL_CUSTOS_CDG: {
          JAN: maskToMoney3(sum_CDG.JAN),
          FEV: maskToMoney3(sum_CDG.FEV),
          MAR: maskToMoney3(sum_CDG.MAR),
          ABR: maskToMoney3(sum_CDG.ABR),
          MAI: maskToMoney3(sum_CDG.MAI),
          JUN: maskToMoney3(sum_CDG.JUN),
          JUL: maskToMoney3(sum_CDG.JUL),
          AGO: maskToMoney3(sum_CDG.AGO),
          SET: maskToMoney3(sum_CDG.SET),
          OUT: maskToMoney3(sum_CDG.OUT),
          NOV: maskToMoney3(sum_CDG.NOV),
          DEZ: maskToMoney3(sum_CDG.DEZ),
        },
        TOTAL_CUSTOS_CRC: {
          JAN: maskToMoney3(sum_CRC.JAN),
          FEV: maskToMoney3(sum_CRC.FEV),
          MAR: maskToMoney3(sum_CRC.MAR),
          ABR: maskToMoney3(sum_CRC.ABR),
          MAI: maskToMoney3(sum_CRC.MAI),
          JUN: maskToMoney3(sum_CRC.JUN),
          JUL: maskToMoney3(sum_CRC.JUL),
          AGO: maskToMoney3(sum_CRC.AGO),
          SET: maskToMoney3(sum_CRC.SET),
          OUT: maskToMoney3(sum_CRC.OUT),
          NOV: maskToMoney3(sum_CRC.NOV),
          DEZ: maskToMoney3(sum_CRC.DEZ),
        },
      };
    }


    const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));
    margemPorcLocalStorage[4][0] = total;
    localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));

    return model;
  };


  const releaseListSubset = [
    'Total Custos',
    'CMD',
    'CMI',
    'CAP',
    'CMM',
    'CCE',
    'CEQ',
    'CMA',
    'CMC',
    'CMV',
    'CSB',
    'CDG',
    'CRC1',
    'CRC2',
  ];
  const releaseListSubtotal = 'Subtotal';
  const releaseList = [
    'Total Custos',
    '01 - Salários',
    '02 - Encargos',
    '03 - Horas Extras / Adicional',
    '01 - Salários',
    '02 - Encargos',
    '03 - Horas Extras / Adicional',
    '01 - Transporte',
    '02 - Alimentação',
    '03 - EPI e Uniformes',
    '04 - Alojamento',
    '05 - Cursos e Treinamento',
    '06 - Assistência Médica',
    '07 - Outros',
    '01 - Despesas com Viagens',
    '02 - Fretes / Transportes',
    '03 - Outros',
    '01 - Instalação / Construção',
    '02 - Manutenção',
    '03 - Alugueis',
    '04 - Comunicação',
    '05 - Informatica / TI',
    '06 - Materiais de Escritorio',
    '07 - Outros',
    '01 - Aquisição',
    '02 - Locação',
    '03 - Manutenção',
    '04 - Outros',
    '01 - Diversos',
    '02 - Civil',
    '03 - Tubulação',
    '04 - Caldeiraria / Estrutura',
    '05 - Eletrica / Instrumentação',
    '06 - Isolamento',
    '07 - Pintura',
    '01 - Material de Consumo',
    '02 - Gases',
    '03 - Eletrodos e Varetas',
    '01 - Merc.Vend.',
    '01 - Diversos',
    '02 - Civil',
    '03 - Tubulação',
    '04 - Caldeiraria / Estrutura',
    '05 - Eletrica / Instrumentação',
    '06 - Isolamento',
    '07 - Pintura',
    '01 - Taxas Diversas',
    '02 - Premiações / Patrocinio',
    '03 - Seguros / Multas / Juros',
    '04 - Outros',
    '01 - Custos de MOD',
    '02 - Custos de MOI',
    '03 - Custos de Apoio',
    '04 - Custos de Mobilização',
    '05 - Custos de Canteiro',
    '06 - Custos de Equipamentos',
    '07 - Custos de Materiais',
    '08 - Custos de Subcontratos',
    '09 - Custos Financeiros',
    '10 - Outros',
  ];

  const releaseFirstList = [
    'TOTAL',
    'CMDTO',
    'CMD01',
    'CMD02',
    'CMD03',
    'CMITO',
    'CMI01',
    'CMI02',
    'CMI03',
    'CAPTO',
    'CAP01',
    'CAP02',
    'CAP03',
    'CAP04',
    'CAP05',
    'CAP06',
    'CAP07',
    'CMMTO',
    'CMM01',
    'CMM02',
    'CMM03',
    'CCETO',
    'CCE01',
    'CCE02',
    'CCE03',
    'CCE04',
    'CCE05',
    'CCE06',
    'CCE07',
    'CEQTO',
    'CEQ01',
    'CEQ02',
    'CEQ03',
    'CEQ04',
    'CMATO',
    'CMA01',
    'CMA02',
    'CMA03',
    'CMA04',
    'CMA05',
    'CMA06',
    'CMA07',
    'CMCTO',
    'CMC01',
    'CMC02',
    'CMC03',
    'CMVTO',
    'CMV01',
    'CSBTO',
    'CSB01',
    'CSB02',
    'CSB03',
    'CSB04',
    'CSB05',
    'CSB06',
    'CSB07',
    'CDGTO',
    'CDG01',
    'CDG02',
    'CDG03',
    'CDG04',
    'CRCTO',
    'CRC01',
    'CRC02',
    'CRC03',
    'CRC04',
    'CRC05',
    'CRC06',
    'CRC07',
    'CRC08',
    'CRC09',
    'CRC10',
  ];



  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'CMD01_SALARIOS.ORCADO', label: releaseList[1], value: values.CMD01_SALARIOS.ORCADO, subSet: 'CMD' },
      { name: 'CMD02_ENCARGOS.ORCADO', label: releaseList[2], value: values.CMD02_ENCARGOS.ORCADO, subSet: 'CMD' },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.ORCADO', label: releaseList[3], value: values.CMD03_HORAS_EXTRAS_ADICIONAL.ORCADO, subSet: 'CMD' },
      { name: 'CMI01_SALARIOS.ORCADO', label: releaseList[4], value: values.CMI01_SALARIOS.ORCADO, subSet: 'CMI' },
      { name: 'CMI02_ENCARGOS.ORCADO', label: releaseList[5], value: values.CMI02_ENCARGOS.ORCADO, subSet: 'CMI' },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.ORCADO', label: releaseList[6], value: values.CMI03_HORAS_EXTRAS_ADICIONAL.ORCADO, subSet: 'CMI' },
      { name: 'CAP01_TRANSPORTE.ORCADO', label: releaseList[7], value: values.CAP01_TRANSPORTE.ORCADO, subSet: 'CAP' },
      { name: 'CAP02_ALIMENTACAO.ORCADO', label: releaseList[8], value: values.CAP02_ALIMENTACAO.ORCADO, subSet: 'CAP' },
      { name: 'CAP03_EPI_E_UNIFORMES.ORCADO', label: releaseList[9], value: values.CAP03_EPI_E_UNIFORMES.ORCADO, subSet: 'CAP' },
      { name: 'CAP04_ALOJAMENTO.ORCADO', label: releaseList[10], value: values.CAP04_ALOJAMENTO.ORCADO, subSet: 'CAP' },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.ORCADO', label: releaseList[11], value: values.CAP05_CURSOS_E_TREINAMENTO.ORCADO, subSet: 'CAP' },
      { name: 'CAP06_ASSISTENCIA_MEDICA.ORCADO', label: releaseList[12], value: values.CAP06_ASSISTENCIA_MEDICA.ORCADO, subSet: 'CAP' },
      { name: 'CAP07_OUTROS.ORCADO', label: releaseList[13], value: values.CAP07_OUTROS.ORCADO, subSet: 'CAP' },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.ORCADO', label: releaseList[14], value: values.CMM01_DESPESAS_COM_VIAGENS.ORCADO, subSet: 'CMM' },
      { name: 'CMM02_FRETES_TRANSPORTES.ORCADO', label: releaseList[15], value: values.CMM02_FRETES_TRANSPORTES.ORCADO, subSet: 'CMM' },
      { name: 'CMM03_OUTROS.ORCADO', label: releaseList[16], value: values.CMM03_OUTROS.ORCADO, subSet: 'CMM' },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.ORCADO', label: releaseList[17], value: values.CCE01_INSTALACAO_CONSTRUCAO.ORCADO, subSet: 'CCE' },
      { name: 'CCE02_MANUTENCAO.ORCADO', label: releaseList[18], value: values.CCE02_MANUTENCAO.ORCADO, subSet: 'CCE' },
      { name: 'CCE03_ALUGUEIS.ORCADO', label: releaseList[19], value: values.CCE03_ALUGUEIS.ORCADO, subSet: 'CCE' },
      { name: 'CCE04_COMUNICACAO.ORCADO', label: releaseList[20], value: values.CCE04_COMUNICACAO.ORCADO, subSet: 'CCE' },
      { name: 'CCE05_INFORMATICA_TI.ORCADO', label: releaseList[21], value: values.CCE05_INFORMATICA_TI.ORCADO, subSet: 'CCE' },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.ORCADO', label: releaseList[22], value: values.CCE06_MATERIAIS_DE_ESCRITORIO.ORCADO, subSet: 'CCE' },
      { name: 'CCE07_OUTROS.ORCADO', label: releaseList[23], value: values.CCE07_OUTROS.ORCADO, subSet: 'CCE' },
      { name: 'CEQ01_AQUISICAO.ORCADO', label: releaseList[24], value: values.CEQ01_AQUISICAO.ORCADO, subSet: 'CEQ' },
      { name: 'CEQ02_LOCACAO.ORCADO', label: releaseList[25], value: values.CEQ02_LOCACAO.ORCADO, subSet: 'CEQ' },
      { name: 'CEQ03_MANUTENCAO.ORCADO', label: releaseList[26], value: values.CEQ03_MANUTENCAO.ORCADO, subSet: 'CEQ' },
      { name: 'CEQ04_OUTROS.ORCADO', label: releaseList[27], value: values.CEQ04_OUTROS.ORCADO, subSet: 'CEQ' },
      { name: 'CMA01_DIVERSOS.ORCADO', label: releaseList[28], value: values.CMA01_DIVERSOS.ORCADO, subSet: 'CMA' },
      { name: 'CMA02_CIVIL.ORCADO', label: releaseList[29], value: values.CMA02_CIVIL.ORCADO, subSet: 'CMA' },
      { name: 'CMA03_TUBULACAO.ORCADO', label: releaseList[30], value: values.CMA03_TUBULACAO.ORCADO, subSet: 'CMA' },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.ORCADO', label: releaseList[31], value: values.CMA04_CALDEIRARIA_ESTRUTURA.ORCADO, subSet: 'CMA' },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.ORCADO', label: releaseList[32], value: values.CMA05_ELETRICA_INSTRUMENTACAO.ORCADO, subSet: 'CMA' },
      { name: 'CMA06_ISOLAMENTO.ORCADO', label: releaseList[33], value: values.CMA06_ISOLAMENTO.ORCADO, subSet: 'CMA' },
      { name: 'CMA07_PINTURA.ORCADO', label: releaseList[34], value: values.CMA07_PINTURA.ORCADO, subSet: 'CMA' },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.ORCADO', label: releaseList[35], value: values.CMC01_MATERIAL_DE_CONSUMO.ORCADO, subSet: 'CMC' },
      { name: 'CMC02_GASES.ORCADO', label: releaseList[36], value: values.CMC02_GASES.ORCADO, subSet: 'CMC' },
      { name: 'CMC03_ELETRODOS_E_VARETAS.ORCADO', label: releaseList[37], value: values.CMC03_ELETRODOS_E_VARETAS.ORCADO, subSet: 'CMC' },
      { name: 'CMV01_MERC_VEND.ORCADO', label: releaseList[38], value: values.CMV01_MERC_VEND.ORCADO, subSet: 'CMV' },
      { name: 'CSB01_DIVERSOS.ORCADO', label: releaseList[39], value: values.CSB01_DIVERSOS.ORCADO, subSet: 'CSB' },
      { name: 'CSB02_CIVIL.ORCADO', label: releaseList[40], value: values.CSB02_CIVIL.ORCADO, subSet: 'CSB' },
      { name: 'CSB03_TUBULACAO.ORCADO', label: releaseList[41], value: values.CSB03_TUBULACAO.ORCADO, subSet: 'CSB' },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.ORCADO', label: releaseList[42], value: values.CSB04_CALDEIRARIA_ESTRUTURA.ORCADO, subSet: 'CSB' },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.ORCADO', label: releaseList[43], value: values.CSB05_ELETRICA_INSTRUMENTACAO.ORCADO, subSet: 'CSB' },
      { name: 'CSB06_ISOLAMENTO.ORCADO', label: releaseList[44], value: values.CSB06_ISOLAMENTO.ORCADO, subSet: 'CSB' },
      { name: 'CSB07_PINTURA.ORCADO', label: releaseList[45], value: values.CSB07_PINTURA.ORCADO, subSet: 'CSB' },
      { name: 'CDG01_TAXAS_DIVERSAS.ORCADO', label: releaseList[46], value: values.CDG01_TAXAS_DIVERSAS.ORCADO, subSet: 'CDG' },
      { name: 'CDG02_PREMIACOES_PATROCINIO.ORCADO', label: releaseList[47], value: values.CDG02_PREMIACOES_PATROCINIO.ORCADO, subSet: 'CDG' },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.ORCADO', label: releaseList[48], value: values.CDG03_SEGUROS_MULTAS_JUROS.ORCADO, subSet: 'CDG' },
      { name: 'CDG04_OUTROS.ORCADO', label: releaseList[49], value: values.CDG04_OUTROS.ORCADO, subSet: 'CDG' },
      { name: 'CRC01_CUSTOS_DE_MOD.ORCADO', label: releaseList[50], value: values.CRC01_CUSTOS_DE_MOD.ORCADO, subSet: 'CRC1' },
      { name: 'CRC02_CUSTOS_DE_MOI.ORCADO', label: releaseList[51], value: values.CRC02_CUSTOS_DE_MOI.ORCADO, subSet: 'CRC1' },
      { name: 'CRC03_CUSTOS_DE_APOIO.ORCADO', label: releaseList[52], value: values.CRC03_CUSTOS_DE_APOIO.ORCADO, subSet: 'CRC1' },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.ORCADO', label: releaseList[53], value: values.CRC04_CUSTOS_DE_MOBILIZACAO.ORCADO, subSet: 'CRC1' },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.ORCADO', label: releaseList[54], value: values.CRC05_CUSTOS_DE_CANTEIRO.ORCADO, subSet: 'CRC1' },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.ORCADO', label: releaseList[55], value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.ORCADO, subSet: 'CRC2' },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.ORCADO', label: releaseList[56], value: values.CRC07_CUSTOS_DE_MATERIAIS.ORCADO, subSet: 'CRC2' },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.ORCADO', label: releaseList[57], value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.ORCADO, subSet: 'CRC2' },
      { name: 'CRC09_CUSTOS_FINANCEIROS.ORCADO', label: releaseList[58], value: values.CRC09_CUSTOS_FINANCEIROS.ORCADO, subSet: 'CRC2' },
      { name: 'CRC10_OUTROS.ORCADO', label: releaseList[59], value: values.CRC10_OUTROS.ORCADO, subSet: 'CRC2' },
    ];
  };

  function calcBudgeted(values: any, setFieldValue: any) {
    let subTotal = 0.00;
    let subTotal_CMD = 0.00;
    let subTotal_CMI = 0.00;
    let subTotal_CAP = 0.00;
    let subTotal_CMM = 0.00;
    let subTotal_CCE = 0.00;
    let subTotal_CEQ = 0.00;
    let subTotal_CMA = 0.00;
    let subTotal_CMC = 0.00;
    let subTotal_CMV = 0.00;
    let subTotal_CSB = 0.00;
    let subTotal_CDG = 0.00;
    let subTotal_CRC = 0.00;

    if (values.CMD01_SALARIOS.ORCADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD01_SALARIOS.ORCADO);
    }
    if (values.CMD02_ENCARGOS.ORCADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD02_ENCARGOS.ORCADO);
    }
    if (values.CMD03_HORAS_EXTRAS_ADICIONAL.ORCADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD03_HORAS_EXTRAS_ADICIONAL.ORCADO);
    }

    if (values.CMI01_SALARIOS.ORCADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI01_SALARIOS.ORCADO);
    }
    if (values.CMI02_ENCARGOS.ORCADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI02_ENCARGOS.ORCADO);
    }
    if (values.CMI03_HORAS_EXTRAS_ADICIONAL.ORCADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI03_HORAS_EXTRAS_ADICIONAL.ORCADO);
    }

    if (values.CAP01_TRANSPORTE.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP01_TRANSPORTE.ORCADO);
    }
    if (values.CAP02_ALIMENTACAO.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP02_ALIMENTACAO.ORCADO);
    }
    if (values.CAP03_EPI_E_UNIFORMES.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP03_EPI_E_UNIFORMES.ORCADO);
    }
    if (values.CAP04_ALOJAMENTO.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP04_ALOJAMENTO.ORCADO);
    }
    if (values.CAP05_CURSOS_E_TREINAMENTO.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP05_CURSOS_E_TREINAMENTO.ORCADO);
    }
    if (values.CAP06_ASSISTENCIA_MEDICA.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP06_ASSISTENCIA_MEDICA.ORCADO);
    }
    if (values.CAP07_OUTROS.ORCADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP07_OUTROS.ORCADO);
    }

    if (values.CMM01_DESPESAS_COM_VIAGENS.ORCADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM01_DESPESAS_COM_VIAGENS.ORCADO);
    }
    if (values.CMM02_FRETES_TRANSPORTES.ORCADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM02_FRETES_TRANSPORTES.ORCADO);
    }
    if (values.CMM03_OUTROS.ORCADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM03_OUTROS.ORCADO);
    }

    if (values.CCE01_INSTALACAO_CONSTRUCAO.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE01_INSTALACAO_CONSTRUCAO.ORCADO);
    }
    if (values.CCE02_MANUTENCAO.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE02_MANUTENCAO.ORCADO);
    }
    if (values.CCE03_ALUGUEIS.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE03_ALUGUEIS.ORCADO);
    }
    if (values.CCE04_COMUNICACAO.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE04_COMUNICACAO.ORCADO);
    }
    if (values.CCE05_INFORMATICA_TI.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE05_INFORMATICA_TI.ORCADO);
    }
    if (values.CCE06_MATERIAIS_DE_ESCRITORIO.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE06_MATERIAIS_DE_ESCRITORIO.ORCADO);
    }
    if (values.CCE07_OUTROS.ORCADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE07_OUTROS.ORCADO);
    }

    if (values.CEQ01_AQUISICAO.ORCADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ01_AQUISICAO.ORCADO);
    }
    if (values.CEQ02_LOCACAO.ORCADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ02_LOCACAO.ORCADO);
    }
    if (values.CEQ03_MANUTENCAO.ORCADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ03_MANUTENCAO.ORCADO);
    }
    if (values.CEQ04_OUTROS.ORCADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ04_OUTROS.ORCADO);
    }

    if (values.CMA01_DIVERSOS.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA01_DIVERSOS.ORCADO);
    }
    if (values.CMA02_CIVIL.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA02_CIVIL.ORCADO);
    }
    if (values.CMA03_TUBULACAO.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA03_TUBULACAO.ORCADO);
    }
    if (values.CMA04_CALDEIRARIA_ESTRUTURA.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA04_CALDEIRARIA_ESTRUTURA.ORCADO);
    }
    if (values.CMA05_ELETRICA_INSTRUMENTACAO.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA05_ELETRICA_INSTRUMENTACAO.ORCADO);
    }
    if (values.CMA06_ISOLAMENTO.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA06_ISOLAMENTO.ORCADO);
    }
    if (values.CMA07_PINTURA.ORCADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA07_PINTURA.ORCADO);
    }

    if (values.CMC01_MATERIAL_DE_CONSUMO.ORCADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC01_MATERIAL_DE_CONSUMO.ORCADO);
    }
    if (values.CMC02_GASES.ORCADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC02_GASES.ORCADO);
    }
    if (values.CMC03_ELETRODOS_E_VARETAS.ORCADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC03_ELETRODOS_E_VARETAS.ORCADO);
    }

    if (values.CMV01_MERC_VEND.ORCADO) {
      subTotal_CMV = subTotal_CMV + maskToFloat(values.CMV01_MERC_VEND.ORCADO);
    }

    if (values.CSB01_DIVERSOS.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB01_DIVERSOS.ORCADO);
    }
    if (values.CSB02_CIVIL.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB02_CIVIL.ORCADO);
    }
    if (values.CSB03_TUBULACAO.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB03_TUBULACAO.ORCADO);
    }
    if (values.CSB04_CALDEIRARIA_ESTRUTURA.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB04_CALDEIRARIA_ESTRUTURA.ORCADO);
    }
    if (values.CSB05_ELETRICA_INSTRUMENTACAO.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB05_ELETRICA_INSTRUMENTACAO.ORCADO);
    }
    if (values.CSB06_ISOLAMENTO.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB06_ISOLAMENTO.ORCADO);
    }
    if (values.CSB07_PINTURA.ORCADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB07_PINTURA.ORCADO);
    }

    if (values.CDG01_TAXAS_DIVERSAS.ORCADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG01_TAXAS_DIVERSAS.ORCADO);
    }
    if (values.CDG02_PREMIACOES_PATROCINIO.ORCADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG02_PREMIACOES_PATROCINIO.ORCADO);
    }
    if (values.CDG03_SEGUROS_MULTAS_JUROS.ORCADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG03_SEGUROS_MULTAS_JUROS.ORCADO);
    }
    if (values.CDG04_OUTROS.ORCADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG04_OUTROS.ORCADO);
    }

    if (values.CRC01_CUSTOS_DE_MOD.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC01_CUSTOS_DE_MOD.ORCADO);
    }
    if (values.CRC02_CUSTOS_DE_MOI.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC02_CUSTOS_DE_MOI.ORCADO);
    }
    if (values.CRC03_CUSTOS_DE_APOIO.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC03_CUSTOS_DE_APOIO.ORCADO);
    }
    if (values.CRC04_CUSTOS_DE_MOBILIZACAO.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC04_CUSTOS_DE_MOBILIZACAO.ORCADO);
    }
    if (values.CRC05_CUSTOS_DE_CANTEIRO.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC05_CUSTOS_DE_CANTEIRO.ORCADO);
    }
    if (values.CRC06_CUSTOS_DE_EQUIPAMENTOS.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC06_CUSTOS_DE_EQUIPAMENTOS.ORCADO);
    }
    if (values.CRC07_CUSTOS_DE_MATERIAIS.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC07_CUSTOS_DE_MATERIAIS.ORCADO);
    }
    if (values.CRC08_CUSTOS_DE_SUBCONTRATOS.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC08_CUSTOS_DE_SUBCONTRATOS.ORCADO);
    }
    if (values.CRC09_CUSTOS_FINANCEIROS.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC09_CUSTOS_FINANCEIROS.ORCADO);
    }
    if (values.CRC10_OUTROS.ORCADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC10_OUTROS.ORCADO);
    }

    subTotal = (subTotal_CMD + subTotal_CMI + subTotal_CAP + subTotal_CMM + subTotal_CCE + subTotal_CEQ + subTotal_CMA + subTotal_CMC + subTotal_CMV + subTotal_CSB + subTotal_CDG + subTotal_CRC);


    setFieldValue('TOTAL_CUSTOS_CMD.ORCADO', subTotal_CMD);
    setFieldValue('TOTAL_CUSTOS_CMI.ORCADO', subTotal_CMI);
    setFieldValue('TOTAL_CUSTOS_CAP.ORCADO', subTotal_CAP);
    setFieldValue('TOTAL_CUSTOS_CMM.ORCADO', subTotal_CMM);
    setFieldValue('TOTAL_CUSTOS_CCE.ORCADO', subTotal_CCE);
    setFieldValue('TOTAL_CUSTOS_CEQ.ORCADO', subTotal_CEQ);
    setFieldValue('TOTAL_CUSTOS_CMA.ORCADO', subTotal_CMA);
    setFieldValue('TOTAL_CUSTOS_CMC.ORCADO', subTotal_CMC);
    setFieldValue('TOTAL_CUSTOS_CMV.ORCADO', subTotal_CMV);
    setFieldValue('TOTAL_CUSTOS_CSB.ORCADO', subTotal_CSB);
    setFieldValue('TOTAL_CUSTOS_CDG.ORCADO', subTotal_CDG);
    setFieldValue('TOTAL_CUSTOS_CRC.ORCADO', subTotal_CRC);
    setFieldValue('TOTAL_CUSTOS.ORCADO', subTotal);


    let marginReal = 0.00;
    let marginPorc = 0.00;

    marginReal = returnCalcMarginsAndTaxOfLS_Budgeted().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Budgeted().PORC;

    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.ORCADO', marginReal);
    setFieldValue('MARGEM_PORC.ORCADO', marginPorc);
  };

  function saveOnLocalStorageOR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[7].CUS.forEach((invoicing) => {
      if (`${field?.subSet}${field?.label?.substring(0, 2)}` === 'CMV01') {
        field.subSet = 'CMV';
        field.label = '-Merc.Vend.';
      };


      if (invoicing.TIPO.substring(0, 5) === (`${field.subSet.substring(0, 3)}${field.label.substring(0, 2)}`)) {
        invoicing.ORCADO = e;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'CMD01_SALARIOS.PLANEJADO', label: releaseList[1], value: values.CMD01_SALARIOS.PLANEJADO, subSet: 'CMD' },
      { name: 'CMD02_ENCARGOS.PLANEJADO', label: releaseList[2], value: values.CMD02_ENCARGOS.PLANEJADO, subSet: 'CMD' },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.PLANEJADO', label: releaseList[3], value: values.CMD03_HORAS_EXTRAS_ADICIONAL.PLANEJADO, subSet: 'CMD' },
      { name: 'CMI01_SALARIOS.PLANEJADO', label: releaseList[4], value: values.CMI01_SALARIOS.PLANEJADO, subSet: 'CMI' },
      { name: 'CMI02_ENCARGOS.PLANEJADO', label: releaseList[5], value: values.CMI02_ENCARGOS.PLANEJADO, subSet: 'CMI' },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.PLANEJADO', label: releaseList[6], value: values.CMI03_HORAS_EXTRAS_ADICIONAL.PLANEJADO, subSet: 'CMI' },
      { name: 'CAP01_TRANSPORTE.PLANEJADO', label: releaseList[7], value: values.CAP01_TRANSPORTE.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP02_ALIMENTACAO.PLANEJADO', label: releaseList[8], value: values.CAP02_ALIMENTACAO.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP03_EPI_E_UNIFORMES.PLANEJADO', label: releaseList[9], value: values.CAP03_EPI_E_UNIFORMES.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP04_ALOJAMENTO.PLANEJADO', label: releaseList[10], value: values.CAP04_ALOJAMENTO.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.PLANEJADO', label: releaseList[11], value: values.CAP05_CURSOS_E_TREINAMENTO.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP06_ASSISTENCIA_MEDICA.PLANEJADO', label: releaseList[12], value: values.CAP06_ASSISTENCIA_MEDICA.PLANEJADO, subSet: 'CAP' },
      { name: 'CAP07_OUTROS.PLANEJADO', label: releaseList[13], value: values.CAP07_OUTROS.PLANEJADO, subSet: 'CAP' },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.PLANEJADO', label: releaseList[14], value: values.CMM01_DESPESAS_COM_VIAGENS.PLANEJADO, subSet: 'CMM' },
      { name: 'CMM02_FRETES_TRANSPORTES.PLANEJADO', label: releaseList[15], value: values.CMM02_FRETES_TRANSPORTES.PLANEJADO, subSet: 'CMM' },
      { name: 'CMM03_OUTROS.PLANEJADO', label: releaseList[16], value: values.CMM03_OUTROS.PLANEJADO, subSet: 'CMM' },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.PLANEJADO', label: releaseList[17], value: values.CCE01_INSTALACAO_CONSTRUCAO.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE02_MANUTENCAO.PLANEJADO', label: releaseList[18], value: values.CCE02_MANUTENCAO.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE03_ALUGUEIS.PLANEJADO', label: releaseList[19], value: values.CCE03_ALUGUEIS.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE04_COMUNICACAO.PLANEJADO', label: releaseList[20], value: values.CCE04_COMUNICACAO.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE05_INFORMATICA_TI.PLANEJADO', label: releaseList[21], value: values.CCE05_INFORMATICA_TI.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.PLANEJADO', label: releaseList[22], value: values.CCE06_MATERIAIS_DE_ESCRITORIO.PLANEJADO, subSet: 'CCE' },
      { name: 'CCE07_OUTROS.PLANEJADO', label: releaseList[23], value: values.CCE07_OUTROS.PLANEJADO, subSet: 'CCE' },
      { name: 'CEQ01_AQUISICAO.PLANEJADO', label: releaseList[24], value: values.CEQ01_AQUISICAO.PLANEJADO, subSet: 'CEQ' },
      { name: 'CEQ02_LOCACAO.PLANEJADO', label: releaseList[25], value: values.CEQ02_LOCACAO.PLANEJADO, subSet: 'CEQ' },
      { name: 'CEQ03_MANUTENCAO.PLANEJADO', label: releaseList[26], value: values.CEQ03_MANUTENCAO.PLANEJADO, subSet: 'CEQ' },
      { name: 'CEQ04_OUTROS.PLANEJADO', label: releaseList[27], value: values.CEQ04_OUTROS.PLANEJADO, subSet: 'CEQ' },
      { name: 'CMA01_DIVERSOS.PLANEJADO', label: releaseList[28], value: values.CMA01_DIVERSOS.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA02_CIVIL.PLANEJADO', label: releaseList[29], value: values.CMA02_CIVIL.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA03_TUBULACAO.PLANEJADO', label: releaseList[30], value: values.CMA03_TUBULACAO.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.PLANEJADO', label: releaseList[31], value: values.CMA04_CALDEIRARIA_ESTRUTURA.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.PLANEJADO', label: releaseList[32], value: values.CMA05_ELETRICA_INSTRUMENTACAO.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA06_ISOLAMENTO.PLANEJADO', label: releaseList[33], value: values.CMA06_ISOLAMENTO.PLANEJADO, subSet: 'CMA' },
      { name: 'CMA07_PINTURA.PLANEJADO', label: releaseList[34], value: values.CMA07_PINTURA.PLANEJADO, subSet: 'CMA' },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.PLANEJADO', label: releaseList[35], value: values.CMC01_MATERIAL_DE_CONSUMO.PLANEJADO, subSet: 'CMC' },
      { name: 'CMC02_GASES.PLANEJADO', label: releaseList[36], value: values.CMC02_GASES.PLANEJADO, subSet: 'CMC' },
      { name: 'CMC03_ELETRODOS_E_VARETAS.PLANEJADO', label: releaseList[37], value: values.CMC03_ELETRODOS_E_VARETAS.PLANEJADO, subSet: 'CMC' },
      { name: 'CMV01_MERC_VEND.PLANEJADO', label: releaseList[38], value: values.CMV01_MERC_VEND.PLANEJADO, subSet: 'CMV' },
      { name: 'CSB01_DIVERSOS.PLANEJADO', label: releaseList[39], value: values.CSB01_DIVERSOS.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB02_CIVIL.PLANEJADO', label: releaseList[40], value: values.CSB02_CIVIL.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB03_TUBULACAO.PLANEJADO', label: releaseList[41], value: values.CSB03_TUBULACAO.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.PLANEJADO', label: releaseList[42], value: values.CSB04_CALDEIRARIA_ESTRUTURA.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.PLANEJADO', label: releaseList[43], value: values.CSB05_ELETRICA_INSTRUMENTACAO.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB06_ISOLAMENTO.PLANEJADO', label: releaseList[44], value: values.CSB06_ISOLAMENTO.PLANEJADO, subSet: 'CSB' },
      { name: 'CSB07_PINTURA.PLANEJADO', label: releaseList[45], value: values.CSB07_PINTURA.PLANEJADO, subSet: 'CSB' },
      { name: 'CDG01_TAXAS_DIVERSAS.PLANEJADO', label: releaseList[46], value: values.CDG01_TAXAS_DIVERSAS.PLANEJADO, subSet: 'CDG' },
      { name: 'CDG02_PREMIACOES_PATROCINIO.PLANEJADO', label: releaseList[47], value: values.CDG02_PREMIACOES_PATROCINIO.PLANEJADO, subSet: 'CDG' },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.PLANEJADO', label: releaseList[48], value: values.CDG03_SEGUROS_MULTAS_JUROS.PLANEJADO, subSet: 'CDG' },
      { name: 'CDG04_OUTROS.PLANEJADO', label: releaseList[49], value: values.CDG04_OUTROS.PLANEJADO, subSet: 'CDG' },
      { name: 'CRC01_CUSTOS_DE_MOD.PLANEJADO', label: releaseList[50], value: values.CRC01_CUSTOS_DE_MOD.PLANEJADO, subSet: 'CRC1' },
      { name: 'CRC02_CUSTOS_DE_MOI.PLANEJADO', label: releaseList[51], value: values.CRC02_CUSTOS_DE_MOI.PLANEJADO, subSet: 'CRC1' },
      { name: 'CRC03_CUSTOS_DE_APOIO.PLANEJADO', label: releaseList[52], value: values.CRC03_CUSTOS_DE_APOIO.PLANEJADO, subSet: 'CRC1' },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.PLANEJADO', label: releaseList[53], value: values.CRC04_CUSTOS_DE_MOBILIZACAO.PLANEJADO, subSet: 'CRC1' },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.PLANEJADO', label: releaseList[54], value: values.CRC05_CUSTOS_DE_CANTEIRO.PLANEJADO, subSet: 'CRC1' },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.PLANEJADO', label: releaseList[55], value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.PLANEJADO, subSet: 'CRC2' },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.PLANEJADO', label: releaseList[56], value: values.CRC07_CUSTOS_DE_MATERIAIS.PLANEJADO, subSet: 'CRC2' },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.PLANEJADO', label: releaseList[57], value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.PLANEJADO, subSet: 'CRC2' },
      { name: 'CRC09_CUSTOS_FINANCEIROS.PLANEJADO', label: releaseList[58], value: values.CRC09_CUSTOS_FINANCEIROS.PLANEJADO, subSet: 'CRC2' },
      { name: 'CRC10_OUTROS.PLANEJADO', label: releaseList[59], value: values.CRC10_OUTROS.PLANEJADO, subSet: 'CRC2' },
    ];
  };

  function calcPlanned(values: any, setFieldValue: any) {
    let subTotal = 0.00;
    let subTotal_CMD = 0.00;
    let subTotal_CMI = 0.00;
    let subTotal_CAP = 0.00;
    let subTotal_CMM = 0.00;
    let subTotal_CCE = 0.00;
    let subTotal_CEQ = 0.00;
    let subTotal_CMA = 0.00;
    let subTotal_CMC = 0.00;
    let subTotal_CMV = 0.00;
    let subTotal_CSB = 0.00;
    let subTotal_CDG = 0.00;
    let subTotal_CRC = 0.00;

    if (values.CMD01_SALARIOS.PLANEJADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD01_SALARIOS.PLANEJADO);
    }
    if (values.CMD02_ENCARGOS.PLANEJADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD02_ENCARGOS.PLANEJADO);
    }
    if (values.CMD03_HORAS_EXTRAS_ADICIONAL.PLANEJADO) {
      subTotal_CMD = subTotal_CMD + maskToFloat(values.CMD03_HORAS_EXTRAS_ADICIONAL.PLANEJADO);
    }

    if (values.CMI01_SALARIOS.PLANEJADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI01_SALARIOS.PLANEJADO);
    }
    if (values.CMI02_ENCARGOS.PLANEJADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI02_ENCARGOS.PLANEJADO);
    }
    if (values.CMI03_HORAS_EXTRAS_ADICIONAL.PLANEJADO) {
      subTotal_CMI = subTotal_CMI + maskToFloat(values.CMI03_HORAS_EXTRAS_ADICIONAL.PLANEJADO);
    }

    if (values.CAP01_TRANSPORTE.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP01_TRANSPORTE.PLANEJADO);
    }
    if (values.CAP02_ALIMENTACAO.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP02_ALIMENTACAO.PLANEJADO);
    }
    if (values.CAP03_EPI_E_UNIFORMES.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP03_EPI_E_UNIFORMES.PLANEJADO);
    }
    if (values.CAP04_ALOJAMENTO.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP04_ALOJAMENTO.PLANEJADO);
    }
    if (values.CAP05_CURSOS_E_TREINAMENTO.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP05_CURSOS_E_TREINAMENTO.PLANEJADO);
    }
    if (values.CAP06_ASSISTENCIA_MEDICA.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP06_ASSISTENCIA_MEDICA.PLANEJADO);
    }
    if (values.CAP07_OUTROS.PLANEJADO) {
      subTotal_CAP = subTotal_CAP + maskToFloat(values.CAP07_OUTROS.PLANEJADO);
    }

    if (values.CMM01_DESPESAS_COM_VIAGENS.PLANEJADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM01_DESPESAS_COM_VIAGENS.PLANEJADO);
    }
    if (values.CMM02_FRETES_TRANSPORTES.PLANEJADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM02_FRETES_TRANSPORTES.PLANEJADO);
    }
    if (values.CMM03_OUTROS.PLANEJADO) {
      subTotal_CMM = subTotal_CMM + maskToFloat(values.CMM03_OUTROS.PLANEJADO);
    }

    if (values.CCE01_INSTALACAO_CONSTRUCAO.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE01_INSTALACAO_CONSTRUCAO.PLANEJADO);
    }
    if (values.CCE02_MANUTENCAO.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE02_MANUTENCAO.PLANEJADO);
    }
    if (values.CCE03_ALUGUEIS.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE03_ALUGUEIS.PLANEJADO);
    }
    if (values.CCE04_COMUNICACAO.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE04_COMUNICACAO.PLANEJADO);
    }
    if (values.CCE05_INFORMATICA_TI.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE05_INFORMATICA_TI.PLANEJADO);
    }
    if (values.CCE06_MATERIAIS_DE_ESCRITORIO.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE06_MATERIAIS_DE_ESCRITORIO.PLANEJADO);
    }
    if (values.CCE07_OUTROS.PLANEJADO) {
      subTotal_CCE = subTotal_CCE + maskToFloat(values.CCE07_OUTROS.PLANEJADO);
    }

    if (values.CEQ01_AQUISICAO.PLANEJADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ01_AQUISICAO.PLANEJADO);
    }
    if (values.CEQ02_LOCACAO.PLANEJADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ02_LOCACAO.PLANEJADO);
    }
    if (values.CEQ03_MANUTENCAO.PLANEJADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ03_MANUTENCAO.PLANEJADO);
    }
    if (values.CEQ04_OUTROS.PLANEJADO) {
      subTotal_CEQ = subTotal_CEQ + maskToFloat(values.CEQ04_OUTROS.PLANEJADO);
    }

    if (values.CMA01_DIVERSOS.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA01_DIVERSOS.PLANEJADO);
    }
    if (values.CMA02_CIVIL.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA02_CIVIL.PLANEJADO);
    }
    if (values.CMA03_TUBULACAO.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA03_TUBULACAO.PLANEJADO);
    }
    if (values.CMA04_CALDEIRARIA_ESTRUTURA.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA04_CALDEIRARIA_ESTRUTURA.PLANEJADO);
    }
    if (values.CMA05_ELETRICA_INSTRUMENTACAO.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA05_ELETRICA_INSTRUMENTACAO.PLANEJADO);
    }
    if (values.CMA06_ISOLAMENTO.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA06_ISOLAMENTO.PLANEJADO);
    }
    if (values.CMA07_PINTURA.PLANEJADO) {
      subTotal_CMA = subTotal_CMA + maskToFloat(values.CMA07_PINTURA.PLANEJADO);
    }

    if (values.CMC01_MATERIAL_DE_CONSUMO.PLANEJADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC01_MATERIAL_DE_CONSUMO.PLANEJADO);
    }
    if (values.CMC02_GASES.PLANEJADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC02_GASES.PLANEJADO);
    }
    if (values.CMC03_ELETRODOS_E_VARETAS.PLANEJADO) {
      subTotal_CMC = subTotal_CMC + maskToFloat(values.CMC03_ELETRODOS_E_VARETAS.PLANEJADO);
    }

    if (values.CMV01_MERC_VEND.PLANEJADO) {
      subTotal_CMV = subTotal_CMV + maskToFloat(values.CMV01_MERC_VEND.PLANEJADO);
    }

    if (values.CSB01_DIVERSOS.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB01_DIVERSOS.PLANEJADO);
    }
    if (values.CSB02_CIVIL.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB02_CIVIL.PLANEJADO);
    }
    if (values.CSB03_TUBULACAO.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB03_TUBULACAO.PLANEJADO);
    }
    if (values.CSB04_CALDEIRARIA_ESTRUTURA.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB04_CALDEIRARIA_ESTRUTURA.PLANEJADO);
    }
    if (values.CSB05_ELETRICA_INSTRUMENTACAO.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB05_ELETRICA_INSTRUMENTACAO.PLANEJADO);
    }
    if (values.CSB06_ISOLAMENTO.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB06_ISOLAMENTO.PLANEJADO);
    }
    if (values.CSB07_PINTURA.PLANEJADO) {
      subTotal_CSB = subTotal_CSB + maskToFloat(values.CSB07_PINTURA.PLANEJADO);
    }

    if (values.CDG01_TAXAS_DIVERSAS.PLANEJADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG01_TAXAS_DIVERSAS.PLANEJADO);
    }
    if (values.CDG02_PREMIACOES_PATROCINIO.PLANEJADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG02_PREMIACOES_PATROCINIO.PLANEJADO);
    }
    if (values.CDG03_SEGUROS_MULTAS_JUROS.PLANEJADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG03_SEGUROS_MULTAS_JUROS.PLANEJADO);
    }
    if (values.CDG04_OUTROS.PLANEJADO) {
      subTotal_CDG = subTotal_CDG + maskToFloat(values.CDG04_OUTROS.PLANEJADO);
    }

    if (values.CRC01_CUSTOS_DE_MOD.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC01_CUSTOS_DE_MOD.PLANEJADO);
    }
    if (values.CRC02_CUSTOS_DE_MOI.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC02_CUSTOS_DE_MOI.PLANEJADO);
    }
    if (values.CRC03_CUSTOS_DE_APOIO.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC03_CUSTOS_DE_APOIO.PLANEJADO);
    }
    if (values.CRC04_CUSTOS_DE_MOBILIZACAO.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC04_CUSTOS_DE_MOBILIZACAO.PLANEJADO);
    }
    if (values.CRC05_CUSTOS_DE_CANTEIRO.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC05_CUSTOS_DE_CANTEIRO.PLANEJADO);
    }
    if (values.CRC06_CUSTOS_DE_EQUIPAMENTOS.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC06_CUSTOS_DE_EQUIPAMENTOS.PLANEJADO);
    }
    if (values.CRC07_CUSTOS_DE_MATERIAIS.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC07_CUSTOS_DE_MATERIAIS.PLANEJADO);
    }
    if (values.CRC08_CUSTOS_DE_SUBCONTRATOS.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC08_CUSTOS_DE_SUBCONTRATOS.PLANEJADO);
    }
    if (values.CRC09_CUSTOS_FINANCEIROS.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC09_CUSTOS_FINANCEIROS.PLANEJADO);
    }
    if (values.CRC10_OUTROS.PLANEJADO) {
      subTotal_CRC = subTotal_CRC + maskToFloat(values.CRC10_OUTROS.PLANEJADO);
    }

    subTotal = (subTotal_CMD + subTotal_CMI + subTotal_CAP + subTotal_CMM + subTotal_CCE + subTotal_CEQ + subTotal_CMA + subTotal_CMC + subTotal_CMV + subTotal_CSB + subTotal_CDG + subTotal_CRC);


    setFieldValue('TOTAL_CUSTOS_CMD.PLANEJADO', subTotal_CMD);
    setFieldValue('TOTAL_CUSTOS_CMI.PLANEJADO', subTotal_CMI);
    setFieldValue('TOTAL_CUSTOS_CAP.PLANEJADO', subTotal_CAP);
    setFieldValue('TOTAL_CUSTOS_CMM.PLANEJADO', subTotal_CMM);
    setFieldValue('TOTAL_CUSTOS_CCE.PLANEJADO', subTotal_CCE);
    setFieldValue('TOTAL_CUSTOS_CEQ.PLANEJADO', subTotal_CEQ);
    setFieldValue('TOTAL_CUSTOS_CMA.PLANEJADO', subTotal_CMA);
    setFieldValue('TOTAL_CUSTOS_CMC.PLANEJADO', subTotal_CMC);
    setFieldValue('TOTAL_CUSTOS_CMV.PLANEJADO', subTotal_CMV);
    setFieldValue('TOTAL_CUSTOS_CSB.PLANEJADO', subTotal_CSB);
    setFieldValue('TOTAL_CUSTOS_CDG.PLANEJADO', subTotal_CDG);
    setFieldValue('TOTAL_CUSTOS_CRC.PLANEJADO', subTotal_CRC);
    setFieldValue('TOTAL_CUSTOS.PLANEJADO', subTotal);


    let marginReal = 0.00;
    let marginPorc = 0.00;


    marginReal = returnCalcMarginsAndTaxOfLS_Planned().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Planned().PORC;

    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.PLANEJADO', marginReal);
    setFieldValue('MARGEM_PORC.PLANEJADO', marginPorc);
  };

  function saveOnLocalStoragePL(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[7].CUS.forEach((invoicing) => {
      if (`${field?.subSet}${field?.label?.substring(0, 2)}` === 'CMV01') {
        field.subSet = 'CMV';
        field.label = '-Merc.Vend.';
      };
      
      if (invoicing.TIPO.substring(0, 5) === (`${field.subSet.substring(0, 3)}${field.label.substring(0, 2)}`)) {
        invoicing.PLANEJADO = e;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PREVISTO
  const listFieldsProviderT = (_values) => {
    const list = [];

    list.push(
      { name: 'TOTAL_CUSTOS_CMD.JAN', label: 'JAN', value: total_state.JAN, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.FEV', label: 'FEV', value: total_state.FEV, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.MAR', label: 'MAR', value: total_state.MAR, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.ABR', label: 'ABR', value: total_state.ABR, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.MAI', label: 'MAI', value: total_state.MAI, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.JUN', label: 'JUN', value: total_state.JUN, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.JUL', label: 'JUL', value: total_state.JUL, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.AGO', label: 'AGO', value: total_state.AGO, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.SET', label: 'SET', value: total_state.SET, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.OUT', label: 'OUT', value: total_state.OUT, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.NOV', label: 'NOV', value: total_state.NOV, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.DEZ', label: 'DEZ', value: total_state.DEZ, typeRelease: releaseList[0], subSet: 'TOT', account: 'TOTAL', month: 11, disabled: true },

    );

    return list;
  };

  const listFieldsProvider1 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMD.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMD.JAN, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMD.FEV, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMD.MAR, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMD.ABR, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMD.MAI, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMD.JUN, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMD.JUL, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMD.AGO, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMD.SET, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMD.OUT, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMD.NOV, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMD.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMD.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMD', account: 'CMDTO', month: 11, disabled: true },

      { name: 'CMD01_SALARIOS.JAN', label: 'JAN', value: values.CMD01_SALARIOS.JAN, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMD01_SALARIOS.FEV', label: 'FEV', value: values.CMD01_SALARIOS.FEV, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMD01_SALARIOS.MAR', label: 'MAR', value: values.CMD01_SALARIOS.MAR, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMD01_SALARIOS.ABR', label: 'ABR', value: values.CMD01_SALARIOS.ABR, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMD01_SALARIOS.MAI', label: 'MAI', value: values.CMD01_SALARIOS.MAI, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMD01_SALARIOS.JUN', label: 'JUN', value: values.CMD01_SALARIOS.JUN, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMD01_SALARIOS.JUL', label: 'JUL', value: values.CMD01_SALARIOS.JUL, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMD01_SALARIOS.AGO', label: 'AGO', value: values.CMD01_SALARIOS.AGO, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMD01_SALARIOS.SET', label: 'SET', value: values.CMD01_SALARIOS.SET, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMD01_SALARIOS.OUT', label: 'OUT', value: values.CMD01_SALARIOS.OUT, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMD01_SALARIOS.NOV', label: 'NOV', value: values.CMD01_SALARIOS.NOV, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMD01_SALARIOS.DEZ', label: 'DEZ', value: values.CMD01_SALARIOS.DEZ, typeRelease: releaseList[1], subSet: 'CMD', account: 'CMD01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMD02_ENCARGOS.JAN', label: 'JAN', value: values.CMD02_ENCARGOS.JAN, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMD02_ENCARGOS.FEV', label: 'FEV', value: values.CMD02_ENCARGOS.FEV, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMD02_ENCARGOS.MAR', label: 'MAR', value: values.CMD02_ENCARGOS.MAR, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMD02_ENCARGOS.ABR', label: 'ABR', value: values.CMD02_ENCARGOS.ABR, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMD02_ENCARGOS.MAI', label: 'MAI', value: values.CMD02_ENCARGOS.MAI, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMD02_ENCARGOS.JUN', label: 'JUN', value: values.CMD02_ENCARGOS.JUN, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMD02_ENCARGOS.JUL', label: 'JUL', value: values.CMD02_ENCARGOS.JUL, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMD02_ENCARGOS.AGO', label: 'AGO', value: values.CMD02_ENCARGOS.AGO, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMD02_ENCARGOS.SET', label: 'SET', value: values.CMD02_ENCARGOS.SET, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMD02_ENCARGOS.OUT', label: 'OUT', value: values.CMD02_ENCARGOS.OUT, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMD02_ENCARGOS.NOV', label: 'NOV', value: values.CMD02_ENCARGOS.NOV, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMD02_ENCARGOS.DEZ', label: 'DEZ', value: values.CMD02_ENCARGOS.DEZ, typeRelease: releaseList[2], subSet: 'CMD', account: 'CMD02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.JAN', label: 'JAN', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.JAN, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.FEV', label: 'FEV', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.FEV, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.MAR', label: 'MAR', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.MAR, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.ABR', label: 'ABR', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.ABR, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.MAI', label: 'MAI', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.MAI, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.JUN', label: 'JUN', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.JUN, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.JUL', label: 'JUL', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.JUL, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.AGO', label: 'AGO', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.AGO, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.SET', label: 'SET', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.SET, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.OUT', label: 'OUT', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.OUT, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.NOV', label: 'NOV', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.NOV, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMD03_HORAS_EXTRAS_ADICIONAL.DEZ', label: 'DEZ', value: values.CMD03_HORAS_EXTRAS_ADICIONAL.DEZ, typeRelease: releaseList[3], subSet: 'CMD', account: 'CMD03', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider2 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMI.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMI.JAN, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMI.FEV, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMI.MAR, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMI.ABR, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMI.MAI, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMI.JUN, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMI.JUL, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMI.AGO, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMI.SET, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMI.OUT, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMI.NOV, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMI.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMI.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMI', account: 'CMITO', month: 11, disabled: true },

      { name: 'CMI01_SALARIOS.JAN', label: 'JAN', value: values.CMI01_SALARIOS.JAN, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMI01_SALARIOS.FEV', label: 'FEV', value: values.CMI01_SALARIOS.FEV, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMI01_SALARIOS.MAR', label: 'MAR', value: values.CMI01_SALARIOS.MAR, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMI01_SALARIOS.ABR', label: 'ABR', value: values.CMI01_SALARIOS.ABR, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMI01_SALARIOS.MAI', label: 'MAI', value: values.CMI01_SALARIOS.MAI, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMI01_SALARIOS.JUN', label: 'JUN', value: values.CMI01_SALARIOS.JUN, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMI01_SALARIOS.JUL', label: 'JUL', value: values.CMI01_SALARIOS.JUL, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMI01_SALARIOS.AGO', label: 'AGO', value: values.CMI01_SALARIOS.AGO, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMI01_SALARIOS.SET', label: 'SET', value: values.CMI01_SALARIOS.SET, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMI01_SALARIOS.OUT', label: 'OUT', value: values.CMI01_SALARIOS.OUT, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMI01_SALARIOS.NOV', label: 'NOV', value: values.CMI01_SALARIOS.NOV, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMI01_SALARIOS.DEZ', label: 'DEZ', value: values.CMI01_SALARIOS.DEZ, typeRelease: releaseList[4], subSet: 'CMI', account: 'CMI01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMI02_ENCARGOS.JAN', label: 'JAN', value: values.CMI02_ENCARGOS.JAN, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMI02_ENCARGOS.FEV', label: 'FEV', value: values.CMI02_ENCARGOS.FEV, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMI02_ENCARGOS.MAR', label: 'MAR', value: values.CMI02_ENCARGOS.MAR, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMI02_ENCARGOS.ABR', label: 'ABR', value: values.CMI02_ENCARGOS.ABR, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMI02_ENCARGOS.MAI', label: 'MAI', value: values.CMI02_ENCARGOS.MAI, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMI02_ENCARGOS.JUN', label: 'JUN', value: values.CMI02_ENCARGOS.JUN, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMI02_ENCARGOS.JUL', label: 'JUL', value: values.CMI02_ENCARGOS.JUL, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMI02_ENCARGOS.AGO', label: 'AGO', value: values.CMI02_ENCARGOS.AGO, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMI02_ENCARGOS.SET', label: 'SET', value: values.CMI02_ENCARGOS.SET, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMI02_ENCARGOS.OUT', label: 'OUT', value: values.CMI02_ENCARGOS.OUT, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMI02_ENCARGOS.NOV', label: 'NOV', value: values.CMI02_ENCARGOS.NOV, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMI02_ENCARGOS.DEZ', label: 'DEZ', value: values.CMI02_ENCARGOS.DEZ, typeRelease: releaseList[5], subSet: 'CMI', account: 'CMI02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.JAN', label: 'JAN', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.JAN, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.FEV', label: 'FEV', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.FEV, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.MAR', label: 'MAR', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.MAR, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.ABR', label: 'ABR', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.ABR, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.MAI', label: 'MAI', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.MAI, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.JUN', label: 'JUN', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.JUN, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.JUL', label: 'JUL', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.JUL, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.AGO', label: 'AGO', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.AGO, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.SET', label: 'SET', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.SET, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.OUT', label: 'OUT', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.OUT, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.NOV', label: 'NOV', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.NOV, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMI03_HORAS_EXTRAS_ADICIONAL.DEZ', label: 'DEZ', value: values.CMI03_HORAS_EXTRAS_ADICIONAL.DEZ, typeRelease: releaseList[6], subSet: 'CMI', account: 'CMI03', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider3 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CAP.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CAP.JAN, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CAP.FEV, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CAP.MAR, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CAP.ABR, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CAP.MAI, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CAP.JUN, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CAP.JUL, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CAP.AGO, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.SET', label: 'SET', value: values.TOTAL_CUSTOS_CAP.SET, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CAP.OUT, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CAP.NOV, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CAP.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CAP.DEZ, typeRelease: releaseListSubtotal, subSet: 'CAP', account: 'CAPTO', month: 11, disabled: true },

      { name: 'CAP01_TRANSPORTE.JAN', label: 'JAN', value: values.CAP01_TRANSPORTE.JAN, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP01_TRANSPORTE.FEV', label: 'FEV', value: values.CAP01_TRANSPORTE.FEV, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP01_TRANSPORTE.MAR', label: 'MAR', value: values.CAP01_TRANSPORTE.MAR, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP01_TRANSPORTE.ABR', label: 'ABR', value: values.CAP01_TRANSPORTE.ABR, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP01_TRANSPORTE.MAI', label: 'MAI', value: values.CAP01_TRANSPORTE.MAI, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP01_TRANSPORTE.JUN', label: 'JUN', value: values.CAP01_TRANSPORTE.JUN, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP01_TRANSPORTE.JUL', label: 'JUL', value: values.CAP01_TRANSPORTE.JUL, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP01_TRANSPORTE.AGO', label: 'AGO', value: values.CAP01_TRANSPORTE.AGO, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP01_TRANSPORTE.SET', label: 'SET', value: values.CAP01_TRANSPORTE.SET, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP01_TRANSPORTE.OUT', label: 'OUT', value: values.CAP01_TRANSPORTE.OUT, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP01_TRANSPORTE.NOV', label: 'NOV', value: values.CAP01_TRANSPORTE.NOV, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP01_TRANSPORTE.DEZ', label: 'DEZ', value: values.CAP01_TRANSPORTE.DEZ, typeRelease: releaseList[7], subSet: 'CAP', account: 'CAP01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP02_ALIMENTACAO.JAN', label: 'JAN', value: values.CAP02_ALIMENTACAO.JAN, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP02_ALIMENTACAO.FEV', label: 'FEV', value: values.CAP02_ALIMENTACAO.FEV, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP02_ALIMENTACAO.MAR', label: 'MAR', value: values.CAP02_ALIMENTACAO.MAR, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP02_ALIMENTACAO.ABR', label: 'ABR', value: values.CAP02_ALIMENTACAO.ABR, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP02_ALIMENTACAO.MAI', label: 'MAI', value: values.CAP02_ALIMENTACAO.MAI, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP02_ALIMENTACAO.JUN', label: 'JUN', value: values.CAP02_ALIMENTACAO.JUN, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP02_ALIMENTACAO.JUL', label: 'JUL', value: values.CAP02_ALIMENTACAO.JUL, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP02_ALIMENTACAO.AGO', label: 'AGO', value: values.CAP02_ALIMENTACAO.AGO, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP02_ALIMENTACAO.SET', label: 'SET', value: values.CAP02_ALIMENTACAO.SET, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP02_ALIMENTACAO.OUT', label: 'OUT', value: values.CAP02_ALIMENTACAO.OUT, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP02_ALIMENTACAO.NOV', label: 'NOV', value: values.CAP02_ALIMENTACAO.NOV, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP02_ALIMENTACAO.DEZ', label: 'DEZ', value: values.CAP02_ALIMENTACAO.DEZ, typeRelease: releaseList[8], subSet: 'CAP', account: 'CAP02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP03_EPI_E_UNIFORMES.JAN', label: 'JAN', value: values.CAP03_EPI_E_UNIFORMES.JAN, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP03_EPI_E_UNIFORMES.FEV', label: 'FEV', value: values.CAP03_EPI_E_UNIFORMES.FEV, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP03_EPI_E_UNIFORMES.MAR', label: 'MAR', value: values.CAP03_EPI_E_UNIFORMES.MAR, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP03_EPI_E_UNIFORMES.ABR', label: 'ABR', value: values.CAP03_EPI_E_UNIFORMES.ABR, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP03_EPI_E_UNIFORMES.MAI', label: 'MAI', value: values.CAP03_EPI_E_UNIFORMES.MAI, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP03_EPI_E_UNIFORMES.JUN', label: 'JUN', value: values.CAP03_EPI_E_UNIFORMES.JUN, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP03_EPI_E_UNIFORMES.JUL', label: 'JUL', value: values.CAP03_EPI_E_UNIFORMES.JUL, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP03_EPI_E_UNIFORMES.AGO', label: 'AGO', value: values.CAP03_EPI_E_UNIFORMES.AGO, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP03_EPI_E_UNIFORMES.SET', label: 'SET', value: values.CAP03_EPI_E_UNIFORMES.SET, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP03_EPI_E_UNIFORMES.OUT', label: 'OUT', value: values.CAP03_EPI_E_UNIFORMES.OUT, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP03_EPI_E_UNIFORMES.NOV', label: 'NOV', value: values.CAP03_EPI_E_UNIFORMES.NOV, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP03_EPI_E_UNIFORMES.DEZ', label: 'DEZ', value: values.CAP03_EPI_E_UNIFORMES.DEZ, typeRelease: releaseList[9], subSet: 'CAP', account: 'CAP03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP04_ALOJAMENTO.JAN', label: 'JAN', value: values.CAP04_ALOJAMENTO.JAN, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP04_ALOJAMENTO.FEV', label: 'FEV', value: values.CAP04_ALOJAMENTO.FEV, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP04_ALOJAMENTO.MAR', label: 'MAR', value: values.CAP04_ALOJAMENTO.MAR, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP04_ALOJAMENTO.ABR', label: 'ABR', value: values.CAP04_ALOJAMENTO.ABR, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP04_ALOJAMENTO.MAI', label: 'MAI', value: values.CAP04_ALOJAMENTO.MAI, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP04_ALOJAMENTO.JUN', label: 'JUN', value: values.CAP04_ALOJAMENTO.JUN, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP04_ALOJAMENTO.JUL', label: 'JUL', value: values.CAP04_ALOJAMENTO.JUL, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP04_ALOJAMENTO.AGO', label: 'AGO', value: values.CAP04_ALOJAMENTO.AGO, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP04_ALOJAMENTO.SET', label: 'SET', value: values.CAP04_ALOJAMENTO.SET, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP04_ALOJAMENTO.OUT', label: 'OUT', value: values.CAP04_ALOJAMENTO.OUT, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP04_ALOJAMENTO.NOV', label: 'NOV', value: values.CAP04_ALOJAMENTO.NOV, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP04_ALOJAMENTO.DEZ', label: 'DEZ', value: values.CAP04_ALOJAMENTO.DEZ, typeRelease: releaseList[10], subSet: 'CAP', account: 'CAP04', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP05_CURSOS_E_TREINAMENTO.JAN', label: 'JAN', value: values.CAP05_CURSOS_E_TREINAMENTO.JAN, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.FEV', label: 'FEV', value: values.CAP05_CURSOS_E_TREINAMENTO.FEV, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.MAR', label: 'MAR', value: values.CAP05_CURSOS_E_TREINAMENTO.MAR, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.ABR', label: 'ABR', value: values.CAP05_CURSOS_E_TREINAMENTO.ABR, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.MAI', label: 'MAI', value: values.CAP05_CURSOS_E_TREINAMENTO.MAI, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.JUN', label: 'JUN', value: values.CAP05_CURSOS_E_TREINAMENTO.JUN, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.JUL', label: 'JUL', value: values.CAP05_CURSOS_E_TREINAMENTO.JUL, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.AGO', label: 'AGO', value: values.CAP05_CURSOS_E_TREINAMENTO.AGO, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.SET', label: 'SET', value: values.CAP05_CURSOS_E_TREINAMENTO.SET, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.OUT', label: 'OUT', value: values.CAP05_CURSOS_E_TREINAMENTO.OUT, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.NOV', label: 'NOV', value: values.CAP05_CURSOS_E_TREINAMENTO.NOV, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP05_CURSOS_E_TREINAMENTO.DEZ', label: 'DEZ', value: values.CAP05_CURSOS_E_TREINAMENTO.DEZ, typeRelease: releaseList[11], subSet: 'CAP', account: 'CAP05', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP06_ASSISTENCIA_MEDICA.JAN', label: 'JAN', value: values.CAP06_ASSISTENCIA_MEDICA.JAN, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.FEV', label: 'FEV', value: values.CAP06_ASSISTENCIA_MEDICA.FEV, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.MAR', label: 'MAR', value: values.CAP06_ASSISTENCIA_MEDICA.MAR, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.ABR', label: 'ABR', value: values.CAP06_ASSISTENCIA_MEDICA.ABR, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.MAI', label: 'MAI', value: values.CAP06_ASSISTENCIA_MEDICA.MAI, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.JUN', label: 'JUN', value: values.CAP06_ASSISTENCIA_MEDICA.JUN, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.JUL', label: 'JUL', value: values.CAP06_ASSISTENCIA_MEDICA.JUL, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.AGO', label: 'AGO', value: values.CAP06_ASSISTENCIA_MEDICA.AGO, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.SET', label: 'SET', value: values.CAP06_ASSISTENCIA_MEDICA.SET, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.OUT', label: 'OUT', value: values.CAP06_ASSISTENCIA_MEDICA.OUT, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.NOV', label: 'NOV', value: values.CAP06_ASSISTENCIA_MEDICA.NOV, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP06_ASSISTENCIA_MEDICA.DEZ', label: 'DEZ', value: values.CAP06_ASSISTENCIA_MEDICA.DEZ, typeRelease: releaseList[12], subSet: 'CAP', account: 'CAP06', month: 11, disabled: currentMonth >= 12 },

      { name: 'CAP07_OUTROS.JAN', label: 'JAN', value: values.CAP07_OUTROS.JAN, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 0, disabled: currentMonth >= 1 },
      { name: 'CAP07_OUTROS.FEV', label: 'FEV', value: values.CAP07_OUTROS.FEV, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 1, disabled: currentMonth >= 2 },
      { name: 'CAP07_OUTROS.MAR', label: 'MAR', value: values.CAP07_OUTROS.MAR, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 2, disabled: currentMonth >= 3 },
      { name: 'CAP07_OUTROS.ABR', label: 'ABR', value: values.CAP07_OUTROS.ABR, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 3, disabled: currentMonth >= 4 },
      { name: 'CAP07_OUTROS.MAI', label: 'MAI', value: values.CAP07_OUTROS.MAI, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 4, disabled: currentMonth >= 5 },
      { name: 'CAP07_OUTROS.JUN', label: 'JUN', value: values.CAP07_OUTROS.JUN, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 5, disabled: currentMonth >= 6 },
      { name: 'CAP07_OUTROS.JUL', label: 'JUL', value: values.CAP07_OUTROS.JUL, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 6, disabled: currentMonth >= 7 },
      { name: 'CAP07_OUTROS.AGO', label: 'AGO', value: values.CAP07_OUTROS.AGO, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 7, disabled: currentMonth >= 8 },
      { name: 'CAP07_OUTROS.SET', label: 'SET', value: values.CAP07_OUTROS.SET, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 8, disabled: currentMonth >= 9 },
      { name: 'CAP07_OUTROS.OUT', label: 'OUT', value: values.CAP07_OUTROS.OUT, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 9, disabled: currentMonth >= 10 },
      { name: 'CAP07_OUTROS.NOV', label: 'NOV', value: values.CAP07_OUTROS.NOV, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 10, disabled: currentMonth >= 11 },
      { name: 'CAP07_OUTROS.DEZ', label: 'DEZ', value: values.CAP07_OUTROS.DEZ, typeRelease: releaseList[13], subSet: 'CAP', account: 'CAP07', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider4 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMM.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMM.JAN, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMM.FEV, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMM.MAR, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMM.ABR, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMM.MAI, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMM.JUN, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMM.JUL, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMM.AGO, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMM.SET, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMM.OUT, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMM.NOV, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMM.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMM.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMM', account: 'CMMTO', month: 11, disabled: true },

      { name: 'CMM01_DESPESAS_COM_VIAGENS.JAN', label: 'JAN', value: values.CMM01_DESPESAS_COM_VIAGENS.JAN, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.FEV', label: 'FEV', value: values.CMM01_DESPESAS_COM_VIAGENS.FEV, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.MAR', label: 'MAR', value: values.CMM01_DESPESAS_COM_VIAGENS.MAR, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.ABR', label: 'ABR', value: values.CMM01_DESPESAS_COM_VIAGENS.ABR, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.MAI', label: 'MAI', value: values.CMM01_DESPESAS_COM_VIAGENS.MAI, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.JUN', label: 'JUN', value: values.CMM01_DESPESAS_COM_VIAGENS.JUN, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.JUL', label: 'JUL', value: values.CMM01_DESPESAS_COM_VIAGENS.JUL, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.AGO', label: 'AGO', value: values.CMM01_DESPESAS_COM_VIAGENS.AGO, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.SET', label: 'SET', value: values.CMM01_DESPESAS_COM_VIAGENS.SET, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.OUT', label: 'OUT', value: values.CMM01_DESPESAS_COM_VIAGENS.OUT, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.NOV', label: 'NOV', value: values.CMM01_DESPESAS_COM_VIAGENS.NOV, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMM01_DESPESAS_COM_VIAGENS.DEZ', label: 'DEZ', value: values.CMM01_DESPESAS_COM_VIAGENS.DEZ, typeRelease: releaseList[14], subSet: 'CMM', account: 'CMM01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMM02_FRETES_TRANSPORTES.JAN', label: 'JAN', value: values.CMM02_FRETES_TRANSPORTES.JAN, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMM02_FRETES_TRANSPORTES.FEV', label: 'FEV', value: values.CMM02_FRETES_TRANSPORTES.FEV, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMM02_FRETES_TRANSPORTES.MAR', label: 'MAR', value: values.CMM02_FRETES_TRANSPORTES.MAR, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMM02_FRETES_TRANSPORTES.ABR', label: 'ABR', value: values.CMM02_FRETES_TRANSPORTES.ABR, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMM02_FRETES_TRANSPORTES.MAI', label: 'MAI', value: values.CMM02_FRETES_TRANSPORTES.MAI, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMM02_FRETES_TRANSPORTES.JUN', label: 'JUN', value: values.CMM02_FRETES_TRANSPORTES.JUN, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMM02_FRETES_TRANSPORTES.JUL', label: 'JUL', value: values.CMM02_FRETES_TRANSPORTES.JUL, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMM02_FRETES_TRANSPORTES.AGO', label: 'AGO', value: values.CMM02_FRETES_TRANSPORTES.AGO, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMM02_FRETES_TRANSPORTES.SET', label: 'SET', value: values.CMM02_FRETES_TRANSPORTES.SET, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMM02_FRETES_TRANSPORTES.OUT', label: 'OUT', value: values.CMM02_FRETES_TRANSPORTES.OUT, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMM02_FRETES_TRANSPORTES.NOV', label: 'NOV', value: values.CMM02_FRETES_TRANSPORTES.NOV, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMM02_FRETES_TRANSPORTES.DEZ', label: 'DEZ', value: values.CMM02_FRETES_TRANSPORTES.DEZ, typeRelease: releaseList[15], subSet: 'CMM', account: 'CMM02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMM03_OUTROS.JAN', label: 'JAN', value: values.CMM03_OUTROS.JAN, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMM03_OUTROS.FEV', label: 'FEV', value: values.CMM03_OUTROS.FEV, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMM03_OUTROS.MAR', label: 'MAR', value: values.CMM03_OUTROS.MAR, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMM03_OUTROS.ABR', label: 'ABR', value: values.CMM03_OUTROS.ABR, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMM03_OUTROS.MAI', label: 'MAI', value: values.CMM03_OUTROS.MAI, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMM03_OUTROS.JUN', label: 'JUN', value: values.CMM03_OUTROS.JUN, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMM03_OUTROS.JUL', label: 'JUL', value: values.CMM03_OUTROS.JUL, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMM03_OUTROS.AGO', label: 'AGO', value: values.CMM03_OUTROS.AGO, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMM03_OUTROS.SET', label: 'SET', value: values.CMM03_OUTROS.SET, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMM03_OUTROS.OUT', label: 'OUT', value: values.CMM03_OUTROS.OUT, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMM03_OUTROS.NOV', label: 'NOV', value: values.CMM03_OUTROS.NOV, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMM03_OUTROS.DEZ', label: 'DEZ', value: values.CMM03_OUTROS.DEZ, typeRelease: releaseList[16], subSet: 'CMM', account: 'CMM03', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider5 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CCE.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CCE.JAN, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CCE.FEV, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CCE.MAR, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CCE.ABR, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CCE.MAI, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CCE.JUN, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CCE.JUL, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CCE.AGO, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.SET', label: 'SET', value: values.TOTAL_CUSTOS_CCE.SET, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CCE.OUT, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CCE.NOV, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CCE.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CCE.DEZ, typeRelease: releaseListSubtotal, subSet: 'CCE', account: 'CCETO', month: 11, disabled: true },

      { name: 'CCE01_INSTALACAO_CONSTRUCAO.JAN', label: 'JAN', value: values.CCE01_INSTALACAO_CONSTRUCAO.JAN, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.FEV', label: 'FEV', value: values.CCE01_INSTALACAO_CONSTRUCAO.FEV, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.MAR', label: 'MAR', value: values.CCE01_INSTALACAO_CONSTRUCAO.MAR, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.ABR', label: 'ABR', value: values.CCE01_INSTALACAO_CONSTRUCAO.ABR, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.MAI', label: 'MAI', value: values.CCE01_INSTALACAO_CONSTRUCAO.MAI, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.JUN', label: 'JUN', value: values.CCE01_INSTALACAO_CONSTRUCAO.JUN, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.JUL', label: 'JUL', value: values.CCE01_INSTALACAO_CONSTRUCAO.JUL, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.AGO', label: 'AGO', value: values.CCE01_INSTALACAO_CONSTRUCAO.AGO, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.SET', label: 'SET', value: values.CCE01_INSTALACAO_CONSTRUCAO.SET, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.OUT', label: 'OUT', value: values.CCE01_INSTALACAO_CONSTRUCAO.OUT, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.NOV', label: 'NOV', value: values.CCE01_INSTALACAO_CONSTRUCAO.NOV, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE01_INSTALACAO_CONSTRUCAO.DEZ', label: 'DEZ', value: values.CCE01_INSTALACAO_CONSTRUCAO.DEZ, typeRelease: releaseList[17], subSet: 'CCE', account: 'CCE01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE02_MANUTENCAO.JAN', label: 'JAN', value: values.CCE02_MANUTENCAO.JAN, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE02_MANUTENCAO.FEV', label: 'FEV', value: values.CCE02_MANUTENCAO.FEV, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE02_MANUTENCAO.MAR', label: 'MAR', value: values.CCE02_MANUTENCAO.MAR, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE02_MANUTENCAO.ABR', label: 'ABR', value: values.CCE02_MANUTENCAO.ABR, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE02_MANUTENCAO.MAI', label: 'MAI', value: values.CCE02_MANUTENCAO.MAI, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE02_MANUTENCAO.JUN', label: 'JUN', value: values.CCE02_MANUTENCAO.JUN, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE02_MANUTENCAO.JUL', label: 'JUL', value: values.CCE02_MANUTENCAO.JUL, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE02_MANUTENCAO.AGO', label: 'AGO', value: values.CCE02_MANUTENCAO.AGO, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE02_MANUTENCAO.SET', label: 'SET', value: values.CCE02_MANUTENCAO.SET, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE02_MANUTENCAO.OUT', label: 'OUT', value: values.CCE02_MANUTENCAO.OUT, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE02_MANUTENCAO.NOV', label: 'NOV', value: values.CCE02_MANUTENCAO.NOV, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE02_MANUTENCAO.DEZ', label: 'DEZ', value: values.CCE02_MANUTENCAO.DEZ, typeRelease: releaseList[18], subSet: 'CCE', account: 'CCE02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE03_ALUGUEIS.JAN', label: 'JAN', value: values.CCE03_ALUGUEIS.JAN, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE03_ALUGUEIS.FEV', label: 'FEV', value: values.CCE03_ALUGUEIS.FEV, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE03_ALUGUEIS.MAR', label: 'MAR', value: values.CCE03_ALUGUEIS.MAR, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE03_ALUGUEIS.ABR', label: 'ABR', value: values.CCE03_ALUGUEIS.ABR, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE03_ALUGUEIS.MAI', label: 'MAI', value: values.CCE03_ALUGUEIS.MAI, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE03_ALUGUEIS.JUN', label: 'JUN', value: values.CCE03_ALUGUEIS.JUN, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE03_ALUGUEIS.JUL', label: 'JUL', value: values.CCE03_ALUGUEIS.JUL, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE03_ALUGUEIS.AGO', label: 'AGO', value: values.CCE03_ALUGUEIS.AGO, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE03_ALUGUEIS.SET', label: 'SET', value: values.CCE03_ALUGUEIS.SET, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE03_ALUGUEIS.OUT', label: 'OUT', value: values.CCE03_ALUGUEIS.OUT, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE03_ALUGUEIS.NOV', label: 'NOV', value: values.CCE03_ALUGUEIS.NOV, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE03_ALUGUEIS.DEZ', label: 'DEZ', value: values.CCE03_ALUGUEIS.DEZ, typeRelease: releaseList[19], subSet: 'CCE', account: 'CCE03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE04_COMUNICACAO.JAN', label: 'JAN', value: values.CCE04_COMUNICACAO.JAN, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE04_COMUNICACAO.FEV', label: 'FEV', value: values.CCE04_COMUNICACAO.FEV, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE04_COMUNICACAO.MAR', label: 'MAR', value: values.CCE04_COMUNICACAO.MAR, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE04_COMUNICACAO.ABR', label: 'ABR', value: values.CCE04_COMUNICACAO.ABR, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE04_COMUNICACAO.MAI', label: 'MAI', value: values.CCE04_COMUNICACAO.MAI, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE04_COMUNICACAO.JUN', label: 'JUN', value: values.CCE04_COMUNICACAO.JUN, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE04_COMUNICACAO.JUL', label: 'JUL', value: values.CCE04_COMUNICACAO.JUL, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE04_COMUNICACAO.AGO', label: 'AGO', value: values.CCE04_COMUNICACAO.AGO, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE04_COMUNICACAO.SET', label: 'SET', value: values.CCE04_COMUNICACAO.SET, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE04_COMUNICACAO.OUT', label: 'OUT', value: values.CCE04_COMUNICACAO.OUT, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE04_COMUNICACAO.NOV', label: 'NOV', value: values.CCE04_COMUNICACAO.NOV, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE04_COMUNICACAO.DEZ', label: 'DEZ', value: values.CCE04_COMUNICACAO.DEZ, typeRelease: releaseList[20], subSet: 'CCE', account: 'CCE04', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE05_INFORMATICA_TI.JAN', label: 'JAN', value: values.CCE05_INFORMATICA_TI.JAN, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE05_INFORMATICA_TI.FEV', label: 'FEV', value: values.CCE05_INFORMATICA_TI.FEV, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE05_INFORMATICA_TI.MAR', label: 'MAR', value: values.CCE05_INFORMATICA_TI.MAR, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE05_INFORMATICA_TI.ABR', label: 'ABR', value: values.CCE05_INFORMATICA_TI.ABR, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE05_INFORMATICA_TI.MAI', label: 'MAI', value: values.CCE05_INFORMATICA_TI.MAI, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE05_INFORMATICA_TI.JUN', label: 'JUN', value: values.CCE05_INFORMATICA_TI.JUN, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE05_INFORMATICA_TI.JUL', label: 'JUL', value: values.CCE05_INFORMATICA_TI.JUL, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE05_INFORMATICA_TI.AGO', label: 'AGO', value: values.CCE05_INFORMATICA_TI.AGO, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE05_INFORMATICA_TI.SET', label: 'SET', value: values.CCE05_INFORMATICA_TI.SET, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE05_INFORMATICA_TI.OUT', label: 'OUT', value: values.CCE05_INFORMATICA_TI.OUT, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE05_INFORMATICA_TI.NOV', label: 'NOV', value: values.CCE05_INFORMATICA_TI.NOV, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE05_INFORMATICA_TI.DEZ', label: 'DEZ', value: values.CCE05_INFORMATICA_TI.DEZ, typeRelease: releaseList[21], subSet: 'CCE', account: 'CCE05', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.JAN', label: 'JAN', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.JAN, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.FEV', label: 'FEV', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.FEV, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.MAR', label: 'MAR', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.MAR, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.ABR', label: 'ABR', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.ABR, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.MAI', label: 'MAI', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.MAI, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.JUN', label: 'JUN', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.JUN, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.JUL', label: 'JUL', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.JUL, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.AGO', label: 'AGO', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.AGO, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.SET', label: 'SET', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.SET, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.OUT', label: 'OUT', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.OUT, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.NOV', label: 'NOV', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.NOV, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE06_MATERIAIS_DE_ESCRITORIO.DEZ', label: 'DEZ', value: values.CCE06_MATERIAIS_DE_ESCRITORIO.DEZ, typeRelease: releaseList[22], subSet: 'CCE', account: 'CCE06', month: 11, disabled: currentMonth >= 12 },

      { name: 'CCE07_OUTROS.JAN', label: 'JAN', value: values.CCE07_OUTROS.JAN, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 0, disabled: currentMonth >= 1 },
      { name: 'CCE07_OUTROS.FEV', label: 'FEV', value: values.CCE07_OUTROS.FEV, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 1, disabled: currentMonth >= 2 },
      { name: 'CCE07_OUTROS.MAR', label: 'MAR', value: values.CCE07_OUTROS.MAR, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 2, disabled: currentMonth >= 3 },
      { name: 'CCE07_OUTROS.ABR', label: 'ABR', value: values.CCE07_OUTROS.ABR, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 3, disabled: currentMonth >= 4 },
      { name: 'CCE07_OUTROS.MAI', label: 'MAI', value: values.CCE07_OUTROS.MAI, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 4, disabled: currentMonth >= 5 },
      { name: 'CCE07_OUTROS.JUN', label: 'JUN', value: values.CCE07_OUTROS.JUN, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 5, disabled: currentMonth >= 6 },
      { name: 'CCE07_OUTROS.JUL', label: 'JUL', value: values.CCE07_OUTROS.JUL, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 6, disabled: currentMonth >= 7 },
      { name: 'CCE07_OUTROS.AGO', label: 'AGO', value: values.CCE07_OUTROS.AGO, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 7, disabled: currentMonth >= 8 },
      { name: 'CCE07_OUTROS.SET', label: 'SET', value: values.CCE07_OUTROS.SET, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 8, disabled: currentMonth >= 9 },
      { name: 'CCE07_OUTROS.OUT', label: 'OUT', value: values.CCE07_OUTROS.OUT, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 9, disabled: currentMonth >= 10 },
      { name: 'CCE07_OUTROS.NOV', label: 'NOV', value: values.CCE07_OUTROS.NOV, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 10, disabled: currentMonth >= 11 },
      { name: 'CCE07_OUTROS.DEZ', label: 'DEZ', value: values.CCE07_OUTROS.DEZ, typeRelease: releaseList[23], subSet: 'CCE', account: 'CCE07', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider6 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CEQ.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CEQ.JAN, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CEQ.FEV, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CEQ.MAR, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CEQ.ABR, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CEQ.MAI, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CEQ.JUN, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CEQ.JUL, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CEQ.AGO, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.SET', label: 'SET', value: values.TOTAL_CUSTOS_CEQ.SET, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CEQ.OUT, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CEQ.NOV, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CEQ.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CEQ.DEZ, typeRelease: releaseListSubtotal, subSet: 'CEQ', account: 'CEQTO', month: 11, disabled: true },

      { name: 'CEQ01_AQUISICAO.JAN', label: 'JAN', value: values.CEQ01_AQUISICAO.JAN, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CEQ01_AQUISICAO.FEV', label: 'FEV', value: values.CEQ01_AQUISICAO.FEV, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CEQ01_AQUISICAO.MAR', label: 'MAR', value: values.CEQ01_AQUISICAO.MAR, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CEQ01_AQUISICAO.ABR', label: 'ABR', value: values.CEQ01_AQUISICAO.ABR, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CEQ01_AQUISICAO.MAI', label: 'MAI', value: values.CEQ01_AQUISICAO.MAI, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CEQ01_AQUISICAO.JUN', label: 'JUN', value: values.CEQ01_AQUISICAO.JUN, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CEQ01_AQUISICAO.JUL', label: 'JUL', value: values.CEQ01_AQUISICAO.JUL, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CEQ01_AQUISICAO.AGO', label: 'AGO', value: values.CEQ01_AQUISICAO.AGO, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CEQ01_AQUISICAO.SET', label: 'SET', value: values.CEQ01_AQUISICAO.SET, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CEQ01_AQUISICAO.OUT', label: 'OUT', value: values.CEQ01_AQUISICAO.OUT, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CEQ01_AQUISICAO.NOV', label: 'NOV', value: values.CEQ01_AQUISICAO.NOV, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CEQ01_AQUISICAO.DEZ', label: 'DEZ', value: values.CEQ01_AQUISICAO.DEZ, typeRelease: releaseList[24], subSet: 'CEQ', account: 'CEQ01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CEQ02_LOCACAO.JAN', label: 'JAN', value: values.CEQ02_LOCACAO.JAN, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CEQ02_LOCACAO.FEV', label: 'FEV', value: values.CEQ02_LOCACAO.FEV, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CEQ02_LOCACAO.MAR', label: 'MAR', value: values.CEQ02_LOCACAO.MAR, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CEQ02_LOCACAO.ABR', label: 'ABR', value: values.CEQ02_LOCACAO.ABR, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CEQ02_LOCACAO.MAI', label: 'MAI', value: values.CEQ02_LOCACAO.MAI, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CEQ02_LOCACAO.JUN', label: 'JUN', value: values.CEQ02_LOCACAO.JUN, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CEQ02_LOCACAO.JUL', label: 'JUL', value: values.CEQ02_LOCACAO.JUL, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CEQ02_LOCACAO.AGO', label: 'AGO', value: values.CEQ02_LOCACAO.AGO, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CEQ02_LOCACAO.SET', label: 'SET', value: values.CEQ02_LOCACAO.SET, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CEQ02_LOCACAO.OUT', label: 'OUT', value: values.CEQ02_LOCACAO.OUT, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CEQ02_LOCACAO.NOV', label: 'NOV', value: values.CEQ02_LOCACAO.NOV, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CEQ02_LOCACAO.DEZ', label: 'DEZ', value: values.CEQ02_LOCACAO.DEZ, typeRelease: releaseList[25], subSet: 'CEQ', account: 'CEQ02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CEQ03_MANUTENCAO.JAN', label: 'JAN', value: values.CEQ03_MANUTENCAO.JAN, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CEQ03_MANUTENCAO.FEV', label: 'FEV', value: values.CEQ03_MANUTENCAO.FEV, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CEQ03_MANUTENCAO.MAR', label: 'MAR', value: values.CEQ03_MANUTENCAO.MAR, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CEQ03_MANUTENCAO.ABR', label: 'ABR', value: values.CEQ03_MANUTENCAO.ABR, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CEQ03_MANUTENCAO.MAI', label: 'MAI', value: values.CEQ03_MANUTENCAO.MAI, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CEQ03_MANUTENCAO.JUN', label: 'JUN', value: values.CEQ03_MANUTENCAO.JUN, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CEQ03_MANUTENCAO.JUL', label: 'JUL', value: values.CEQ03_MANUTENCAO.JUL, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CEQ03_MANUTENCAO.AGO', label: 'AGO', value: values.CEQ03_MANUTENCAO.AGO, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CEQ03_MANUTENCAO.SET', label: 'SET', value: values.CEQ03_MANUTENCAO.SET, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CEQ03_MANUTENCAO.OUT', label: 'OUT', value: values.CEQ03_MANUTENCAO.OUT, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CEQ03_MANUTENCAO.NOV', label: 'NOV', value: values.CEQ03_MANUTENCAO.NOV, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CEQ03_MANUTENCAO.DEZ', label: 'DEZ', value: values.CEQ03_MANUTENCAO.DEZ, typeRelease: releaseList[26], subSet: 'CEQ', account: 'CEQ03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CEQ04_OUTROS.JAN', label: 'JAN', value: values.CEQ04_OUTROS.JAN, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CEQ04_OUTROS.FEV', label: 'FEV', value: values.CEQ04_OUTROS.FEV, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CEQ04_OUTROS.MAR', label: 'MAR', value: values.CEQ04_OUTROS.MAR, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CEQ04_OUTROS.ABR', label: 'ABR', value: values.CEQ04_OUTROS.ABR, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CEQ04_OUTROS.MAI', label: 'MAI', value: values.CEQ04_OUTROS.MAI, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CEQ04_OUTROS.JUN', label: 'JUN', value: values.CEQ04_OUTROS.JUN, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CEQ04_OUTROS.JUL', label: 'JUL', value: values.CEQ04_OUTROS.JUL, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CEQ04_OUTROS.AGO', label: 'AGO', value: values.CEQ04_OUTROS.AGO, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CEQ04_OUTROS.SET', label: 'SET', value: values.CEQ04_OUTROS.SET, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CEQ04_OUTROS.OUT', label: 'OUT', value: values.CEQ04_OUTROS.OUT, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CEQ04_OUTROS.NOV', label: 'NOV', value: values.CEQ04_OUTROS.NOV, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CEQ04_OUTROS.DEZ', label: 'DEZ', value: values.CEQ04_OUTROS.DEZ, typeRelease: releaseList[27], subSet: 'CEQ', account: 'CEQ04', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider7 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMA.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMA.JAN, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMA.FEV, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMA.MAR, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMA.ABR, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMA.MAI, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMA.JUN, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMA.JUL, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMA.AGO, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMA.SET, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMA.OUT, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMA.NOV, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMA.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMA.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMA', account: 'CMATO', month: 11, disabled: true },

      { name: 'CMA01_DIVERSOS.JAN', label: 'JAN', value: values.CMA01_DIVERSOS.JAN, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA01_DIVERSOS.FEV', label: 'FEV', value: values.CMA01_DIVERSOS.FEV, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA01_DIVERSOS.MAR', label: 'MAR', value: values.CMA01_DIVERSOS.MAR, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA01_DIVERSOS.ABR', label: 'ABR', value: values.CMA01_DIVERSOS.ABR, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA01_DIVERSOS.MAI', label: 'MAI', value: values.CMA01_DIVERSOS.MAI, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA01_DIVERSOS.JUN', label: 'JUN', value: values.CMA01_DIVERSOS.JUN, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA01_DIVERSOS.JUL', label: 'JUL', value: values.CMA01_DIVERSOS.JUL, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA01_DIVERSOS.AGO', label: 'AGO', value: values.CMA01_DIVERSOS.AGO, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA01_DIVERSOS.SET', label: 'SET', value: values.CMA01_DIVERSOS.SET, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA01_DIVERSOS.OUT', label: 'OUT', value: values.CMA01_DIVERSOS.OUT, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA01_DIVERSOS.NOV', label: 'NOV', value: values.CMA01_DIVERSOS.NOV, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA01_DIVERSOS.DEZ', label: 'DEZ', value: values.CMA01_DIVERSOS.DEZ, typeRelease: releaseList[28], subSet: 'CMA', account: 'CMA01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA02_CIVIL.JAN', label: 'JAN', value: values.CMA02_CIVIL.JAN, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA02_CIVIL.FEV', label: 'FEV', value: values.CMA02_CIVIL.FEV, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA02_CIVIL.MAR', label: 'MAR', value: values.CMA02_CIVIL.MAR, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA02_CIVIL.ABR', label: 'ABR', value: values.CMA02_CIVIL.ABR, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA02_CIVIL.MAI', label: 'MAI', value: values.CMA02_CIVIL.MAI, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA02_CIVIL.JUN', label: 'JUN', value: values.CMA02_CIVIL.JUN, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA02_CIVIL.JUL', label: 'JUL', value: values.CMA02_CIVIL.JUL, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA02_CIVIL.AGO', label: 'AGO', value: values.CMA02_CIVIL.AGO, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA02_CIVIL.SET', label: 'SET', value: values.CMA02_CIVIL.SET, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA02_CIVIL.OUT', label: 'OUT', value: values.CMA02_CIVIL.OUT, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA02_CIVIL.NOV', label: 'NOV', value: values.CMA02_CIVIL.NOV, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA02_CIVIL.DEZ', label: 'DEZ', value: values.CMA02_CIVIL.DEZ, typeRelease: releaseList[29], subSet: 'CMA', account: 'CMA02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA03_TUBULACAO.JAN', label: 'JAN', value: values.CMA03_TUBULACAO.JAN, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA03_TUBULACAO.FEV', label: 'FEV', value: values.CMA03_TUBULACAO.FEV, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA03_TUBULACAO.MAR', label: 'MAR', value: values.CMA03_TUBULACAO.MAR, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA03_TUBULACAO.ABR', label: 'ABR', value: values.CMA03_TUBULACAO.ABR, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA03_TUBULACAO.MAI', label: 'MAI', value: values.CMA03_TUBULACAO.MAI, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA03_TUBULACAO.JUN', label: 'JUN', value: values.CMA03_TUBULACAO.JUN, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA03_TUBULACAO.JUL', label: 'JUL', value: values.CMA03_TUBULACAO.JUL, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA03_TUBULACAO.AGO', label: 'AGO', value: values.CMA03_TUBULACAO.AGO, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA03_TUBULACAO.SET', label: 'SET', value: values.CMA03_TUBULACAO.SET, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA03_TUBULACAO.OUT', label: 'OUT', value: values.CMA03_TUBULACAO.OUT, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA03_TUBULACAO.NOV', label: 'NOV', value: values.CMA03_TUBULACAO.NOV, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA03_TUBULACAO.DEZ', label: 'DEZ', value: values.CMA03_TUBULACAO.DEZ, typeRelease: releaseList[30], subSet: 'CMA', account: 'CMA03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.JAN', label: 'JAN', value: values.CMA04_CALDEIRARIA_ESTRUTURA.JAN, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.FEV', label: 'FEV', value: values.CMA04_CALDEIRARIA_ESTRUTURA.FEV, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.MAR', label: 'MAR', value: values.CMA04_CALDEIRARIA_ESTRUTURA.MAR, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.ABR', label: 'ABR', value: values.CMA04_CALDEIRARIA_ESTRUTURA.ABR, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.MAI', label: 'MAI', value: values.CMA04_CALDEIRARIA_ESTRUTURA.MAI, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.JUN', label: 'JUN', value: values.CMA04_CALDEIRARIA_ESTRUTURA.JUN, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.JUL', label: 'JUL', value: values.CMA04_CALDEIRARIA_ESTRUTURA.JUL, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.AGO', label: 'AGO', value: values.CMA04_CALDEIRARIA_ESTRUTURA.AGO, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.SET', label: 'SET', value: values.CMA04_CALDEIRARIA_ESTRUTURA.SET, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.OUT', label: 'OUT', value: values.CMA04_CALDEIRARIA_ESTRUTURA.OUT, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.NOV', label: 'NOV', value: values.CMA04_CALDEIRARIA_ESTRUTURA.NOV, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA04_CALDEIRARIA_ESTRUTURA.DEZ', label: 'DEZ', value: values.CMA04_CALDEIRARIA_ESTRUTURA.DEZ, typeRelease: releaseList[31], subSet: 'CMA', account: 'CMA04', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.JAN', label: 'JAN', value: values.CMA05_ELETRICA_INSTRUMENTACAO.JAN, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.FEV', label: 'FEV', value: values.CMA05_ELETRICA_INSTRUMENTACAO.FEV, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.MAR', label: 'MAR', value: values.CMA05_ELETRICA_INSTRUMENTACAO.MAR, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.ABR', label: 'ABR', value: values.CMA05_ELETRICA_INSTRUMENTACAO.ABR, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.MAI', label: 'MAI', value: values.CMA05_ELETRICA_INSTRUMENTACAO.MAI, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.JUN', label: 'JUN', value: values.CMA05_ELETRICA_INSTRUMENTACAO.JUN, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.JUL', label: 'JUL', value: values.CMA05_ELETRICA_INSTRUMENTACAO.JUL, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.AGO', label: 'AGO', value: values.CMA05_ELETRICA_INSTRUMENTACAO.AGO, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.SET', label: 'SET', value: values.CMA05_ELETRICA_INSTRUMENTACAO.SET, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.OUT', label: 'OUT', value: values.CMA05_ELETRICA_INSTRUMENTACAO.OUT, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.NOV', label: 'NOV', value: values.CMA05_ELETRICA_INSTRUMENTACAO.NOV, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA05_ELETRICA_INSTRUMENTACAO.DEZ', label: 'DEZ', value: values.CMA05_ELETRICA_INSTRUMENTACAO.DEZ, typeRelease: releaseList[32], subSet: 'CMA', account: 'CMA05', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA06_ISOLAMENTO.JAN', label: 'JAN', value: values.CMA06_ISOLAMENTO.JAN, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA06_ISOLAMENTO.FEV', label: 'FEV', value: values.CMA06_ISOLAMENTO.FEV, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA06_ISOLAMENTO.MAR', label: 'MAR', value: values.CMA06_ISOLAMENTO.MAR, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA06_ISOLAMENTO.ABR', label: 'ABR', value: values.CMA06_ISOLAMENTO.ABR, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA06_ISOLAMENTO.MAI', label: 'MAI', value: values.CMA06_ISOLAMENTO.MAI, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA06_ISOLAMENTO.JUN', label: 'JUN', value: values.CMA06_ISOLAMENTO.JUN, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA06_ISOLAMENTO.JUL', label: 'JUL', value: values.CMA06_ISOLAMENTO.JUL, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA06_ISOLAMENTO.AGO', label: 'AGO', value: values.CMA06_ISOLAMENTO.AGO, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA06_ISOLAMENTO.SET', label: 'SET', value: values.CMA06_ISOLAMENTO.SET, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA06_ISOLAMENTO.OUT', label: 'OUT', value: values.CMA06_ISOLAMENTO.OUT, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA06_ISOLAMENTO.NOV', label: 'NOV', value: values.CMA06_ISOLAMENTO.NOV, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA06_ISOLAMENTO.DEZ', label: 'DEZ', value: values.CMA06_ISOLAMENTO.DEZ, typeRelease: releaseList[33], subSet: 'CMA', account: 'CMA06', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMA07_PINTURA.JAN', label: 'JAN', value: values.CMA07_PINTURA.JAN, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMA07_PINTURA.FEV', label: 'FEV', value: values.CMA07_PINTURA.FEV, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMA07_PINTURA.MAR', label: 'MAR', value: values.CMA07_PINTURA.MAR, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMA07_PINTURA.ABR', label: 'ABR', value: values.CMA07_PINTURA.ABR, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMA07_PINTURA.MAI', label: 'MAI', value: values.CMA07_PINTURA.MAI, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMA07_PINTURA.JUN', label: 'JUN', value: values.CMA07_PINTURA.JUN, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMA07_PINTURA.JUL', label: 'JUL', value: values.CMA07_PINTURA.JUL, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMA07_PINTURA.AGO', label: 'AGO', value: values.CMA07_PINTURA.AGO, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMA07_PINTURA.SET', label: 'SET', value: values.CMA07_PINTURA.SET, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMA07_PINTURA.OUT', label: 'OUT', value: values.CMA07_PINTURA.OUT, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMA07_PINTURA.NOV', label: 'NOV', value: values.CMA07_PINTURA.NOV, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMA07_PINTURA.DEZ', label: 'DEZ', value: values.CMA07_PINTURA.DEZ, typeRelease: releaseList[34], subSet: 'CMA', account: 'CMA07', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider8 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMC.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMC.JAN, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMC.FEV, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMC.MAR, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMC.ABR, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMC.MAI, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMC.JUN, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMC.JUL, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMC.AGO, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMC.SET, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMC.OUT, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMC.NOV, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMC.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMC.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMC', account: 'CMCTO', month: 11, disabled: true },

      { name: 'CMC01_MATERIAL_DE_CONSUMO.JAN', label: 'JAN', value: values.CMC01_MATERIAL_DE_CONSUMO.JAN, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.FEV', label: 'FEV', value: values.CMC01_MATERIAL_DE_CONSUMO.FEV, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.MAR', label: 'MAR', value: values.CMC01_MATERIAL_DE_CONSUMO.MAR, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.ABR', label: 'ABR', value: values.CMC01_MATERIAL_DE_CONSUMO.ABR, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.MAI', label: 'MAI', value: values.CMC01_MATERIAL_DE_CONSUMO.MAI, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.JUN', label: 'JUN', value: values.CMC01_MATERIAL_DE_CONSUMO.JUN, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.JUL', label: 'JUL', value: values.CMC01_MATERIAL_DE_CONSUMO.JUL, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.AGO', label: 'AGO', value: values.CMC01_MATERIAL_DE_CONSUMO.AGO, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.SET', label: 'SET', value: values.CMC01_MATERIAL_DE_CONSUMO.SET, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.OUT', label: 'OUT', value: values.CMC01_MATERIAL_DE_CONSUMO.OUT, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.NOV', label: 'NOV', value: values.CMC01_MATERIAL_DE_CONSUMO.NOV, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMC01_MATERIAL_DE_CONSUMO.DEZ', label: 'DEZ', value: values.CMC01_MATERIAL_DE_CONSUMO.DEZ, typeRelease: releaseList[35], subSet: 'CMC', account: 'CMC01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMC02_GASES.JAN', label: 'JAN', value: values.CMC02_GASES.JAN, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMC02_GASES.FEV', label: 'FEV', value: values.CMC02_GASES.FEV, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMC02_GASES.MAR', label: 'MAR', value: values.CMC02_GASES.MAR, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMC02_GASES.ABR', label: 'ABR', value: values.CMC02_GASES.ABR, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMC02_GASES.MAI', label: 'MAI', value: values.CMC02_GASES.MAI, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMC02_GASES.JUN', label: 'JUN', value: values.CMC02_GASES.JUN, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMC02_GASES.JUL', label: 'JUL', value: values.CMC02_GASES.JUL, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMC02_GASES.AGO', label: 'AGO', value: values.CMC02_GASES.AGO, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMC02_GASES.SET', label: 'SET', value: values.CMC02_GASES.SET, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMC02_GASES.OUT', label: 'OUT', value: values.CMC02_GASES.OUT, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMC02_GASES.NOV', label: 'NOV', value: values.CMC02_GASES.NOV, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMC02_GASES.DEZ', label: 'DEZ', value: values.CMC02_GASES.DEZ, typeRelease: releaseList[36], subSet: 'CMC', account: 'CMC02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CMC03_ELETRODOS_E_VARETAS.JAN', label: 'JAN', value: values.CMC03_ELETRODOS_E_VARETAS.JAN, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.FEV', label: 'FEV', value: values.CMC03_ELETRODOS_E_VARETAS.FEV, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.MAR', label: 'MAR', value: values.CMC03_ELETRODOS_E_VARETAS.MAR, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.ABR', label: 'ABR', value: values.CMC03_ELETRODOS_E_VARETAS.ABR, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.MAI', label: 'MAI', value: values.CMC03_ELETRODOS_E_VARETAS.MAI, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.JUN', label: 'JUN', value: values.CMC03_ELETRODOS_E_VARETAS.JUN, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.JUL', label: 'JUL', value: values.CMC03_ELETRODOS_E_VARETAS.JUL, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.AGO', label: 'AGO', value: values.CMC03_ELETRODOS_E_VARETAS.AGO, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.SET', label: 'SET', value: values.CMC03_ELETRODOS_E_VARETAS.SET, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.OUT', label: 'OUT', value: values.CMC03_ELETRODOS_E_VARETAS.OUT, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.NOV', label: 'NOV', value: values.CMC03_ELETRODOS_E_VARETAS.NOV, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMC03_ELETRODOS_E_VARETAS.DEZ', label: 'DEZ', value: values.CMC03_ELETRODOS_E_VARETAS.DEZ, typeRelease: releaseList[37], subSet: 'CMC', account: 'CMC03', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider9 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CMV.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CMV.JAN, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CMV.FEV, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CMV.MAR, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CMV.ABR, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CMV.MAI, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CMV.JUN, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CMV.JUL, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CMV.AGO, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.SET', label: 'SET', value: values.TOTAL_CUSTOS_CMV.SET, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CMV.OUT, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CMV.NOV, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CMV.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CMV.DEZ, typeRelease: releaseListSubtotal, subSet: 'CMV', account: 'CMVTO', month: 11, disabled: true },

      { name: 'CMV01_MERC_VEND.JAN', label: 'JAN', value: values.CMV01_MERC_VEND.JAN, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CMV01_MERC_VEND.FEV', label: 'FEV', value: values.CMV01_MERC_VEND.FEV, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CMV01_MERC_VEND.MAR', label: 'MAR', value: values.CMV01_MERC_VEND.MAR, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CMV01_MERC_VEND.ABR', label: 'ABR', value: values.CMV01_MERC_VEND.ABR, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CMV01_MERC_VEND.MAI', label: 'MAI', value: values.CMV01_MERC_VEND.MAI, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CMV01_MERC_VEND.JUN', label: 'JUN', value: values.CMV01_MERC_VEND.JUN, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CMV01_MERC_VEND.JUL', label: 'JUL', value: values.CMV01_MERC_VEND.JUL, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CMV01_MERC_VEND.AGO', label: 'AGO', value: values.CMV01_MERC_VEND.AGO, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CMV01_MERC_VEND.SET', label: 'SET', value: values.CMV01_MERC_VEND.SET, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CMV01_MERC_VEND.OUT', label: 'OUT', value: values.CMV01_MERC_VEND.OUT, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CMV01_MERC_VEND.NOV', label: 'NOV', value: values.CMV01_MERC_VEND.NOV, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CMV01_MERC_VEND.DEZ', label: 'DEZ', value: values.CMV01_MERC_VEND.DEZ, typeRelease: releaseList[38], subSet: 'CMV', account: 'CMV01', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider10 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CSB.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CSB.JAN, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CSB.FEV, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CSB.MAR, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CSB.ABR, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CSB.MAI, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CSB.JUN, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CSB.JUL, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CSB.AGO, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.SET', label: 'SET', value: values.TOTAL_CUSTOS_CSB.SET, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CSB.OUT, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CSB.NOV, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CSB.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CSB.DEZ, typeRelease: releaseListSubtotal, subSet: 'CSB', account: 'CSBTO', month: 11, disabled: true },

      { name: 'CSB01_DIVERSOS.JAN', label: 'JAN', value: values.CSB01_DIVERSOS.JAN, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB01_DIVERSOS.FEV', label: 'FEV', value: values.CSB01_DIVERSOS.FEV, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB01_DIVERSOS.MAR', label: 'MAR', value: values.CSB01_DIVERSOS.MAR, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB01_DIVERSOS.ABR', label: 'ABR', value: values.CSB01_DIVERSOS.ABR, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB01_DIVERSOS.MAI', label: 'MAI', value: values.CSB01_DIVERSOS.MAI, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB01_DIVERSOS.JUN', label: 'JUN', value: values.CSB01_DIVERSOS.JUN, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB01_DIVERSOS.JUL', label: 'JUL', value: values.CSB01_DIVERSOS.JUL, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB01_DIVERSOS.AGO', label: 'AGO', value: values.CSB01_DIVERSOS.AGO, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB01_DIVERSOS.SET', label: 'SET', value: values.CSB01_DIVERSOS.SET, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB01_DIVERSOS.OUT', label: 'OUT', value: values.CSB01_DIVERSOS.OUT, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB01_DIVERSOS.NOV', label: 'NOV', value: values.CSB01_DIVERSOS.NOV, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB01_DIVERSOS.DEZ', label: 'DEZ', value: values.CSB01_DIVERSOS.DEZ, typeRelease: releaseList[39], subSet: 'CSB', account: 'CSB01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB02_CIVIL.JAN', label: 'JAN', value: values.CSB02_CIVIL.JAN, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB02_CIVIL.FEV', label: 'FEV', value: values.CSB02_CIVIL.FEV, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB02_CIVIL.MAR', label: 'MAR', value: values.CSB02_CIVIL.MAR, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB02_CIVIL.ABR', label: 'ABR', value: values.CSB02_CIVIL.ABR, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB02_CIVIL.MAI', label: 'MAI', value: values.CSB02_CIVIL.MAI, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB02_CIVIL.JUN', label: 'JUN', value: values.CSB02_CIVIL.JUN, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB02_CIVIL.JUL', label: 'JUL', value: values.CSB02_CIVIL.JUL, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB02_CIVIL.AGO', label: 'AGO', value: values.CSB02_CIVIL.AGO, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB02_CIVIL.SET', label: 'SET', value: values.CSB02_CIVIL.SET, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB02_CIVIL.OUT', label: 'OUT', value: values.CSB02_CIVIL.OUT, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB02_CIVIL.NOV', label: 'NOV', value: values.CSB02_CIVIL.NOV, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB02_CIVIL.DEZ', label: 'DEZ', value: values.CSB02_CIVIL.DEZ, typeRelease: releaseList[40], subSet: 'CSB', account: 'CSB02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB03_TUBULACAO.JAN', label: 'JAN', value: values.CSB03_TUBULACAO.JAN, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB03_TUBULACAO.FEV', label: 'FEV', value: values.CSB03_TUBULACAO.FEV, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB03_TUBULACAO.MAR', label: 'MAR', value: values.CSB03_TUBULACAO.MAR, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB03_TUBULACAO.ABR', label: 'ABR', value: values.CSB03_TUBULACAO.ABR, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB03_TUBULACAO.MAI', label: 'MAI', value: values.CSB03_TUBULACAO.MAI, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB03_TUBULACAO.JUN', label: 'JUN', value: values.CSB03_TUBULACAO.JUN, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB03_TUBULACAO.JUL', label: 'JUL', value: values.CSB03_TUBULACAO.JUL, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB03_TUBULACAO.AGO', label: 'AGO', value: values.CSB03_TUBULACAO.AGO, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB03_TUBULACAO.SET', label: 'SET', value: values.CSB03_TUBULACAO.SET, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB03_TUBULACAO.OUT', label: 'OUT', value: values.CSB03_TUBULACAO.OUT, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB03_TUBULACAO.NOV', label: 'NOV', value: values.CSB03_TUBULACAO.NOV, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB03_TUBULACAO.DEZ', label: 'DEZ', value: values.CSB03_TUBULACAO.DEZ, typeRelease: releaseList[41], subSet: 'CSB', account: 'CSB03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.JAN', label: 'JAN', value: values.CSB04_CALDEIRARIA_ESTRUTURA.JAN, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.FEV', label: 'FEV', value: values.CSB04_CALDEIRARIA_ESTRUTURA.FEV, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.MAR', label: 'MAR', value: values.CSB04_CALDEIRARIA_ESTRUTURA.MAR, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.ABR', label: 'ABR', value: values.CSB04_CALDEIRARIA_ESTRUTURA.ABR, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.MAI', label: 'MAI', value: values.CSB04_CALDEIRARIA_ESTRUTURA.MAI, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.JUN', label: 'JUN', value: values.CSB04_CALDEIRARIA_ESTRUTURA.JUN, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.JUL', label: 'JUL', value: values.CSB04_CALDEIRARIA_ESTRUTURA.JUL, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.AGO', label: 'AGO', value: values.CSB04_CALDEIRARIA_ESTRUTURA.AGO, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.SET', label: 'SET', value: values.CSB04_CALDEIRARIA_ESTRUTURA.SET, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.OUT', label: 'OUT', value: values.CSB04_CALDEIRARIA_ESTRUTURA.OUT, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.NOV', label: 'NOV', value: values.CSB04_CALDEIRARIA_ESTRUTURA.NOV, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB04_CALDEIRARIA_ESTRUTURA.DEZ', label: 'DEZ', value: values.CSB04_CALDEIRARIA_ESTRUTURA.DEZ, typeRelease: releaseList[42], subSet: 'CSB', account: 'CSB04', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.JAN', label: 'JAN', value: values.CSB05_ELETRICA_INSTRUMENTACAO.JAN, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.FEV', label: 'FEV', value: values.CSB05_ELETRICA_INSTRUMENTACAO.FEV, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.MAR', label: 'MAR', value: values.CSB05_ELETRICA_INSTRUMENTACAO.MAR, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.ABR', label: 'ABR', value: values.CSB05_ELETRICA_INSTRUMENTACAO.ABR, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.MAI', label: 'MAI', value: values.CSB05_ELETRICA_INSTRUMENTACAO.MAI, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.JUN', label: 'JUN', value: values.CSB05_ELETRICA_INSTRUMENTACAO.JUN, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.JUL', label: 'JUL', value: values.CSB05_ELETRICA_INSTRUMENTACAO.JUL, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.AGO', label: 'AGO', value: values.CSB05_ELETRICA_INSTRUMENTACAO.AGO, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.SET', label: 'SET', value: values.CSB05_ELETRICA_INSTRUMENTACAO.SET, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.OUT', label: 'OUT', value: values.CSB05_ELETRICA_INSTRUMENTACAO.OUT, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.NOV', label: 'NOV', value: values.CSB05_ELETRICA_INSTRUMENTACAO.NOV, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB05_ELETRICA_INSTRUMENTACAO.DEZ', label: 'DEZ', value: values.CSB05_ELETRICA_INSTRUMENTACAO.DEZ, typeRelease: releaseList[43], subSet: 'CSB', account: 'CSB05', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB06_ISOLAMENTO.JAN', label: 'JAN', value: values.CSB06_ISOLAMENTO.JAN, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB06_ISOLAMENTO.FEV', label: 'FEV', value: values.CSB06_ISOLAMENTO.FEV, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB06_ISOLAMENTO.MAR', label: 'MAR', value: values.CSB06_ISOLAMENTO.MAR, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB06_ISOLAMENTO.ABR', label: 'ABR', value: values.CSB06_ISOLAMENTO.ABR, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB06_ISOLAMENTO.MAI', label: 'MAI', value: values.CSB06_ISOLAMENTO.MAI, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB06_ISOLAMENTO.JUN', label: 'JUN', value: values.CSB06_ISOLAMENTO.JUN, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB06_ISOLAMENTO.JUL', label: 'JUL', value: values.CSB06_ISOLAMENTO.JUL, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB06_ISOLAMENTO.AGO', label: 'AGO', value: values.CSB06_ISOLAMENTO.AGO, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB06_ISOLAMENTO.SET', label: 'SET', value: values.CSB06_ISOLAMENTO.SET, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB06_ISOLAMENTO.OUT', label: 'OUT', value: values.CSB06_ISOLAMENTO.OUT, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB06_ISOLAMENTO.NOV', label: 'NOV', value: values.CSB06_ISOLAMENTO.NOV, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB06_ISOLAMENTO.DEZ', label: 'DEZ', value: values.CSB06_ISOLAMENTO.DEZ, typeRelease: releaseList[44], subSet: 'CSB', account: 'CSB06', month: 11, disabled: currentMonth >= 12 },

      { name: 'CSB07_PINTURA.JAN', label: 'JAN', value: values.CSB07_PINTURA.JAN, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 0, disabled: currentMonth >= 1 },
      { name: 'CSB07_PINTURA.FEV', label: 'FEV', value: values.CSB07_PINTURA.FEV, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 1, disabled: currentMonth >= 2 },
      { name: 'CSB07_PINTURA.MAR', label: 'MAR', value: values.CSB07_PINTURA.MAR, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 2, disabled: currentMonth >= 3 },
      { name: 'CSB07_PINTURA.ABR', label: 'ABR', value: values.CSB07_PINTURA.ABR, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 3, disabled: currentMonth >= 4 },
      { name: 'CSB07_PINTURA.MAI', label: 'MAI', value: values.CSB07_PINTURA.MAI, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 4, disabled: currentMonth >= 5 },
      { name: 'CSB07_PINTURA.JUN', label: 'JUN', value: values.CSB07_PINTURA.JUN, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 5, disabled: currentMonth >= 6 },
      { name: 'CSB07_PINTURA.JUL', label: 'JUL', value: values.CSB07_PINTURA.JUL, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 6, disabled: currentMonth >= 7 },
      { name: 'CSB07_PINTURA.AGO', label: 'AGO', value: values.CSB07_PINTURA.AGO, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 7, disabled: currentMonth >= 8 },
      { name: 'CSB07_PINTURA.SET', label: 'SET', value: values.CSB07_PINTURA.SET, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 8, disabled: currentMonth >= 9 },
      { name: 'CSB07_PINTURA.OUT', label: 'OUT', value: values.CSB07_PINTURA.OUT, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 9, disabled: currentMonth >= 10 },
      { name: 'CSB07_PINTURA.NOV', label: 'NOV', value: values.CSB07_PINTURA.NOV, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 10, disabled: currentMonth >= 11 },
      { name: 'CSB07_PINTURA.DEZ', label: 'DEZ', value: values.CSB07_PINTURA.DEZ, typeRelease: releaseList[45], subSet: 'CSB', account: 'CSB07', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider11 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CDG.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CDG.JAN, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CDG.FEV, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CDG.MAR, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CDG.ABR, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CDG.MAI, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CDG.JUN, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CDG.JUL, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CDG.AGO, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.SET', label: 'SET', value: values.TOTAL_CUSTOS_CDG.SET, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CDG.OUT, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CDG.NOV, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CDG.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CDG.DEZ, typeRelease: releaseListSubtotal, subSet: 'CDG', account: 'CDGTO', month: 11, disabled: true },

      { name: 'CDG01_TAXAS_DIVERSAS.JAN', label: 'JAN', value: values.CDG01_TAXAS_DIVERSAS.JAN, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CDG01_TAXAS_DIVERSAS.FEV', label: 'FEV', value: values.CDG01_TAXAS_DIVERSAS.FEV, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CDG01_TAXAS_DIVERSAS.MAR', label: 'MAR', value: values.CDG01_TAXAS_DIVERSAS.MAR, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CDG01_TAXAS_DIVERSAS.ABR', label: 'ABR', value: values.CDG01_TAXAS_DIVERSAS.ABR, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CDG01_TAXAS_DIVERSAS.MAI', label: 'MAI', value: values.CDG01_TAXAS_DIVERSAS.MAI, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CDG01_TAXAS_DIVERSAS.JUN', label: 'JUN', value: values.CDG01_TAXAS_DIVERSAS.JUN, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CDG01_TAXAS_DIVERSAS.JUL', label: 'JUL', value: values.CDG01_TAXAS_DIVERSAS.JUL, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CDG01_TAXAS_DIVERSAS.AGO', label: 'AGO', value: values.CDG01_TAXAS_DIVERSAS.AGO, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CDG01_TAXAS_DIVERSAS.SET', label: 'SET', value: values.CDG01_TAXAS_DIVERSAS.SET, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CDG01_TAXAS_DIVERSAS.OUT', label: 'OUT', value: values.CDG01_TAXAS_DIVERSAS.OUT, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CDG01_TAXAS_DIVERSAS.NOV', label: 'NOV', value: values.CDG01_TAXAS_DIVERSAS.NOV, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CDG01_TAXAS_DIVERSAS.DEZ', label: 'DEZ', value: values.CDG01_TAXAS_DIVERSAS.DEZ, typeRelease: releaseList[46], subSet: 'CDG', account: 'CDG01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CDG02_PREMIACOES_PATROCINIO.JAN', label: 'JAN', value: values.CDG02_PREMIACOES_PATROCINIO.JAN, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.FEV', label: 'FEV', value: values.CDG02_PREMIACOES_PATROCINIO.FEV, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.MAR', label: 'MAR', value: values.CDG02_PREMIACOES_PATROCINIO.MAR, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.ABR', label: 'ABR', value: values.CDG02_PREMIACOES_PATROCINIO.ABR, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.MAI', label: 'MAI', value: values.CDG02_PREMIACOES_PATROCINIO.MAI, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.JUN', label: 'JUN', value: values.CDG02_PREMIACOES_PATROCINIO.JUN, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.JUL', label: 'JUL', value: values.CDG02_PREMIACOES_PATROCINIO.JUL, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.AGO', label: 'AGO', value: values.CDG02_PREMIACOES_PATROCINIO.AGO, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.SET', label: 'SET', value: values.CDG02_PREMIACOES_PATROCINIO.SET, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.OUT', label: 'OUT', value: values.CDG02_PREMIACOES_PATROCINIO.OUT, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.NOV', label: 'NOV', value: values.CDG02_PREMIACOES_PATROCINIO.NOV, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CDG02_PREMIACOES_PATROCINIO.DEZ', label: 'DEZ', value: values.CDG02_PREMIACOES_PATROCINIO.DEZ, typeRelease: releaseList[47], subSet: 'CDG', account: 'CDG02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CDG03_SEGUROS_MULTAS_JUROS.JAN', label: 'JAN', value: values.CDG03_SEGUROS_MULTAS_JUROS.JAN, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.FEV', label: 'FEV', value: values.CDG03_SEGUROS_MULTAS_JUROS.FEV, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.MAR', label: 'MAR', value: values.CDG03_SEGUROS_MULTAS_JUROS.MAR, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.ABR', label: 'ABR', value: values.CDG03_SEGUROS_MULTAS_JUROS.ABR, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.MAI', label: 'MAI', value: values.CDG03_SEGUROS_MULTAS_JUROS.MAI, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.JUN', label: 'JUN', value: values.CDG03_SEGUROS_MULTAS_JUROS.JUN, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.JUL', label: 'JUL', value: values.CDG03_SEGUROS_MULTAS_JUROS.JUL, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.AGO', label: 'AGO', value: values.CDG03_SEGUROS_MULTAS_JUROS.AGO, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.SET', label: 'SET', value: values.CDG03_SEGUROS_MULTAS_JUROS.SET, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.OUT', label: 'OUT', value: values.CDG03_SEGUROS_MULTAS_JUROS.OUT, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.NOV', label: 'NOV', value: values.CDG03_SEGUROS_MULTAS_JUROS.NOV, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CDG03_SEGUROS_MULTAS_JUROS.DEZ', label: 'DEZ', value: values.CDG03_SEGUROS_MULTAS_JUROS.DEZ, typeRelease: releaseList[48], subSet: 'CDG', account: 'CDG03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CDG04_OUTROS.JAN', label: 'JAN', value: values.CDG04_OUTROS.JAN, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CDG04_OUTROS.FEV', label: 'FEV', value: values.CDG04_OUTROS.FEV, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CDG04_OUTROS.MAR', label: 'MAR', value: values.CDG04_OUTROS.MAR, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CDG04_OUTROS.ABR', label: 'ABR', value: values.CDG04_OUTROS.ABR, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CDG04_OUTROS.MAI', label: 'MAI', value: values.CDG04_OUTROS.MAI, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CDG04_OUTROS.JUN', label: 'JUN', value: values.CDG04_OUTROS.JUN, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CDG04_OUTROS.JUL', label: 'JUL', value: values.CDG04_OUTROS.JUL, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CDG04_OUTROS.AGO', label: 'AGO', value: values.CDG04_OUTROS.AGO, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CDG04_OUTROS.SET', label: 'SET', value: values.CDG04_OUTROS.SET, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CDG04_OUTROS.OUT', label: 'OUT', value: values.CDG04_OUTROS.OUT, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CDG04_OUTROS.NOV', label: 'NOV', value: values.CDG04_OUTROS.NOV, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CDG04_OUTROS.DEZ', label: 'DEZ', value: values.CDG04_OUTROS.DEZ, typeRelease: releaseList[49], subSet: 'CDG', account: 'CDG04', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const listFieldsProvider12 = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_CUSTOS_CRC.JAN', label: 'JAN', value: values.TOTAL_CUSTOS_CRC.JAN, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 0, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.FEV', label: 'FEV', value: values.TOTAL_CUSTOS_CRC.FEV, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 1, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.MAR', label: 'MAR', value: values.TOTAL_CUSTOS_CRC.MAR, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 2, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.ABR', label: 'ABR', value: values.TOTAL_CUSTOS_CRC.ABR, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 3, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.MAI', label: 'MAI', value: values.TOTAL_CUSTOS_CRC.MAI, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 4, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.JUN', label: 'JUN', value: values.TOTAL_CUSTOS_CRC.JUN, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 5, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.JUL', label: 'JUL', value: values.TOTAL_CUSTOS_CRC.JUL, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 6, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.AGO', label: 'AGO', value: values.TOTAL_CUSTOS_CRC.AGO, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 7, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.SET', label: 'SET', value: values.TOTAL_CUSTOS_CRC.SET, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 8, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.OUT', label: 'OUT', value: values.TOTAL_CUSTOS_CRC.OUT, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 9, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.NOV', label: 'NOV', value: values.TOTAL_CUSTOS_CRC.NOV, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 10, disabled: true },
      { name: 'TOTAL_CUSTOS_CRC.DEZ', label: 'DEZ', value: values.TOTAL_CUSTOS_CRC.DEZ, typeRelease: releaseListSubtotal, subSet: 'CRC', account: 'CRCTO', month: 11, disabled: true },

      { name: 'CRC01_CUSTOS_DE_MOD.JAN', label: 'JAN', value: values.CRC01_CUSTOS_DE_MOD.JAN, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC01_CUSTOS_DE_MOD.FEV', label: 'FEV', value: values.CRC01_CUSTOS_DE_MOD.FEV, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC01_CUSTOS_DE_MOD.MAR', label: 'MAR', value: values.CRC01_CUSTOS_DE_MOD.MAR, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC01_CUSTOS_DE_MOD.ABR', label: 'ABR', value: values.CRC01_CUSTOS_DE_MOD.ABR, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC01_CUSTOS_DE_MOD.MAI', label: 'MAI', value: values.CRC01_CUSTOS_DE_MOD.MAI, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC01_CUSTOS_DE_MOD.JUN', label: 'JUN', value: values.CRC01_CUSTOS_DE_MOD.JUN, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC01_CUSTOS_DE_MOD.JUL', label: 'JUL', value: values.CRC01_CUSTOS_DE_MOD.JUL, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC01_CUSTOS_DE_MOD.AGO', label: 'AGO', value: values.CRC01_CUSTOS_DE_MOD.AGO, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC01_CUSTOS_DE_MOD.SET', label: 'SET', value: values.CRC01_CUSTOS_DE_MOD.SET, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC01_CUSTOS_DE_MOD.OUT', label: 'OUT', value: values.CRC01_CUSTOS_DE_MOD.OUT, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC01_CUSTOS_DE_MOD.NOV', label: 'NOV', value: values.CRC01_CUSTOS_DE_MOD.NOV, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC01_CUSTOS_DE_MOD.DEZ', label: 'DEZ', value: values.CRC01_CUSTOS_DE_MOD.DEZ, typeRelease: releaseList[50], subSet: 'CRC', account: 'CRC01', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC02_CUSTOS_DE_MOI.JAN', label: 'JAN', value: values.CRC02_CUSTOS_DE_MOI.JAN, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC02_CUSTOS_DE_MOI.FEV', label: 'FEV', value: values.CRC02_CUSTOS_DE_MOI.FEV, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC02_CUSTOS_DE_MOI.MAR', label: 'MAR', value: values.CRC02_CUSTOS_DE_MOI.MAR, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC02_CUSTOS_DE_MOI.ABR', label: 'ABR', value: values.CRC02_CUSTOS_DE_MOI.ABR, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC02_CUSTOS_DE_MOI.MAI', label: 'MAI', value: values.CRC02_CUSTOS_DE_MOI.MAI, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC02_CUSTOS_DE_MOI.JUN', label: 'JUN', value: values.CRC02_CUSTOS_DE_MOI.JUN, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC02_CUSTOS_DE_MOI.JUL', label: 'JUL', value: values.CRC02_CUSTOS_DE_MOI.JUL, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC02_CUSTOS_DE_MOI.AGO', label: 'AGO', value: values.CRC02_CUSTOS_DE_MOI.AGO, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC02_CUSTOS_DE_MOI.SET', label: 'SET', value: values.CRC02_CUSTOS_DE_MOI.SET, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC02_CUSTOS_DE_MOI.OUT', label: 'OUT', value: values.CRC02_CUSTOS_DE_MOI.OUT, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC02_CUSTOS_DE_MOI.NOV', label: 'NOV', value: values.CRC02_CUSTOS_DE_MOI.NOV, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC02_CUSTOS_DE_MOI.DEZ', label: 'DEZ', value: values.CRC02_CUSTOS_DE_MOI.DEZ, typeRelease: releaseList[51], subSet: 'CRC', account: 'CRC02', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC03_CUSTOS_DE_APOIO.JAN', label: 'JAN', value: values.CRC03_CUSTOS_DE_APOIO.JAN, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC03_CUSTOS_DE_APOIO.FEV', label: 'FEV', value: values.CRC03_CUSTOS_DE_APOIO.FEV, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC03_CUSTOS_DE_APOIO.MAR', label: 'MAR', value: values.CRC03_CUSTOS_DE_APOIO.MAR, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC03_CUSTOS_DE_APOIO.ABR', label: 'ABR', value: values.CRC03_CUSTOS_DE_APOIO.ABR, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC03_CUSTOS_DE_APOIO.MAI', label: 'MAI', value: values.CRC03_CUSTOS_DE_APOIO.MAI, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC03_CUSTOS_DE_APOIO.JUN', label: 'JUN', value: values.CRC03_CUSTOS_DE_APOIO.JUN, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC03_CUSTOS_DE_APOIO.JUL', label: 'JUL', value: values.CRC03_CUSTOS_DE_APOIO.JUL, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC03_CUSTOS_DE_APOIO.AGO', label: 'AGO', value: values.CRC03_CUSTOS_DE_APOIO.AGO, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC03_CUSTOS_DE_APOIO.SET', label: 'SET', value: values.CRC03_CUSTOS_DE_APOIO.SET, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC03_CUSTOS_DE_APOIO.OUT', label: 'OUT', value: values.CRC03_CUSTOS_DE_APOIO.OUT, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC03_CUSTOS_DE_APOIO.NOV', label: 'NOV', value: values.CRC03_CUSTOS_DE_APOIO.NOV, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC03_CUSTOS_DE_APOIO.DEZ', label: 'DEZ', value: values.CRC03_CUSTOS_DE_APOIO.DEZ, typeRelease: releaseList[52], subSet: 'CRC', account: 'CRC03', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.JAN', label: 'JAN', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.JAN, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.FEV', label: 'FEV', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.FEV, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.MAR', label: 'MAR', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.MAR, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.ABR', label: 'ABR', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.ABR, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.MAI', label: 'MAI', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.MAI, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.JUN', label: 'JUN', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.JUN, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.JUL', label: 'JUL', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.JUL, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.AGO', label: 'AGO', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.AGO, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.SET', label: 'SET', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.SET, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.OUT', label: 'OUT', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.OUT, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.NOV', label: 'NOV', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.NOV, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC04_CUSTOS_DE_MOBILIZACAO.DEZ', label: 'DEZ', value: values.CRC04_CUSTOS_DE_MOBILIZACAO.DEZ, typeRelease: releaseList[53], subSet: 'CRC', account: 'CRC04', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC05_CUSTOS_DE_CANTEIRO.JAN', label: 'JAN', value: values.CRC05_CUSTOS_DE_CANTEIRO.JAN, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.FEV', label: 'FEV', value: values.CRC05_CUSTOS_DE_CANTEIRO.FEV, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.MAR', label: 'MAR', value: values.CRC05_CUSTOS_DE_CANTEIRO.MAR, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.ABR', label: 'ABR', value: values.CRC05_CUSTOS_DE_CANTEIRO.ABR, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.MAI', label: 'MAI', value: values.CRC05_CUSTOS_DE_CANTEIRO.MAI, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.JUN', label: 'JUN', value: values.CRC05_CUSTOS_DE_CANTEIRO.JUN, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.JUL', label: 'JUL', value: values.CRC05_CUSTOS_DE_CANTEIRO.JUL, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.AGO', label: 'AGO', value: values.CRC05_CUSTOS_DE_CANTEIRO.AGO, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.SET', label: 'SET', value: values.CRC05_CUSTOS_DE_CANTEIRO.SET, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.OUT', label: 'OUT', value: values.CRC05_CUSTOS_DE_CANTEIRO.OUT, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.NOV', label: 'NOV', value: values.CRC05_CUSTOS_DE_CANTEIRO.NOV, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC05_CUSTOS_DE_CANTEIRO.DEZ', label: 'DEZ', value: values.CRC05_CUSTOS_DE_CANTEIRO.DEZ, typeRelease: releaseList[54], subSet: 'CRC', account: 'CRC05', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.JAN', label: 'JAN', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.JAN, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.FEV', label: 'FEV', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.FEV, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.MAR', label: 'MAR', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.MAR, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.ABR', label: 'ABR', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.ABR, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.MAI', label: 'MAI', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.MAI, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.JUN', label: 'JUN', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.JUN, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.JUL', label: 'JUL', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.JUL, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.AGO', label: 'AGO', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.AGO, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.SET', label: 'SET', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.SET, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.OUT', label: 'OUT', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.OUT, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.NOV', label: 'NOV', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.NOV, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC06_CUSTOS_DE_EQUIPAMENTOS.DEZ', label: 'DEZ', value: values.CRC06_CUSTOS_DE_EQUIPAMENTOS.DEZ, typeRelease: releaseList[55], subSet: 'CRC', account: 'CRC06', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC07_CUSTOS_DE_MATERIAIS.JAN', label: 'JAN', value: values.CRC07_CUSTOS_DE_MATERIAIS.JAN, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.FEV', label: 'FEV', value: values.CRC07_CUSTOS_DE_MATERIAIS.FEV, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.MAR', label: 'MAR', value: values.CRC07_CUSTOS_DE_MATERIAIS.MAR, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.ABR', label: 'ABR', value: values.CRC07_CUSTOS_DE_MATERIAIS.ABR, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.MAI', label: 'MAI', value: values.CRC07_CUSTOS_DE_MATERIAIS.MAI, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.JUN', label: 'JUN', value: values.CRC07_CUSTOS_DE_MATERIAIS.JUN, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.JUL', label: 'JUL', value: values.CRC07_CUSTOS_DE_MATERIAIS.JUL, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.AGO', label: 'AGO', value: values.CRC07_CUSTOS_DE_MATERIAIS.AGO, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.SET', label: 'SET', value: values.CRC07_CUSTOS_DE_MATERIAIS.SET, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.OUT', label: 'OUT', value: values.CRC07_CUSTOS_DE_MATERIAIS.OUT, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.NOV', label: 'NOV', value: values.CRC07_CUSTOS_DE_MATERIAIS.NOV, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC07_CUSTOS_DE_MATERIAIS.DEZ', label: 'DEZ', value: values.CRC07_CUSTOS_DE_MATERIAIS.DEZ, typeRelease: releaseList[56], subSet: 'CRC', account: 'CRC07', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.JAN', label: 'JAN', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.JAN, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.FEV', label: 'FEV', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.FEV, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.MAR', label: 'MAR', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.MAR, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.ABR', label: 'ABR', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.ABR, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.MAI', label: 'MAI', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.MAI, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.JUN', label: 'JUN', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.JUN, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.JUL', label: 'JUL', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.JUL, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.AGO', label: 'AGO', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.AGO, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.SET', label: 'SET', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.SET, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.OUT', label: 'OUT', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.OUT, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.NOV', label: 'NOV', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.NOV, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC08_CUSTOS_DE_SUBCONTRATOS.DEZ', label: 'DEZ', value: values.CRC08_CUSTOS_DE_SUBCONTRATOS.DEZ, typeRelease: releaseList[57], subSet: 'CRC', account: 'CRC08', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC09_CUSTOS_FINANCEIROS.JAN', label: 'JAN', value: values.CRC09_CUSTOS_FINANCEIROS.JAN, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.FEV', label: 'FEV', value: values.CRC09_CUSTOS_FINANCEIROS.FEV, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.MAR', label: 'MAR', value: values.CRC09_CUSTOS_FINANCEIROS.MAR, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.ABR', label: 'ABR', value: values.CRC09_CUSTOS_FINANCEIROS.ABR, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.MAI', label: 'MAI', value: values.CRC09_CUSTOS_FINANCEIROS.MAI, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.JUN', label: 'JUN', value: values.CRC09_CUSTOS_FINANCEIROS.JUN, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.JUL', label: 'JUL', value: values.CRC09_CUSTOS_FINANCEIROS.JUL, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.AGO', label: 'AGO', value: values.CRC09_CUSTOS_FINANCEIROS.AGO, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.SET', label: 'SET', value: values.CRC09_CUSTOS_FINANCEIROS.SET, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.OUT', label: 'OUT', value: values.CRC09_CUSTOS_FINANCEIROS.OUT, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.NOV', label: 'NOV', value: values.CRC09_CUSTOS_FINANCEIROS.NOV, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC09_CUSTOS_FINANCEIROS.DEZ', label: 'DEZ', value: values.CRC09_CUSTOS_FINANCEIROS.DEZ, typeRelease: releaseList[58], subSet: 'CRC', account: 'CRC09', month: 11, disabled: currentMonth >= 12 },

      { name: 'CRC10_OUTROS.JAN', label: 'JAN', value: values.CRC10_OUTROS.JAN, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 0, disabled: currentMonth >= 1 },
      { name: 'CRC10_OUTROS.FEV', label: 'FEV', value: values.CRC10_OUTROS.FEV, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 1, disabled: currentMonth >= 2 },
      { name: 'CRC10_OUTROS.MAR', label: 'MAR', value: values.CRC10_OUTROS.MAR, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 2, disabled: currentMonth >= 3 },
      { name: 'CRC10_OUTROS.ABR', label: 'ABR', value: values.CRC10_OUTROS.ABR, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 3, disabled: currentMonth >= 4 },
      { name: 'CRC10_OUTROS.MAI', label: 'MAI', value: values.CRC10_OUTROS.MAI, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 4, disabled: currentMonth >= 5 },
      { name: 'CRC10_OUTROS.JUN', label: 'JUN', value: values.CRC10_OUTROS.JUN, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 5, disabled: currentMonth >= 6 },
      { name: 'CRC10_OUTROS.JUL', label: 'JUL', value: values.CRC10_OUTROS.JUL, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 6, disabled: currentMonth >= 7 },
      { name: 'CRC10_OUTROS.AGO', label: 'AGO', value: values.CRC10_OUTROS.AGO, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 7, disabled: currentMonth >= 8 },
      { name: 'CRC10_OUTROS.SET', label: 'SET', value: values.CRC10_OUTROS.SET, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 8, disabled: currentMonth >= 9 },
      { name: 'CRC10_OUTROS.OUT', label: 'OUT', value: values.CRC10_OUTROS.OUT, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 9, disabled: currentMonth >= 10 },
      { name: 'CRC10_OUTROS.NOV', label: 'NOV', value: values.CRC10_OUTROS.NOV, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 10, disabled: currentMonth >= 11 },
      { name: 'CRC10_OUTROS.DEZ', label: 'DEZ', value: values.CRC10_OUTROS.DEZ, typeRelease: releaseList[59], subSet: 'CRC', account: 'CRC10', month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const calcTotalProvider = (month) => {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[7].CUS;

    let total = { JAN: 0, FEV: 0, MAR: 0, ABR: 0, MAI: 0, JUN: 0, JUL: 0, AGO: 0, SET: 0, OUT: 0, NOV: 0, DEZ: 0 };

    localData.forEach((invoicing) => {
      switch (month) {
        case 0:
          total.JAN = total.JAN + maskToFloat2(invoicing.JAN);
          setTotal_state((total_state) => ({ ...total_state, JAN: maskToMoney3(total.JAN) }));
          break;
        case 1:
          total.FEV = total.FEV + maskToFloat2(invoicing.FEV);
          setTotal_state((total_state) => ({ ...total_state, FEV: maskToMoney3(total.FEV) }));
          break;
        case 2:
          total.MAR = total.MAR + maskToFloat2(invoicing.MAR);
          setTotal_state((total_state) => ({ ...total_state, MAR: maskToMoney3(total.MAR) }));
          break;
        case 3:
          total.ABR = total.ABR + maskToFloat2(invoicing.ABR);
          setTotal_state((total_state) => ({ ...total_state, ABR: maskToMoney3(total.ABR) }));
          break;
        case 4:
          total.MAI = total.MAI + maskToFloat2(invoicing.MAI);
          setTotal_state((total_state) => ({ ...total_state, MAI: maskToMoney3(total.MAI) }));
          break;
        case 5:
          total.JUN = total.JUN + maskToFloat2(invoicing.JUN);
          setTotal_state((total_state) => ({ ...total_state, JUN: maskToMoney3(total.JUN) }));
          break;
        case 6:
          total.JUL = total.JUL + maskToFloat2(invoicing.JUL);
          setTotal_state((total_state) => ({ ...total_state, JUL: maskToMoney3(total.JUL) }));
          break;
        case 7:
          total.AGO = total.AGO + maskToFloat2(invoicing.AGO);
          setTotal_state((total_state) => ({ ...total_state, AGO: maskToMoney3(total.AGO) }));
          break;
        case 8:
          total.SET = total.SET + maskToFloat2(invoicing.SET);
          setTotal_state((total_state) => ({ ...total_state, SET: maskToMoney3(total.SET) }));
          break;
        case 9:
          total.OUT = total.OUT + maskToFloat2(invoicing.OUT);
          setTotal_state((total_state) => ({ ...total_state, OUT: maskToMoney3(total.OUT) }));
          break;
        case 10:
          total.NOV = total.NOV + maskToFloat2(invoicing.NOV);
          setTotal_state((total_state) => ({ ...total_state, NOV: maskToMoney3(total.NOV) }));
          break;
        case 11:
          total.DEZ = total.DEZ + maskToFloat2(invoicing.DEZ);
          setTotal_state((total_state) => ({ ...total_state, DEZ: maskToMoney3(total.DEZ) }));
          break;
        case 777:
          total.JAN = total.JAN + maskToFloat2(invoicing.JAN);
          setTotal_state((total_state) => ({ ...total_state, JAN: maskToMoney3(total.JAN) }));

          total.FEV = total.FEV + maskToFloat2(invoicing.FEV);
          setTotal_state((total_state) => ({ ...total_state, FEV: maskToMoney3(total.FEV) }));

          total.MAR = total.MAR + maskToFloat2(invoicing.MAR);
          setTotal_state((total_state) => ({ ...total_state, MAR: maskToMoney3(total.MAR) }));

          total.ABR = total.ABR + maskToFloat2(invoicing.ABR);
          setTotal_state((total_state) => ({ ...total_state, ABR: maskToMoney3(total.ABR) }));

          total.MAI = total.MAI + maskToFloat2(invoicing.MAI);
          setTotal_state((total_state) => ({ ...total_state, MAI: maskToMoney3(total.MAI) }));

          total.JUN = total.JUN + maskToFloat2(invoicing.JUN);
          setTotal_state((total_state) => ({ ...total_state, JUN: maskToMoney3(total.JUN) }));

          total.JUL = total.JUL + maskToFloat2(invoicing.JUL);
          setTotal_state((total_state) => ({ ...total_state, JUL: maskToMoney3(total.JUL) }));

          total.AGO = total.AGO + maskToFloat2(invoicing.AGO);
          setTotal_state((total_state) => ({ ...total_state, AGO: maskToMoney3(total.AGO) }));

          total.SET = total.SET + maskToFloat2(invoicing.SET);
          setTotal_state((total_state) => ({ ...total_state, SET: maskToMoney3(total.SET) }));

          total.OUT = total.OUT + maskToFloat2(invoicing.OUT);
          setTotal_state((total_state) => ({ ...total_state, OUT: maskToMoney3(total.OUT) }));

          total.NOV = total.NOV + maskToFloat2(invoicing.NOV);
          setTotal_state((total_state) => ({ ...total_state, NOV: maskToMoney3(total.NOV) }));

          total.DEZ = total.DEZ + maskToFloat2(invoicing.DEZ);
          setTotal_state((total_state) => ({ ...total_state, DEZ: maskToMoney3(total.DEZ) }));
          break;
      }
    });
  };

  const calcProvider = (values: any, month: any, setFieldValue: any) => {
    let marginReal = 0;
    let marginPorc = 0;

    switch (month) {
      case 0:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JAN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JAN;
        break;
      case 1:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.FEV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.FEV;
        break;
      case 2:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAR;
        break;
      case 3:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.ABR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.ABR;
        break;
      case 4:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAI;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAI;
        break;
      case 5:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUN;
        break;
      case 6:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUL;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUL;
        break;
      case 7:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.AGO;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.AGO;
        break;
      case 8:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.SET;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.SET;
        break;
      case 9:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.OUT;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.OUT;
        break;
      case 10:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.NOV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.NOV;
        break;
      case 11:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.DEZ;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.DEZ;
        break;
    };


    props.marginReal({ month: month, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: month, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });


    setFieldValue(`MARGEM_REAL.PREVISTO[${month}]`, marginReal);
    setFieldValue(`MARGEM_PORC.PREVISTO[${month}]`, marginPorc);
  };


  function saveOnLocalStoragePR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[7].CUS.forEach((invoicing) => {
      // Essa função é porque o campo CMV não tem numeração Ex.: CMV01,CMV02,CMV03
      if (`${field.subSet}${field.typeRelease.substring(0, 2)}` === 'CMV01') {
        field.subSet = 'CMV';
        field.typeRelease = '-Merc.Vend.';
      };

      if (invoicing.TIPO.substring(0, 5) === `${field.subSet}${field.typeRelease.substring(0, 2)}`) {
        switch (field.label) {
          case 'JAN':
            invoicing.JAN = e;
            break;
          case 'FEV':
            invoicing.FEV = e;
            break;
          case 'MAR':
            invoicing.MAR = e;
            break;
          case 'ABR':
            invoicing.ABR = e;
            break;
          case 'MAI':
            invoicing.MAI = e;
            break;
          case 'JUN':
            invoicing.JUN = e;
            break;
          case 'JUL':
            invoicing.JUL = e;
            break;
          case 'AGO':
            invoicing.AGO = e;
            break;
          case 'SET':
            invoicing.SET = e;
            break;
          case 'OUT':
            invoicing.OUT = e;
            break;
          case 'NOV':
            invoicing.NOV = e;
            break;
          case 'DEZ':
            invoicing.DEZ = e;
            break;
          default:
            break;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  useEffect(() => {
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      calcTotalProvider(777);
    }
  }, []);

  return (
    <>

      {/* ORIGINAL / PLANEJADO */}
      {(props.releaseType?.COD_LANCAMENTO === 'OR' || props.releaseType?.COD_LANCAMENTO === 'PL') && (
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={PffGridValidationSchema}
          initialValues={mountPffModel(PffCustosModel)}
          onSubmit={() => {
            return null;
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <>
              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO ORIGINAL */}
                  {(props.releaseType?.COD_LANCAMENTO === 'OR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>

                      {releaseListSubset.map((subSet) => (
                        <>
                          {(subSet !== 'CRC2') && (

                            <TitleInterGroup titleAlign={'rigth'}>
                              <h1>{subSet.replaceAll('1', '')}</h1>
                            </TitleInterGroup>

                          )}


                          <ColFieldsGrid xs={12} >

                            {
                              listFieldsBudgeted(values).map((field) => (

                                (subSet === field.subSet) && (

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    name={field.name}
                                    label={field.label.substring(0, 20) + '. R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e: any) => {
                                      if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                                        handleChange(e);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                        saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                                      }
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                                        calcBudgeted(values, setFieldValue);
                                        saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        calcBudgeted(values, setFieldValue);
                                      }
                                    }}
                                  />
                                )
                              ))
                            }

                            {
                              {

                                'Total Custos':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS.ORCADO'}
                                    label={'Total Custos R$'}
                                    placeholder={'Total Custos R$'}
                                    value={maskToMoney2(values.TOTAL_CUSTOS.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMD':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMD.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMD.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMI':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMI.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMI.ORCADO)}
                                    type={'text'}
                                  />,

                                'CAP':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CAP.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CAP.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMM':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMM.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMM.ORCADO)}
                                    type={'text'}
                                  />,

                                'CCE':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CCE.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CCE.ORCADO)}
                                    type={'text'}
                                  />,

                                'CEQ':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CEQ.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CEQ.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMA':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMA.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMA.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMC':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMC.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMC.ORCADO)}
                                    type={'text'}
                                  />,

                                'CMV':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMV.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMV.ORCADO)}
                                    type={'text'}
                                  />,

                                'CSB':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CSB.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CSB.ORCADO)}
                                    type={'text'}
                                  />,

                                'CDG':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CDG.ORCADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CDG.ORCADO)}
                                    type={'text'}
                                  />,

                                'CRC2':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CRC.ORCADO'}
                                    label={`Total ${subSet.replaceAll('2', '')} R$`}
                                    placeholder={`Total ${subSet.replaceAll('2', '')} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CRC.ORCADO)}
                                    type={'text'}
                                  />,

                              }[subSet]
                            }

                          </ColFieldsGrid>
                        </>
                      ))}

                    </PffFilterContainerForm>

                  )}


                  {/* LANÇAMENTO PLANEJADO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PL') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>

                      {releaseListSubset.map((subSet) => (
                        <>
                          {subSet !== 'CRC2' &&

                            <TitleInterGroup titleAlign={'rigth'}>
                              <h1>{subSet.replaceAll('1', '')}</h1>
                            </TitleInterGroup>

                          }

                          <ColFieldsGrid xs={12}>

                            {listFieldsPlanned(values).map((field) => (

                              (subSet === field.subSet) && (

                                <TextInputLabelFloat
                                  fontSize={Metrics.fontSizePFFSm}
                                  fontSizeLabel={Metrics.fontSizePFFSm}
                                  name={field.name}
                                  label={field.label.substring(0, 20) + '. R$'}
                                  value={field.value}
                                  type={'text'}
                                  onChange={(e: any) => {
                                    if (e.target.value !== '') {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                                    }
                                  }}

                                  onBlur={(e) => {
                                    handleBlur(e);
                                    if (e.target.value !== '') {
                                      calcPlanned(values, setFieldValue);
                                      saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                                    }
                                  }}

                                  onFocus={(e) => {
                                    e.target.select();
                                  }}

                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                      calcPlanned(values, setFieldValue);
                                    }
                                  }}
                                />
                              )
                            ))}

                            {
                              {

                                'Total Custos':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS.PLANEJADO'}
                                    label={'Total Custos R$'}
                                    placeholder={'Total Custos R$'}
                                    value={maskToMoney2(values.TOTAL_CUSTOS.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMD':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMD.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMD.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMI':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMI.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMI.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CAP':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CAP.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CAP.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMM':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMM.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMM.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CCE':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CCE.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CCE.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CEQ':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CEQ.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CEQ.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMA':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMA.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMA.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMC':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMC.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMC.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CMV':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CMV.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CMV.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CSB':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CSB.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CSB.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CDG':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CDG.PLANEJADO'}
                                    label={`Total ${subSet} R$`}
                                    placeholder={`Total ${subSet} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CDG.PLANEJADO)}
                                    type={'text'}
                                  />,

                                'CRC2':

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    name={'TOTAL_CUSTOS_CRC.PLANEJADO'}
                                    label={`Total ${subSet.replaceAll('2', '')} R$`}
                                    placeholder={`Total ${subSet.replaceAll('2', '')} R$`}
                                    value={maskToMoney2(values.TOTAL_CUSTOS_CRC.PLANEJADO)}
                                    type={'text'}
                                  />,

                              }[subSet]
                            }


                          </ColFieldsGrid>

                        </>
                      ))}

                    </PffFilterContainerForm>
                  )}

                </SectionMargin>

              </ContainerView >

            </>
          )}
        </Formik >
      )}



      {/* PREVISTO */}
      {(props.releaseType?.COD_LANCAMENTO === 'PR') && (
        <>

          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModel)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
            }) => (
              <>
                <ContainerView>

                  <SectionMargin>


                    <PffFilterContainerForm auto onSubmit={handleSubmit}>

                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>Total de Custos</h1>
                        </TitleInterGroup>

                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProviderT(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.account}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'} />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  </SectionMargin>

                </ContainerView>

              </>
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMD)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <>

                <ContainerView>

                  <SectionMargin>

                    {/* LANÇAMENTO PREVISTO */}
                    {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                      <PffFilterContainerForm auto onSubmit={handleSubmit}>

                        <>

                          <TitleInterGroup titleAlign={'rigth'}>
                            <h1>CMD</h1>
                          </TitleInterGroup>

                          {releaseFirstList.map((account) => (

                            <ColFieldsGrid xs={12}>

                              {listFieldsProvider1(values).map((field) => (

                                (account === field.account) && (
                                  <>
                                    {field.month === 0 && (
                                      <ColFieldsGridTitle>

                                        <TitleSubSetAccProvider>
                                          {field.typeRelease}
                                        </TitleSubSetAccProvider>

                                      </ColFieldsGridTitle>
                                    )}


                                    <TextInputLabelFloat
                                      fontSize={Metrics.fontSizePFFSm}
                                      fontSizeLabel={Metrics.fontSizePFFSm}
                                      disabled={field.disabled}
                                      name={field.name}
                                      id={field.name}
                                      label={field.label + ' R$'}
                                      value={field.value}
                                      type={'text'}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                      }}

                                      onBlur={(e) => {
                                        handleBlur(e);
                                        if (e.target.value !== '') {
                                          saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                          calcProvider(values, field.month, setFieldValue);
                                          calcTotalProvider(field.month);
                                          setFieldValue(field.name, maskToMoney(e.target.value));
                                        }
                                        else {
                                          setFieldValue(field.name, '00');
                                        }
                                      }}

                                      onFocus={(e) => {
                                        e.target.select();
                                      }}

                                      onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                          saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                          calcProvider(values, field.month, setFieldValue);
                                          calcTotalProvider(field.month);
                                          setFieldValue(field.name, maskToMoney(e.target.value));
                                        }
                                      }}

                                    />

                                  </>
                                )

                              ))}

                            </ColFieldsGrid>

                          ))}
                        </>

                      </PffFilterContainerForm>

                    )}

                  </SectionMargin>

                </ContainerView >

              </>
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMI)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>

                      <>
                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CMI</h1>
                        </TitleInterGroup>

                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider2(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCAP)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CAP</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider3(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMM)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CMM</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider4(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCCE)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CCE</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider5(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCEQ)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CEQ</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider6(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMA)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CMA</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider7(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMC)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CMC</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider8(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCMV)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CMV</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider9(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCSB)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CSB</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider10(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCDG)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CDG</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider11(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >


          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PffGridValidationSchema}
            initialValues={mountPffModel(PffCustosModelCRC)}
            onSubmit={() => {
              return null;
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (

              <ContainerView>

                <SectionMargin>

                  {/* LANÇAMENTO PREVISTO */}
                  {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                    <PffFilterContainerForm auto onSubmit={handleSubmit}>
                      <>

                        <TitleInterGroup titleAlign={'rigth'}>
                          <h1>CRC</h1>
                        </TitleInterGroup>


                        {releaseFirstList.map((account) => (

                          <ColFieldsGrid xs={12}>

                            {listFieldsProvider12(values).map((field) => (

                              (account === field.account) && (
                                <>

                                  {field.month === 0 && (
                                    <ColFieldsGridTitle>

                                      <TitleSubSetAccProvider>
                                        {field.typeRelease}
                                      </TitleSubSetAccProvider>

                                    </ColFieldsGridTitle>
                                  )}


                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled={field.disabled}
                                    name={field.name}
                                    id={field.name}
                                    label={field.label + ' R$'}
                                    value={field.value}
                                    type={'text'}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(field.name, maskToMoney(e.target.value));
                                      saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                    }}

                                    onBlur={(e) => {
                                      handleBlur(e);
                                      if (e.target.value !== '') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                      else {
                                        setFieldValue(field.name, '00');
                                      }
                                    }}

                                    onFocus={(e) => {
                                      e.target.select();
                                    }}

                                    onKeyUp={(e) => {
                                      if (e.key === 'Enter') {
                                        saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                        calcProvider(values, field.month, setFieldValue);
                                        calcTotalProvider(field.month);
                                        setFieldValue(field.name, maskToMoney(e.target.value));
                                      }
                                    }}

                                  />

                                </>
                              )

                            ))}

                          </ColFieldsGrid>

                        ))}
                      </>

                    </PffFilterContainerForm>

                  )}

                </SectionMargin>

              </ContainerView >
            )}
          </Formik >

        </>
      )}

    </>
  );
};



export default Tab_CustosScreen;
