import React,
{
  useState,
} from 'react';
import {
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { SmeOrientacaoArray } from '../../../../../common/arrays/SmeSolicitacao';

import {
  Colors,
} from '../../../../../common/constants';

import {
  ObsSubTitleGroup,
  ObsTitleGroup,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  IconWarning,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';
import DropdownInput from '../../../../../components/TextFields/Dropdown';

import ApiWS from '../../../../../services/api.service';
import NovoFornecedoreValidationSchema from './novo-fornecedor.validation';
import NovoFornecedoresModel from './novo-fornecedor.model';
import TextInput from '../../../../../components/TextFields/Input';



interface IProps {
  show: boolean;
  anexar?: boolean;
  buttonText?: string;
  onHide?: any;

  fetchNow?: any;

  sm?: any;
}



const FORNECNovoFornecedorModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postSaveFornecedor(values) {
    setLoading(true);

    const url = '/fornecedores/cadastro';
    const payload = values;


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          IToast({
            type: 'success',
            message: res.data || 'Salvo com sucesso!',
          });

          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível salvar!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveFornecedor', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Solicitação de Novo Fornecedor'.toUpperCase()}
        </TitleGroup>

        {
          loading &&
          <LoadingsComponent fontSize={'12px'} size={'0.9rem'} />
        }


        <Formik
          enableReinitialize
          validateOnMount
          initialValues={NovoFornecedoresModel}
          validationSchema={NovoFornecedoreValidationSchema}
          onSubmit={(values) => {
            postSaveFornecedor(values);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <Row>

                  <Col xs={3}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'cnpj'}
                      label={'CNPJ'}
                      placeholder={'CNPJ'}
                      value={values.cnpj}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'razao_social'}
                      label={'Razão Social'}
                      placeholder={'Razão Social'}
                      value={values.razao_social}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={5}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'nome'}
                      label={'Nome'}
                      placeholder={'Nome'}
                      value={values.nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                </Row>


                <Row>

                  <Col xs={5}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'endereco'}
                      label={'Endereco'}
                      placeholder={'Endereco'}
                      value={values.endereco}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={2}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'cep'}
                      label={'CEP'}
                      placeholder={'CEP'}
                      value={values.cep}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={3}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'cidade'}
                      label={'Cidade'}
                      placeholder={'Cidade'}
                      value={values.cidade}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={2}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'uf'}
                      label={'UF'}
                      placeholder={'UF'}
                      value={values.uf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                </Row>


                <Row>

                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'contato'}
                      label={'Contato'}
                      placeholder={'Contato'}
                      value={values.contato}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'telefone'}
                      label={'Telefone'}
                      placeholder={'Telefone'}
                      value={values.telefone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'email'}
                      label={'Email'}
                      placeholder={'Email'}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>


                <Row>

                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'bco'}
                      label={'BCO'}
                      placeholder={'BCO'}
                      value={values.bco}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'agencia'}
                      label={'Agência'}
                      placeholder={'Agência'}
                      value={values.agencia}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'conta'}
                      label={'Conta'}
                      placeholder={'Conta'}
                      value={values.conta}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>


                <Row>

                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'tipopix'}
                      label={'Tipo PIX'}
                      placeholder={'Tipo PIX'}
                      min={0}
                      value={values.tipopix}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={4}>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'chavepix'}
                      label={'Chave PIX'}
                      placeholder={'Chave PIX'}
                      min={0}
                      value={values.chavepix}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>

              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.gray}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Enviar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



FORNECNovoFornecedorModal.defaultProps = {
  anexar: true,
  buttonText: 'Fechar',
};



export default FORNECNovoFornecedorModal;
