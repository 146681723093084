import * as Yup from 'yup';


const FuncionarioPstockValidationSchema = Yup.object().shape({

  input_funcionario: Yup.string(),

});



export default FuncionarioPstockValidationSchema;
