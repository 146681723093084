import styled from 'styled-components';
import { Col } from 'react-bootstrap';



export const ColItems_A = styled(Col)`
    width: 25%;    
    flex: none;
`;

export const ColLeader_A = styled(Col)`
    width: 27%;
    flex: none;    
`;

export const ColDescr_A = styled(Col)`
    width: 27%;
    flex: none;    
`;

export const ColObsPers_A = styled(Col)`
    width: 10.5%;
    flex: none;    
`;

export const ColDetours_A = styled(Col)`
    width: 10.5%;
    flex: none;    
`;



export const ColItems_B = styled(Col)`
    width: 25%;    
    flex: none;
`;

export const ColLeader_B = styled(Col)`
    width: 27%;
    flex: none;    
`;

export const ColDescr_B = styled(Col)`
    width: 27%;
    flex: none;    
`;

export const ColObsPers_B = styled(Col)`
    width: 10.5%;
    flex: none;    
`;

export const ColDetours_B = styled(Col)`
    width: 10.5%;
    flex: none;    
`;



export const ColItems_C = styled(Col)`
    width: 30%;    
    flex: none;
`;

export const ColLeader_C = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColDescr_C = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColObsPers_C = styled(Col)`
    width: 10%;
    flex: none;    
`;

export const ColDetours_C = styled(Col)`
    width: 10%;
    flex: none;    
`;



export const ColItems_D = styled(Col)`
    width: 30%;    
    flex: none;
`;

export const ColLeader_D = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColDescr_D = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColObsPers_D = styled(Col)`
    width: 10%;
    flex: none;    
`;

export const ColDetours_D = styled(Col)`
    width: 10%;
    flex: none;    
`;



export const ColItems_E = styled(Col)`
    width: 30%;    
    flex: none;
`;

export const ColLeader_E = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColDescr_E = styled(Col)`
    width: 25%;
    flex: none;    
`;

export const ColObsPers_E = styled(Col)`
    width: 10%;
    flex: none;    
`;

export const ColDetours_E = styled(Col)`
    width: 10%;
    flex: none;    
`;
