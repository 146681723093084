import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { YesNoArray } from '../../../../../common/arrays';
import { Colors, Images } from '../../../../../common/constants';
import { ContainerView, Content, ContentStyled, FilterContainerForm, Screen, TitleGroup } from '../../../../../common/styles/styled.layout';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IToast } from '../../../../../components/IToast/Toast';
import ListTransfer from '../../../../../components/ListTransfer';
import LoadingsComponent from '../../../../../components/Loadings';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import { PAV_NavRoutes } from '../../../../../navigation/navs/HumanResources';
import ApiWS from '../../../../../services/api.service';
import { RowButtonFiltersView } from '../../../Corporate/PFF/PFF_Aprovacao/PFF-aprovacao-styled';
import PAVConfiguracaoModel from './PAV-Configuracoes.model';
import PAVConfiguracoesValidationSchema from './PAV-Configuracoes.validation';



const PAV_ConfiguracoesScreen: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [auth, setAuth] = useState(false);
  const emptyObject = {
    image: Images.error401,
    title: 'Acesso Negado!',
    description: 'Você não tem permissão para o acesso.',
  };

  const [listInputs, setListInputs] = useState([]);
  const [listFunctions, setListFunctions] = useState([]);
  const [listNoParticipants, setListNoParticipants] = useState([]);



  function postLoadFunctionsParam() {
    setLoading(true);

    const url = '/pav/parametros/cargo';
    const payload = [{}];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        resp.data.forEach((func) => {
          setListFunctions((data) => [...data, { label: func.DESCR, value: func.CODIGO }]);
        });
      })
      .catch((err) => {
        console.error('postLoadFunctionsParam', err);

        IToast({
          type: 'warning',
          message: 'Ocorreu um erro! Tente novamente ou contate o suporte, caso erro persista.',
        });
      })
      .finally(() => setLoading(false));
  };

  function postLoadInputsParam() {
    setLoading(true);

    const url = '/pav/parametros/carregar';
    const payload = [{}];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        setListInputs(resp.data);


        resp.data[1].PAR2.forEach((func) => {
          setListNoParticipants((listNoParticipants) => [...listNoParticipants, { label: func.funcao, value: func.valor }]);
        });
      })
      .catch((err) => {
        console.error('postLoadInputsParam', err);

        IToast({
          type: 'warning',
          message: 'Ocorreu um erro! Tente novamente ou contate o suporte, caso erro persista.',
        });
      })
      .finally(() => postLoadFunctionsParam());
  };


  function listFields(values, inputs) {
    return ([
      { name: 'EST01', label: inputs[0]?.PAR1[0]?.descr, value: values.EST01, type: inputs[0]?.PAR1[0]?.tipo },
      { name: 'EST02', label: inputs[0]?.PAR1[1]?.descr, value: values.EST02, type: inputs[0]?.PAR1[1]?.tipo },
      { name: 'EST03', label: inputs[0]?.PAR1[2]?.descr, value: values.EST03, type: inputs[0]?.PAR1[2]?.tipo },
      { name: 'PON01', label: inputs[0]?.PAR1[3]?.descr, value: values.PON01, type: inputs[0]?.PAR1[3]?.tipo },
      { name: 'PON02', label: inputs[0]?.PAR1[4]?.descr, value: values.PON02, type: inputs[0]?.PAR1[4]?.tipo },
      { name: 'REC01', label: inputs[0]?.PAR1[5]?.descr, value: values.REC01, type: inputs[0]?.PAR1[5]?.tipo },
      { name: 'REC02', label: inputs[0]?.PAR1[6]?.descr, value: values.REC02, type: inputs[0]?.PAR1[6]?.tipo },
    ]);
  };


  function postSaveConfig(values) {
    setLoading(true);

    const payload = [];
    payload.push({ CODPARAMETRO: 'EST01', VALOR: values.EST01 });
    payload.push({ CODPARAMETRO: 'EST02', VALOR: values.EST02 });
    payload.push({ CODPARAMETRO: 'EST03', VALOR: values.EST03 });
    payload.push({ CODPARAMETRO: 'PON01', VALOR: values.PON01 });
    payload.push({ CODPARAMETRO: 'PON02', VALOR: values.PON02 });
    payload.push({ CODPARAMETRO: 'REC01', VALOR: values.REC01 });
    payload.push({ CODPARAMETRO: 'REC02', VALOR: values.REC02 });

    values.NAOPARTICIPANTES.forEach((func) => {
      payload.push({ CODPARAMETRO: 'LID01', VALOR: func.value });
    });

    console.log('payload:::', payload);

    const url = '/pav/parametros/salvar';

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });

          setTimeout(() => {
            location.reload();
          }, 3000);
        }
        else {
          IToast({
            type: 'warning',
            message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
          });
        }
      })
      .catch((err) => {
        console.error('postLoadInputsParam', err);

        IToast({
          type: 'warning',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });
      })
      .finally();
  };


  function postAuth() {
    setLoading(true);

    const url = '/pav/parametros/permissao';
    const payload = [{}];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setAuth(true);

          postLoadInputsParam();
        }
        else {
          setAuth(false);

          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })
      .catch((err) => {
        console.error('postLoadFunctionsParam', err);

        IToast({
          type: 'warning',
          message: 'Ocorreu um erro! Tente novamente ou contate o suporte, caso erro persista.',
        });
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    postAuth();
  }, []);



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PAV_NavRoutes}
          title={'PAV'}
        />
      </NavHeader>


      <TitleGroup>
        {'Configurações'.toUpperCase()}
      </TitleGroup>


      {loading && (<LoadingsComponent />)}



      {!loading && !auth && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description} />
      )}



      {!loading && auth && (
        <Content>

          <ContainerView>

            <ContentStyled fluid>

              <Formik
                enableReinitialize
                validateOnMount
                validationSchema={PAVConfiguracoesValidationSchema}
                initialValues={{
                  ...PAVConfiguracaoModel,
                  EST01: listInputs[0]?.PAR1[0]?.valor,
                  EST02: listInputs[0]?.PAR1[1]?.valor,
                  EST03: listInputs[0]?.PAR1[2]?.valor,
                  PON01: listInputs[0]?.PAR1[3]?.valor,
                  PON02: listInputs[0]?.PAR1[4]?.valor,
                  REC01: listInputs[0]?.PAR1[5]?.valor,
                  REC02: listInputs[0]?.PAR1[6]?.valor,
                }}
                onSubmit={(values) => {
                  postSaveConfig(values);
                }}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }) => (

                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row xs={3}>

                      {listFields(values, listInputs).map((input) => (
                        <Col>

                          {input.type === 'number' && (
                            <TextInput
                              disabled={loading}
                              type={'text'}
                              name={input.name}
                              label={input.label}
                              placeholder={input.label}
                              min={0}
                              value={input.value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}


                          {input.type === 'boolean' && (
                            <DropdownInput
                              disabled={loading}
                              name={input.name}
                              label={input.label}
                              dropArray={YesNoArray}
                              value={input.value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}

                        </Col>
                      ))}

                    </Row>


                    <Row>

                      <Col xs={1}>

                        <ListTransfer
                          titles={{
                            header: 'Lista dos não participantes',
                            listLeft: 'Todos os Cargos',
                            listRight: 'Não participantes',
                          }}
                          listLeft={listFunctions}
                          listRight={listNoParticipants}
                          returnRight={(list) => setFieldValue('NAOPARTICIPANTES', list)}
                        />

                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        backgroundColor={Colors?.gray}
                        text={'Cancelar'}
                        onClick={() => history.push(`/${NameRoutes.PAV_Destaques}`)}
                      />

                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Salvar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>

                )}

              </Formik>

            </ContentStyled>

          </ContainerView>

        </Content>
      )}

    </Screen >

  );
};



export default PAV_ConfiguracoesScreen;
