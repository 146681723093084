import React, { useEffect, useState } from 'react';
import { Form, Row, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import { Colors, Metrics } from '../../../../../../common/constants';

import {
  ContainerView, TabsStyled, TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../components/Buttons/IButton';
import { IconAdvances, IconControl, IconCost, IconDirectLabor, IconEffective, IconIndirectLabor, IconInvoicing, IconList, IconNext, IconNext2, IconOutsourcedLabor, IconProgress, IconReceivables, IconReturn, IconReturn2, IconTax } from '../../../../../../components/Icons';
import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import TextInputLabelFloat from '../../../../../../components/TextFields/InputLabelFloat';


import ApiWS from '../../../../../../services/api.service';
import { maskToMoney, maskToMoney3, maskToPorcent, maskToPorcent2, maskToMoney4 } from '../../../../../../utils/CurrencyUtils';
import { returnCalcMarginsAndTaxOfLS_Budgeted, returnCalcMarginsAndTaxOfLS_Planned, returnCalcMarginsAndTaxOfLS_Provider } from '../PFF-Formula';
import Tab_FaturamentoScreen from './PFF_Tabs/001PFF_TabFaturamento';
import Tab_ReceitasRecebiveisScreen from './PFF_Tabs/002PFF_TabReceitasRece';
import Tab_ReceitasControleScreen from './PFF_Tabs/003PFF_TabReceitasCont';
import Tab_ImpostosDiretoScreen from './PFF_Tabs/004PFF_TabImpostDireto';
import Tab_CustosScreen from './PFF_Tabs/005PFF_TabCustos';
import Tab_HhMODScreen from './PFF_Tabs/006PFF_TabHhMOD';
import Tab_HhMOIScreen from './PFF_Tabs/007PFF_TabHhMOI';
import Tab_HhTerceirosScreen from './PFF_Tabs/008PFF_TabHhTerceiros';
import Tab_AvancoScreen from './PFF_Tabs/009PFF_TabAvanco';
import Tab_AdiantamentoScreen from './PFF_Tabs/010PFF_TabAdiantamentos';
import Tab_EfetivoScreen from './PFF_Tabs/011PFF_TabEfetivo';
import Tab_ResumoScreen from './PFF_Tabs/012PFF_TabResumo';
import {
  FieldMarginHeader,
  FieldsHaderStyle,
  HeaderRelease,
  HeaderReleaseColLeft,
  HeaderReleaseColRightOrUp,
  PFFTabIconStyled,
  PFFTabTextStyled,
  PFFTabViewStyled,
  RowButtonsInteration,
  TitleInterGroup,
  HeaderReleaseColDow,
} from './PFF-grid-styled';
import PffGridValidationSchema from './PFF-grid-validation';
import { PffGridModel } from './PFF-grid.model';


interface IProps {
  releaseType?: any;
}

const PFF_InicioScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);
  const [sumMarginReal, setSumMarginReal] = useState(['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
  const [sumMarginPorc, setSumMarginPorc] = useState(['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);

  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    model = {
      ...model,
      TAXA_ISS: data[1]?.CAB[0]?.IMP01,
      TAXA_PIS_COFINS: data[1]?.CAB[0]?.IMP02,
      TAXA_ICMS_IPI: data[1]?.CAB[0]?.IMP03,
      TAXA_DESONERACAO: data[1]?.CAB[0]?.IMP04,

      VALOR_ORIGINAL: maskToMoney(data[1]?.CAB[0]?.VLORIG),
      VALOR_VIGENTE: maskToMoney(data[1]?.CAB[0]?.VLVIG),

      INICIO: data[1]?.CAB[0]?.DTINICIO,
      PRAZO: data[1]?.CAB[0]?.PRAZO,

      REV: data[1]?.CAB[0]?.REV,
      DESCSTATUS: data[1]?.CAB[0]?.DESCSTATUS,
      CC: props.releaseType.CC,
      ANO: props.releaseType.ANO,

      MARGEM_REAL: {
        ORCADO: data[2]?.MAR[0]?.ORCADO,
        PLANEJADO: data[2]?.MAR[0]?.PLANEJADO,
        JAN: data[2]?.MAR[0]?.JAN,
        FEV: data[2]?.MAR[0]?.FEV,
        MAR: data[2]?.MAR[0]?.MAR,
        ABR: data[2]?.MAR[0]?.ABR,
        MAI: data[2]?.MAR[0]?.MAI,
        JUN: data[2]?.MAR[0]?.JUN,
        JUL: data[2]?.MAR[0]?.JUL,
        AGO: data[2]?.MAR[0]?.AGO,
        SET: data[2]?.MAR[0]?.SET,
        OUT: data[2]?.MAR[0]?.OUT,
        NOV: data[2]?.MAR[0]?.NOV,
        DEZ: data[2]?.MAR[0]?.DEZ,
      },

      MARGEM_PORCENTAGEM: {
        ORCADO: data[2]?.MAR[1]?.ORCADO,
        PLANEJADO: data[2]?.MAR[1]?.PLANEJADO,
        JAN: data[2]?.MAR[1]?.JAN,
        FEV: data[2]?.MAR[1]?.FEV,
        MAR: data[2]?.MAR[1]?.MAR,
        ABR: data[2]?.MAR[1]?.ABR,
        MAI: data[2]?.MAR[1]?.MAI,
        JUN: data[2]?.MAR[1]?.JUN,
        JUL: data[2]?.MAR[1]?.JUL,
        AGO: data[2]?.MAR[1]?.AGO,
        SET: data[2]?.MAR[1]?.SET,
        OUT: data[2]?.MAR[1]?.OUT,
        NOV: data[2]?.MAR[1]?.NOV,
        DEZ: data[2]?.MAR[1]?.DEZ,
      },
    };

    return model;
  };


  function postFilter(values) {
    alert(JSON.stringify(values));
  };


  const fieldsHeadersList = (values) => [
    { type: 'text', name: 'TAXA_ISS', label_place: 'ISS%', value: values?.TAXA_ISS?.replaceAll('.', ',') },
    { type: 'text', name: 'TAXA_PIS_COFINS', label_place: 'PIS/COFINS%', value: values?.TAXA_PIS_COFINS?.replaceAll('.', ',') },
    { type: 'text', name: 'TAXA_ICMS_IPI', label_place: 'ICMS/IPI%', value: values?.TAXA_ICMS_IPI?.replaceAll('.', ',') },
    { type: 'text', name: 'TAXA_DESONERACAO', label_place: 'Desoneração%', value: values?.TAXA_DESONERACAO?.replaceAll('.', ',') },
    { type: 'text', name: 'VALOR_ORIGINAL', label_place: 'Vlr. Original', value: values?.VALOR_ORIGINAL },
    { type: 'text', name: 'VALOR_VIGENTE', label_place: 'Vlr. Vigente', value: values?.VALOR_VIGENTE },
    { type: 'date', name: 'INICIO', label_place: 'Início', value: values?.INICIO },
    { type: 'number', name: 'PRAZO', label_place: 'Prazo (Dias)', value: values?.PRAZO },
    { type: 'text', name: 'REV', label_place: 'Revisão', value: values?.REV, disabled: true },
    { type: 'text', name: 'DESCSTATUS', label_place: 'Status', value: values?.DESCSTATUS, disabled: true },
    { type: 'text', name: 'CC', label_place: 'CC', value: values?.CC, disabled: true },
    { type: 'number', name: 'ANO', label_place: 'Ano', value: values?.ANO, disabled: true },
  ];


  const listFieldsMargin = (values) => {
    const listMarginValueField = [];
    const listMarginPerctField = [];


    listMarginValueField.push(
      { name: values.MARGEM_REAL.JAN, label: 'JAN', value: values.MARGEM_REAL.JAN },
      { name: values.MARGEM_REAL.FEV, label: 'FEV', value: values.MARGEM_REAL.FEV },
      { name: values.MARGEM_REAL.MAR, label: 'MAR', value: values.MARGEM_REAL.MAR },
      { name: values.MARGEM_REAL.ABR, label: 'ABR', value: values.MARGEM_REAL.ABR },
      { name: values.MARGEM_REAL.MAI, label: 'MAI', value: values.MARGEM_REAL.MAI },
      { name: values.MARGEM_REAL.JUN, label: 'JUN', value: values.MARGEM_REAL.JUN },
      { name: values.MARGEM_REAL.JUL, label: 'JUL', value: values.MARGEM_REAL.JUL },
      { name: values.MARGEM_REAL.AGO, label: 'AGO', value: values.MARGEM_REAL.AGO },
      { name: values.MARGEM_REAL.SET, label: 'SET', value: values.MARGEM_REAL.SET },
      { name: values.MARGEM_REAL.OUT, label: 'OUT', value: values.MARGEM_REAL.OUT },
      { name: values.MARGEM_REAL.NOV, label: 'NOV', value: values.MARGEM_REAL.NOV },
      { name: values.MARGEM_REAL.DEZ, label: 'DEZ', value: values.MARGEM_REAL.DEZ },
    );

    listMarginPerctField.push(
      { name: values.MARGEM_PORCENTAGEM.JAN, label: 'JAN', value: values.MARGEM_PORCENTAGEM.JAN },
      { name: values.MARGEM_PORCENTAGEM.FEV, label: 'FEV', value: values.MARGEM_PORCENTAGEM.FEV },
      { name: values.MARGEM_PORCENTAGEM.MAR, label: 'MAR', value: values.MARGEM_PORCENTAGEM.MAR },
      { name: values.MARGEM_PORCENTAGEM.ABR, label: 'ABR', value: values.MARGEM_PORCENTAGEM.ABR },
      { name: values.MARGEM_PORCENTAGEM.MAI, label: 'MAI', value: values.MARGEM_PORCENTAGEM.MAI },
      { name: values.MARGEM_PORCENTAGEM.JUN, label: 'JUN', value: values.MARGEM_PORCENTAGEM.JUN },
      { name: values.MARGEM_PORCENTAGEM.JUL, label: 'JUL', value: values.MARGEM_PORCENTAGEM.JUL },
      { name: values.MARGEM_PORCENTAGEM.AGO, label: 'AGO', value: values.MARGEM_PORCENTAGEM.AGO },
      { name: values.MARGEM_PORCENTAGEM.SET, label: 'SET', value: values.MARGEM_PORCENTAGEM.SET },
      { name: values.MARGEM_PORCENTAGEM.OUT, label: 'OUT', value: values.MARGEM_PORCENTAGEM.OUT },
      { name: values.MARGEM_PORCENTAGEM.NOV, label: 'NOV', value: values.MARGEM_PORCENTAGEM.NOV },
      { name: values.MARGEM_PORCENTAGEM.DEZ, label: 'DEZ', value: values.MARGEM_PORCENTAGEM.DEZ },
    );

    return {
      listMarginValueField: listMarginValueField,
      listMarginPerctField: listMarginPerctField,
    };
  };


  // Calcula as margens quando altera os impostos no cabecalho
  function changeMarginsCaseForHeader() {
    const localData_DATA = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    if (props.releaseType.COD_LANCAMENTO === 'OR' || props.releaseType.COD_LANCAMENTO === 'PL') {
      // Realtime
      setSumMarginReal([returnCalcMarginsAndTaxOfLS_Budgeted().REAL.toFixed(2)]);
      setSumMarginPorc([returnCalcMarginsAndTaxOfLS_Budgeted().PORC.toFixed(2)]);


      // Salva no LS para o Resumo
      if (props.releaseType.COD_LANCAMENTO === 'OR') {
        localData_DATA[2].MAR[0].ORCADO = returnCalcMarginsAndTaxOfLS_Budgeted().REAL.toFixed(2);;
        localData_DATA[2].MAR[1].ORCADO = returnCalcMarginsAndTaxOfLS_Budgeted().PORC.toFixed(2);
      }
      else if (props.releaseType.COD_LANCAMENTO === 'PL') {
        localData_DATA[2].MAR[0].PLANEJADO = returnCalcMarginsAndTaxOfLS_Planned().REAL.toFixed(2);
        localData_DATA[2].MAR[1].PLANEJADO = returnCalcMarginsAndTaxOfLS_Planned().PORC.toFixed(2);
      }

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData_DATA));
    }

    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      setSumMarginReal([
        returnCalcMarginsAndTaxOfLS_Provider().REAL.JAN.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.FEV.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.MAR.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.ABR.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.MAI.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.JUN.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.JUL.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.AGO.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.SET.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.OUT.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.NOV.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().REAL.DEZ.toFixed(2),
      ]);

      setSumMarginPorc([
        returnCalcMarginsAndTaxOfLS_Provider().PORC.JAN.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.FEV.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.MAR.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.ABR.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.MAI.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.JUN.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.JUL.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.AGO.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.SET.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.OUT.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.NOV.toFixed(2),
        returnCalcMarginsAndTaxOfLS_Provider().PORC.DEZ.toFixed(2),
      ]);

      localData_DATA[2].MAR[0].JAN = returnCalcMarginsAndTaxOfLS_Provider().REAL.JAN.toFixed(2);
      localData_DATA[2].MAR[0].FEV = returnCalcMarginsAndTaxOfLS_Provider().REAL.FEV.toFixed(2);
      localData_DATA[2].MAR[0].MAR = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAR.toFixed(2);
      localData_DATA[2].MAR[0].ABR = returnCalcMarginsAndTaxOfLS_Provider().REAL.ABR.toFixed(2);
      localData_DATA[2].MAR[0].MAI = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAI.toFixed(2);
      localData_DATA[2].MAR[0].JUN = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUN.toFixed(2);
      localData_DATA[2].MAR[0].JUL = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUL.toFixed(2);
      localData_DATA[2].MAR[0].AGO = returnCalcMarginsAndTaxOfLS_Provider().REAL.AGO.toFixed(2);
      localData_DATA[2].MAR[0].SET = returnCalcMarginsAndTaxOfLS_Provider().REAL.SET.toFixed(2);
      localData_DATA[2].MAR[0].OUT = returnCalcMarginsAndTaxOfLS_Provider().REAL.OUT.toFixed(2);
      localData_DATA[2].MAR[0].NOV = returnCalcMarginsAndTaxOfLS_Provider().REAL.NOV.toFixed(2);
      localData_DATA[2].MAR[0].DEZ = returnCalcMarginsAndTaxOfLS_Provider().REAL.DEZ.toFixed(2);

      localData_DATA[2].MAR[1].JAN = returnCalcMarginsAndTaxOfLS_Provider().PORC.JAN.toFixed(2);
      localData_DATA[2].MAR[1].FEV = returnCalcMarginsAndTaxOfLS_Provider().PORC.FEV.toFixed(2);
      localData_DATA[2].MAR[1].MAR = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAR.toFixed(2);
      localData_DATA[2].MAR[1].ABR = returnCalcMarginsAndTaxOfLS_Provider().PORC.ABR.toFixed(2);
      localData_DATA[2].MAR[1].MAI = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAI.toFixed(2);
      localData_DATA[2].MAR[1].JUN = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUN.toFixed(2);
      localData_DATA[2].MAR[1].JUL = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUL.toFixed(2);
      localData_DATA[2].MAR[1].AGO = returnCalcMarginsAndTaxOfLS_Provider().PORC.AGO.toFixed(2);
      localData_DATA[2].MAR[1].SET = returnCalcMarginsAndTaxOfLS_Provider().PORC.SET.toFixed(2);
      localData_DATA[2].MAR[1].OUT = returnCalcMarginsAndTaxOfLS_Provider().PORC.OUT.toFixed(2);
      localData_DATA[2].MAR[1].NOV = returnCalcMarginsAndTaxOfLS_Provider().PORC.NOV.toFixed(2);
      localData_DATA[2].MAR[1].DEZ = returnCalcMarginsAndTaxOfLS_Provider().PORC.DEZ.toFixed(2);


      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData_DATA));
    }
  };


  function postDraftSave() {
    setLoading(true);

    const url = '/pff/lancamento/salvar';
    const payload = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });
        }
        else if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
            autoClose: Metrics.messageAutoCloseDouble,
          });
        }
      })
      .catch((_err) => IToast({
        type: 'error',
        message: 'Ocorreu um erro, contate o suporte!',
      }))
      .finally(() => setLoading(false));
  };


  function postRecordSave() {
    setLoading(true);

    const url = '/pff/lancamento/salvar';
    const payload = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    payload[1].CAB[0].STATUS = '20';

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });

          setInterval(() => {
            window.location.reload();
          }, 2000);
        }
        else if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
            autoClose: Metrics.messageAutoCloseDouble,
          });
        }
      })
      .catch((_err) => IToast({
        type: 'error',
        message: 'Ocorreu um erro, contate o suporte!',
      }))
      .finally(() => setLoading(true));
  };


  function writeLocalStorageMarginReal(releaseType, tempListMarginReal, month) {
    const localStorageData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));
    if (releaseType === 'OR') {
      localStorageData[2].MAR[0].ORCADO = tempListMarginReal[0];

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
    else if (releaseType === 'PL') {
      localStorageData[2].MAR[0].PLANEJADO = tempListMarginReal[0];

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
    else if (releaseType === 'PR') {
      switch (month) {
        case 0:
          localStorageData[2].MAR[0].JAN = tempListMarginReal[0];
          break;
        case 1:
          localStorageData[2].MAR[0].FEV = tempListMarginReal[1];
          break;
        case 2:
          localStorageData[2].MAR[0].MAR = tempListMarginReal[2];
          break;
        case 3:
          localStorageData[2].MAR[0].ABR = tempListMarginReal[3];
          break;
        case 4:
          localStorageData[2].MAR[0].MAI = tempListMarginReal[4];
          break;
        case 5:
          localStorageData[2].MAR[0].JUN = tempListMarginReal[5];
          break;
        case 6:
          localStorageData[2].MAR[0].JUL = tempListMarginReal[6];
          break;
        case 7:
          localStorageData[2].MAR[0].AGO = tempListMarginReal[7];
          break;
        case 8:
          localStorageData[2].MAR[0].SET = tempListMarginReal[8];
          break;
        case 9:
          localStorageData[2].MAR[0].OUT = tempListMarginReal[9];
          break;
        case 10:
          localStorageData[2].MAR[0].NOV = tempListMarginReal[10];
          break;
        case 11:
          localStorageData[2].MAR[0].DEZ = tempListMarginReal[11];
          break;
      }

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
  }


  function writeLocalStorageMarginPorc(releaseType, tempListMarginPorc, month) {
    const localStorageData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));
    if (releaseType === 'OR') {
      localStorageData[2].MAR[1].ORCADO = tempListMarginPorc[0];

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
    else if (releaseType === 'PL') {
      localStorageData[2].MAR[1].PLANEJADO = tempListMarginPorc[0];

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
    else {
      switch (month) {
        case 0:
          localStorageData[2].MAR[1].JAN = tempListMarginPorc[0];
          break;
        case 1:
          localStorageData[2].MAR[1].FEV = tempListMarginPorc[1];
          break;
        case 2:
          localStorageData[2].MAR[1].MAR = tempListMarginPorc[2];
          break;
        case 3:
          localStorageData[2].MAR[1].ABR = tempListMarginPorc[3];
          break;
        case 4:
          localStorageData[2].MAR[1].MAI = tempListMarginPorc[4];
          break;
        case 5:
          localStorageData[2].MAR[1].JUN = tempListMarginPorc[5];
          break;
        case 6:
          localStorageData[2].MAR[1].JUL = tempListMarginPorc[6];
          break;
        case 7:
          localStorageData[2].MAR[1].AGO = tempListMarginPorc[7];
          break;
        case 8:
          localStorageData[2].MAR[1].SET = tempListMarginPorc[8];
          break;
        case 9:
          localStorageData[2].MAR[1].OUT = tempListMarginPorc[9];
          break;
        case 10:
          localStorageData[2].MAR[1].NOV = tempListMarginPorc[10];
          break;
        case 11:
          localStorageData[2].MAR[1].DEZ = tempListMarginPorc[11];
          break;
      }

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localStorageData));
    }
  }



  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconInvoicing size={16} />,
        text: 'Faturamento',
      },
      screenTab:
        <Tab_FaturamentoScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 1,
      title: {
        icon: <IconReceivables size={16} />,
        text: 'Receitas Recebíveis',
      },
      screenTab:
        <Tab_ReceitasRecebiveisScreen
          marginReal={(props) => {
            const tempListMarginReal = [...sumMarginReal];
            tempListMarginReal[props.month] = (props.value);
            setSumMarginReal(tempListMarginReal);

            writeLocalStorageMarginReal(props.releaseType, tempListMarginReal, props.month);
          }}

          marginPorc={(props) => {
            const tempListMarginPorc = [...sumMarginPorc];
            tempListMarginPorc[props.month] = props.value;
            setSumMarginPorc(tempListMarginPorc);

            writeLocalStorageMarginPorc(props.releaseType, tempListMarginPorc, props.month);
          }}

          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 2,
      title: {
        icon: <IconControl size={16} />,
        text: 'Receitas Controle',
      },
      screenTab:
        <Tab_ReceitasControleScreen
          marginReal={(props) => {
            const tempListMarginReal = [...sumMarginReal];
            tempListMarginReal[props.month] = (props.value);
            setSumMarginReal(tempListMarginReal);

            writeLocalStorageMarginReal(props.releaseType, tempListMarginReal, props.month);
          }}

          marginPorc={(props) => {
            const tempListMarginPorc = [...sumMarginPorc];
            tempListMarginPorc[props.month] = props.value;
            setSumMarginPorc(tempListMarginPorc);

            writeLocalStorageMarginPorc(props.releaseType, tempListMarginPorc, props.month);
          }}

          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 3,
      title: {
        icon: <IconTax size={16} />,
        text: 'Impostos Diretos',
      },
      screenTab:
        <Tab_ImpostosDiretoScreen
          marginReal={(props) => {
            const tempListMarginReal = [...sumMarginReal];
            tempListMarginReal[props.month] = (props.value);
            setSumMarginReal(tempListMarginReal);

            writeLocalStorageMarginReal(props.releaseType, tempListMarginReal, props.month);
          }}

          marginPorc={(props) => {
            const tempListMarginPorc = [...sumMarginPorc];
            tempListMarginPorc[props.month] = props.value;
            setSumMarginPorc(tempListMarginPorc);

            writeLocalStorageMarginPorc(props.releaseType, tempListMarginPorc, props.month);
          }}

          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 4,
      title: {
        icon: <IconCost size={16} />,
        text: 'Custos',
      },
      screenTab:
        <Tab_CustosScreen
          marginReal={(props) => {
            const tempListMarginReal = [...sumMarginReal];
            tempListMarginReal[props.month] = (props.value);
            setSumMarginReal(tempListMarginReal);

            writeLocalStorageMarginReal(props.releaseType, tempListMarginReal, props.month);
          }}

          marginPorc={(props) => {
            const tempListMarginPorc = [...sumMarginPorc];
            tempListMarginPorc[props.month] = props.value;
            setSumMarginPorc(tempListMarginPorc);

            writeLocalStorageMarginPorc(props.releaseType, tempListMarginPorc, props.month);
          }}

          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 5,
      title: {
        icon: <IconDirectLabor size={16} />,
        text: 'Hh MOD',
      },
      screenTab:
        <Tab_HhMODScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 6,
      title: {
        icon: <IconIndirectLabor size={16} />,
        text: 'Hh MOI',
      },
      screenTab:
        <Tab_HhMOIScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 7,
      title: {
        icon: <IconOutsourcedLabor size={16} />,
        text: 'Hh Terceiros',
      },
      screenTab:
        <Tab_HhTerceirosScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 8,
      title: {
        icon: <IconProgress size={16} />,
        text: 'Avanço',
      },
      screenTab:
        <Tab_AvancoScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 9,
      title: {
        icon: <IconAdvances size={16} />,
        text: 'Adiantamentos',
      },
      screenTab:
        <Tab_AdiantamentoScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 10,
      title: {
        icon: <IconEffective size={16} />,
        text: 'Efetivo',
      },
      screenTab:
        <Tab_EfetivoScreen
          releaseType={props.releaseType}
        />,
    },


    {
      eventKey: 11,
      title: {
        icon: <IconList size={16} />,
        text: 'Resumo',
      },
      screenTab:
        <Tab_ResumoScreen
          releaseType={props.releaseType}
        />,
    },

  ];


  useEffect(() => {
    const ls_var = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    if (props.releaseType?.COD_LANCAMENTO === 'OR') {
      const marginReal_var = ls_var[2].MAR[0].ORCADO;
      const marginPorc_var = ls_var[2].MAR[1].ORCADO;
      setSumMarginReal([marginReal_var]);
      setSumMarginPorc([marginPorc_var]);
    }


    if (props.releaseType?.COD_LANCAMENTO === 'PL') {
      const marginReal_var = ls_var[2].MAR[0].PLANEJADO;
      const marginPorc_var = ls_var[2].MAR[1].PLANEJADO;
      setSumMarginReal([marginReal_var]);
      setSumMarginPorc([marginPorc_var]);
    }

    if (props.releaseType?.COD_LANCAMENTO === 'PR') {
      const marginReal_var = {
        JAN: ls_var[2].MAR[0].JAN,
        FEV: ls_var[2].MAR[0].FEV,
        MAR: ls_var[2].MAR[0].MAR,
        ABR: ls_var[2].MAR[0].ABR,
        MAI: ls_var[2].MAR[0].MAI,
        JUN: ls_var[2].MAR[0].JUN,
        JUL: ls_var[2].MAR[0].JUL,
        AGO: ls_var[2].MAR[0].AGO,
        SET: ls_var[2].MAR[0].SET,
        OUT: ls_var[2].MAR[0].OUT,
        NOV: ls_var[2].MAR[0].NOV,
        DEZ: ls_var[2].MAR[0].DEZ,
      };

      const marginPorc_var = {
        JAN: ls_var[2].MAR[1].JAN,
        FEV: ls_var[2].MAR[1].FEV,
        MAR: ls_var[2].MAR[1].MAR,
        ABR: ls_var[2].MAR[1].ABR,
        MAI: ls_var[2].MAR[1].MAI,
        JUN: ls_var[2].MAR[1].JUN,
        JUL: ls_var[2].MAR[1].JUL,
        AGO: ls_var[2].MAR[1].AGO,
        SET: ls_var[2].MAR[1].SET,
        OUT: ls_var[2].MAR[1].OUT,
        NOV: ls_var[2].MAR[1].NOV,
        DEZ: ls_var[2].MAR[1].DEZ,
      };


      setSumMarginReal([
        marginReal_var.JAN,
        marginReal_var.FEV,
        marginReal_var.MAR,
        marginReal_var.ABR,
        marginReal_var.MAI,
        marginReal_var.JUN,
        marginReal_var.JUL,
        marginReal_var.AGO,
        marginReal_var.SET,
        marginReal_var.OUT,
        marginReal_var.NOV,
        marginReal_var.DEZ,
      ]);

      setSumMarginPorc([
        maskToPorcent(marginPorc_var.JAN),
        maskToPorcent(marginPorc_var.FEV),
        maskToPorcent(marginPorc_var.MAR),
        maskToPorcent(marginPorc_var.ABR),
        maskToPorcent(marginPorc_var.MAI),
        maskToPorcent(marginPorc_var.JUN),
        maskToPorcent(marginPorc_var.JUL),
        maskToPorcent(marginPorc_var.AGO),
        maskToPorcent(marginPorc_var.SET),
        maskToPorcent(marginPorc_var.OUT),
        maskToPorcent(marginPorc_var.NOV),
        maskToPorcent(marginPorc_var.DEZ),
      ]);
    }
  }, []);



  return (

    <Formik
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffGridModel)}
      onSubmit={(values) => {
        postFilter(values);
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>

          <TitleGroup>
            {`${'Lançamento'} de ${props.releaseType.LANCAMENTO}`.toUpperCase()}
          </TitleGroup>


          {
            loading &&
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          }


          {
            !loading &&

            <Form style={{ margin: '-1.5em 4em' }} onSubmit={handleSubmit}>

              <Row>

                <TabsStyled
                  id={'control-tab-pcb'}
                  activeKey={currentTab}
                  onSelect={(tab) => setCurrentTab(parseInt(tab))}
                >

                  {tabsList.map((tab) => (

                    <Tab
                      eventKey={tab.eventKey}
                      title={
                        <PFFTabViewStyled>

                          <PFFTabIconStyled>
                            {tab.title.icon}
                          </PFFTabIconStyled>



                          <PFFTabTextStyled fontSizeHeader={1}>
                            {tab.title.text}
                          </PFFTabTextStyled>

                        </PFFTabViewStyled>
                      }>

                      <HeaderRelease>

                        {/* MARGENS ORIGINAL / PLANEJADO */}

                        {currentTab !== 11 &&
                          <HeaderReleaseColLeft md={6}>

                            {(props.releaseType.COD_LANCAMENTO === 'OR' || props.releaseType.COD_LANCAMENTO === 'PL') &&
                              <Row styled={{ '--bs-gutter-x': '0' }}>
                                <FieldMarginHeader md={4} valueColorBorder={sumMarginReal[0]}>
                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFLg}
                                    fontSizeLabel={Metrics.fontSizePFFLg}
                                    disabled
                                    height={70}
                                    type={'text'}
                                    name={'MARGEM_REAL.ORCADO'}
                                    label={'Margem (R$)'}
                                    placeholder={'Margem (R$)'}
                                    value={maskToMoney3(sumMarginReal[0])} />
                                </FieldMarginHeader>


                                <FieldMarginHeader md={4} valueColorBorder={parseFloat(sumMarginPorc[0])}>
                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFLg}
                                    fontSizeLabel={Metrics.fontSizePFFLg}
                                    disabled
                                    height={70}
                                    type={'text'}
                                    name={'MARGEM_PORCENTAGEM.ORCADO'}
                                    label={'Margem (%)'}
                                    placeholder={'Margem (%)'}
                                    value={maskToPorcent(parseFloat(sumMarginPorc[0]).toFixed(2))} />
                                </FieldMarginHeader>

                              </Row>
                            }

                          </HeaderReleaseColLeft>
                        }

                        {/* CABECALHO */}
                        <HeaderReleaseColRightOrUp
                          md={
                            currentTab === 11 && 12 ||
                            props.releaseType.COD_LANCAMENTO === 'OR' && 6 ||
                            props.releaseType.COD_LANCAMENTO === 'PL' && 6 ||
                            props.releaseType.COD_LANCAMENTO === 'PR' && 12
                          }
                        >

                          <Row
                            md={
                              currentTab === 11 && 12 ||
                              props.releaseType.COD_LANCAMENTO === 'OR' && 6 ||
                              props.releaseType.COD_LANCAMENTO === 'PL' && 6 ||
                              props.releaseType.COD_LANCAMENTO === 'PR' && 12
                            }
                          >
                            {fieldsHeadersList(values).map((field) => (

                              <FieldsHaderStyle>

                                <TextInputLabelFloat
                                  fontSize={Metrics.fontSizePFFSm}
                                  fontSizeLabel={Metrics.fontSizePFFSm}
                                  disabled={field.disabled || currentTab === 11}
                                  type={field.type}
                                  name={field.name}
                                  label={field.label_place}
                                  placeholder={field.label_place}
                                  value={field.value}

                                  onBlur={(e) => {
                                    e.target.value = e.target.value === '' ? '0' : e.target.value;
                                    setFieldValue(field.name, e.target.value);

                                    changeMarginsCaseForHeader();
                                  }}

                                  onFocus={(e) => {
                                    e.target.select();

                                    // const localData_DATA = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

                                    // e.target.value = e.target.value === '' ? '0' : e.target.value;
                                    // setFieldValue(field.name, e.target.value);


                                    // localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData_DATA));

                                    // changeMarginsCaseForHeader();
                                  }}

                                  onChange={(e: any) => {
                                    if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                                      handleChange(e);

                                      const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));
                                      switch (field.label_place) {
                                        case 'ISS%':
                                          localData[1].CAB[0].IMP01 = maskToPorcent2(e.target.value);
                                          setFieldValue(field.name, maskToPorcent2(e.target.value));
                                          break;
                                        case 'PIS/COFINS%':
                                          localData[1].CAB[0].IMP02 = maskToPorcent2(e.target.value);
                                          setFieldValue(field.name, maskToPorcent2(e.target.value));
                                          break;
                                        case 'ICMS/IPI%':
                                          localData[1].CAB[0].IMP03 = maskToPorcent2(e.target.value);
                                          setFieldValue(field.name, maskToPorcent2(e.target.value));
                                          break;
                                        case 'Desoneração%':
                                          localData[1].CAB[0].IMP04 = maskToPorcent2(e.target.value);
                                          setFieldValue(field.name, maskToPorcent2(e.target.value));
                                          break;
                                        case 'Vlr. Original':
                                          localData[1].CAB[0].VLORIG = maskToMoney(e.target.value);
                                          setFieldValue(field.name, maskToMoney(e.target.value));
                                          break;
                                        case 'Vlr. Vigente':
                                          localData[1].CAB[0].VLVIG = maskToMoney(e.target.value);
                                          setFieldValue(field.name, maskToMoney(e.target.value));
                                          break;
                                        case 'Início':
                                          localData[1].CAB[0].DTINICIO = e.target.value;
                                          setFieldValue(field.name, e.target.value);
                                          break;
                                        case 'Prazo (Dias)':
                                          localData[1].CAB[0].PRAZO = e.target.value;
                                          setFieldValue(field.name, e.target.value);
                                          break;

                                        default:
                                          break;
                                      }


                                      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
                                    }
                                  }} />
                              </FieldsHaderStyle>

                            ))}

                          </Row>

                        </HeaderReleaseColRightOrUp>



                        {/* MARGENS PREVISTO */}

                        {(props.releaseType.COD_LANCAMENTO === 'PR' && currentTab !== 11) &&
                          <HeaderReleaseColDow md={12}>

                            <TitleInterGroup>
                              <h1>{'Margens'}</h1>
                            </TitleInterGroup>

                            <Row md={12}>

                              {listFieldsMargin(values).listMarginValueField.map((field, index) => (

                                <FieldsHaderStyle>

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    type={'text'}
                                    name={field.name}
                                    label={`${field.label} R$`}
                                    placeholder={`${field.label} R$`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={maskToMoney4(sumMarginReal[index])?.replaceAll('--', '-')} />

                                </FieldsHaderStyle>

                              ))}

                            </Row>


                            <Row md={12}>
                              {listFieldsMargin(values).listMarginPerctField.map((field, index) => (

                                <FieldsHaderStyle>

                                  <TextInputLabelFloat
                                    fontSize={Metrics.fontSizePFFSm}
                                    fontSizeLabel={Metrics.fontSizePFFSm}
                                    disabled
                                    type={'text'}
                                    name={field.name}
                                    label={`${field.label} %`}
                                    placeholder={`${field.label} %`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={maskToPorcent(parseFloat(sumMarginPorc[index]).toFixed(2))} />

                                </FieldsHaderStyle>

                              ))}

                            </Row>

                          </HeaderReleaseColDow>

                        }

                      </HeaderRelease>



                      {tab.screenTab}


                    </Tab>
                  ))}

                </TabsStyled>

              </Row>



              <RowButtonsInteration xl={6}>



                {currentTab > 0 &&
                  <IButton
                    backgroundColor={Colors?.grayDark}
                    type={'button'}
                    text={'Início'}
                    iconLeft={
                      <IconReturn2 color={Colors?.white} size={16} />
                    }
                    onClick={() => {
                      setCurrentTab(0);
                    }}
                  />
                }

                {currentTab !== 0 &&
                  <IButton
                    backgroundColor={Colors?.grayDark}
                    type={'button'}
                    text={'Retornar'}
                    iconLeft={
                      <IconReturn color={Colors?.white} />
                    }
                    onClick={() => {
                      setCurrentTab(currentTab - 1);
                    }}
                  />
                }



                {currentTab >= 0 && currentTab !== (tabsList.length - 1) &&
                  <>
                    <IButton
                      backgroundColor={Colors?.grayDark}
                      type={'button'}
                      text={'Avançar'}
                      iconRigth={
                        <IconNext color={Colors?.white} />
                      }
                      onClick={() => {
                        setCurrentTab(currentTab + 1);
                      }}
                    />


                    <IButton
                      backgroundColor={Colors?.grayDark}
                      type={'button'}
                      text={'Resumo'}
                      iconRigth={
                        <IconNext2 color={Colors?.white} size={16} />
                      }
                      onClick={() => {
                        setCurrentTab(tabsList.length - 1);
                      }}
                    />
                  </>
                }



                <IButton
                  backgroundColor={Colors?.quaternaryLight}
                  type={'button'}
                  text={'Salvar Rascunho'}
                  iconRigth={
                    <IconNext2 color={Colors?.white} size={16} />
                  }
                  onClick={() => {
                    postDraftSave();
                  }}
                />


                {
                  currentTab === (tabsList.length - 1) &&
                  <IButton
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                    text={'Gravar PFF'}
                    onClick={() => {
                      postRecordSave();
                    }}
                  />
                }

              </RowButtonsInteration>

            </Form>

          }


        </ContainerView >
      )}
    </Formik>
  );
};



export default PFF_InicioScreen;
