import React from 'react';

import {
  OverlayTrigger,
} from 'react-bootstrap';

import {
  TableActionButton,
  TableToltip,
} from '../../../common/styles/styled.layout';

interface IProps {
  key?: any;
  disabled?: boolean;
  backgroundColor?: any;

  tooltip?: any;
  icon?: any;
  placement?: any;
  trigger?: any;
  overlay?: any;

  onClick?: any;
}



const ButtonTableAction: React.FC<IProps> = (props) => {
  return (

    <OverlayTrigger
      key={props.key || 'left'}
      placement={props.placement || 'left'}
      trigger={props.trigger || ['hover', 'hover']}
      overlay={ props.overlay ||
        <TableToltip className="d-print-none">
          {props.tooltip}
        </TableToltip>
      }>

      <TableActionButton
        backgroundColor={props.backgroundColor}
        onClick={props.onClick}>

        {props.icon}

      </TableActionButton>

    </OverlayTrigger>

  );
};



export default ButtonTableAction;
