import React, { useState } from 'react';

import {
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';


import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonAttach from '../../../../../components/Buttons/ButtonAttach';
import IButton from '../../../../../components/Buttons/IButton';

import { IconArchiveImport } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
  postRelease?: any;
}



const ImportModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [fileSheet, setFileSheet] = useState(null);



  const onFileSheet = (event: any) => {
    setFileSheet(event.target.files[0]);
  };

  function postImportSheet(values: any) {
    if (fileSheet) {
      setLoading(true);
      const formData = new FormData();
      const url = '/pff/lancamento/importaprevisto';

      formData.append('TIPO', values.COD_LANCAMENTO);
      formData.append('CC', values.CC);
      formData.append('ANO', values.ANO);
      formData.append('NEG', values.NEGOCIO);

      formData.append('file', fileSheet);

      ApiWS()
        .post(url, formData, { headers: {
          'Content-Type': 'multipart/form-data',
        } })
        .then((res) => {
          if (res.status === 200) {
            IToast({
              type: 'success',
              message: res.data || 'Importado com sucesso!',
            });

            props.postRelease();
          }
          else {
            IToast({
              type: 'warning',
              message: res.data || 'Verifique a planilha, ou contate o suporte!',
            });
          }
        })
        .catch((error) => {
          console.error('ErrorProdutos', JSON.stringify(error.response, null, 2));

          IToast({
            type: 'error',
            message: 'Ocorreu um erro! Tente novamente, caso o erro persista entre em contato com o suporte.',
          });
        })
        .finally(() => {
          setFileSheet(null);
          setLoading(false);
        });
    }
    else {
      IToast({
        type: 'info',
        message: 'Selecione um arquivo!',
      });
    }
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Importar Lançamento do PFF'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={props.values}
          onSubmit={(values) => {
            postImportSheet(values);
          }}>
          {({
            handleSubmit,
          }) => (

            <Form onSubmit={handleSubmit}>

              <Row>

                <Col xs={6}>
                  <ButtonAttach
                    disabled={loading}
                    idButton={'pff-import-sheet2'}
                    fileGroup={fileSheet}
                    onChangeButton={onFileSheet}
                    accept={'.xlsm'}
                  />
                </Col>


                <Col xs={3}>
                  <IButton
                    disabled={loading}
                    type={'submit'}
                    backgroundColor={Colors?.excel}
                    text={'Importar'}
                    iconLeft={
                      <IconArchiveImport
                        color={'#FFFFFF'}
                        size={20}
                      />
                    }
                  />
                </Col>

                {loading && (
                  <Col xs={3}>
                    <LoadingsComponent size={'10px'} />
                  </Col>
                )}
              </Row>

              <br />


              <ModalFooterStyled>

                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.gray}
                  text={'Cancelar'}
                  type={'button'}
                  onClick={() => {
                    props.onHide(false);
                  }}
                />



              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default ImportModal;
