import React,
{
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import SistemasCUDGIModal from '../../../_Modals/002SistemasCUDGIModal ';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GISistemaModel from './GI-Tab_Sistema.model';
import GISistemaValidationSchema from './GI-Tab_Sistema.validation';


const Tab_SistemaScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listDepartment, setListDepartment] = useState([]);
  const [listSystem, setListSystem] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem pedidos!',
    description: 'Não há nenhum pedido aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postFetchDepartment(values) {
    setLoading(true);

    const url = 'gi/departamento/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((dep, index) => {
            setListDepartment(
              (list) => [...list, {
                key: index,
                label: dep.DESCR,
                value: dep.ID,
              }],
            );
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchDepartment', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postFetchSystem(values, page_iteration: any) {
    setLoading(true);
    setShowList(false);

    const url = 'gi/sistema/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListSystem(res.data);
        }


        if (res.status === 200 && res.data.length === 0) {
          setEmptyObject({
            image: Images.empty,
            title: 'Sem Sistemas!',
            description: 'Não há nenhum sistema aqui',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchSystem', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchSystem(valuesPayload, page_var);
    setPage(page_var);
  };


  const options = {
    custom: true,
    totalSize: listSystem.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'DISPONIVEL',
      text: 'Status',
      sort: true,
      formatter: (_cell, row) => {
        if (row.DISPONIVEL === 1) {
          return 'Ativo'.toLocaleUpperCase();
        }
        else if (row.DISPONIVEL === 0 || row.DISPONIVEL === '') {
          return 'Desativado'.toLocaleUpperCase();
        };
      },
    },
    {
      dataField: 'VERSAO',
      text: 'Versão',
      sort: true,
    },
    {
      dataField: 'ROTA',
      text: 'Rota',
      sort: true,
    },
    {
      dataField: '',
      text: 'Detal./Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.DETAILS}/${TextTooltip.EDIT}`}
          onClick={() => {
            setShowModalEdit(true);
            setValuesEdit({
              ID: row.ID,
              DESCR: row.DESCR,
              IDDEP: row.IDDEP,
              MANUAL: row.MANUAL,
              IMAGEM: row.IMAGEM,
              ORDEM: row.ORDEM,
              COR: row.COR,
              VERSAO: row.VERSAO,
              ROTA: row.ROTA,
              DISP: row.DISPONIVEL,
            });
          }}
          icon={
            <IconEdit color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove({
                ID: row.ID,
                DESCR: row.DESCR,
                IDDEP: row.IDDEP,
                MANUAL: row.MANUAL,
                IMAGEM: row.IMAGEM,
                ORDEM: row.ORDEM,
                COR: row.COR,
                VERSAO: row.VERSAO,
                ROTA: row.ROTA,
                DISP: row.DISPONIVEL,
              });
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  useEffect(() => {
    postFetchDepartment({ DESCR: '' });
  }, []);



  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listSystem}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.PCB_Pedidos,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              validationSchema={GISistemaValidationSchema}
              initialValues={GISistemaModel}
              onSubmit={(values) => {
                postFetchSystem(values, 1);
              }}>
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
              }) => (

                <>

                  {loading && (
                    <LoadingsComponent />
                  )}



                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>

                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'DESCR'}
                          label={'Sistema'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'IDDEP'}
                          label={'Departamento'}
                          placeholder={'Todos'}
                          dropArray={listDepartment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.IDDEP || 'TODOS'}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        type={'button'}
                        backgroundColor={Colors?.grayDark}
                        text={'Novo'}
                        onClick={() => {
                          setShowModalNew(true);
                        }}
                      />


                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>

                </>

              )}

            </Formik>



            {!loading && showList && listSystem.length === 0 && (

              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />

            )}


            {!loading && showList && listSystem.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={listSystem}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}

      </ToolkitProvider>



      {showModalNew && (
        <SistemasCUDGIModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Novo Sistema'}
          type={'NEW'}
          fetchNow={() => postFetchSystem({ DESCR: '', IDDEP: '' }, 1)}
        />
      )}



      {showModalEdit && (
        <SistemasCUDGIModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Sistema'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchSystem({ DESCR: '', IDDEP: '' }, 1)}
        />
      )}



      {showModalRemove && (
        <SistemasCUDGIModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Sistema'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchSystem({ DESCR: '', IDDEP: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_SistemaScreen;
