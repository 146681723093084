const PStockProdutoModel = {
  input_cod: '',
  input_produto: '',
  input_grupo: '',
  input_cc: 'Todos'.toLocaleUpperCase(),
  input_deposito: '',
};



export default PStockProdutoModel;
