import styled from 'styled-components';
import { Colors } from '../../../../../../common/constants';
import { TitleGroup } from '../../../../../../common/styles/styled.layout';

export const ImgStyled = styled.img`
  max-height: 12em;
  min-height: 12em;
  max-width:  12em;
  min-width:  12em;
  opacity: 70%;
  padding: 5px;
  cursor: pointer;

  transition: all 0.7s;
  -webkit-filter: blur(1px);
  filter: blur(1px);

  :hover {
    opacity: 100%;
    border: 5px solid ${Colors?.primary};
    border-radius: 50%;

    transform: rotateZ(0);
    -webkit-transform: rotateZ(-360deg) scale(1.5);
    filter: blur(0);
    -webkit-filter: blur(0);
  }
`;

export const ImgStyledSelected = styled.img`
  max-height: 12.9em;
  min-height: 12.9em;
  max-width:  12.9em;
  min-width:  12.9em;
  padding: 7px;
  cursor: pointer;

  filter: blur(0);
  -webkit-filter: blur(0);
  border: 7px solid ${Colors?.primary};
  border-radius: 50%;
`;

export const TitleGroupPAV = styled(TitleGroup)`
  margin-top: -20px;
  margin-bottom: 30px;
`;

export const TextHelp = styled.p`
  color: ${Colors?.danger};
`;
