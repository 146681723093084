import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  format,
} from 'date-fns';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TablePrinterStyled,
  TableTdButtonNextCss,
} from '../../../../../common/styles/styled.layout';

import { IconPrinter } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';

import NameRoutes from '../../../../../navigation/name';
import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';
import { intranet_config } from '../../../../../utils/Config';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';
import { formataData } from '../../../../../utils/DateUtils';

import {
  PrinterContainer,
  A4LandscapeContent,
} from './PBC-contratos-impressao.styled';



const PBC_ContratosItensImpressao: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const { filial, contrato, rev, plan } = useParams() as any;


  const [loading, setLoading] = useState(false);


  const [listItens, setListItens] = useState([]);
  const [ultimaRev, setUltimaRev] = useState([]);

  const [valorNovoTotal, setValorNovoTotal] = useState(0);
  const [valorMedidoTotal, setValorMedidoTotal] = useState(0);
  const [valorAditivoTotal, setValorAditivoTotal] = useState(0);
  const [valorNovoSaldoTotal, setValorNovoSaldoTotal] = useState(0);


  async function fetchContrato(params: any) {
    try {
      const objToBack = {
        'FILIAL': params.filial,
        'CONTRATO': params.contrato,
        'REV': params.rev,
        'PLANILHA': params.plan,
      };

      setLoading(true);

      ApiWS()
        .post('/suprimento/consulta/contrato/itens', objToBack)
        .then((resp) => {
          setListItens(resp.data);
          let somaNovoTotal = 0;
          let somaMedidoTotal = 0;
          let somaAditivoTotal = 0;
          let somaNovoSaldoTotal = 0;
          resp.data.forEach((item) => {
            somaNovoTotal += item.PRECO_UNIT * item.QTDADE;
            somaMedidoTotal += item.VALOR_MEDIDO;
            somaAditivoTotal += item.ITEM_ADITIVO;
            somaNovoSaldoTotal += item.SALDO_ATUAL;
          });
          setValorNovoTotal(somaNovoTotal);
          setValorMedidoTotal(somaMedidoTotal);
          setValorAditivoTotal(somaAditivoTotal);
          setValorNovoSaldoTotal(somaNovoSaldoTotal);
        })
        .catch((error) => {
          console.error('fetchContrato', JSON.stringify(error.response, null, 2));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }
  };


  async function fetchContratoUltimaRev(params: any) {
    try {
      const objToBack = {
        'FILIAL': params.filial,
        'CONTRATO': params.contrato,
        'PLANILHA': params.plan,
        'REV': params.rev,
      };
      console.log(objToBack);
      setLoading(true);


      ApiWS()
        .post('/suprimento/consulta/contrato/ultima_rev', objToBack)
        .then((resp) => {
          setUltimaRev(resp.data);
        })
        .catch((error) => {
          console.error('findItens', JSON.stringify(error.response, null, 2));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    catch (error) {
      console.error('fetchContratoUltimaRev', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }
  };


  async function printerLandscapePage() {
    await fetchContrato({ filial: filial, contrato: contrato, rev: rev, plan: plan });
    await fetchContratoUltimaRev({ filial: filial, contrato: contrato, rev: rev, plan: plan });
    // window.print();
  }



  useEffect(() => {
    printerLandscapePage();
  }, [contrato]);


  function renderLoadingComponent() {
    return (

      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </PrinterContainer>

    );
  }


  function renderLoadingPrinter() {
    return (
      <A4LandscapeContent className="page-a4-landscape">

        <TablePrinterStyled>
          <tbody>
            <tr>
              <td rowSpan={3} className="image-td">
                <img
                  alt="Logo"
                  src={intranet_config.Images.logo}
                />
              </td>
              <th rowSpan={3} className="name-printer-td">
                Informações do Contrato/Aditivo
              </th>
              <th colSpan={2}>Contrato</th>
              <td colSpan={2}>{ultimaRev.map((contr) => contr.CT)[ultimaRev.length - 1]}</td>
            </tr>

            <tr>
              <th colSpan={2}>Data Início</th>
              <td colSpan={2}>{ultimaRev.map((contr) => formataData(contr.INICIO))[ultimaRev.length - 1]}</td>
            </tr>
            <tr>
              <th colSpan={2}>Data Fim</th>
              <td colSpan={2}>{ultimaRev.map((contr) => formataData(contr.FIM))[ultimaRev.length - 1]}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>

        <h4 className='text-center text-secondary'>Informações do Contrato</h4>
        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Filial</th>
              <th>Nª da OS</th>
              <th>Fornecedor</th>
              <th>Ult. Revisão</th>
              <th>Planilha</th>
              <th>SME</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{ultimaRev.map((contr) => contr.EMPRESA)[ultimaRev.length - 1]}</td>  {/* Empresa */}
              <td>{ultimaRev.map((contr) => contr.FILIAL)[ultimaRev.length - 1]}</td> {/* Filial */}
              <td>{ultimaRev.map((contr) => contr.OS)[ultimaRev.length - 1]}</td> {/* Nª da OS */}
              <td>{ultimaRev.map((contr) => contr.FORNECEDOR)[ultimaRev.length - 1]}</td> {/* Fornecedor */}
              <td>{ultimaRev.map((contr) => contr.REV)[ultimaRev.length - 1]}</td> {/* Ult. Revisão */}
              <td>{ultimaRev.map((contr) => contr.PLANILHA)[ultimaRev.length - 1]}</td> {/* Planilha */}
              <td> {/* SME */}
                {ultimaRev.map((contr) => contr.SM)[ultimaRev.length - 1] !== '00000'
                  ? <>
                    <TableTdButtonNextCss
                      onClick={() => {
                        window.open(`/${NameRoutes.SME_Impressao}/${ultimaRev.map((contr) => contr.FILIAL)[ultimaRev.length - 1]}${ultimaRev.map((contr) => contr.SM)[ultimaRev.length - 1]}`, '_blank').focus();
                      }}
                    >
                      {ultimaRev.map((contr) => contr.SM)[ultimaRev.length - 1]}
                    </TableTdButtonNextCss>
                  </>
                  : 'N/A'
                }
              </td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <tbody>
            <tr>
              <th>Aditivo</th>
              <th>Tipo de Aditivo</th>
              <th>Vl. Total do Contrato Original (Revisão 000)</th>
              <th>Vl. Total do Contrato Vigente (Revisão {ultimaRev.map((contr) => contr.REVVIG)[ultimaRev.length - 1]})</th>
              <th>Vl. Total do Contrato à aprovar (Revisão {ultimaRev.map((contr) => contr.REV)[ultimaRev.length - 1]})</th>
              <th>Valor Aditivo (Revisão {ultimaRev.map((contr) => contr.REV)[ultimaRev.length - 1]})</th>
            </tr>

            <tr>
              <td>{ultimaRev.map((contr) => contr.ADITIVO)[ultimaRev.length - 1]}</td>
              <td>{ultimaRev.map((contr) => contr.TIP_ADITIVO)[ultimaRev.length - 1]}</td>
              <td>{listItens.map((contr) => convertCurrencyBRL(contr.VLREV00))[listItens.length - 1]}</td>
              <td>{ultimaRev.map((contr) => convertCurrencyBRL(contr.VLVIG))[ultimaRev.length - 1]}</td>
              <td>{ultimaRev.map((contr) => convertCurrencyBRL(contr.VLREV))[ultimaRev.length - 1]}</td>
              <td>{ultimaRev.map((contr) => convertCurrencyBRL(contr.VLADIT))[ultimaRev.length - 1]}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>



        <h4 className='text-center text-secondary'>Informações desta Revisão</h4>
        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Item</th>
              <th>Código</th>
              <th>Descrição</th>
              <th>Complemento</th>
              <th>UND.</th>
              <th>Qtd. Nova</th>
              <th>PU. Novo</th>
              <th>Valor Novo</th>
              <th>Valor Medido</th>
              <th>Aditivo R$</th>
              <th>Novo Saldo R$</th>
            </tr>
          </thead>


          <tbody>
            <tr>
              <th colSpan={7}>Total</th>
              <th>{convertCurrencyBRL(valorNovoTotal)}</th>
              <th>{convertCurrencyBRL(valorMedidoTotal)}</th>
              <th>{convertCurrencyBRL(valorAditivoTotal)}</th>
              <th>{convertCurrencyBRL(valorNovoSaldoTotal)}</th>
            </tr>
            {listItens.map((item, key) => (
              <tr key={key}>
                <td>{item.ITEM}</td> {/* Item */}
                <td>{item.CODIGO}</td> {/* Código */}
                <td>{item.DESCRICAO}</td> {/* Descrição */}
                <td>{item.COMPLEMENTO}</td> {/* Complemento */}
                <td>{item.UNID}</td> {/* UND */}
                <td>{item.QTDADE}</td> {/* Qtd. Nova */}
                <td>{convertCurrencyBRL(item.PRECO_UNIT)}</td> {/* PU. Novo */}
                <td>{convertCurrencyBRL((item.PRECO_UNIT * item.QTDADE))}</td> {/* Valor Novo */}
                <td>{convertCurrencyBRL(item.VALOR_MEDIDO)}</td> {/* Valor Medido > */}
                <td>{convertCurrencyBRL(item.ITEM_ADITIVO)}</td> {/* Aditivo R$ */}
                <td>{convertCurrencyBRL(item.SALDO_ATUAL)}</td> {/* Novo Saldo R$ */}
              </tr>
            ))}
          </tbody>
        </TablePrinterStyled>



        <TablePrinterStyled>
          <thead>
            <tr>
              <th className='text-center'>Detalhe</th>
            </tr>
          </thead>


          <tbody>
            <tr>
              <td colSpan={2} height={90}>Justificativa:{<br />}{listItens.map((contr) => contr.JUST)[0]} </td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <div>
          <p><i>* - Relatório Gerado por: {user.name} em: {format(new Date(Date.now()), 'dd/MM/yyyy HH:mm')}</i></p>

          <div>
            <TableActionButton
              onClick={() => {
                window.print();
              }}>
              <IconPrinter
                color={Colors?.black}
                size={72}
              />
            </TableActionButton>
          </div>
        </div>

      </A4LandscapeContent>
    );
  }


  return loading
    ? renderLoadingComponent()
    : renderLoadingPrinter();
};



export default PBC_ContratosItensImpressao;
