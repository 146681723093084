import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';


import IButton from '../../../../../../../components/Buttons/IButton';

import {
  IconSearch,
} from '../../../../../../../components/Icons';

import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TextInput from '../../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../../services/api.service';
import PcbManutencaoCnpjModel from './PBC-manutencao-tab-cnpj.model';
import PcbManutencaoCnpjValidationSchema from './PBC-manutencao-tab-cnpj.validation';



const Tab_CnpjScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [consultCheckdCNPJ, setConsultCheckdCNPJ] = useState(false);
  const [consultCheckdCNPJNovo, setConsultCheckdCNPJNovo] = useState(false);


  function postCNPJChange(values: any, resetForm: any) {
    const payload = {
      'CNPJANT': values.input_cnpj,
      'CNPJNOVO': values.input_cnpj_novo,
      'FILIAL': values.input_filial,
      'NUM': values.input_num_pedido,
    };

    setLoading(true);
    ApiWS()
      .post('/suprimento/manutencao/altera/cnpj', payload)
      .then((resp) => {
        if (resp.status === 200) {
          resetForm();
          IToast({
            type: 'success',
            message: 'CNPJ alterado com sucesso!',
          });
          setConsultCheckdCNPJ(false);
          setConsultCheckdCNPJNovo(false);
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro ao alterar o CNPJ! Contate o suporte.',
        });

        console.error('postCNPJChange', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function fetchRequest(values: any, setFieldValue: any) {
    const payload = {
      'FILIAL': values.input_filial,
      'NUM': values.input_num_pedido,
    };

    setLoading(true);

    ApiWS()
      .post('/suprimento/manutencao/consulta/pedido', payload)
      .then((resp) => {
        if (resp.status === 200 && resp.data.length > 0) {
          setFieldValue('input_cnpj', resp.data[0].CNPJ);
          setFieldValue('input_loja', resp.data[0].LOJA);
          setFieldValue('input_fornecedor', resp.data[0].COD_FORNEC);
          setConsultCheckdCNPJ(true);
        }
        else {
          IToast({
            type: 'warning',
            message: 'Filial ou Pedido não encontrado!',
          });
          setConsultCheckdCNPJ(false);
        }
      })
      .catch((error) => {
        IToast({
          message: `Erro ${error.code}. Contate o suporte!`,
          type: 'error',
        });
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
        setConsultCheckdCNPJ(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function fetchCNPJNew(cnpj: string, setFieldValue: any) {
    setLoading(true);

    ApiWS()
      .post('/suprimento/manutencao/consulta/cnpj', { 'CNPJ': cnpj })
      .then((resp) => {
        if (resp.status === 200 && resp.data.length > 0) {
          setFieldValue('input_loja_novo', resp.data[0].LOJA);
          setFieldValue('input_fornecedor_novo', resp.data[0].COD_FORNECE);
          setConsultCheckdCNPJNovo(true);
        }
        else {
          IToast({
            type: 'warning',
            message: 'Fornecedor não encontrado. Verifique CNPJ!',
          });
          setConsultCheckdCNPJNovo(false);
        }
      })
      .catch((error) => {
        IToast({
          message: `Erro ${error.code}. Contate o suporte!`,
          type: 'error',
        });
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
        setConsultCheckdCNPJNovo(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PcbManutencaoCnpjValidationSchema}
      initialValues={PcbManutencaoCnpjModel}
      onSubmit={(values, { resetForm }) => {
        postCNPJChange(values, resetForm);
      }}>
      {({
        values,
        isValid,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setErrors,
        validateField,
      }) => (

        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Alterar CNPJ'.toUpperCase()}
            </TitleGroup>

            {loading &&
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            }

            <SectionMargin>
              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>
                  <Row xs={4}>

                    <Col>
                      <TextInput
                        disabled={loading || consultCheckdCNPJ}
                        type={'text'}
                        name={'input_filial'}
                        label={'Filial'}
                        placeholder={'Número'}
                        value={values.input_filial}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>



                    <Col style={{ paddingRight: '0' }}>
                      <TextInput
                        disabled={loading || consultCheckdCNPJ}
                        type={'text'}
                        name={'input_num_pedido'}
                        label={'Pedido'}
                        placeholder={'Número do Pedido'}
                        value={values.input_num_pedido}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        buttonIcon={
                          <IconSearch
                            color={'black'}
                            size={20}
                          />
                        }
                        buttonClick={() => {
                          if (values.input_filial.length === 2
                            && values.input_num_pedido.length === 6
                          ) {
                            fetchRequest(values, setFieldValue);
                          }
                          else {
                            IToast({
                              type: 'warning',
                              message: 'Filial e Pedido devem ser preenchidos corretamente!',
                            });
                          }
                        }}
                      />
                    </Col>

                  </Row>


                  <Col>
                    <TextInput
                      disabled={true}
                      type={'text'}
                      name={'input_cnpj'}
                      label={'CNPJ'}
                      placeholder={'CNPJ'}
                      value={values.input_cnpj}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue('input_loja', '');
                        setFieldValue('input_fornecedor', '');
                      }}
                      onBlur={handleBlur}
                    />


                    <TextInput
                      disabled={true}
                      type={'text'}
                      name={'input_loja'}
                      label={'Loja'}
                      placeholder={'Consulte Pedido'}
                      value={values.input_loja}
                    />


                    <TextInput
                      disabled={true}
                      type={'text'}
                      name={'input_fornecedor'}
                      label={'Fornecedor'}
                      placeholder={'Consulte Pedido'}
                      value={values.input_fornecedor}
                    />
                  </Col>



                  <Col>

                    <TextInput
                      disabled={loading || consultCheckdCNPJNovo}
                      type={'text'}
                      name={'input_cnpj_novo'}
                      label={'CNPJ Novo'}
                      placeholder={'CNPJ Novo'}
                      value={values.input_cnpj_novo}
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue('input_loja_novo', '');
                        setFieldValue('input_fornecedor_novo', '');
                      }}
                      onBlur={handleBlur}
                      buttonIcon={
                        <IconSearch
                          color={'black'}
                          size={20}
                        />
                      }
                      buttonClick={() => {
                        if (values.input_cnpj_novo.length === 14) {
                          if (values.input_cnpj.substring(0, 8) !== values.input_cnpj_novo.substring(0, 8)) {
                            setErrors({ input_cnpj_novo: 'O prefixo deve ser igual ao do CNPJ atual.' });
                          }
                          else {
                            fetchCNPJNew(values.input_cnpj_novo, setFieldValue);
                          }
                        }
                        else {
                          IToast({
                            type: 'warning',
                            message: 'CNPJ Novo deve ser preenchido corretamente!',
                          });
                        }
                      }}
                    />


                    <TextInput
                      disabled={true}
                      type={'text'}
                      name={'input_loja_novo'}
                      label={'Loja Nova'}
                      placeholder={'Consulte CNPJ Novo'}
                      value={values.input_loja_novo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />


                    <TextInput
                      disabled={true}
                      type={'text'}
                      name={'input_fornecedor_novo'}
                      label={'Fornecedor Novo'}
                      placeholder={'Consulte CNPJ Novo'}
                      value={values.input_fornecedor_novo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loading}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();
                      setConsultCheckdCNPJ(false);
                      setConsultCheckdCNPJNovo(false);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    text={'Alterar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                    onClick={() => {
                      if (!isValid) {
                        IToast({
                          type: 'warning',
                          message: 'Preencha todos os campos obrigatórios e realize as consultas!',
                        });
                      }
                    }}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>
            </SectionMargin>

          </SectionMargin>


        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_CnpjScreen;
