import React from 'react';
import { Row } from 'react-bootstrap';
import { YesNoArray2 } from '../../../../../../../../common/arrays/YesNo';
import { TitleGroup } from '../../../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../../components/TextFields/Input';
import { ColItems_B, ColMeets_B, ColObsColab_B, ColImproviment_B, AreaFieldsSeparate, ColDescr_B, ColLeader_B } from '../../../styled';

interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}

const POCSectionB: React.FC<IProps> = (props) => {
  const { handleBlur, handleChange, values, setFieldValue } = props;

  return (
    <AreaFieldsSeparate>

      <Row>
        <TitleGroup>
          {'B - Pessoas'.toUpperCase()}
        </TitleGroup>

        <Row>
          <ColItems_B>Itens</ColItems_B>
          <ColLeader_B><span>Líder</span></ColLeader_B>
          <ColDescr_B><span>Descrição</span></ColDescr_B>
          <ColMeets_B><span>Atende</span></ColMeets_B>
          <ColObsColab_B><span>Colab. Observ.</span></ColObsColab_B>
          <ColImproviment_B><span>Melhorias</span></ColImproviment_B>
        </Row>
      </Row>


      {/* B1 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B1'}
            label={'B1'}
            onBlur={handleBlur}
            value={'Colaborador (es) possuem os CONHECIMENTOS MINIMOS  para realizar esta atividade?'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B1_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B1_NCOLABORADOR', '');
                setFieldValue('B1_NMELHORIA', '');
                setFieldValue('B1_IDEQUIPE', '');
                setFieldValue('B1_LIDER', '');
                setFieldValue('B1_DESCR', '');
              }
              else {
                setFieldValue('B1_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B1_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B1_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B1_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B1_ATENDE === ''}
            type={'number'}
            name={'B1_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B1_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B1_ATENDE === ''} 
            type={'number'}
            name={'B1_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B1_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B2 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B2'}
            label={'B2'}
            onBlur={handleBlur}
            value={'Colaborador (es) conhecem os Perigos e Riscos, associados a atividade? Estimulem através de perguntas!'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B2_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B2_NCOLABORADOR', '');
                setFieldValue('B2_NMELHORIA', '');
                setFieldValue('B2_IDEQUIPE', '');
                setFieldValue('B2_LIDER', '');
                setFieldValue('B2_DESCR', '');
              }
              else {
                setFieldValue('B2_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B2_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B2_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B2_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B2_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B2_ATENDE === ''}
            type={'number'}
            name={'B2_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B2_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B2_ATENDE === ''} 
            type={'number'}
            name={'B2_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B2_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B3 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B3'}
            label={'B3'}
            onBlur={handleBlur}
            value={'Colaborador (es)  apresentam sinais de ESTRESSE FISICO OU EMOCIONAL, para realizar a tarefa? (Quando a pressão de trabalho ultrapassa o limite individual dos colaboradores)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B3_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B3_NCOLABORADOR', '');
                setFieldValue('B3_NMELHORIA', '');
                setFieldValue('B3_IDEQUIPE', '');
                setFieldValue('B3_LIDER', '');
                setFieldValue('B3_DESCR', '');
              }
              else {
                setFieldValue('B3_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B3_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B3_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B3_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B3_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B3_ATENDE === ''}
            type={'number'}
            name={'B3_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B3_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B3_ATENDE === ''} 
            type={'number'}
            name={'B3_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B3_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B4 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B4'}
            label={'B4'}
            onBlur={handleBlur}
            value={'Para execução da tarefa é necessário liberar uma GRANDE CARGA DE TRABALHO? (Tentar fazer mais de uma tarefa ao mesmo tempo com pensamento consciente)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B4_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B4_NCOLABORADOR', '');
                setFieldValue('B4_NMELHORIA', '');
                setFieldValue('B4_IDEQUIPE', '');
                setFieldValue('B4_LIDER', '');
                setFieldValue('B4_DESCR', '');
              }
              else {
                setFieldValue('B4_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B4_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B4_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B4_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B4_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B4_ATENDE === ''}
            type={'number'}
            name={'B4_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B4_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B4_ATENDE === ''} 
            type={'number'}
            name={'B4_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B4_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B5 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B5'}
            label={'B5'}
            onBlur={handleBlur}
            value={'A tarefa está sendo executada com PRESSÃO DE TEMPO ? (Ter uma agenda apertada para entrega da atividade/Tarefa)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B5_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B5_NCOLABORADOR', '');
                setFieldValue('B5_NMELHORIA', '');
                setFieldValue('B5_IDEQUIPE', '');
                setFieldValue('B5_LIDER', '');
                setFieldValue('B5_DESCR', '');
              }
              else {
                setFieldValue('B5_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B5_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B5_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B5_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B5_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B5_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B5_ATENDE === ''}
            type={'number'}
            name={'B5_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B5_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B5_ATENDE === ''} 
            type={'number'}
            name={'B5_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B5_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B6 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B6'}
            label={'B6'}
            onBlur={handleBlur}
            value={'A tarefa foi compreendida pelo (s) colaborador (es) ou HOUVE COMUNICAÇÃO FALHA? (A informação passada não foi clara, ou não foi completa)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B6_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B6_NCOLABORADOR', '');
                setFieldValue('B6_NMELHORIA', '');
                setFieldValue('B6_IDEQUIPE', '');
                setFieldValue('B6_LIDER', '');
                setFieldValue('B6_DESCR', '');
              }
              else {
                setFieldValue('B6_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B6_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B6_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B6_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B6_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B6_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B6_ATENDE === ''}
            type={'number'}
            name={'B6_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B6_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B6_ATENDE === ''} 
            type={'number'}
            name={'B6_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B6_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B7 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B7'}
            label={'B7'}
            onBlur={handleBlur}
            value={'A tarefa esta sendo executada com EXCESSO DE CONFIANÇA? (Tendência a não querer melhorar e de que não precisar seguir procedimentos, pois, acredita que sabe tudo)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B7_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B7_NCOLABORADOR', '');
                setFieldValue('B7_NMELHORIA', '');
                setFieldValue('B7_IDEQUIPE', '');
                setFieldValue('B7_LIDER', '');
                setFieldValue('B7_DESCR', '');
              }
              else {
                setFieldValue('B7_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B7_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B7_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B7_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B7_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B7_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B7_ATENDE === ''}
            type={'number'}
            name={'B7_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B7_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B7_ATENDE === ''} 
            type={'number'}
            name={'B7_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B7_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B8 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B8'}
            label={'B8'}
            onBlur={handleBlur}
            value={'A tarefa está sendo executada com DISTRAÇÕES? (Algo está tirando a sua atenção, ou dos colegas, para realização da tarefa)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B8_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B8_NCOLABORADOR', '');
                setFieldValue('B8_NMELHORIA', '');
                setFieldValue('B8_IDEQUIPE', '');
                setFieldValue('B8_LIDER', '');
                setFieldValue('B8_DESCR', '');
              }
              else {
                setFieldValue('B8_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B8_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B8_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B8_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B8_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B8_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B8_ATENDE === ''}
            type={'number'}
            name={'B8_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B8_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B8_ATENDE === ''} 
            type={'number'}
            name={'B8_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B8_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>


      {/* B9 */}
      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B9'}
            label={'B9'}
            onBlur={handleBlur}
            value={'A tarefa esta sendo executada ao FINAL DE UM TURNO OU CICLO DE TRABALHO? (Querer terminar logo a tarefa, cansaço, concentração reduzida)'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B9_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('B9_NCOLABORADOR', '');
                setFieldValue('B9_NMELHORIA', '');
                setFieldValue('B9_IDEQUIPE', '');
                setFieldValue('B9_LIDER', '');
                setFieldValue('B9_DESCR', '');
              }
              else {
                setFieldValue('B9_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('B9_LIDER', e.target.value.split(',')[1]);
                setFieldValue('B9_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B9_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.B9_DESCR}
          />
        </ColDescr_B>

        
        <ColMeets_B>
          <DropdownInput
            name={'B9_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_B>


        <ColObsColab_B>
          <TextInput
            disabled={values.B9_ATENDE === ''}
            type={'number'}
            name={'B9_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B9_NCOLABORADORES}
          />
        </ColObsColab_B>


        <ColImproviment_B>
          <TextInput
            disabled={values.B9_ATENDE === ''} 
            type={'number'}
            name={'B9_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.B9_NMELHORIAS}
          />
        </ColImproviment_B>

      </Row>

    </AreaFieldsSeparate>
  );
};


export default POCSectionB;
