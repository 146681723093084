import React from 'react';

import {
  Spinner,
} from 'react-bootstrap';

import {
  ButtonStyled,
  ButtonIconView,
  ButtonText,
  ButtonLoadingView,
  ButtonLoadingText,
} from './styled';



interface IProps {
  loading?: boolean;
  disabled?: boolean;

  type?: string;
  backgroundColor?: string;
  text?: string;
  iconLeft?: any;
  iconRigth?: any;
  onClick?: any;
}



const IButton: React.FC<IProps> = (props) => {
  return (

    <ButtonStyled
      loading={props.loading}
      disabled={props.disabled || props.loading}
      type={props.type || 'button'}
      backgroundColor={props.backgroundColor}
      onClick={props.onClick}>

      {!props.loading && props.iconLeft && (
        <ButtonIconView>
          {props.iconLeft}
        </ButtonIconView>
      )}


      {!props.loading && (
        <ButtonText>
          {props.text}
        </ButtonText>
      )}

      {props.iconRigth && (
        <ButtonIconView>
          {props.iconRigth}
        </ButtonIconView>
      )}


      {props.loading && (
        <ButtonLoadingView>
          <Spinner
            as={'span'}
            animation={'border'}
            size={'sm'}
            role={'status'}
            aria-hidden={'true'}
          />

          <ButtonLoadingText>
            Carregando ...
          </ButtonLoadingText>
        </ButtonLoadingView>
      )}

    </ButtonStyled>

  );
};



IButton.defaultProps = {
  type: 'button',
};



export default IButton;
