import React, { useState, useEffect } from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import { TitleGroup } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconAddTag, IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { currentDateToInput, formataData } from '../../../../../../../utils/DateUtils';
import OccupationChangeModal from './004OccupationChangeModal';



interface IProps {
  idUser: string;
  type?: any;
}



const UserOccupationSection: React.FC<IProps> = ({ idUser, type }) => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [valuesModal, setValuesModal] = useState({});

  const emptyObject = {
    image: Images.empty,
    title: 'Sem Funções!',
    description: 'Usuário não tem funções de aprovador.',
  };

  const [listCCDrop, setListCCDrop] = useState([]);
  const [listOccupationDrop, setListOccupationDrop] = useState([]);

  const [listOccupationGrid, setListOccupationGrid] = useState([]);


  function postFetchOccupationDrop(values) {
    setLoading(true);
    setListCCDrop([]);

    const url = 'gi/funcao/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((item, index) => {
            setListOccupationDrop((list) => [...list, {
              key: index,
              label: item.DESCR,
              value: item.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchOccupationDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postFetchCCDrop(values) {
    setLoading(true);
    setListCCDrop([]);

    const url = 'gi/centrocusto/empresa/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((item, index) => {
            setListCCDrop((list) => [...list, {
              key: index,
              label: `${item.DESCEMP.substring(0, 3)} - ${item.CC} - ${item.DESCR}`,
              value: item.IDCCEMP,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCCDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchOccupationGrid(values) {
    setLoading(true);
    setShowList(false);

    const url = 'gi/usuario/funcao/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListOccupationGrid(res.data);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postAddOccupation_x_User(values) {
    setLoading(true);

    values = { ...values, IDUSUARIO: idUser };

    const url = 'gi/usuario/funcao/adicionar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          postFetchOccupationGrid({ IDUSUARIO: idUser });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemoveOccupation_x_User(values) {
    setLoading(true);

    values = { ...values, IDUSUARIO: idUser };

    const url = 'gi/usuario/funcao/remover';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          postFetchOccupationGrid({ IDUSUARIO: idUser });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };



  useEffect(() => {
    postFetchOccupationDrop({ DESCR: '' });
  }, []);

  useEffect(() => {
    postFetchCCDrop({ IDCC: '' });
  }, []);

  useEffect(() => {
    postFetchOccupationGrid({ IDUSUARIO: idUser });
  }, []);



  const options = {
    custom: true,
    totalSize: listOccupationGrid.length,
  };

  const columns = [
    {
      dataField: 'IDFUNCAO',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'FUNCAO',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DESCEMP',
      text: 'Empresa',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (_cell, row) => {
        return (row?.DESCR && row?.CC) && `${row.CC} - ${row.DESCR}`;
      },
    },
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: '',
      text: loading || type !== 'REMOVE' && 'Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {loading || type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.EDIT}
                icon={
                  <IconEdit color={Colors?.black} size={16} />
                }
                onClick={() => {
                  setShowModal(true);
                  setValuesModal(row);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      dataField: '',
      text: loading || type === 'REMOVE' && 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {loading || type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.REMOVE}
                icon={
                  <IconTrash color={Colors?.black} size={16} />
                }
                onClick={() => {
                  postRemoveOccupation_x_User(row);
                }}
              />
            )}
          </>
        );
      },
    },
  ];


  const columnsConsult = [
    {
      dataField: 'IDFUNCAO',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'FUNCAO',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DESCEMP',
      text: 'Empresa',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (_cell, row) => {
        return (row?.DESCR && row?.CC) && `${row.CC} - ${row.DESCR}`;
      },
    },
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
      headerStyle: () => {
        return { width: '15%' };
      },
    },
  ];


  return (
    <>

      <TitleGroup>
        {'Funções'.toUpperCase()}
      </TitleGroup>


      {loading && (
        <LoadingsComponent />
      )}

      {type !== 'CONSULT' && (

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            IDUSUARIO: idUser || '',
            IDFUNCAO: '',
            IDCCEMP: '',
            DTINI: currentDateToInput(),
            DTFIM: null,
          }}
          validationSchema={
            Yup.object().shape({
              IDUSUARIO: Yup.string().required('Obrigatório!'),
              IDFUNCAO: Yup.string().required('Obrigatório!'),
              IDCCEMP: Yup.string().required('Obrigatório!'),
              DTINI: Yup.string().required('Obrigatório!'),
            })
          }
          onSubmit={(values) => {
            postAddOccupation_x_User(values);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              {!loading && (
                <>
                  <Row>

                    <Col xs={2}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDFUNCAO'}
                        label={'Função'}
                        placeholder={'Selecione'}
                        dropArray={listOccupationDrop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IDFUNCAO}
                      />
                    </Col>


                    <Col xs={5}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDCCEMP'}
                        label={'CC'}
                        placeholder={'Selecione'}
                        dropArray={listCCDrop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IDCCEMP}
                      />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTINI'}
                        label={'De'}
                        type={'date'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTINI}
                      />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTFIM'}
                        label={'Até'}
                        type={'date'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTFIM}
                      />
                    </Col>


                    <Col xs={1}>
                      <IButton
                        disabled={loading || type === 'REMOVE'}
                        type={'submit'}
                        backgroundColor={Colors?.white}
                        iconLeft={<IconAddTag size={30} color={Colors?.buttonOk} />}
                      />
                    </Col>

                  </Row>

                </>
              )}

            </Form>

          )}
        </Formik>

      )}


      {!loading && showList && listOccupationGrid.length === 0 && (
        <EmptyContent
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}


      {!loading && showList && listOccupationGrid.length > 0 && (

        <PaginationProvider
          pagination={paginationFactory(options)}>
          {({
            paginationProps,
            paginationTableProps,
          }) => (
            <TableCuston
              noQuantity={listOccupationGrid.length < 10}
              data={listOccupationGrid}
              columns={type === 'CONSULT' ? columnsConsult : columns}
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
            />
          )}
        </PaginationProvider>

      )}



      {showModal && (
        <OccupationChangeModal
          show={showModal}
          values={valuesModal}
          onHide={() => setShowModal(false)}
          fetchNow={() => postFetchOccupationGrid({ IDUSUARIO: idUser })}
          listOccupationDrop={listOccupationDrop}
          listCCDrop={listCCDrop}
        />
      )
      }

    </>

  );
};



export default UserOccupationSection;

