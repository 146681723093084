import styled from 'styled-components';



interface IProps {
  center?: boolean;
  backgroundColor?: string;
}



export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  margin-bottom: 0px;
`;

export const SubtitleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
`;

export const SubtitleSquare = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  margin: ${(props: IProps) => props.center ? '0 auto' : '0 3px 0 -5px'};
  background-color: ${(props: IProps) => props.backgroundColor};
`;

export const SubtitleText = styled.p`
  margin: 0 6px auto 0;
  font-size: 14px;
`;
