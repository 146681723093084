import React, { useState } from 'react';
import { Row, Tab } from 'react-bootstrap';
import { Colors } from '../../../../../../common/constants';

import {
  ContainerView, TabsStyled, TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import { IconCalendar, IconIncomplete, IconMirror } from '../../../../../../components/Icons';
import LoadingsComponent from '../../../../../../components/Loadings';


import Tab_FaturamentoScreen from './PFF_Tabs/001PFF_TabAtualMesRef';
import Tab_ReceitasRecebiveisScreen from './PFF_Tabs/002PFF_TabLancarRealizado';
import Tab_ComparativoScreen from './PFF_Tabs/003PFF_TabComparativo';
import Tab_PendentesScreen from './PFF_Tabs/004PFF_TabPendentes';
import {
  PFFTabIconStyled,
  PFFTabTextStyled,
  PFFTabViewStyled,
} from './PFF-realizado-styled';

interface IProps {
  releaseType?: any;
}

const PFF_RealizadoScreen: React.FC<IProps> = () => {
  const [loading] = useState(false);

  /* FIXME: Descomentar linha abaixo para prod/homolog */
  const [currentTab, setCurrentTab] = useState(0);
  // const [currentTab, setCurrentTab] = useState(1);

  const tabsList = [

    {
      eventKey: 0,
      title: {
        icon: <IconCalendar size={16} />,
        text: 'Atualizar Mês de Ref.',
      },
      screenTab:
        <Tab_FaturamentoScreen />,
    },


    {
      eventKey: 1,
      title: {
        icon: <IconIncomplete size={16} />,
        text: 'Lançar Realizado',
      },
      screenTab:
        <Tab_ReceitasRecebiveisScreen />,
    },


    {
      eventKey: 2,
      title: {
        icon: <IconMirror size={16} />,
        text: 'Comparativo',
      },
      screenTab:
        <Tab_ComparativoScreen />,
    },


    {
      eventKey: 3,
      title: {
        icon: <IconMirror size={16} />,
        text: 'Pendentes',
      },
      screenTab:
        <Tab_PendentesScreen />,
    },

  ];


  return (

    <ContainerView>

      <TitleGroup>
        {'Lançamento de Realizado'.toUpperCase()}
      </TitleGroup>


      {
        loading &&
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.tertiary}
        />
      }


      {
        !loading &&


        <Row style={{ margin: '-1.5em 4em' }}>

          <TabsStyled
            id={'control-tab-pcb'}
            activeKey={currentTab}
            onSelect={(tab) => setCurrentTab(parseInt(tab))}
          >

            {tabsList.map((tab) => (

              <Tab
                eventKey={tab.eventKey}
                title={
                  <PFFTabViewStyled>

                    <PFFTabIconStyled>
                      {tab.title.icon}
                    </PFFTabIconStyled>



                    <PFFTabTextStyled fontSizeHeader={1}>
                      {tab.title.text}
                    </PFFTabTextStyled>

                  </PFFTabViewStyled>
                }>

                {tab.screenTab}


              </Tab>
            ))}

          </TabsStyled>

        </Row>
      }

    </ContainerView >
  );
};



export default PFF_RealizadoScreen;
