import React from 'react'
import { Chart } from 'react-google-charts';
import LoadingsComponent from '../Loadings';


const Grafico = (props) => {
  const { dado } = props;
  return (
    <>
      <Chart
        width={"100%"}
        height={"350px"}
        chartType="PieChart"
        loader={
          <LoadingsComponent/>
        }
        data={dado}
        rootProps={{ 'data-testid': '6' }}
        options={{
          fontSize: 9,
          pieHole: 0.55,
          legend: {
            position: 'block'
          },
        }}
      />
    </>
  )
}

export default Grafico
