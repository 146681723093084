import React, { useState } from 'react';
import { Formik } from 'formik';
import LoadingsComponent from '../../../../../../../components/Loadings';
import { PffFilterTableResume } from '../../PFF-lancamento-styled';
import { TableResume, TrHeader, ThHead, TdBudgeted } from './styled';


interface IProps {
  releaseType?: any;
  loading?: any;
}


const Tab_PendentesScreen: React.FC<IProps> = (props) => {
  const [loading] = useState(false);

  const localStorageFlagPendencia = localStorage.getItem('@pff/LANCAMENTO/FLAG/PENDENCIA');
  const localStorageFlagRealizado = localStorage.getItem('@pff/LANCAMENTO/FLAG/REALIZADO');
  const localStorageData = localStorageFlagRealizado === '1' ? JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA/REALIZADO')).REL : [];


  return (

    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{}}
      onSubmit={() => {
        console.log('');
      }}>
      {({
        handleSubmit,
      }) => (
        <>
          {((props.loading || loading) && localStorageFlagPendencia === '1') && <LoadingsComponent />}


          {((!props.loading || !loading) && localStorageFlagPendencia === '1') &&
            <PffFilterTableResume onSubmit={handleSubmit}>

              <TableResume id={'tabela'} className="table table-bordered table-striped" md>

                <thead>
                  <TrHeader>

                    <ThHead>Centro Custo</ThHead>
                    <ThHead>Descrição</ThHead>

                  </TrHeader>

                </thead>


                <tbody>

                  {localStorageData.map((os) => (
                    <tr>
                      <TdBudgeted>{os.CC}</TdBudgeted>
                      <TdBudgeted>{os.DESCR}</TdBudgeted>
                    </tr>
                  ))}


                </tbody>

              </TableResume>


            </PffFilterTableResume >
          }
        </>
      )
      }
    </Formik >

  );
};



export default Tab_PendentesScreen;

