import React from 'react';

import {
  NavbarStyled,
} from './styled';



interface IProps {
  children: any;
}



const NavHeader: React.FC<IProps> = (props) => {
  return (

    <NavbarStyled expand="lg">

      {props.children}

    </NavbarStyled>

  );
};



export default NavHeader;
