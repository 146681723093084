import React from 'react';

// import { Container } from './styles';



const NoItemsContent: React.FC = () => {
  return (

    <h5>
      Sem Itens
    </h5>

  );
};



export default NoItemsContent;
