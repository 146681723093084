import * as Yup from 'yup';


const FornecedoresValidationSchema = Yup.object().shape({

  SIT: Yup.string(),

  RAZAO: Yup.string(),

  COD: Yup.string(),

  CNPJ: Yup.string(),


});



export default FornecedoresValidationSchema;
