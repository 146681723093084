import React from 'react';
import { Row } from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import { ColDescr_C, ColDetours_C, ColItems_C, ColLeader_C, ColObsPers_C } from './styled';


interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}


const PICSectionC:React.FC<IProps> = (props) => {
  return (

    <div>

      <TitleGroup>
        {'C - Ferramentas e Equipamentos Inadequados'.toUpperCase()}
      </TitleGroup>

      <Row>
        <ColItems_C>Itens</ColItems_C>
        <ColLeader_C><span>Líder</span></ColLeader_C>
        <ColDescr_C><span>Descrição</span></ColDescr_C>
        <ColObsPers_C><span>Pess. Observ.</span></ColObsPers_C>
        <ColDetours_C><span>Desvios</span></ColDetours_C>
      </Row>

      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C1'}
            label={'C1'}       
            onBlur={props.handleBlur}
            value={'Impróprias para o serviço'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C1_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('C1_NPESSOA','');
                props.setFieldValue('C1_NDESVIO','');
              }
              props.setFieldValue('C1_IDEQUIPE', e.target.value.split(',')[0]);             
              props.setFieldValue('C1_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('C1_DESCR', e.target.value.split(',')[2]);             
            }}
            onBlur={props.handleBlur}
            // value={props.values.C1_LIDER}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C1_DESCR}
          />
        </ColDescr_C>
        
        
        <ColObsPers_C>
          <TextInput
            disabled={
              props.values.C1_LIDER === ''
              || props.values.C1_LIDER === undefined
              || props.values.C1_LIDER === null
            }
            type={'number'}
            name={'C1_NPESSOA'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C1_NPESSOA}
          />
        </ColObsPers_C>


        <ColDetours_C>
          <TextInput
            disabled={
              props.values.C1_LIDER === ''
              || props.values.C1_LIDER === undefined
              || props.values.C1_LIDER === null
            }
            type={'number'}
            name={'C1_NDESVIO'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C1_NDESVIO}
          />
        </ColDetours_C>

      </Row>


      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C2'}
            label={'C2'}
            onBlur={props.handleBlur}
            value={'Usados incorretamente'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C2_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('C2_NPESSOA','');
                props.setFieldValue('C2_NDESVIO','');
              }
              props.setFieldValue('C2_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('C2_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('C2_DESCR', e.target.value.split(',')[2]);    
            }}             
            onBlur={props.handleBlur}
            // value={props.values.C2_LIDER}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C2_DESCR}
          />
        </ColDescr_C>
        
        
        <ColObsPers_C>
          <TextInput
            disabled={
              props.values.C2_LIDER === ''
              || props.values.C2_LIDER === undefined
              || props.values.C2_LIDER === null
            }
            type={'number'}
            name={'C2_NPESSOA'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C2_NPESSOA}
          />
        </ColObsPers_C>


        <ColDetours_C>
          <TextInput
            disabled={
              props.values.C2_LIDER === ''
              || props.values.C2_LIDER === undefined
              || props.values.C2_LIDER === null
            }
            type={'number'}
            name={'C2_NDESVIO'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C2_NDESVIO}
          />
        </ColDetours_C>

      </Row>


      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C3'}
            label={'C3'}
            onBlur={props.handleBlur}
            value={'Em condição/local inseguro'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C3_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('C3_NPESSOA','');
                props.setFieldValue('C3_NDESVIO','');
              }
              props.setFieldValue('C3_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('C3_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('C3_DESCR', e.target.value.split(',')[2]);    
            }}             
            onBlur={props.handleBlur}
            // value={props.values.C3_LIDER}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C3_DESCR}
          />
        </ColDescr_C>
        
        
        <ColObsPers_C>
          <TextInput
            disabled={
              props.values.C3_LIDER === ''
              || props.values.C3_LIDER === undefined
              || props.values.C3_LIDER === null
            }
            type={'number'}
            name={'C3_NPESSOA'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C3_NPESSOA}
          />
        </ColObsPers_C>


        <ColDetours_C>
          <TextInput
            disabled={
              props.values.C3_LIDER === ''
              || props.values.C3_LIDER === undefined
              || props.values.C3_LIDER === null
            }
            type={'number'}
            name={'C3_NDESVIO'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C3_NDESVIO}
          />
        </ColDetours_C>

      </Row>

      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C4'}
            label={'C4'}       
            onBlur={props.handleBlur}
            value={'Equipa. com vazam. ou derramam.'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C4_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('C4_NPESSOA','');
                props.setFieldValue('C4_NDESVIO','');
              }
              props.setFieldValue('C4_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('C4_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('C4_DESCR', e.target.value.split(',')[2]);    
            }}             
            onBlur={props.handleBlur}
            // value={props.values.C4_LIDER}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C4_DESCR}
          />
        </ColDescr_C>
        
        
        <ColObsPers_C>
          <TextInput
            disabled={
              props.values.C4_LIDER === ''
              || props.values.C4_LIDER === undefined
              || props.values.C4_LIDER === null
            }
            type={'number'}
            name={'C4_NPESSOA'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C4_NPESSOA}
          />
        </ColObsPers_C>


        <ColDetours_C>
          <TextInput
            disabled={
              props.values.C4_LIDER === ''
              || props.values.C4_LIDER === undefined
              || props.values.C4_LIDER === null
            }
            type={'number'}
            name={'C4_NDESVIO'}
            onChange={props.handleChange}             
            onBlur={props.handleBlur}
            value={props.values.C4_NDESVIO}
          />
        </ColDetours_C>

      </Row>

    </div>

  );
};


export default PICSectionC;
