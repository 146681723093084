import React,
{
  useRef,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import ReactToPrint from 'react-to-print';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
} from '../../../../../../common/constants';

import {
  ContainerView,
  ContentStyled,
  FilterContainerForm,
  SectionMargin,
  TableActionButton,
  TableActionUpButton,
  TableToltip,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../components/Empty';

import {
  IconUser,
  IconUserCheck,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import RelatorioMovimentacoesPrintComponent from '../../../../../../components/Reports/RelatorioMovimentacoes';
import RelatorioNadaConstaPrintComponent from '../../../../../../components/Reports/RelatorioNadaConsta';
import TableCuston from '../../../../../../components/Table';
import TextInput from '../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import FuncionarioPstockModel from './funcionario.model';
import FuncionarioPstockValidationSchema from './funcionario.validation';

import {
  RowButtonView,
} from './styled';



const TabFuncionarios: React.FC = () => {
  const compRef = useRef([]);

  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [listEmployee, setListEmployee] = useState([]);
  const [listLoading, setListLoading] = useState(false);



  async function getEmployeeData(values: typeof FuncionarioPstockModel) {
    const payload = {
      'TIPOREL': 'FUNCIONARIO',
      'ID': values.input_funcionario,
    };

    try {
      setListLoading(true);
      setShowList(false);

      const url = '/pstock/relatorio/consulta';
      const response = await ApiWS().post(url, payload);

      if (response) {
        const arrayTreated = [
          {
            DATA: response.data[0]?.DATA || null,
            MOVIMENTACAO: response.data[1]?.MOVIMENTACAO || [],
            NADACONSTA: response.data[2]?.NADACONSTA || [],
          },
        ];
        console.log('arrayTreated', arrayTreated);
        setListEmployee(arrayTreated);
      }
    }
    catch (error) {
      console.error('getDepositsData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);



  const columns = [
    {
      dataField: 'NOMEFUNCIONARIO',
      text: 'Nome do funcionário',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.DATA?.NOME || '-';
      },
    },
    {
      dataField: 'MATRICULA',
      text: 'Matrícula',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.DATA?.MATRICULA || '-';
      },
    },
    {
      dataField: 'NADACONSTA',
      text: 'Nada consta',
      sort: false,
      formatter: (_cell: any, row: any, rowIndex: number) => {
        return (
          <>
            <ReactToPrint
              content={() => (compRef as any).current[`A${rowIndex}`]}
              trigger={() => (
                <TableActionUpButton>
                  <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    trigger={['hover', 'hover']}
                    overlay={
                      <TableToltip>
                        Nada consta
                      </TableToltip>
                    }>
                    <TableActionButton
                      backgroundColor={Colors?.primary}>
                      <IconUserCheck
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </OverlayTrigger>
                </TableActionUpButton>
              )}
            />

            <RelatorioNadaConstaPrintComponent
              ref={(element) => (compRef.current[`A${rowIndex}`] = element)}
              data={row?.DATA}
              list={row?.NADACONSTA}
            />
          </>
        );
      },
    },
    {
      dataField: 'MOVIMENTACAO',
      text: 'Entradas e saídas',
      sort: false,
      formatter: (_cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <ReactToPrint
              content={() => (compRef as any).current[`B${rowIndex}`]}
              trigger={() => (
                <TableActionUpButton>
                  <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    trigger={['hover', 'hover']}
                    overlay={
                      <TableToltip>
                        Entradas e saídas
                      </TableToltip>
                    }>
                    <TableActionButton
                      backgroundColor={Colors?.primary}>
                      <IconUser
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </OverlayTrigger>
                </TableActionUpButton>
              )}
            />

            <RelatorioMovimentacoesPrintComponent
              ref={(element) => (compRef.current[`B${rowIndex}`] = element)}
              data={row?.DATA}
              list={row?.MOVIMENTACAO}
            />
          </>
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listEmployee.length,
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={FuncionarioPstockValidationSchema}
      initialValues={FuncionarioPstockModel}
      onSubmit={(values) => {
        getEmployeeData(values);
      }}>
      {({
        values,
        resetForm,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
      }) => (

        <ContentStyled fluid>


          <TitleGroup>
            {'Funcionários'.toUpperCase()}
          </TitleGroup>


          <SectionMargin>

            <FilterContainerForm auto onSubmit={handleSubmit}>

              <Row>
                <Col>
                  <TextInput
                    disabled={loading || listLoading}
                    type={'text'}
                    name={'input_funcionario'}
                    label={'Funcionário'}
                    placeholder={'Nome, CPF, ou matrícula'}
                    value={values.input_funcionario || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>



                <Col>
                  <RowButtonView>

                    <IButton
                      disabled={loading || listLoading}
                      text={'Limpar'}
                      backgroundColor={Colors?.gray}
                      type={'reset'}
                      onClick={() => {
                        resetForm();

                        setValues(FuncionarioPstockModel);
                      }}
                    />


                    <IButton
                      disabled={!isValid || loading || listLoading}
                      text={'Filtrar'}
                      backgroundColor={Colors?.primary}
                      type={'submit'}
                    />

                  </RowButtonView>
                </Col>
              </Row>

            </FilterContainerForm>

          </SectionMargin>



          <ContainerView>

            {listLoading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}



            {!loading && !listLoading && showList && listEmployee.length === 0 && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}



            {!loading && !listLoading && showList && listEmployee.length > 0 && (
              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={listEmployee.length < 10}
                    data={listEmployee}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            )}

          </ContainerView>

        </ContentStyled>

      )}
    </Formik>

  );
};



export default TabFuncionarios;
