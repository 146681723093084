import { Row } from 'react-bootstrap';
import styled from 'styled-components';



export const ButtonCopySMEContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;


export const ButtonFinishSMEContainer = styled(ButtonCopySMEContainer)`
  margin-top: 30px;
`;

export const GenerateButtonsSMEView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
`;

export const AreaManutencaoFrotaStyle = styled(Row)`
  margin: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: gray -5px 5px 10px;
`;
