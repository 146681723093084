import * as Yup from 'yup';



const GIFuncaoValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),

});



export default GIFuncaoValidationSchema;
