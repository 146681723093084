import * as Yup from 'yup';



const GIDepartamentoValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),

});



export default GIDepartamentoValidationSchema;
