import React,
{
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';



import {
  Content,
  ContentStyled,
  Screen,
  TabIconStyled,
  TabsStyled,
  TabTextStyled,
  TabViewStyled,
} from '../../../../../common/styles/styled.layout';


import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';


import { IconMaintenanceAttached, IconSendAttached } from '../../../../../components/Icons';



import {
  PBC_NavRoutes,
} from '../../../../../navigation/navs/Supplies';



import Tab_AttachedSendScreen from './Tabs/tab_Anexar';
import Tab_AttachedSettingsScreen from './Tabs/tab_Manutencao';



const PBC_AnexoScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState('tab_send');


  const tabsList = [ // Para nova TAB, basta preencher o Array
    {
      eventKey: 'tab_send', // Tela no momento, altera no onClick, pelo estado
      title: {
        icon: <IconSendAttached size={22} />, // Ícone da TAB
        text: 'Anexar', // Texto da TAB
      },
      screenTab: <Tab_AttachedSendScreen />, // Conteúdo da TAB
    },

    {
      eventKey: 'tab_maintenance',
      title: {
        icon: <IconMaintenanceAttached />,
        text: 'Manutenção',
      },
      screenTab: <Tab_AttachedSettingsScreen />,
    },
  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={4}
          navArray={PBC_NavRoutes}
          title={'Suprimentos'}
        />
      </NavHeader>


      <Content>
        <ContentStyled fluid>

          <TabsStyled
            id={'control-tab-pcb'}
            activeKey={currentTab}
            onSelect={(tab) => setCurrentTab(tab)}
          >

            {tabsList.map((tab) => (

              <Tab
                eventKey={tab.eventKey}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      {tab.title.icon}
                    </TabIconStyled>

                    <TabTextStyled>
                      {tab.title.text}
                    </TabTextStyled>
                  </TabViewStyled>
                }>

                {tab.screenTab}

              </Tab>

            ))}

          </TabsStyled>

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default PBC_AnexoScreen;
