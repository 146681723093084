import styled from 'styled-components';

import {
  Screen,
} from '../../../common/styles/styled.layout';



interface IProps {
  marginLeftInfo?: number;
  width?: number;
  margin?: number;
}


export const PrinterContainer = styled(Screen)`
    overflow: visible !important;
`;

export const A4LandscapeContent = styled.div`
  display: grid;
  flex-direction: column;
  margin: auto 2px;
  
  height: 29.7cm !important;
  min-height: 29.7cm !important;
  max-height: 29.7cm !important;
  width: 21cm !important;
  min-width: 21cm !important;
  max-width: 21cm !important;
`;

export const HeaderSpace = styled.div`
  height: 4.5cm;
  min-height: 4.5cm;
  max-height: 4.5cm;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 0.5px solid #CDCDD0 !important;
`;

export const BodySpace = styled.div`
  border-top: solid 0.5px #9fa6ac;
  border-bottom: solid 0.5px #9fa6ac;
  margin-top: 7px;
  
  height: 17cm;
  min-height: 17cm;
  max-height: 17cm;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

export const FooterSpace = styled.div`
  height: 10cm;
  min-height: 7cm;
  max-height: 7cm;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

export const TitleAfterBody = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: bold;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const TitleGeneralContainer = styled.th`
  text-align: left;
  padding-top: 5px !important;
  padding-left: 20px !important;
  
  height: auto;
  min-height: auto;
  max-height: auto;
  width: ${(props: IProps) => `${props.width}px` || 'auto'};
  min-width: ${(props: IProps) => `${props.width}px` || 'auto'};
  max-width: ${(props: IProps) => `${props.width}px` || 'auto'};
`;


export const TitleGeneral = styled.span`
  font-size: 12px;
  font-weight: bold;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const TitleHeader = styled.th`
  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const TitleCenter = styled.th`
  font-size: 10px;
  text-align: center;
  vertical-align: bottom !important;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const Cell = styled.td`
  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;

`;

export const CellLogo = styled.td`
  text-align: center;
  vertical-align: middle;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;


export const ImgLogo = styled.img`
  margin-left: 5px;
  max-height: 130px;
  max-width: 130px;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const RowInfo = styled.div`
  font-size: 10px;
  font-weight: normal !important; 
  margin-top: ${(props: IProps) => `${props.margin}px` || 'auto'} !important;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const ColInfo = styled.span`
  margin-left: ${(props: IProps) => `${props.marginLeftInfo}px` || 'auto'};

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const CellCenter = styled.td`
  text-align: center;
  font-size: 10px;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const CellCenterBold = styled.td`
  text-align: center;
  font-weight: bold;

  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const Row = styled.tr`
  height: auto;
  min-height: auto;
  max-height: auto;
  width: auto;
  min-width: auto;
  max-width: auto;
`;

export const ContentOthers = styled.div`
  font-size: 12px !important;
  margin-left: 5px;
  padding: 10px;
  text-align: justify;
  
  height: auto;
  min-height: auto;
  max-height: auto;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

export const ContentNotes = styled.div`
  font-size: 12px !important;
  margin-left: 5px;
  padding: 10px;
  text-align: justify;
  
  height: auto;
  min-height: auto;
  max-height: auto;
  width: 100%;
  min-width: 100%100ch;
  max-width: 100%;
`;

export const ContentObsv = styled.div`
  font-size: 12px !important;
  margin-left: 5px;
  padding: 10px;
  text-align: justify;
  
  height: auto;
  min-height: auto;
  max-height: auto;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;
