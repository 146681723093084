const PffLancamentoInicioModel = {
  /* FIXME: Descomentar todos vazios e comentar preenchidos para subir homolog/prod */
  ANO: '2023',
  NEGOCIO: '',
  // NEGOCIO: '30',
  CC: '',
  // CC: 'MKS0116',
  LANCAMENTO: '',
  // LANCAMENTO: 'REALIZADO',
  COD_LANCAMENTO: '',
  // COD_LANCAMENTO: 'RE',
};



export default PffLancamentoInicioModel;
