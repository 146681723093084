import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';

import {
  Colors, Images, Metrics,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';


import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';


import ApiWS from '../../../../../../../services/api.service';

import PcbManutencaoHistoricoModel from './PBC-manutencao-tab-historico.model';
import PcbManutencaoHistoricoValidationSchema from './PBC-manutencao-tab-historico.validation';



const Tab_HistoryScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [listShow, setListShow] = useState(false);

  const [listHistory, setListHistory] = useState([]);

  const [emptyObject] = useState({
    image: Images.empty,
    title: 'Sem Histórico!',
    description: 'Não há nenhum histórico aqui',
  });


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  async function fetchHistory(values: any, page_iteration: number) {
    setLoading(true);

    values = {
      ...values,
      'PAGINA:': page_iteration,
      'TAMANHO:': Metrics.size_supplier,
    };

    setValuesPayload(values);

    ApiWS()
      .post('/suprimento/manutencao/consulta/historico', values)
      .then((resp) => {
        if (resp.status === 200 && resp.data.length > 0) {
          setListHistory(resp.data[0]);
          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));

          setListShow(true);
        }
        else {
          setListShow(false);
        }
      })
      .catch((error) => {
        IToast({
          message: 'Ocorreu um erro ao consultar pedido! Contate o suporte.',
          type: 'error',
        });
        console.error('fetchRequest', JSON.stringify(error.response, null, 2));
        setListShow(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    fetchHistory(valuesPayload, page_var);
    setPage(page_var);
  };

  const columns = [
    {
      dataField: 'USUARIO',
      text: 'Usuário',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Realizou',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'PEDIDO',
      text: 'PC/BM',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'CONTRATO',
      text: 'Contrato',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DATA1',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'OBS',
      text: 'Detalhes',
      sort: true,
    },
  ];


  const options = {
    custom: true,
    totalSize: listHistory.length,
  };


  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PcbManutencaoHistoricoValidationSchema}
      initialValues={PcbManutencaoHistoricoModel}
      onSubmit={(values) => {
        fetchHistory(values, 1);
      }}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Alterações realizadas'.toUpperCase()}
            </TitleGroup>


            {loading &&
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            }


            <SectionMargin>
              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>


                  <Col>
                    <DropdownInput
                      disabled={loading}
                      name={'TIPO'}
                      label={'Tipo'}
                      placeholder={'Selecione'}
                      dropArray={[
                        {
                          key: '1',
                          label: 'Pedido/BM',
                          value: 'PEDIDO',
                        },
                        {
                          key: '2',
                          label: 'Contrato',
                          value: 'CONTRATO',
                        },
                      ]}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('NUM', '');
                        setFieldValue('USER', '');
                      }}
                      onBlur={handleBlur}
                      value={values.TIPO || ''}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'NUM'}
                      label={'Número'}
                      placeholder={values.TIPO === '' ? 'Número' : values.TIPO === 'PEDIDO' ? 'Pedido / BM' : 'Contrato'}
                      value={values.NUM}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'USUARIO'}
                      label={'Usuário'}
                      placeholder={'Nome do Usuário'}
                      min={3}
                      value={values.USUARIO}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>


                <RowButtonFiltersView>
                  <IButton
                    disabled={loading}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                  />


                  <IButton
                    disabled={loading}
                    text={'Consultar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>
            </SectionMargin>

          </SectionMargin>

          {!loading && listShow && listHistory.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!loading && listShow && listHistory.length > 0 && (
            <PaginationProvider
              pagination={paginationFactory(options)}>
              {({
                paginationProps,
                paginationTableProps,
              }) => (
                <TableCuston
                  data={listHistory}
                  columns={columns}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                  actionPrevious={() => handlePagination(0)}
                  actionNext={() => handlePagination(1)}
                  currentPage={page}
                  totalPage={totalPage}
                  totalResults={totalResults}
                />
              )}
            </PaginationProvider>
          )}

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_HistoryScreen;
