const POCCartaoModel = {
  DATA: '',
  CC:'',
  IDAREA:'',
  TAREFA:'',
  OBSERVADOR:'',
  MAT:'',

  SUCESSOS:[ '', '', '', '', ''],
  DESVIOS: '',
  REC: [{ MAT: '', FUNC: '', RECOM: '' }],
  OBS: '',


  A1_ATENDE: '',
  A1_LIDER: '',
  A1_DESCR: '',
  A1_NCOLABORADORES: '',
  A1_NMELHORIAS: '',
  A2_ATENDE: '',
  A2_LIDER: '',
  A2_DESCR: '',
  A2_NCOLABORADORES: '',
  A2_NMELHORIAS: '',
  A3_ATENDE: '',
  A3_LIDER: '',
  A3_DESCR: '',
  A3_NCOLABORADORES: '',
  A3_NMELHORIAS: '',

  B1_ATENDE: '',
  B1_LIDER: '',
  B1_DESCR: '',
  B1_NCOLABORADORES: '',
  B1_NMELHORIAS: '',
  B2_ATENDE: '',
  B2_LIDER: '',
  B2_DESCR: '',
  B2_NCOLABORADORES: '',
  B2_NMELHORIAS: '',
  B3_ATENDE: '',
  B3_LIDER: '',
  B3_DESCR: '',
  B3_NCOLABORADORES: '',
  B3_NMELHORIAS: '',
  B4_ATENDE: '',
  B4_LIDER: '',
  B4_DESCR: '',
  B4_NCOLABORADORES: '',
  B4_NMELHORIAS: '',
  B5_ATENDE: '',
  B5_LIDER: '',
  B5_DESCR: '',
  B5_NCOLABORADORES: '',
  B5_NMELHORIAS: '',
  B6_ATENDE: '',
  B6_LIDER: '',
  B6_DESCR: '',
  B6_NCOLABORADORES: '',
  B6_NMELHORIAS: '',
  B7_ATENDE: '',
  B7_LIDER: '',
  B7_DESCR: '',
  B7_NCOLABORADORES: '',
  B7_NMELHORIAS: '',
  B8_ATENDE: '',
  B8_LIDER: '',
  B8_DESCR: '',
  B8_NCOLABORADORES: '',
  B8_NMELHORIAS: '',
  B9_ATENDE: '',
  B9_LIDER: '',
  B9_DESCR: '',
  B9_NCOLABORADORES: '',
  B9_NMELHORIAS: '',
  
  C1_ATENDE: '',
  C1_LIDER: '',
  C1_DESCR: '',
  C1_NCOLABORADORES: '',
  C1_NMELHORIAS: '',
  C2_ATENDE: '',
  C2_LIDER: '',
  C2_DESCR: '',
  C2_NCOLABORADORES: '',
  C2_NMELHORIAS: '',
  C3_ATENDE: '',
  C3_LIDER: '',
  C3_DESCR: '',
  C3_NCOLABORADORES: '',
  C3_NMELHORIAS: '',
  C4_ATENDE: '',
  C4_LIDER: '',
  C4_DESCR: '',
  C4_NCOLABORADORES: '',
  C4_NMELHORIAS: '',
  
  D1_ATENDE: '',
  D1_LIDER: '',
  D1_DESCR: '',
  D1_NCOLABORADORES: '',
  D1_NMELHORIAS: '',
  D2_ATENDE: '',
  D2_LIDER: '',
  D2_DESCR: '',
  D2_NCOLABORADORES: '',
  D2_NMELHORIAS: '',
  D3_ATENDE: '',
  D3_LIDER: '',
  D3_DESCR: '',
  D3_NCOLABORADORES: '',
  D3_NMELHORIAS: '',
  
  E1_ATENDE: '',
  E1_LIDER: '',
  E1_DESCR: '',
  E1_NCOLABORADORES: '',
  E1_NMELHORIAS: '',
  E2_ATENDE: '',
  E2_LIDER: '',
  E2_DESCR: '',
  E2_NCOLABORADORES: '',
  E2_NMELHORIAS: '',
  E3_ATENDE: '',
  E3_LIDER: '',
  E3_DESCR: '',
  E3_NCOLABORADORES: '',
  E3_NMELHORIAS: '',
  E4_ATENDE: '',
  E4_LIDER: '',
  E4_DESCR: '',
  E4_NCOLABORADORES: '',
  E4_NMELHORIAS: '',

};



export default POCCartaoModel;
