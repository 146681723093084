import React, { useState, useEffect } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Colors, Images } from '../../../../../../common/constants';
import { ContentStyled, FilterContainerForm } from '../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../components/Buttons/IButton';
import { IconNext, IconReturn } from '../../../../../../components/Icons';
import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import ApiWS from '../../../../../../services/api.service';
import CompetencyModal from '../../Modals/CompetenciaModal';
import { RowStyled, FooterRowButton } from '../PAV-Reconhecimento.styled';
import { ImgStyled, ImgStyledSelected, TextHelp, TitleGroupPAV } from './styled';



interface IProps {
  values?: any;
  handleSubmit?: any;
  handleBlur?: any;
  handleChange?: any;
  setFieldValue?: any;
  setCurrentTab?: any;
  competencySelected?: any;
  setCompetencySelected?: any;
}


const Tab_Competencias: React.FC<IProps> = ({ values, setFieldValue, setCurrentTab, competencySelected, setCompetencySelected }) => {
  const [loading, setLoading] = useState(false);
  const [listCompetency, setListCompetency] = useState([]);

  const [showModalCompetency, setShowModalCompetency] = useState(false);
  const [valuesModalCompetency, setValuesModalCompetency] = useState({});


  function postFetchCompetency() {
    setLoading(true);

    const url = '/pav/reconhecimento/pilar';
    const payload = [{}];

    ApiWS()
      .post(url, payload)
      .then((res) => {
        res.data[0] = { ...res.data[0], img: Images.protagonismoImg };
        res.data[1] = { ...res.data[1], img: Images.relacionamentoImg };
        res.data[2] = { ...res.data[2], img: Images.liderancaImg };
        res.data[3] = { ...res.data[3], img: Images.focoImg };
        res.data[4] = { ...res.data[4], img: Images.organizacaoImg };
        setListCompetency(res.data);
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte.',
        });
        console.error('postFetchCompetency', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  useEffect(() => {
    postFetchCompetency();
  }, []);



  return (
    <>
      <FilterContainerForm auto >

        <ContentStyled fluid>

          <Container>

            <TitleGroupPAV>
              {'Selecione a Competência'.toUpperCase()}
            </TitleGroupPAV>



            {loading && (
              <LoadingsComponent />
            )}



            {!loading && listCompetency.length > 0 && (
              <>

                {(!values.PILAR || values.PILAR === '') && <TextHelp>Obrigatório</TextHelp>}


                <RowStyled>

                  {listCompetency.map((values, index) => (
                    <Col
                      onClick={() => {
                        setShowModalCompetency(true);
                        setValuesModalCompetency(values);
                      }}>


                      {competencySelected === `${index + 3}` && (

                        <ImgStyledSelected
                          alt={values.titulo}
                          src={values.img}
                        />

                      )}



                      {competencySelected !== `${index + 3}` && (

                        <ImgStyled
                          alt={values.titulo}
                          src={values.img}
                        />

                      )}

                    </Col>

                  ))}

                </RowStyled>

              </>
            )}


            <FooterRowButton>
              <Col></Col>

              <Col>
                <IButton
                  type={'button'}
                  backgroundColor={Colors?.gray}
                  text={'Anterior'}
                  iconLeft={
                    <IconReturn />
                  }
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                />
              </Col>


              <Col>
                <IButton
                  disabled={!values.PILAR}
                  type={'button'}
                  backgroundColor={Colors?.primary}
                  text={'Próximo'}
                  iconRigth={
                    <IconNext />
                  }
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                />
              </Col>

            </FooterRowButton>


          </Container>

        </ContentStyled>

      </FilterContainerForm>



      {
        showModalCompetency && (
          <CompetencyModal
            show={showModalCompetency}
            values={valuesModalCompetency}
            data={(result) => {
              setFieldValue('PILAR', result?.PILAR);
              setCompetencySelected(result?.PILAR);
            }}
            onHide={() => setShowModalCompetency(false)}
          />
        )
      }
    </>
  );
};

export default Tab_Competencias;
