import * as Yup from 'yup';


const NovoFornecedoreValidationSchema = Yup.object().shape({

  razao_social: Yup.string().required('Obrigatório'),

  nome: Yup.string().required('Obrigatório'),

  endereco: Yup.string().required('Obrigatório'),

  cep: Yup.string().required('Obrigatório'),

  cidade: Yup.string().required('Obrigatório'),

  uf: Yup.string().required('Obrigatório').length(2, 'Apenas 2 dígitos'),

  cnpj: Yup.string().required('Obrigatório'),

  contato: Yup.string(),

  telefone: Yup.string(),

  email: Yup.string(),

  bco: Yup.string(),

  agencia: Yup.string(),

  conta: Yup.string(),

  tipopix: Yup.string(),

  chavepix: Yup.string(),


});



export default NovoFornecedoreValidationSchema;
