import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';



interface IProps {
  show?: boolean;
  dadosComp?: any;
  onHide?: any;
  isContrato?: any;
}



const ProdutodadosComplementaresModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Dados Complementares'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{}}
          onSubmit={() => null}>
          {() => (

            <Form>

              <ModalBodyStyled>

                <TextInput
                  disabled
                  type={'text'}
                  label={'Condição de Pagamento'}
                  placeholder={'Condição'}
                  value={props.dadosComp?.CONDPAG}
                />


                <TextInput
                  disabled
                  type={'text'}
                  label={'Filial de Entrega'}
                  placeholder={'Filial de Entrega'}
                  value={props.dadosComp?.FILIALENT1}
                />

                <TextInput
                  disabled
                  textHeight={60}
                  type={'text'}
                  label={'Endereço de Entrega'}
                  placeholder={'Endereço'}
                  value={props.dadosComp?.ENDENTR}
                />


                <TextInput
                  disabled
                  type={'text'}
                  label={'Data da Entrega'}
                  placeholder={'Data'}
                  value={props.dadosComp?.DTENTR}
                />


                <TextInput
                  disabled
                  type={'text'}
                  label={'Frete R$'}
                  placeholder={'Frete'}
                  value={props.dadosComp?.FRETE}
                />


                <TextInput
                  disabled
                  type={'text'}
                  label={'Cl. Orc.'}
                  placeholder={'Classificação Orçamentária'}
                  value={props.dadosComp?.CLORC}
                />

                {!props?.isContrato && (
                  <TextInput
                    disabled
                    type={'text'}
                    label={'OP'}
                    placeholder={'OP'}
                    value={props.dadosComp?.OP}
                  />

                )}


                <TextInput
                  disabled
                  type={'text'}
                  label={'Imposto'}
                  placeholder={'Imposto'}
                  value={convertCurrencyBRL(props.dadosComp?.IMP)}
                />


                <TextInput
                  disabled
                  type={'text'}
                  label={'Vlr. com Imposto'}
                  placeholder={'Vlr. com Imposto'}
                  value={convertCurrencyBRL(props.dadosComp?.VALORI)}
                />


                <TextInput
                  disabled
                  type={'text'}
                  textHeight={80}
                  label={'Obs.:'}
                  placeholder={'Sem Observação'}
                  value={props.dadosComp?.OBS}
                />


                <TextInput
                  disabled
                  type={'text'}
                  textHeight={80}
                  label={'Justificativa'}
                  placeholder={'Sem Justificativa'}
                  value={props.dadosComp?.JUST}
                />

              </ModalBodyStyled>



              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Fechar'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default ProdutodadosComplementaresModal;
