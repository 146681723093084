import styled from 'styled-components';

import {
  Navbar,
} from 'react-bootstrap';



export const NavbarStyled = styled(Navbar)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0px;
  box-shadow: 0px 0px 20px 1px rgb(0, 0, 0, 0.75);
  z-index: 20;
`;
