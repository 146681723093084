import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';



const PcbManutencaoHistoricoValidationSchema = Yup.object().shape({

  TIPO: Yup.string()
    .required('Campo Obrigatório'),



  NUM: Yup.string()
    .when('TIPO', {
      is: 'PEDIDO',
      then:
        Yup.string(),
      // .length(Metrics.input_pedido, Messages.input_pedido),
    })
    .when('TIPO', {
      is: 'CONTRATO',
      then:
        Yup.string(),
      // .length(Metrics.input_contrato, Messages.input_contrato),
    })
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  USUARIO: Yup.string(),



});



export default PcbManutencaoHistoricoValidationSchema;
