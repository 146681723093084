import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { YesNoArray2 } from '../../../../../../../common/arrays/YesNo';
import { Colors } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import { ContentStyled, RowButtonFiltersView, TitleGroup } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import { IconListNew, IconSearch } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../../components/Modals/Confirmation';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../../../navigation/name';
import ApiWS from '../../../../../../../services/api.service';
import { currentDateToInput } from '../../../../../../../utils/DateUtils';
import { GenerateButtonsSMEView } from '../../../../../Supplies/SME/SME_Solicitacao/SME_Nova_Copia/SME-solicitacao.styled';
import { AreaFieldsSeparate, ColItemsCase, ColItems_A, ColMeetsCase, ColMeets_A } from '../../styled';
import POCCartaoModel from './POC-Tab_Cartao.model';
import POCCartaoValidationSchema from './POC-Tab_Cartao.validation';
import POCSectionA from './Sections/POCSectionA';
import POCSectionB from './Sections/POCSectionB';
import POCSectionC from './Sections/POCSectionC';
import POCSectionD from './Sections/POCSectionD';
import POCSectionE from './Sections/POCSectionE';


const Tab_CadastroCartaoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [areaLoading, setAreaLoading] = useState(false);


  const [showModalPrinter, setShowModalPriter] = useState(false);

  const [numberCardPOC, setNumberCardPOC] = useState('');

  const [bloksRecognitions, setBloksRecognitions] = useState([]);

  const [listNegocDrop, setListNegocDrop] = useState([]);
  const [listAreaDrop, setListAreaDrop] = useState([]);

  const [registrationLoading, setRegistrationLoading] = useState(false);

  const [listLeaders, setListLeaders] = useState([]);


  function handleToPostPayload(values: any) {
    const cardList = [];
    let payload = { CARTAO: [], SUCESSOS: [], REC: [] };

    if (values) {
      // --------------- A -----------------
      if (values.A1_ATENDE !== ''
        && values.A1_ATENDE !== undefined
        && values.A1_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.A1_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'A1',
          'ATENDE': values.A1_ATENDE,
          'NCOLABORADORES': parseInt(values.A1_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.A1_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.A2_ATENDE !== ''
        && values.A2_ATENDE !== undefined
        && values.A2_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.A2_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'A2',
          'ATENDE': values.A2_ATENDE,
          'NCOLABORADORES': parseInt(values.A2_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.A2_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.A3_ATENDE !== ''
        && values.A3_ATENDE !== undefined
        && values.A3_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.A3_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'A3',
          'ATENDE': values.A3_ATENDE,
          'NCOLABORADORES': parseInt(values.A3_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.A3_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- B -----------------
      if (values.B1_ATENDE !== ''
        && values.B1_ATENDE !== undefined
        && values.B1_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B1_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B1',
          'ATENDE': values.B1_ATENDE,
          'NCOLABORADORES': parseInt(values.B1_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B1_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B2_ATENDE !== ''
        && values.B2_ATENDE !== undefined
        && values.B2_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B2_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B2',
          'ATENDE': values.B2_ATENDE,
          'NCOLABORADORES': parseInt(values.B2_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B2_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B3_ATENDE !== ''
        && values.B3_ATENDE !== undefined
        && values.B3_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B3_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B3',
          'ATENDE': values.B3_ATENDE,
          'NCOLABORADORES': parseInt(values.B3_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B3_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B4_ATENDE !== ''
        && values.B4_ATENDE !== undefined
        && values.B4_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B4_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B4',
          'ATENDE': values.B4_ATENDE,
          'NCOLABORADORES': parseInt(values.B4_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B4_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B5_ATENDE !== ''
        && values.B5_ATENDE !== undefined
        && values.B5_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B5_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B5',
          'ATENDE': values.B5_ATENDE,
          'NCOLABORADORES': parseInt(values.B5_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B5_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B6_ATENDE !== ''
        && values.B6_ATENDE !== undefined
        && values.B6_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B6_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B6',
          'ATENDE': values.B6_ATENDE,
          'NCOLABORADORES': parseInt(values.B6_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B6_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B7_ATENDE !== ''
        && values.B7_ATENDE !== undefined
        && values.B7_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B7_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B7',
          'ATENDE': values.B7_ATENDE,
          'NCOLABORADORES': parseInt(values.B7_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B7_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B8_ATENDE !== ''
        && values.B8_ATENDE !== undefined
        && values.B8_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B8_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B8',
          'ATENDE': values.B8_ATENDE,
          'NCOLABORADORES': parseInt(values.B8_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B8_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.B9_ATENDE !== ''
        && values.B9_ATENDE !== undefined
        && values.B9_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.B9_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'B9',
          'ATENDE': values.B9_ATENDE,
          'NCOLABORADORES': parseInt(values.B9_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.B9_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- C -----------------
      if (values.C1_ATENDE !== ''
        && values.C1_ATENDE !== undefined
        && values.C1_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.C1_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'C1',
          'ATENDE': values.C1_ATENDE,
          'NCOLABORADORES': parseInt(values.C1_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.C1_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.C2_ATENDE !== ''
        && values.C2_ATENDE !== undefined
        && values.C2_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.C2_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'C2',
          'ATENDE': values.C2_ATENDE,
          'NCOLABORADORES': parseInt(values.C2_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.C2_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.C3_ATENDE !== ''
        && values.C3_ATENDE !== undefined
        && values.C3_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.C3_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'C3',
          'ATENDE': values.C3_ATENDE,
          'NCOLABORADORES': parseInt(values.C3_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.C3_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.C4_ATENDE !== ''
        && values.C4_ATENDE !== undefined
        && values.C4_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.C4_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'C4',
          'ATENDE': values.C4_ATENDE,
          'NCOLABORADORES': parseInt(values.C4_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.C4_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- D -----------------
      if (values.D1_ATENDE !== ''
        && values.D1_ATENDE !== undefined
        && values.D1_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.D1_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'D1',
          'ATENDE': values.D1_ATENDE,
          'NCOLABORADORES': parseInt(values.D1_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.D1_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.D2_ATENDE !== ''
        && values.D2_ATENDE !== undefined
        && values.D2_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.D2_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'D2',
          'ATENDE': values.D2_ATENDE,
          'NCOLABORADORES': parseInt(values.D2_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.D2_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.D3_ATENDE !== ''
        && values.D3_ATENDE !== undefined
        && values.D3_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.D3_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'D3',
          'ATENDE': values.D3_ATENDE,
          'NCOLABORADORES': parseInt(values.D3_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.D3_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- E -----------------
      if (values.E1_ATENDE !== ''
        && values.E1_ATENDE !== undefined
        && values.E1_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.E1_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'E1',
          'ATENDE': values.E1_ATENDE,
          'NCOLABORADORES': parseInt(values.E1_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.E1_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.E2_ATENDE !== ''
        && values.E2_ATENDE !== undefined
        && values.E2_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.E2_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'E2',
          'ATENDE': values.E2_ATENDE,
          'NCOLABORADORES': parseInt(values.E2_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.E2_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.E3_ATENDE !== ''
        && values.E3_ATENDE !== undefined
        && values.E3_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.E3_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'E3',
          'ATENDE': values.E3_ATENDE,
          'NCOLABORADORES': parseInt(values.E3_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.E3_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }


      if (values.E4_ATENDE !== ''
        && values.E4_ATENDE !== undefined
        && values.E4_ATENDE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'IDEQUIPE': parseInt(values.E4_IDEQUIPE),
          'TAREFA': values.TAREFA,
          'CODITEM': 'E4',
          'ATENDE': values.E4_ATENDE,
          'NCOLABORADORES': parseInt(values.E4_NCOLABORADORES),
          'NMELHORIAS': parseInt(values.E4_NMELHORIAS),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'OBSERVADOR': values.OBSERVADOR,
          'DESVIOS': values.DESVIOS,
        });
      }
    }

    if (values) {
      payload = {
        CARTAO: cardList,
        SUCESSOS: values.SUCESSOS,
        REC: values.REC,
      };
    };

    return payload;
  };

  async function postPocInclusion(values: any, resetForm: any) {
    let payload = { CARTAO: [], SUCESSOS: [], REC: [] };
    const url = '/poc/cartao/insere';

    payload = handleToPostPayload(values);


    if (payload?.CARTAO.length > 0) {
      setLoading(true);

      ApiWS()
        .post(url, [payload])

        .then((res) => {
          if (res.status === 200) {
            setShowModalPriter(true);
            setNumberCardPOC(res.data.NUM);

            resetForm();
          };
        })

        .catch((err) => {
          IToast({
            type: 'error',
            message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
          });

          console.error('postPicInclusion:::', JSON.stringify(err));

          return false;
        })

        .finally(() => {
          setLoading(false);
        });
    };

    if (payload?.CARTAO.length <= 0) {
      IToast({
        type: 'warning',
        message: 'Preencha pelo menos um item para salvar o cartão.',
      });
    };
  };


  function handleBloksRecognition(handleChange: any, handleBlur: any, setFieldValue: any) {
    setBloksRecognitions((bloksRecognitions) => [
      ...bloksRecognitions,
      <>
        <Row>
          <Col></Col>
          <Col>
            <TextInput
              type={'text'}
              name={`REC[${bloksRecognitions.length + 1}].MAT`}
              label={`Matrícula ${bloksRecognitions.length + 2}`}
              placeholder={'Número'}
              onBlur={handleBlur}
              onChange={(e: any) => {
                handleChange(e);
                setFieldValue(`REC[${bloksRecognitions.length + 1}].FUNC`, '');
                setFieldValue(`REC[${bloksRecognitions.length + 1}].RECOM`, '');
              }}
            />
          </Col>
          <Col></Col>
        </Row>
      </>,
    ]);
  };


  function getAreaFetch(values: any) {
    setListAreaDrop([]);
    setAreaLoading(true);

    const url = `/poc/cartao/consulta/area/${values}`;
    console.log(url);
    ApiWS()
      .get(url)

      .then((resp) => {
        resp.data.length === 0 && resp.status === 200 &&
          IToast({
            type: 'warning',
            message: `Não existem áreas cadastradas nesta Unidade de Negócio: ${values}.`,
          });


        resp.data.map((resp: any, index: any) => (
          setListAreaDrop((listAreas) => [
            ...listAreas, {
              key: index,
              label: resp.DESCR.trim(),
              value: resp.ID,
            },
          ])
        ));
      })

      .catch((error) => {
        console.error('getAreaFetch', JSON.stringify(error.response, null, 2));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })

      .finally(() => {
        setAreaLoading(false);
      });
  };

  function postRegistrationFetch(value: any, setFieldValue: any) {
    const payload = { MATRICULA: value };
    const url = '/poc/cartao/auditor';

    if (value.length >= 4 && value !== undefined && value !== '') {
      setRegistrationLoading(true);
      ApiWS()
        .post(url, payload)

        .then((resp) => {
          if (resp.status === 200 && resp.data.length > 0) {
            setFieldValue('OBSERVADOR', resp.data[0].NOME);
            setFieldValue('FUNCAO', resp.data[0].FUNCAO);
          }
          else {
            IToast({
              type: 'warning',
              message: 'Verifique se a matrícula está correta.',
            });
          }
        })

        .catch((err) => {
          IToast({
            type: 'error',
            message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
          });
          console.error(err);
        })

        .finally(() => {
          setRegistrationLoading(false);
        });
    }

    if (value.length === 0 || value === undefined || value === '') {
      IToast({
        type: 'warning',
        message: 'Digite a matrícula para consultar.',
      });
    }
  };


  function postFetchCC() {
    setLoading(true);

    const url = '/poc/cartao/consulta/cc';

    ApiWS()
      .post(url)
      .then((res) => {
        res.data.forEach((data, index) => {
          setListNegocDrop((listNegocDrop) => [...listNegocDrop, {
            key: index,
            label: data.OS,
            value: data.OS,
          }]);
        });
      })
      .catch((err) => {
        console.error('postFetchNegoc:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => setLoading(false));
  };

  function getLeaderFetch(values: any) {
    setAreaLoading(true);

    const url = `/poc/equipe/consulta/equipe/${values}`;
    ApiWS()
      .get(url)

      .then((resp) => {
        setListLeaders([]);

        if (resp.data.length === 0 && resp.status === 200) {
          IToast({
            type: 'warning',
            message: `Não existem equipes cadastradas neste Centro de Custo: ${values}.`,
          });
        }

        resp.data.map((leader: any, index: any) => {
          return (

            setListLeaders((listLeaders) => [
              ...listLeaders,
              {
                key: index,
                label: leader.LIDER,
                value: [leader.ID, leader.LIDER, leader.DESCR],
              },
            ])

          );
        });
      })

      .catch((error) => {
        if (!error.response) {
          console.error('getLeaderFetch', JSON.stringify(error.response, null, 2));

          IToast({
            type: 'error',
            message: 'Erro! Contate o suporte!.',
          });
        }
        else {
          IToast({
            type: 'error',
            message: 'Erro! Contate o suporte!.',
          });
        }
      })

      .finally(() => {
        setAreaLoading(false);
      });
  };


  useEffect(() => {
    postFetchCC();
  }, []);

  return (
    <>
      <ContentStyled fluid>
        <>
          {loading && (<LoadingsComponent />)}

          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={POCCartaoValidationSchema}
            initialValues={{
              ...POCCartaoModel,
              DATA: currentDateToInput(),
            }}
            onSubmit={(values, { resetForm }) => {
              postPocInclusion(values, resetForm);
            }}
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              resetForm,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit}>

                {!loading && (
                  <>
                    <AreaFieldsSeparate>

                      <Row>

                        <Col xs={2}>
                          <TextInput
                            type={'date'}
                            name={'DATA'}
                            min={currentDateToInput(-2)}
                            max={currentDateToInput()}
                            label={'Data'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DATA}
                          />
                        </Col>


                        <Col>
                          <DropdownInput
                            name={'CC'}
                            label={'Centro de Custo'}
                            placeholder={'Selecione'}
                            dropArray={listNegocDrop}
                            onChange={(e: any) => {
                              handleChange(e);
                              getAreaFetch(e.target.value);
                              getLeaderFetch(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.CC}
                          />
                        </Col>


                        <Col>
                          <DropdownInput
                            disabled={areaLoading}
                            name={'IDAREA'}
                            label={'Área.'}
                            placeholder={'Selecione'}
                            dropArray={listAreaDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.IDAREA}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col xs={4}>
                          <TextInput
                            type={'text'}
                            name={'TAREFA'}
                            label={'Tarefa'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.TAREFA}
                          />
                        </Col>


                        <Col xs={3}>
                          <TextInput
                            type={'text'}
                            name={'MAT'}
                            label={'Matrícula'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.MAT}
                            buttonIcon={
                              <IconSearch
                                color={!registrationLoading ? 'black' : 'gray'}
                                size={20}
                              />
                            }
                            buttonClick={() => {
                              if (!registrationLoading) {
                                postRegistrationFetch(values.MAT, setFieldValue);
                              }
                            }}
                          />

                          {registrationLoading && (
                            <div className="d-flex text-left">
                              <LoadingsComponent
                                size='10px'
                                type={'FIELD'}
                                color={Colors?.primary}
                                text={'Carregando Matrícula'}
                              />
                            </div>
                          )}
                        </Col>


                        <Col xs={5}>
                          <TextInput
                            disabled
                            type={'text'}
                            name={'OBSERVADOR'}
                            label={'Observador'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.OBSERVADOR}
                          />
                        </Col>

                      </Row>

                    </AreaFieldsSeparate>



                    <POCSectionA
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      listLeaders={listLeaders}
                      values={values}
                    />


                    <POCSectionB
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      listLeaders={listLeaders}
                      values={values}
                    />


                    <POCSectionC
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      listLeaders={listLeaders}
                      values={values}
                    />


                    <POCSectionD
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      listLeaders={listLeaders}
                      values={values}
                    />


                    <POCSectionE
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      listLeaders={listLeaders}
                      values={values}
                    />


                    {/* CASE SUCESSO */}
                    <AreaFieldsSeparate>

                      <Row>
                        <TitleGroup>
                          {'Case de sucesso SMS/EHS'.toUpperCase()}
                        </TitleGroup>

                        <Row>
                          <ColItems_A>Case</ColItems_A>
                          <ColMeets_A><span>Atende</span></ColMeets_A>
                        </Row>
                      </Row>


                      {/* Case1 */}
                      <Row>

                        <ColItemsCase>
                          <TextInput
                            disabled
                            label={'1'}
                            type={'text'}
                            onBlur={handleBlur}
                            value={'O líder da atividade participou do DDS Hoje?'}
                          />
                        </ColItemsCase>

                        <ColMeetsCase>
                          <DropdownInput
                            name={'SUCESSOS[0]'}
                            dropArray={YesNoArray2}
                            placeholder={'N/A'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SUCESSOS[0]}
                          />
                        </ColMeetsCase>

                      </Row>


                      {/* Case2 */}
                      <Row>

                        <ColItemsCase>
                          <TextInput
                            disabled
                            label={'2'}
                            type={'text'}
                            onBlur={handleBlur}
                            value={'O líder determinou o padrinho para orientar os novos colaboradores da equipe ?'}
                          />
                        </ColItemsCase>


                        <ColMeetsCase>
                          <DropdownInput
                            name={'SUCESSOS[1]'}
                            dropArray={YesNoArray2}
                            placeholder={'N/A'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SUCESSOS[1]}
                          />
                        </ColMeetsCase>

                      </Row>


                      {/* Case3 */}
                      <Row>

                        <ColItemsCase>
                          <TextInput
                            disabled
                            label={'3'}
                            type={'text'}
                            onBlur={handleBlur}
                            value={'O líder verificou os pontos de bloqueios (Etiqueta principal e bloqueios individuais), junto a sua equipe de trabalho?'}
                          />
                        </ColItemsCase>


                        <ColMeetsCase>
                          <DropdownInput
                            name={'SUCESSOS[2]'}
                            dropArray={YesNoArray2}
                            placeholder={'N/A'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SUCESSOS[2]}
                          />
                        </ColMeetsCase>

                      </Row>


                      {/* Case4 */}
                      <Row>

                        <ColItemsCase>
                          <TextInput
                            disabled
                            label={'4'}
                            type={'text'}
                            onBlur={handleBlur}
                            value={'Existem rotas de fugas seguras (rampas, andaimes, escadas e passarelas)  e são de conhecimento da equipe de trabalho, em caso de evcuação de área? '}
                          />
                        </ColItemsCase>


                        <ColMeetsCase>
                          <DropdownInput
                            name={'SUCESSOS[3]'}
                            dropArray={YesNoArray2}
                            placeholder={'N/A'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SUCESSOS[3]}
                          />
                        </ColMeetsCase>

                      </Row>


                      {/* Case5 */}
                      <Row>

                        <ColItemsCase>
                          <TextInput
                            disabled
                            label={'5'}
                            type={'text'}
                            onBlur={handleBlur}
                            value={'Durante a execução da tarefa, o líder imediato  orienta os colaboradores o que fazer? como fazer? e quando parar?'}
                          />
                        </ColItemsCase>


                        <ColMeetsCase>
                          <DropdownInput
                            name={'SUCESSOS[4]'}
                            dropArray={YesNoArray2}
                            placeholder={'N/A'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SUCESSOS[4]}
                          />
                        </ColMeetsCase>

                      </Row>


                    </AreaFieldsSeparate>


                    {/* DESVIO */}
                    <AreaFieldsSeparate>
                      <Row>
                        <TitleGroup>
                          {'Desvio Crítico / Risco Iminente / Incidente ( Presenciado pelo Observador )'.toUpperCase()}
                        </TitleGroup>

                        <Row>

                          <Col>
                            <TextInput
                              textHeight={150}
                              max={150}
                              type={'text'}
                              name={'DESVIOS'}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.DESVIOS}
                              totalChar={values.DESVIOS?.length}
                            />
                          </Col>

                        </Row>
                      </Row>

                    </AreaFieldsSeparate>


                    {/* RECONHECIMENTO */}
                    <AreaFieldsSeparate>
                      <TitleGroup>
                        {'Reconhecimento de Trabalho Seguro - Matrículas dos Funcionários'.toUpperCase()}
                      </TitleGroup>


                      <Row>

                        <Row>

                          <Col></Col>
                          <Col>
                            <TextInput
                              type={'text'}
                              name={'REC[0].MAT'}
                              label={'Matrícula 1'}
                              placeholder={'Número'}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.REC[0].MAT}
                            />
                          </Col>
                          <Col></Col>

                        </Row>


                        {bloksRecognitions &&
                          bloksRecognitions.map((blok) => (
                            blok
                          ))
                        }

                        <Row>
                          <Col></Col>
                          <Col xs={1} style={{ marginTop: 15 }}>
                            <ButtonTableAction
                              onClick={() => handleBloksRecognition(handleChange, handleBlur, setFieldValue)}
                              tooltip={TextTooltip.ADD_MATRICULATION}
                              icon={
                                <IconListNew
                                  color={Colors?.quaternary}
                                  size={30}
                                />
                              }
                            />
                          </Col>
                          <Col></Col>
                        </Row>

                      </Row>

                    </AreaFieldsSeparate>


                    {/* OBSERVACOES */}
                    <AreaFieldsSeparate>
                      <TitleGroup>
                        {'Observações E Sugestões - SMS/EHS'.toUpperCase()}
                      </TitleGroup>


                      <Row>

                        <Col>
                          <TextInput
                            textHeight={150}
                            max={150}
                            type={'text'}
                            name={'OBS'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.OBS}
                            totalChar={values.OBS?.length}
                          />
                        </Col>

                      </Row>

                    </AreaFieldsSeparate>



                    {/* BUTTONS */}
                    <AreaFieldsSeparate>

                      <Row>

                        <RowButtonFiltersView>


                          <IButton
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();
                            }}
                          />


                          <IButton
                            // disabled={loading}
                            text={'Salvar'}
                            backgroundColor={Colors?.primary}
                            type={'submit'}
                            onClick={() => {
                              if (!isValid) {
                                IToast({
                                  type: 'warning',
                                  message: 'Existem campos obrigatórios que não foram preenchidos! Revise o formulário.',
                                });
                              }
                            }}
                          />

                        </RowButtonFiltersView>

                      </Row>

                    </AreaFieldsSeparate>
                  </>
                )}
              </Form>

            )}
          </Formik>

        </>
      </ContentStyled>


      <ConfirmationModal
        show={showModalPrinter}
        head="Feito!"
        orient={`Cartão PIC ${numberCardPOC} gerado com sucesso.`}
        others={
          <GenerateButtonsSMEView>
            <IButton
              backgroundColor={Colors?.tertiary}
              onClick={() => {
                window.open(`/${NameRoutes.POC_Consulta_Impressao}/${numberCardPOC}`, '_blank').focus();
              }}
              text={'Imprimir'}
            />

          </GenerateButtonsSMEView>
        }
        negative={() => {
          setShowModalPriter(false);
        }}
      />

    </>
  );
};



export default Tab_CadastroCartaoScreen;
