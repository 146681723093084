const RpAtendimentoModel = {
  OS: 'TODOS',
  NUM: '',
  FUNCAO: '',
  SITUAC: 'Todos',
  DTABERTINI: '',
  DTABERTFIM: '',
  DTNECESINI: '',
  DTNECESFIM: '',
};


export default RpAtendimentoModel;
