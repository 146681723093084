export const PffRealizadoModel = {
  /* FIXME: Comentar linhas 5,7,11 e descomentar 4,6,8,12 para subir prod/homolog */
  ANO: new Date(Date.now()).getFullYear(),
  CC: 'TODOS',
  // CC: 'MKS0116',
  MES: '',
  // MES: '05',
  NEGOC: 'TODOS',
  // NEGOC: '30',
  TIPO: 'RE',
  // TIPORE: 'MKS0116',
  TIPORE: 'TODOS',
};


