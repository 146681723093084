import React, { useState } from 'react';

import { OverlayTrigger } from 'react-bootstrap';
import {
  Formik,
} from 'formik';
import { Colors } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import { TableToltip } from '../../../../../common/styles/styled.layout';
import { IconColapseExpand, IconColapseExpand2, IconColapseRetract, IconColapseRetract2, IconExcel } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import { maskToMoney3, maskToMoney4 } from '../../../../../utils/CurrencyUtils';
import PffGridValidationSchema from '../PFF_Lancamento/PFF_Lancamento_Grid/PFF-grid-validation';
import { PffGridModel } from '../PFF_Lancamento/PFF_Lancamento_Grid/PFF-grid.model';
import { PffFilterTableResume } from '../PFF_Lancamento/PFF-lancamento-styled';
import { TrHeader, TableResume, TdBudgeted, TdRelease, ThExpandAll, TrRelease, BtnExportResume, ThHead, ContainerExportResume } from './styled';



interface IProps {
  indexTab?: number;
  releaseType?: any;
  loading?: boolean;
  bdView?: string;
}


const PFFDetailsScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const btnExpand = <IconColapseExpand size={12} color={Colors?.textColor} />;
  const btnCollapse = <IconColapseRetract size={12} color={Colors?.textColor} />;
  const btnExpand2 = <IconColapseExpand2 size={12} color={Colors?.white} />;
  const btnCollapse2 = <IconColapseRetract2 size={12} color={Colors?.white} />;


  const [allExpanded, setAllExpanded] = useState(false);
  const [rowExpanded, setRowExpanded] = useState([false, false, false, false, false, false, false, false, false, false, false]);

  const dataLocalStorage = JSON.parse(localStorage.getItem(props.bdView || '@pff/LANCAMENTO/DATA'));

  function calcTotalsReleases(type, release) {
    setLoading(true);

    try {
      let sum_ANT = 0.00;
      let sum_ORCADO = 0.00;
      let sum_PLANEJADO = 0.00;
      let sum_REALIZADO = 0.00;
      let sum_PREVISTO = 0.00;
      let sum_TOTAL = 0.00;

      let sum_JAN = 0.00;
      let sum_FEV = 0.00;
      let sum_MAR = 0.00;
      let sum_ABR = 0.00;
      let sum_MAI = 0.00;
      let sum_JUN = 0.00;
      let sum_JUL = 0.00;
      let sum_AGO = 0.00;
      let sum_SET = 0.00;
      let sum_OUT = 0.00;
      let sum_NOV = 0.00;
      let sum_DEZ = 0.00;


      if (type === 'FAT') {
        if (release === 'ANT') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[3].FAT.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'RRE') {
        if (release === 'ANT') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[4].RRE.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'RCO') {
        if (release === 'ANT') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.ANT.indexOf('-') !== -1) {
              rco.ANT = rco.ANT.replaceAll('-', '');
            }

            if (rco.ANT && rco.ANT.indexOf(',') !== -1) {
              rco.ANT = rco.ANT.replaceAll('.', '');
              rco.ANT = rco.ANT.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_ANT = sum_ANT - parseFloat(rco.ANT);
            }
            else {
              sum_ANT = sum_ANT + parseFloat(rco.ANT);
            }
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.ORCADO.indexOf('-') !== -1) {
              rco.ORCADO = rco.ORCADO.replaceAll('-', '');
            }

            if (rco.ORCADO && rco.ORCADO.indexOf(',') !== -1) {
              rco.ORCADO = rco.ORCADO.replaceAll('.', '');
              rco.ORCADO = rco.ORCADO.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_ORCADO = sum_ORCADO - parseFloat(rco.ORCADO);
            }
            else {
              sum_ORCADO = sum_ORCADO + parseFloat(rco.ORCADO);
            }
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.PLANEJADO.indexOf('-') !== -1) {
              rco.PLANEJADO = rco.PLANEJADO.replaceAll('-', '');
            }

            if (rco.PLANEJADO && rco.PLANEJADO.indexOf(',') !== -1) {
              rco.PLANEJADO = rco.PLANEJADO.replaceAll('.', '');
              rco.PLANEJADO = rco.PLANEJADO.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.PLANEJADO);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.PLANEJADO);
            }
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.REALIZADO.indexOf('-') !== -1) {
              rco.REALIZADO = rco.REALIZADO.replaceAll('-', '');
            }

            if (rco.REALIZADO && rco.REALIZADO.indexOf(',') !== -1) {
              rco.REALIZADO = rco.REALIZADO.replaceAll('.', '');
              rco.REALIZADO = rco.REALIZADO.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_REALIZADO = sum_REALIZADO - parseFloat(rco.REALIZADO);
            }
            else {
              sum_REALIZADO = sum_REALIZADO + parseFloat(rco.REALIZADO);
            }
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.PREVISTO.indexOf('-') !== -1) {
              rco.PREVISTO = rco.PREVISTO.replaceAll('-', '');
            }

            if (rco.PREVISTO && rco.PREVISTO.indexOf(',') !== -1) {
              rco.PREVISTO = rco.PREVISTO.replaceAll('.', '');
              rco.PREVISTO = rco.PREVISTO.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_PREVISTO = sum_PREVISTO - parseFloat(rco.PREVISTO);
            }
            else {
              sum_PREVISTO = sum_PREVISTO + parseFloat(rco.PREVISTO);
            }
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TOTAL.indexOf('-') !== -1) {
              rco.TOTAL = rco.TOTAL.replaceAll('-', '');
            }

            if (rco.TOTAL && rco.TOTAL.indexOf(',') !== -1) {
              rco.TOTAL = rco.TOTAL.replaceAll('.', '');
              rco.TOTAL = rco.TOTAL.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_TOTAL = sum_TOTAL - parseFloat(rco.TOTAL);
            }
            else {
              sum_TOTAL = sum_TOTAL + parseFloat(rco.TOTAL);
            }
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.PLANEJADO);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.PLANEJADO);
            }

            if (rco.JAN && rco.JAN.indexOf(',') !== -1) {
              rco.JAN = rco.JAN.replaceAll('.', '');
              rco.JAN = rco.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(rco.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.FEV);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.FEV);
            }

            if (rco.FEV && rco.FEV.indexOf(',') !== -1) {
              rco.FEV = rco.FEV.replaceAll('.', '');
              rco.FEV = rco.FEV.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_FEV = sum_FEV - parseFloat(rco.FEV);
            }
            else {
              sum_FEV = sum_FEV + parseFloat(rco.FEV);
            }
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.MAR);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.MAR);
            }

            if (rco.MAR && rco.MAR.indexOf(',') !== -1) {
              rco.MAR = rco.MAR.replaceAll('.', '');
              rco.MAR = rco.MAR.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_MAR = sum_MAR - parseFloat(rco.MAR);
            }
            else {
              sum_MAR = sum_MAR + parseFloat(rco.MAR);
            }
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.ABR);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.ABR);
            }

            if (rco.ABR && rco.ABR.indexOf(',') !== -1) {
              rco.ABR = rco.ABR.replaceAll('.', '');
              rco.ABR = rco.ABR.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_ABR = sum_ABR - parseFloat(rco.ABR);
            }
            else {
              sum_ABR = sum_ABR + parseFloat(rco.ABR);
            }
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.MAI);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.MAI);
            }

            if (rco.MAI && rco.MAI.indexOf(',') !== -1) {
              rco.MAI = rco.MAI.replaceAll('.', '');
              rco.MAI = rco.MAI.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_MAI = sum_MAI - parseFloat(rco.MAI);
            }
            else {
              sum_MAI = sum_MAI + parseFloat(rco.MAI);
            }
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.JUN);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.JUN);
            }

            if (rco.JUN && rco.JUN.indexOf(',') !== -1) {
              rco.JUN = rco.JUN.replaceAll('.', '');
              rco.JUN = rco.JUN.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_JUN = sum_JUN - parseFloat(rco.JUN);
            }
            else {
              sum_JUN = sum_JUN + parseFloat(rco.JUN);
            }
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.JUL);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.JUL);
            }

            if (rco.JUL && rco.JUL.indexOf(',') !== -1) {
              rco.JUL = rco.JUL.replaceAll('.', '');
              rco.JUL = rco.JUL.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_JUL = sum_JUL - parseFloat(rco.JUL);
            }
            else {
              sum_JUL = sum_JUL + parseFloat(rco.JUL);
            }
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.AGO);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.AGO);
            }

            if (rco.AGO && rco.AGO.indexOf(',') !== -1) {
              rco.AGO = rco.AGO.replaceAll('.', '');
              rco.AGO = rco.AGO.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_AGO = sum_AGO - parseFloat(rco.AGO);
            }
            else {
              sum_AGO = sum_AGO + parseFloat(rco.AGO);
            }
          });
        }

        if (release === 'SET') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.SET);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.SET);
            }

            if (rco.SET && rco.SET.indexOf(',') !== -1) {
              rco.SET = rco.SET.replaceAll('.', '');
              rco.SET = rco.SET.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_SET = sum_SET - parseFloat(rco.SET);
            }
            else {
              sum_SET = sum_SET + parseFloat(rco.SET);
            }
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.OUT);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.OUT);
            }

            if (rco.OUT && rco.OUT.indexOf(',') !== -1) {
              rco.OUT = rco.OUT.replaceAll('.', '');
              rco.OUT = rco.OUT.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_OUT = sum_OUT - parseFloat(rco.OUT);
            }
            else {
              sum_OUT = sum_OUT + parseFloat(rco.OUT);
            }
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.NOV);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.NOV);
            }

            if (rco.NOV && rco.NOV.indexOf(',') !== -1) {
              rco.NOV = rco.NOV.replaceAll('.', '');
              rco.NOV = rco.NOV.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_NOV = sum_NOV - parseFloat(rco.NOV);
            }
            else {
              sum_NOV = sum_NOV + parseFloat(rco.NOV);
            }
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[5].RCO.forEach((rco) => {
            if (rco.TIPO === 'BMSI Negativo') {
              sum_PLANEJADO = sum_PLANEJADO - parseFloat(rco.DEZ);
            }
            else {
              sum_PLANEJADO = sum_PLANEJADO + parseFloat(rco.DEZ);
            }

            if (rco.DEZ && rco.DEZ.indexOf(',') !== -1) {
              rco.DEZ = rco.DEZ.replaceAll('.', '');
              rco.DEZ = rco.DEZ.replaceAll(',', '.');
            }

            if (rco.TIPO === 'BMSI Negativo') {
              sum_DEZ = sum_DEZ - parseFloat(rco.DEZ);
            }
            else {
              sum_DEZ = sum_DEZ + parseFloat(rco.DEZ);
            }
          });
        }
      }


      if (type === 'IMP') {
        if (release === 'ANT') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }
            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[6].IMP.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'CUS') {
        if (release === 'ANT') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[7].CUS.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'MOD') {
        if (release === 'ANT') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[8].MOD.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'MOI') {
        if (release === 'ANT') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[9].MOI.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'MOT') {
        if (release === 'ANT') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[10].MOT.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'AVA') {
        if (release === 'ANT') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[11].AVA.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'ADI') {
        if (release === 'ANT') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[12].ADI.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }


      if (type === 'EFE') {
        if (release === 'ANT') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.ANT && fat.ANT.indexOf(',') !== -1) {
              fat.ANT = fat.ANT.replaceAll('.', '');
              fat.ANT = fat.ANT.replaceAll(',', '.');
            }

            sum_ANT = sum_ANT + parseFloat(fat.ANT);
          });
        }

        if (release === 'ORCADO') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.ORCADO && fat.ORCADO.indexOf(',') !== -1) {
              fat.ORCADO = fat.ORCADO.replaceAll('.', '');
              fat.ORCADO = fat.ORCADO.replaceAll(',', '.');
            }

            sum_ORCADO = sum_ORCADO + parseFloat(fat.ORCADO);
          });
        }

        if (release === 'PLANEJADO') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.PLANEJADO && fat.PLANEJADO.indexOf(',') !== -1) {
              fat.PLANEJADO = fat.PLANEJADO.replaceAll('.', '');
              fat.PLANEJADO = fat.PLANEJADO.replaceAll(',', '.');
            }

            sum_PLANEJADO = sum_PLANEJADO + parseFloat(fat.PLANEJADO);
          });
        }

        if (release === 'REALIZADO') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.REALIZADO && fat.REALIZADO.indexOf(',') !== -1) {
              fat.REALIZADO = fat.REALIZADO.replaceAll('.', '');
              fat.REALIZADO = fat.REALIZADO.replaceAll(',', '.');
            }

            sum_REALIZADO = sum_REALIZADO + parseFloat(fat.REALIZADO);
          });
        }

        if (release === 'PREVISTO') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.PREVISTO && fat.PREVISTO.indexOf(',') !== -1) {
              fat.PREVISTO = fat.PREVISTO.replaceAll('.', '');
              fat.PREVISTO = fat.PREVISTO.replaceAll(',', '.');
            }

            sum_PREVISTO = sum_PREVISTO + parseFloat(fat.PREVISTO);
          });
        }

        if (release === 'TOTAL') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.TOTAL && fat.TOTAL.indexOf(',') !== -1) {
              fat.TOTAL = fat.TOTAL.replaceAll('.', '');
              fat.TOTAL = fat.TOTAL.replaceAll(',', '.');
            }

            sum_TOTAL = sum_TOTAL + parseFloat(fat.TOTAL);
          });
        }

        if (release === 'JAN') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.JAN && fat.JAN.indexOf(',') !== -1) {
              fat.JAN = fat.JAN.replaceAll('.', '');
              fat.JAN = fat.JAN.replaceAll(',', '.');
            }

            sum_JAN = sum_JAN + parseFloat(fat.JAN);
          });
        }

        if (release === 'FEV') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.FEV && fat.FEV.indexOf(',') !== -1) {
              fat.FEV = fat.FEV.replaceAll('.', '');
              fat.FEV = fat.FEV.replaceAll(',', '.');
            }

            sum_FEV = sum_FEV + parseFloat(fat.FEV);
          });
        }

        if (release === 'MAR') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.MAR && fat.MAR.indexOf(',') !== -1) {
              fat.MAR = fat.MAR.replaceAll('.', '');
              fat.MAR = fat.MAR.replaceAll(',', '.');
            }

            sum_MAR = sum_MAR + parseFloat(fat.MAR);
          });
        }

        if (release === 'ABR') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.ABR && fat.ABR.indexOf(',') !== -1) {
              fat.ABR = fat.ABR.replaceAll('.', '');
              fat.ABR = fat.ABR.replaceAll(',', '.');
            }

            sum_ABR = sum_ABR + parseFloat(fat.ABR);
          });
        }

        if (release === 'MAI') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.MAI && fat.MAI.indexOf(',') !== -1) {
              fat.MAI = fat.MAI.replaceAll('.', '');
              fat.MAI = fat.MAI.replaceAll(',', '.');
            }

            sum_MAI = sum_MAI + parseFloat(fat.MAI);
          });
        }

        if (release === 'JUN') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.JUN && fat.JUN.indexOf(',') !== -1) {
              fat.JUN = fat.JUN.replaceAll('.', '');
              fat.JUN = fat.JUN.replaceAll(',', '.');
            }

            sum_JUN = sum_JUN + parseFloat(fat.JUN);
          });
        }

        if (release === 'JUL') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.JUL && fat.JUL.indexOf(',') !== -1) {
              fat.JUL = fat.JUL.replaceAll('.', '');
              fat.JUL = fat.JUL.replaceAll(',', '.');
            }

            sum_JUL = sum_JUL + parseFloat(fat.JUL);
          });
        }

        if (release === 'AGO') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.AGO && fat.AGO.indexOf(',') !== -1) {
              fat.AGO = fat.AGO.replaceAll('.', '');
              fat.AGO = fat.AGO.replaceAll(',', '.');
            }

            sum_AGO = sum_AGO + parseFloat(fat.AGO);
          });
        }

        if (release === 'SET') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.SET && fat.SET.indexOf(',') !== -1) {
              fat.SET = fat.SET.replaceAll('.', '');
              fat.SET = fat.SET.replaceAll(',', '.');
            }

            sum_SET = sum_SET + parseFloat(fat.SET);
          });
        }

        if (release === 'OUT') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.OUT && fat.OUT.indexOf(',') !== -1) {
              fat.OUT = fat.OUT.replaceAll('.', '');
              fat.OUT = fat.OUT.replaceAll(',', '.');
            }

            sum_OUT = sum_OUT + parseFloat(fat.OUT);
          });
        }

        if (release === 'NOV') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.NOV && fat.NOV.indexOf(',') !== -1) {
              fat.NOV = fat.NOV.replaceAll('.', '');
              fat.NOV = fat.NOV.replaceAll(',', '.');
            }

            sum_NOV = sum_NOV + parseFloat(fat.NOV);
          });
        }

        if (release === 'DEZ') {
          dataLocalStorage[13].EFE.forEach((fat) => {
            if (fat.DEZ && fat.DEZ.indexOf(',') !== -1) {
              fat.DEZ = fat.DEZ.replaceAll('.', '');
              fat.DEZ = fat.DEZ.replaceAll(',', '.');
            }

            sum_DEZ = sum_DEZ + parseFloat(fat.DEZ);
          });
        }
      }

      return {
        ANT: maskToMoney3(sum_ANT),
        ORCADO: maskToMoney3(sum_ORCADO),
        PLANEJADO: maskToMoney3(sum_PLANEJADO),
        REALIZADO: maskToMoney3(sum_REALIZADO),
        PREVISTO: maskToMoney3(sum_PREVISTO),
        TOTAL: maskToMoney3(sum_TOTAL),
        JAN: maskToMoney3(sum_JAN),
        FEV: maskToMoney3(sum_FEV),
        MAR: maskToMoney3(sum_MAR),
        ABR: maskToMoney3(sum_ABR),
        MAI: maskToMoney3(sum_MAI),
        JUN: maskToMoney3(sum_JUN),
        JUL: maskToMoney3(sum_JUL),
        AGO: maskToMoney3(sum_AGO),
        SET: maskToMoney3(sum_SET),
        OUT: maskToMoney3(sum_OUT),
        NOV: maskToMoney3(sum_NOV),
        DEZ: maskToMoney3(sum_DEZ),
      };
    }
    catch (error) {
      console.error('ERRO na construção do GRID:::', error);
    }
    finally {
      setLoading(false);
    }
  };


  function singleExpandOrCollapse(position: number): void {
    const newArray = [];

    rowExpanded.forEach((row, index) => {
      if (index === position) {
        row = !row;
        newArray.push(row);
      }
      else {
        newArray.push(row);
      }
    });

    setRowExpanded(newArray);
  };


  function allExpandOrCollapse() {
    setAllExpanded(!allExpanded);
    const newArray = [];

    rowExpanded.forEach((row) => {
      row = !allExpanded;
      newArray.push(row);
    });

    setRowExpanded(newArray);
  };


  function postCNPJChange(values: any, resetForm: any) {
    alert(JSON.stringify(values));
    resetForm();
  };


  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={PffGridModel}
      onSubmit={(values, { resetForm }) => {
        postCNPJChange(values, resetForm);
      }}>
      {({
        handleSubmit,
      }) => (
        <>
          {(props.loading || loading) && <LoadingsComponent />}


          {(!props.loading || !loading) &&
            <PffFilterTableResume onSubmit={handleSubmit}>

              <TableResume id={'tabela'} className="table table-bordered table-striped">

                <thead>
                  <TrHeader>

                    <ThExpandAll onClick={() => allExpandOrCollapse()} colSpan={2}>
                      {allExpanded ? btnExpand2 : btnCollapse2}
                    </ThExpandAll>

                    <ThHead>Anterior</ThHead>
                    <ThHead>Original</ThHead>
                    <ThHead>Executivo</ThHead>
                    <ThHead>Realizado</ThHead>
                    <ThHead>Previsto</ThHead>
                    <ThHead>Total</ThHead>
                    <ThHead>JAN</ThHead>
                    <ThHead>FEV</ThHead>
                    <ThHead>MAR</ThHead>
                    <ThHead>ABR</ThHead>
                    <ThHead>MAI</ThHead>
                    <ThHead>JUN</ThHead>
                    <ThHead>JUL</ThHead>
                    <ThHead>AGO</ThHead>
                    <ThHead>SET</ThHead>
                    <ThHead>OUT</ThHead>
                    <ThHead>NOV</ThHead>
                    <ThHead>DEZ</ThHead>

                  </TrHeader>

                </thead>


                <tbody>

                  {/* ----------------------- Margem (R$) */}
                  <tr>
                    <TdRelease colSpan={2}>Margem (R$)</TdRelease>

                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].ANT)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].ORCADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].PLANEJADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].REALIZADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].PREVISTO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].TOTAL)?.replaceAll('--', '-')}</TdBudgeted>

                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].JAN)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].FEV)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].MAR)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].ABR)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].MAI)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].JUN)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].JUL)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].AGO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].SET)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].OUT)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].NOV)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[0].DEZ)?.replaceAll('--', '-')}</TdBudgeted>
                  </tr>


                  {/* ----------------------- Margem (%) */}
                  <tr>
                    <TdRelease colSpan={2}>Margem (%)</TdRelease>

                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].ANT)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].ORCADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].PLANEJADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].REALIZADO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].PREVISTO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].TOTAL)?.replaceAll('--', '-')}</TdBudgeted>

                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].JAN)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].FEV)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].MAR)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].ABR)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].MAI)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].JUN)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].JUL)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].AGO)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].SET)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].OUT)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].NOV)?.replaceAll('--', '-')}</TdBudgeted>
                    <TdBudgeted>{maskToMoney4(dataLocalStorage[2].MAR[1].DEZ)?.replaceAll('--', '-')}</TdBudgeted>
                  </tr>


                  {/* ----------------------- Faturamento */}
                  <TrRelease onClick={() => singleExpandOrCollapse(0)}>
                    <td>{rowExpanded[0] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Faturamento</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('FAT', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('FAT', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('FAT', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[0] && <>

                    <tr>
                      <td colSpan={2}> Direto  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Contratual  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[1].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Reajuste  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[2].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Extra  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[3].FAT[3].DEZ)}</TdBudgeted>
                    </tr>

                  </>}



                  {/* ----------------------- Receitas Recebí.s */}
                  <TrRelease onClick={() => singleExpandOrCollapse(1)}>
                    <td>{rowExpanded[1] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Receitas Receb.</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('RRE', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('RRE', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RRE', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[1] && <>

                    <tr>
                      <td colSpan={2}> Mobilização  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Indiretos  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[1].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Revenda  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[2].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Ser./Avanço Físico  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[3].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Extra  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[4].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Outras </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[4].RRE[5].DEZ)}</TdBudgeted>
                    </tr>

                  </>}



                  {/* ----------------------- Receitas Cont.e */}
                  <TrRelease onClick={() => singleExpandOrCollapse(2)}>
                    <td>{rowExpanded[2] ? btnExpand : btnCollapse}</td>

                    <TdRelease>Receitas Contr.</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('RCO', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('RCO', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('RCO', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[2] && <>

                    <tr>
                      <td colSpan={2}> BMSI  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> BMSI Negativo  </td>

                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].ANT)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].ORCADO)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].PLANEJADO)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].REALIZADO)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].PREVISTO)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].TOTAL)}`}</TdBudgeted>

                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].JAN)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].FEV)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].MAR)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].ABR)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].MAI)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].JUN)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].JUL)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].AGO)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].SET)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].OUT)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].NOV)}`}</TdBudgeted>
                      <TdBudgeted>{`-${maskToMoney3(dataLocalStorage[5].RCO[1].DEZ)}`}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Notas de Debitos  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[2].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Outras  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[5].RCO[3].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Impostos Diretos */}
                  <TrRelease onClick={() => singleExpandOrCollapse(3)}>
                    <td>{rowExpanded[3] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Impostos Diret.</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('IMP', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('IMP', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('IMP', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[3] && <>

                    <tr>
                      <td colSpan={2}> ISS  </td>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].ANT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].ORCADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].PLANEJADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].REALIZADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].PREVISTO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].TOTAL).toFixed(2))}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].JAN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].FEV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].MAR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].ABR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].MAI).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].JUN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].JUL).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].AGO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].SET).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].OUT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].NOV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[0].DEZ).toFixed(2))}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> PIS/COFINS  </td>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].ANT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].ORCADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].PLANEJADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].REALIZADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].PREVISTO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].TOTAL).toFixed(2))}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].JAN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].FEV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].MAR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].ABR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].MAI).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].JUN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].JUL).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].AGO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].SET).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].OUT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].NOV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[1].DEZ).toFixed(2))}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> ICMS/IPI	  </td>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].ANT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].ORCADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].PLANEJADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].REALIZADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].PREVISTO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].TOTAL).toFixed(2))}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].JAN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].FEV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].MAR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].ABR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].MAI).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].JUN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].JUL).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].AGO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].SET).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].OUT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].NOV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[2].DEZ).toFixed(2))}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Desoneração  </td>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].ANT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].ORCADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].PLANEJADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].REALIZADO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].PREVISTO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].TOTAL).toFixed(2))}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].JAN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].FEV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].MAR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].ABR).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].MAI).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].JUN).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].JUL).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].AGO).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].SET).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].OUT).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].NOV).toFixed(2))}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(parseFloat(dataLocalStorage[6].IMP[3].DEZ).toFixed(2))}</TdBudgeted>
                    </tr>

                  </>}


                  {/* ----------------------- Custos */}
                  <TrRelease onClick={() => singleExpandOrCollapse(4)}>
                    <td>{rowExpanded[4] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Custos</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('CUS', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('CUS', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('CUS', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[4] && <>

                    <tr>
                      <td colSpan={2}>CMD01-Salários</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMD02-Encargos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[1].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMD03-Horas Extras/Adicional</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[2].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMI01-Salários</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[3].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMI02-Encargos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[4].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMI03-Horas Extras/Adicional</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[5].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP01-Transporte</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[6].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP02-Alimentação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[7].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP03-EPI e Uniformes</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[8].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP04-Alojamento</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[9].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP05-Cursos e Treinamento</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[10].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP06-Assistência Médica</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[11].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CAP07-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[12].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMM01-Despesas com Viagens</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[13].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMM02-Fretes/Transportes</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[14].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMM03-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[15].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE01-Instalação/Construção</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[16].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE02-Manutenção</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[17].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE03-Alugueis</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[18].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE04-Comunicação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[19].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE05-Informatica/TI</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[20].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE06-Materiais de Escritorio</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[21].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CCE07-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[22].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CEQ01-Aquisição</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[23].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CEQ02-Locação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[24].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CEQ03-Manutenção</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[25].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CEQ04-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[26].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA01-Diversos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[27].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA02-Civil</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[28].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA03-Tubulação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[29].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA04-Caldeiraria/Estrutura</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[30].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA05-Eletrica/Instrumentação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[31].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA06-Isolamento</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[32].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMA07-Pintura</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[33].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMC01-Material de Consumo</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[34].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMC02-Gases</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[35].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMC03-Eletrodos e Varetas</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[36].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CMV-Merc.Vend.</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[37].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB01-Diversos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[38].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB02-Civil</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[39].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB03-Tubulação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[40].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB04-Caldeiraria/Estrutura</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[41].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB05-Eletrica/Instrumentação</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[42].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB06-Isolamento</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[43].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CSB07-Pintura</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[44].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CDG01-Taxas Diversas</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[45].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CDG02-Premiações/Patrocinio</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[46].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CDG03-Seguros/Multas/Juros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[47].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CDG04-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[48].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC01-Custos de MOD</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[49].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC02-Custos de MOI</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[50].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC03-Custos de Apoio</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[51].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC04-Custos de Mobilização</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[52].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC05-Custos de Canteiro</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[53].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC06-Custos de Equipamentos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[54].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC07-Custos de Materiais</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[55].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC08-Custos de Subcontratos</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[56].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC09-Custos Financeiros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[57].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}>CRC10-Outros</td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[7].CUS[58].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Hh MOD */}
                  <TrRelease onClick={() => singleExpandOrCollapse(5)}>
                    <td>{rowExpanded[5] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Hh MOD</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('MOD', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('MOD', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOD', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[5] && <>

                    <tr>
                      <td colSpan={2}> MOD - Hh normais	  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> MOD - Hh extras	  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[8].MOD[1].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Hh MOI */}
                  <TrRelease onClick={() => singleExpandOrCollapse(6)}>
                    <td>{rowExpanded[6] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Hh MOI</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('MOI', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('MOI', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOI', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[6] && <>

                    <tr>
                      <td colSpan={2}> MOI - Hh Normais	  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> MOI - Hh extras	  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[9].MOI[1].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Hh Terceiros */}
                  <TrRelease onClick={() => singleExpandOrCollapse(7)}>
                    <td>{rowExpanded[7] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Hh Terceiros</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('MOT', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('MOT', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('MOT', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[7] && <>

                    <tr>
                      <td colSpan={2}> Hh Terceiros  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[10].MOT[0].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Avanço */}
                  <TrRelease onClick={() => singleExpandOrCollapse(8)}>
                    <td>{rowExpanded[8] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Avanço</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('AVA', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('AVA', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('AVA', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[8] && <>

                    <tr>
                      <td colSpan={2}> Receita  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Fisico  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[1].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Medição  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[11].AVA[2].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Adiantamentos */}
                  <TrRelease onClick={() => singleExpandOrCollapse(9)}>
                    <td>{rowExpanded[9] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Adiantamentos</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('ADI', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('ADI', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('ADI', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[9] && <>

                    <tr>
                      <td colSpan={2}> Contratual  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[0].DEZ)}</TdBudgeted>
                    </tr>

                    <tr>
                      <td colSpan={2}> Outros  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[12].ADI[1].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                  {/* ----------------------- Efetivo */}
                  <TrRelease onClick={() => singleExpandOrCollapse(10)}>
                    <td>{rowExpanded[10] ? btnExpand : btnCollapse}</td>
                    <TdRelease>Efetivo</TdRelease>

                    <TdBudgeted> {calcTotalsReleases('EFE', 'ANT')?.ANT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'ORCADO')?.ORCADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'PLANEJADO')?.PLANEJADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'REALIZADO')?.REALIZADO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'PREVISTO')?.PREVISTO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'TOTAL')?.TOTAL} </TdBudgeted>

                    <TdBudgeted> {calcTotalsReleases('EFE', 'JAN').JAN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'FEV').FEV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'MAR').MAR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'ABR').ABR} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'MAI').MAI} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'JUN').JUN} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'JUL').JUL} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'AGO').AGO} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'SET').SET} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'OUT').OUT} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'NOV').NOV} </TdBudgeted>
                    <TdBudgeted> {calcTotalsReleases('EFE', 'DEZ').DEZ} </TdBudgeted>
                  </TrRelease>

                  {rowExpanded[10] && <>

                    <tr>
                      <td colSpan={2}> Efetivo  </td>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].ANT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].ORCADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].PLANEJADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].REALIZADO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].PREVISTO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].TOTAL)}</TdBudgeted>

                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].JAN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].FEV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].MAR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].ABR)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].MAI)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].JUN)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].JUL)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].AGO)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].SET)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].OUT)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].NOV)}</TdBudgeted>
                      <TdBudgeted>{maskToMoney3(dataLocalStorage[13].EFE[0].DEZ)}</TdBudgeted>
                    </tr>

                  </>}

                </tbody>

              </TableResume>


              <OverlayTrigger
                key={'left'}
                placement={'left'}
                trigger={['hover', 'hover']}
                overlay={
                  <TableToltip className="d-print-none">
                    {TextTooltip.EXPORT}
                  </TableToltip>
                }>
                <ContainerExportResume
                  backgroundColor={Colors?.excel}>
                  <BtnExportResume
                    table={'tabela'}
                    filename={`PFF_Resumo_${dataLocalStorage[1]?.CAB[0]?.CC}_${dataLocalStorage[1]?.CAB[0]?.ANO}`}
                    sheet={'sheet'}
                    buttonText={<IconExcel color={Colors?.white} size={35} />}
                  />
                </ContainerExportResume>

              </OverlayTrigger>

            </PffFilterTableResume>
          }
        </>
      )
      }
    </Formik >

  );
};



export default PFFDetailsScreen;
