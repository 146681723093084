import React,
{
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../common/constants';

import {
  TableDefaultStyled,
  TitleGroup,
} from '../../../common/styles/styled.layout';

import ApiWS from '../../../services/api.service';

import IButton from '../../Buttons/IButton';

import {
  IconSelect,
} from '../../Icons';

import LoadingsComponent from '../../Loadings';

import TextInput from '../../TextFields/Input';

import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalTableContainer,
  ModalFooterStyled,
  ModalFilterView,
} from '../styled';



interface IProps {
  show: boolean;
  onHide?: any;
  escolaridade: any;
}



const EscolaridadeModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listEscolaridade, setListEscolaridade] = useState([]);
  const [escolaridade, setEscolaridade] = useState('');



  const findEscolaridades = async (consulta: any) => {
    try {
      setLoading(true);

      const url = '/rp/nova_rp/consulta/escolaridade/';
      const response = await ApiWS().post(url, consulta);

      setListEscolaridade(response.data.sort());
    }
    catch (error) {
      console.log('findEscolaridade', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Escolaridade'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>
          <ModalFilterView>
            <TextInput
              className='filter-input-view'
              type={'text'}
              name={'input_escolaridade'}
              label={'Digite a Escolaridade'}
              placeholder={'Escolaridade'}
              onChange={(e: any) => {
                setEscolaridade(e.target.value);
              }}
              value={escolaridade}
            // onChange={handleChange}
            // value={values.user}
            />

            <IButton
              disabled={loading}
              backgroundColor={Colors?.accept}
              text={'Consultar'}
              onClick={() => {
                findEscolaridades({ 'consulta': escolaridade });
              }}
            />
          </ModalFilterView>



          <ModalTableContainer>
            {loading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}


            <TableDefaultStyled striped bordered hover>
              <thead>
                <tr>
                  <th>{'Descrição'.toUpperCase()}</th>
                  <th>{'Ação'.toUpperCase()}</th>
                </tr>
              </thead>

              <tbody>
                {
                  listEscolaridade.map((escol) => (
                    <tr>
                      <td>{escol.X5_DESCRI}</td>
                      <td onClick={() => {
                        props.escolaridade(escol);
                        props.onHide(false);
                      }}>
                        <IconSelect
                          color={Colors?.black}
                          size={16}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </TableDefaultStyled>
          </ModalTableContainer>

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default EscolaridadeModal;
