import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalTableContainer,
  ModalTableObsText,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS from '../../../../../services/api.service';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
}



const NotaFiscalModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [taxNotes, setTaxNotes] = useState([]);


  function fetchTaxNotes() {
    setLoading(true);

    ApiWS()
      .post('/suprimento/consulta/pedido/nf', props.values)
      .then((resp) => {
        setTaxNotes(resp.data);
      })
      .catch((error) => {
        console.error('fetchTaxNotes', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };



  useEffect(() => {
    fetchTaxNotes();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>
      <ModalContainerStyled>

        <TitleGroup>
          {'Notas Fiscais'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && (!taxNotes || taxNotes === null || taxNotes.length === 0) && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.PEDIDO}
              </ModalTableObsText>

              <NoItemsContent />

            </ModalTableContainer>
          )}


          {!loading && taxNotes && taxNotes.length > 0 && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.NUM}
              </ModalTableObsText>


              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: taxNotes.length,
                })}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity
                    data={taxNotes}
                    columns={[
                      {
                        dataField: 'DOC',
                        text: 'NF',
                        sort: true,
                        headerStyle: () => {
                          return { width: '9%' };
                        },
                      },
                      {
                        dataField: 'ITEMNF',
                        text: 'Item NF',
                        sort: true,
                        formatter: (_cell, row) => {
                          return ('00' + (taxNotes.indexOf(row)+1)).slice(-3);
                        },
                      },
                      {
                        dataField: 'VALMERC',
                        text: 'Valor',
                        sort: true,
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                      },
                      {
                        dataField: 'EMISSAO',
                        text: 'Data Emissão',
                        sort: true,
                      },
                      {
                        dataField: 'DTRECEB',
                        text: 'Dt. Recebimento',
                        sort: true,
                      },
                      {
                        dataField: 'DTLANC',
                        text: 'Dt. Lançamento',
                        sort: true,
                      },
                      {
                        dataField: 'DTVENCTO',
                        text: 'Dt. Vencimento',
                        sort: true,
                      },
                      {
                        dataField: 'DTPGTO',
                        text: 'Dt. Pagamento ',
                        sort: true,
                      },
                      {
                        dataField: 'PARCELA',
                        text: 'Parcela ',
                        sort: true,
                      },
                    ]}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>

            </ModalTableContainer>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default NotaFiscalModal;
