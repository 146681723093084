import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { Colors } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { ButtonAreaColStyled, PffUpdateMonthContainerForm } from '../PFF-realizado-styled';
import PffRealizadoValidationSchema from '../PFF-realizado-validation';
import { PffRealizadoModel } from '../PFF-realizado.model';



interface IProps {
  indexTab?: number;
}


const Tab_AtualizarMesRefScreen: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const listMonth = [
    { key: 0, label: 'Janeiro', value: 1 },
    { key: 1, label: 'Fevereiro', value: 2 },
    { key: 2, label: 'Março', value: 3 },
    { key: 3, label: 'Abril', value: 4 },
    { key: 4, label: 'Maio', value: 5 },
    { key: 5, label: 'Junho', value: 6 },
    { key: 6, label: 'Julho', value: 7 },
    { key: 7, label: 'Agosto', value: 8 },
    { key: 8, label: 'Setembro', value: 9 },
    { key: 9, label: 'Outubro', value: 10 },
    { key: 10, label: 'Novembro', value: 11 },
    { key: 11, label: 'Dezembro', value: 12 },
  ];


  function postUpdateMonth(values) {
    setLoading(true);

    const url = '/pff/lancamento/atualizamesref';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });
        }
        else if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffRealizadoValidationSchema}
      initialValues={{
        ...PffRealizadoModel,
        ANO: new Date(Date.now()).getFullYear(),
      }}
      onSubmit={(values) => {
        postUpdateMonth(values);
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (

        <ContainerView>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && (

            <SectionMargin>

              <PffUpdateMonthContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col>
                    <TextInput
                      type={'text'}
                      name={'ANO'}
                      label={'Ano'}
                      placeholder={'Ano'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ANO} />
                  </Col>



                  <Col>
                    <DropdownInput
                      name={'MES'}
                      label={'Mês Ref.'}
                      placeholder={'Selecione'}
                      dropArray={listMonth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.MES} />
                  </Col>


                  <ButtonAreaColStyled>

                    <IButton
                      disabled={loading}
                      text={'Atualizar'}
                      backgroundColor={Colors?.primary}
                      type={'button'}
                      onClick={() => {
                        if (values.ANO !== 0 && values.MES !== '') {
                          postUpdateMonth(values);
                        }
                      }}
                    />

                  </ButtonAreaColStyled>

                </Row>


              </PffUpdateMonthContainerForm>

            </SectionMargin>
          )}

        </ContainerView>
      )}

    </Formik>

  );
};



export default Tab_AtualizarMesRefScreen;
