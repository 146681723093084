import React from 'react';

import {
  Form,
} from 'react-bootstrap';

import {
  Field,
} from 'formik';


import {
  InputStyledLabelFloat, LabelFloatStyled,
} from './styled';



interface IProps {
  id?: string;
  type?: string;
  className?: string;

  // required?: boolean;
  disableFeedBackValid?: boolean;
  editable?: boolean;
  disabled?: boolean;
  textHeight?: number;

  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  min?: number | string;
  max?: number | string;

  showPass?: boolean;

  buttonIcon?: any;
  buttonClick?: any;

  onChange?: any;
  onBlur?: any;
  onFocus?: any;

  accept?: string;
  ref?: any;
  multiple?: boolean;

  onKeyUp?: any;

  height?: any;
  fontSize?: any;

  fontSizeLabel?: any;
}



const TextInputLabelFloat: React.FC<IProps> = (props) => {
  return (

    <Field
      disabled={!props.editable || props.disabled}
      name={props.name}
      render={({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (

          <Form.Floating>

            <InputStyledLabelFloat
              {...field}
              type={props.type}
              id={props.id}
              disabled={props.disabled}
              readOnly={!props.editable || props.disabled}
              as={props.textHeight && 'textarea'}
              textHeight={props.textHeight}
              name={props.name}
              placeholder={props.placeholder}
              minLength={props.min}
              maxLength={props.max}
              value={props.value}
              onChange={props.onChange}
              onBlur={props.onBlur}
              showPass={props.showPass}
              isValid={!props.disableFeedBackValid && (!props.disabled && form.touched[field.name] && isValid)}
              isInvalid={!props.disabled && isInvalid}
              feedback={!props.disabled && form.errors[field.name]}
              min={props.min}
              max={props.max}
              accept={props.accept}
              ref={props.ref}
              multiple={props.multiple}
              onFocus={props.onFocus}
              onKeyUp={props.onKeyUp}
              height={props.height}
              fontSizeLabel={props.fontSizeLabel}
              fontSize={props.fontSize}
            />

            <LabelFloatStyled fontSizeLabel={props.fontSizeLabel} height={undefined} fontSize={props.fontSizeLabel}>{props.label}</LabelFloatStyled>

          </Form.Floating>

        );
      }}
    />
  );
};



TextInputLabelFloat.defaultProps = {
  editable: true,
  disabled: false,
  type: 'text',
};



export default TextInputLabelFloat;
