import styled from 'styled-components';

import {
  Colors,
} from '../../common/constants';
import { intranet_config } from '../../utils/Config';



export const HomeLogoContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 30px 0;
  background-image: url("${intranet_config?.Images.background_home}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  z-index: 1;
`;

export const ImageViewLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  padding: 20px;
  background-color: white;
  border-radius: 15%;
`;

export const ImageLogo = styled.img`
  object-fit: contain;
  max-width: 180px !important;
  max-height: 180px !important;
  min-width: 180px !important;
  min-height: 180px !important;

`;


export const UserViewContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const UserViewButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 220px;
  padding: 0 5px;
  background-color: #FFFFFF;
  border-left-color: ${Colors?.primary};
  border-left-style: solid;
  border-left-width: 2px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  :hover {
    p,
    svg {
      transition: fill .15s ease-in-out;
      color: ${Colors?.primary} !important;
      fill: ${Colors?.primary} !important;
    }
  }
`;

export const UserTextButton = styled.p`
  display: flex;
  flex: 1;
  margin: 0 0 0 10px;
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
`;

export const DashboardAutoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0;
  align-items: center;
`;

export const DashboardCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
`;
