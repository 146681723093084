import React from 'react';

import {
  Spinner,
} from 'react-bootstrap';

import {
  LoadingContainer,
  AreaText,
  AreaIcon,
} from './styled';



export interface IProps {
  color?: string;
  type?: string; // FOOTER, SCREEN, FIELD
  size?: string;
  text?: string;
  fontSize?: string;
}



const LoadingsComponent: React.FC<IProps> = (props) => {
  return (

    <LoadingContainer
      size={props.size || '4rem'}
      type={props.type}
      color={props.color}>

      <AreaIcon>
        <Spinner
          animation={'grow'}
          variant={'secondary'}
          style={{ width: props.size || '1em', height: props.size || '1em' }}
        />

        <Spinner
          animation={'grow'}
          variant={'secondary'}
          style={{ width: props.size || '1em', height: props.size || '1em', marginLeft: '1em' }}
        />

        <Spinner
          animation={'grow'}
          variant={'secondary'}
          style={{ width: props.size || '1em', height: props.size || '1em', marginLeft: '1em' }}
        />
      </AreaIcon>


      {props.type !== 'FIELD' &&
        <AreaText>
          Aguarde, Carregando.
        </AreaText>
      }


      {props.type === 'FIELD' &&
        <AreaText>
          {props.text}
        </AreaText>
      }

    </LoadingContainer>

  );
};



LoadingsComponent.defaultProps = {
  color: 'orange',
  type: 'FOOTER',
};



export default LoadingsComponent;
