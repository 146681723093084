import * as Yup from 'yup';



const PffLancamentoInicioSchema = Yup.object().shape({


});



export default PffLancamentoInicioSchema;
