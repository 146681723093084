import * as Yup from 'yup';
import { Metrics, Messages } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';


const PcbManutencaoFilialValidationSchema = Yup.object().shape({

  input_pcbm: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_pedido, Messages.input_pedido)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_filial: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_filial_nova: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),


});



export default PcbManutencaoFilialValidationSchema;
