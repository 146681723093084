import React,
{
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../common/constants';

import {
  TableDefaultStyled,
  TitleGroup,
} from '../../../common/styles/styled.layout';

import ApiWS from '../../../services/api.service';

import IButton from '../../Buttons/IButton';

import {
  IconSelect,
} from '../../Icons';

import LoadingsComponent from '../../Loadings';

import TextInput from '../../TextFields/Input';

import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalTableContainer,
  ModalFooterStyled,
  ModalFilterView,
} from '../styled';



interface IProps {
  show: boolean;
  onHide?: any;
  funcao: any;
}



const FuncaoModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [funcao, setFuncao] = useState('');
  const [listFuncao, setListFuncao] = useState([]);



  const findFuncoes = async (consulta: any) => {
    try {
      setLoading(true);

      const url = '/rp/nova_rp/consulta/funcao/';
      const response = await ApiWS().post(url, consulta);

      setListFuncao(response.data.sort());
    }
    catch (error) {
      console.log('findOS', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }
  };


  async function findCargo(consulta: any) {
    let newArray = [];
    try {
      // setLoading(true);

      const url = '/rp/nova_rp/consulta/cargo/';
      const response = await ApiWS().post(url, consulta);

      newArray = response.data;
    }
    catch (error) {
      console.log('findCargo', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }

    return newArray;
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Função'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>
          <ModalFilterView>
            <TextInput
              className='filter-input-view'
              type={'text'}
              name={'funcao'}
              label={'Digite a Função'}
              placeholder={'Função'}
              onChange={(e: any) => {
                setFuncao(e.target.value);
              }}
              value={funcao}
            // onChange={handleChange}
            // value={values.user}
            />

            <IButton
              disabled={disable}
              backgroundColor={Colors?.accept}
              text={'Consultar'}
              onClick={() => {
                findFuncoes({ 'consulta': funcao });
              }}
            />
          </ModalFilterView>



          <ModalTableContainer>
            {loading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}


            <TableDefaultStyled>
              <thead className={disable && 'thead-two'}>
                <tr>
                  <th>{'Descrição'.toUpperCase()}</th>
                  <th>{'Ação'.toUpperCase()}</th>
                </tr>
              </thead>


              <tbody className={disable && 'tbody-two'}>
                {listFuncao.map((funcaoItem) => (
                  <tr>
                    <td>{funcaoItem.RJ_DESC}</td>
                    <td onClick={async () => {
                      if (!disable) {
                        setDisable(true);
                        const consultaCargo = await findCargo({ consulta: funcaoItem.RJ_FUNCAO });

                        props.funcao({
                          funcao: funcaoItem,
                          cargo: consultaCargo,
                        });

                        props.onHide();

                        setTimeout(() => {
                          setDisable(false);
                        }, 300);
                      }
                    }}>
                      <IconSelect
                        color={disable ? Colors?.gray : Colors?.black}
                        size={16}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableDefaultStyled>
          </ModalTableContainer>

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default FuncaoModal;
