import styled from 'styled-components';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Form } from 'formik';
import { Colors, Metrics } from '../../../../../../common/constants';
import { TabIconStyled, TabsStyled, TabTextStyled, TabViewStyled } from '../../../../../../common/styles/styled.layout';


interface IProps {
  loading?: boolean;
  backgroundColor?: string;
  valueColorBorder?: number;
  titleAlign?: string;
  titleSize?: number;
  auto?: boolean;
}


export const PffUpdateMonthContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  ${(props: IProps) => !props.auto && 'min-height: 100px'};
  /* max-width: 60em; */
  margin-top: 2em;
  padding: 30px 30px 30px 95px ;
  background-color: ${Colors?.formBackground};
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;

export const TitleGroup = styled.h1`
  padding: 25px 0 0 2em;
  color: ${Colors?.primary};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  line-height: 2rem;
  letter-spacing: -.04rem;
  border-bottom: 1px solid ${Colors?.primary};
`;

export const TitleInterGroup = styled(Col)`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 0.2em;

    h1 {
      margin-bottom: 9.1px;
      color: #c90406;
      font-size: 1rem;
      font-weight: 600;
      text-align: ${(props: IProps) => props.titleAlign || 'center'};
      letter-spacing: -0.04rem;
      border-bottom: 1px solid #c90406;
      width: ${(props: IProps) => props.titleSize || '100'}%;
    }
`;

export const TitleSubSetAccProvider = styled.h1`
  font-size: 12px;
  margin: 1.5em 1em auto 1.5em;
  color: #7A7A7A;
`;

export const HeaderRelease = styled(Row)`
  margin-bottom: 1em;
  width: 100%;

  --bs-gutter-x: 0;
`;

export const FieldsHaderStyle = styled(Col)`
  font-size: 12px;
  padding: 0 0.2em;
`;

export const HeaderReleaseColLeft = styled(Col)`
  margin-top: 15px;
  margin-bottom: -30px;
  align-content: bottom;
`;

export const FieldMarginHeader = styled(Col)`
  margin-left: 10px;
  border-radius: 3px;
  box-shadow:  5px 6px 0px 0px ${(props: IProps) =>
    props.valueColorBorder === 0 && '#D2D2D2' ||
    props.valueColorBorder > 0 && '#04C906' ||
    props.valueColorBorder < 0 && '#990406'};
  background-color: ${Colors?.formBackground};
  border: solid 1px #D2D2D2;
  padding: 1em 1em;
  font-size: 3vh;
  font-weight: bold;
  width: auto;
`;

export const HeaderReleaseColRightOrUp = styled(Col)`
  margin-top: 0.5em;
  background-color: ${Colors?.formBackground};
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.125);
`;

export const HeaderReleaseColDow = styled(HeaderReleaseColRightOrUp)`

`;

export const RowFieldsGrid = styled(Row)`
  --bs-gutter-x: 0;
`;

export const ColFieldsGrid = styled(Col)`
  --bs-gutter-x: 0;
  display: flex;
`;


export const FieldAreaRowStyled = styled(Row)`
  padding-bottom: 0.5em;
`;


export const ButtonAreaColStyled = styled(Col)`
  margin-top: 0.65em;
  margin-left: 2em;
`;

export const ButtonAreaDraftColStyled = styled(Col)`
  padding-top: 1vh;
`;


export const ButtonSend = styled(Dropdown.Toggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: ${Metrics.inputHeight}px;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
  color: white !important;
  font-size: 1.125rem;
  border: none;
  border-radius: ${Metrics.inputRadius}px !important;
  z-index: 2 !important;

  :hover,
  :active,
  :focus {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
    box-shadow: none !important;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    pointer-events: none;
    opacity: .65;
  }

  span {
    margin: 0 8px;
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  :active {
    background-color: ${Colors?.primaryDark};
  }
`;

export const PFFTabsStyled = styled(TabsStyled)`
  /* padding: 0; */
  /* margin-top: 2px; */
`;

export const PFFTabViewStyled = styled(TabViewStyled)`
  padding: 0;
`;



export const PFFTabIconStyled = styled(TabIconStyled)`
  padding: 0;
  margin-right: 0;
`;


export const PFFTabTextStyled = styled(TabTextStyled)`
  padding: 0;

`;

export const RowButtonsInteration = styled(Row)`
  border-top: solid 1px ${Colors?.gray};
  padding-top: 1em;
  margin-bottom: 1em;
  justify-content: center!important;
`;


export const AreaText = styled.div`
  text-align: center;
  margin-top: 15px;
  font-size: 26px;
  font-weight: bold;
  color: ${Colors?.textColor};
`;

export const RowTimer = styled(Row)`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const TimerStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  margin-top: 10px;
  color: ${Colors?.textActiveColor};
  box-shadow: 1px 1px 10px ${Colors?.gray};
  text-align: center;
  font-size: 25px;
  font-weight: bold;
`;
