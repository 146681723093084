import styled from 'styled-components';



export const FilterSMEPendentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const FilterView = styled.div`
  display: flex;
  flex: 1;
`;
