import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors, Images, Metrics,
} from '../../../../../../../common/constants';

import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import {
  FilterContainerForm,
} from '../../../../../../../common/styles/styled.layout';


import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';


import EmptyContent from '../../../../../../../components/Empty';
import { IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';



import ApiWS from '../../../../../../../services/api.service';


import {
  PcbAnexoRowButtonFilterView,
} from '../tabs.styled';
import PcbAnexosManutencaoModel from './PBC-tab_manutencao.model';
import PcbAnexosManutencaoValidationSchema from './PBC-tab_manutencao.validation';


const Tab_AttachedSettingsScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [attachedList, setAttachedList] = useState([]);
  const [showList, setShowList] = useState(false);

  const [emptyObject] = useState({
    image: Images.empty,
    title: 'Sem Anexos!',
    description: 'Não há nenhum anexo aqui.',
  });


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  async function postRequestAttachedFetch(values: any, page_iteration: any): Promise<void> {
    setLoading(true);
    setShowList(false);
    setPage(1);

    const url = '/suprimento/anexo/historico';

    values = {
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_PBCRequests,
    };

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          setAttachedList(resp.data[0]);
          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar anexos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar anexos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  async function postContractAttachedFetch(values: any, page_iteration: any): Promise<void> {
    console.log(values);
    setLoading(true);
    setShowList(false);

    const url = '/suprimento/anexo/historico';
    let payload = {};

    if (values?.CONTRATO?.length === 15) {
      payload = {
        FILIAL: values.FILIAL,
        NUM: `${values.CONTRATO.slice(9, values.CONTRATO.length)}/${values.PLANILHA.slice(3, values.PLANILHA.length)}R${values.REVISAO}`,
        TIPO: 'CT',
      };
    }
    else {
      payload = { ...values, TIPO: 'CT' };
    };


    payload = {
      ...payload,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_PBCRequests,
    };

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setAttachedList(resp.data[0]);
          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          toast.error('Erro ao consultar anexos. Contate o suporte!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else {
          toast.error(`Erro ao consultar anexos, contate o suporte! ${error.response.status}`, {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  async function getRemoveAttached(values: any) {
    setLoading(true);
    setShowList(false);

    ApiWS()
      .get(`/suprimento/anexo/remover/${values.ID}`)
      .then(() => {
        if (values.TIPO !== 'CT') {
          postRequestAttachedFetch(values, page);
        }
        else {
          postContractAttachedFetch(values, page);
        }


        IToast({
          type: 'success',
          message: 'Registro removido com sucesso!',
        });
      })

      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Erro ao remover anexo! Por favor, contate o suporte.',
        });

        console.log('postFetchAttached', JSON.stringify(err));
      })

      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };



  function renderFieldDropArrayType() {
    return [
      {
        key: '0',
        label: 'Selecione...',
        value: 'SELECIONE',
      },
      {
        key: '2',
        label: 'PC',
        value: 'PC',
      },
      {
        key: '3',
        label: 'BM',
        value: 'BM',
      },
      {
        key: '4',
        label: 'Contrato',
        value: 'CONTRATO',
      },
      {
        key: '5',
        label: 'Contrato de Parceria',
        value: 'CP',
      },
      {
        key: '6',
        label: 'Autorização de Entrega',
        value: 'AE',
      },
    ];
  };


  function renderBtnRemoveAttached(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.REMOVE}
        icon={
          <IconTrash size={14} color={Colors?.black} />
        }
        onClick={() => {
          getRemoveAttached(row);
        }}
      />
    );
  };


  async function handlePagination(type: number, documentType: String) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    if (documentType === 'CONTRATOS'){
      postContractAttachedFetch(valuesPayload, page_var);
    }
    else {
      postRequestAttachedFetch(valuesPayload, page_var);
    }

    setPage(page_var);
  };


  const columns = [
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'NUM',
      text: 'Número',
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descricao',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'ARQUIVO',
      text: 'Arquivo',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: 'COMPRADOR',
      text: 'Comprador',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: 'DTCAD',
      text: 'Data Cadast.',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: '',
      text: 'Remover',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (_cell, row): JSX.Element => {
        return renderBtnRemoveAttached(_cell, row);
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: attachedList.length,
  };



  return (
    <ToolkitProvider
      keyField={'id'}
      data={attachedList}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.PIC_Cadastro_Area,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (

        <>
          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PcbAnexosManutencaoValidationSchema}
            initialValues={PcbAnexosManutencaoModel}
            onSubmit={(values) => {
              setValuesPayload(values);
              if (values.TIPO !== 'CONTRATO') {
                postRequestAttachedFetch(values, 1);
              }
              else {
                postContractAttachedFetch(values, 1);
              }
            }}>
            {({
              values, resetForm, handleSubmit, handleChange, handleBlur, setValues,
            }) => (

              <>

                {loading &&
                  <LoadingsComponent />
                }


                <FilterContainerForm auto onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <TextInput
                        type={'text'}
                        name={'FILIAL'}
                        label={'Filial'}
                        placeholder={'Filial'}
                        value={values.FILIAL || ''}
                        onChange={handleChange}
                        onBlur={handleBlur} />


                      <DropdownInput
                        name={'TIPO'}
                        label={'Tipo'}
                        dropArray={renderFieldDropArrayType()}
                        onChange={handleChange}
                        value={values.TIPO} />


                    </Col>



                    <Col>
                      <TextInput
                        disabled={(values.TIPO === 'CONTRATO')}
                        type={'text'}
                        name={'NUM'}
                        label={'PC/BM'}

                        placeholder={values.TIPO !== 'CONTRATO'
                          ? 'PC/BM'
                          : 'Somente para Tipo: "PC ou BM"'}

                        value={values.TIPO !== 'CONTRATO'
                          ? values.NUM || ''
                          : ''}

                        onChange={handleChange}
                        onBlur={handleBlur} />


                      <TextInput
                        disabled={!(values.TIPO === 'CONTRATO')}
                        type={'text'}
                        name={'CONTRATO'}
                        label={'Contrato'}

                        placeholder={values.TIPO === 'CONTRATO'
                          ? 'Contrato'
                          : 'Somente para Tipo: "Contrato"'}

                        value={values.TIPO === 'CONTRATO'
                          ? values.CONTRATO || ''
                          : ''}

                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </Col>



                    <Col>
                      <TextInput
                        disabled={!(values.TIPO === 'CONTRATO')}
                        type={'text'}
                        name={'PLANILHA'}
                        label={'Planilha'}

                        placeholder={values.TIPO === 'CONTRATO'
                          ? 'Planilha'
                          : 'Somente para Tipo: "Contrato"'}

                        value={values.TIPO === 'CONTRATO'
                          ? values.PLANILHA || ''
                          : ''}

                        onChange={handleChange}
                        onBlur={handleBlur} />


                      <TextInput
                        disabled={!(values.TIPO === 'CONTRATO')}
                        type={'text'}
                        name={'REVISAO'}
                        label={'Revisão'}

                        placeholder={values.TIPO === 'CONTRATO'
                          ? 'Revisão'
                          : 'Somente para Tipo: "Contrato"'}

                        value={values.TIPO === 'CONTRATO'
                          ? values.REVISAO || ''
                          : ''}

                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </Col>

                  </Row>



                  <PcbAnexoRowButtonFilterView>
                    <IButton
                      text={'Limpar'}
                      backgroundColor={Colors?.gray}
                      type={'reset'}
                      onClick={() => {
                        resetForm();

                        setValues(PcbAnexosManutencaoModel);
                      }} />

                    <IButton
                      disabled={loading}
                      text={'Consultar'}
                      backgroundColor={Colors?.primary}
                      type={'submit'} />

                  </PcbAnexoRowButtonFilterView>

                </FilterContainerForm>

              </>
            )}
          </Formik>


          {!loading && attachedList && attachedList.length > 0 && showList && (
            <PaginationProvider
              pagination={paginationFactory(options)}>
              {({
                paginationProps, paginationTableProps,
              }) => (
                <TableCuston
                  noQuantity={totalPage === 1}
                  data={attachedList}
                  columns={columns}
                  baseProps={propsTkProvider.baseProps}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                  actionPrevious={() => handlePagination(0, valuesPayload?.TIPO)}
                  actionNext={() => handlePagination(1, valuesPayload?.TIPO)}
                  currentPage={page}
                  totalPage={totalPage}
                  totalResults={totalResults}
                />
              )}
            </PaginationProvider>
          )}


          {!loading && attachedList.length <= 0 && showList &&
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          }

        </>

      )}

    </ToolkitProvider>

  );
};



export default Tab_AttachedSettingsScreen;
