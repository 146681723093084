import React from 'react';
import logo from '../../assets/logos/logo_psuite.png';

import {
  Spinner,
} from 'react-bootstrap';
import { intranet_config } from '../../utils/Config';

import {
  LoadingContainer,
  AreaText,
  AreaIcon,
} from './styled';

export interface IProps {
  color?: string;
  type?: string; // FOOTER, SCREEN, FIELD
  size?: string;
  text?: string;
  fontSize?: string;
}

const LoadingIniComponent: React.FC<IProps> = (props) => {
  return (

    <LoadingContainer
      size={props.size || '4rem'}
      type={props.type}
      color={props.color}>

      <AreaIcon>
        <img src={logo}/>
      </AreaIcon>


      {props.type !== 'FIELD' &&
        <AreaText>
          Por favor aguarde, estamos carregando tudo para você!
        </AreaText>
      }


      {props.type === 'FIELD' &&
        <AreaText>
          {props.text}
        </AreaText>
      }

    </LoadingContainer>

  );
};



LoadingIniComponent.defaultProps = {
  color: 'orange',
  type: 'FOOTER',
};



export default LoadingIniComponent;
