import NameRoutes from '../name';



export const GI_NavRoutes = [
  {
    key: 1,
    label: 'Administração',
    path: `/${NameRoutes.GI_Administracao}`,
  },
  {
    key: 2,
    label: 'Consulta',
    path: `/${NameRoutes.GI_Consulta}`,
  },
];
