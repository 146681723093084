import * as Yup from 'yup';



const SMEGrupoValidationSchema = Yup.object().shape({
  
  CODIGO: Yup.string(),
  DESCRICAO: Yup.string(),

});



export default SMEGrupoValidationSchema;
