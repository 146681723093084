import * as Yup from 'yup';



const PffGridValidationSchema = Yup.object().shape({

  ANO: Yup.string()
    .required('Obrigatório'),

  NEGOCIO: Yup.string()
    .required('Obrigatório'),

  REV: Yup.string()
    .required('Obrigatório'),
  
  INICIO: Yup.string()
    .required('Obrigatório'),
  
  PRAZO: Yup.string()
    .required('Obrigatório'),
  
  // TAXA_ISS: Yup.string()
  //   .required('Obrigatório'),
  
  // TAXA_PIS_COFINS: Yup.string()
  //   .required('Obrigatório'),
  
  // TAXA_ICMS_IPI: Yup.string()
  //   .required('Obrigatório'),
  
  // TAXA_OUTROS: Yup.string()
  //   .required('Obrigatório'),
  
  VALOR_ORIGINAL: Yup.string()
    .required('Obrigatório'),
  
  VALOR_VIGENTE: Yup.string()
    .required('Obrigatório'),

});



export default PffGridValidationSchema;
