import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';


const PcbManutencaoResiduoValidationSchema = Yup.object().shape({

  input_pc: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_pedido, Messages.input_pedido)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_filial: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

});



export default PcbManutencaoResiduoValidationSchema;
