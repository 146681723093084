import React from 'react';
import { Row } from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import { ColDescr_D, ColDetours_D, ColItems_D, ColLeader_D, ColObsPers_D } from './styled';


interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}


const PICSectionD: React.FC<IProps> = (props) => {
  return (

    <div>
      <TitleGroup>
        {'D - Procedimento de SMS'.toUpperCase()}
      </TitleGroup>


      <Row>
        <ColItems_D>Itens</ColItems_D>
        <ColLeader_D><span>Líder</span></ColLeader_D>
        <ColDescr_D><span>Descrição</span></ColDescr_D>
        <ColObsPers_D><span>Pess. Observ.</span></ColObsPers_D>
        <ColDetours_D><span>Desvios</span></ColDetours_D>
      </Row>


      <Row>

        <ColItems_D>
          <TextInput
            disabled
            type={'text'}
            name={'D1'}
            label={'D1'}
            onBlur={props.handleBlur}
            value={'Procedimento Errado'}
          />
        </ColItems_D>


        <ColLeader_D>
          <DropdownInput
            name={'D1_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('D1_NPESSOA','');
                props.setFieldValue('D1_NDESVIO','');
              }
              props.setFieldValue('D1_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('D1_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('D1_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.D1_LIDER}
          />
        </ColLeader_D>


        <ColDescr_D>
          <TextInput
            disabled
            type={'text'}
            name={'D1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D1_DESCR}
          />
        </ColDescr_D>
        
        
        <ColObsPers_D>
          <TextInput
            disabled={
              props.values.D1_LIDER === ''
              || props.values.D1_LIDER === undefined
              || props.values.D1_LIDER === null
            }
            type={'number'}
            name={'D1_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D1_NPESSOA}
          />
        </ColObsPers_D>


        <ColDetours_D>
          <TextInput
            disabled={
              props.values.D1_LIDER === ''
              || props.values.D1_LIDER === undefined
              || props.values.D1_LIDER === null
            }
            type={'number'}
            name={'D1_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D1_NDESVIO}
          />
        </ColDetours_D>

      </Row>


      <Row>

        <ColItems_D>
          <TextInput
            disabled
            type={'text'}
            name={'D2'}
            label={'D2'}
            onBlur={props.handleBlur}
            value={'Não há procedimento escrito'}
          />
        </ColItems_D>


        <ColLeader_D>
          <DropdownInput
            name={'D2_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('D2_NPESSOA','');
                props.setFieldValue('D2_NDESVIO','');
              }
              props.setFieldValue('D2_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('D2_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('D2_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.D2_LIDER}
          />
        </ColLeader_D>


        <ColDescr_D>
          <TextInput
            disabled
            type={'text'}
            name={'D2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D2_DESCR}
          />
        </ColDescr_D>


        <ColObsPers_D>
          <TextInput
            disabled={
              props.values.D2_LIDER === ''
              || props.values.D2_LIDER === undefined
              || props.values.D2_LIDER === null
            }
            type={'number'}
            name={'D2_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D2_NPESSOA}
          />
        </ColObsPers_D>


        <ColDetours_D>
          <TextInput
            disabled={
              props.values.D2_LIDER === ''
              || props.values.D2_LIDER === undefined
              || props.values.D2_LIDER === null
            }
            type={'number'}
            name={'D2_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.D2_NDESVIO}
          />
        </ColDetours_D>

      </Row>

    </div>

  );
};


export default PICSectionD;
