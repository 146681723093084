import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../common/constants';

import {
  ObsTitleGroup,
  TitleGroup,
} from '../../common/styles/styled.layout';

import IButton from '../Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from './styled';



interface IProps {
  show: boolean;

  head?: string;
  orient?: string;
  orient2?: string;

  others?: any;

  buttonDisabled?: boolean;
  positive?: any;
  negative?: any;
}



const ConfirmationModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.head.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>
          {props.orient && (
            <ObsTitleGroup>
              {props.orient}
            </ObsTitleGroup>
          )}

          {props.orient2 && (
            <ObsTitleGroup>
              {props.orient2}
            </ObsTitleGroup>
          )}

          {props.others}

        </ModalBodyStyled>



        <ModalFooterStyled>
          {props.positive && (
            <IButton
              disabled={props.buttonDisabled}
              backgroundColor={Colors?.accept}
              text={'Sim'}
              onClick={props.positive}
            />
          )}


          {props.negative && (
            <IButton
              disabled={props.buttonDisabled}
              backgroundColor={Colors?.primary}
              text={'Fechar'}
              onClick={props.negative}
            />
          )}
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default ConfirmationModal;
