import * as Yup from 'yup';



const GIGrupoValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),

});



export default GIGrupoValidationSchema;
