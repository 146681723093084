import React,
{
  useState,
  useEffect,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';


import {
  Colors,
  Images,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  Content,
  ContentStyled,
  Screen,
  SectionMargin,
  TableActionNextCss,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import LinkTableAction from '../../../../../components/Buttons/LinkTableAction';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconClipboard,
  // IconFolderPlus,
  IconListNew,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';

import NameRoutes from '../../../../../navigation/name';

import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS from '../../../../../services/api.service';


import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import SMEModeloModal from '../Modals/ModeloModal';
import SMECopiaModal from '../Modals/SME_Copia_Modal';



const SME_SolicitacaoScreen: React.FC = () => {
  const [carregando, setCarregando] = useState(false);
  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s aqui!',
    description: 'Não há nenhuma SME para você',
  });

  const [osPermitidas, setOsPermistidas] = useState([]);

  const [copiaModal, setCopiaModal] = useState(false);
  const [SMECopiaValue, setSMECopiaValue] = useState(null);

  const [modeloModal, setModeloModal] = useState(false);
  const [SMEModeloValue, setSMEModeloValue] = useState(null);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);


  async function findOsPermitidas(page_iteration) {
    setCarregando(true);

    const values = {
      TAMANHO: 10,
      PAGINA: page_iteration,
    };

    const url = '/sm/nova_sm/consulta/os';
    ApiWS()
      .post(url, values)
      .then((res) => {
        setOsPermistidas(res.data[0]);
        setTotalResults(res.data[1][0].TOTAL);
        setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
      })
      .catch((error) => {
        console.error('findOsPermitidas', JSON.stringify(error.response, null, 2));
        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      })
      .finally(() => setCarregando(false));
  };


  async function handlePagination(type: number) {
    let page_var = page;

    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    findOsPermitidas(page_var);
    setPage(page_var);
  }


  useEffect(() => {
    findOsPermitidas(1);
  }, []);



  const columns = [
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Nova SME
        return (
          <LinkTableAction
            key={'right'}
            placement={'right'}
            tooltip={TextTooltip.SME_NEW}
            to={{
              pathname: `/${NameRoutes.SME_Nova}`,
              state: {
                tipo: 'NOVA',
                solicitante: row.usu_nm_usuario,
                os: row.cco_vl_centro_custo,
                setor_operacao: row.cco_ds_centro_custo,
                filial: row.cem_ds_filial,
                funcaoSolicitante: row.fun_ds_funcao,
                sme: null,
              },
            }}
            icon={
              <IconListNew
                color={Colors?.quaternary}
                size={16}
              />
            }
            backgroundColor={Colors?.quaternary}
          />
        );
      },
    },


    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Modal Copia SME
        return (
          <ButtonTableAction
            key={'right'}
            placement={'right'}
            tooltip={TextTooltip.SME_COPY}
            onClick={() => {
              setSMECopiaValue({
                usu_nm_usuario: row.usu_nm_usuario,
                cco_vl_centro_custo: row.cco_vl_centro_custo,
                cco_ds_centro_custo: row.cco_ds_centro_custo,
                cem_ds_filial: row.cem_ds_filial,
                fun_ds_funcao: row.fun_ds_funcao,
              });
              setCopiaModal(true);
            }}
            icon={
              <IconClipboard
                color={Colors?.tertiary}
                size={16}
              />
            }
            backgroundColor={Colors?.tertiary}
          />
        );
      },
    },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Modal Modelo SME
    //     return (
    //       <TableActionButton
    //         onClick={() => {
    //           setSMEModeloValue({
    //             usu_nm_usuario: row.usu_nm_usuario,
    //             cco_vl_centro_custo: row.cco_vl_centro_custo,
    //             cco_ds_centro_custo: row.cco_ds_centro_custo,
    //             filial: row.cem_ds_filial,
    //             fun_ds_funcao: row.fun_ds_funcao,
    //           });
    //           setModeloModal(true);
    //         }}>
    //         <IconFolderPlus
    //           color={Colors?.black}
    //           size={16}
    //         />
    //       </TableActionButton>
    //     );
    //   },
    // },
    {
      dataField: 'cem_ds_filial',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
    },
    {
      dataField: 'cco_vl_centro_custo',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'cco_ds_centro_custo',
      text: 'Descrição do CC',
      sort: true,
    },
    {
      dataField: 'usu_nm_usuario',
      text: 'Solicitante',
      sort: true,
    },
    {
      dataField: 'fun_ds_funcao',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: osPermitidas.length,
  };



  return (

    <Screen>

      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={2}
          navArray={SME_NavRoutes}
          title={'SME'}
        />
      </NavHeader>



      <Content>

        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!carregando && osPermitidas.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}


          {!carregando && osPermitidas.length > 0 && (
            <SectionMargin>
              <TitleGroup>
                {'Solicitações SME'.toUpperCase()}
              </TitleGroup>


              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={osPermitidas}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>
            </SectionMargin>
          )}

        </ContentStyled>
      </Content>


      {SMECopiaValue && (
        <SMECopiaModal
          show={copiaModal}
          dadosParaSM={SMECopiaValue}
          onHide={() => {
            setCopiaModal(false);
            setTimeout(() => {
              setSMECopiaValue(null);
            }, 300);
          }}
        />
      )}


      {SMEModeloValue && (
        <SMEModeloModal
          show={modeloModal}
          dadosParaSM={SMEModeloValue}
          onHide={() => {
            setModeloModal(false);
            setTimeout(() => {
              setSMEModeloValue(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default SME_SolicitacaoScreen;
