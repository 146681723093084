import styled from 'styled-components';

import {
  Screen,
} from '../../../../../common/styles/styled.layout';


export const PrinterContainer = styled(Screen)`
`;

export const A4LandscapeContent = styled.div`
`;
