// Backgrounds
import _backgroundAvatar from '../../assets/backgrounds/background-avatar.png';
import _backgroundHome from '../../assets/backgrounds/background-home.jpg';
import _backgroundLine from '../../assets/backgrounds/background-lines.jpg';
import _backgroundLogin from '../../assets/backgrounds/background-login.jpg';

// Success
import _check from '../../assets/images/check.png';

// Errors
import _error401 from '../../assets/images/error_access.png';
import _other500 from '../../assets/images/error_server.png';


// PAV
import _focoImg from '../../assets/images/PAV/foco.png';
import _liderancaImg from '../../assets/images/PAV/lideranca.png';
import _organizacaoImg from '../../assets/images/PAV/organizacao.png';
import _protagonismoImg from '../../assets/images/PAV/protagonismo.png';
import _relacionamentoImg from '../../assets/images/PAV/relacionamento.png';


// Logos
import _logoIconApp from '../../assets/logos/icon.png';
import _logoFullNegativeApp from '../../assets/logos/logo-negative.png';
import _logoFullApp from '../../assets/logos/logo.png';
import { intranet_config } from '../../utils/Config';


export const Backgrounds = {
  backgroundAvatar:                 _backgroundAvatar,
  backgroundHome:                   _backgroundHome,
  backgroundLogin:                  _backgroundLogin,
  backgroundLine:                   _backgroundLine,
};



export const Errors = {
  empty:                            intranet_config?.Images?.empty,
  error401:                         _error401,
  other500:                         _other500,
};



export const Success = {
  check:                            _check,
};



export const Logos = {
  logoIconApp:                      _logoIconApp,
  logoFullApp:                      _logoFullApp,
  logoFullNegativeApp:              _logoFullNegativeApp,
};

export const ModulePAV = {
  protagonismoImg:                  _protagonismoImg,
  relacionamentoImg:                _relacionamentoImg,
  liderancaImg:                     _liderancaImg,
  focoImg:                          _focoImg,
  organizacaoImg:                   _organizacaoImg,
};



const Images = {
  ...Backgrounds,
  ...Errors,
  ...Logos,
  ...Success,
  ...ModulePAV,
};



export default Images;
