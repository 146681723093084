import React from 'react';



import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToFloat, maskToMoney, maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, RowFieldsGrid, TitleInterGroup } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffHhTerceirosModel } from '../PFF-grid.model';



interface IProps {
  indexTab?: number;
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_HhTerceirosScreen: React.FC<IProps> = (props) => {
  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[10].MOT;
    let total = 0.00;

    // ORIGINAL
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      model = {
        ...model,
        HH_TERCEIROS: { ORCADO: maskToMoney(`${data[0].ORCADO}`) },
      };

      data.forEach((value) => {
        total = total + parseFloat(value.ORCADO);
      });

      model = {
        ...model,
        TOTAL_HH_TERCEIROS: { ORCADO: maskToMoney2(total) },
      };
    }


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      model = {
        ...model,
        HH_TERCEIROS: { PLANEJADO: maskToMoney(`${data[0].PLANEJADO}`) },
      };

      data.forEach((value) => {
        total = total + parseFloat(value.PLANEJADO);
      });

      model = {
        ...model,
        TOTAL_HH_TERCEIROS: { PLANEJADO: maskToMoney2(total) },
      };
    }


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sum_JAN = 0.00;
      let sum_FEV = 0.00;
      let sum_MAR = 0.00;
      let sum_ABR = 0.00;
      let sum_MAI = 0.00;
      let sum_JUN = 0.00;
      let sum_JUL = 0.00;
      let sum_AGO = 0.00;
      let sum_SET = 0.00;
      let sum_OUT = 0.00;
      let sum_NOV = 0.00;
      let sum_DEZ = 0.00;


      data.forEach((account) => {
        if (account.JAN && account.JAN.indexOf(',') !== -1) {
          account.JAN = account.JAN.replaceAll('.', '');
          account.JAN = account.JAN.replaceAll(',', '.');
        }
        if (account.FEV && account.FEV.indexOf(',') !== -1) {
          account.FEV = account.FEV.replaceAll('.', '');
          account.FEV = account.FEV.replaceAll(',', '.');
        }
        if (account.MAR && account.MAR.indexOf(',') !== -1) {
          account.MAR = account.MAR.replaceAll('.', '');
          account.MAR = account.MAR.replaceAll(',', '.');
        }
        if (account.ABR && account.ABR.indexOf(',') !== -1) {
          account.ABR = account.ABR.replaceAll('.', '');
          account.ABR = account.ABR.replaceAll(',', '.');
        }
        if (account.MAI && account.MAI.indexOf(',') !== -1) {
          account.MAI = account.MAI.replaceAll('.', '');
          account.MAI = account.MAI.replaceAll(',', '.');
        }
        if (account.JUN && account.JUN.indexOf(',') !== -1) {
          account.JUN = account.JUN.replaceAll('.', '');
          account.JUN = account.JUN.replaceAll(',', '.');
        }
        if (account.JUL && account.JUL.indexOf(',') !== -1) {
          account.JUL = account.JUL.replaceAll('.', '');
          account.JUL = account.JUL.replaceAll(',', '.');
        }
        if (account.AGO && account.AGO.indexOf(',') !== -1) {
          account.AGO = account.AGO.replaceAll('.', '');
          account.AGO = account.AGO.replaceAll(',', '.');
        }
        if (account.SET && account.SET.indexOf(',') !== -1) {
          account.SET = account.SET.replaceAll('.', '');
          account.SET = account.SET.replaceAll(',', '.');
        }
        if (account.OUT && account.OUT.indexOf(',') !== -1) {
          account.OUT = account.OUT.replaceAll('.', '');
          account.OUT = account.OUT.replaceAll(',', '.');
        }
        if (account.NOV && account.NOV.indexOf(',') !== -1) {
          account.NOV = account.NOV.replaceAll('.', '');
          account.NOV = account.NOV.replaceAll(',', '.');
        }
        if (account.DEZ && account.DEZ.indexOf(',') !== -1) {
          account.DEZ = account.DEZ.replaceAll('.', '');
          account.DEZ = account.DEZ.replaceAll(',', '.');
        }


        sum_JAN = sum_JAN + parseFloat(account.JAN);
        sum_FEV = sum_FEV + parseFloat(account.FEV);
        sum_MAR = sum_MAR + parseFloat(account.MAR);
        sum_ABR = sum_ABR + parseFloat(account.ABR);
        sum_MAI = sum_MAI + parseFloat(account.MAI);
        sum_JUN = sum_JUN + parseFloat(account.JUN);
        sum_JUL = sum_JUL + parseFloat(account.JUL);
        sum_AGO = sum_AGO + parseFloat(account.AGO);
        sum_SET = sum_SET + parseFloat(account.SET);
        sum_OUT = sum_OUT + parseFloat(account.OUT);
        sum_NOV = sum_NOV + parseFloat(account.NOV);
        sum_DEZ = sum_DEZ + parseFloat(account.DEZ);
      });


      model = {
        ...model,
        HH_TERCEIROS: {
          JAN: maskToMoney(`${data[0].JAN}`),
          FEV: maskToMoney(`${data[0].FEV}`),
          MAR: maskToMoney(`${data[0].MAR}`),
          ABR: maskToMoney(`${data[0].ABR}`),
          MAI: maskToMoney(`${data[0].MAI}`),
          JUN: maskToMoney(`${data[0].JUN}`),
          JUL: maskToMoney(`${data[0].JUL}`),
          AGO: maskToMoney(`${data[0].AGO}`),
          SET: maskToMoney(`${data[0].SET}`),
          OUT: maskToMoney(`${data[0].OUT}`),
          NOV: maskToMoney(`${data[0].NOV}`),
          DEZ: maskToMoney(`${data[0].DEZ}`),
        },
        TOTAL_HH_TERCEIROS: {
          JAN: maskToMoney3(sum_JAN),
          FEV: maskToMoney3(sum_FEV),
          MAR: maskToMoney3(sum_MAR),
          ABR: maskToMoney3(sum_ABR),
          MAI: maskToMoney3(sum_MAI),
          JUN: maskToMoney3(sum_JUN),
          JUL: maskToMoney3(sum_JUL),
          AGO: maskToMoney3(sum_AGO),
          SET: maskToMoney3(sum_SET),
          OUT: maskToMoney3(sum_OUT),
          NOV: maskToMoney3(sum_NOV),
          DEZ: maskToMoney3(sum_DEZ),
        },
      };
    }

    return model;
  };


  function postDraftSave(values: any, resetForm: any) {
    alert(JSON.stringify(values));
    resetForm();
  };

  const releaseList = [
    'Total HH Normais',
    'Hh normais',
  ];


  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'HH_TERCEIROS.ORCADO', label: releaseList[1], value: values.HH_TERCEIROS.ORCADO },
    ];
  };

  function calcBudgeted(values: any, setFieldValue: any) {
    let subTotal = 0.00;

    if (values.HH_TERCEIROS.ORCADO) {
      subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.ORCADO);
    }

    setFieldValue('TOTAL_HH_TERCEIROS.ORCADO', subTotal);
  };

  function saveOnLocalStorageOR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[10].MOT.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.ORCADO = e.target.value;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'HH_TERCEIROS.PLANEJADO', label: releaseList[1], value: values.HH_TERCEIROS.PLANEJADO },
    ];
  };

  function calcPlanned(values: any, setFieldValue: any) {
    let subTotal = 0.00;

    if (values.HH_TERCEIROS.PLANEJADO) {
      subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.PLANEJADO);
    }

    setFieldValue('TOTAL_HH_TERCEIROS.PLANEJADO', subTotal);
  };

  function saveOnLocalStoragePL(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[10].MOT.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.PLANEJADO = e.target.value;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PREVISTO
  const listFieldsProvider = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_HH_TERCEIROS.JAN', label: 'JAN', value: values.TOTAL_HH_TERCEIROS.JAN, typeRelease: releaseList[0], month: 0, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.FEV', label: 'FEV', value: values.TOTAL_HH_TERCEIROS.FEV, typeRelease: releaseList[0], month: 1, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.MAR', label: 'MAR', value: values.TOTAL_HH_TERCEIROS.MAR, typeRelease: releaseList[0], month: 2, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.ABR', label: 'ABR', value: values.TOTAL_HH_TERCEIROS.ABR, typeRelease: releaseList[0], month: 3, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.MAI', label: 'MAI', value: values.TOTAL_HH_TERCEIROS.MAI, typeRelease: releaseList[0], month: 4, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.JUN', label: 'JUN', value: values.TOTAL_HH_TERCEIROS.JUN, typeRelease: releaseList[0], month: 5, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.JUL', label: 'JUL', value: values.TOTAL_HH_TERCEIROS.JUL, typeRelease: releaseList[0], month: 6, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.AGO', label: 'AGO', value: values.TOTAL_HH_TERCEIROS.AGO, typeRelease: releaseList[0], month: 7, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.SET', label: 'SET', value: values.TOTAL_HH_TERCEIROS.SET, typeRelease: releaseList[0], month: 8, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.OUT', label: 'OUT', value: values.TOTAL_HH_TERCEIROS.OUT, typeRelease: releaseList[0], month: 9, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.NOV', label: 'NOV', value: values.TOTAL_HH_TERCEIROS.NOV, typeRelease: releaseList[0], month: 10, disabled: true },
      { name: 'TOTAL_HH_TERCEIROS.DEZ', label: 'DEZ', value: values.TOTAL_HH_TERCEIROS.DEZ, typeRelease: releaseList[0], month: 11, disabled: true },

      { name: 'HH_TERCEIROS.JAN', label: 'JAN', value: values.HH_TERCEIROS.JAN, typeRelease: releaseList[1], month: 0, disabled: currentMonth >= 1 },
      { name: 'HH_TERCEIROS.FEV', label: 'FEV', value: values.HH_TERCEIROS.FEV, typeRelease: releaseList[1], month: 1, disabled: currentMonth >= 2 },
      { name: 'HH_TERCEIROS.MAR', label: 'MAR', value: values.HH_TERCEIROS.MAR, typeRelease: releaseList[1], month: 2, disabled: currentMonth >= 3 },
      { name: 'HH_TERCEIROS.ABR', label: 'ABR', value: values.HH_TERCEIROS.ABR, typeRelease: releaseList[1], month: 3, disabled: currentMonth >= 4 },
      { name: 'HH_TERCEIROS.MAI', label: 'MAI', value: values.HH_TERCEIROS.MAI, typeRelease: releaseList[1], month: 4, disabled: currentMonth >= 5 },
      { name: 'HH_TERCEIROS.JUN', label: 'JUN', value: values.HH_TERCEIROS.JUN, typeRelease: releaseList[1], month: 5, disabled: currentMonth >= 6 },
      { name: 'HH_TERCEIROS.JUL', label: 'JUL', value: values.HH_TERCEIROS.JUL, typeRelease: releaseList[1], month: 6, disabled: currentMonth >= 7 },
      { name: 'HH_TERCEIROS.AGO', label: 'AGO', value: values.HH_TERCEIROS.AGO, typeRelease: releaseList[1], month: 7, disabled: currentMonth >= 8 },
      { name: 'HH_TERCEIROS.SET', label: 'SET', value: values.HH_TERCEIROS.SET, typeRelease: releaseList[1], month: 8, disabled: currentMonth >= 9 },
      { name: 'HH_TERCEIROS.OUT', label: 'OUT', value: values.HH_TERCEIROS.OUT, typeRelease: releaseList[1], month: 9, disabled: currentMonth >= 10 },
      { name: 'HH_TERCEIROS.NOV', label: 'NOV', value: values.HH_TERCEIROS.NOV, typeRelease: releaseList[1], month: 10, disabled: currentMonth >= 11 },
      { name: 'HH_TERCEIROS.DEZ', label: 'DEZ', value: values.HH_TERCEIROS.DEZ, typeRelease: releaseList[1], month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const calcProvider = (values: any, month: any, setFieldValue: any) => {
    let subTotal = 0;

    switch (month) {
      case 0:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.JAN);
        setFieldValue('TOTAL_HH_TERCEIROS.JAN', maskToMoney3(subTotal));
        break;
      case 1:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.FEV);
        setFieldValue('TOTAL_HH_TERCEIROS.FEV', maskToMoney3(subTotal));
        break;
      case 2:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.MAR);
        setFieldValue('TOTAL_HH_TERCEIROS.MAR', maskToMoney3(subTotal));
        break;
      case 3:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.ABR);
        setFieldValue('TOTAL_HH_TERCEIROS.ABR', maskToMoney3(subTotal));
        break;
      case 4:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.MAI);
        setFieldValue('TOTAL_HH_TERCEIROS.MAI', maskToMoney3(subTotal));
        break;
      case 5:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.JUN);
        setFieldValue('TOTAL_HH_TERCEIROS.JUN', maskToMoney3(subTotal));
        break;
      case 6:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.JUL);
        setFieldValue('TOTAL_HH_TERCEIROS.JUL', maskToMoney3(subTotal));
        break;
      case 7:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.AGO);
        setFieldValue('TOTAL_HH_TERCEIROS.AGO', maskToMoney3(subTotal));
        break;
      case 8:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.SET);
        setFieldValue('TOTAL_HH_TERCEIROS.SET', maskToMoney3(subTotal));
        break;
      case 9:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.OUT);
        setFieldValue('TOTAL_HH_TERCEIROS.OUT', maskToMoney3(subTotal));
        break;
      case 10:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.NOV);
        setFieldValue('TOTAL_HH_TERCEIROS.NOV', maskToMoney3(subTotal));
        break;
      case 11:
        subTotal = subTotal + maskToFloat(values.HH_TERCEIROS.DEZ);
        setFieldValue('TOTAL_HH_TERCEIROS.DEZ', maskToMoney3(subTotal));
        break;
    };
  };

  function saveOnLocalStoragePR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[10].MOT.forEach((invoicing) => {
      if (invoicing.TIPO === field.typeRelease) {
        switch (field.label) {
          case 'JAN':
            invoicing.JAN = maskToMoney(e.target.value);
            break;
          case 'FEV':
            invoicing.FEV = maskToMoney(e.target.value);
            break;
          case 'MAR':
            invoicing.MAR = maskToMoney(e.target.value);
            break;
          case 'ABR':
            invoicing.ABR = maskToMoney(e.target.value);
            break;
          case 'MAI':
            invoicing.MAI = maskToMoney(e.target.value);
            break;
          case 'JUN':
            invoicing.JUN = maskToMoney(e.target.value);
            break;
          case 'JUL':
            invoicing.JUL = maskToMoney(e.target.value);
            break;
          case 'AGO':
            invoicing.AGO = maskToMoney(e.target.value);
            break;
          case 'SET':
            invoicing.SET = maskToMoney(e.target.value);
            break;
          case 'OUT':
            invoicing.OUT = maskToMoney(e.target.value);
            break;
          case 'NOV':
            invoicing.NOV = maskToMoney(e.target.value);
            break;
          case 'DEZ':
            invoicing.DEZ = maskToMoney(e.target.value);
            break;

          default:
            break;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffHhTerceirosModel)}
      onSubmit={(values, { resetForm }) => {
        postDraftSave(values, resetForm);
      }}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>
            <PffFilterContainerForm auto onSubmit={handleSubmit}>

              {/* LANÇAMENTO ORIGINAL */}
              {(props.releaseType?.COD_LANCAMENTO === 'OR') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsBudgeted(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStorageOR(field, e);
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          calcBudgeted(values, setFieldValue);
                          saveOnLocalStorageOR(field, e);
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          calcBudgeted(values, setFieldValue);
                        }
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_HH_TERCEIROS.ORCADO'}
                    label={'Total Hh Terceiros '}
                    value={maskToMoney2(values.TOTAL_HH_TERCEIROS.ORCADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}


              {/* LANÇAMENTO PLANEJADO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PL') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsPlanned(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStoragePL(field, e);
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          calcPlanned(values, setFieldValue);
                          saveOnLocalStoragePL(field, e);
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          calcPlanned(values, setFieldValue);
                        }
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_HH_TERCEIROS.PLANEJADO'}
                    label={'Total Hh Terceiros '}
                    value={maskToMoney2(values.TOTAL_HH_TERCEIROS.PLANEJADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}


              {/* LANÇAMENTO PREVISTO */}
              {props.releaseType?.COD_LANCAMENTO === 'PR' && (

                releaseList.map((typeRelease) => (
                  <>

                    <TitleInterGroup titleAlign={'rigth'}>
                      <h1>{typeRelease}</h1>
                    </TitleInterGroup>


                    <ColFieldsGrid xs={12}>

                      {listFieldsProvider(values).map((field) => (

                        typeRelease === field.typeRelease && (

                          <TextInputLabelFloat
                            fontSize={Metrics.fontSizePFFSm}
                            fontSizeLabel={Metrics.fontSizePFFSm}
                            disabled={field.disabled}
                            name={field.name}
                            id={field.name}
                            label={field.label}
                            value={field.value}
                            type={'text'}
                            onChange={(e: any) => {
                              if (e.target.value !== '') {
                                handleChange(e);

                                setFieldValue(field.name, maskToMoney(e.target.value));
                              }
                            }}

                            onBlur={(e) => {
                              handleBlur(e);
                              if (e.target.value !== '') {
                                calcProvider(values, field.month, setFieldValue);

                                saveOnLocalStoragePR(field, e);
                              }
                              else {
                                setFieldValue(field.name, '00');
                              }
                            }}

                            onFocus={(e) => {
                              e.target.select();
                            }}

                            onKeyUp={(e) => {
                              if (e.key === 'Enter')
                                calcProvider(values, field.mouth, setFieldValue);
                            }}
                          />
                        )

                      ))}

                    </ColFieldsGrid>

                  </>
                ))
              )}

            </PffFilterContainerForm>

          </SectionMargin>

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_HhTerceirosScreen;
