import React from 'react';

import {
  OverlayTrigger,
} from 'react-bootstrap';

import {
  TableActionLink,
  TableToltip,
} from '../../../common/styles/styled.layout';

interface IProps {
  key?: any;
  disabled?: boolean;
  backgroundColor?: any;

  tooltip?: string;
  icon?: any;
  placement?: any;
  trigger?: any;

  to?: any;
}



const LinkTableAction: React.FC<IProps> = (props) => {
  return (

    <OverlayTrigger
      key={props.key || 'left'}
      placement={props.placement || 'left'}
      trigger={props.trigger || ['hover', 'hover']}
      overlay={
        <TableToltip>
          {props.tooltip}
        </TableToltip>
      }>

      <TableActionLink
        backgroundColor={props.backgroundColor}
        to={props.to}>

        {props.icon}

      </TableActionLink>

    </OverlayTrigger>

  );
};



export default LinkTableAction;
