import React,
{
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';

import {
  ContentStyled,
  Content,
  Screen,
  TabsStyled,
  TabViewStyled,
  TabIconStyled,
  TabTextStyled,
} from '../../../../../common/styles/styled.layout';

import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconDevolution,
  IconFiscal,
  IconTransfer,
} from '../../../../../components/Icons';

import {
  PSTOCK_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import Tab_DevolucoesScreen from './TabDevolucoes';
import Tab_NfScreen from './TabNf';
import Tab_TransferenciasScreen from './TabTransferencias';



const PSTOCK_EntradaScreen: React.FC = () => {
  const [atualTab, setAtualTab] = useState('table_devolucoes');



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PSTOCK_NavRoutes}
          title={'PStock'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          <TabsStyled
            id={'control-tab-pstock-entrance'}
            activeKey={atualTab}
            onSelect={(tab) => setAtualTab(tab)}>

            <Tab
              eventKey={'table_devolucoes'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconDevolution />
                  </TabIconStyled>

                  <TabTextStyled>
                    Devoluções
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_DevolucoesScreen />
            </Tab>

            <Tab
              eventKey={'table_nf'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconFiscal />
                  </TabIconStyled>

                  <TabTextStyled>
                    NF/Romaneio
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_NfScreen />
            </Tab>

            <Tab
              eventKey={'table_transferencias'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconTransfer />
                  </TabIconStyled>

                  <TabTextStyled>
                    Transferências
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_TransferenciasScreen />
            </Tab>
          </TabsStyled>

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default PSTOCK_EntradaScreen;
