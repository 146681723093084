import React from 'react';

import {
  OverlayTrigger,
} from 'react-bootstrap';

import {
  TableToltip,
} from '../../../common/styles/styled.layout';
import { CheckBoxContainer } from './styled';

interface IProps {
  key?: any;
  disabled?: boolean;
  
  tooltip?: string;
  placement?: any;
  trigger?: any;
  
  onChange?: any;
  onClick?: any;
}



const ButtonTableCheck: React.FC<IProps> = (props) => {
  return (

    <OverlayTrigger
      key={props.key || 'right'}
      placement={props.placement || 'right'}
      trigger={props.trigger || ['hover', 'hover']}
      overlay={
        <TableToltip>
          {props.tooltip}
        </TableToltip>
      }>

      <CheckBoxContainer
        disabled = {props.disabled}
        type="checkbox"
        onClick={props.onClick}
        onChange={props.onChange}
      />

    </OverlayTrigger>

  );
};



export default ButtonTableCheck;
