const PcbAnexosManutencaoModel = {
  CONTRATO: '',
  FILIAL: '',
  NUM: '',
  PLANILHA: '',
  REVISAO: '',
  TIPO: '',
};



export default PcbAnexosManutencaoModel;
