import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';
import { Images } from '../../../../../common/constants';

import {
  ContentStyled,
  Content,
  Screen,
  TabsStyled,
  TabViewStyled,
  TabIconStyled,
  TabTextStyled,
} from '../../../../../common/styles/styled.layout';
import EmptyContent from '../../../../../components/Empty';

import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconBranch, IconCNPJSupplier, IconContract2, IconHistory, IconPO, IconResidue,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  PBC_NavRoutes,
} from '../../../../../navigation/navs/Supplies';
import ApiWS from '../../../../../services/api.service';

import Tab_CnpjScreen from './Tabs/tab_Cnpj';
import Tab_ContratoScreen from './Tabs/tab_Contrato';
import Tab_FilialScreen from './Tabs/tab_FilialEntrega';
import Tab_HistoryScreen from './Tabs/tab_Historico';
import Tab_OpScreen from './Tabs/tab_Op';
import Tab_ResiduoScreen from './Tabs/tab_Residuo';



const PBC_ManutencaoScreen: React.FC = () => {
  const [atualTab, setAtualTab] = useState('table_cnpj');

  const [loading, setLoading] = useState(false);

  const [permission, setPermission] = useState(true);

  const [emptyObject] = useState({
    image: Images.error401,
    title: 'Sem Permissão!',
    description: 'Você não tem permissão para acessar essa tela!',
  });


  function postFetchPermission() {
    setLoading(true);
    ApiWS()
      .post('/suprimento/manutencao/consulta/permissao')
      .then((resp) => {
        if (resp.status === 200) {
          setPermission(true);
        }
        else {
          setPermission(false);
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persiste contate o suporte.',
        });

        console.error('postFetchPermission', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    postFetchPermission();
  }, []);


  const tabsList = [ // Para nova TAB, basta preencher o Array
    {
      eventKey: 'table_cnpj', // Tela no momento, altera no onClick, pelo estado
      title: {
        icon: <IconCNPJSupplier />, // Ícone da TAB
        text: 'CNPJ', // Texto da TAB
      },
      screenTab: <Tab_CnpjScreen />, // Conteúdo da TAB
    },

    {
      eventKey: 'table_filial',
      title: {
        icon: <IconBranch />,
        text: 'Filial',
      },
      screenTab: <Tab_FilialScreen />,
    },

    {
      eventKey: 'table_residuo',
      title: {
        icon: <IconResidue />,
        text: 'Resíduo',
      },
      screenTab: <Tab_ResiduoScreen />,
    },

    {
      eventKey: 'table_contract',
      title: {
        icon: <IconContract2 />,
        text: 'Contrato',
      },
      screenTab: <Tab_ContratoScreen />,
    },

    // {
    //   eventKey: 'table_op',
    //   title: {
    //     icon: <IconPO />,
    //     text: 'OP',
    //   },
    //   screenTab: <Tab_OpScreen />,
    // },

    {
      eventKey: 'table_history',
      title: {
        icon: <IconHistory size={20} />,
        text: 'Histórico',
      },
      screenTab: <Tab_HistoryScreen />,
    },
  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={5}
          navArray={PBC_NavRoutes}
          title={'Suprimentos'}
        />
      </NavHeader>


      {loading && (
        <LoadingsComponent />
      )}


      {!loading && !permission && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}


      {!loading && permission && (

        <Content>
          <ContentStyled fluid>

            <TabsStyled
              id={'control-tab-pcb'}
              activeKey={atualTab}
              onSelect={(tab) => setAtualTab(tab)}
            >

              {tabsList.map((tab) => (

                <Tab
                  eventKey={tab.eventKey}
                  title={
                    <TabViewStyled>
                      <TabIconStyled>
                        {tab.title.icon}
                      </TabIconStyled>

                      <TabTextStyled>
                        {tab.title.text}
                      </TabTextStyled>
                    </TabViewStyled>
                  }>

                  {tab.screenTab}

                </Tab>
              ))}

            </TabsStyled>

          </ContentStyled>
        </Content>

      )}

    </Screen>

  );
};



export default PBC_ManutencaoScreen;
