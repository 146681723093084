import React,
{
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import UsuarioCUDGIModal from '../../../_Modals/007UsuarioCUDGIModal';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconCheckCopy, IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GIUsuarioModel from './GI-Tab_Usuario.model';
import GIUsuarioValidationSchema from './GI-Tab_Usuario.validation';


const Tab_UsuarioScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listUser, setListUser] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Usuários!',
    description: 'Não há nenhum usuário aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [valuesCopy, setValuesCopy] = useState(null);
  const [showModalCopy, setModalCopy] = useState(false);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  function postFetchUser(values, page_iteration: any) {
    setLoading(true);
    setShowList(false);
    setListUser([]);
    setValuesPayload(values);


    const url = 'gi/usuario/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        setListUser(res.data);
        setTotalResults(res.data[1][0].TOTAL);
        setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchUser', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchUser(valuesPayload, page_var);
    setPage(page_var);
  };

  // function postProfileCopy(values) {
  //   setLoading(true);

  //   const url = 'gi/usuario/copiar';
  //   const payload = [values];


  //   ApiWS()
  //     .post(url, payload)
  //     .then((res) => {
  //       if(res.status === 200) {
  //         setValuesCopy(res.data);
  //         setModalCopy(true);
  //       }
  //     })
  //     .catch((err) => {
  //       IToast({
  //         type: 'error',
  //         message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
  //       });

  //       setEmptyObject({
  //         image: Images.other500,
  //         title: 'Erro',
  //         description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
  //       });

  //       console.error('postProfileCopy', JSON.stringify(err));
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }


  const options = {
    custom: true,
    totalSize: listUser.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'MATRICULA',
      text: 'Matrícula',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'NOME',
      text: 'Nome',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'LOGIN',
      text: 'Login',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'EMAIL',
      text: 'Email',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'STATUS',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      formatter: (_cell, row) => {
        if (row.STATUS === '1') {
          return 'Ativo';
        }
        else {
          return 'Desativado';
        }
      },
    },
    {
      dataField: 'CHECKEMAIL',
      text: 'Recebe Email',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      formatter: (_cell, row) => {
        if (row.CHECKEMAIL === '1') {
          return 'Sim';
        }
        else {
          return 'Não';
        }
      },
    },

    {
      dataField: '',
      text: 'Copiar',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.COPY}`}
          onClick={() => {
            setValuesCopy(row);
            setModalCopy(true);
          }}
          icon={
            <IconCheckCopy color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Detal./Edit.',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.DETAILS}/${TextTooltip.EDIT}`}
          onClick={() => {
            setShowModalEdit(true);
            setValuesEdit(row);
          }}
          icon={
            <IconEdit color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove(row);
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listUser}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.PCB_Pedidos,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              validationSchema={GIUsuarioValidationSchema}
              initialValues={GIUsuarioModel}
              onSubmit={(values) => {
                postFetchUser(values, 1);
              }}>
              {({
                handleSubmit,
                handleBlur,
                handleChange,
              }) => (

                <>

                  {loading && (
                    <LoadingsComponent />
                  )}



                  <FilterContainerForm auto onSubmit={handleSubmit}>
                    <Row>

                      <Col>
                        <TextInput
                          type={'text'}
                          name={'NOME'}
                          label={'Nome'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>



                      <Col>
                        <TextInput
                          type={'text'}
                          name={'LOGIN'}
                          label={'Login'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>

                    </Row>



                    <Row>

                      <Col>
                        <TextInput
                          type={'text'}
                          name={'EMAIL'}
                          label={'Email'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        type={'button'}
                        backgroundColor={Colors?.grayDark}
                        text={'Novo'}
                        onClick={() => {
                          setShowModalNew(true);
                        }}
                      />


                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>



                </>

              )}

            </Formik>



            {!loading && showList && listUser.length === 0 && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}



            {!loading && showList && listUser.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={listUser}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}
      </ToolkitProvider>

      {showModalNew && (
        <UsuarioCUDGIModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Novo Usuário'}
          type={'NEW'}
          fetchNow={() => postFetchUser({ NOME: '', LOGIN: '', EMAIL: '' }, 1)}
        />
      )}


      {showModalEdit && (
        <UsuarioCUDGIModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Usuário'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchUser({ NOME: '', LOGIN: '', EMAIL: '' }, 1)}
        />
      )}


      {showModalRemove && (
        <UsuarioCUDGIModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Usuário'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchUser({ NOME: '', LOGIN: '', EMAIL: '' }, 1)}
        />
      )}


      {showModalCopy && (
        <UsuarioCUDGIModal
          show={showModalCopy}
          onHide={() => setModalCopy(false)}
          title={'Copiar Usuário'}
          type={'COPY'}
          valuesCopy={valuesCopy}
          fetchNow={() => postFetchUser({ NOME: '', LOGIN: '', EMAIL: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_UsuarioScreen;
