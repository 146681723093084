import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';



const PcbAnexosValidationSchema = Yup.object().shape({

  input_descricao: Yup.string()
    .required('Campo Obrigatório')
    .max(Metrics.input_descAnexo, Messages.input_descAnexo),



  input_tipo: Yup.string()
    .required('Campo Obrigatório'),



  input_filial: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_contrato: Yup.string()
    .when('input_tipo', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo Contrato'),
    })
    // .length(Metrics.input_contrato, Messages.input_contrato)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_planilha: Yup.string()
    .when('input_tipo', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo Contrato'),
    })
    // .length(Metrics.input_planilha, Messages.input_planilha)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_revisao: Yup.string()
    .when('input_tipo', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo CONTRATO'),
    })
    // .length(Metrics.input_rev, Messages.input_rev)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  // input_pcbm: Yup.string(),
  // .when('input_tipo', {
  //   is: 'BM',
  //   then: Yup.string()
  //     .required('Obrigatório para o tipo BM'),
  // }),

  // input_fileAnexo: Yup.string()
  //   .required('Obrigatório para o tipo PC'),

});



export default PcbAnexosValidationSchema;
