import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  ContentStyled,
  FilterContainerForm,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../components/Empty';
import LoadingsComponent from '../../../../../../components/Loadings';
import TableCuston from '../../../../../../components/Table';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import ModalCreateUnit from './ModalCreateUnit';

import {
  RowButtonUnitView,
} from './styled';

import UnitPstockModel from './unit.model';
import UnitPstockValidationSchema from './unit.validation';



const TabUnidade: React.FC = () => {
  const formik = useRef<FormikProps<any>>(null);

  const [openModalCreate, setOpenModalCreate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [listUnit, setListUnit] = useState([]);
  const [responseUnitArray, setResponseUnitArray] = useState([]);
  const [listLoading, setListLoading] = useState(false);



  function showSnackBarProps() {
    toast.error('Erro ao obter dados.', {
      position: 'bottom-center',
      autoClose: Metrics.messageAutoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }


  function renderListUnit(listResp: any) {
    const listToMap = listResp.map((item) => {
      const valueResp = item.ALIAS !== '' || item.ALIAS !== null || item.ALIAS !== undefined ? item.ALIAS : null;

      const listMapped = {
        'key': item?.ID,
        'label': item?.ID ? valueResp : item?.ID,
        'value': item?.ID,
      };
      return listMapped;
    });

    setListUnit(listToMap);
    formik.current?.setFieldValue('input_unidade', listToMap[0]?.value);
  };


  async function getUnitArrayData() {
    try {
      setLoading(true);

      const url = '/pstock/parametro/lista/unidade';
      const response = await ApiWS().post(url);
      if (response) {
        renderListUnit(response.data);
      }
    }
    catch (error: any) {
      console.error(error);

      showSnackBarProps();
    }
    finally {
      setLoading(false);
    }
  };



  async function getUnitData(values: typeof UnitPstockModel) {
    const payload = {
      'IDUNIDADE': values.input_unidade,
    };

    try {
      setListLoading(true);
      setShowList(false);

      const url = '/pstock/parametro/consulta/unidade';
      const response = await ApiWS().post(url, payload);

      setResponseUnitArray(response.data);
    }
    catch (error) {
      console.error('getUnitData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };



  useEffect(() => {
    getUnitArrayData();
  }, []);



  const columns = [
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
      formatter: (cell) => {
        return cell || '-';
      },
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'ALIAS',
      text: 'Unidade',
      sort: false,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'VALOR',
      text: 'Valor',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
    {
      dataField: 'DTATUALIZACAO',
      text: 'Data de atualização',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: responseUnitArray.length,
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={UnitPstockValidationSchema}
      initialValues={UnitPstockModel}
      onSubmit={(values) => {
        getUnitData(values);
      }}>
      {({
        values,
        // resetForm,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        // setValues,
      }) => (

        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Unidades'.toUpperCase()}
              </TitleGroup>


              <SectionMargin>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>
                    <Col>
                      <DropdownInput
                        disabled={loading || listLoading}
                        name={'input_unidade'}
                        label={'Unidade'}
                        dropArray={listUnit}
                        defaultValue={listUnit[0]?.value}
                        value={values.input_unidade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>



                    <Col>
                      <RowButtonUnitView>
                        <IButton
                          disabled={loading || listLoading}
                          text={'Associar parâmetro a unidade'}
                          backgroundColor={Colors?.tertiaryDark}
                          onClick={() => {
                            setOpenModalCreate(true);
                          }}
                        />


                        <SeparatorButton />


                        {/*
                        <IButton
                          disabled={loading || listLoading}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            setValues(UnitPstockModel);
                          }}
                        />
                        */}


                        <IButton
                          disabled={!isValid || loading || listLoading}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />
                      </RowButtonUnitView>
                    </Col>
                  </Row>

                </FilterContainerForm>

              </SectionMargin>


              <ContainerView>

                {listLoading && (
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary}
                  />
                )}



                {!loading && !listLoading && showList && responseUnitArray.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}



                {!loading && !listLoading && showList && responseUnitArray.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={responseUnitArray.length < 10}
                        data={responseUnitArray}
                        columns={columns}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>


            </SectionMargin>

          </ContainerView>



          {openModalCreate && (
            <ModalCreateUnit
              show={openModalCreate}
              negative={(status: boolean) => {
                setOpenModalCreate(false);

                if (status) {
                  getUnitData(values);
                }
              }}
            />
          )}

        </ContentStyled>

      )}
    </Formik>

  );
};



export default TabUnidade;
