export const ArrayChrSituacao = [
  {
    key: '1',
    label: 'Todos',
    value: 'TODOS',
  },
  {
    key: '2',
    label: 'Afastado',
    value: 'AFASTADO',
  },
  {
    key: '3',
    label: 'Demitido',
    value: 'DEMITIDO',
  },
  {
    key: '4',
    label: 'Férias',
    value: 'FERIAS',
  },
  {
    key: '5',
    label: 'Normal',
    value: 'NORMAL',
  },
];

export const ArrayChrMao = [
  {
    key: '1',
    label: 'Todos',
    value: 'TODOS',
  },
  {
    key: '2',
    label: 'Direta',
    value: 'DIRETA',
  },
  {
    key: '3',
    label: 'Indireta',
    value: 'INDIRETA',
  },
];


export const ArrayChrClassificacao = [
  {
    key: '1',
    label: 'Todos',
    value: 'TODOS',
  },
  {
    key: '2',
    label: 'Hora Abonada',
    value: 'HORA ABONADA',
  },
  {
    key: '3',
    label: 'Hora Normal',
    value: 'HORA NORMAL',
  },
  {
    key: '4',
    label: 'Hora Extra',
    value: 'HORA EXTRA',
  },
  {
    key: '5',
    label: 'N/A',
    value: 'N / A',
  },
  {
    key: '6',
    label: 'Outros',
    value: 'OUTROS',
  },
];


export const ArrayChrTipo = [
  {
    key: '1',
    label: 'Custos',
    value: 'C',
  },
  {
    key: '2',
    label: 'Horas',
    value: 'H',
  },
];
