import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Redirect,
} from 'react-router';

import {
  Formik,
} from 'formik';

import jwt_decode from 'jwt-decode';
import jwt from 'jwt-simple';

import {
  Colors,
} from '../../common/constants';

import {
  Overlay,
  Screen,
} from '../../common/styles/styled.layout';

import LoginValidationSchema from '../../common/validations/Login';

import { IToast } from '../../components/IToast/Toast';
import LoadingsComponent from '../../components/Loadings';
import TextInput from '../../components/TextFields/Input';
import ApiWS from '../../services/api.service';

import {
  RootState,
} from '../../store';

import {
  USER_FETCH_INFO,
  USER_RESET,
  USER_TOKEN,
} from '../../store/reducers/user.store';

import { intranet_config } from '../../utils/Config';
import SendLicenseScreen from '../Errors/NotLicense';
import {
  LoginBody,
  LoginContainer,
  LoginFormView,
  LoginView,
  LogoImgContent,
  LogoImg,
  LoginTextsContent,
  WelcomeTitle,
  WelcomeText,
  LoginInputsContent,
  ButtonLogin,
  ContainerImgLogoFooter,
  ImgLogoFooter,
} from './styled';
import LoadingIniComponent from '../../components/Loading Ini';

import logoPSuite from '../../assets/logos/logo-negative.png';
import { Row } from 'react-bootstrap';


const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  // QUANDO EM PRODUCAO ALTERAR PARA FALSE
  const [licensed, setLicensed] = useState(true);
  const [IdEmp_state, setIdEmp_state] = useState('');

  const [loading, setLoading] = useState(false);

  function clearAll() {
    try {
      dispatch({ type: USER_RESET });
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_FETCH_INFO);
      localStorage.removeItem('@intranet/validade');
    }
    catch (err) {
      console.log(`Clear Error: ${err}`);
    }
  }


  async function AuthenticateUser(data: any) {
    setLoading(true);

    const tokenData = jwt.encode(data, process.env.REACT_APP_SECRET_API);
    const payload = { token: tokenData };

    const url = '/login';
    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.data.token) {
          // Header
          const token = res.data.token;

          let userDataDecode = { Id: '', Login: '', Email: '', Nome: '', iat: 0, exp: 0, Matricula: '', Funcao: '', Foto: '' };
          userDataDecode = jwt_decode(token);

          const userData = {
            id: userDataDecode.Id,
            IdEmp: IdEmp_state,
            login: userDataDecode.Login,
            user: data.user,
            email: userDataDecode.Email,
            name: userDataDecode.Nome,
            iat: userDataDecode.iat,
            exp: userDataDecode.exp,
            registration: userDataDecode.Matricula,
            occupation: userDataDecode.Funcao,
            // domain: data.domain,
            photo: userDataDecode.Foto,
          };
          console.log('userData:::::::::::', userData);
          localStorage.setItem(USER_TOKEN, token);
          // localStorage.setItem(USER_FETCH_INFO, JSON.stringify(userData));


          dispatch({ type: USER_FETCH_INFO, payload: userData });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Erro ao Entrar! Verifique Usuário e Senha.',
        });


        console.error('Token Not Present', JSON.stringify(err));
        clearAll();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const name = intranet_config?.Registration?.name;

    if(name === '' || name === undefined || name === null) {
      window.location.reload();
    };
  },[]);

  return (

    <Screen>

      {!loading && loggedIn && licensed && (
        <Redirect
          to={{
            pathname: '/home',
          }}
        />
      )}


      {!loading && !licensed && (
        <SendLicenseScreen />
      )}


      {!loading && !loggedIn && licensed && (

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            user: '',
            pass: '',
            // QUANDO EM PRODUCAO REMOVER DOMINIO
            // domain: intranet_config.Registration.domain,
            IDEMP: jwt_decode(localStorage.getItem('@intranet/idEmp')),
          }}
          validationSchema={LoginValidationSchema}
          onSubmit={(values) => {
            AuthenticateUser(values);
          }}>
          {({
            handleChange,
            // handleBlur,
            handleSubmit,
            values,
            // errors,
          }) => (

            <LoginBody>

              <Overlay />

              <LoginContainer>

                <LoginFormView onSubmit={handleSubmit}>

                  <LogoImgContent>
                    <LogoImg
                      alt={`${intranet_config?.Registration?.name} Logo`}
                      src={intranet_config?.Images?.logo}
                    />
                  </LogoImgContent>



                  {!loading && (
                    <LoginView>

                      <LoginTextsContent>

                        <WelcomeTitle>
                          Bem vindo!
                        </WelcomeTitle>

                        <WelcomeText>
                          Para acessar o PSUITE da
                          <br />
                          {intranet_config?.Registration?.name},
                          é necessário autenticar com suas credenciais de rede.
                        </WelcomeText>

                      </LoginTextsContent>


                      <LoginInputsContent>

                        <TextInput
                          type={'text'}
                          name={'user'}
                          label={'Usuário'}
                          placeholder={'Nome de Usuario'}
                          onChange={handleChange}
                          value={values.user}
                        />


                        <TextInput
                          showPass
                          type={'password'}
                          name={'pass'}
                          label={'Senha'}
                          placeholder={'Senha'}
                          onChange={handleChange}
                          value={values.pass}
                        />

                      </LoginInputsContent>


                      <ButtonLogin
                        size={'lg'}
                        type={'submit'}>
                        Entrar
                      </ButtonLogin>

                    </LoginView>
                  )}

                </LoginFormView>

              </LoginContainer>


              <ContainerImgLogoFooter>
                <ImgLogoFooter src={intranet_config?.Images?.logo_planeja_negativa}
                  alt={'PSuite - By PlanejaTI'} />
              </ContainerImgLogoFooter>

            </LoginBody>

          )}
        </Formik>
      )}



    </Screen>

  );
};



export default LoginScreen;
