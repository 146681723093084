import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  format,
} from 'date-fns';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';

import { IconPrinter } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';

import { intranet_config } from '../../../../../utils/Config';
import {
  DateTimezoneSpliceFormat,
} from '../../../../../utils/DateUtils';

import {
  PrinterContainer,
  A4LandscapeContent,
} from './SME-impressao.styled';



const SME_Impressao: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const { id } = useParams() as any;


  const [carregando, setCarregando] = useState(false);
  const [listaItens, setListaItens] = useState([]);



  async function findSM(numSM) {
    try {
      setCarregando(true);

      const url = `/sm/impressao/${numSM}`;
      const response = await ApiWS().get(url);

      setListaItens(response.data);
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };


  async function printerLandscapePage() {
    await findSM(id);
    // window.print();
  }



  useEffect(() => {
    printerLandscapePage();
  }, [id]);


  function renderLoadingComponent() {
    return (

      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </PrinterContainer>

    );
  }


  function renderLoadingPrinter() {
    return (
      <A4LandscapeContent className="page-a4-landscape">

        <TablePrinterStyled>
          <tbody>
            <tr>
              <td rowSpan={2} className="image-td">
                <img
                  width={'100px'}
                  height={'100px'}
                  alt="Logo"
                  src={intranet_config.Images.logo}
                />
              </td>
              <th rowSpan={2} className="name-printer-td-sm">
                SM - Solicitação de Materiais Compras e Serviços
              </th>
              <th colSpan={2}>Nº</th>
              <td colSpan={2}>{listaItens.map((i) => i.NUMSM)[0]}</td>
            </tr>

            <tr>
              <th colSpan={2}>Data</th>
              <td colSpan={2}>{listaItens.map((i) => DateTimezoneSpliceFormat(i.DATEMIS))[0]}</td>
            </tr>

            <tr>
              <th>Nª da OS</th>
              <th>Setor - Operação</th>
              <th>OP</th>
              <th>Tipo OP</th>
              <th>Utilização</th>
              <th>Orientação</th>
            </tr>

            <tr>
              <td>{listaItens.map((i) => i.OS)[0]}</td>
              <td>{listaItens.map((i) => i.DESCRICAO_OS)[0]}</td>
              <td>{listaItens.map((i) => i.OP)[0]}</td>
              <td>{listaItens.map((i) => i.TIPO_OP)[0]}</td>
              <td>{listaItens.map((i) => i.UTILIZACAO)[0]}</td>
              <td>{listaItens.map((i) => i.ORIENTACAO)[0]}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>



        <TablePrinterStyled>
          <thead>
            <tr className="text-center">
              <th>Item</th>
              <th>Código</th>
              <th>UN</th>
              <th>Qtd. Sol.</th>
              <th>Qtd. Aprov.</th>
              <th>Qtd. Pendente</th>
              <th>Descrição Básica</th>
              <th>Dados Complementares</th>
              <th>Limite</th>
              <th>Prazo</th>
              <th>Status</th>
            </tr>
          </thead>


          <tbody>
            {listaItens.map((item, key) => (
              <tr key={key}>
                <td>{item.ITEM}</td> {/* Item */}
                <td>{item.PRODUTO}</td> {/* Código */}
                <td>{item.UN}</td> {/* UN */}
                <td>{item.QTDSOL}</td> {/* Qtd. Sol. */}
                <td>{item.QTDATE}</td> {/* Qtd. Aprov. */}
                <td>{item.QUANTPEN}</td> {/* Qtd. Pendente */}
                <td>{item.DESCRICAO}</td> {/* Descrição */}
                <td>{item.COMPLEM}</td> {/* Dados */}
                <td>{DateTimezoneSpliceFormat(item.DATNECES)}</td> {/* Limite */}
                <td>{item.PRAZO}</td> {/* Prazo */}
                <th className="text-center text-danger">{item.STATUS}</th> {/* Status */}
              </tr>
            ))}
          </tbody>
        </TablePrinterStyled>



        <TablePrinterStyled>
          <thead>
            <tr>
              <th className="text-center">Detalhe</th>
            </tr>
          </thead>


          <tbody>
            <tr>
              <td className="text-left">Emitente: {listaItens.map((i) => i.SOLICITANTE)[0]}</td>
            </tr>

            <tr>
              <td colSpan={2} height={90}>Observações:{<br />}{listaItens.map((i) => i.OBSERVACAO)[0]} </td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <div>
          <p><i>* - Relatório Gerado por: {user.name} em: {format(new Date(Date.now()), 'dd/MM/yyyy HH:mm')}</i></p>
          <TableActionButton
            onClick={() => {
              window.print();
            }}>
            <IconPrinter
              color={Colors?.black}
              size={72}
            />
          </TableActionButton>
        </div>

      </A4LandscapeContent>
    );
  }


  return carregando ? renderLoadingComponent() : renderLoadingPrinter();
};



export default SME_Impressao;
