import * as Yup from 'yup';


export const PICConsultaValidationSchema = Yup.object().shape({
  CC: Yup.string().trim()
    .required('Campo Obrigatório!'),

  AREA: Yup.string(),

  AUDITOR: Yup.string(),

  MATRICULA: Yup.number()
    .positive('A matricula deve conter apenas números.')
    .integer('A mátricula deve conter apenas números.'),

  DTINI: Yup.string(),

  DTFIM: Yup.string(),

});
