import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Colors } from '../../common/constants';
import { IconNext, IconReturn } from '../Icons';
import { HeaderStyle, ListStyleLeft, ContainerStyle, ItemStyle, ListGroupStyle, RowStyle, ListStyleRight, TitleListsHeader, AreaButtonList, ButtonListStyle } from './styled';



export interface IProps {
  titles: any;
  listLeft?: any;
  listRight?: any;

  returnLeft?: any;
  returnRight?: any;

  size?: any;

  disabled?: any;
}



const ListTransfer: React.FC<IProps> = (props) => {
  const [listLeft, setListLeft] = useState(props?.listLeft);
  const [listRigth, setListRigth] = useState(props?.listRight);

  const [listTransfer, setListTransfer] = useState([]);

  const [direction, setDirection] = useState('N');


  function transferToList(list: string) {
    let newListSlice = [];

    if (list === 'R') {
      newListSlice = listLeft;

      listTransfer.forEach((item) => {
        setListRigth((listRigth) => [...listRigth, item]);
      });
    }
    else if (list === 'L') {
      newListSlice = listRigth;

      listTransfer.forEach((item) => {
        setListLeft((listLeft) => [...listLeft, item]);
      });
    }

    listTransfer.forEach((item) => {
      newListSlice.splice(newListSlice.indexOf(item), 1);
    });


    if (list === 'R') {
      setListLeft(newListSlice);
    }
    else if (list === 'L') {
      setListRigth(newListSlice);
    }

    setListTransfer([]);
  };

  function addListToTransf(element: any, item: any, var_direction: any) {
    if (element.target.checked) {
      setListTransfer((listTransfer) => [...listTransfer, item]);

      setDirection(var_direction);
    }
    else {
      const newList = [];

      listTransfer.forEach((itemOfList) => {
        if (item !== itemOfList) {
          newList.push(itemOfList);
        }
      });
      setListTransfer(newList);
    }
  };


  useEffect(() => {
    if (listTransfer.length === 0) {
      setDirection('N');
    }
  }, [listTransfer.length]);

  useEffect(() => {
    props.returnLeft && props.returnLeft(listLeft);
    props.returnRight && props.returnRight(listRigth);
  }, [listLeft, listRigth]);



  return (
    <ContainerStyle size={props?.size}>
      {props?.titles?.header && <HeaderStyle> {props?.titles?.header} </HeaderStyle>}

      <RowStyle>

        <ListStyleLeft>

          <ListGroupStyle>

            <TitleListsHeader> {props?.titles?.listLeft} </TitleListsHeader>


            {listLeft.map((item, index) => (

              <ItemStyle>

                <Form.Check
                  disabled={direction === 'R' || props.disabled}
                  key={`${item.value}${index}`}
                  id={`${item.value}${index}`}
                  onChange={(element) => addListToTransf(element, item, 'L')}
                  type={'checkbox'}
                  label={item.label}
                />

              </ItemStyle>

            ))}

          </ListGroupStyle>

        </ListStyleLeft>


        <AreaButtonList>

          <ButtonListStyle
            disabled={direction === 'L' || props.disabled}
            onClick={() => (listTransfer.length !== 0) && transferToList('L')}
          >
            <IconReturn color={Colors?.white} />
          </ButtonListStyle>


          <ButtonListStyle
            disabled={direction === 'R' || props.disabled}
            color={Colors?.buttonCancel}
            onClick={() => (listTransfer.length !== 0) && transferToList('R')}
          >
            <IconNext color={Colors?.white} />
          </ButtonListStyle>

        </AreaButtonList>



        <ListStyleRight>

          <ListGroupStyle>

            <TitleListsHeader color={Colors?.buttonCancel}>{props?.titles?.listRight}</TitleListsHeader>


            {listRigth.map((item, index) => (

              <ItemStyle>

                <Form.Check
                  disabled={direction === 'L' || props.disabled}
                  key={`${item.value}${index}`}
                  id={`${item.value}${index}`}
                  onChange={(element) => addListToTransf(element, item, 'R')}
                  type={'checkbox'}
                  label={item.label}
                />

              </ItemStyle>

            ))}

          </ListGroupStyle>

        </ListStyleRight>


      </RowStyle>

    </ContainerStyle>
  );
};


export default ListTransfer;
