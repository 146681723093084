import * as Yup from 'yup';



const SMEOriDesValidationSchema = Yup.object().shape({
  
  ORIGEM: Yup.string(),
  DESTINO: Yup.string(),

});



export default SMEOriDesValidationSchema;
