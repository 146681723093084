import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';
import IButton from '../../../../../components/Buttons/IButton';


import {
  ModalContainerStyled,
  ModalFooterStyled,
  ImgStyled,
  ImgContainer,
  TextContainer,
  RowBodyStyled,
} from './styled';



interface IProps {
  show: boolean;
  onHide?: any;
  data: any;
  values: any;
}



const CompetencyModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.values.titulo.toUpperCase()}
        </TitleGroup>



        <RowBodyStyled>

          <TextContainer>
            {props.values.conceito}
          </TextContainer>


          <ImgContainer>
            <ImgStyled
              alt={props.values.titulo}
              src={props.values.img}
            />
          </ImgContainer>

        </RowBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.gray}
            text={'Cancelar'}
            onClick={props.onHide}
          />

          <IButton
            backgroundColor={Colors?.primary}
            text={'Li e Confirmo'}
            onClick={() => {
              props.data({ PILAR: `${props.values.id}` });

              setTimeout(() => {
                props.onHide();
              },300);
            }}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default CompetencyModal;
