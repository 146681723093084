import styled from 'styled-components';

import {
  Row,
} from 'react-bootstrap';



export const RowContratosLegends = styled(Row)`
  max-width: 20%;
`;

export const StatusContratosView = styled.div`
`;
