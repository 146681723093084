import styled from 'styled-components';

import {
  Form,
} from 'react-bootstrap';
import { Colors } from '../../../common/constants';

export const CheckBoxContainer = styled(Form.Check)`
  .form-check-input {
      width: 1.2em;
      height: 1.2em;
      cursor: pointer;

      :checked {
        background-color: ${Colors?.primary};
        border-color: ${Colors?.secondaryLight};
      }

      :focus {
        box-shadow: 0 0 0 0.25rem ${Colors?.secondaryLight};
      }
  }
`;
