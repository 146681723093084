import React from 'react';

import {
  HomeCardContainer,
  CardDashboardTitle,
  CardDashboardBody,
  CardDashChildren,
} from './styled';



interface IProps {
  title: string;
  icon?: any;
  color?: string;
  children: any;
}



const CardDashboard: React.FC<IProps> = (props) => {
  return (

    <HomeCardContainer>

      <CardDashboardTitle
        color={props.color}>
        {props.title}
      </CardDashboardTitle>


      <CardDashboardBody>
        <CardDashChildren>

          {props.children}

        </CardDashChildren>
      </CardDashboardBody>

    </HomeCardContainer>

  );
};



export default CardDashboard;
