import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';
import { Images } from '../../../../../common/constants';



import {
  Content,
  ContentStyled,
  Screen,
  TabIconStyled,
  TabsStyled,
  TabTextStyled,
  TabViewStyled,
} from '../../../../../common/styles/styled.layout';
import EmptyContent from '../../../../../components/Empty';


import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';


import { IconCA, IconEffective, IconIndirectLabor, IconNetwork, IconOrganization, IconSystem, IconUser } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';



import { GI_NavRoutes } from '../../../../../navigation/navs/TI';
import ApiWS from '../../../../../services/api.service';



import Tab_DepartamentoScreen from './GI_Tabs/001GI_Departamento';
import Tab_SistemaScreen from './GI_Tabs/002GI_Sistema';
import Tab_RegraScreen from './GI_Tabs/003GI_Regra';
import Tab_GrupoScreen from './GI_Tabs/004GI_Grupo';
import Tab_CCScreen from './GI_Tabs/005GI_CentroCusto';
import Tab_FuncaoScreen from './GI_Tabs/006GI_Funcao';
import Tab_UsuarioScreen from './GI_Tabs/007GI_Usuario';



const GI_AdministracaoScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.error401,
    title: 'Sem Permissão!',
    description: 'Você não tem permissão para acessar essa tela!',
  });


  function postPermission() {
    setLoading(true);

    ApiWS()
      .post('gi/administracao/permissao')
      .then((res) => {
        if (res.status === 200) {
          setPermission(true);
        }
        else if (res.status === 203) {
          setPermission(false);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        console.error('postPermission', JSON.stringify(err));
        setPermission(false);

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });
      })
      .finally(() => setLoading(false));
  }


  useEffect(() => {
    postPermission();
  }, []);


  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconNetwork />,
        text: 'Departamento',
      },
      screenTab: <Tab_DepartamentoScreen />,
    },
    {
      eventKey: 1,
      title: {
        icon: <IconSystem />,
        text: 'Sistema',
      },
      screenTab: <Tab_SistemaScreen />,
    },
    {
      eventKey: 2,
      title: {
        icon: <IconCA />,
        text: 'Regra',
      },
      screenTab: <Tab_RegraScreen />,
    },
    {
      eventKey: 3,
      title: {
        icon: <IconEffective />,
        text: 'Grupo',
      },
      screenTab: <Tab_GrupoScreen />,
    },
    {
      eventKey: 4,
      title: {
        icon: <IconOrganization />,
        text: 'Centro de Custos',
      },
      screenTab: <Tab_CCScreen />,
    },
    {
      eventKey: 5,
      title: {
        icon: <IconIndirectLabor />,
        text: 'Função',
      },
      screenTab: <Tab_FuncaoScreen />,
    },
    {
      eventKey: 6,
      title: {
        icon: <IconUser />,
        text: 'Usuário',
      },
      screenTab: <Tab_UsuarioScreen />,
    },
  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={GI_NavRoutes}
          title={'GI'}
        />
      </NavHeader>


      {loading && (
        <LoadingsComponent />
      )}


      {!loading && !permission && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}


      {!loading && permission && (

        <Content>
          <ContentStyled fluid>

            <TabsStyled
              id={'control-tab-pcb'}
              activeKey={currentTab}
              onSelect={(tab) => setCurrentTab(parseInt(tab))}
            >

              {tabsList.map((tab) => (

                <Tab
                  eventKey={tab.eventKey}
                  title={
                    <TabViewStyled>

                      <TabIconStyled>
                        {tab.title.icon}
                      </TabIconStyled>


                      <TabTextStyled>
                        {tab.title.text}
                      </TabTextStyled>

                    </TabViewStyled>
                  }>

                  {tab.screenTab}

                </Tab>

              ))}

            </TabsStyled>

          </ContentStyled>

        </Content>

      )}

    </Screen>

  );
};



export default GI_AdministracaoScreen;
