import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown } from 'react-bootstrap';
import { Formik } from 'formik';
import { Colors, Images } from '../../../../../../common/constants';
import {
  ContainerView,
  Content,
  FilterContainerForm,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import EmptyContent from '../../../../../../components/Empty';
import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';
import ImportModal from '../../Modals/ImportarPFFModal';
import { ButtonAreaColStyled, ButtonSend, DropdownItem, FieldAreaRowStyled as FieldsAreaRowStyled } from './PFF-lancamento-styled';
import PffLancamentoInicioSchema from './PFF-lancamento-validation';
import { releaseTypeList } from './PFF-lancamento.array';
import PffLancamentoInicioModel from './PFF-lancamento.model';


interface IProps {
  releaseType?: any;
}

const PFF_InicioScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [authToPff, setAuthToPff] = useState(false);
  const [listBusiness, setListBusiness] = useState([]);
  const [listOs, setListOs] = useState([]);

  const [isImport] = useState(false);

  const [importModalShow, setImportModalShow] = useState(false);
  const [importModalValues, setImportModalValues] = useState(null);


  async function postPermissionToPFF() {
    setLoading(true);

    ApiWS()
      .post('/pff/lancamento/permissao')
      .then((resp) => {
        if (resp.status === 200) {
          setAuthToPff(true);

          postFetchBusiness();
        }
      })
      .catch((_err) => IToast({ type: 'error', message: 'Ocorreu um erro ao autorizar acesso, contate o suporte!' }))
      .finally(() => setLoading(false));
  };


  const postFetchBusiness = () => {
    setLoading(true);

    const url = '/pff/lancamento/negoc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((neg, index) => {
            setListBusiness((listBusiness) => [...listBusiness, {
              key: index,
              label: neg.NEGOC,
              value: neg.VALOR,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchListOS(values) {
    setLoading(true);
    setListOs([]);

    const url = '/pff/lancamento/os';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((os, index) => {
            setListOs((listOS) => [...listOS, {
              key: index,
              label: os.OS,
              value: os.OS,
            }],
            );
          });
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  async function postRelease(values) {
    setLoading(true);

    const url = '/pff/lancamento/permissaotipo';
    const payload = {
      TIPO: values.COD_LANCAMENTO,
      CC: values.CC,
      ANO: values.ANO,
      NEG: values.NEGOCIO,
      TIPORE: values.TIPORE,
    };

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(resp.data));
          props.releaseType(values);
        }
        else if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })
      .catch((_err) => IToast({
        type: 'error',
        message: 'Ocorreu um erro, contate o suporte!',
      }))
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    postPermissionToPFF();
    /* FIXME: Comentar linha abaixo para subir homolog/prod */
    // postRelease(PffLancamentoInicioModel);
  }, []);



  return (

    <ContainerView>



      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={PffLancamentoInicioSchema}
        initialValues={{
          ...PffLancamentoInicioModel,
          ANO: new Date().getFullYear(),
        }}
        onSubmit={(values) => {
          if (!isImport) {
            if (values.COD_LANCAMENTO !== 'IMP' && values.COD_LANCAMENTO !== 'RE') {
              postRelease(values);
            }
            else if (values.COD_LANCAMENTO === 'IMP') {
              setImportModalShow(true);
              setImportModalValues(values);
            }
            else if (values.COD_LANCAMENTO === 'RE') {
              props.releaseType(values);
            }
          }
        }}
      >
        {({
          isValid,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <>

            {loading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}

            {!authToPff && !loading && (
              <EmptyContent
                image={Images.error401}
                title={'Não autorizado.'}
                description={'Não há nada aqui!'}
              />
            )}

            {authToPff && !loading && <>
              <TitleGroup>
                {'Lançamento'.toUpperCase()}
              </TitleGroup>

              <Content>

                <Container>

                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <FieldsAreaRowStyled>

                      <Col xl={2}>
                        <TextInput
                          type={'text'}
                          name={'ANO'}
                          label={'Ano'}
                          placeholder={'Ano'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ANO} />
                      </Col>



                      <Col xl={4}>
                        <DropdownInput
                          name={'NEGOCIO'}
                          label={'Negócio'}
                          placeholder={'Selecione'}
                          dropArray={listBusiness}
                          onChange={(e) => {
                            handleChange(e);

                            postFetchListOS(
                              { NEGOC: e.target.value },
                            );
                          }}
                          onBlur={handleBlur}
                          value={values.NEGOCIO} />
                      </Col>



                      <Col xl={3}>
                        <DropdownInput
                          name={'CC'}
                          label={'CC'}
                          placeholder={'Selecione'}
                          dropArray={listOs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.CC} />
                      </Col>



                      <ButtonAreaColStyled>


                        <Dropdown autoClose={false}>


                          <ButtonSend>
                            Lançar
                          </ButtonSend>


                          <Dropdown.Menu>
                            {releaseTypeList.map((release, index) => {
                              return (
                                <>
                                  {release.header && (
                                    <Dropdown.Header key={index}> {release.header} </Dropdown.Header>
                                  )}


                                  {release.label && (
                                    <DropdownItem key={index} onClick={() => {
                                      if (release.cod !== 'RE') {
                                        if (isValid) {
                                          setFieldValue('LANCAMENTO', release.label);
                                          setFieldValue('COD_LANCAMENTO', release.cod);
                                        }
                                      }

                                      if (release.cod === 'RE') {
                                        setFieldValue('NEGOCIO', 'TODOS');
                                        setFieldValue('CC', 'TODOS');
                                        setFieldValue('LANCAMENTO', release.label);
                                        setFieldValue('COD_LANCAMENTO', release.cod);
                                        setFieldValue('TIPORE', 'NA');
                                      }

                                      setTimeout(() => {
                                        handleSubmit();
                                      }, 300);
                                    }}>
                                      {release.label.replace('Orçado', '')}
                                    </DropdownItem>
                                  )}


                                  {release.div && (<Dropdown.Divider key={index} />)}
                                </>
                              );
                            })}

                          </Dropdown.Menu>

                        </Dropdown>



                      </ButtonAreaColStyled>


                    </FieldsAreaRowStyled>

                  </FilterContainerForm>

                </Container>

              </Content>
            </>}
          </>

        )}

      </Formik>

      <ImportModal
        show={importModalShow}
        values={importModalValues}
        onHide={() => {
          setImportModalShow(false);
          setImportModalValues(null);
        }}
        postRelease={() => {
          postRelease({ ...importModalValues, COD_LANCAMENTO: 'PR' });

          setImportModalShow(false);
        }}
      />


    </ContainerView>

  );
};

export default PFF_InicioScreen;
