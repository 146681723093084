const IPicCadastroEquipeModel = {
  CC: '',

  DESCR: '',
  
  LIDER: '',
  
};



export default IPicCadastroEquipeModel;
