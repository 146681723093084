import styled from 'styled-components';

interface IProps {
  margin?: any;
}

export const HeaderUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 35px;
`;

export const BoxImg = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 20rem;
  text-align: center;
  vertical-align: center;
`;


export const HeaderUserImage = styled.img`
  max-width: 190px;
  max-height: 190px;
  background-color: #fff;
  /* border: 1px solid #dee2e6; */
  border-radius: 20rem;
  object-fit: cover;
`;

export const UserInfoHeaderView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 50px;
`;

export const HeaderUserName = styled.h2`
  margin: 0 0 15px;
  font-size: 1.75rem;
`;

export const UserInfoDomainContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
`;


export const HeaderDomainText = styled.h4`
  display: flex;
  flex: 1;
  margin: 0;
  font-size: 1.125rem;
  font-weight: normal;
`;

export const HeaderInfoText = styled.h4`
  display: flex;
  flex: 1;
  margin: 0;
  font-size: 1.125rem;
  font-weight: normal;
`;

export const AreaEditPhoto = styled.div`
    color: rgba(0, 0, 0, 0.816);
    margin-top: 250px;
    margin-left: -170px;
    margin-right: 190px;
`;

export const LabelPhoto = styled.label`
  margin: ${((props: IProps) => props.margin)};
`;

export const InputFilePhoto = styled.input`
  display:none;
`;
