import React, { useEffect, useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { TypeSystem } from '../../../../../common/arrays/GI';
import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const RegrasCUDGIModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listDepartment, setListSystem] = useState([]);


  function postFetchSystem(values) {
    setLoading(true);

    const url = 'gi/sistema/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((dep, index) => {
            setListSystem(
              (list) => [...list, {
                key: index,
                label: dep.DESCR,
                value: dep.ID,
              }],
            );
          });
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchDepartment', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postSaveRoule(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/regra/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/regra/alterar';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveRoule', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemoveRoule(values) {
    setLoading(true);

    let url = '';

    url = 'gi/regra/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveRoule', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    postFetchSystem({ DESCR: '', IDDEP: '' });
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || '',
            DESCR: props.valuesEdit?.DESCR || props?.valuesRemove?.DESCR || '',
            IDSIS: props.valuesEdit?.IDSIS || props?.valuesRemove?.IDSIS || '',
            COD: props?.valuesEdit?.COD || props?.valuesRemove?.COD || '',
            TIPO: props?.valuesEdit?.TIPO || props?.valuesRemove?.TIPO || '',
          }}
          validationSchema={
            Yup.object().shape({
              ID: props.type !== 'NEW' ? Yup.string().required('Obrigatório') : Yup.string(),
              DESCR: Yup.string().required('Obrigatório'),
              IDSIS: Yup.string().required('Obrigatório'),
              COD: Yup.string().required('Obrigatório'),
              TIPO: Yup.string().required('Obrigatório'),
            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveRoule(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveRoule(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'DESCR'}
                  label={'Nova Regra'}
                  type={'text'}
                  placeholder={'Descrição'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.DESCR}
                />


                <DropdownInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'IDSIS'}
                  label={'Sistema'}
                  placeholder={'Selecione'}
                  defaultValue={values.IDSIS}
                  dropArray={listDepartment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDSIS || ''}
                />


                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'COD'}
                  label={'Código'}
                  type={'text'}
                  placeholder={'Código da nova regra.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.COD || ''}
                />


                <DropdownInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'TIPO'}
                  label={'Tipo'}
                  placeholder={'Selecione'}
                  dropArray={TypeSystem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.TIPO || ''}
                />

              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default RegrasCUDGIModal;
