import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Colors } from '../../../../../common/constants';


interface IProps {
  noMargin?: boolean;
}


export const ModalContainerStyled = styled.div`
  padding: 0 1rem 1.3rem;
`;


export const ModalBodyStyled = styled.div`
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 1.5rem 0;
`;


export const ModalFilterView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .filter-input-view{
    width: 100%;
  }
`;

export const RowBodyStyled = styled(Row)`
  display: flex;
`;

export const TextContainer = styled(Col)`
  width: 100px;
  height: 250px;
  margin: 0 0 10px 50px;
  padding: 10px;
  overflow-y: scroll;
  border: solid 0.5px ${Colors?.grayLight};
`;

export const ImgContainer = styled(Col)`
  margin-left: 70px;
`;

export const ImgStyled = styled.img`
  height: 200px;
  width:  200px;
  max-height: 200px;
  max-width:  200px;
  padding: 5px;
  box-shadow: 5px 10px 10px ${Colors?.gray};
`;

export const ModalGridContainer = styled.div`
    margin-left: 16%;
    margin-right: 50%;
    margin-top: ${(props: IProps) => props.noMargin ? '0' : '1.5rem'};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
`;
