import NameRoutes from '../name';



export const PFF_NavRoutes = [
  {
    key: 1,
    label: 'Lançamento',
    path: `/${NameRoutes.PFF_Lancamento}`,
  },
  {
    key: 2,
    label: 'Consulta',
    path: `/${NameRoutes.PFF_Consulta}`,
  },
  {
    key: 3,
    label: 'Aprovação',
    path: `/${NameRoutes.PFF_Aprovacao}`,
  },
];
