import * as Yup from 'yup';



const CHRConsultaSchema = Yup.object().shape({
  OS: Yup.string().required('Obrigatorio!'),
  TIPO: Yup.string().required('Obrigatorio!'),
  MOTIVO: Yup.string().required('Obrigatorio!'),

});



export default CHRConsultaSchema;
