import * as Yup from 'yup';



const GISistemaValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),
  IDDEP: Yup.string(),

});



export default GISistemaValidationSchema;
