import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const ColButtonReturn = styled(Col)`
    display: flex;
    justify-content: flex-start;
    margin: -0.9em -5.4em -1em 3em;
`;


export const ColButtonApproval = styled(Col)`
    display: flex;
    justify-content: flex-end;
    margin-right: 10%;
`;



