export function validateString(value: string) {
  if (value && (value !== null || value !== '' || value !== undefined)) {
    const valueSplit = value.trim();
    return String(valueSplit);
  }
  else {
    return null;
  }
}



export function validateNumber(value: string) {
  if (value && (value !== null || value !== '' || value !== undefined)) {
    return Number(value);
  }
  else {
    return null;
  }
}



export function accentRemove(value: string) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}



export function numberOnlyAccept(value: string) {
  if (value?.match('[^0-9]')){
    return false; 
  }
  else {
    return true; 
  }
}
