import React from 'react';
import { Row } from 'react-bootstrap';
import { YesNoArray2 } from '../../../../../../../../common/arrays/YesNo';
import { TitleGroup } from '../../../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../../components/TextFields/Input';
import { ColItems_C, ColMeets_C, ColObsColab_C, ColImproviment_C, AreaFieldsSeparate, ColDescr_C, ColLeader_C } from '../../../styled';

interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}

const POCSectionC: React.FC<IProps> = (props) => {
  const { handleBlur, handleChange, values, setFieldValue } = props;

  return (
    <AreaFieldsSeparate>

      <Row>
        <TitleGroup>
          {'C - Controle Operacional'.toUpperCase()}
        </TitleGroup>

        <Row>
          <ColItems_C>Itens</ColItems_C>
          <ColLeader_C><span>Líder</span></ColLeader_C>
          <ColDescr_C><span>Descrição</span></ColDescr_C>
          <ColMeets_C><span>Atende</span></ColMeets_C>
          <ColObsColab_C><span>Colab. Observ.</span></ColObsColab_C>
          <ColImproviment_C><span>Melhorias</span></ColImproviment_C>
        </Row>
      </Row>


      {/* C1 */}
      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C1'}
            label={'C1'}
            onBlur={handleBlur}
            value={'Análise de Risco  ou Procedimentos de SMS/EHS, estão entendidos e acessíveis para consulta durante execução da tarefa?'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C1_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('C1_NCOLABORADOR', '');
                setFieldValue('C1_NMELHORIA', '');
                setFieldValue('C1_IDEQUIPE', '');
                setFieldValue('C1_LIDER', '');
                setFieldValue('C1_DESCR', '');
              }
              else {
                setFieldValue('C1_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('C1_LIDER', e.target.value.split(',')[1]);
                setFieldValue('C1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.C1_DESCR}
          />
        </ColDescr_C>


        <ColMeets_C>
          <DropdownInput
            name={'C1_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_C>


        <ColObsColab_C>
          <TextInput
            disabled={values.C1_CTENDE === ''}
            type={'number'}
            name={'C1_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C1_NCOLABORADORES}
          />
        </ColObsColab_C>


        <ColImproviment_C>
          <TextInput
            disabled={values.C1_CTENDE === ''}
            type={'number'}
            name={'C1_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C1_NMELHORIAS}
          />
        </ColImproviment_C>

      </Row>


      {/* C2 */}
      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C2'}
            label={'C2'}
            onBlur={handleBlur}
            value={'Orientação vaga ou precaria para o trabalho (Análise de Risco ou   Procedimento estão atualizados? Livres de erros ou com prazo vencido? Contém termos vagos como "se necessário", "se disponível“?'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C2_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('C2_NCOLABORADOR', '');
                setFieldValue('C2_NMELHORIA', '');
                setFieldValue('C2_IDEQUIPE', '');
                setFieldValue('C2_LIDER', '');
                setFieldValue('C2_DESCR', '');
              }
              else {
                setFieldValue('C2_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('C2_LIDER', e.target.value.split(',')[1]);
                setFieldValue('C2_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.C2_DESCR}
          />
        </ColDescr_C>


        <ColMeets_C>
          <DropdownInput
            name={'C2_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_C>


        <ColObsColab_C>
          <TextInput
            disabled={values.C2_CTENDE === ''}
            type={'number'}
            name={'C2_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C2_NCOLABORADORES}
          />
        </ColObsColab_C>


        <ColImproviment_C>
          <TextInput
            disabled={values.C2_CTENDE === ''}
            type={'number'}
            name={'C2_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C2_NMELHORIAS}
          />
        </ColImproviment_C>

      </Row>


      {/* C3 */}
      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C3'}
            label={'C3'}
            onBlur={handleBlur}
            value={'Os colaboradores conhecem as fontes de energias (quimica, fisica, térmica, hidráulica, mecânica e pneumática) inerentes a tarefa?'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C3_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('C3_NCOLABORADOR', '');
                setFieldValue('C3_NMELHORIA', '');
                setFieldValue('C3_IDEQUIPE', '');
                setFieldValue('C3_LIDER', '');
                setFieldValue('C3_DESCR', '');
              }
              else {
                setFieldValue('C3_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('C3_LIDER', e.target.value.split(',')[1]);
                setFieldValue('C3_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.C3_DESCR}
          />
        </ColDescr_C>


        <ColMeets_C>
          <DropdownInput
            name={'C3_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_C>


        <ColObsColab_C>
          <TextInput
            disabled={values.C3_CTENDE === ''}
            type={'number'}
            name={'C3_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C3_NCOLABORADORES}
          />
        </ColObsColab_C>


        <ColImproviment_C>
          <TextInput
            disabled={values.C3_CTENDE === ''}
            type={'number'}
            name={'C3_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C3_NMELHORIAS}
          />
        </ColImproviment_C>

      </Row>


      {/* C4 */}
      <Row>

        <ColItems_C>
          <TextInput
            disabled
            type={'text'}
            name={'C4'}
            label={'C4'}
            onBlur={handleBlur}
            value={'Os treinamentos que HABILITAM os colaborador (es) executar a tarefa, estão válidos?'}
          />
        </ColItems_C>


        <ColLeader_C>
          <DropdownInput
            name={'C4_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('C4_NCOLABORADOR', '');
                setFieldValue('C4_NMELHORIA', '');
                setFieldValue('C4_IDEQUIPE', '');
                setFieldValue('C4_LIDER', '');
                setFieldValue('C4_DESCR', '');
              }
              else {
                setFieldValue('C4_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('C4_LIDER', e.target.value.split(',')[1]);
                setFieldValue('C4_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_C>


        <ColDescr_C>
          <TextInput
            disabled
            type={'text'}
            name={'C4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.C4_DESCR}
          />
        </ColDescr_C>


        <ColMeets_C>
          <DropdownInput
            name={'C4_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_C>


        <ColObsColab_C>
          <TextInput
            disabled={values.C4_CTENDE === ''}
            type={'number'}
            name={'C4_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C4_NCOLABORADORES}
          />
        </ColObsColab_C>


        <ColImproviment_C>
          <TextInput
            disabled={values.C4_CTENDE === ''}
            type={'number'}
            name={'C4_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.C4_NMELHORIAS}
          />
        </ColImproviment_C>

      </Row>

    </AreaFieldsSeparate>
  );
};


export default POCSectionC;
