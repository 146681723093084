import styled,
{
  css,
} from 'styled-components';


import {
  Colors,
} from '../../../common/constants';



export const TableActionButton = styled.a`
  border: 1px solid ${Colors?.primary};
  border-radius: 7px;
  margin: 5px;
  cursor: pointer;
  :hover {
    background-color: ${Colors?.secondaryThin};
  }
`;
