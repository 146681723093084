import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';



export const ImgStyled = styled.img`
  margin-top: 10px;
  max-height: 100px;
  max-width: 100px;
`;

export const PAVRowStyled = styled(Row)`
  flex-wrap: nowrap;
`;

export const PAVContentStyled = styled(Col)`
  min-height: 100%;
  margin: 0 auto;
`;

