import React from 'react';

import {
  IconPaperclip,
} from '../../Icons';

import {
  CardImportInputButton,
  CardImportInput,
  CardImportInputIcon,
  CardImportInputLabel,
} from './styled';



interface IProps {
  idButton: string;
  disabled?: any;
  fileGroup?: any;
  onChangeButton?: any;
  accept?: any;
}



const ButtonAttach: React.FC<IProps> = (props) => {
  return (

    <CardImportInputButton htmlFor={props.idButton}>
      <CardImportInput
        disabled={props.disabled}
        type={'file'}
        id={props.idButton}
        onChange={props.onChangeButton}
        accept={props.accept}
      />

      <CardImportInputIcon>
        <IconPaperclip
          color={'#000000'}
          size={20}
        />
      </CardImportInputIcon>

      <CardImportInputLabel>
        {!props.fileGroup
          ? 'Planilha'
          : `${props.fileGroup.name}`
        }
      </CardImportInputLabel>
    </CardImportInputButton>

  );
};



export default ButtonAttach;
