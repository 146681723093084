const Months = [
  { key: 0, label: 'Janeiro', value: '01' },
  { key: 1, label: 'Fevereiro', value: '02' },
  { key: 2, label: 'Março', value: '03' },
  { key: 3, label: 'Abril', value: '04' },
  { key: 4, label: 'Maio', value: '05' },
  { key: 5, label: 'Junho', value: '06' },
  { key: 6, label: 'Julho', value: '07' },
  { key: 7, label: 'Agosto', value: '08' },
  { key: 8, label: 'Setembro', value: '09' },
  { key: 9, label: 'Outubro', value: '10' },
  { key: 10, label: 'Novembro', value: '11' },
  { key: 11, label: 'Dezembro', value: '12' },
];


export default Months;
