import NameRoutes from '../name';



export const PBC_NavRoutes = [
  {
    key: 1,
    label: 'Aprovação',
    path: `/${NameRoutes.PBC_Aprovacao}`,
  },
  {
    key: 2,
    label: 'Pedidos',
    path: `/${NameRoutes.PBC_Pedidos}`,
  },
  {
    key: 3,
    label: 'Contratos',
    path: `/${NameRoutes.PBC_Contratos}`,
  },
  {
    key: 4,
    label: 'Anexo',
    path: `/${NameRoutes.PBC_Anexo}`,
  },
  {
    key: 5,
    label: 'Manutenção',
    path: `/${NameRoutes.PBC_Manutencao}`,
  },
];



export const SME_NavRoutes = [
  {
    key: 1,
    label: 'Início',
    path: `/${NameRoutes.SME_Home}`,
  },
  {
    key: 2,
    label: 'Solicitação',
    path: `/${NameRoutes.SME_Solicitacao}`,
  },
  {
    key: 3,
    label: 'Aprovação',
    path: `/${NameRoutes.SME_Aprovacao}`,
  },
  {
    key: 4,
    label: 'Triagem',
    path: `/${NameRoutes.SME_Triagem}`,
  },
  {
    key: 5,
    label: 'Atendimento',
    path: `/${NameRoutes.SME_Atendimento}`,
  },
  {
    key: 6,
    label: 'Monitor',
    path: `/${NameRoutes.SME_Monitor}`,
  },
  {
    key: 7,
    label: 'Manutenção Triagem',
    path: `/${NameRoutes.SME_ManutencaoTriagem}`,
  },
];



export const PSTOCK_NavRoutes = [
  {
    key: 1,
    label: 'Início',
    path: `/${NameRoutes.PSTOCK_HomeScreen}`,
  },
  {
    key: 2,
    label: 'Administração',
    path: `/${NameRoutes.PSTOCK_AdministracaoScreen}`,
  },
  {
    key: 3,
    label: 'Parametrização',
    path: `/${NameRoutes.PSTOCK_ParametrizacaoScreen}`,
  },
  {
    key: 4,
    label: 'Relatórios',
    path: `/${NameRoutes.PSTOCK_RelatoriosScreen}`,
  },
  {
    key: 5,
    label: 'Monitor',
    path: `/${NameRoutes.PSTOCK_MonitorScreen}`,
  },
];
