import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';


import IButton from '../../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TextInput from '../../../../../../../components/TextFields/Input';


import ApiWS from '../../../../../../../services/api.service';

import PcbManutencaoContratoModel from './PBC-manutencao-tab-contrato.model';
import PcbManutencaoContratoValidationSchema from './PBC-manutencao-tab-contratos.validation';



const Tab_ContratoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({});
  const [requestCheck, setRequestCheck] = useState(false);



  function addZerosInFieldContract(setFieldValue: any, values: any) {
    setFieldValue(
      'input_contrato',
      ('000000000000000' + values.input_contrato).slice(-15),
    );
  };


  async function postContractsFetch(values: any) {
    const payload = {
      'FILIAL': values.input_filial,
      'NUM': values.input_contrato.slice(9,15),
      'SITUAC': 'TODOS',
      'CC': '',
      'FORNECEDOR': '',
      'PLANILHA': '',
      'REVISAO': '',
      'CNPJ': '',
    };

    setLoading(true);

    ApiWS()
      .post('/suprimento/consulta/contrato', payload)
      .then((resp) => {
        if(resp.status === 200 && resp.data.length > 0){
          setValues(values);
          setRequestCheck(true);
        }
        else {
          IToast({
            type: 'warning',
            message: 'Filial ou Contrato não encontrado!',
          });
          setRequestCheck(false);
        }
      })
      .catch((error) => {
        IToast({
          message: `Erro ${error.code}. Contate o suporte!`,
          type: 'error',
        });
        console.error('postContractsFetch', JSON.stringify(error.response, null, 2));
        setRequestCheck(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postChangeContract(values: any) {
    const payload = {
      'CONTRATO': values.input_contrato,
      'FILIAL': values.input_filial,
    };

    setLoading(true);
    ApiWS()
      .post('/suprimento/manutencao/altera/contrato', payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: 'Contrato alterado com sucesso!',
          });
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro ao alterar o contrato! Contate o suporte.',
        });

        console.error('postChangeContrato', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if(requestCheck){
      postChangeContract(values);
    }
  }, [requestCheck]);



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PcbManutencaoContratoValidationSchema}
      initialValues={PcbManutencaoContratoModel}
      onSubmit={(values, { resetForm }) => {
        postContractsFetch(values);
        resetForm();
      }}>
      {({
        values,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Transferir Contrato para Aprovação no ERP'.toUpperCase()}
            </TitleGroup>

            {loading &&
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            }


            <SectionMargin>
              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'input_contrato'}
                      label={'Contrato'}
                      placeholder={'Número do Contrato, permitido menor que 15 caracteres. Ex.: 1911'}
                      value={values.input_contrato}
                      onChange={handleChange}
                      onBlur={(e: any) => {
                        handleBlur(e);
                        addZerosInFieldContract(setFieldValue, values);
                      }}
                    />
                  </Col>


                  <Col>

                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'input_filial'}
                      label={'Filial'}
                      placeholder={'Filial'}
                      value={values.input_filial}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                  </Col>

                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loading}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();

                      setValues(PcbManutencaoContratoModel);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    text={'Transferir'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>
            </SectionMargin>

          </SectionMargin>


        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_ContratoScreen;
