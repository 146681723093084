import React,
{
  useEffect,
  useState,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
  Images,
} from '../../../../../../common/constants';

import {
  ContentStyled,
  RowButtonFiltersView,
  SubTitleGroup,
  ContainerView,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../../components/Empty';
import LoadingsComponent from '../../../../../../components/Loadings';
import TableCuston from '../../../../../../components/Table';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import ModalCreateParams from './ModalCreateParams';



const TabParametros: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [paramsArray, setParamsArray] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Parâmetros aqui!',
    description: 'Não há nenhum parâmetro, faça uma nova busca',
  });


  async function getParamsFunction() {
    try {
      setLoading(true);

      const url = '/pstock/parametro/consulta/parametro';
      const response = await ApiWS().post(url);
      setParamsArray(response.data);
    }
    catch (error) {
      console.error('getParamsFunction', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getParamsFunction();
  }, []);



  const columns = [
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: false,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'AJUDA',
      text: 'Ajuda',
      sort: false,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Data de cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: paramsArray.length,
  };



  return (

    <ContentStyled fluid>

      {loading && (
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.tertiary}
        />
      )}



      {!loading && paramsArray.length === 0 && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}



      {!loading && paramsArray.length > 0 && (
        <ContainerView>

          <SubTitleGroup noMarginTop noMarginBottom>
            {'Parâmetros'.toUpperCase()}
          </SubTitleGroup>


          <RowButtonFiltersView>
            <IButton
              // loading={loadingUpdateConfirmModal}
              // disabled={carregando || listaCarregando}
              backgroundColor={Colors?.tertiaryDark}
              text={'Criar parâmetro'}
              onClick={() => {
                setOpenModalCreate(true);
              }}
            />
          </RowButtonFiltersView>


          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={paramsArray.length < 10}
                data={paramsArray}
                columns={columns}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>

        </ContainerView>
      )}



      {openModalCreate && (
        <ModalCreateParams
          show={openModalCreate}
          negative={(status: boolean) => {
            setOpenModalCreate(false);

            if (status) {
              getParamsFunction();
            }
          }}
        />
      )}

    </ContentStyled>

  );
};



export default TabParametros;
