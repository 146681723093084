import React from 'react';

import {
  WarningLimitSMEView,
  WarningLimitTitle,
  WarningLimitSeparator,
  WarningLimitText,
} from './styled';



export interface IProps {
  title?: string;
  text?: string;
}



const Warning: React.FC<IProps> = (props) => {
  return (

    <WarningLimitSMEView>
      <WarningLimitTitle>
        {props.title}
      </WarningLimitTitle>

      <WarningLimitSeparator />

      <WarningLimitText>
        {props.text}
      </WarningLimitText>
    </WarningLimitSMEView>

  );
};



export default Warning;
