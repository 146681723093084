import {
  toast,
} from 'react-toastify';

import {
  Metrics,
} from '../../common/constants';



export interface IProps {
  type?: 'error' | 'info' | 'success' | 'warning',
  message?: string;
  position?: any,
  autoClose?: any,
  hideProgressBar?: any,
  closeOnClick?: any,
  pauseOnHover?: any,
  draggable?: any,
  progress?: any,
}



export function IToast(props: IProps) {
  toast(
    props.message,
    {
      type: props.type || 'warning',
      position: props.position || 'bottom-center',
      autoClose: props.autoClose || Metrics.messageAutoClose,
      hideProgressBar: props.hideProgressBar || false,
      closeOnClick: props.closeOnClick || true,
      pauseOnHover: props.pauseOnHover || false,
      draggable: props.draggable || true,
      progress: props.progress || undefined,
    },
  );
};
