import React from 'react';

import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToFloat, maskToMoney, maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, RowFieldsGrid, TitleInterGroup } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffFaturamentoModel } from '../PFF-grid.model';



interface IProps {
  indexTab?: number;
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_FaturamentoScreen: React.FC<IProps> = (props) => {
  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[3].FAT;
    let total = 0.00;

    // ORCADO
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      model = {
        ...model,
        DIRETO: { ORCADO: maskToMoney(`${data[0].ORCADO}`) },
        CONTRATUAL_FATURAMENTO: { ORCADO: maskToMoney(`${data[1].ORCADO}`) },
        REAJUSTE: { ORCADO: maskToMoney(`${data[2].ORCADO}`) },
        EXTRA: { ORCADO: maskToMoney(`${data[3].ORCADO}`) },
      };


      data.forEach((value) => {
        total = total + parseFloat(value.ORCADO);
      });


      model = {
        ...model,
        TOTAL_FATURAMENTO: { ORCADO: maskToMoney2(total) },
      };
    };


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      model = {
        ...model,
        DIRETO: { PLANEJADO: maskToMoney(`${data[0].PLANEJADO}`) },
        CONTRATUAL_FATURAMENTO: { PLANEJADO: maskToMoney(`${data[1].PLANEJADO}`) },
        REAJUSTE: { PLANEJADO: maskToMoney(`${data[2].PLANEJADO}`) },
        EXTRA: { PLANEJADO: maskToMoney(`${data[3].PLANEJADO}`) },
      };


      data.forEach((value) => {
        total = total + parseFloat(value.PLANEJADO);
      });


      model = {
        ...model,
        TOTAL_FATURAMENTO: { PLANEJADO: maskToMoney2(total) },
      };
    };


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sum_JAN = 0.00;
      let sum_FEV = 0.00;
      let sum_MAR = 0.00;
      let sum_ABR = 0.00;
      let sum_MAI = 0.00;
      let sum_JUN = 0.00;
      let sum_JUL = 0.00;
      let sum_AGO = 0.00;
      let sum_SET = 0.00;
      let sum_OUT = 0.00;
      let sum_NOV = 0.00;
      let sum_DEZ = 0.00;

      data.forEach((account) => {
        if (account.JAN && account.JAN.indexOf(',') !== -1) {
          account.JAN = account.JAN.replaceAll('.', '');
          account.JAN = account.JAN.replaceAll(',', '.');
        }
        if (account.FEV && account.FEV.indexOf(',') !== -1) {
          account.FEV = account.FEV.replaceAll('.', '');
          account.FEV = account.FEV.replaceAll(',', '.');
        }
        if (account.MAR && account.MAR.indexOf(',') !== -1) {
          account.MAR = account.MAR.replaceAll('.', '');
          account.MAR = account.MAR.replaceAll(',', '.');
        }
        if (account.ABR && account.ABR.indexOf(',') !== -1) {
          account.ABR = account.ABR.replaceAll('.', '');
          account.ABR = account.ABR.replaceAll(',', '.');
        }
        if (account.MAI && account.MAI.indexOf(',') !== -1) {
          account.MAI = account.MAI.replaceAll('.', '');
          account.MAI = account.MAI.replaceAll(',', '.');
        }
        if (account.JUN && account.JUN.indexOf(',') !== -1) {
          account.JUN = account.JUN.replaceAll('.', '');
          account.JUN = account.JUN.replaceAll(',', '.');
        }
        if (account.JUL && account.JUL.indexOf(',') !== -1) {
          account.JUL = account.JUL.replaceAll('.', '');
          account.JUL = account.JUL.replaceAll(',', '.');
        }
        if (account.AGO && account.AGO.indexOf(',') !== -1) {
          account.AGO = account.AGO.replaceAll('.', '');
          account.AGO = account.AGO.replaceAll(',', '.');
        }
        if (account.SET && account.SET.indexOf(',') !== -1) {
          account.SET = account.SET.replaceAll('.', '');
          account.SET = account.SET.replaceAll(',', '.');
        }
        if (account.OUT && account.OUT.indexOf(',') !== -1) {
          account.OUT = account.OUT.replaceAll('.', '');
          account.OUT = account.OUT.replaceAll(',', '.');
        }
        if (account.NOV && account.NOV.indexOf(',') !== -1) {
          account.NOV = account.NOV.replaceAll('.', '');
          account.NOV = account.NOV.replaceAll(',', '.');
        }
        if (account.DEZ && account.DEZ.indexOf(',') !== -1) {
          account.DEZ = account.DEZ.replaceAll('.', '');
          account.DEZ = account.DEZ.replaceAll(',', '.');
        }

        sum_JAN = sum_JAN + parseFloat(account.JAN);
        sum_FEV = sum_FEV + parseFloat(account.FEV);
        sum_MAR = sum_MAR + parseFloat(account.MAR);
        sum_ABR = sum_ABR + parseFloat(account.ABR);
        sum_MAI = sum_MAI + parseFloat(account.MAI);
        sum_JUN = sum_JUN + parseFloat(account.JUN);
        sum_JUL = sum_JUL + parseFloat(account.JUL);
        sum_AGO = sum_AGO + parseFloat(account.AGO);
        sum_SET = sum_SET + parseFloat(account.SET);
        sum_OUT = sum_OUT + parseFloat(account.OUT);
        sum_NOV = sum_NOV + parseFloat(account.NOV);
        sum_DEZ = sum_DEZ + parseFloat(account.DEZ);
      });
      

      model = {
        ...model,
        DIRETO: {
          JAN: maskToMoney(`${data[0].JAN}`),
          FEV: maskToMoney(`${data[0].FEV}`),
          MAR: maskToMoney(`${data[0].MAR}`),
          ABR: maskToMoney(`${data[0].ABR}`),
          MAI: maskToMoney(`${data[0].MAI}`),
          JUN: maskToMoney(`${data[0].JUN}`),
          JUL: maskToMoney(`${data[0].JUL}`),
          AGO: maskToMoney(`${data[0].AGO}`),
          SET: maskToMoney(`${data[0].SET}`),
          OUT: maskToMoney(`${data[0].OUT}`),
          NOV: maskToMoney(`${data[0].NOV}`),
          DEZ: maskToMoney(`${data[0].DEZ}`),
        },
        CONTRATUAL_FATURAMENTO: {
          JAN: maskToMoney(`${data[1].JAN}`),
          FEV: maskToMoney(`${data[1].FEV}`),
          MAR: maskToMoney(`${data[1].MAR}`),
          ABR: maskToMoney(`${data[1].ABR}`),
          MAI: maskToMoney(`${data[1].MAI}`),
          JUN: maskToMoney(`${data[1].JUN}`),
          JUL: maskToMoney(`${data[1].JUL}`),
          AGO: maskToMoney(`${data[1].AGO}`),
          SET: maskToMoney(`${data[1].SET}`),
          OUT: maskToMoney(`${data[1].OUT}`),
          NOV: maskToMoney(`${data[1].NOV}`),
          DEZ: maskToMoney(`${data[1].DEZ}`),
        },
        REAJUSTE: {
          JAN: maskToMoney(`${data[2].JAN}`),
          FEV: maskToMoney(`${data[2].FEV}`),
          MAR: maskToMoney(`${data[2].MAR}`),
          ABR: maskToMoney(`${data[2].ABR}`),
          MAI: maskToMoney(`${data[2].MAI}`),
          JUN: maskToMoney(`${data[2].JUN}`),
          JUL: maskToMoney(`${data[2].JUL}`),
          AGO: maskToMoney(`${data[2].AGO}`),
          SET: maskToMoney(`${data[2].SET}`),
          OUT: maskToMoney(`${data[2].OUT}`),
          NOV: maskToMoney(`${data[2].NOV}`),
          DEZ: maskToMoney(`${data[2].DEZ}`),
        },
        EXTRA: {
          JAN: maskToMoney(`${data[3].JAN}`),
          FEV: maskToMoney(`${data[3].FEV}`),
          MAR: maskToMoney(`${data[3].MAR}`),
          ABR: maskToMoney(`${data[3].ABR}`),
          MAI: maskToMoney(`${data[3].MAI}`),
          JUN: maskToMoney(`${data[3].JUN}`),
          JUL: maskToMoney(`${data[3].JUL}`),
          AGO: maskToMoney(`${data[3].AGO}`),
          SET: maskToMoney(`${data[3].SET}`),
          OUT: maskToMoney(`${data[3].OUT}`),
          NOV: maskToMoney(`${data[3].NOV}`),
          DEZ: maskToMoney(`${data[3].DEZ}`),
        },
        TOTAL_FATURAMENTO: {
          JAN: maskToMoney3(sum_JAN),
          FEV: maskToMoney3(sum_FEV),
          MAR: maskToMoney3(sum_MAR),
          ABR: maskToMoney3(sum_ABR),
          MAI: maskToMoney3(sum_MAI),
          JUN: maskToMoney3(sum_JUN),
          JUL: maskToMoney3(sum_JUL),
          AGO: maskToMoney3(sum_AGO),
          SET: maskToMoney3(sum_SET),
          OUT: maskToMoney3(sum_OUT),
          NOV: maskToMoney3(sum_NOV),
          DEZ: maskToMoney3(sum_DEZ),
        },
      };
    };


    return model;
  };

  function postDraftSave(values: any) {
    alert(JSON.stringify(values));
  };



  const releaseList = ['Total Faturamento', 'Direto', 'Contratual', 'Reajuste', 'Extra'];

  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'DIRETO.ORCADO', label: releaseList[1], value: values.DIRETO.ORCADO },
      { name: 'CONTRATUAL_FATURAMENTO.ORCADO', label: releaseList[2], value: values.CONTRATUAL_FATURAMENTO.ORCADO },
      { name: 'REAJUSTE.ORCADO', label: releaseList[3], value: values.REAJUSTE.ORCADO },
      { name: 'EXTRA.ORCADO', label: releaseList[4], value: values.EXTRA.ORCADO },

    ];
  };

  function calcBudgeted(values: any, setFieldValue: any) {
    let subTotal = 0.00;

    if (values.DIRETO.ORCADO) {
      subTotal = subTotal + maskToFloat(values.DIRETO.ORCADO);
    }
    if (values.CONTRATUAL_FATURAMENTO.ORCADO) {
      subTotal = subTotal + maskToFloat(values.CONTRATUAL_FATURAMENTO.ORCADO);
    }
    if (values.REAJUSTE.ORCADO) {
      subTotal = subTotal + maskToFloat(values.REAJUSTE.ORCADO);
    }
    if (values.EXTRA.ORCADO) {
      subTotal = subTotal + maskToFloat(values.EXTRA.ORCADO);
    }

    setFieldValue('TOTAL_FATURAMENTO.ORCADO', subTotal);
  };

  function saveOnLocalStorageOR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[3].FAT.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.ORCADO = e.target.value;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'DIRETO.PLANEJADO', label: releaseList[1], value: values.DIRETO.PLANEJADO },
      { name: 'CONTRATUAL_FATURAMENTO.PLANEJADO', label: releaseList[2], value: values.CONTRATUAL_FATURAMENTO.PLANEJADO },
      { name: 'REAJUSTE.PLANEJADO', label: releaseList[3], value: values.REAJUSTE.PLANEJADO },
      { name: 'EXTRA.PLANEJADO', label: releaseList[4], value: values.EXTRA.PLANEJADO },

    ];
  };

  function calcPlanned(values: any, setFieldValue: any) {
    let subTotal = 0.00;

    if (values.DIRETO.PLANEJADO) {
      subTotal = subTotal + maskToFloat(values.DIRETO.PLANEJADO);
    }
    if (values.CONTRATUAL_FATURAMENTO.PLANEJADO) {
      subTotal = subTotal + maskToFloat(values.CONTRATUAL_FATURAMENTO.PLANEJADO);
    }
    if (values.REAJUSTE.PLANEJADO) {
      subTotal = subTotal + maskToFloat(values.REAJUSTE.PLANEJADO);
    }
    if (values.EXTRA.PLANEJADO) {
      subTotal = subTotal + maskToFloat(values.EXTRA.PLANEJADO);
    }



    setFieldValue('TOTAL_FATURAMENTO.PLANEJADO', subTotal);
  };

  function saveOnLocalStoragePL(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[3].FAT.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.PLANEJADO = e.target.value;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PREVISTO
  const listFieldsProvider = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_FATURAMENTO.JAN', label: 'JAN', value: values.TOTAL_FATURAMENTO.JAN, typeRelease: releaseList[0], month: 0, disabled: true },
      { name: 'TOTAL_FATURAMENTO.FEV', label: 'FEV', value: values.TOTAL_FATURAMENTO.FEV, typeRelease: releaseList[0], month: 1, disabled: true },
      { name: 'TOTAL_FATURAMENTO.MAR', label: 'MAR', value: values.TOTAL_FATURAMENTO.MAR, typeRelease: releaseList[0], month: 2, disabled: true },
      { name: 'TOTAL_FATURAMENTO.ABR', label: 'ABR', value: values.TOTAL_FATURAMENTO.ABR, typeRelease: releaseList[0], month: 3, disabled: true },
      { name: 'TOTAL_FATURAMENTO.MAI', label: 'MAI', value: values.TOTAL_FATURAMENTO.MAI, typeRelease: releaseList[0], month: 4, disabled: true },
      { name: 'TOTAL_FATURAMENTO.JUN', label: 'JUN', value: values.TOTAL_FATURAMENTO.JUN, typeRelease: releaseList[0], month: 5, disabled: true },
      { name: 'TOTAL_FATURAMENTO.JUL', label: 'JUL', value: values.TOTAL_FATURAMENTO.JUL, typeRelease: releaseList[0], month: 6, disabled: true },
      { name: 'TOTAL_FATURAMENTO.AGO', label: 'AGO', value: values.TOTAL_FATURAMENTO.AGO, typeRelease: releaseList[0], month: 7, disabled: true },
      { name: 'TOTAL_FATURAMENTO.SET', label: 'SET', value: values.TOTAL_FATURAMENTO.SET, typeRelease: releaseList[0], month: 8, disabled: true },
      { name: 'TOTAL_FATURAMENTO.OUT', label: 'OUT', value: values.TOTAL_FATURAMENTO.OUT, typeRelease: releaseList[0], month: 9, disabled: true },
      { name: 'TOTAL_FATURAMENTO.NOV', label: 'NOV', value: values.TOTAL_FATURAMENTO.NOV, typeRelease: releaseList[0], month: 10, disabled: true },
      { name: 'TOTAL_FATURAMENTO.DEZ', label: 'DEZ', value: values.TOTAL_FATURAMENTO.DEZ, typeRelease: releaseList[0], month: 11, disabled: true },

      { name: 'DIRETO.JAN', label: 'JAN', value: values.DIRETO.JAN, typeRelease: releaseList[1], month: 0, disabled: currentMonth >= 1 },
      { name: 'DIRETO.FEV', label: 'FEV', value: values.DIRETO.FEV, typeRelease: releaseList[1], month: 1, disabled: currentMonth >= 2 },
      { name: 'DIRETO.MAR', label: 'MAR', value: values.DIRETO.MAR, typeRelease: releaseList[1], month: 2, disabled: currentMonth >= 3 },
      { name: 'DIRETO.ABR', label: 'ABR', value: values.DIRETO.ABR, typeRelease: releaseList[1], month: 3, disabled: currentMonth >= 4 },
      { name: 'DIRETO.MAI', label: 'MAI', value: values.DIRETO.MAI, typeRelease: releaseList[1], month: 4, disabled: currentMonth >= 5 },
      { name: 'DIRETO.JUN', label: 'JUN', value: values.DIRETO.JUN, typeRelease: releaseList[1], month: 5, disabled: currentMonth >= 6 },
      { name: 'DIRETO.JUL', label: 'JUL', value: values.DIRETO.JUL, typeRelease: releaseList[1], month: 6, disabled: currentMonth >= 7 },
      { name: 'DIRETO.AGO', label: 'AGO', value: values.DIRETO.AGO, typeRelease: releaseList[1], month: 7, disabled: currentMonth >= 8 },
      { name: 'DIRETO.SET', label: 'SET', value: values.DIRETO.SET, typeRelease: releaseList[1], month: 8, disabled: currentMonth >= 9 },
      { name: 'DIRETO.OUT', label: 'OUT', value: values.DIRETO.OUT, typeRelease: releaseList[1], month: 9, disabled: currentMonth >= 10 },
      { name: 'DIRETO.NOV', label: 'NOV', value: values.DIRETO.NOV, typeRelease: releaseList[1], month: 10, disabled: currentMonth >= 11 },
      { name: 'DIRETO.DEZ', label: 'DEZ', value: values.DIRETO.DEZ, typeRelease: releaseList[1], month: 11, disabled: currentMonth >= 12 },

      { name: 'CONTRATUAL_FATURAMENTO.JAN', label: 'JAN', value: values.CONTRATUAL_FATURAMENTO.JAN, typeRelease: releaseList[2], month: 0, disabled: currentMonth >= 1 },
      { name: 'CONTRATUAL_FATURAMENTO.FEV', label: 'FEV', value: values.CONTRATUAL_FATURAMENTO.FEV, typeRelease: releaseList[2], month: 1, disabled: currentMonth >= 2 },
      { name: 'CONTRATUAL_FATURAMENTO.MAR', label: 'MAR', value: values.CONTRATUAL_FATURAMENTO.MAR, typeRelease: releaseList[2], month: 2, disabled: currentMonth >= 3 },
      { name: 'CONTRATUAL_FATURAMENTO.ABR', label: 'ABR', value: values.CONTRATUAL_FATURAMENTO.ABR, typeRelease: releaseList[2], month: 3, disabled: currentMonth >= 4 },
      { name: 'CONTRATUAL_FATURAMENTO.MAI', label: 'MAI', value: values.CONTRATUAL_FATURAMENTO.MAI, typeRelease: releaseList[2], month: 4, disabled: currentMonth >= 5 },
      { name: 'CONTRATUAL_FATURAMENTO.JUN', label: 'JUN', value: values.CONTRATUAL_FATURAMENTO.JUN, typeRelease: releaseList[2], month: 5, disabled: currentMonth >= 6 },
      { name: 'CONTRATUAL_FATURAMENTO.JUL', label: 'JUL', value: values.CONTRATUAL_FATURAMENTO.JUL, typeRelease: releaseList[2], month: 6, disabled: currentMonth >= 7 },
      { name: 'CONTRATUAL_FATURAMENTO.AGO', label: 'AGO', value: values.CONTRATUAL_FATURAMENTO.AGO, typeRelease: releaseList[2], month: 7, disabled: currentMonth >= 8 },
      { name: 'CONTRATUAL_FATURAMENTO.SET', label: 'SET', value: values.CONTRATUAL_FATURAMENTO.SET, typeRelease: releaseList[2], month: 8, disabled: currentMonth >= 9 },
      { name: 'CONTRATUAL_FATURAMENTO.OUT', label: 'OUT', value: values.CONTRATUAL_FATURAMENTO.OUT, typeRelease: releaseList[2], month: 9, disabled: currentMonth >= 10 },
      { name: 'CONTRATUAL_FATURAMENTO.NOV', label: 'NOV', value: values.CONTRATUAL_FATURAMENTO.NOV, typeRelease: releaseList[2], month: 10, disabled: currentMonth >= 11 },
      { name: 'CONTRATUAL_FATURAMENTO.DEZ', label: 'DEZ', value: values.CONTRATUAL_FATURAMENTO.DEZ, typeRelease: releaseList[2], month: 11, disabled: currentMonth >= 12 },

      { name: 'REAJUSTE.JAN', label: 'JAN', value: values.REAJUSTE.JAN, typeRelease: releaseList[3], month: 0, disabled: currentMonth >= 1 },
      { name: 'REAJUSTE.FEV', label: 'FEV', value: values.REAJUSTE.FEV, typeRelease: releaseList[3], month: 1, disabled: currentMonth >= 2 },
      { name: 'REAJUSTE.MAR', label: 'MAR', value: values.REAJUSTE.MAR, typeRelease: releaseList[3], month: 2, disabled: currentMonth >= 3 },
      { name: 'REAJUSTE.ABR', label: 'ABR', value: values.REAJUSTE.ABR, typeRelease: releaseList[3], month: 3, disabled: currentMonth >= 4 },
      { name: 'REAJUSTE.MAI', label: 'MAI', value: values.REAJUSTE.MAI, typeRelease: releaseList[3], month: 4, disabled: currentMonth >= 5 },
      { name: 'REAJUSTE.JUN', label: 'JUN', value: values.REAJUSTE.JUN, typeRelease: releaseList[3], month: 5, disabled: currentMonth >= 6 },
      { name: 'REAJUSTE.JUL', label: 'JUL', value: values.REAJUSTE.JUL, typeRelease: releaseList[3], month: 6, disabled: currentMonth >= 7 },
      { name: 'REAJUSTE.AGO', label: 'AGO', value: values.REAJUSTE.AGO, typeRelease: releaseList[3], month: 7, disabled: currentMonth >= 8 },
      { name: 'REAJUSTE.SET', label: 'SET', value: values.REAJUSTE.SET, typeRelease: releaseList[3], month: 8, disabled: currentMonth >= 9 },
      { name: 'REAJUSTE.OUT', label: 'OUT', value: values.REAJUSTE.OUT, typeRelease: releaseList[3], month: 9, disabled: currentMonth >= 10 },
      { name: 'REAJUSTE.NOV', label: 'NOV', value: values.REAJUSTE.NOV, typeRelease: releaseList[3], month: 10, disabled: currentMonth >= 11 },
      { name: 'REAJUSTE.DEZ', label: 'DEZ', value: values.REAJUSTE.DEZ, typeRelease: releaseList[3], month: 11, disabled: currentMonth >= 12 },

      { name: 'EXTRA.JAN', label: 'JAN', value: values.EXTRA.JAN, typeRelease: releaseList[4], month: 0, disabled: currentMonth >= 1 },
      { name: 'EXTRA.FEV', label: 'FEV', value: values.EXTRA.FEV, typeRelease: releaseList[4], month: 1, disabled: currentMonth >= 2 },
      { name: 'EXTRA.MAR', label: 'MAR', value: values.EXTRA.MAR, typeRelease: releaseList[4], month: 2, disabled: currentMonth >= 3 },
      { name: 'EXTRA.ABR', label: 'ABR', value: values.EXTRA.ABR, typeRelease: releaseList[4], month: 3, disabled: currentMonth >= 4 },
      { name: 'EXTRA.MAI', label: 'MAI', value: values.EXTRA.MAI, typeRelease: releaseList[4], month: 4, disabled: currentMonth >= 5 },
      { name: 'EXTRA.JUN', label: 'JUN', value: values.EXTRA.JUN, typeRelease: releaseList[4], month: 5, disabled: currentMonth >= 6 },
      { name: 'EXTRA.JUL', label: 'JUL', value: values.EXTRA.JUL, typeRelease: releaseList[4], month: 6, disabled: currentMonth >= 7 },
      { name: 'EXTRA.AGO', label: 'AGO', value: values.EXTRA.AGO, typeRelease: releaseList[4], month: 7, disabled: currentMonth >= 8 },
      { name: 'EXTRA.SET', label: 'SET', value: values.EXTRA.SET, typeRelease: releaseList[4], month: 8, disabled: currentMonth >= 9 },
      { name: 'EXTRA.OUT', label: 'OUT', value: values.EXTRA.OUT, typeRelease: releaseList[4], month: 9, disabled: currentMonth >= 10 },
      { name: 'EXTRA.NOV', label: 'NOV', value: values.EXTRA.NOV, typeRelease: releaseList[4], month: 10, disabled: currentMonth >= 11 },
      { name: 'EXTRA.DEZ', label: 'DEZ', value: values.EXTRA.DEZ, typeRelease: releaseList[4], month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  function calcProvider(values: any, setFieldValue: any) {
    let subTotal_JAN = 0.00;
    let subTotal_FEV = 0.00;
    let subTotal_MAR = 0.00;
    let subTotal_ABR = 0.00;
    let subTotal_MAI = 0.00;
    let subTotal_JUN = 0.00;
    let subTotal_JUL = 0.00;
    let subTotal_AGO = 0.00;
    let subTotal_SET = 0.00;
    let subTotal_OUT = 0.00;
    let subTotal_NOV = 0.00;
    let subTotal_DEZ = 0.00;

    if (values.DIRETO.JAN) {
      subTotal_JAN = subTotal_JAN + maskToFloat(values.DIRETO.JAN);
    }
    if (values.CONTRATUAL_FATURAMENTO.JAN) {
      subTotal_JAN = subTotal_JAN + maskToFloat(values.CONTRATUAL_FATURAMENTO.JAN);
    }
    if (values.REAJUSTE.JAN) {
      subTotal_JAN = subTotal_JAN + maskToFloat(values.REAJUSTE.JAN);
    }
    if (values.EXTRA.JAN) {
      subTotal_JAN = subTotal_JAN + maskToFloat(values.EXTRA.JAN);
    }

    
    if (values.DIRETO.JAN) {
      subTotal_FEV = subTotal_FEV + maskToFloat(values.DIRETO.FEV);
    }
    if (values.CONTRATUAL_FATURAMENTO.FEV) {
      subTotal_FEV = subTotal_FEV + maskToFloat(values.CONTRATUAL_FATURAMENTO.FEV);
    }
    if (values.REAJUSTE.FEV) {
      subTotal_FEV = subTotal_FEV + maskToFloat(values.REAJUSTE.FEV);
    }
    if (values.EXTRA.FEV) {
      subTotal_FEV = subTotal_FEV + maskToFloat(values.EXTRA.FEV);
    }


    if (values.DIRETO.MAR) {
      subTotal_MAR = subTotal_MAR + maskToFloat(values.DIRETO.MAR);
    }
    if (values.CONTRATUAL_FATURAMENTO.MAR) {
      subTotal_MAR = subTotal_MAR + maskToFloat(values.CONTRATUAL_FATURAMENTO.MAR);
    }
    if (values.REAJUSTE.MAR) {
      subTotal_MAR = subTotal_MAR + maskToFloat(values.REAJUSTE.MAR);
    }
    if (values.EXTRA.MAR) {
      subTotal_MAR = subTotal_MAR + maskToFloat(values.EXTRA.MAR);
    }


    if (values.DIRETO.ABR) {
      subTotal_ABR = subTotal_ABR + maskToFloat(values.DIRETO.ABR);
    }
    if (values.CONTRATUAL_FATURAMENTO.ABR) {
      subTotal_ABR = subTotal_ABR + maskToFloat(values.CONTRATUAL_FATURAMENTO.ABR);
    }
    if (values.REAJUSTE.ABR) {
      subTotal_ABR = subTotal_ABR + maskToFloat(values.REAJUSTE.ABR);
    }
    if (values.EXTRA.ABR) {
      subTotal_ABR = subTotal_ABR + maskToFloat(values.EXTRA.ABR);
    }


    if (values.DIRETO.MAI) {
      subTotal_MAI = subTotal_MAI + maskToFloat(values.DIRETO.MAI);
    }
    if (values.CONTRATUAL_FATURAMENTO.MAI) {
      subTotal_MAI = subTotal_MAI + maskToFloat(values.CONTRATUAL_FATURAMENTO.MAI);
    }
    if (values.REAJUSTE.MAI) {
      subTotal_MAI = subTotal_MAI + maskToFloat(values.REAJUSTE.MAI);
    }
    if (values.EXTRA.MAI) {
      subTotal_MAI = subTotal_MAI + maskToFloat(values.EXTRA.MAI);
    }


    if (values.DIRETO.JUN) {
      subTotal_JUN = subTotal_JUN + maskToFloat(values.DIRETO.JUN);
    }
    if (values.CONTRATUAL_FATURAMENTO.JUN) {
      subTotal_JUN = subTotal_JUN + maskToFloat(values.CONTRATUAL_FATURAMENTO.JUN);
    }
    if (values.REAJUSTE.JUN) {
      subTotal_JUN = subTotal_JUN + maskToFloat(values.REAJUSTE.JUN);
    }
    if (values.EXTRA.JUN) {
      subTotal_JUN = subTotal_JUN + maskToFloat(values.EXTRA.JUN);
    }


    if (values.DIRETO.JUL) {
      subTotal_JUL = subTotal_JUL + maskToFloat(values.DIRETO.JUL);
    }
    if (values.CONTRATUAL_FATURAMENTO.JUL) {
      subTotal_JUL = subTotal_JUL + maskToFloat(values.CONTRATUAL_FATURAMENTO.JUL);
    }
    if (values.REAJUSTE.JUL) {
      subTotal_JUL = subTotal_JUL + maskToFloat(values.REAJUSTE.JUL);
    }
    if (values.EXTRA.JUL) {
      subTotal_JUL = subTotal_JUL + maskToFloat(values.EXTRA.JUL);
    }


    if (values.DIRETO.AGO) {
      subTotal_AGO = subTotal_AGO + maskToFloat(values.DIRETO.AGO);
    }
    if (values.CONTRATUAL_FATURAMENTO.AGO) {
      subTotal_AGO = subTotal_AGO + maskToFloat(values.CONTRATUAL_FATURAMENTO.AGO);
    }
    if (values.REAJUSTE.AGO) {
      subTotal_AGO = subTotal_AGO + maskToFloat(values.REAJUSTE.AGO);
    }
    if (values.EXTRA.AGO) {
      subTotal_AGO = subTotal_AGO + maskToFloat(values.EXTRA.AGO);
    }


    if (values.DIRETO.SET) {
      subTotal_SET = subTotal_SET + maskToFloat(values.DIRETO.SET);
    }
    if (values.CONTRATUAL_FATURAMENTO.SET) {
      subTotal_SET = subTotal_SET + maskToFloat(values.CONTRATUAL_FATURAMENTO.SET);
    }
    if (values.REAJUSTE.SET) {
      subTotal_SET = subTotal_SET + maskToFloat(values.REAJUSTE.SET);
    }
    if (values.EXTRA.SET) {
      subTotal_SET = subTotal_SET + maskToFloat(values.EXTRA.SET);
    }


    if (values.DIRETO.OUT) {
      subTotal_OUT = subTotal_OUT + maskToFloat(values.DIRETO.OUT);
    }
    if (values.CONTRATUAL_FATURAMENTO.OUT) {
      subTotal_OUT = subTotal_OUT + maskToFloat(values.CONTRATUAL_FATURAMENTO.OUT);
    }
    if (values.REAJUSTE.OUT) {
      subTotal_OUT = subTotal_OUT + maskToFloat(values.REAJUSTE.OUT);
    }
    if (values.EXTRA.OUT) {
      subTotal_OUT = subTotal_OUT + maskToFloat(values.EXTRA.OUT);
    }

    if (values.DIRETO.NOV) {
      subTotal_NOV = subTotal_NOV + maskToFloat(values.DIRETO.NOV);
    }
    if (values.CONTRATUAL_FATURAMENTO.NOV) {
      subTotal_NOV = subTotal_NOV + maskToFloat(values.CONTRATUAL_FATURAMENTO.NOV);
    }
    if (values.REAJUSTE.NOV) {
      subTotal_NOV = subTotal_NOV + maskToFloat(values.REAJUSTE.NOV);
    }
    if (values.EXTRA.NOV) {
      subTotal_NOV = subTotal_NOV + maskToFloat(values.EXTRA.NOV);
    }

    if (values.DIRETO.DEZ) {
      subTotal_DEZ = subTotal_DEZ + maskToFloat(values.DIRETO.DEZ);
    }
    if (values.CONTRATUAL_FATURAMENTO.DEZ) {
      subTotal_DEZ = subTotal_DEZ + maskToFloat(values.CONTRATUAL_FATURAMENTO.DEZ);
    }
    if (values.REAJUSTE.DEZ) {
      subTotal_DEZ = subTotal_DEZ + maskToFloat(values.REAJUSTE.DEZ);
    }
    if (values.EXTRA.DEZ) {
      subTotal_DEZ = subTotal_DEZ + maskToFloat(values.EXTRA.DEZ);
    }



    setFieldValue('TOTAL_FATURAMENTO.JAN', maskToMoney3(subTotal_JAN));
    setFieldValue('TOTAL_FATURAMENTO.FEV', maskToMoney3(subTotal_FEV));
    setFieldValue('TOTAL_FATURAMENTO.MAR', maskToMoney3(subTotal_MAR));
    setFieldValue('TOTAL_FATURAMENTO.ABR', maskToMoney3(subTotal_ABR));
    setFieldValue('TOTAL_FATURAMENTO.MAI', maskToMoney3(subTotal_MAI));
    setFieldValue('TOTAL_FATURAMENTO.JUN', maskToMoney3(subTotal_JUN));
    setFieldValue('TOTAL_FATURAMENTO.JUL', maskToMoney3(subTotal_JUL));
    setFieldValue('TOTAL_FATURAMENTO.AGO', maskToMoney3(subTotal_AGO));
    setFieldValue('TOTAL_FATURAMENTO.SET', maskToMoney3(subTotal_SET));
    setFieldValue('TOTAL_FATURAMENTO.OUT', maskToMoney3(subTotal_OUT));
    setFieldValue('TOTAL_FATURAMENTO.NOV', maskToMoney3(subTotal_NOV));
    setFieldValue('TOTAL_FATURAMENTO.DEZ', maskToMoney3(subTotal_DEZ));
  };
  
  function saveOnLocalStoragePR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[3].FAT.forEach((invoicing) => {
      if (invoicing.TIPO === field.typeRelease) {
        switch (field.label) {
          case 'JAN':
            invoicing.JAN = maskToMoney(e.target.value);
            break;
          case 'FEV':
            invoicing.FEV = maskToMoney(e.target.value);
            break;
          case 'MAR':
            invoicing.MAR = maskToMoney(e.target.value);
            break;
          case 'ABR':
            invoicing.ABR = maskToMoney(e.target.value);
            break;
          case 'MAI':
            invoicing.MAI = maskToMoney(e.target.value);
            break;
          case 'JUN':
            invoicing.JUN = maskToMoney(e.target.value);
            break;
          case 'JUL':
            invoicing.JUL = maskToMoney(e.target.value);
            break;
          case 'AGO':
            invoicing.AGO = maskToMoney(e.target.value);
            break;
          case 'SET':
            invoicing.SET = maskToMoney(e.target.value);
            break;
          case 'OUT':
            invoicing.OUT = maskToMoney(e.target.value);
            break;
          case 'NOV':
            invoicing.NOV = maskToMoney(e.target.value);
            break;
          case 'DEZ':
            invoicing.DEZ = maskToMoney(e.target.value);
            break;

          default:
            break;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffFaturamentoModel)}
      onSubmit={(values) => {
        postDraftSave(values);
      }}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>

            <PffFilterContainerForm auto onSubmit={handleSubmit}>

              {/* LANÇAMENTO ORIGINAL */}
              {(props.releaseType?.COD_LANCAMENTO === 'OR') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsBudgeted(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStorageOR(field, e);
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value !== '') {
                          calcBudgeted(values, setFieldValue);
                          saveOnLocalStorageOR(field, e);
                        }
                        else {
                          setFieldValue(field.name, '00');
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          calcBudgeted(values, setFieldValue);
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_FATURAMENTO.ORCADO'}
                    label={'Total Faturamento R$'}
                    placeholder={'Total Faturamento R$'}
                    value={maskToMoney2(values.TOTAL_FATURAMENTO.ORCADO)}
                    type={'text'}
                  />

                </RowFieldsGrid>

              )}



              {/* LANÇAMENTO PLANEJADO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PL') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsPlanned(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStoragePL(field, e);
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value !== '') {
                          calcPlanned(values, setFieldValue);
                          saveOnLocalStoragePL(field, e);
                        }
                        else {
                          setFieldValue(field.name, '00');
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          calcPlanned(values, setFieldValue);
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_FATURAMENTO.PLANEJADO'}
                    label={'Total Faturamento R$'}
                    placeholder={'Total Faturamento R$'}
                    value={maskToMoney2(values.TOTAL_FATURAMENTO.PLANEJADO)}
                    type={'text'}
                  />

                </RowFieldsGrid>

              )}



              {/* LANÇAMENTO PREVISTO */}
              {props.releaseType?.COD_LANCAMENTO === 'PR' && (

                releaseList.map((typeRelease) => (
                  <>

                    <TitleInterGroup titleAlign={'rigth'}>
                      <h1>{typeRelease}</h1>
                    </TitleInterGroup>


                    <ColFieldsGrid xs={12}>

                      {listFieldsProvider(values).map((field) => (

                        typeRelease === field.typeRelease && (

                          <TextInputLabelFloat
                            fontSize={Metrics.fontSizePFFSm}
                            fontSizeLabel={Metrics.fontSizePFFSm}
                            disabled={field.disabled}
                            name={field.name}
                            id={field.name}
                            label={field.label + ' R$'}
                            value={field.value}
                            type={'text'}
                            onChange={(e: any) => {
                              if (e.target.value !== '') {
                                handleChange(e);
                                setFieldValue(field.name, maskToMoney(e.target.value));
                                saveOnLocalStoragePR(field, e);
                              }
                            }}

                            onBlur={(e) => {
                              handleBlur(e);
                              if (e.target.value !== '') {
                                calcProvider(values, setFieldValue);
                                saveOnLocalStoragePR(field, e);
                              }
                              else {
                                setFieldValue(field.name, '00');
                              }
                            }}

                            onFocus={(e) => {
                              e.target.select();
                            }}

                            onKeyUp={(e) => {
                              if (e.key === 'Enter')
                                calcProvider(values, setFieldValue);
                            }}
                          />
                        )

                      ))}

                    </ColFieldsGrid>

                  </>
                ))

              )}


            </PffFilterContainerForm>

          </SectionMargin>

        </ContainerView>
      )}

    </Formik >

  );
};



export default Tab_FaturamentoScreen;
