import * as Yup from 'yup';



const GICCValidationSchema = Yup.object().shape({
  
  ID: Yup.string(),
  DESCR: Yup.string(),
  CC: Yup.string(),

});



export default GICCValidationSchema;
