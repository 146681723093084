import * as Yup from 'yup';


const ColetorPstockValidationSchema = Yup.object().shape({

  input_identificador: Yup.string(),

  select_deposito: Yup.string()
    .required('Campo obrigatório'),

});



export default ColetorPstockValidationSchema;
