import React,
{
  useState,
} from 'react';

import {
  Col,
  Modal,
} from 'react-bootstrap';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../../components/Buttons/IButton';
import TextInput from '../../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../../services/api.service';

import ParamsCreateModel from './params.model';
import ParamsCreateValidationSchema from './params.validation';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from './styled';



interface IProps {
  show: boolean;

  negative?: any;
}



const ModalCreateParams: React.FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = useState(false);


  async function savePstockParams(values: any) {
    const payload = {
      DESCR: values.input_desc,
      COD: values.input_code,
      AJUDA: values.input_ajuda,
    };

    try {
      setLoading(true);

      const url = '/pstock/parametro/salva/parametro';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        toast.success('Parâmetro salvo.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.negative(true);
      }
    }
    catch (error) {
      console.error('savePstockParams', JSON.stringify(error.response, null, 2));

      toast.error('Erro ao salvar parâmetro.', {
        position: 'bottom-center',
        autoClose: Metrics.messageAutoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    finally {
      setLoading(false);
    }
  }



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={ParamsCreateModel}
        validationSchema={ParamsCreateValidationSchema}
        onSubmit={(values) => {
          savePstockParams(values);
        }}>
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (

          <ModalContainerStyled onSubmit={handleSubmit}>

            <TitleGroup>
              {'Criar parâmetro'}
            </TitleGroup>



            <ModalBodyStyled>

              <Col>
                <TextInput
                  disabled={loading}
                  type={'text'}
                  name={'input_desc'}
                  label={'Descrição'}
                  placeholder={'Descrição'}
                  value={values.input_desc || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>


              <Col>
                <TextInput
                  disabled={loading}
                  type={'text'}
                  name={'input_code'}
                  label={'Código'}
                  placeholder={'Código'}
                  value={values.input_code || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>


              <Col>
                <TextInput
                  disabled={loading}
                  type={'text'}
                  name={'input_ajuda'}
                  label={'Ajuda'}
                  placeholder={'Ajuda'}
                  value={values.input_ajuda || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>

            </ModalBodyStyled>



            <ModalFooterStyled>
              <IButton
                loading={loading}
                backgroundColor={Colors?.accept}
                text={'Salvar'}
                type={'submit'}
              />


              <IButton
                backgroundColor={Colors?.primary}
                text={'Fechar'}
                onClick={() => {
                  props.negative(false);
                }}
              />
            </ModalFooterStyled>

          </ModalContainerStyled>

        )}
      </Formik>

    </Modal>

  );
};



export default ModalCreateParams;
