export const BUTTONS = {
  containerRadius:           10,
};



export const ICONS = {
  iconSize:                  24,
  iconHomeSize:              55,
  iconDashboardSize:         180,
};



export const INPUTS = {
  inputHeight:               40,
  inputHeightGrid:           20,
  inputRadius:               5,
};



export const MESSAGES = {
  messageAutoClose:          3000,
  messageAutoCloseDouble:    6000,
};



export const TEXTFIELD = {
  input_bm:                  6,
  input_centroCusto:         999,
  input_cnpj:                14,
  input_contrato:            999,
  input_descAnexo:           30,
  input_descComp:            30,
  input_filial:              999,
  input_op:                  11,
  input_pedido:              6,
  input_planilha:            6,
  input_rev:                 3,
  input_sme:                 6,
  input_solicitante:         24,
};

export const FONTSIZE = {
  fontSizeDefault:           0.938,
  fontSizePFFSm:             11,
  fontSizePFFLg:             15,
};


export const PAGINATIONS_TABLE = {
  size_centrocusto: 10,
  size_custogerencial: 10,
  size_requestHuman: 10,
  size_efectiveHuman: 10,
  size_supplier: 10,
  size_PBCApprovall: 10,
  size_PBCContracts: 10,
  size_PBCRequests: 10,

};



const Metrics = {
  ...BUTTONS,
  ...ICONS,
  ...INPUTS,
  ...MESSAGES,
  ...TEXTFIELD,
  ...FONTSIZE,
  ...PAGINATIONS_TABLE,
};



export default Metrics;
