import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  format,
} from 'date-fns';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';

import { IconPrinter } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';

import { intranet_config } from '../../../../../utils/Config';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';
import { convertCNPJ } from '../../../../../utils/ListUtils';
import {
  PrinterContainer,
  A4LandscapeContent,
  TitleCenter,
  TitleHeader,
  CellCenter,
  Row,
  TitleGeneral,
  Cell,
  CellCenterBold,
  RowInfo,
  ColInfo,
  ImgLogStyle,
} from './PBC-bm-impressao.styled';



const PBC_ImpressaoBM: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const { filial, pedido } = useParams() as any;


  const [carregando, setCarregando] = useState(false);
  const [listItens, setItensBM] = useState([]);



  async function findModeloBM(bm) {
    try {
      setCarregando(true);

      const url = '/suprimento/consulta/pedido/medicao';
      const payload = [{ 'FILIAL': bm.filial, 'PEDIDO': bm.pedido }];
      console.log(payload);


      const response = await ApiWS().post(url, payload);

      console.log('Data Impressao:', response.data);

      setItensBM(response.data);
    }
    catch (error) {
      console.error('findModeloBM', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };


  async function printerLandscapePage() {
    await findModeloBM({ filial: filial, pedido: pedido });
    // window.print();
  }



  useEffect(() => {
    printerLandscapePage();
  }, [pedido]);


  function renderLoadingComponent() {
    return (

      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </PrinterContainer>

    );
  }


  function renderLoadingPrinter() {
    return (
      <A4LandscapeContent className="page-a4-landscape">

        <TablePrinterStyled>

          <tbody>

            <Row>

              <Cell rowSpan={4} className="image-Cell">
                <ImgLogStyle
                  alt="Logo"
                  src={intranet_config.Images.logo}
                />
              </Cell>

              <TitleGeneral rowSpan={3} className="name-printer-Cell">

                BM - Boletim de Medição

                <RowInfo>EMPRESA: {listItens.map((i) => i.RAZAO)[listItens.length - 1]}</RowInfo>


                <RowInfo> END.: {listItens.map((i) => i.ENDER)[listItens.length - 1]}</RowInfo>


                <RowInfo>

                  <ColInfo> CIDADE.: {listItens.map((i) => i.CIDADE)[listItens.length - 1]} </ColInfo>


                  <ColInfo marginLeftInfo={7}> UF: {listItens.map((i) => i.UF)[listItens.length - 1]} </ColInfo>


                  <ColInfo marginLeftInfo={7}> CEP.: {listItens.map((i) => i.CEP)[listItens.length - 1]} </ColInfo>

                </RowInfo>

                <RowInfo>

                  <ColInfo> TEL.: {listItens.map((i) => i.TELEFONE)[listItens.length - 1]} </ColInfo>


                  <ColInfo marginLeftInfo={7}> FAX: {listItens.map((i) => i.FAX)[listItens.length - 1]} </ColInfo>

                </RowInfo>

                <RowInfo>
                  <ColInfo> CNPJ: {listItens.map((i) => i.CNPJ)[listItens.length - 1]} </ColInfo>
                  <ColInfo marginLeftInfo={7}> IE: {listItens.map((i) => i.IE)[listItens.length - 1]} </ColInfo>
                </RowInfo>

              </TitleGeneral>


              <TitleHeader colSpan={4}>Data</TitleHeader>


              <CellCenter colSpan={4}>{format(new Date, 'dd/MM/yyyy HH:mm:ss')}</CellCenter>

            </Row>


            <Row>

              <TitleHeader colSpan={4}>OS</TitleHeader>


              <CellCenter colSpan={4}>{listItens.map((i) => i.CC)[listItens.length - 1]}</CellCenter>

            </Row>

          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>

          <tbody>

            <Row>

              <TitleCenter>Fornecedor</TitleCenter>


              <TitleCenter>Endereço</TitleCenter>

            </Row>


            <Row>

              <CellCenter>
                {listItens.map((i) => convertCNPJ(i.CNPJFORN))[listItens.length - 1]}
                {' / '}
                {listItens.map((i) => i.FORNEC)[listItens.length - 1]}
              </CellCenter>


              <CellCenter>{listItens.map((i) => i.ENDERECO)[listItens.length - 1]}</CellCenter>

            </Row>

          </tbody>

        </TablePrinterStyled>


        <TablePrinterStyled>

          <tbody>

            <Row>

              <TitleCenter>Medição</TitleCenter>


              <TitleCenter>Pedido</TitleCenter>


              <TitleCenter>Contrato</TitleCenter>


              <TitleCenter>Planilha</TitleCenter>


              <TitleCenter>Valor do Contrato</TitleCenter>


              <TitleCenter>Saldo do Contrato</TitleCenter>


              <TitleCenter>Venc. do Contrato</TitleCenter>


              <TitleCenter>Competência</TitleCenter>


              <TitleCenter>Per. de Exec. do Serviço</TitleCenter>

            </Row>


            <Row>

              <CellCenter>{listItens.map((i) => i.MEDICAO)[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => i.PEDIDO)[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => i.CONTRATO)[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => i.PLANILHA)[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => convertCurrencyBRL(i.VLCONTR))[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => convertCurrencyBRL(i.SALDO))[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => i.DTFIM)[listItens.length - 1]}</CellCenter>


              <CellCenter>{listItens.map((i) => i.COMPET)[listItens.length - 1]}</CellCenter>


              <CellCenter>
                {
                  listItens.map((i) =>
                    `De ${i.INICIOSERV} Até ${i.FIMSERV}`,
                  )[listItens.length - 1]
                }
              </CellCenter>

            </Row>


            <Row>

              <TitleCenter>Observações:</TitleCenter>

              <Cell colSpan={9}>
                {
                  listItens.map((i) => i.OBS)[listItens.length - 1] !== null
                    ? listItens.map((i) => i.OBS)[listItens.length - 1]
                    : <span>Sem observações.</span>
                }
              </Cell>

            </Row>

          </tbody>

        </TablePrinterStyled>


        <TablePrinterStyled>

          <tbody>

            <Row>

              <TitleCenter>Item</TitleCenter>


              <TitleCenter>Produto</TitleCenter>


              <TitleCenter>Descrição</TitleCenter>


              <TitleCenter>Vt. Unit.</TitleCenter>


              <TitleCenter>Qtd.</TitleCenter>


              <TitleCenter>Vlr. Total</TitleCenter>


              <TitleCenter>Saldo Restante</TitleCenter>

            </Row>


            {
              listItens.map((itm: any) =>

                <Row>

                  <CellCenter>{itm.ITEM}</CellCenter>


                  <CellCenter>{itm.PRODUTO}</CellCenter>


                  <CellCenter>{itm.DESCRPROD}</CellCenter>


                  <CellCenter>{convertCurrencyBRL(itm.VLUNIT)}</CellCenter>


                  <CellCenter>{itm.QUANT}</CellCenter>


                  <CellCenter>{convertCurrencyBRL(itm.VLTOT)}</CellCenter>


                  <CellCenter>{convertCurrencyBRL(itm.SLDREC)}</CellCenter>

                </Row>,

              )
            }

            <Row>

              <CellCenterBold>TOTAL</CellCenterBold>


              <CellCenter></CellCenter>


              <CellCenter></CellCenter>


              <CellCenter></CellCenter>


              <CellCenter></CellCenter>


              <CellCenterBold>{convertCurrencyBRL(listItens[0]?.VLTOTITEM)}</CellCenterBold>


              <CellCenter></CellCenter>

            </Row>

          </tbody>
        </TablePrinterStyled>

        <TablePrinterStyled>

          <Row>

            <Cell>

              <p><i>* - Relatório Gerado por: {user.name} em: {format(new Date(Date.now()), 'dd/MM/yyyy HH:mm')}</i></p>

            </Cell>

          </Row>


          <Row>

            <TitleCenter>

              <div>
                <TableActionButton
                  onClick={() => {
                    window.print();
                  }}>
                  <IconPrinter
                    color={Colors?.black}
                    size={72}
                  />
                </TableActionButton>
              </div>


            </TitleCenter>

          </Row>


        </TablePrinterStyled>

      </A4LandscapeContent>
    );
  }


  return carregando
    ? renderLoadingComponent()
    : renderLoadingPrinter();
};



export default PBC_ImpressaoBM;
