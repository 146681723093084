import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useParams,
} from 'react-router';


import {
  Colors,
} from '../../../../../common/constants';


import LoadingsComponent from '../../../../../components/Loadings';

import PCPrintComponent from '../../../../../components/Printers/Impressao_PC';
import ApiWS from '../../../../../services/api.service';



const PBC_ImpressaoPC: React.FC = () => {
  const { filial, pedido } = useParams() as any;


  const [carregando, setCarregando] = useState(false);
  const [listItens, setItensPC] = useState([]);



  async function findModeloPC(pc) {
    try {
      setCarregando(true);

      const url = '/suprimento/consulta/pedido/impressao';
      const payload = [{ 'TIPO': 'PC', 'FILIAL': pc.filial, 'NUM': pc.pedido }];


      const response = await ApiWS().post(url, payload);

      setItensPC(response.data);
    }
    catch (error) {
      console.error('findModeloPC', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };


  async function printerLandscapePage() {
    await findModeloPC({ filial: filial, pedido: pedido });
    window.print();
  }



  useEffect(() => {
    printerLandscapePage();
  }, [pedido]);


  function renderLoadingComponent() {
    return (

      <LoadingsComponent
        type={'SCREEN'}
        color={Colors?.primary}
      />

    );
  }


  function renderLoadingPrinter() {
    return (
      <PCPrintComponent list={listItens}/>
    );
  }


  return carregando
    ? renderLoadingComponent()
    : renderLoadingPrinter();
};



export default PBC_ImpressaoPC;
