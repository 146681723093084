import styled, { keyframes } from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import {
  Colors,
} from '../../../common/constants';



interface IProps {
  disabled?: boolean;
  color?: string;
  size?: string;
}



const FadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const DisplayCardContainerStyle = styled(Row)`
  animation: ${FadeInAnimation} 2s;
  display: flex;
  flex-direction: column;
  width: ${((props: IProps) => props.size === 'sm' && '500px' || props.size === 'md' && '600px')};
  height: 120px;
  margin: 10px auto;
  background-color: ${(props: IProps) => props.disabled ? Colors?.grayLight : Colors?.white};
  border-top-color: ${(props: IProps) => props.disabled
    ? Colors?.buttonDisabled
    : props.color || Colors?.primary};
  border-top-style: solid;
  border-top-width: 2px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};

  :hover {
    border-top-color: ${(props: IProps) => !props.disabled && Colors?.hoverLink};
  }
`;

export const DisplayCardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0px 20px;
`;

export const DisplayCardIcon = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: 80px;
  width: 300px;
  flex-direction: column;
`;

export const DisplayCardPhoto = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: start;
  height: 100px;
  width: 30px;
  margin-top: 8px;
  margin-right: 90px;
  flex-direction: column;
`;

export const DisplayCardAreaData = styled(Col)`
  display: block;
  margin-top: 2%;
  text-align: start;
`;

export const DisplayCardTitle = styled(Col)`
  color: black;
  font-size: 0.938rem;
  font-weight: bold;

`;

export const DisplayCardDescription = styled(Col)`
  display: inline-block;
  color: #595959;
  font-size: 0.813rem;
  min-width: 350px;
  margin-right: 10px;
`;

