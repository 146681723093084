import styled from 'styled-components';

import {
  Button,
} from 'react-bootstrap';

import {
  Colors,
  Metrics,
} from '../../../common/constants';



interface IProps {
  loading?: boolean;
  backgroundColor?: string;
}



export const ButtonStyled = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: ${Metrics.inputHeight}px;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
  color: white !important;
  font-size: 1.125rem;
  border: none;
  border-radius: ${Metrics.inputRadius}px !important;
  z-index: 2 !important;

  :hover,
  :active,
  :focus {
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    box-shadow: none !important;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    pointer-events: none;
    opacity: .65;
  }

  span {
    margin: 0 8px;
  }
`;


export const ButtonIconView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

export const ButtonText = styled.span`
  line-height: 17px;
`;

export const ButtonLoadingView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonLoadingText = styled.p`
  margin: 0 5px;
  color: ${Colors?.white};
  font-size: 1rem;
`;
