export function convertCurrencyBRL(value: any) {
  return (value !== null && value !== undefined && value !== '' && value !== '0' && value !== 0)
    ? value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    : value;
};


export function maskToNumberNode(value: any) {
  if (value !== null && value !== undefined && value !== '' && value !== '0' && value !== 0) {
    value = value.toLocaleString('pt-BR');
    return value;
  }
  else {
    return value;
  };
}


export function maskToMoney(value: any) {
  if (value !== null && value !== undefined && value !== '' && value !== '0' && value !== 0) {
    value = String(value)?.replace(/\D/g, '');
    value = String(value)?.replace(/(\d)(\d{2})$/, '$1,$2');
    value = String(value)?.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return value;
  }
  else {
    return value;
  };
}

export function maskToMoney2(value: any) {
  if (value !== null && value !== undefined && value !== '' && value !== '0' && value !== 0) {
    return convertCurrencyBRL(value).replaceAll('R$', '');
  }
  else {
    return value;
  };
};

export function maskToMoney3(value: any) {
  if (value === '' || value === '0' || value === 0 || value === '00' || value === '0.0' || isNaN(value)) {
    return '0,00';
  }
  else if (typeof value === 'string') {
    return maskToMoney(value);
  }
  else {
    return maskToMoney2(value);
  }
};

export function maskToMoney4(value: any) {
  if(String(value).indexOf('-') !== -1) {
    return `-${maskToMoney3(value)}`;
  } 
  else {
    return maskToMoney3(value);
  }
};


export function maskToPorcent(value: any) {
  if (value !== null && value !== undefined && value !== '') {
    // value = value.replace(/\D/g, '');
    // value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    // value = value.replaceAll('.', ',');

    return value;
  }
  else {
    return value;
  };
};


export function maskToPorcent2(value: any) {
  if (value !== null && value !== undefined && value !== '') {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replaceAll('.', ',');

    return value;
  }
  else {
    return value;
  };
};


export function maskToFloat(value: any) {
  if (value !== null && value !== undefined && value !== '' && value !== 0) {
    value = value.replaceAll('.', '');
    value = value.replace(',', '.');


    return parseFloat(value);
  }
  else {
    return value;
  }
};


export function maskToFloat2(value: any) {
  if (typeof value === 'string') {
    if (value !== null || value !== '0') {
      if (value.indexOf(',') !== -1) {
        value = maskToFloat(value);
      }
      else {
        value = parseFloat(value);
      }
    }
  }


  return value;
};
