import styled from 'styled-components';

import {
  Form,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../../common/constants';



export const AdmModalDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

export const AdmModalDetailInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
`;

export const AdmModalDetailBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ItemContentFull = styled(ItemContent)`
  grid-column-start: 1;
  grid-column-end: 4;
`;


export const ItemLabel = styled.span`
  color: #000000;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const ItemText = styled.p`
  color: #000000;
  font-size: 1.1rem;
`;



export const ItemDetailUserImage = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 10px;
  object-fit: cover;
  border: 0.5px solid ${Colors?.borderColor};
`;



export const SwitchCheckStyled = styled(Form.Check)`
  margin-top: 5px;
  font-size: 1.1rem;
`;
