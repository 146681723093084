import * as Yup from 'yup';



const POCAreaValidationSchema = Yup.object().shape({
  
  NEGOC: Yup.string(),
  DESCR: Yup.string(),

});



export default POCAreaValidationSchema;
