const IPicCadastroModel = {
  // Geral
  CC: '',
  IDAREA: '',
  USER: '',
  OBS: '',
  PENDENCIAS: [{ PENDENCIA: '', RESP: '' }],
  DESVIOS: '',
  REC: [{ MAT: '', FUNC: '', RECOM: '' }],
  
  DATA: '',
  MAT: '',
  AUDITOR: '',
  FUNCAO: '',
  
  
  // A1
  A1_IDEQUIPE: '',
  A1_LIDER: '',
  A1_DESCR: '',
  A1_NPESSOA: '',
  A1_NDESVIO: '',

  // A2
  A2_IDEQUIPE: '',
  A2_LIDER: '',
  A2_DESCR: '',
  A2_NPESSOA: '',
  A2_NDESVIO: '',

  // A3
  A3_IDEQUIPE: '',
  A3_LIDER: '',
  A3_DESCR: '',
  A3_NPESSOA: '',
  A3_NDESVIO: '',

  // A4
  A4_IDEQUIPE: '',
  A4_LIDER: '',
  A4_DESCR: '',
  A4_NPESSOA: '',
  A4_NDESVIO: '',

  // A5
  A5_IDEQUIPE: '',
  A5_LIDER: '',
  A5_DESCR: '',
  A5_NPESSOA: '',
  A5_NDESVIO: '',

  // A6
  A6_IDEQUIPE: '',
  A6_LIDER: '',
  A6_DESCR: '',
  A6_NPESSOA: '',
  A6_NDESVIO: '',

  // A7
  A7_IDEQUIPE: '',
  A7_LIDER: '',
  A7_DESCR: '',
  A7_NPESSOA: '',
  A7_NDESVIO: '',

  // A8
  A8_IDEQUIPE: '',
  A8_LIDER: '',
  A8_DESCR: '',
  A8_NPESSOA: '',
  A8_NDESVIO: '',


  // B1
  B1_IDEQUIPE: '',
  B1_LIDER: '',
  B1_DESCR: '',
  B1_NPESSOA: '',
  B1_NDESVIO: '',

  // B2
  B2_IDEQUIPE: '',
  B2_LIDER: '',
  B2_DESCR: '',
  B2_NPESSOA: '',
  B2_NDESVIO: '',

  // B3
  B3_IDEQUIPE: '',
  B3_LIDER: '',
  B3_DESCR: '',
  B3_NPESSOA: '',
  B3_NDESVIO: '',

  // B4
  B4_IDEQUIPE: '',
  B4_LIDER: '',
  B4_DESCR: '',
  B4_NPESSOA: '',
  B4_NDESVIO: '',

  // B5
  B5_IDEQUIPE: '',
  B5_LIDER: '',
  B5_DESCR: '',
  B5_NPESSOA: '',
  B5_NDESVIO: '',


  // C1
  C1_IDEQUIPE: '',
  C1_LIDER: '',
  C1_DESCR: '',
  C1_NPESSOA: '',
  C1_NDESVIO: '',

  // C2
  C2_IDEQUIPE: '',
  C2_LIDER: '',
  C2_DESCR: '',
  C2_NPESSOA: '',
  C2_NDESVIO: '',

  // C3
  C3_IDEQUIPE: '',
  C3_LIDER: '',
  C3_DESCR: '',
  C3_NPESSOA: '',
  C3_NDESVIO: '',

  // C4
  C4_IDEQUIPE: '',
  C4_LIDER: '',
  C4_DESCR: '',
  C4_NPESSOA: '',
  C4_NDESVIO: '',


  // D1
  D1_IDEQUIPE: '',
  D1_LIDER: '',
  D1_DESCR: '',
  D1_NPESSOA: '',
  D1_NDESVIO: '',

  // D2
  D2_IDEQUIPE: '',
  D2_LIDER: '',
  D2_DESCR: '',
  D2_NPESSOA: '',
  D2_NDESVIO: '',


  // E1
  E1_IDEQUIPE: '',
  E1_LIDER: '',
  E1_DESCR: '',
  E1_NPESSOA: '',
  E1_NDESVIO: '',

  // E2
  E2_IDEQUIPE: '',
  E2_LIDER: '',
  E2_DESCR: '',
  E2_NPESSOA: '',
  E2_NDESVIO: '',

  // E3
  E3_IDEQUIPE: '',
  E3_LIDER: '',
  E3_DESCR: '',
  E3_NPESSOA: '',
  E3_NDESVIO: '',

  // E4
  E4_IDEQUIPE: '',
  E4_LIDER: '',
  E4_DESCR: '',
  E4_NPESSOA: '',
  E4_NDESVIO: '',
  
};



export default IPicCadastroModel;
