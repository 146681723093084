import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  Colors,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';

import {
  IconCheck, IconNot, IconPrinter,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';
import { intranet_config } from '../../../../../utils/Config';

import {
  currentDateFormated,
  formataData,
} from '../../../../../utils/DateUtils';

import objRP from './RP-impressao.model';

import {
  PrinterContainer,
  A4LandscapeContent,
} from './RP-impressao.styled';



const RP_ImpressaoScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const { id: param_rp } = useParams() as any;


  const [carregando, setCarregando] = useState(false);
  const [rp, setRP] = useState(objRP);

  const [aprovadores, setAprovadores] = useState([]);

  async function findAprovadores(os: any) {
    try {
      const url = '/rp/consulta/pendentes/aprovadores';
      const response = await ApiWS().post(url, { 'cc': os });

      setAprovadores(response.data);
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
  };


  async function findRP(numRP: any) {
    try {
      setCarregando(true);

      const url = `/rp/impressao/${numRP}`;
      const response = await ApiWS().get(url);

      setRP(response.data[0]);

      await findAprovadores(response.data[0].QS_CC);
      console.log(response.data[0]);
    }
    catch (error) {
      console.error('findRP', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };


  async function printerLandscapePage() {
    await findRP(param_rp);
    // window.print();
  }



  function renderLoadingComponent() {
    return (

      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </PrinterContainer>

    );
  }



  useEffect(() => {
    printerLandscapePage();
  }, []);



  function renderLoadingPrinter(): JSX.Element {
    return (
      <A4LandscapeContent className="page-a4-landscape">

        <TablePrinterStyled>
          <tbody>
            <tr>
              <td rowSpan={2} className="image-td">
                <img
                  alt="Logo"
                  src={intranet_config.Images.logo}
                />
              </td>
              <th rowSpan={2} className="name-printer-td">
                RP - Requisição de Pessoal
              </th>
              <th colSpan={2}>Nº</th>
              <td colSpan={2}>{rp.QS_VAGA}</td>
            </tr>

            <tr>
              <th colSpan={2}>Data</th>
              <td colSpan={2}>{formataData(rp.QS_DTABERT)}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <tbody>
            <tr className="text-center">
              <th>Nª da OS</th>
              <th>Setor - Operação</th>
              <th>Dt. Prev. Atend.</th>
              <th>Data Necessidade</th>
            </tr>

            <tr className="text-center">
              <td>{rp.QS_CC}</td>
              <td>{rp.QS_DESCUST}</td>
              <td>{formataData(rp.QS_DTPREVI)}</td>
              <td>{formataData(rp.QS_DTNECES)}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <tbody>
            <tr className="text-center">
              <th>Quantidade</th>
              <th>Cód. Função</th>
              <th>Desc. Função</th>
              <th>Cód. Cargo</th>
              <th>Desc. Cargo</th>
            </tr>

            <tr className="text-center">
              <td>{rp.QS_NRVAGA}</td>
              <td>{rp.QS_FUNCAO}</td>
              <td>{rp.QS_DESCRIC}</td>
              <td>{rp.QS_CARGO}</td>
              <td>{rp.QS_DESCARG}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Motivo</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Justificativa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={5} colSpan={5}>{
                {
                  '01': 'Aumento de Quadro',
                  '02': 'Vaga Prevista',
                  '03': 'Substituição',
                  '04': 'Outros',
                }[rp.QS_MOTIVO]
              }</td>
              <td rowSpan={3}>{rp.QS_JUSTIF}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Tipo Contrato</th>
              <th>Prazo Contrato</th>
              <th>Descrição Sumária das Atribuições</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{
                {
                  '01': 'Efetivo',
                  '02': 'Temporário',
                  '03': 'Estagiário',
                  '04': 'Outros',
                }[rp.QS_TPCONTR]
              }</td>
              <td>{rp.QS_PRZCONT}</td>
              <td colSpan={6}>{rp.QS_DESCSUM}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Escolaridade</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Formação Profissional</th>
              <th>Carecteristicas Pessoais Básicas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>{rp.QS_DESCESC}</td>
              <td rowSpan={3} colSpan={9} className='text-center'>{rp.QS_FORMCAO}</td>
              <td rowSpan={3} colSpan={9}>{rp.QS_PERF}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Especialização / Conhecimentos</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>{rp.QS_ESPCONH}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Remuneração Básica</th>
              <th></th>
              <th></th>
              <th></th>
              <th colSpan={7}>Benefícios</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Salário Base (Mensal):</b> R$ {rp.QS_VCUSTO}</td>
              &nbsp; <td rowSpan={4} colSpan={17}>{rp.QS_BENEFIC}</td>
            </tr>
            <tr>
              <td><b>Soldador:</b> {rp.QS_FUNSOLD.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
            <tr>
              <td><b>Periculosidade:</b> {rp.QS_PERICUL.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
            <tr>
              <td><b>Insalubridade:</b> {rp.QS_INSALUB.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        {
          parseInt(rp.QS_FUNSOLD) === 1 &&
          <>
            <TablePrinterStyled>
              <thead>
                <tr>
                  <th>Processos de Soldagem  </th>
                  <th>Eletrodo Revestido</th>
                  <th>TIG</th>
                  <th>MIG</th>
                  <th>MIG</th>
                  <th>A. Tubular</th>
                  <th>Outros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> - - - </td>
                  <td>
                    {parseInt(rp.QS_SOLDELT) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_SOLDTIG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_SOLMIG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_SOLMAG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_SOLTUB) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td> {rp.QS_PSOUTRO}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TablePrinterStyled>
              <thead>
                <tr>
                  <th>Materiais de Soldagem</th>
                  <th>Aço Carbono</th>
                  <th>Aço Inox</th>
                  <th>Ligas de Níquel</th>
                  <th>Titânio</th>
                  <th>Alumínio</th>
                  <th>Outros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> - - - </td>
                  <td>
                    {parseInt(rp.QS_ACOCARB) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_ACOINOX) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_LIGNIQ) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_TITANIO) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rp.QS_ALUMIN) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>{rp.QS_OUTRMAT}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>
          </>
        }


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Observações do Solicitante</th>
              <th>Observações do RH</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{rp.QS_OBSERVA}</td>
              <td>{rp.QS_OBSERVR}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Status</th>
              <th>Solicitada <IconCheck color='Back' /></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Solicitante</b></td>


              <td>
                {rp.QS_SOLICIT}
              </td>


            </tr>
          </tbody>
        </TablePrinterStyled>

        <div>
          <p><i>* - Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            onClick={() => {
              window.print();
            }}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={72}
              />
            }
          />
        </div>

      </A4LandscapeContent>
    );
  }


  return carregando ? renderLoadingComponent() : renderLoadingPrinter();
};



export default RP_ImpressaoScreen;
