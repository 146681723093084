import React, { useState, useEffect } from 'react';
import { Col, Tab } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Colors } from '../../../../../common/constants';
import { Content, ContentStyled, Screen, TabIconStyled, TabsStyled, TabTextStyled, TabViewStyled } from '../../../../../common/styles/styled.layout';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconCollaboration, IconEffective, IconHardHappy, IconMesseger, IconSad, IconSend } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import { PAV_NavRoutes } from '../../../../../navigation/navs/HumanResources';
import ApiWS from '../../../../../services/api.service';
import Tab_Colaborador from './PAV_Tabs/001PAV_ColaboradorTab';
import Tab_Competencias from './PAV_Tabs/002PAV_CompetenciasTab';
import Tab_Reconhecimento from './PAV_Tabs/003PAV_ReconhecimentoTab';
import Tab_Enviar from './PAV_Tabs/004PAV_EnviarTab';
import PAVReconhecimentoModel from './PAV-Reconhecimento.model';
import { AreaTextQtdStar, RowStyled } from './PAV-Reconhecimento.styled';
import PAVReconhecimentoValidationSchema from './PAV-Reconhecimento.validation';



const PAV_ReconhecimentoScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [qthStar, setQthStar] = useState(0);

  const [competencySelected, setCompetencySelected] = useState(-1);


  function postSendCompetency(values) {
    setLoading(true);

    const url = '/pav/reconhecimento/salvar';
    const payload = [
      {
        MATRICULA: values.MAT,
        PILAR: values.PILAR,
        MENSAGEM: values.MENSAGEM,
        EMAIL: values.EMAIL_ENVIO,
      },
    ];


    ApiWS()
      .post(url, payload)
      .then((resp) => {
        IToast({
          type: 'success',
          message: resp.data,
        });

        setTimeout(() => {
          location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.error('postSendCompetency', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        setLoading(false);
      });
  };



  const tabsList = (propsFormik) => [
    {
      eventKey: 0,
      title: {
        icon: <IconEffective size={16} />,
        text: 'Colaborador',
      },
      screenTab:
        <Tab_Colaborador
          values={propsFormik.values}
          handleSubmit={propsFormik.handleSubmit}
          handleBlur={propsFormik.handleBlur}
          handleChange={propsFormik.handleChange}
          setFieldValue={propsFormik.setFieldValue}
          setCurrentTab={setCurrentTab}
        />,
    },
    {
      eventKey: 1,
      title: {
        icon: <IconCollaboration size={16} />,
        text: 'Competência',
      },
      screenTab:
        <Tab_Competencias
          values={propsFormik.values}
          handleSubmit={propsFormik.handleSubmit}
          handleBlur={propsFormik.handleBlur}
          handleChange={propsFormik.handleChange}
          setFieldValue={propsFormik.setFieldValue}
          setCurrentTab={setCurrentTab}
          competencySelected={competencySelected}
          setCompetencySelected={setCompetencySelected}
        />,
    },
    {
      eventKey: 2,
      title: {
        icon: <IconMesseger size={16} />,
        text: 'Reconhecimento',
      },
      screenTab:
        <Tab_Reconhecimento
          values={propsFormik.values}
          handleSubmit={propsFormik.handleSubmit}
          handleBlur={propsFormik.handleBlur}
          handleChange={propsFormik.handleChange}
          setFieldValue={propsFormik.setFieldValue}
          setCurrentTab={setCurrentTab}
        />,
    },
    {
      eventKey: 3,
      title: {
        icon: <IconSend size={16} />,
        text: 'Enviar',
      },
      screenTab:
        <Tab_Enviar
          values={propsFormik.values}
          handleSubmit={propsFormik.handleSubmit}
          handleBlur={propsFormik.handleBlur}
          handleChange={propsFormik.handleChange}
          setFieldValue={propsFormik.setFieldValue}
          resetForm={propsFormik.resetForm}
          isValid={propsFormik.isValid}
          setCompetencySelected={setCompetencySelected}
          setCurrentTab={setCurrentTab}
          postSendCompetency={(values) => postSendCompetency(values)}
        />,
    },

  ];



  function fetchQthStar() {
    setLoading(true);

    const url = '/pav/reconhecimento/estrelas';
    ApiWS()
      .post(url, [])
      .then((res) => {
        if (res.status === 200) {
          setQthStar(res.data);
        }
      })
      .catch((err) => {
        console.error('renderStars', JSON.parse(err));

        IToast({
          type: 'warning',
          message: 'Erro ao consultar estrelas! Tente novamente, caso o erro persista, contate o suporte.',
        });
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    fetchQthStar();
  }, []);


  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PAVReconhecimentoValidationSchema}
      initialValues={{
        ...PAVReconhecimentoModel,
        QTDESTRELAS: qthStar,
      }}
      onSubmit={() => {
        console.log('');
      }}>
      {({
        values,
        isValid,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        resetForm,
      }) => (
        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={2}
              navArray={PAV_NavRoutes}
              title={'PAV'}
            />
          </NavHeader>



          <Content>

            <ContentStyled fluid>


              <Form noValidate onSubmit={handleSubmit}>

                {loading && (
                  <LoadingsComponent />
                )}


                {!loading && (
                  <>
                    {(qthStar <= 0) && (
                      <RowStyled>
                        <Col></Col>
                        <Col>

                          <AreaTextQtdStar size={1.3}>

                            <p><IconSad color={Colors?.primary} /> Oops...</p>
                            <p>Você não possui estrelas suficientes para enviar.</p>
                            <p>Mas não se preocupe, atualizaremos no próximo ciclo mensal. <IconHardHappy color={Colors?.primary} /></p>

                          </AreaTextQtdStar>
                        </Col>
                        <Col></Col>
                      </RowStyled>

                    )}



                    {(qthStar > 0) && (


                      <TabsStyled
                        id={'control-tab-pcb'}
                        activeKey={currentTab}
                        onSelect={(tab) => setCurrentTab(parseInt(tab))}
                      >

                        {tabsList({
                          values: values,
                          handleSubmit: handleSubmit,
                          handleBlur: handleBlur,
                          handleChange: handleChange,
                          setFieldValue: setFieldValue,
                          resetForm: resetForm,
                          isValid: isValid,
                        }).map((tab) => (
                          <Tab
                            disabled={!isValid || loading}
                            eventKey={tab.eventKey}
                            title={
                              <TabViewStyled>

                                <TabIconStyled>
                                  {tab.title.icon}
                                </TabIconStyled>



                                <TabTextStyled>
                                  {tab.title.text}
                                </TabTextStyled>

                              </TabViewStyled>
                            }>

                            {tab.screenTab}


                          </Tab>
                        ))}

                      </TabsStyled>


                    )}

                  </>
                )}
              </Form>

            </ContentStyled>
          </Content>


        </Screen>

      )}

    </Formik>

  );
};



export default PAV_ReconhecimentoScreen;
