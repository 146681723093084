import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../../components/Buttons/IButton';
import NavHeader from '../../../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../../../components/Loadings';

import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';

// import { Container } from './styles';



const NovoFuncionarioScreen: React.FC = () => {
  const [carregando] = useState(false);



  return (

    <Screen>

      <NavHeader>
        <NavUser back />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && (
            <ContainerView>

              <SectionMargin>
                <TitleGroup>
                  {false
                    ? 'Editar Funcionário'.toUpperCase()
                    : 'Novo Funcionário'.toUpperCase()
                  }
                </TitleGroup>


                <SectionMargin>
                  <Formik
                    enableReinitialize
                    validateOnMount
                    // validationSchema={IGercCustoGerencialValidationSchema}
                    initialValues={[{}]}
                    onSubmit={() => {
                      // handleCustoGerecialFunction(values);
                    }}>
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>
                          <Col>

                            <Row>
                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <DropdownInput
                                  // disabled={carregando || listaCarregando}
                                  name={'select_situacao'}
                                  label={'Situação'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    {
                                      key: '1',
                                      label: 'Ativa',
                                      value: 'Ativa',
                                    },
                                    {
                                      key: '2',
                                      label: 'Encerrada',
                                      value: 'Encerrada',
                                    },
                                  ]}
                                  // value={values.select_situacao || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <DropdownInput
                                  // disabled={carregando || listaCarregando}
                                  name={'select_situacao'}
                                  label={'Situação'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    {
                                      key: '1',
                                      label: 'Ativa',
                                      value: 'Ativa',
                                    },
                                    {
                                      key: '2',
                                      label: 'Encerrada',
                                      value: 'Encerrada',
                                    },
                                  ]}
                                  // value={values.select_situacao || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>

                            </Row>



                            <Row>
                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Row>



                            <Row>
                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <DropdownInput
                                  // disabled={carregando || listaCarregando}
                                  name={'select_situacao'}
                                  label={'Situação'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    {
                                      key: '1',
                                      label: 'Ativa',
                                      value: 'Ativa',
                                    },
                                    {
                                      key: '2',
                                      label: 'Encerrada',
                                      value: 'Encerrada',
                                    },
                                  ]}
                                  // value={values.select_situacao || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <DropdownInput
                                  // disabled={carregando || listaCarregando}
                                  name={'select_situacao'}
                                  label={'Situação'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    {
                                      key: '1',
                                      label: 'Ativa',
                                      value: 'Ativa',
                                    },
                                    {
                                      key: '2',
                                      label: 'Encerrada',
                                      value: 'Encerrada',
                                    },
                                  ]}
                                  // value={values.select_situacao || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>

                            </Row>



                            <Row>
                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <TextInput
                                  // disabled={loadingCGConfirmModal}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  // value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Row>

                          </Col>



                          <Col xs lg="2">
                            <IButton
                              backgroundColor={Colors?.excel}
                              text={'Gravar Biometria'}
                              onClick={() => {
                                // TODO
                              }}
                            />
                          </Col>
                        </Row>


                        <RowButtonFiltersView>
                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}
                  </Formik>
                </SectionMargin>

              </SectionMargin>

            </ContainerView>
          )}

        </ContentStyled>

      </Content>

    </Screen>

  );
};



export default NovoFuncionarioScreen;
