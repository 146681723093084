import { maskToFloat, maskToFloat2 } from '../../../../../utils/CurrencyUtils';



export function returnCalcMarginsAndTaxOfLS_Budgeted() {
  const dataLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

  const RRE = dataLocalStorage[4].RRE;
  const RCO = dataLocalStorage[5].RCO;
  const IMPOSTO = dataLocalStorage[6].IMP;
  const CUSTO = dataLocalStorage[7].CUS;

  let ISS = dataLocalStorage[1].CAB[0].IMP01;
  let PIS = dataLocalStorage[1].CAB[0].IMP02;
  let ICMS = dataLocalStorage[1].CAB[0].IMP03;
  let DESO = dataLocalStorage[1].CAB[0].IMP04;

  let sumRRE = 0.00;
  let sumRCO = 0.00;
  let sumIMPOSTO = 0.00;
  let sumCUSTO = 0.00;

  let marginReal = 0.00;
  let marginPorc = 0.00;


  RRE.forEach((lancamento) => {
    if (typeof lancamento.ORCADO === 'string') {
      if (lancamento.ORCADO.indexOf(',') !== -1) {
        lancamento.ORCADO = maskToFloat(lancamento.ORCADO);
      }
    }
    sumRRE = sumRRE + parseFloat(lancamento.ORCADO);
  });

  RCO.forEach((lancamento) => {
    if (typeof lancamento.ORCADO === 'string') {
      if (lancamento.ORCADO.indexOf(',') !== -1) {
        lancamento.ORCADO = maskToFloat(lancamento.ORCADO);
      }
    }
    sumRCO = sumRCO + parseFloat(lancamento.ORCADO);
  });

  if (ISS.indexOf(',') !== -1) {
    ISS = maskToFloat(ISS);
  }
  if (PIS.indexOf(',') !== -1) {
    PIS = maskToFloat(PIS);
  }
  if (ICMS.indexOf(',') !== -1) {
    ICMS = maskToFloat(ICMS);
  }
  if (DESO.indexOf(',') !== -1) {
    DESO = maskToFloat(DESO);
  }

  IMPOSTO[0].ORCADO = ((sumRRE * ISS) / 100);
  IMPOSTO[1].ORCADO = ((sumRRE * PIS) / 100);
  IMPOSTO[2].ORCADO = ((sumRRE * ICMS) / 100);
  IMPOSTO[3].ORCADO = ((sumRRE * DESO) / 100);

  IMPOSTO.forEach((lancamento) => {
    if (typeof lancamento.ORCADO === 'string') {
      if (lancamento.ORCADO.indexOf(',') !== -1) {
        lancamento.ORCADO = maskToFloat(lancamento.ORCADO);
      }
    }
    sumIMPOSTO = sumIMPOSTO + parseFloat(lancamento.ORCADO);
  });

  CUSTO.forEach((lancamento) => {
    if (typeof lancamento.ORCADO === 'string') {
      if (lancamento.ORCADO.indexOf(',') !== -1) {
        lancamento.ORCADO = maskToFloat(lancamento.ORCADO);
      }
    }
    sumCUSTO = sumCUSTO + parseFloat(lancamento.ORCADO);
  });

  marginReal = ((sumRRE + sumRCO) - (sumIMPOSTO + sumCUSTO));

  marginPorc = ((marginReal / (sumRRE - sumIMPOSTO)) * 100);


  // eslint-disable-next-line use-isnan
  if (isNaN(marginPorc)) {
    marginPorc = 0;
  }

  return { REAL: marginReal, PORC: marginPorc, TOTAL_IMP: sumIMPOSTO };
};



export function returnCalcMarginsAndTaxOfLS_Planned() {
  const dataLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

  const RRE = dataLocalStorage[4].RRE;
  const RCO = dataLocalStorage[5].RCO;
  const IMPOSTO = dataLocalStorage[6].IMP;
  const CUSTO = dataLocalStorage[7].CUS;

  let ISS = dataLocalStorage[1].CAB[0].IMP01;
  let PIS = dataLocalStorage[1].CAB[0].IMP02;
  let ICMS = dataLocalStorage[1].CAB[0].IMP03;
  let DESO = dataLocalStorage[1].CAB[0].IMP04;

  let sumRRE = 0.00;
  let sumRCO = 0.00;
  let sumIMPOSTO = 0.00;
  let sumCUSTO = 0.00;

  let marginReal = 0.00;
  let marginPorc = 0.00;


  RRE.forEach((lancamento) => {
    if (typeof lancamento.PLANEJADO === 'string') {
      if (lancamento.PLANEJADO.indexOf(',') !== -1) {
        lancamento.PLANEJADO = maskToFloat(lancamento.PLANEJADO);
      }
    }
    sumRRE = sumRRE + parseFloat(lancamento.PLANEJADO);
  });

  RCO.forEach((lancamento) => {
    if (typeof lancamento.PLANEJADO === 'string') {
      if (lancamento.PLANEJADO.indexOf(',') !== -1) {
        lancamento.PLANEJADO = maskToFloat(lancamento.PLANEJADO);
      }
    }
    sumRCO = sumRCO + parseFloat(lancamento.PLANEJADO);
  });

  if (ISS.indexOf(',') !== -1) {
    ISS = maskToFloat(ISS);
  }
  if (PIS.indexOf(',') !== -1) {
    PIS = maskToFloat(PIS);
  }
  if (ICMS.indexOf(',') !== -1) {
    ICMS = maskToFloat(ICMS);
  }
  if (DESO.indexOf(',') !== -1) {
    DESO = maskToFloat(DESO);
  }

  IMPOSTO[0].PLANEJADO = ((sumRRE * ISS) / 100);
  IMPOSTO[1].PLANEJADO = ((sumRRE * PIS) / 100);
  IMPOSTO[2].PLANEJADO = ((sumRRE * ICMS) / 100);
  IMPOSTO[3].PLANEJADO = ((sumRRE * DESO) / 100);

  IMPOSTO.forEach((lancamento) => {
    if (typeof lancamento.PLANEJADO === 'string') {
      if (lancamento.PLANEJADO.indexOf(',') !== -1) {
        lancamento.PLANEJADO = maskToFloat(lancamento.PLANEJADO);
      }
    }
    sumIMPOSTO = sumIMPOSTO + parseFloat(lancamento.PLANEJADO);
  });

  CUSTO.forEach((lancamento) => {
    if (typeof lancamento.PLANEJADO === 'string') {
      if (lancamento.PLANEJADO.indexOf(',') !== -1) {
        lancamento.PLANEJADO = maskToFloat(lancamento.PLANEJADO);
      }
    }
    sumCUSTO = sumCUSTO + parseFloat(lancamento.PLANEJADO);
  });

  marginReal = ((sumRRE + sumRCO) - (sumIMPOSTO + sumCUSTO));

  marginPorc = ((marginReal / (sumRRE - sumIMPOSTO)) * 100);



  // eslint-disable-next-line use-isnan
  if (isNaN(marginPorc)) {
    marginPorc = 0;
  }

  return { REAL: marginReal, PORC: marginPorc, TOTAL_IMP: sumIMPOSTO };
};



export function returnCalcMarginsAndTaxOfLS_Provider() {
  const dataLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

  const RRE = dataLocalStorage[4].RRE;
  const RCO = dataLocalStorage[5].RCO;
  const IMPOSTO = dataLocalStorage[6].IMP;
  const CUSTO = dataLocalStorage[7].CUS;

  let ISS = dataLocalStorage[1]?.CAB[0]?.IMP01;
  let PIS = dataLocalStorage[1]?.CAB[0]?.IMP02;
  let ICMS = dataLocalStorage[1]?.CAB[0]?.IMP03;
  let DESO = dataLocalStorage[1]?.CAB[0]?.IMP04;


  const sumRRE = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };
  const sumRCO = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };
  const sumIMPOSTO = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };
  const sumCUSTO = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };

  const marginReal = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };
  const marginPorc = { JAN: 0.00, FEV: 0.00, MAR: 0.00, ABR: 0.00, MAI: 0.00, JUN: 0.00, JUL: 0.00, AGO: 0.00, SET: 0.00, OUT: 0.00, NOV: 0.00, DEZ: 0.00 };


  RRE.forEach((lancamento) => {
    sumRRE.JAN = sumRRE.JAN + maskToFloat2(lancamento.JAN);
    sumRRE.FEV = sumRRE.FEV + maskToFloat2(lancamento.FEV);
    sumRRE.MAR = sumRRE.MAR + maskToFloat2(lancamento.MAR);
    sumRRE.ABR = sumRRE.ABR + maskToFloat2(lancamento.ABR);
    sumRRE.MAI = sumRRE.MAI + maskToFloat2(lancamento.MAI);
    sumRRE.JUN = sumRRE.JUN + maskToFloat2(lancamento.JUN);
    sumRRE.JUL = sumRRE.JUL + maskToFloat2(lancamento.JUL);
    sumRRE.AGO = sumRRE.AGO + maskToFloat2(lancamento.AGO);
    sumRRE.SET = sumRRE.SET + maskToFloat2(lancamento.SET);
    sumRRE.OUT = sumRRE.OUT + maskToFloat2(lancamento.OUT);
    sumRRE.NOV = sumRRE.NOV + maskToFloat2(lancamento.NOV);
    sumRRE.DEZ = sumRRE.DEZ + maskToFloat2(lancamento.DEZ);
  });

  RCO.forEach((lancamento) => {
    // if(lancamento.TIPO !== 'BMSI Negativo'){
    sumRCO.JAN = sumRCO.JAN + maskToFloat2(lancamento.JAN);
    sumRCO.FEV = sumRCO.FEV + maskToFloat2(lancamento.FEV);
    sumRCO.MAR = sumRCO.MAR + maskToFloat2(lancamento.MAR);
    sumRCO.ABR = sumRCO.ABR + maskToFloat2(lancamento.ABR);
    sumRCO.MAI = sumRCO.MAI + maskToFloat2(lancamento.MAI);
    sumRCO.JUN = sumRCO.JUN + maskToFloat2(lancamento.JUN);
    sumRCO.JUL = sumRCO.JUL + maskToFloat2(lancamento.JUL);
    sumRCO.AGO = sumRCO.AGO + maskToFloat2(lancamento.AGO);
    sumRCO.SET = sumRCO.SET + maskToFloat2(lancamento.SET);
    sumRCO.OUT = sumRCO.OUT + maskToFloat2(lancamento.OUT);
    sumRCO.NOV = sumRCO.NOV + maskToFloat2(lancamento.NOV);
    sumRCO.DEZ = sumRCO.DEZ + maskToFloat2(lancamento.DEZ);
    // }
    // else {
    //   sumRCO.JAN = sumRCO.JAN - maskToFloat2(lancamento.JAN);
    //   sumRCO.FEV = sumRCO.FEV - maskToFloat2(lancamento.FEV);
    //   sumRCO.MAR = sumRCO.MAR - maskToFloat2(lancamento.MAR);
    //   sumRCO.ABR = sumRCO.ABR - maskToFloat2(lancamento.ABR);
    //   sumRCO.MAI = sumRCO.MAI - maskToFloat2(lancamento.MAI);
    //   sumRCO.JUN = sumRCO.JUN - maskToFloat2(lancamento.JUN);
    //   sumRCO.JUL = sumRCO.JUL - maskToFloat2(lancamento.JUL);
    //   sumRCO.AGO = sumRCO.AGO - maskToFloat2(lancamento.AGO);
    //   sumRCO.SET = sumRCO.SET - maskToFloat2(lancamento.SET);
    //   sumRCO.OUT = sumRCO.OUT - maskToFloat2(lancamento.OUT);
    //   sumRCO.NOV = sumRCO.NOV - maskToFloat2(lancamento.NOV);
    //   sumRCO.DEZ = sumRCO.DEZ - maskToFloat2(lancamento.DEZ);
    // }
  });

  if (ISS?.indexOf(',') !== -1) {
    ISS = maskToFloat(ISS);
  }
  if (PIS?.indexOf(',') !== -1) {
    PIS = maskToFloat(PIS);
  }
  if (ICMS?.indexOf(',') !== -1) {
    ICMS = maskToFloat(ICMS);
  }
  if (DESO?.indexOf(',') !== -1) {
    DESO = maskToFloat(DESO);
  }


  IMPOSTO[0].JAN = ((sumRRE.JAN * ISS) / 100);
  IMPOSTO[0].FEV = ((sumRRE.FEV * ISS) / 100);
  IMPOSTO[0].MAR = ((sumRRE.MAR * ISS) / 100);
  IMPOSTO[0].ABR = ((sumRRE.ABR * ISS) / 100);
  IMPOSTO[0].MAI = ((sumRRE.MAI * ISS) / 100);
  IMPOSTO[0].JUN = ((sumRRE.JUN * ISS) / 100);
  IMPOSTO[0].JUL = ((sumRRE.JUL * ISS) / 100);
  IMPOSTO[0].AGO = ((sumRRE.AGO * ISS) / 100);
  IMPOSTO[0].SET = ((sumRRE.SET * ISS) / 100);
  IMPOSTO[0].OUT = ((sumRRE.OUT * ISS) / 100);
  IMPOSTO[0].NOV = ((sumRRE.NOV * ISS) / 100);
  IMPOSTO[0].DEZ = ((sumRRE.DEZ * ISS) / 100);

  IMPOSTO[1].JAN = ((sumRRE.JAN * PIS) / 100);
  IMPOSTO[1].FEV = ((sumRRE.FEV * PIS) / 100);
  IMPOSTO[1].MAR = ((sumRRE.MAR * PIS) / 100);
  IMPOSTO[1].ABR = ((sumRRE.ABR * PIS) / 100);
  IMPOSTO[1].MAI = ((sumRRE.MAI * PIS) / 100);
  IMPOSTO[1].JUN = ((sumRRE.JUN * PIS) / 100);
  IMPOSTO[1].JUL = ((sumRRE.JUL * PIS) / 100);
  IMPOSTO[1].AGO = ((sumRRE.AGO * PIS) / 100);
  IMPOSTO[1].SET = ((sumRRE.SET * PIS) / 100);
  IMPOSTO[1].OUT = ((sumRRE.OUT * PIS) / 100);
  IMPOSTO[1].NOV = ((sumRRE.NOV * PIS) / 100);
  IMPOSTO[1].DEZ = ((sumRRE.DEZ * PIS) / 100);

  IMPOSTO[2].JAN = ((sumRRE.JAN * ICMS) / 100);
  IMPOSTO[2].FEV = ((sumRRE.FEV * ICMS) / 100);
  IMPOSTO[2].MAR = ((sumRRE.MAR * ICMS) / 100);
  IMPOSTO[2].ABR = ((sumRRE.ABR * ICMS) / 100);
  IMPOSTO[2].MAI = ((sumRRE.MAI * ICMS) / 100);
  IMPOSTO[2].JUN = ((sumRRE.JUN * ICMS) / 100);
  IMPOSTO[2].JUL = ((sumRRE.JUL * ICMS) / 100);
  IMPOSTO[2].AGO = ((sumRRE.AGO * ICMS) / 100);
  IMPOSTO[2].SET = ((sumRRE.SET * ICMS) / 100);
  IMPOSTO[2].OUT = ((sumRRE.OUT * ICMS) / 100);
  IMPOSTO[2].NOV = ((sumRRE.NOV * ICMS) / 100);
  IMPOSTO[2].DEZ = ((sumRRE.DEZ * ICMS) / 100);

  IMPOSTO[3].JAN = ((sumRRE.JAN * DESO) / 100);
  IMPOSTO[3].FEV = ((sumRRE.FEV * DESO) / 100);
  IMPOSTO[3].MAR = ((sumRRE.MAR * DESO) / 100);
  IMPOSTO[3].ABR = ((sumRRE.ABR * DESO) / 100);
  IMPOSTO[3].MAI = ((sumRRE.MAI * DESO) / 100);
  IMPOSTO[3].JUN = ((sumRRE.JUN * DESO) / 100);
  IMPOSTO[3].JUL = ((sumRRE.JUL * DESO) / 100);
  IMPOSTO[3].AGO = ((sumRRE.AGO * DESO) / 100);
  IMPOSTO[3].SET = ((sumRRE.SET * DESO) / 100);
  IMPOSTO[3].OUT = ((sumRRE.OUT * DESO) / 100);
  IMPOSTO[3].NOV = ((sumRRE.NOV * DESO) / 100);
  IMPOSTO[3].DEZ = ((sumRRE.DEZ * DESO) / 100);

  IMPOSTO.forEach((lancamento) => {
    sumIMPOSTO.JAN = sumIMPOSTO.JAN + maskToFloat2(lancamento.JAN);
    sumIMPOSTO.FEV = sumIMPOSTO.FEV + maskToFloat2(lancamento.FEV);
    sumIMPOSTO.MAR = sumIMPOSTO.MAR + maskToFloat2(lancamento.MAR);
    sumIMPOSTO.ABR = sumIMPOSTO.ABR + maskToFloat2(lancamento.ABR);
    sumIMPOSTO.MAI = sumIMPOSTO.MAI + maskToFloat2(lancamento.MAI);
    sumIMPOSTO.JUN = sumIMPOSTO.JUN + maskToFloat2(lancamento.JUN);
    sumIMPOSTO.JUL = sumIMPOSTO.JUL + maskToFloat2(lancamento.JUL);
    sumIMPOSTO.AGO = sumIMPOSTO.AGO + maskToFloat2(lancamento.AGO);
    sumIMPOSTO.SET = sumIMPOSTO.SET + maskToFloat2(lancamento.SET);
    sumIMPOSTO.OUT = sumIMPOSTO.OUT + maskToFloat2(lancamento.OUT);
    sumIMPOSTO.NOV = sumIMPOSTO.NOV + maskToFloat2(lancamento.NOV);
    sumIMPOSTO.DEZ = sumIMPOSTO.DEZ + maskToFloat2(lancamento.DEZ);
  });

  CUSTO.forEach((lancamento) => {
    sumCUSTO.JAN = sumCUSTO.JAN + maskToFloat2(lancamento.JAN);
    sumCUSTO.FEV = sumCUSTO.FEV + maskToFloat2(lancamento.FEV);
    sumCUSTO.MAR = sumCUSTO.MAR + maskToFloat2(lancamento.MAR);
    sumCUSTO.ABR = sumCUSTO.ABR + maskToFloat2(lancamento.ABR);
    sumCUSTO.MAI = sumCUSTO.MAI + maskToFloat2(lancamento.MAI);
    sumCUSTO.JUN = sumCUSTO.JUN + maskToFloat2(lancamento.JUN);
    sumCUSTO.JUL = sumCUSTO.JUL + maskToFloat2(lancamento.JUL);
    sumCUSTO.AGO = sumCUSTO.AGO + maskToFloat2(lancamento.AGO);
    sumCUSTO.SET = sumCUSTO.SET + maskToFloat2(lancamento.SET);
    sumCUSTO.OUT = sumCUSTO.OUT + maskToFloat2(lancamento.OUT);
    sumCUSTO.NOV = sumCUSTO.NOV + maskToFloat2(lancamento.NOV);
    sumCUSTO.DEZ = sumCUSTO.DEZ + maskToFloat2(lancamento.DEZ);
  });


  marginReal.JAN = (sumRRE.JAN + sumRCO.JAN) - (sumIMPOSTO.JAN + sumCUSTO.JAN);
  marginReal.FEV = (sumRRE.FEV + sumRCO.FEV) - (sumIMPOSTO.FEV + sumCUSTO.FEV);
  marginReal.MAR = (sumRRE.MAR + sumRCO.MAR) - (sumIMPOSTO.MAR + sumCUSTO.MAR);
  marginReal.ABR = (sumRRE.ABR + sumRCO.ABR) - (sumIMPOSTO.ABR + sumCUSTO.ABR);
  marginReal.MAI = (sumRRE.MAI + sumRCO.MAI) - (sumIMPOSTO.MAI + sumCUSTO.MAI);
  marginReal.JUN = (sumRRE.JUN + sumRCO.JUN) - (sumIMPOSTO.JUN + sumCUSTO.JUN);
  marginReal.JUL = (sumRRE.JUL + sumRCO.JUL) - (sumIMPOSTO.JUL + sumCUSTO.JUL);
  marginReal.AGO = (sumRRE.AGO + sumRCO.AGO) - (sumIMPOSTO.AGO + sumCUSTO.AGO);
  marginReal.SET = (sumRRE.SET + sumRCO.SET) - (sumIMPOSTO.SET + sumCUSTO.SET);
  marginReal.OUT = (sumRRE.OUT + sumRCO.OUT) - (sumIMPOSTO.OUT + sumCUSTO.OUT);
  marginReal.NOV = (sumRRE.NOV + sumRCO.NOV) - (sumIMPOSTO.NOV + sumCUSTO.NOV);
  marginReal.DEZ = (sumRRE.DEZ + sumRCO.DEZ) - (sumIMPOSTO.DEZ + sumCUSTO.DEZ);

  if ((sumRRE.JAN + sumRCO.JAN - sumIMPOSTO.JAN) !== 0) {
    marginPorc.JAN = ((marginReal.JAN / (sumRRE.JAN - sumIMPOSTO.JAN)) * 100);
  }
  if ((sumRRE.FEV + sumRCO.FEV - sumIMPOSTO.FEV) !== 0) {
    marginPorc.FEV = ((marginReal.FEV / (sumRRE.FEV - sumIMPOSTO.FEV)) * 100);
  }
  if ((sumRRE.MAR + sumRCO.MAR - sumIMPOSTO.MAR) !== 0) {
    marginPorc.MAR = ((marginReal.MAR / (sumRRE.MAR - sumIMPOSTO.MAR)) * 100);
  }
  if ((sumRRE.ABR + sumRCO.ABR - sumIMPOSTO.ABR) !== 0) {
    marginPorc.ABR = ((marginReal.ABR / (sumRRE.ABR - sumIMPOSTO.ABR)) * 100);
  }
  if ((sumRRE.MAI + sumRCO.MAI - sumIMPOSTO.MAI) !== 0) {
    marginPorc.MAI = ((marginReal.MAI / (sumRRE.MAI - sumIMPOSTO.MAI)) * 100);
  }
  if ((sumRRE.JUN + sumRCO.JUN - sumIMPOSTO.JUN) !== 0) {
    marginPorc.JUN = ((marginReal.JUN / (sumRRE.JUN - sumIMPOSTO.JUN)) * 100);
  }
  if ((sumRRE.JUL + sumRCO.JUL - sumIMPOSTO.JUL) !== 0) {
    marginPorc.JUL = ((marginReal.JUL / (sumRRE.JUL - sumIMPOSTO.JUL)) * 100);
  }
  if ((sumRRE.AGO + sumRCO.AGO - sumIMPOSTO.AGO) !== 0) {
    marginPorc.AGO = ((marginReal.AGO / (sumRRE.AGO - sumIMPOSTO.AGO)) * 100);
  }
  if ((sumRRE.SET + sumRCO.SET - sumIMPOSTO.SET) !== 0) {
    marginPorc.SET = ((marginReal.SET / (sumRRE.SET - sumIMPOSTO.SET)) * 100);
  }
  if ((sumRRE.OUT + sumRCO.OUT - sumIMPOSTO.OUT) !== 0) {
    marginPorc.OUT = ((marginReal.OUT / (sumRRE.OUT - sumIMPOSTO.OUT)) * 100);
  }
  if ((sumRRE.NOV + sumRCO.NOV - sumIMPOSTO.NOV) !== 0) {
    marginPorc.NOV = ((marginReal.NOV / (sumRRE.NOV - sumIMPOSTO.NOV)) * 100);
  }
  if ((sumRRE.DEZ + sumRCO.DEZ - sumIMPOSTO.DEZ) !== 0) {
    marginPorc.DEZ = ((marginReal.DEZ / (sumRRE.DEZ - sumIMPOSTO.DEZ)) * 100);
  }


  // marginReal.JAN = marginReal.JAN !== 0 ? maskToMoney3(marginReal.JAN) : '0,00';
  // marginReal.FEV = marginReal.FEV !== 0 ? maskToMoney3(marginReal.FEV) : '0,00';
  // marginReal.MAR = marginReal.MAR !== 0 ? maskToMoney3(marginReal.MAR) : '0,00';
  // marginReal.ABR = marginReal.ABR !== 0 ? maskToMoney3(marginReal.ABR) : '0,00';
  // marginReal.MAI = marginReal.MAI !== 0 ? maskToMoney3(marginReal.MAI) : '0,00';
  // marginReal.JUN = marginReal.JUN !== 0 ? maskToMoney3(marginReal.JUN) : '0,00';
  // marginReal.JUL = marginReal.JUL !== 0 ? maskToMoney3(marginReal.JUL) : '0,00';
  // marginReal.AGO = marginReal.AGO !== 0 ? maskToMoney3(marginReal.AGO) : '0,00';
  // marginReal.SET = marginReal.SET !== 0 ? maskToMoney3(marginReal.SET) : '0,00';
  // marginReal.OUT = marginReal.OUT !== 0 ? maskToMoney3(marginReal.OUT) : '0,00';
  // marginReal.NOV = marginReal.NOV !== 0 ? maskToMoney3(marginReal.NOV) : '0,00';
  // marginReal.DEZ = marginReal.DEZ !== 0 ? maskToMoney3(marginReal.DEZ) : '0,00';

  return { REAL: marginReal, PORC: marginPorc };
};
