import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import {
  ContentStyled,
  Content,
  Screen,
  TabsStyled,
  TabViewStyled,
  TabIconStyled,
  TabTextStyled,
} from '../../../../../common/styles/styled.layout';

import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconCA,
  IconFactory,
  IconProducts,
  IconSetting,
  IconUsers,
} from '../../../../../components/Icons';

import {
  PSTOCK_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';

import Tab_CAScreen from './TabCA';
import Tab_ConfiguracoesScreen from './TabConfiguracoes';
import Tab_FornecedoresScreen from './TabFornecedores';
import Tab_FuncionariosScreen from './TabFuncionarios';
import Tab_ProdutosScreen from './TabProdutos';



const PSTOCK_AdministracaoScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const [atualTab, setAtualTab] = useState('table_funcionarios');
  const [accessConfig, setAccessConfig] = useState(false);


  async function getPermissionConfig() {
    try {
      const url = '/pstock/adm/configuracao/permissao';
      const response = await ApiWS().get(url);

      const permissionStatus = response.data.trim().toUpperCase() === 'Negado'.trim().toUpperCase()
        ? false
        : true;

      setAccessConfig(permissionStatus);
    }
    catch (error) {
      console.error('getPermissionConfig', JSON.stringify(error.response, null, 2));
    }
  };



  useEffect(() => {
    getPermissionConfig();
  }, [user]);



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={2}
          navArray={PSTOCK_NavRoutes}
          title={'PStock'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          <TabsStyled
            id={'control-tab-pstock-adm'}
            activeKey={atualTab}
            onSelect={(tab) => setAtualTab(tab)}>

            <Tab
              eventKey={'table_funcionarios'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconUsers />
                  </TabIconStyled>

                  <TabTextStyled>
                    Funcionários
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_FuncionariosScreen />
            </Tab>


            <Tab
              eventKey={'table_fornecedores'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconFactory />
                  </TabIconStyled>

                  <TabTextStyled>
                    Fornecedores
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_FornecedoresScreen />
            </Tab>


            <Tab
              eventKey={'table_produtos'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconProducts />
                  </TabIconStyled>

                  <TabTextStyled>
                    Produtos
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_ProdutosScreen />
            </Tab>


            <Tab
              eventKey={'table_ca'}
              title={
                <TabViewStyled>
                  <TabIconStyled>
                    <IconCA />
                  </TabIconStyled>

                  <TabTextStyled>
                    CA
                  </TabTextStyled>
                </TabViewStyled>
              }>
              <Tab_CAScreen />
            </Tab>


            {accessConfig && (
              <Tab
                eventKey={'table_configuracoes'}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      <IconSetting />
                    </TabIconStyled>

                    <TabTextStyled>
                      Configurações
                    </TabTextStyled>
                  </TabViewStyled>
                }>
                <Tab_ConfiguracoesScreen />
              </Tab>
            )}
          </TabsStyled>

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default PSTOCK_AdministracaoScreen;
