import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalTableContainer,
  ModalFooterStyled,
  ModalTableObsText,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS from '../../../../../services/api.service';

import {
  formataData,
} from '../../../../../utils/DateUtils';



interface IProps {
  show: boolean;
  values: any;
  onHide?: any;
}



const HistoricoItemModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [historico, setHistorico] = useState([]);

  const {
    CC,
    FILIAL,
    NUM,
    TIPO,
  } = props.values;

  const findHistorico = async () => {
    const consult = {
      'CC': CC,
      'FILIAL': FILIAL,
      'NUM': NUM,
      'TIPO': TIPO,
    };

    setLoading(true);

    ApiWS()
      .post('/suprimento/consulta/pedido/historico', consult)
      .then((resp) => {
        setHistorico(resp.data);
      })
      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };



  useEffect(() => {
    findHistorico();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {
            {
              'CT': 'Historico do Contrato'.toUpperCase(),
              'PC': 'Historico do PC'.toUpperCase(),
              'BM': 'Historico do BM'.toUpperCase(),
              'CP': 'Historico do Contrato de Parceria'.toUpperCase(),
            } [TIPO]
          }
        </TitleGroup>



        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && (!historico || historico === null || historico.length === 0) && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.NUM}
              </ModalTableObsText>

              <NoItemsContent />

            </ModalTableContainer>
          )}



          {!loading && historico && historico.length > 0 && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.NUM}
              </ModalTableObsText>


              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: historico.length,
                })}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity
                    data={historico}
                    columns={[
                      {
                        dataField: 'NIVEL',
                        text: 'Nível',
                        sort: true,
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: 'NOME',
                        text: 'Aprovador',
                        sort: true,
                      },
                      {
                        dataField: 'STATUS',
                        text: 'Situação',
                        sort: true,
                      },
                      {
                        dataField: 'DATALIB',
                        text: 'Aprovado Em',
                        sort: true,
                        headerStyle: () => {
                          return { width: '15%' };
                        },
                        formatter: (cell) => {
                          return formataData(cell);
                        },
                      },
                      {
                        dataField: 'TIPO',
                        text: 'Tipo',
                        sort: true,
                      },
                      {
                        dataField: 'OBS',
                        text: 'Observação',
                        sort: true,
                      },
                    ]}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>

            </ModalTableContainer>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default HistoricoItemModal;
