import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../common/constants';

import ExcelConfigs from '../../../../common/excel';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../common/styles/styled.layout';


import IButton from '../../../../components/Buttons/IButton';
import EmptyContent from '../../../../components/Empty';
import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../components/Loadings';
import TableCuston from '../../../../components/Table';
import DropdownInput from '../../../../components/TextFields/Dropdown';
import TextInput from '../../../../components/TextFields/Input';


import ApiWS, { defaultBaseURL } from '../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../utils/ErrorUtils';
import IFuncEfetivoModel from './EFETIVO.model';


import {
  DividerSpan,
  DividerText,
} from './EFETIVO.styled';
import FuncEfetivoValidationSchema from './EFETIVO.validation';
import { IToast } from '../../../../components/IToast/Toast';



const FUNC_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [carregando, setCarregando] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum efetivo aqui!',
    description: 'Não há nenhum efetivo aqui',
  });

  const [listOs, setListOs] = useState([]);

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [listaEfetivo, setListaEfetivo] = useState([]);
  const [showListEfet, setShowListEfet] = useState(false);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  const [authorized, setAuthorized] = useState(false);


  const columns = [
    {
      dataField: 'RA_MAT',
      text: 'Matrícula',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
    },
    {
      dataField: 'RP',
      text: 'RP',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
    },
    {
      dataField: 'RA_NOME',
      text: 'Funcionário',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'RA_ADMISSA',
      text: 'Admissão',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'RA_TPCONTR',
      text: 'Tipo de Contrato',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'RA_DTFIMCT',
      text: 'Dt. Fim Contr.',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'RJ_DESC',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'CTT_CUSTO',
      text: 'CC',
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'RA_MAOBRA',
      text: 'Mão de Obra',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'RA_SITFOLH',
      text: 'Situação',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'RF_DFERVAT',
      text: 'Dias Férias',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'RF_DFERAAT',
      text: 'Dias Férias Prop.',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'RA_CIC',
      text: 'PIS',
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'RATEIO',
      text: 'Rateio',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (_cell, row) => {
        if (row.RATEIO === 'S') {
          return '%';
        }
        else {
          return '-';
        }
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listaEfetivo.length,
  };



  async function handleEfetivoFunction(values: typeof IFuncEfetivoModel, page_iteration: any) {
    setValuesPayload(values);
    setListaEfetivo([]);
    setPage(1);

    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_efectiveHuman,
    };

    try {
      setListaCarregando(true);
      setShowListEfet(false);

      const url = '/efetivo/home/consulta/efetivo';
      const response = await ApiWS().post(url, payload);

      setListaEfetivo(response.data[0]);
      setTotalResults(response.data[1][0].TOTAL);
      setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));
    }
    catch (error) {
      console.error('EfetivoFunction', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListaCarregando(false);
      setShowListEfet(true);
    }
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleEfetivoFunction(valuesPayload, page_var);
    setPage(page_var);
  };



  function renderListOsInfo(listOsResp: any) {
    const listOsMap = listOsResp.map((os) => {
      const valueOs = os.OS !== '' || os.OS !== null || os.OS !== undefined ? os.OS : null;

      const OsMapped = {
        'key': os.OS,
        'label': os.DESCR,
        'value': valueOs,
      };
      return OsMapped;
    });

    setListOs([
      AllItems,
      ...listOsMap,
    ]);
  };



  async function findOrderService() {
    try {
      setCarregando(true);

      const url = '/efetivo/home/consulta/os';
      const response = await ApiWS().get(url);

      if (response.data.length === 0) {
        setAuthorized(false);
      }
      else {
        setAuthorized(true);
        renderListOsInfo(response.data);
      }
    }
    catch (error) {
      console.error('FindOrderService', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function postExport(values: any) {
    setCarregando(true);

    const url = '/efetivo/home/exporta';



    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  useEffect(() => {
    findOrderService();
  }, []);



  return (

    <ToolkitProvider
      keyField={'id'}
      data={listaEfetivo}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.FUNC_Efetivo,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              title={'Efetivo'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>

              {carregando && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}

              {!carregando && !authorized && (
                <EmptyContent
                  image={unauthorizedObject.image}
                  title={unauthorizedObject.title}
                  description={unauthorizedObject.description}
                />
              )}

              {!carregando && authorized && (
                <ContainerView>

                  <SectionMargin>
                    <TitleGroup>
                      {'Efetivo'.toUpperCase()}
                    </TitleGroup>


                    <SectionMargin>
                      <Formik
                        enableReinitialize
                        validateOnMount
                        validationSchema={FuncEfetivoValidationSchema}
                        initialValues={IFuncEfetivoModel}
                        onSubmit={(values) => {
                          handleEfetivoFunction(values, 1);
                        }}>
                        {({
                          values,
                          resetForm,
                          isValid,
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          setValues,
                        }) => (

                          <FilterContainerForm auto onSubmit={handleSubmit}>

                            <Row>
                              <Col>
                                <DropdownInput
                                  disabled={carregando || listaCarregando}
                                  name={'select_os'}
                                  label={'CC'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={listOs}
                                  value={values.select_os || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />


                                <DividerText>
                                  <DividerSpan>
                                    {'Ou'.toUpperCase()}
                                  </DividerSpan>
                                </DividerText>


                                <TextInput
                                  disabled={carregando || listaCarregando}
                                  type={'text'}
                                  name={'input_os'}
                                  label={'CC'}
                                  placeholder={'OS'}
                                  min={0}
                                  value={values.input_os || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>



                              <Col>
                                <DropdownInput
                                  disabled={carregando || listaCarregando}
                                  name={'input_tipo'}
                                  label={'Tipo'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    AllItems,
                                    {
                                      key: '1',
                                      label: 'Direta',
                                      value: 'Direta',
                                    },
                                    {
                                      key: '2',
                                      label: 'Indireta',
                                      value: 'Indireta',
                                    },
                                  ]}
                                  value={values.input_tipo || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />


                                <TextInput
                                  disabled={carregando || listaCarregando}
                                  type={'text'}
                                  name={'input_funcao'}
                                  label={'Função'}
                                  placeholder={'Função'}
                                  value={values.input_funcao || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />


                                <TextInput
                                  disabled={carregando || listaCarregando}
                                  type={'text'}
                                  name={'input_matricula'}
                                  label={'Matrícula'}
                                  placeholder={'Matrícula'}
                                  max={6}
                                  value={values.input_matricula || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>



                              <Col>
                                <TextInput
                                  disabled={carregando || listaCarregando}
                                  type={'text'}
                                  name={'input_funcionario'}
                                  label={'Funcionário'}
                                  placeholder={'Funcionário'}
                                  value={values.input_funcionario || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />


                                <DropdownInput
                                  disabled={carregando || listaCarregando}
                                  name={'input_situacao'}
                                  label={'Situação'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    AllItems,
                                    {
                                      key: '1',
                                      label: 'Afastado',
                                      value: 'Afastado',
                                    },
                                    {
                                      key: '2',
                                      label: 'Férias',
                                      value: 'Ferias',
                                    },
                                    {
                                      key: '3',
                                      label: 'Normal',
                                      value: 'Normal',
                                    },
                                  ]}
                                  value={values.input_situacao || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />


                                <DropdownInput
                                  disabled={carregando || listaCarregando}
                                  name={'input_ferias'}
                                  label={'Férias'}
                                  defaultValue={'Todos'.toLocaleUpperCase()}
                                  dropArray={[
                                    AllItems,
                                    {
                                      key: '1',
                                      label: 'Vencidas',
                                      value: 'Vencidas',
                                    },
                                  ]}
                                  value={values.input_ferias || 'Todos'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Row>



                            <RowButtonFiltersView>

                              {listaEfetivo.length > 0 && (
                                <IButton
                                  disabled={!isValid || carregando || listaCarregando}
                                  backgroundColor={Colors?.excel}
                                  text={'Exportar'}
                                  onClick={() => {
                                    postExport(values);
                                  }}
                                />
                              )}


                              {listaEfetivo.length > 0 && <SeparatorButton />}


                              <IButton
                                disabled={carregando || listaCarregando}
                                text={'Limpar'}
                                backgroundColor={Colors?.gray}
                                type={'reset'}
                                onClick={() => {
                                  resetForm();

                                  setValues(IFuncEfetivoModel);
                                }}
                              />


                              <IButton
                                disabled={!isValid || carregando || listaCarregando}
                                text={'Filtrar'}
                                backgroundColor={Colors?.primary}
                                type={'submit'}
                              />

                            </RowButtonFiltersView>

                          </FilterContainerForm>

                        )}
                      </Formik>
                    </SectionMargin>



                    <ContainerView>

                      {listaCarregando && (
                        <LoadingsComponent
                          type={'SCREEN'}
                          color={Colors?.tertiary}
                        />
                      )}



                      {!carregando && !listaCarregando && showListEfet && listaEfetivo.length === 0 && (
                        <EmptyContent
                          image={emptyObject.image}
                          title={emptyObject.title}
                          description={emptyObject.description}
                        />
                      )}



                      {!carregando && !listaCarregando && showListEfet && listaEfetivo.length > 0 && (
                        <PaginationProvider
                          pagination={paginationFactory(options)}>
                          {({
                            paginationProps,
                            paginationTableProps,
                          }) => (
                            <TableCuston
                              noQuantity={totalPage === 1}
                              data={listaEfetivo}
                              columns={columns}
                              baseProps={propsTkProvider.baseProps}
                              paginationProps={paginationProps}
                              paginationTableProps={paginationTableProps}
                              actionPrevious={() => handlePagination(0)}
                              actionNext={() => handlePagination(1)}
                              currentPage={page}
                              totalPage={totalPage}
                              totalResults={totalResults}
                            />
                          )}
                        </PaginationProvider>
                      )}

                    </ContainerView>


                  </SectionMargin>

                </ContainerView>
              )}

            </ContentStyled>
          </Content>

        </Screen>

      )}
    </ToolkitProvider>

  );
};



export default FUNC_IndexScreen;
