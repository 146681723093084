import React, { useState } from 'react';

import {
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { Colors } from '../../../../../../../common/constants';
import { TitleGroup } from '../../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { formataParaInputDate } from '../../../../../../../utils/DateUtils';
import { GIModalBodyStyled2, GIModalContainerStyled, ModalFooterStyled } from '../../styled';



interface IProps {
  show?: any;
  values?: any;
  onHide?: any;
  fetchNow?: any;
  listSystemDrop?: any;
  listPermissionDrop?: any;
  listCCDrop?: any;
}



const PermissionChangeModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  const [listPermissionDrop, setListPermissionDrop] = useState(props.listPermissionDrop);



  function postFetchPermissionDrop(values) {
    setLoading(true);
    setListPermissionDrop([]);

    const url = 'gi/regra/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((group, index) => {
            setListPermissionDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postChangePermission_x_User(values) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/permissao/alterar';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.onHide();
          props.fetchNow();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postAddPermission_x_User', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  return (
    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <GIModalContainerStyled>

        <TitleGroup>
          {'Editar Permissão'.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ID: props.values.ID,
            IDUSUARIO: props.values.IDUSER,
            IDREGRA: props.values.IDREGRA,
            IDSIS: props.values.IDSIS,
            IDCCEMP: props.values.IDCC,
            DTINI: props?.values?.DTINI && formataParaInputDate(props?.values?.DTINI.replaceAll('-', '').substring(0, 8)),
            DTFIM: props?.values?.DTFIM && formataParaInputDate(props?.values?.DTFIM.replaceAll('-', '').substring(0, 8)),
          }}
          validationSchema={
            Yup.object().shape({
              ID: Yup.string().required('Obrigatório!'),
              IDUSUARIO: Yup.string().required('Obrigatório!'),
              IDSIS: Yup.string(),
              IDREGRA: Yup.string().required('Obrigatório!'),
              IDCCEMP: Yup.string().required('Obrigatório!'),
              DTINI: Yup.string().required('Obrigatório!'),
            })
          }
          onSubmit={(values) => {
            postChangePermission_x_User(values);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <GIModalBodyStyled2>

                <DropdownInput
                  disabled={loading}
                  name={'IDSIS'}
                  label={'Sistema'}
                  placeholder={'Todos'}
                  defaultValue={values.IDSIS}
                  dropArray={props.listSystemDrop}
                  onChange={(e) => {
                    handleChange(e);
                    postFetchPermissionDrop({ DESCR: '', IDSIS: e.target.value });
                  }}
                  onBlur={handleBlur}
                  value={values.IDSIS}
                />


                <DropdownInput
                  disabled={loading}
                  name={'IDREGRA'}
                  label={'Regra'}
                  placeholder={'Selecione'}
                  defaultValue={values.IDREGRA}
                  dropArray={listPermissionDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDREGRA}
                />


                <DropdownInput
                  disabled={loading}
                  name={'IDCCEMP'}
                  label={'Empresa - CC'}
                  placeholder={'Selecione'}
                  defaultValue={values.IDCCEMP}
                  dropArray={props.listCCDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDCCEMP}
                />

                <Row>
                  <Col>
                    <TextInput
                      disabled={loading}
                      name={'DTINI'}
                      label={'De'}
                      type={'date'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DTINI}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loading}
                      name={'DTFIM'}
                      label={'Até'}
                      type={'date'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DTFIM}
                    />
                  </Col>

                </Row>


              </GIModalBodyStyled2>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </GIModalContainerStyled>

    </Modal>
  );
};



export default PermissionChangeModal;

