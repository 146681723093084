import React from 'react';

import {
  HomeCardContainerStyle,
  HomeCardInfo,
  HomeCardIcon,
  HomeCardTitle,
  HomeCardDescription,
  HomeCardButton,
  HomeCardButtonText,
} from './styled';



interface IProps {
  disabled?: boolean;
  icon?: any;
  title: string;
  description?: string;
  color?: string;
  onClick?: any;
}



const CardHome: React.FC<IProps> = (props) => {
  return (

    <HomeCardContainerStyle
      target={'_blank'}
      color={props.color}
      onClick={!props.disabled && props.onClick}>

      <HomeCardInfo>

        <HomeCardIcon>
          {props.icon}
        </HomeCardIcon>


        <HomeCardTitle>
          {props.title}
        </HomeCardTitle>

        <HomeCardDescription>
          {props.description}
        </HomeCardDescription>

      </HomeCardInfo>


      <HomeCardButton
        color={props.color}
      >

        <HomeCardButtonText>
          Acessar
        </HomeCardButtonText>

      </HomeCardButton>

    </HomeCardContainerStyle>

  );
};



export default CardHome;
