import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../utils/StringUtils';



const PcbAprovacaoValidationSchema = Yup.object().shape({

  CC: Yup.string(),
  // .length(Metrics.input_centroCusto, Messages.input_centroCusto),

  NUM: Yup.string()
    // .length(Metrics.input_pedido, Messages.input_pedido)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  ANEXO: Yup.string(),


});



export default PcbAprovacaoValidationSchema;
