import React from 'react';
import { Row } from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import { ColDescr_E, ColDetours_E, ColItems_E, ColLeader_E, ColObsPers_E } from './styled';


interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}


const PICSectionE: React.FC<IProps> = (props) => {
  return (

    <div>

      <TitleGroup>
        {'E - Organização e Limpeza'.toUpperCase()}
      </TitleGroup>
    
      <Row>
        <ColItems_E>Itens</ColItems_E>
        <ColLeader_E><span>Líder</span></ColLeader_E>
        <ColDescr_E><span>Descrição</span></ColDescr_E>
        <ColObsPers_E><span>Pess. Observ.</span></ColObsPers_E>
        <ColDetours_E><span>Desvios</span></ColDetours_E>
      </Row>

      <Row>
  
        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E1'}
            label={'E1'}
            onBlur={props.handleBlur}
            value={'Ordem e limpeza deficiente'}
          />
        </ColItems_E>

  
        <ColLeader_E>
          <DropdownInput
            name={'E1_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('E1_NPESSOA','');
                props.setFieldValue('E1_NDESVIO','');
              }
              props.setFieldValue('E1_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('E1_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('E1_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.E1_LIDER}
          />
        </ColLeader_E>


        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E1_DESCR}
          />
        </ColDescr_E>


        <ColObsPers_E>
          <TextInput
            disabled={
              props.values.E1_LIDER === ''
              || props.values.E1_LIDER === undefined
              || props.values.E1_LIDER === null
            }
            type={'number'}
            name={'E1_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E1_NPESSOA}
          />
        </ColObsPers_E>


        <ColDetours_E>
          <TextInput
            disabled={
              props.values.E1_LIDER === ''
              || props.values.E1_LIDER === undefined
              || props.values.E1_LIDER === null
            }
            type={'number'}
            name={'E1_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E1_NDESVIO}
          />
        </ColDetours_E>

      </Row>


      <Row>
  
        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E2'}
            label={'E2'}
            onBlur={props.handleBlur}
            value={'Falta de Coleta seletiva'}
          />
        </ColItems_E>

  
        <ColLeader_E>
          <DropdownInput
            name={'E2_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('E2_NPESSOA','');
                props.setFieldValue('E2_NDESVIO','');
              }
              props.setFieldValue('E2_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('E2_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('E2_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.E2_LIDER}
          />
        </ColLeader_E>


        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E2_DESCR'}
            placeholder={'Selecione o Líder'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E2_DESCR}
          />
        </ColDescr_E>
        
        
        <ColObsPers_E>
          <TextInput
            disabled={
              props.values.E2_LIDER === ''
              || props.values.E2_LIDER === undefined
              || props.values.E2_LIDER === null
            }
            type={'number'}
            name={'E2_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E2_NPESSOA}
          />
        </ColObsPers_E>


        <ColDetours_E>
          <TextInput
            disabled={
              props.values.E2_LIDER === ''
              || props.values.E2_LIDER === undefined
              || props.values.E2_LIDER === null
            }
            type={'number'}
            name={'E2_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E2_NDESVIO}
          />
        </ColDetours_E>

      </Row>


      <Row>
  
        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E3'}
            label={'E3'}
            onBlur={props.handleBlur}
            value={'Isolamento inexistente ou deficiente'}
          />
        </ColItems_E>

  
        <ColLeader_E>
          <DropdownInput
            name={'E3_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('E3_NPESSOA','');
                props.setFieldValue('E3_NDESVIO','');
              }
              props.setFieldValue('E3_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('E3_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('E3_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.E3_LIDER}
          />
        </ColLeader_E>


        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E3_DESCR}
          />
        </ColDescr_E>
        
        
        <ColObsPers_E>
          <TextInput
            disabled={
              props.values.E3_LIDER === ''
              || props.values.E3_LIDER === undefined
              || props.values.E3_LIDER === null
            }
            type={'number'}
            name={'E3_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E3_NPESSOA}
          />
        </ColObsPers_E>


        <ColDetours_E>
          <TextInput
            disabled={
              props.values.E3_LIDER === ''
              || props.values.E3_LIDER === undefined
              || props.values.E3_LIDER === null
            }
            type={'number'}
            name={'E3_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E3_NDESVIO}
          />
        </ColDetours_E>

      </Row>

      <Row>
  
        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E4'}
            label={'E4'}
            onBlur={props.handleBlur}
            value={'Falta de sinalização ou identificação'}
          />
        </ColItems_E>

  
        <ColLeader_E>
          <DropdownInput
            name={'E4_LIDER'}
            placeholder={'Selecione o Líder.'}
            dropArray={[...props.listLeaders]}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('E4_NPESSOA','');
                props.setFieldValue('E4_NDESVIO','');
              }
              props.setFieldValue('E4_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('E4_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('E4_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.E4_LIDER}
          />
        </ColLeader_E>


        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E4_DESCR}
          />
        </ColDescr_E>


        <ColObsPers_E>
          <TextInput
            disabled={
              props.values.E4_LIDER === ''
              || props.values.E4_LIDER === undefined
              || props.values.E4_LIDER === null
            }
            type={'number'}
            name={'E4_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E4_NPESSOA}
          />
        </ColObsPers_E>


        <ColDetours_E>
          <TextInput
            disabled={
              props.values.E4_LIDER === ''
              || props.values.E4_LIDER === undefined
              || props.values.E4_LIDER === null
            }
            type={'number'}
            name={'E4_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.E4_NDESVIO}
          />
        </ColDetours_E>

      </Row>

    </div>

  );
};


export default PICSectionE;
