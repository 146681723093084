import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';



const PcbAnexosManutencaoValidationSchema = Yup.object().shape({

  FILIAL: Yup.string()
    .required('Campo Obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),


  TIPO: Yup.string()
    .required('Campo Obrigatório'),


  CONTRATO: Yup.string()
    .when('TIPO', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo Contrato'),
    })
    // .length(Metrics.input_contrato, Messages.input_contrato)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  PLANILHA: Yup.string()
    .when('TIPO', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo Contrato'),
    })
    // .length(Metrics.input_planilha, Messages.input_planilha)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  REVISAO: Yup.string()
    .when('TIPO', {
      is: 'CONTRATO',
      then: Yup.string()
        .required('Obrigatório para o tipo CONTRATO'),
    })
    // .length(Metrics.input_rev, Messages.input_rev)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  NUM: Yup.string()
    .when('TIPO', {
      is: 'PC',
      then: Yup.string()
        .required('Obrigatório para o tipo PC'),
    })
    .when('TIPO', {
      is: 'BM',
      then: Yup.string()
        .required('Obrigatório para o tipo BM'),
    })
    // .length(Metrics.input_pedido, Messages.input_pedido)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

});



export default PcbAnexosManutencaoValidationSchema;
