import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';


const PcbManutencaoCnpjValidationSchema = Yup.object().shape({

  input_filial: Yup.string()
    .required('Campo obrigatório')
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),


  input_num_pedido: Yup.string()
    .required('Campo obrigatório')
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),


  input_cnpj: Yup.string()
    .required('Campo obrigatório'),


  input_loja: Yup.string()
    .required('Campo obrigatório'),


  input_fornecedor: Yup.string()
    .required('Campo obrigatório'),


  input_cnpj_novo: Yup.string()
    .required('Campo obrigatório')
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value))
    .length(Metrics.input_cnpj, Messages.input_cnpj),


  input_loja_novo: Yup.string()
    .required('Campo obrigatório'),


  input_fornecedor_novo: Yup.string()
    .required('Campo obrigatório'),

});



export default PcbManutencaoCnpjValidationSchema;
