import React,
{
  useState,
} from 'react';

import {
  Field,
} from 'formik';

import {
  IconEyeClose,
  IconEyeOpen,
} from '../Icons';

import {
  InputContainer,
  InputGroupComponent,
  ButtonPassStyled,
  LabelStyled,
  InputStyled,
  HelpFeedbackStyled,
  CountTextStyled,
  AreaCountTextStyled,
} from './styled';



interface IProps {
  id?: string;
  type?: string;
  className?: string;

  // required?: boolean;
  disableFeedBackValid?: boolean;
  editable?: boolean;
  disabled?: boolean;
  textHeight?: number;

  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  min?: number | string;
  max?: number | string;

  showPass?: boolean;

  buttonIcon?: any;
  buttonClick?: any;

  onChange?: any;
  onBlur?: any;
  onFocus?: any;

  accept?: string;
  ref?: any;
  multiple?: boolean;

  onKeyUp?: any;

  totalChar?: any;
}



const TextInput: React.FC<IProps> = (props) => {
  const [typePass, setTypePass] = useState(props.type);


  const handlePassword = () => {
    if (typePass === 'password') {
      props.type === 'password' && setTypePass('text');
    }
    else {
      setTypePass('password');
    }
  };

  function returnCalcText() {
    if (props.totalChar >= props.max) {
      return true;
    }
    if (props.totalChar < props.max) {
      return false;
    }
  };


  return (
    <>
      <Field
        disabled={!props.editable || props.disabled}
        name={props.name}>
        {({ field, form }) => {
          const isValid = !form.errors[field.name];
          const isInvalid = form.touched[field.name] && !isValid;
          return (

            <InputContainer
              className={props.className}
              paddingFeedback={props.showPass || props.buttonIcon}>

              <InputGroupComponent>

                {props.label && (
                  <LabelStyled
                    textHeight={props.textHeight}>
                    {props.label}
                  </LabelStyled>
                )}



                <InputStyled
                  {...field}
                  id={props.id}
                  disabled={props.disabled}
                  readOnly={!props.editable || props.disabled}
                  type={typePass || props.type}
                  as={props.textHeight && 'textarea'}
                  textHeight={props.textHeight}
                  name={props.name}
                  placeholder={props.placeholder}
                  minLength={props.min}
                  maxLength={props.max}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  showPass={props.showPass}
                  isValid={!props.disableFeedBackValid && (!props.disabled && form.touched[field.name] && isValid)}
                  isInvalid={!props.disabled && isInvalid}
                  feedback={!props.disabled && form.errors[field.name]}
                  min={props.min}
                  max={props.max}
                  accept={props.accept}
                  ref={props.ref}
                  multiple={props.multiple}
                  onFocus={props.onFocus}
                  onKeyUp={props.onKeyUp}
                />



                {props.showPass && (
                  <ButtonPassStyled
                    onClick={() => {
                      handlePassword();
                    }}>

                    {typePass === 'password' ? (
                      <IconEyeOpen
                        color={'#212529'}
                        size={24}
                      />
                    ) : (
                      <IconEyeClose
                        color={'#212529'}
                        size={24}
                      />
                    )}

                  </ButtonPassStyled>
                )}



                {props.buttonIcon && !props.showPass && (
                  <ButtonPassStyled
                    onClick={props.buttonClick}>

                    {props.buttonIcon}

                  </ButtonPassStyled>
                )}


                <HelpFeedbackStyled type="invalid">
                  {form.errors[field.name]}
                </HelpFeedbackStyled>

              </InputGroupComponent>

            </InputContainer>

          );
        }}

      </Field>


      {props.totalChar !== undefined && props.max && (
        <AreaCountTextStyled exceed={returnCalcText()}>

          <CountTextStyled>{props.totalChar} / {props.max}</CountTextStyled>

        </AreaCountTextStyled>
      )}

    </>
  );
};



TextInput.defaultProps = {
  editable: true,
  disabled: false,
  type: 'text',
};



export default TextInput;
