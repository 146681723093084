const IPicConsultaModel = {
  CC: '',
  AREA: '',
  AUDITOR: '',
  MATRICULA: '',
  DTINI: '',
  DTFIM: '',
};



export default IPicConsultaModel;
