import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { Months } from '../../../../../../../common/arrays';
import { Colors, Images } from '../../../../../../../common/constants';
import { ContainerView, FilterContainerForm, RowButtonFiltersView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { RowTimer, TimerStyle } from '../PFF-realizado-styled';
import PffRealizadoValidationSchema from '../PFF-realizado-validation';
import { PffRealizadoModel } from '../PFF-realizado.model';


interface IProps {
  indexTab?: number;
}


const Tab_LancarRealizadoScreen: React.FC<IProps> = () => {
  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);

  const [loading, setLoading] = useState(false);
  const [listBusiness, setListBusiness] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [erro, setErro] = useState(false);

  const [listOs, setListOs] = useState([{ key: 0, label: 'Todos', value: 'TODOS' }]);

  const [messageObjLoading, setMessageObjLoading] = useState({
    image: '',
    title: 'Lançando o Realizado.',
    description: 'Não feche essa tela! Isso pode demorar um pouco.',
  });

  const [messageObjError, setMessageObjError] = useState({
    image: Images.other500,
    title: 'Erro 500! Atualize e tente novamente.',
    description: 'Caso esse erro persista, contate o suporte!',
  });

  const [messageObjSuccess] = useState({
    image: Images.check,
    title: 'Rezalizado concluído com sucesso!',
    description: '',
  });


  function postFetchListBusiness() {
    setLoading(true);

    const url = '/pff/lancamento/negoc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((neg, index) => {
            setListBusiness((listBusiness) => [...listBusiness, {
              key: index + 1,
              label: neg.NEGOC,
              value: neg.VALOR,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os Negócios. Contate o suporte!',
          });

          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os Negócios. Contate o suporte! ${error.response.status}`,
          });

          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchListOS(values) {
    setLoading(true);
    setListOs([]);

    const url = '/pff/lancamento/os';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          setListOs((listOS) => [...listOS, {
            key: 0,
            label: 'Todos',
            value: 'TODOS',
          }]);

          resp.data.forEach((os, index) => {
            setListOs((listOS) => [...listOS, {
              key: index + 1,
              label: os.OS,
              value: os.OS,
            }],
            );
          });
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os Centros de Custos. Contate o suporte!',
          });

          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os Centros de Custos. Contate o suporte! ${error.response.status}`,
          });

          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  function renderMesageLoading() {
    setTimeout(() => {
      setMessageObjLoading({
        ...messageObjLoading,
        title: 'Não feche essa tela! Isso pode demorar um pouco.',
        description: 'Quando finalizar, informaremos por aqui.',
      });
    }, 10000);

    setTimeout(() => {
      setMessageObjLoading({
        ...messageObjLoading,
        title: 'Quando finalizar, informaremos por aqui.',
        description: 'Enquanto isso você pode trabalhar em outra aba.',
      });
    }, 20000);


    setTimeout(() => {
      setMessageObjLoading({
        ...messageObjLoading,
        title: 'Enquanto isso você pode trabalhar em outra aba.',
        description: 'Lançando o Realizado. Não feche essa tela!',
      });
    }, 30000);


    setTimeout(() => {
      setMessageObjLoading({
        ...messageObjLoading,
        title: 'Lançando o Realizado. Não feche essa tela!',
        description: '',
      });
    }, 40000);
  }

  function postRelease(values) {
    localStorage.setItem('@pff/LANCAMENTO/FLAG/REALIZADO', '0');
    localStorage.setItem('@pff/LANCAMENTO/FLAG/PENDENCIA', '-1');

    localStorage.setItem('@pff/LANCAMENTO/DATA/REALIZADO', '[]');

    setMessageLoading(false);
    setMessageSuccess(false);
    setErro(false);

    setLoading(true);
    setMessageLoading(true);

    renderMesageLoading();

    const url = '/pff/lancamento/permissaotipo';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          setMessageLoading(false);
          setMessageSuccess(true);

          localStorage.setItem('@pff/LANCAMENTO/FLAG/REALIZADO', '1');
          localStorage.setItem('@pff/LANCAMENTO/FLAG/PENDENCIA', resp?.data[0]?.PENDENCIA);

          localStorage.setItem('@pff/LANCAMENTO/DATA/REALIZADO', JSON.stringify(resp.data[2]));

          if(resp?.data[0]?.PENDENCIA === '0'){
            IToast({
              type: 'success',
              message: resp.data[1].MENS,
            });
          }
        }
        else if (resp.status === 401) {
          setMessageLoading(false);
          setErro(true);

          setMessageObjError({
            image: Images.error401,
            title: resp.data[1].MENS,
            description: '',
          });

          IToast({
            type: 'warning',
            message: resp.data[1].MENS,
          });
        }

        if (localStorage.getItem('@pff/LANCAMENTO/FLAG/PENDENCIA') === '1') {
          setMessageLoading(false);
          setErro(true);

          setMessageObjError({
            image: Images.empty,
            title: resp.data[1].MENS,
            description: '',
          });

          IToast({
            type: 'warning',
            message: resp.data[1].MENS,
          });
        }
      })

      .catch((error) => {
        setMessageLoading(false);
        setErro(true);

        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro 500! Atualize e tente novamente.',
            description: 'Caso esse erro persista, contate o suporte!',
          });

          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos.',
          });
        }
        else {
          setMessageObjLoading({
            image: Images.other500,
            title: 'Erro 500! Atualize e tente novamente.',
            description: 'Caso esse erro persista, contate o suporte!',
          });

          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);

        setTimeout(() => {
          setMin(0);
          setSec(0);
        },300);
      });
  };


  useEffect(() => {
    postFetchListBusiness();
  }, []);


  useEffect(() => {
    setTimeout(() => {
      if (sec < 59) {
        setSec(sec + 1);
      }

      if (sec === 59) {
        setSec(0);
        setMin(min + 1);
      }
    }, 1000);
  }, [new Date().getSeconds()]);



  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffRealizadoValidationSchema}
      initialValues={PffRealizadoModel}
      onSubmit={() => {
        return null;
      }}
    >
      {({
        values,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>


          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {messageLoading && (
            <>
              <RowTimer>
                <TimerStyle>
                  {`${min < 10 ? '0' : ''}${min} : ${sec < 10 ? '0' : ''}${sec}`}
                </TimerStyle>
              </RowTimer>

              <EmptyContent
                image={null}
                title={messageObjLoading.title}
                description={messageObjLoading.description}
              />
            </>
          )}



          {messageSuccess && (localStorage.getItem('@pff/LANCAMENTO/FLAG/PENDENCIA') === '0') && (
            <EmptyContent
              image={messageObjSuccess.image}
              title={messageObjSuccess.title}
              description={messageObjSuccess.description}
            />
          )}



          {erro && (
            <EmptyContent
              image={messageObjError.image}
              title={messageObjError.title}
              description={messageObjError.description}
            />
          )}


          {!loading && !messageLoading && (

            <SectionMargin>

              <Container>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>

                    <Col xs={2}>

                      <TextInput
                        disabled={loading}
                        type={'text'}
                        name={'ANO'}
                        label={'Ano'}
                        placeholder={'Ano'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ANO} />

                    </Col>



                    <Col xl={3}>

                      <DropdownInput
                        disabled={loading}
                        name={'MES'}
                        label={'Mês'}
                        placeholder={'Selecione'}
                        dropArray={Months}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.MES} />

                    </Col>


                    <Col xl={4}>

                      <DropdownInput
                        disabled={loading}
                        name={'NEGOC'}
                        label={'Negócio'}
                        dropArray={[
                          {
                            key: 0,
                            label: 'Todos',
                            value: 'TODOS',
                          },
                          ...listBusiness,
                        ]}
                        onChange={(e) => {
                          handleChange(e);

                          if (e.target.value !== 'TODOS') {
                            postFetchListOS({ NEGOC: e.target.value });
                          }
                          else {
                            setListOs([{
                              key: 0,
                              label: 'Todos',
                              value: 'TODOS',
                            }]);
                          }

                          setFieldValue('CC', 'TODOS');
                        }}
                        onBlur={handleBlur}
                        value={values.NEGOC} />

                    </Col>



                    <Col xl={3}>

                      <DropdownInput
                        disabled={loading}
                        name={'CC'}
                        label={'CC'}
                        dropArray={listOs}
                        onChange={(e) => {
                          handleChange(e);

                          setFieldValue('TIPORE', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.CC} />

                    </Col>


                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        text={'Lançar'}
                        backgroundColor={Colors?.primary}
                        type={'submit'}
                        onClick={() => {
                          if (isValid) {
                            postRelease(values);
                          }
                        }}
                      />

                    </RowButtonFiltersView>


                  </Row>

                </FilterContainerForm>

              </Container>

            </SectionMargin>
          )}

        </ContainerView>
      )}

    </Formik>

  );
};



export default Tab_LancarRealizadoScreen;

