const IFuncEfetivoModel = {

  select_os: 'Todos'.toLocaleUpperCase(),

  input_os: '',

  input_tipo: 'Todos'.toLocaleUpperCase(),

  input_funcao: '',

  input_matricula: '',

  input_funcionario: '',

  input_situacao: 'Todos'.toLocaleUpperCase(),

  input_ferias: 'Todos'.toLocaleUpperCase(),

};



export default IFuncEfetivoModel;
