const PStockFornecedorModel = {
  input_cod: '',
  input_razao: '',
  input_nreduz: '',
  input_cnpj: '',
  input_est: '',
};



export default PStockFornecedorModel;
