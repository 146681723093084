import React from 'react';

import {
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';

import { intranet_config } from '../../../utils/Config';

import {
  NavbarCompContainer,
  NavbarContainer,
  NavbarImage,
  NavTitle,
  NavIdStyled,
  NavStyled,
  NavSubTitle,
} from './styled';



interface IProps {
  navKey?: number;
  itemKey?: number;
  navArray?: any;
  title?: string;
  subtitle?: string;
  children?: any;
}



const NavComp: React.FC<IProps> = (props) => {
  return (

    <NavbarCompContainer>

      <NavbarContainer>

        <Navbar.Collapse id="basic-navbar-nav">

          <NavIdStyled className="me-auto">
            <NavbarImage
              alt=""
              src={intranet_config.Images.logo}
            />

            {props.title && (
              <NavTitle>
                {props.title}
              </NavTitle>
            )}

            {props.subtitle && (
              <NavSubTitle>
                {props.subtitle}
              </NavSubTitle>
            )}    
          </NavIdStyled>



          {props.navArray && props.navArray.length > 0 && (
            <NavStyled variant="pills">

              {props.navArray.map((navItem, index) => {
                const keyActive = props.navKey;

                return (
                  <>
                    {(!navItem.items || navItem.items.length === 0) && 
                      <Nav.Link
                        key={index}
                        active={keyActive === navItem.key}
                        target={navItem.target}
                        href={navItem.path}>
                        <span>
                          {navItem.label}
                        </span>
                      </Nav.Link>
                    }

                    {navItem.items && navItem.items.length > 0 &&
                      <NavDropdown 
                        active={keyActive === navItem.key}
                        key={index} 
                        title={navItem.title}>

                        {navItem.items.map((item, index) => {
                          const keyActive = props.itemKey;
                          return(
                            <NavDropdown.Item 
                              active={keyActive === item.key}
                              key={index}
                              href={item.path}>
                              {item.label}
                            </NavDropdown.Item>
                          );
                        })}

                      </NavDropdown>
                    }
                  </>
                );
              })}

            </NavStyled>
          )}

        </Navbar.Collapse>

      </NavbarContainer>

    </NavbarCompContainer>

  );
};



export default NavComp;
