import * as Yup from 'yup';


const RpInclusaoValidationSchema = Yup.object().shape({

  // Requisição

  requisicao_os: Yup.string()
    .required('Campo obrigatório'),

  input_funcao: Yup.string()
    .required('Campo obrigatório'),

  data_abertura: Yup.string(),

  data_prev_atend: Yup.date(),

  input_cargo: Yup.string(),

  desc_cargo: Yup.string(),

  data_necessidade: Yup
    .date()
    .min(Yup.ref('data_prev_atend'), 'Deve ser maior ou igual Prev. Atend.')
    .required('Campo obrigatório'),

  vagas: Yup.number()
    .required('Campo obrigatório')
    .positive('Vagas não pode ser negativo.')
    .min(1, 'O mínimo de vagas deve ser 1'),

  tiporp: Yup.string()
    .required('Campo obrigatório'),

  tipo_contrato: Yup.string()
    .required('Campo obrigatório'),

  prazo_contrato: Yup.string()
    .required('Campo obrigatório'),

  data_fim_contrato: Yup.string()
    .when('prazo_contrato', {
      is: '2',
      then: Yup.string()
        .required('Obrigatório para o Contrato Determinado'),
    }),

  valor_salario: Yup.number(),

  escolaridade: Yup.string()
    .required('Campo obrigatório'),

  desc_escolaridade: Yup.string(),

  formacao_profissional: Yup.string(),

  justificativa_tipo: Yup.string()
    .when('id_tipo_rp', {
      is: '2',
      then: Yup.string()
        .required('Obrigatório para o Tipo Emergencial'),
    }),


  // Dados Complementares

  motivo_solicitacao: Yup.string()
    .required('Campo obrigatório'),

  justificativa: Yup.string(),

  peric_insal: Yup.string()
    .required('Campo obrigatório'),


  beneficios: Yup.string(),

  ajuda_custo: Yup.string()
    .required('Campo obrigatório'),

  desc_sumaria: Yup.string(),

  espec_conhec: Yup.string(),

  caracteristicas: Yup.string(),

  obs_gerais: Yup.string(),

  grupo_atendimento: Yup.string(),

  desc_grupo_atendimento: Yup.string()
    .required('Campo obrigatório'),


  // Soldagem

  soldador: Yup.string()
    .required('Campo Obrigatório'),


  aco_carbono: Yup.string(),

  aco_inox: Yup.string(),

  chapa: Yup.string(),

  ligas_niquel: Yup.string(),

  titanio: Yup.string(),

  aluminio: Yup.string(),

  eletrodo_revestido: Yup.string(),

  a_tubular: Yup.string(),

  tig: Yup.string(),

  mig: Yup.string(),

  mag: Yup.string(),

  processos_outros: Yup.string(),

  materiais_outros: Yup.string(),

});



export default RpInclusaoValidationSchema;
