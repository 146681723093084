import styled from 'styled-components';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
} from 'formik';



interface IProps {
  noMargin?: boolean;
}



export const ModalFirstStyled = styled(Modal)`
  z-index: 400;
`;

export const ModalSecondStyled = styled(Modal)`
  z-index: 500;
`;

export const ModalContainerStyled = styled(Form)`
  padding: 0 1rem 1.3rem;
`;

export const ModalLoadingStyled = styled.div`
  width    : 10vw !important;
  height    : 10vw !important;
`;

export const ModalBodyStyled = styled.div`
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 1.5rem 0;
`;

export const ModalBodyFilterStyled = styled.div`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  margin: 0.5rem 0 1.5rem;
`;

export const ModalFilterView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .filter-input-view{
    width: 100%;
  }
`;


export const ModalTableTitleText = styled.h5`
  color: #000000;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
`;

export const ModalTableSubTitleText = styled.h6`
  color: #5e5e5e;
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  font-size: 1.15rem;
`;

export const ModalTableObsText = styled.h5`
  text-align: center;
  margin-bottom: 1.6rem;
`;


export const ModalTableContainer = styled.div`
  margin-top: ${(props: IProps) => props.noMargin ? '0' : '1.5rem'};
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
`;
