const GIRegraModel = {
  IDSIS: '',
  DESCR: '',
  IDREGRA: '',
  REGRA: '',
};



export default GIRegraModel;
