import * as Yup from 'yup';


const UnitPstockValidationSchema = Yup.object().shape({

  input_unidade: Yup.string(),

});



export default UnitPstockValidationSchema;
