import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../common/constants';

import LoadingsComponent from '../Loadings';

import {
  ModalLoadingStyled,
} from './styled';



interface IProps {
  show: boolean;
}



const LoadingWaitModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      dialogClassName={'modal-loading-square'}
      centered>

      <ModalLoadingStyled>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </ModalLoadingStyled>

    </Modal>

  );
};



export default LoadingWaitModal;
