import styled from 'styled-components';

import {
  Container,
  Nav,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../common/constants';



export const NavbarCompContainer = styled.div`
  width: 100%;
  height: 75px;
  background-color: white;
  padding-bottom: 4px;
`;



export const NavbarContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  width: 90%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
`;


export const NavbarImage = styled.img`
  height: 50px;
  object-fit: container;
`;

export const NavTitle = styled.h1`
  margin-left: 1.5rem;
  color: black;
  font-size: 1.65rem;
  font-weight: bold;
`;

export const NavSubTitle = styled.h2`
  margin-left: -2.45rem;
  margin-top: 2.5em;
  color: black;
  font-size: 1.2rem;
  font-weight: normal;
`;



export const NavIdStyled = styled(Nav)`
  align-items: center;
`;


export const NavStyled = styled(Nav)`

  .nav-link {
    margin: 0 5px;
    padding: 6px 8px;
    color: black !important;
    font-size: 1.125rem;

    :hover {
      background-color: ${Colors?.hoverLink};
    }


    span {
      margin: 0 8px;
    }
  }

  .nav-link.active {
    background-color: ${Colors?.primary};
    color: white !important;
  }

  .nav-link.nav-pills {
    background-color: ${Colors?.primary};
    color: white !important;
  }

  .nav-item.show.dropdown {
    color: white !important;
  }

  .dropdown-item {

    :active {
      background-color: ${Colors?.secondary};
    }
  }

  .dropdown-item.active {
    background-color: ${Colors?.primary};
    color: white !important;

    :active {
      background-color: ${Colors?.primary};
    }
  }

  .dropdown.show>.nav-link {
    background-color: pink;
    color: black !important;
  }

`;
