import * as Yup from 'yup';
import { currentDateToInput } from '../../../../../utils/DateUtils';


const PICCadastroValidationSchema = Yup.object().shape({
  // Geral
  CC: Yup.string()
    .required('Campo Obrigatório'),
  IDAREA: Yup.string()
    .required('Campo Obrigatório'),
  DATA: Yup.date()
    .required('Campo Obrigatório')
    .min(currentDateToInput(-2), 'Registro deve estar entre hoje e 2 dias anteriores')
    .max(currentDateToInput(), 'Registro deve estar entre hoje e 2 dias anteriores'),

  MAT: Yup.string()
    .required('Campo Obrigatório'),
  AUDITOR: Yup.string()
    .required('Campo Obrigatório'),
  FUNCAO: Yup.string()
    .required('Campo Obrigatório'),

  PENDENCIAS: Yup.array(),
  DESVIOS: Yup.string(),
  REC: Yup.array(),
  OBS: Yup.string(),

  A1_LIDER: Yup.string(),
  A1_IDEQUIPE: Yup.string(),
  A1_NPESSOA: Yup.number()
    .when('A1_IDEQUIPE', {
      is: Number,
      then: Yup.number()          
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),     
    }),
  A1_NDESVIO: Yup.number()
    .when('A1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A1_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A2
  A2_LIDER: Yup.string(),
  A2_IDEQUIPE: Yup.string(),
  A2_NPESSOA: Yup.number()
    .when('A2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A2_NDESVIO: Yup.number()
    .when('A2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A2_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A3
  A3_LIDER: Yup.string(),
  A3_IDEQUIPE: Yup.string(),
  A3_NPESSOA: Yup.number()
    .when('A3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A3_NDESVIO: Yup.number()
    .when('A3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A3_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A4
  A4_LIDER: Yup.string(),
  A4_IDEQUIPE: Yup.string(),
  A4_NPESSOA: Yup.number()
    .when('A4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A4_NDESVIO: Yup.number()
    .when('A4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A4_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A5
  A5_LIDER: Yup.string(),
  A5_IDEQUIPE: Yup.string(),
  A5_NPESSOA: Yup.number()
    .when('A5_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A5_NDESVIO: Yup.number()
    .when('A5_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A5_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A6
  A6_LIDER: Yup.string(),
  A6_IDEQUIPE: Yup.string(),
  A6_NPESSOA: Yup.number()
    .when('A6_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A6_NDESVIO: Yup.number()
    .when('A6_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A6_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A7
  A7_LIDER: Yup.string(),
  A7_IDEQUIPE: Yup.string(),
  A7_NPESSOA: Yup.number()
    .when('A7_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A7_NDESVIO: Yup.number()
    .when('A7_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A7_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // A8
  A8_LIDER: Yup.string(),
  A8_IDEQUIPE: Yup.string(),
  A8_NPESSOA: Yup.number()
    .when('A8_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A8_NDESVIO: Yup.number()
    .when('A8_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A8_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),


  // B1
  B1_LIDER: Yup.string(),
  B1_IDEQUIPE: Yup.string(),
  B1_NPESSOA: Yup.number()
    .when('B1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B1_NDESVIO: Yup.number()
    .when('B1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B1_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // B2
  B2_LIDER: Yup.string(),
  B2_IDEQUIPE: Yup.string(),
  B2_NPESSOA: Yup.number()
    .when('B2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B2_NDESVIO: Yup.number()
    .when('B2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B2_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // B3
  B3_LIDER: Yup.string(),
  B3_IDEQUIPE: Yup.string(),
  B3_NPESSOA: Yup.number()
    .when('B3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B3_NDESVIO: Yup.number()
    .when('B3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B3_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // B4
  B4_LIDER: Yup.string(),
  B4_IDEQUIPE: Yup.string(),
  B4_NPESSOA: Yup.number()
    .when('B4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B4_NDESVIO: Yup.number()
    .when('B4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B4_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // B5
  B5_LIDER: Yup.string(),
  B5_IDEQUIPE: Yup.string(),
  B5_NPESSOA: Yup.number()
    .when('B5_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B5_NDESVIO: Yup.number()
    .when('B5_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B5_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),


  // C1
  C1_LIDER: Yup.string(),
  C1_IDEQUIPE: Yup.string(),
  C1_NPESSOA: Yup.number()
    .when('C1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C1_NDESVIO: Yup.number()
    .when('C1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C1_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // C2
  C2_LIDER: Yup.string(),
  C2_IDEQUIPE: Yup.string(),
  C2_NPESSOA: Yup.number()
    .when('C2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C2_NDESVIO: Yup.number()
    .when('C2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C2_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // C3
  C3_LIDER: Yup.string(),
  C3_IDEQUIPE: Yup.string(),
  C3_NPESSOA: Yup.number()
    .when('C3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C3_NDESVIO: Yup.number()
    .when('C3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C3_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // C4
  C4_LIDER: Yup.string(),
  C4_IDEQUIPE: Yup.string(),
  C4_NPESSOA: Yup.number()
    .when('C4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C4_NDESVIO: Yup.number()
    .when('C4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C4_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),


  // D1
  D1_LIDER: Yup.string(),
  D1_IDEQUIPE: Yup.string(),
  D1_NPESSOA: Yup.number()
    .when('D1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  D1_NDESVIO: Yup.number()
    .when('D1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D1_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // D2
  D2_LIDER: Yup.string(),
  D2_IDEQUIPE: Yup.string(),
  D2_NPESSOA: Yup.number()
    .when('D2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  D2_NDESVIO: Yup.number()
    .when('D2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D2_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),


  // E1
  E1_LIDER: Yup.string(),
  E1_IDEQUIPE: Yup.string(),
  E1_NPESSOA: Yup.number()
    .when('E1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E1_NDESVIO: Yup.number()
    .when('E1_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E1_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // E2
  E2_LIDER: Yup.string(),
  E2_IDEQUIPE: Yup.string(),
  E2_NPESSOA: Yup.number()
    .when('E2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E2_NDESVIO: Yup.number()
    .when('E2_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E2_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // E3
  E3_LIDER: Yup.string(),
  E3_IDEQUIPE: Yup.string(),
  E3_NPESSOA: Yup.number()
    .when('E3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E3_NDESVIO: Yup.number()
    .when('E3_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E3_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),

  // E4
  E4_LIDER: Yup.string(),
  E4_IDEQUIPE: Yup.string(),
  E4_NPESSOA: Yup.number()
    .when('E4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E4_NDESVIO: Yup.number()
    .when('E4_IDEQUIPE', {
      is: Number,
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E4_NPESSOA'),'Não pode ser maior que Nº de Pess. Observ.' )
        .required('Campo obrigatório.'),
    }),



});



export default PICCadastroValidationSchema;
