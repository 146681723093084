import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import { GIModalBodyStyled } from '../styled';

import UserDataSection from './Sections/001SectionData';
import UserGroupSection from './Sections/002SectionGroups';
import UserPermissionSection from './Sections/003SectionPermission';
import UserOccupationSection from './Sections/004SectionOccupation';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  valuesCopy?: any;
  valuesConsult?: any;
  fetchNow: any;
}



const UsuarioCUDGIModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      fullscreen
      size={'xl'}
      centered>


      <TitleGroup>
        {props.title.toUpperCase()}
      </TitleGroup>


      <GIModalBodyStyled>

        <UserDataSection
          valuesConsult={props.valuesConsult}
          valuesEdit={props.valuesEdit}
          valuesRemove={props.valuesRemove}
          valuesCopy={props.valuesCopy}
          type={props.type}
          onHide={props.onHide}
          fetchNow={props.fetchNow}
        />


        {props?.type !== 'NEW' && props?.type !== 'COPY' && (
          <>

            <UserGroupSection
              idUser={props?.valuesEdit?.ID || props?.valuesRemove?.ID || props?.valuesConsult?.IDUSU || props?.valuesCopy?.ID || ''}
              type={props?.type}
            />



            <UserPermissionSection
              idUser={props?.valuesEdit?.ID || props?.valuesRemove?.ID || props?.valuesConsult?.IDUSU || props?.valuesCopy?.ID || ''}
              type={props?.type}
            />



            <UserOccupationSection
              idUser={props?.valuesEdit?.ID || props?.valuesRemove?.ID || props?.valuesConsult?.IDUSU || props?.valuesCopy?.ID || ''}
              type={props?.type}
            />

          </>
        )}

      </GIModalBodyStyled>


    </Modal>

  );
};



export default UsuarioCUDGIModal;
