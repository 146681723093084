import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Colors } from '../../../../../../common/constants';



export const PcbAnexoObsBmText = styled.p`
  margin-top: 15px;
  font-size: 1.1rem;
  font-weight: 500;
`;



export const PcbAnexoRowButtonFilterView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;


export const InputFileButtonContainer = styled.div`
  color: ${Colors?.gray};
  margin-top: 0.5em;
  cursor: pointer;
`;


export const ColTagsView = styled(Col)`
  border: 1px solid ${'#dddddd'};
  padding: 0.5em;
  border-radius: 5px;
  text-align: left;
  align-items: left;
`;


export const TagStyled = styled.div`
  background-color: ${Colors?.primary};
  color: ${Colors?.white};
  margin-left: 0.3em;
  margin-top: 0.6em;
  padding: 0 0 0 0.5em;
  display: inline-block;
`;
