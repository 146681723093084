const PcbPedidosModel = {
  input_situacao: 'Todos'.toLocaleUpperCase(),
  input_filial: '',
  input_centro_custo: '',
  input_data_inicio: '',
  input_data_final: '',
  input_numero: '',
  input_fornecedor: '',
  input_comprador: '',
  input_nota: '',
  input_cnpj: '',
  input_tipo: '',
  input_sme: '',
};



export default PcbPedidosModel;
