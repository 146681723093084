const SmeAtendimentoModel = {
  select_os: 'Todos'.toLocaleUpperCase(),
  select_situacao: 'Pendente'.toLocaleUpperCase(),
  select_grp_triagem: 'Todos'.toLocaleUpperCase(),
  select_grp: 'Todos'.toLocaleUpperCase(),
  input_op: '',
  input_de: '',
  input_ate: '',
  input_num_sm: '',
  input_descricao: '',
  input_emitente: '',
};



export default SmeAtendimentoModel;
