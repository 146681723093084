import styled from 'styled-components';



export const TransferSMEButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;

export const TransferSMEMargin = styled.div`
  margin-top: 10px;
`;

export const CheckBoxFilterSMEView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;

  label{
    margin-right: 20px;
  }
`;


export const MoveGroupInputView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
