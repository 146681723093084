const IGercCustoGerencialModel = {

  input_data_inicial: '',

  input_data_final: '',

  input_un_neg: [],

  input_os: [],

};



export default IGercCustoGerencialModel;
