import React from 'react';

import {
  IconFlag,
} from '../../Icons';

import {
  SubtitleContainer,
  SubtitleItem,
  SubtitleSquare,
  SubtitleText,
} from './styled';



export interface IProps {
  noSubtitle?: boolean;
  center?: boolean;

  label?: string;
  backgroundColor?: string;

  array?: Array<any>;

  showFlag?: boolean;

  color?: string;
  size?: number;
}



const SubtitleComponent: React.FC<IProps> = (props) => {
  function renderSubtitle() {
    if (props.showFlag) {
      if (props.array && props.array.length > 0) {
        return (

          <SubtitleContainer>

            {props.array.map((item) => (
              <SubtitleItem>
                <IconFlag
                  size={11}
                  color={item.color}
                />


                <SubtitleText>
                  {item.label}
                </SubtitleText>
              </SubtitleItem>
            ))}

          </SubtitleContainer>

        );
      }
      else if (props.noSubtitle) {
        return (

          <IconFlag
            size={14}
            color={props.color}
          />

        );
      }
      else {
        return (

          <SubtitleContainer>

            <SubtitleItem>
              <IconFlag
                size={14}
                color={props.color}
              />


              <SubtitleText>
                {props.label}
              </SubtitleText>
            </SubtitleItem>

          </SubtitleContainer>

        );
      }
    }
    else {
      if (props.array && props.array.length > 0) {
        return (

          <SubtitleContainer>

            {props.array.map((item) => (
              <SubtitleItem>
                <SubtitleSquare
                  center={props.center}
                  backgroundColor={item.color}
                />


                <SubtitleText>
                  {item.label}
                </SubtitleText>
              </SubtitleItem>
            ))}

          </SubtitleContainer>

        );
      }
      else if (props.noSubtitle) {
        return (

          <SubtitleSquare
            center={true}
            backgroundColor={props.backgroundColor}
          />

        );
      }
      else {
        return (

          <SubtitleContainer>

            <SubtitleItem>
              <SubtitleSquare
                center={props.center}
                backgroundColor={props.backgroundColor}
              />


              <SubtitleText>
                {props.label}
              </SubtitleText>
            </SubtitleItem>

          </SubtitleContainer>

        );
      }
    }
  };



  return renderSubtitle();
};



SubtitleComponent.defaultProps = {
  noSubtitle: false,
  center: false,
};



export default SubtitleComponent;
