import styled from 'styled-components';

import {
  Colors,
} from '../../../common/constants';



interface IProps {
  disabled?: boolean;
  color?: string;
}



export const HomeCardContainerStyle = styled.a`
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 260px;
  margin: 10px;
  background-color: #ffffff;
  border-top-color: ${(props: IProps) => props.disabled
    ? Colors?.buttonDisabled
    : props.color || Colors?.primary};
  border-top-style: solid;
  border-top-width: 2px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: ${(props: IProps) => props.disabled ? 'default' : 'pointer'};
`;

export const HomeCardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
`;

export const HomeCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const HomeCardTitle = styled.h1`
  margin-bottom: 10px;
  color: black;
  font-size: 0.938rem;
  font-weight: bold;
  text-align: center;
`;

export const HomeCardDescription = styled.p`
  margin: 0;
  color: #595959;
  font-size: 0.813rem;
  text-align: center;
`;

export const HomeCardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  background-color: ${(props: IProps) => props.disabled
    ? Colors?.buttonDisabled
    : props.color || Colors?.primary};
`;


export const HomeCardButtonText = styled.p`
  margin: 0;
  color: white;
  fort-size: 11px;
  text-align: center;
`;
