import { intranet_config } from '../../utils/Config';
import Images from './Images';
import Messages from './Messages';
import Metrics from './Metrics';

const Colors = intranet_config?.Colors;
const Keys = intranet_config?.Keys;

export {
  Images,
  Keys,
  Messages,
  Metrics,
  Colors,
};
