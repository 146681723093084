import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';



const RPSecaoComplementar = ({ handleChange, handleBlur, setFieldValue }) => {
  const [listGrupoAtend, setListGrupoAtend] = useState([]);



  const findGruposTriagem = async () => {
    try {
      const url = '/rp/nova_rp/consulta/grupotriagem';
      const response = await ApiWS().get(url);

      response.data.forEach((grupo) => {
        setListGrupoAtend((listGrupoAtend) => [...listGrupoAtend, { key: grupo.ZU_GRTRIAG, label: grupo.ZU_DESGRTR, value: grupo.ZU_GRTRIAG }]);
      });
    }
    catch (error) {
      console.log('findOS', JSON.stringify(error.response, null, 2));
    }
  };



  useEffect(() => {
    findGruposTriagem();
  }, []);



  return (

    <div>
      <TitleGroup>
        {'Dados Complementares'.toUpperCase()}
      </TitleGroup>


      <Row>
        <Col>
          <DropdownInput
            name={'motivo_solicitacao'}
            label={'Motivo Solicitação'}
            placeholder={'Selecione ...'}
            dropArray={[
              {
                key: '01',
                label: 'Aumento de Quadro',
                value: '01',
              },
              {
                key: '02',
                label: 'Vaga Prevista',
                value: '02',
              },
              {
                key: '03',
                label: 'Substituição',
                value: '03',
              },
              {
                key: '04',
                label: 'Outros',
                value: '04',
              },
            ]}
            onChange={handleChange}
            defaultValue={''}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'text'}
            name={'justificativa'}
            label={'Justificativa da Solicitação'}
            placeholder={'Justificativa'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <DropdownInput
            name={'peric_insal'}
            label={'Periculosidade/Insalubridade'}
            placeholder={'Selecione ...'}
            dropArray={[
              {
                key: '1',
                label: 'Periculosidade',
                value: '1',
              },
              {
                key: '2',
                label: 'Insalubridade',
                value: '2',
              },
              {
                key: '0',
                label: 'Não',
                value: '0',
              },
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'text'}
            name={'beneficios'}
            label={'Beneficios'}
            placeholder={'Beneficios'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <DropdownInput
            name={'ajuda_custo'}
            label={'Ajuda de Custo'}
            placeholder={'Selecione ...'}
            dropArray={[
              {
                key: '1',
                label: 'Sim',
                value: '1',
              },
              {
                key: '0',
                label: 'Não',
                value: '0',
              },
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'text'}
            name={'desc_sumaria'}
            label={'Desc. Sumária das Atribuições'}
            placeholder={'Descrição'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <TextInput
            type={'text'}
            name={'espec_conhec'}
            label={'Especialização/Conhecimento'}
            placeholder={'Especialização/Conhecimento'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <TextInput
            type={'text'}
            name={'caracteristicas'}
            label={'Características Pessoais Básicas'}
            placeholder={'Características'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <TextInput
            type={'text'}
            name={'obs_gerais'}
            label={'Obs. Gerais'}
            placeholder={'Obs.'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <DropdownInput
            name={'grupo_atendimento'}
            label={'Grupo de Atendimento'}
            placeholder={'Selecione ...'}
            dropArray={listGrupoAtend}
            onChange={(e: any) => {
              const selectComponentTarget = [...e.target.children];
              const optionSelected = selectComponentTarget.filter((item) => item.selected === true);

              handleChange(e);

              setFieldValue('grupo_atendimento', optionSelected[0].value);
              setFieldValue('desc_grupo_atendimento', optionSelected[0].label);
            }}
            value={'300001'}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </div>

  );
};


export default RPSecaoComplementar;
