import React,
{
  useState, useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import UsuarioCUDGIModal from '../../../_Modals/007UsuarioCUDGIModal';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconList } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GIGrupoModel from './GI-Tab_Grupo.model';


const Tab_GrupoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listGroup, setListGroupDrop] = useState([]);

  const [listUser, setListUser] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Usuários!',
    description: 'Não há nenhum usuário aqui',
  });

  const [showModalConsult, setShowModalConsult] = useState(false);

  const [valuesConsult, setValuesConsult] = useState({});

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  function postFetchGroupDrop(values) {
    setLoading(true);
    setListGroupDrop([]);

    const url = 'gi/grupo/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((group, index) => {
            setListGroupDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchUser(values, page_iteration: any) {
    setLoading(true);
    setShowList(false);
    setListUser([]);
    setValuesPayload(values);


    const url = 'gi/grupo/usuario/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListUser(res.data);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));

          if (res.data.length === 0) {
            setEmptyObject({
              image: Images.empty,
              title: 'Sem Usuários!',
              description: 'Não há nenhum usuário aqui',
            });
          }
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchUser', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchUser(valuesPayload, page_var);
    setPage(page_var);
  };


  useEffect(() => {
    postFetchGroupDrop({ DESCR: '', IDGRUPO: '' });
  }, []);

  const options = {
    custom: true,
    totalSize: listUser.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'MATRICULA',
      text: 'Matrícula',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'NOME',
      text: 'Nome',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'LOGIN',
      text: 'Login',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'EMAIL',
      text: 'Email',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'STATUS',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      formatter: (_cell, row) => {
        if (row.STATUS === '1') {
          return 'Ativo';
        }
        else {
          return 'Desativado';
        }
      },
    },
    {
      dataField: 'CHECKEMAIL',
      text: 'Recebe Email',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      formatter: (_cell, row) => {
        if (row.CHECKEMAIL === '1') {
          return 'Sim';
        }
        else {
          return 'Não';
        }
      },
    },
    {
      dataField: '',
      text: 'Detalhes',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={TextTooltip.DETAILS}
          onClick={() => {
            setShowModalConsult(true);
            setValuesConsult(row);
          }}
          icon={
            <IconList color={Colors?.black} size={16} />
          }
        />,
    },
  ];


  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listUser}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.PCB_Pedidos,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={GIGrupoModel}
              onSubmit={(values) => {
                postFetchUser(values, 1);
              }}>
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
              }) => (

                <>

                  {loading && (
                    <LoadingsComponent />
                  )}



                  <FilterContainerForm auto onSubmit={handleSubmit}>
                    <Row>

                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'IDGRUPO'}
                          label={'Grupo'}
                          placeholder={'Todos'}
                          dropArray={listGroup}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.IDGRUPO}
                        />
                      </Col>



                      <Col>
                        <TextInput
                          type={'text'}
                          name={'GRUPO'}
                          label={'Grupo'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.GRUPO}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>



                </>

              )}

            </Formik>



            {!loading && showList && listUser.length === 0 && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}



            {!loading && showList && listUser.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={listUser}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}
      </ToolkitProvider>


      {showModalConsult && (
        <UsuarioCUDGIModal
          show={showModalConsult}
          onHide={() => setShowModalConsult(false)}
          title={'Consulta de Usuário'}
          type={'CONSULT'}
          valuesConsult={valuesConsult}
          fetchNow={() => postFetchUser({ NOME: '', LOGIN: '', EMAIL: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_GrupoScreen;
