import React,
{
  useState,
} from 'react';

import {
  Container,
} from 'react-bootstrap';


import {
  toast,
} from 'react-toastify';

import {
  Formik,
  Form,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';

import {
  ContentStyled,
  Content,
  Screen,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import ConfirmationModal from '../../../../../components/Modals/Confirmation';

import NameRoutes from '../../../../../navigation/name';

import {
  RP_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';


import {
  GenerateButtonsSMEView,
} from '../../../Supplies/SME/SME_Solicitacao/SME_Nova_Copia/SME-solicitacao.styled';
import IRpInclusaoModel from './RP-inclusao.model';

import {
  AddRpButtonContainer,
} from './RP-inclusao.styled';
import RpInclusaoValidationSchema from './RP-inclusao.validation';
import RPSecaoComplementar from './Sections/RPSecaoComplementar';
import RPSectionRequisicao from './Sections/RPSecaoRequisicao';
import RPSecaoSoldagem from './Sections/RPSecaoSoldagem';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';



const RP_InclusaoScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RP\'s aqui!',
    description: 'Não há nenhuma RP para você',
  });

  const [valueProgressModal, setValueProgressModal] = useState(false);
  const [progressModalShow, setProgressModalShow] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });

  const [loading, setLoading] = useState(false);


  const objToServer = (values: any) => {
    let newObj = values;

    newObj = {
      ACOCARB: values.aco_carbono,
      ACOINOX: values.aco_inox,
      ADTRANS: values.tipo_contrato,
      ALUMIN: values.aluminio,
      BENEFIC: values.beneficios,
      CARGO: values.input_cargo,
      CC: values.requisicao_os,
      CHAPA: values.chapa,
      CODESC: values.escolaridade,
      DESCARG: values.desc_cargo,
      DESCESC: values.desc_escolaridade,
      DESCRIC: values.desc_funcao,
      DESCSUM: values.desc_sumaria,
      DESCUST: values.desc_cc,
      DESGRTR: values.desc_grupo_atendimento,
      DTABERT: values.data_abertura.replaceAll('-', ''),
      DTFCONT: values.data_fim_contrato.replaceAll('-', ''),
      DTNECES: values.data_necessidade.replaceAll('-', ''),
      DTPREVI: values.data_prev_atend.replaceAll('-', ''),
      ESPCONH: values.espec_conhec,
      FORMCAO: values.formacao_profissional,
      FUNCAO: values.input_funcao,
      FUNSOLD: values.soldador,
      GRTRIAG: values.grupo_atendimento,
      JUSTIF: values.justificativa,
      LIGNIQ: values.ligas_niquel,
      MOTIVO: values.motivo_solicitacao,
      NRVAGA: parseInt(values.vagas),
      OBSERVA: values.obs_gerais,
      OUTRMAT: values.materiais_outros,
      PERF: values.caracteristicas,
      PERICUL: values.peric_insal,
      PRAZO: 3,
      PRZCONT: values.prazo_contrato,
      PSOUTRO: values.processos_outros,
      SOLDELT: values.eletrodo_revestido,
      SOLDTIG: values.tig,
      SOLICIT: values.solicitante,
      SOLMAG: values.mag,
      SOLMIG: values.mig,
      SOLTUB: values.a_tubular,
      TITANIO: values.titanio,
      TPCONTR: values.tipo_contrato,
      VCUSTO: values.valor_salario,
      JUST: values.justificativa_tipo,
      TIPRP: String(values?.tiporp).substring(0, values?.tiporp.indexOf(';')),
    };
    return newObj;
  };


  async function postRpInclusao(values: any) {
    ApiWS()
      .post('/rp/nova_rp/insere', objToServer(values))
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('RP salva com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setProgressModalShow(true);
          setValueProgressModal(resp.data);
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao criar RP! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      });
  };


  function postPrinter(values: any) {
    setLoading(true);

    const url = `/rp/impressao/${values}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if(res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={RpInclusaoValidationSchema}
      initialValues={IRpInclusaoModel}
      onSubmit={(values, isValid ) => {
        if(isValid){
          postRpInclusao(values);
        }

        if(!isValid) {
          setMessageValue({
            head: 'Erro!',
            orient: 'Existem campos obrigatórios que não foram preenchidos.',
            orient2: 'Por favor revise o formulário!',
          });
          setMessageModal(true);
        }
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <>
          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && (
            <Screen>

              <NavHeader>
                <NavUser backHome />


                <NavComp
                  navKey={2}
                  navArray={RP_NavRoutes}
                  title={'RP'}
                />
              </NavHeader>



              <Content>
                <ContentStyled fluid>
                  <Container>

                    <Form noValidate onSubmit={handleSubmit}>
                      {/* {JSON.stringify(values)} */}

                      <RPSectionRequisicao
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />


                      <RPSecaoComplementar
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />


                      <RPSecaoSoldagem
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />


                      <AddRpButtonContainer>
                        <IButton
                          // disabled={!isValid} // {!isValid || isSubmitting}
                          type={'submit'}
                          backgroundColor={Colors?.primary}
                          text={'Finalizar'}
                        />
                      </AddRpButtonContainer>

                    </Form>

                  </Container>
                </ContentStyled>
              </Content>


              {progressModalShow && (
                <ConfirmationModal
                  show={progressModalShow}
                  head="Feito!"
                  orient={`RP ${valueProgressModal} gerada com sucesso`}
                  others={
                    <GenerateButtonsSMEView>
                      <IButton
                        backgroundColor={Colors?.tertiary}
                        onClick={() => {
                          postPrinter(valueProgressModal);
                        }}
                        text={'Imprimir'}
                      />

                    </GenerateButtonsSMEView>
                  }
                  negative={() => {
                    window.location.reload();
                    setProgressModalShow(false);
                    setTimeout(() => {
                      setValueProgressModal(null);
                    }, 300);
                  }}
                />
              )}

              {
                messageModal && (
                  <ConfirmationModal
                    show={messageModal}
                    head={messageValue.head}
                    orient={messageValue.orient}
                    orient2={messageValue.orient2}
                    negative={() => {
                      setMessageModal(false);
                    }}
                  />
                )
              }

            </Screen>
          )}
        </>

      )}
    </Formik>

  );
};



export default RP_InclusaoScreen;

