import React,
{
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors, Images,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';
import IButton from '../../../../../components/Buttons/IButton';
import CardDisplay from '../../../../../components/Cards/CardDisplay';
import EmptyContent from '../../../../../components/Empty';
import IPhoto from '../../../../../components/IPhoto';
import LoadingsComponent from '../../../../../components/Loadings';
import TextInput from '../../../../../components/TextFields/Input';

import ApiWS from '../../../../../services/api.service';



import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
  ModalFilterView,
  ModalGridContainer,
} from './styled';



interface IProps {
  show: boolean;
  onHide?: any;
  data: any;
}



const EmployeeModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [disable, setDisable] = useState(true);

  const [employee, setEmployee] = useState('');
  const [listEmployees, setListEmployees] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem resultado!',
    description: 'Não foi encontrado nenhum colaborador com essas informações.',
  });



  const postFechEmployees = async (consulta: any) => {
    setLoading(true);
    setShowList(false);

    const url = '/pav/reconhecimento/localizar';

    ApiWS()
      .post(url, consulta)
      .then((resp) => {
        setListEmployees(resp.data.sort());
      })
      .catch((error) => {
        console.error('postFechEmployees', JSON.stringify(error.response, null, 2));

        setEmptyObject({
          image: Images.error401,
          title: 'Erro!',
          description: 'Tente novamente! Caso o erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Colaboradores'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          <ModalFilterView>
            <TextInput
              className='filter-input-view'
              type={'text'}
              name={'NOME'}
              label={'Digite o Nome'}
              placeholder={'Nome do colaborador'}
              onChange={(e: any) => {
                setEmployee(e.target.value);
                e.target.value !== '' ? setDisable(false) : setDisable(true);
              }}
              value={employee}
            />

            <IButton
              disabled={disable}
              backgroundColor={Colors?.accept}
              text={'Consultar'}
              onClick={() => {
                postFechEmployees([{ 'NOME': employee }]);
              }}
            />
          </ModalFilterView>



          <ModalGridContainer>

            {loading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}



            {listEmployees.length > 0 && showList && (

              listEmployees.map((employee) => (

                <CardDisplay
                  disabled={
                    (employee.PARTICIPA === 'Não')
                    || (employee.RESTRICAO_PRAZO === 'Sim')
                    || (employee.RESTRICAO_DEPARTAMENTO === 'Sim')}
                  photo={
                    <IPhoto
                      disabled={
                        (employee.PARTICIPA === 'Não')
                        || (employee.RESTRICAO_PRAZO === 'Sim')
                        || (employee.RESTRICAO_DEPARTAMENTO === 'Sim')}
                      src={employee.FOTO}
                      alt={employee.NOME}
                      size={'sm'}
                    />
                  }
                  data={[
                    { title: employee?.NOME },
                    { title: employee?.CARGO[1] },
                    { desc: `Matricula: ${employee?.MAT}` },
                    { title: `${employee?.PARTICIPA === 'Não' || employee?.RESTRICAO_DEPARTAMENTO === 'Sim' || employee?.RESTRICAO_PRAZO === 'Sim' ? ('Bloqueado por:') : ''}` },
                    { desc: `${employee?.PARTICIPA === 'Não' ? ('* Não participante. '):''} ${employee?.RESTRICAO_DEPARTAMENTO === 'Sim' ? ('* Mesmo departamento. '):''} ${employee?.RESTRICAO_PRAZO === 'Sim' ? ('* Prazo de envio da estrela.'):''}` },
                  ]}
                  color={Colors?.primary}
                  size={'sm'}
                  onClick={() => {
                    props.data({
                      'NOME': employee?.NOME,
                      'CARGO': employee?.CARGO,
                      'SETOR': employee?.SETOR,
                      'OS': employee?.OS,
                      'MAT': employee?.MAT,
                      'EMAIL': employee?.EMAIL,
                      'FOTO': employee?.FOTO,
                    });
                    setTimeout(() => {
                      props.onHide();
                    }, 300);
                  }}
                />
              ))
            )}


            {!loading && listEmployees.length === 0 && showList && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}

          </ModalGridContainer>

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default EmployeeModal;
