import styled from 'styled-components';
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Colors } from '../../common/constants';



interface IProps {
  type?: string;
  color?: string;
  size?: string;
  fontSize?: string;
}

const ParamsListCommon = `
  overflow-y: scroll;
  max-height: 200px;
  min-height: 200px;
  box-shadow: -4px 3px 11px 1px ${Colors?.gray};
  background-color: ${Colors?.white};
`;

export const ContainerStyle = styled(Container)`
  border-radius: 5px;
  background-color: ${Colors?.inputDisabled};
  padding: 1.2em;
  margin: 2em auto;
  ${(props: IProps) => props?.size === 'xl' ? 'width: 100%' : 'width: 120vh'};
  box-shadow: 0px 0px 10px -4px ${Colors?.grayDark};
`;

export const HeaderStyle = styled.h1`
  text-align: center;
  align-items: center;
  color: ${Colors?.textActiveColor};
  font-size: 1.2em;
  margin-bottom: 1.5%;
  border-bottom: solid 0.5px ${Colors?.gray};
  padding-bottom: 0.2%;
`;

export const RowStyle = styled(Row)`
`;

export const TitleListsHeader = styled.div`
  color: ${(props: IProps) => props.color || Colors?.grayDark};
  width: auto;
  height: auto;
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 10px;
`;



export const ListStyleRight = styled(Col)`
  ${ParamsListCommon};
`;

export const ListStyleLeft = styled(Col)`
  ${ParamsListCommon};
`;

export const ListGroupStyle = styled(ListGroup)`
  border: none;
`;

export const ItemStyle = styled(ListGroup.Item)`
  border: none;
`;



export const AreaButtonList = styled(Col)`
    display: inline-grid;
    -ms-flex-align: center;
    align-items: center;
    max-width: 70px;
    align-content: space-evenly;
    justify-items: center;
    justify-content: center;
    margin-top: 30px;
`;

export const ButtonListStyle = styled(Button)`
  background-color: ${(props: IProps) => props.color || Colors?.grayDark};
  border: none;

  :hover,
  :active,
  :disabled,
  :focus {
    background-color: ${(props: IProps) => props.color || Colors?.grayDark};
    box-shadow: none !important;
  }
`;
