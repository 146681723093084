import React, { useState } from 'react';

import {
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { Colors } from '../../../../../../../common/constants';
import { TitleGroup } from '../../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { formataParaInputDate } from '../../../../../../../utils/DateUtils';
import { GIModalBodyStyled2, GIModalContainerStyled, ModalFooterStyled } from '../../styled';



interface IProps {
  show?: any;
  values?: any;
  onHide?: any;
  fetchNow?: any;
  listOccupationDrop?: any;
  listCCDrop?: any;
}



const OccupationChangeModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postChangePermission_x_User(values) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/permissao/alterar';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.onHide();
          props.fetchNow();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postAddPermission_x_User', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  return (
    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <GIModalContainerStyled>

        <TitleGroup>
          {'Editar Função'.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            IDUSUARIO: props.values.IDUSU,
            IDCCEMP: props.values.IDCCEMP,
            IDFUNCAO: props.values.IDFUNCAO,
            DTINI: props?.values?.DTINI && formataParaInputDate(props?.values?.DTINI.replaceAll('-', '').substring(0, 8)),
            DTFIM: props?.values?.DTFIM && formataParaInputDate(props?.values?.DTFIM.replaceAll('-', '').substring(0, 8)),
          }}
          validationSchema={
            Yup.object().shape({
              IDUSUARIO: Yup.string().required('Obrigatório!'),
              IDCCEMP: Yup.string().required('Obrigatório!'),
              IDFUNCAO: Yup.string().required('Obrigatório!'),
              DTINI: Yup.string().required('Obrigatório!'),
            })
          }
          onSubmit={(values) => {
            postChangePermission_x_User(values);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <GIModalBodyStyled2>


                <DropdownInput
                  disabled={loading}
                  name={'IDREGRA'}
                  label={'Função'}
                  placeholder={'Selecione'}
                  defaultValue={values.IDFUNCAO}
                  dropArray={props.listOccupationDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDFUNCAO}
                />


                <DropdownInput
                  disabled={loading}
                  name={'IDCCEMP'}
                  label={'Empresa - CC'}
                  placeholder={'Selecione'}
                  defaultValue={values.IDCCEMP}
                  dropArray={props.listCCDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDCCEMP}
                />

                <Row>
                  <Col>
                    <TextInput
                      disabled={loading}
                      name={'DTINI'}
                      label={'De'}
                      type={'date'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DTINI}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loading}
                      name={'DTFIM'}
                      label={'Até'}
                      type={'date'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DTFIM}
                    />
                  </Col>

                </Row>


              </GIModalBodyStyled2>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </GIModalContainerStyled>

    </Modal>
  );
};



export default OccupationChangeModal;

