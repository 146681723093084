import React, { useEffect, useState } from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../components/Empty';
import { IconAddTag, IconTrash } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const CCCUDGIModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingListCompany, setLoadingListCompany] = useState(false);

  const [showList] = useState(true);

  const [emptyObject] = useState({
    title: 'Sem Empresas!',
    description: 'Este centro de custos não possui empresas.',
  });


  const [listBusiness, setListBusiness] = useState([]);

  const [listCompanyDrop, setListCompanyDrop] = useState([]);
  const [listBranchDrop, setListBranchDrop] = useState([]);
  const [listCC_x_Company, setListCC_x_Company] = useState([]);


  function postFetchBusiness() {
    setLoading(true);

    const url = '/gi/centrocusto/consultar/negocio';
    const payload = [];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((dep, index) => {
            setListBusiness(
              (list) => [...list, {
                key: index,
                label: dep.NEGOCIO,
                value: dep.VALOR,
              }],
            );
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchBusiness', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postFetchCompany(values) {
    setLoading(true);

    const url = 'gi/empresa/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((company, index) => {
            setListCompanyDrop((list) => [...list, {
              key: index,
              label: company.DESCR,
              value: company.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCompany', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postFetchBranch(values) {
    setLoading(true);

    const url = '/gi/centrocusto/filial/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((data, index) => {
            setListBranchDrop((list) => [...list, {
              key: index,
              label: data.FILIAL,
              value: data.FILIAL,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchBranch', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  function postFetchCC_x_Company(values) {
    setLoadingListCompany(true);

    const url = 'gi/centrocusto/empresa/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListCC_x_Company(res.data);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCC_x_Company', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListCompany(false);
      });
  };

  function postSaveCC_x_Company(values, resetForm) {
    setLoadingListCompany(true);

    const url = 'gi/centrocusto/empresa/adicionar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          postFetchCC_x_Company({ IDCC: props?.valuesEdit?.ID || props?.valuesRemove?.ID });

          resetForm();
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCC_x_Company', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListCompany(false);
      });
  };

  function postremoveCC_x_Company(values) {
    setLoadingListCompany(true);

    values = { ...values, IDCC: props.valuesEdit.ID };

    const url = 'gi/centrocusto/empresa/remover';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          postFetchCC_x_Company({ IDCC: props?.valuesEdit?.ID || props?.valuesRemove?.ID });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCC_x_Company', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListCompany(false);
      });
  };



  function postSaveCC(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/centrocusto/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/centrocusto/alterar';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveRoule', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemoveCC(values) {
    setLoading(true);

    let url = '';

    url = 'gi/centrocusto/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveRoule', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    postFetchBusiness();
    postFetchCompany({ DESCR: '' });
    postFetchBranch({ FILIAL: '' });
  }, []);



  useEffect(() => {
    if (props.type !== 'NEW') {
      postFetchCC_x_Company({ IDCC: props?.valuesEdit?.ID || props?.valuesRemove?.ID });
    }
  }, []);


  const options = {
    custom: true,
    totalSize: listCC_x_Company.length,
  };

  const columns = [
    {
      dataField: 'IDEMP',
      text: 'ID Empresa',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DESCEMP',
      text: 'Empresa',
      sort: true,
      headerStyle: () => {
        return { width: '60%' };
      },
    },
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: '',
      text: props.type !== 'REMOVE' && 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {props.type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.REMOVE}
                icon={
                  <IconTrash color={Colors?.black} size={16} />
                }
                onClick={() => {
                  postremoveCC_x_Company(row);
                }}
              />)}
          </>
        );
      },
    },
  ];


  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}


        {!loading && (

          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || '',
              CC: props.valuesEdit?.CC || props?.valuesRemove?.CC || '',
              DESCR: props.valuesEdit?.DESCR || props?.valuesRemove?.DESCR || '',
              NEGOCIO: props.valuesEdit?.NEGOCIO || props?.valuesRemove?.NEGOCIO || '',
            }}
            validationSchema={
              Yup.object().shape({
                ID: props.type !== 'NEW' ? Yup.string().required('Obrigatório') : Yup.string(),
                CC: Yup.string().required('Obrigatório'),
                DESCR: Yup.string().required('Obrigatório'),
                NEGOCIO: Yup.string().required('Obrigatório'),
              })
            }
            onSubmit={(values) => {
              if (props?.type !== 'REMOVE') {
                postSaveCC(values);
              }
              else if (props?.type === 'REMOVE') {
                postRemoveCC(values);
              }
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (

              <Form onSubmit={handleSubmit}>

                <ModalBodyStyled>

                  <TextInput
                    disabled={loading || (props?.type === 'REMOVE')}
                    name={'CC'}
                    label={'Novo Centro de Custo'}
                    type={'text'}
                    placeholder={'Centro de Custo'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.CC}
                  />


                  <TextInput
                    disabled={loading || (props?.type === 'REMOVE')}
                    name={'DESCR'}
                    label={'Descrição'}
                    type={'text'}
                    placeholder={'Descrição'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.DESCR}
                  />


                  <DropdownInput
                    disabled={loading || (props?.type === 'REMOVE')}
                    name={'NEGOCIO'}
                    label={'Negócio'}
                    placeholder={'Selecione'}
                    dropArray={listBusiness}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.NEGOCIO || ''}
                  />

                </ModalBodyStyled>


                <ModalFooterStyled>

                  <IButton
                    disabled={loading}
                    backgroundColor={Colors?.buttonCancel}
                    text={'Cancelar'}
                    onClick={() => props.onHide()}
                  />


                  <IButton
                    disabled={loading}
                    backgroundColor={Colors?.buttonOk}
                    text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                    type={'submit'}
                  />

                </ModalFooterStyled>

              </Form>

            )}

          </Formik>

        )}


        {props.type !== 'NEW' && (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{
                IDCC: props?.valuesEdit?.ID || props?.valuesRemove?.ID,
                FILIAL: props?.valuesEdit?.FILIAL || props?.valuesRemove?.FILIAL,
                IDEMP: '',
              }}
              validationSchema={
                Yup.object().shape({
                  IDCC: Yup.string().required('Obrigatório!'),
                  IDEMP: Yup.string().required('Obrigatório!'),
                  FILIAL: Yup.string().required('Obrigatório!'),
                })
              }
              onSubmit={(values, { resetForm }) => {
                postSaveCC_x_Company(values, resetForm);
              }}>
              {({
                values,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (

                <Form onSubmit={handleSubmit}>

                  <TitleGroup>
                    {'Empresas'.toUpperCase()}
                  </TitleGroup>


                  {loading && (
                    <LoadingsComponent />
                  )}


                  {!loading && (
                    <>
                      <Row>

                        <Col xs={5}>
                          <DropdownInput
                            disabled={loading || props.type === 'REMOVE'}
                            name={'IDEMP'}
                            label={'Empresa'}
                            placeholder={'Selecione'}
                            dropArray={listCompanyDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.IDEMP}
                          />
                        </Col>


                        <Col xs={5}>
                          <DropdownInput
                            disabled={loading || props.type === 'REMOVE'}
                            name={'FILIAL'}
                            label={'Filial'}
                            placeholder={'Selecione'}
                            dropArray={listBranchDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FILIAL}
                          />
                        </Col>


                        <Col xs={1}>
                          <IButton
                            disabled={loading || props.type === 'REMOVE'}
                            type={'submit'}
                            backgroundColor={Colors?.white}
                            iconLeft={<IconAddTag size={30} color={Colors?.buttonOk} />}
                          />
                        </Col>

                      </Row>

                    </>
                  )}

                </Form>

              )}
            </Formik>



            {loadingListCompany && (
              <LoadingsComponent />
            )}


            {!loadingListCompany && showList && listCC_x_Company.length === 0 && (
              <EmptyContent
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )
            }


            {!loadingListCompany && showList && listCC_x_Company.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={listCC_x_Company.length < 10}
                    data={listCC_x_Company}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            )}

          </>
        )}

      </ModalContainerStyled>

    </Modal>

  );
};



export default CCCUDGIModal;
