import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  ContentStyled,
  FilterContainerForm,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../components/Empty';
import LoadingsComponent from '../../../../../../components/Loadings';
import TableCuston from '../../../../../../components/Table';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import ColetorPstockModel from './coletor.model';
import ColetorPstockValidationSchema from './coletor.validation';
import ModalCreateColetor from './ModalCreateColetor';

import {
  RowButtonColetorView,
} from './styled';



const TabColetor: React.FC = () => {
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [listDeposit, setListDeposit] = useState([]);

  const [coletorArray, setColetorArray] = useState([]);
  const [listLoading, setListLoading] = useState(false);



  function showSnackBarProps() {
    toast.error('Erro ao obter dados.', {
      position: 'bottom-center',
      autoClose: Metrics.messageAutoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }


  async function getColetoresData(values: typeof ColetorPstockModel) {
    const payload = {
      'IDENTIFICADOR': values.input_identificador,
      'DEPOSITO': values.select_deposito,
    };

    try {
      setListLoading(true);
      setShowList(false);

      const url = '/pstock/coletor/consulta';
      const response = await ApiWS().post(url, payload);

      setColetorArray(response.data);
    }
    catch (error) {
      console.error('getColetoresData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };


  function renderListDeposit(listResp: any) {
    const listToMap = listResp.map((itemDeposit) => {
      const valueResp = itemDeposit.ID !== '' || itemDeposit.ID !== null || itemDeposit.ID !== undefined ? itemDeposit.ID : null;

      const listMapped = {
        'key': itemDeposit.ID,
        'label': itemDeposit.NOME,
        'value': valueResp,
      };
      return listMapped;
    });

    setListDeposit(listToMap);
  };


  async function getDepositsSelect() {
    try {
      setLoading(true);

      const url = '/pstock/coletor/lista/deposito';
      const response = await ApiWS().post(url);

      if (response) {
        renderListDeposit(response.data);
      }
      else {
        showSnackBarProps();
      }
    }
    catch (error) {
      console.error('getDepositsSelect', JSON.stringify(error.response, null, 2));

      showSnackBarProps();
    }
    finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    setLoading(true);

    getDepositsSelect();

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);



  const columns = [
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'UNIDADE',
      text: 'Unidade',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DEPOSITO',
      text: 'Depósito',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'IMEI',
      text: 'IMEI',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Data de cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: coletorArray.length,
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={ColetorPstockValidationSchema}
      initialValues={ColetorPstockModel}
      onSubmit={(values) => {
        getColetoresData(values);
      }}>
      {({
        values,
        resetForm,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
      }) => (

        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Coletores'.toUpperCase()}
              </TitleGroup>


              <SectionMargin>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>
                    <Col>
                      <TextInput
                        disabled={loading || listLoading}
                        type={'text'}
                        name={'input_identificador'}
                        label={'Coletor'}
                        placeholder={'Identificador / Descrição / Codigo'}
                        value={values.input_identificador || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>



                    <Col>
                      <DropdownInput
                        disabled={loading}
                        name={'select_deposito'}
                        label={'Depósito'}
                        dropArray={[
                          {
                            key: 'All',
                            label: 'Todos',
                            value: 'Todos'.toLocaleUpperCase(),
                          },
                          ...listDeposit,
                        ]}
                        value={values.select_deposito}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>



                    <Col>
                      <RowButtonColetorView>
                        <IButton
                          disabled={loading || listLoading}
                          backgroundColor={Colors?.tertiaryDark}
                          text={'Criar coletor'}
                          onClick={() => {
                            setOpenModalCreate(true);
                          }}
                        />


                        <SeparatorButton />


                        <IButton
                          disabled={loading || listLoading}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            setValues(ColetorPstockModel);
                          }}
                        />


                        <IButton
                          disabled={!isValid || loading || listLoading}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />
                      </RowButtonColetorView>
                    </Col>
                  </Row>

                </FilterContainerForm>

              </SectionMargin>


              <ContainerView>

                {listLoading && (
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary}
                  />
                )}



                {!loading && !listLoading && showList && coletorArray.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}



                {!loading && !listLoading && showList && coletorArray.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={coletorArray.length < 10}
                        data={coletorArray}
                        columns={columns}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>


            </SectionMargin>

          </ContainerView>



          {openModalCreate && (
            <ModalCreateColetor
              show={openModalCreate}
              negative={(status: boolean) => {
                setOpenModalCreate(false);

                if (status) {
                  getColetoresData(values);
                }
              }}
            />
          )}

        </ContentStyled>

      )}
    </Formik>

  );
};



export default TabColetor;
