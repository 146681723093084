import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../../../utils/StringUtils';


const PcbManutencaoContratoValidationSchema = Yup.object().shape({

  input_contrato: Yup.string()
    .required('Campo obrigatório')
    // .length(Metrics.input_contrato, Messages.input_contrato)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_filial: Yup.string()
    .required('Campo obrigatório')
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

});



export default PcbManutencaoContratoValidationSchema;
