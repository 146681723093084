const PcbManutencaoCnpjModel = {
  input_filial: '',
  input_num_pedido: '',
  input_cnpj: '',
  input_loja: '',
  input_fornecedor: '',
  input_cnpj_novo: '',
  input_loja_novo: '',
  input_fornecedor_novo: '',
};



export default PcbManutencaoCnpjModel;
