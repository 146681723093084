const PStockCaModel = {
  input_cod: '',
  input_produto: '',
  input_cod_fornec: '',
  input_fornecedor: '',
  input_ca: '',
  input_lote: '',
};



export default PStockCaModel;
