import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';



interface IProps {
  show: boolean;
  dadosComp?: any;
  onHide?: any;
  justificativa?: any;
}



const JustificativaReprovarModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Justificativa sobre a Reprovação'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            JUST: '',
          }}
          onSubmit={(values) => {
            if(values.JUST !== '' && values.JUST !== undefined && values.JUST !== null){
              props.justificativa(values.JUST);
              props.onHide();
            }
            else {
              IToast({
                type: 'warning',
                message: 'Justificativa é obrigatória!',
              });
            }
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <TextInput
                  textHeight={50}
                  name={'JUST'}
                  type={'text'}
                  placeholder={'Adicionar justificativa...'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('JUST', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  type={'button'}
                  onClick={props.onHide}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={'Enviar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default JustificativaReprovarModal;
