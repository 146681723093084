import styled,
{
  css,
} from 'styled-components';



export const QrPageFragment = styled.div`
  display: none;
`;



export const QrPageRefContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const QrPageItemPrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 21cm;
  height: 29.7cm;
  min-height: 29.7cm;
  max-height: 29.7cm;
  padding-left: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
`;



export const QrPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.5cm;
  min-height: 2.5cm;
  max-height: 2.5cm;
  padding-top: 1cm;
  padding-bottom: 20px;
`;

export const QrPageLogoHeaderView = styled.div`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  margin-right: 20px;
`;

export const QrPageLogoImage = styled.img`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const QrPageLogoTitleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QrPageLogoTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 3px;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
`;



export const QrAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1px;
  gap: 5px;
`;

export const QrAuthorTitle = styled.h3`
  margin: 0;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
`;

export const QrAuthorName = styled.p`
  margin: 0;
  color: #000000;
  font-size: 14px;
`;



export const QrPageItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Qr10x10Style = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%
    8.33%;
  grid-gap: 5px;
`;

export const QrSubpageContent = styled.div`
  ${Qr10x10Style};

  height: 24cm;
  min-height: 24cm;
  max-height: 24cm;
  padding: 5px;
`;



export const QrViewContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 4.5cm;
  height: 2cm;
  min-height: 2cm;
  max-height: 2cm;
  padding: 2px;
  border: 1px solid #000;
`;

export const QrTextView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 2;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-left: 3px;
  padding-bottom: 3px;
`;

export const QrIdText = styled.h2`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-size: 11px;
  
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1; 
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const QrNameText = styled.h3`
  width: 100%;
  margin: 0;
  color: #000;
  font-size: 11px;
  
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const QrImageView = styled.div`
  display: flex;
  flex-direction: column;
  width: 1.5cm;
  height: 1.5cm;
  min-height: 1.5cm;
  max-height: 1.5cm;
`;

export const QrCodeImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`;



export const QrPageFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 1.2cm;
  min-height: 1.2cm;
  max-height: 1.2cm;
`;

export const QrPagerFooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

export const QrPagerFooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const QrPagerFooterRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`;

export const QrPagerFooterTitle = styled.h3`
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
`;

export const QrPagerFooterText = styled.p`
  margin: 0;
  color: #000000;
  font-size: 16px;
`;
