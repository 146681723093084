const RPSituacao = [
  {
    key: '1',
    label: 'Todos',
    value: 'Todos',
  },
  {
    key: '2',
    label: 'Em Aprovação',
    value: 'Em Aprovação',
  },
  {
    key: '3',
    label: 'Pendente',
    value: 'Pendente',
  },
  {
    key: '4',
    label: 'Encerrada',
    value: 'Encerrada',
  },
];

export default RPSituacao;
