import * as Yup from 'yup';


export const PICCadastroEquipeValidationSchema = Yup.object().shape({
  CC: Yup.string().trim()
    .required('Campo Obrigatório!'),

});

export const PICCadastroNovaEquipeValidationSchema = Yup.object().shape({
  CC: Yup.string()
    .required('Campo Obrigatório!'),

  DESCR: Yup.string()
    .required('Campo Obrigatório!'),
 
  LIDER: Yup.string()
    .required('Campo Obrigatório!'),

});
