import * as Yup from 'yup';


const PAVConfiguracoesValidationSchema = Yup.object().shape({

  EST01: Yup.number()
    .required('Obrigatório.'),
  EST02: Yup.string()
    .required('Obrigatório.'),
  EST03: Yup.number()
    .required('Obrigatório.'),
  PON01: Yup.number()
    .required('Obrigatório.'),
  PON02: Yup.number()
    .required('Obrigatório.'),
  REC01: Yup.number()
    .required('Obrigatório.'),
  REC02: Yup.string()
    .required('Obrigatório.'),

});



export default PAVConfiguracoesValidationSchema;
