import * as Yup from 'yup';


const FuncEfetivoValidationSchema = Yup.object().shape({

  select_os: Yup.string()
    .required('Campo obrigatório'),

  input_os: Yup.string(),

  input_tipo: Yup.string(),

  input_funcao: Yup.string(),

  input_matricula: Yup.string(),

  input_funcionario: Yup.string(),

  input_situacao: Yup.string(),

  input_ferias: Yup.string(),

});



export default FuncEfetivoValidationSchema;
