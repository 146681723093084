import * as Yup from 'yup';



const SMEAcessoValidationSchema = Yup.object().shape({
  
  CODIGO: Yup.string(),
  USUARIO: Yup.string(),

});



export default SMEAcessoValidationSchema;
