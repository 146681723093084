import * as Yup from 'yup';


const MonitorPstockValidationSchema = Yup.object().shape({

  input_codColetor: Yup.string(),

  input_status: Yup.string(),

  input_data: Yup.string(),

  input_tipo: Yup.string(),

});



export default MonitorPstockValidationSchema;
