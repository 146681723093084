import styled from 'styled-components';

import {
  Colors,
} from '../../../common/constants';



interface IProps {
  color?: string;
}



export const HomeCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 480px;
  max-width: 1200px;
  margin: 20px;
  padding: 0;
`;

export const CardDashboardTitle = styled.h3`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 23px 9px 23px;
  background-color: ${(props: IProps) => props.color || Colors?.primary};
  margin: 0;
  color: white;
  font-size: 1.125rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const CardDashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  padding: 18px;
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CardDashChildren = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 420px;
`;
