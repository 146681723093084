import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import {
  Colors,
  Metrics,
} from '../../common/constants';
import { Screen } from '../../common/styles/styled.layout';
import { intranet_config } from '../../utils/Config';


export const ErrorScreenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 5rem;
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;
  background-color: #f5f5f5;
  border-radius: ${Metrics.containerRadius}px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
`;

export const ErrorTitle = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  color: ${Colors?.primaryDark};
  font-size: 20rem;
  font-weight: bold;
`;

export const ErrorDescriptionText = styled.p`
  margin-top: 0.5rem;
  position: relative;
  color: #000000;
  font-size: 2rem;
  font-weight: bold;
`;

export const GoHomeButtonContent = styled.div`
  margin-top: 3rem;
`;

export const LoginBody = styled(Screen)`
  background-image: url("${intranet_config?.Images.background_login}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

export const LoginFormView = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 450px;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  border-radius: ${Metrics.containerRadius}px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
`;

export const LoginView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 380px;
`;

export const LogoImgContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50rem;
  width: 50rem;
  margin-top: -80px;
  padding: 15px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
`;

export const LogoImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const LoginTextsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 5px;
`;

export const WelcomeTitle = styled.h1`
  margin: 0;
  color: #000000;
  font-size: 2rem;
  font-weight: 400;
`;

export const WelcomeText = styled.p`
  margin: 15px 10px 0;
  color: #000000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const LoginInputsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 30px;
`;

export const ButtonLogin = styled(Button)`
  width: 100%;
  margin: 0;
  background-color:${Colors?.secundary};
  border: 0;

  :focus,
  :visited,
  :active {
    background-color:${Colors?.primary};
  }

  :hover {
    background-color:${Colors?.secondary};
  }
`;
