import styled from 'styled-components';
import { Col } from 'react-bootstrap';


export const RowButtonFiltersView = styled(Col)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;


