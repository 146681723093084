import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS from '../../../../../services/api.service';

import {
  formataData,
} from '../../../../../utils/DateUtils';



interface IProps {
  show: boolean;
  items?: Array<any>;
  onHide?: any;
  value?: any;
}



const RPHistoricoAprovacaoModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listHistory, setListHistory] = useState([]);


  const fetchListHistory = (rp) => {
    setLoading(true);
    const consult = rp;

    ApiWS()
      .post('/rp/consulta/pendentes/aprovadores', consult)
      .then((resp) => {
        setListHistory(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
        setLoading(false);
      });
  };



  useEffect(() => {
    fetchListHistory(props.value);
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Histórico de Aprovação de RP'.toUpperCase()}
        </TitleGroup>


        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {(!loading && (!listHistory || listHistory === null || listHistory.length === 0)) && (
            <h5>Sem Itens</h5>
          )}


          {!loading && listHistory && listHistory.length > 0 && (
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: listHistory.length,
              })}>
              {({
                paginationProps,
                paginationTableProps,
              }) => (
                <TableCuston
                  noQuantity
                  data={listHistory}
                  columns={[
                    {
                      dataField: 'NIVEL',
                      text: 'Nível',
                      sort: true,
                      headerStyle: () => {
                        return { width: '9%' };
                      },
                    },
                    {
                      dataField: 'NOME',
                      text: 'Usuário',
                      sort: true,
                    },
                    {
                      dataField: 'STATUS',
                      text: 'Situação',
                      sort: true,
                    },
                    {
                      dataField: 'DATALIB',
                      text: 'Aprov. Em',
                      sort: true,
                      headerStyle: () => {
                        return { width: '9%' };
                      },
                      formatter: (cell) => {
                        return formataData(cell);
                      },
                    },
                    {
                      dataField: 'TIPO',
                      text: 'Tipo',
                      sort: true,
                      headerStyle: () => {
                        return { width: '8%' };
                      },
                    },
                    {
                      dataField: 'OBS',
                      text: 'Observação',
                      sort: true,
                    },
                  ]}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                />
              )}
            </PaginationProvider>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default RPHistoricoAprovacaoModal;
