const currentdate = new Date(); 

const dateTime = currentdate.getDate() + '-'
  + (currentdate.getMonth() + 1) + '-'
  + currentdate.getFullYear() + '_'
  + currentdate.getHours() + '-'
  + ((currentdate.getMinutes() < 10 ? '0' : '') + currentdate.getMinutes());



export const CONFIGS = {
  blobType:                         'data:text/csv;charset=utf-8',
  onlyExportFiltered:               false,
  exportAll:                        true,
};



export const EXCEL_CONTROLADORIA = {
  CENC_CentroCusto:                 `Intranet_CentroCusto_${dateTime}.csv`,
  CENC_CustoGerencial:              `Intranet_CustoGerencial_${dateTime}.csv`,
};



export const EXCEL_EFETIVO = {
  FUNC_Efetivo:                      `Intranet_Efetivo_${dateTime}.csv`,
};


export const EXCEL_HORAS = {
  Horas_Grid:                      `Intranet_Horas_${dateTime}.csv`,
};

export const EXCEL_PIC = {
  PIC_Cadastro_Area:                 `Intranet_PIC_Area_${dateTime}.csv`,
  PIC_Cadastro_Equipe:               `Intranet_PIC_Equipe_${dateTime}.csv`,
  PIC_Consulta:                      `Intranet_PIC_Consulta_${dateTime}.csv`,
  PIC_Relatorio:                     `Intranet_PIC_Relatorio_${dateTime}.csv`,
};


export const EXCEL_POC = {
  POC_Area:                 `Intranet_POC_Area_${dateTime}.csv`,
  POC_Equipe:               `Intranet_POC_Equipe_${dateTime}.csv`,
  POC_Consulta:             `Intranet_POC_Consulta_${dateTime}.csv`,
  POC_Relatorio:            `Intranet_POC_Relatorio_${dateTime}.csv`,
};



export const EXCEL_SUPRIMENTOS = {
  PCB_Pedidos:                      `Intranet_Suprimentos_Pedidos_${dateTime}.csv`,
  PCB_Contratos:                    `Intranet_Suprimentos_Contratos_${dateTime}.csv`,
  
  SME_Atendimento:                  `Intranet_Suprimentos_SME_Atendimento_${dateTime}.csv`,
  SME_Triagem:                      `Intranet_Suprimentos_SME_Triagem_${dateTime}.csv`,
};



const ExcelConfigs = {
  ...CONFIGS,
  ...EXCEL_CONTROLADORIA,
  ...EXCEL_EFETIVO,
  ...EXCEL_PIC,
  ...EXCEL_POC,
  ...EXCEL_SUPRIMENTOS,
  ...EXCEL_HORAS,
};



export default ExcelConfigs;
