import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import EmptyContent from '../../../../../components/Empty';

import {
  IconPrinter,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';
import { intranet_config } from '../../../../../utils/Config';

import {
  currentDateFormated,
  formataData,
} from '../../../../../utils/DateUtils';



import {
  PrinterContainer,
  A4LandscapeContent,
  TitleSection,
} from './POC-consulta-impressao.styled';


const POC_ConsultaImpressaoScreen: React.FC = () => {
  const initialPrintCard = {
    CAB: {
      AREA: '',
      LIDER: '',
      DESCR_EQUIPE: '',
      DATA: '',
      DESVIOS: '',
      CC: '',
      HORACADASTRO: '',
      NUM: '',
      MATRICULA: '',
      OBSERVADOR: '',
      OBS: '',
    },

    A: [],
    B: [],
    C: [],
    D: [],
    E: [],

    SUCESSOS: [],

    REC: [],

    haveData: false,
  };

  const user = useSelector((state: RootState) => state.user.data);

  const { id } = useParams() as any;


  const [loading, setLoading] = useState(false);
  const [groupCardPoc, setGroupCardPOC] = useState(initialPrintCard);

  const emptyObject = {
    image: Images.empty,
    title: 'Sem registro!',
    description: 'Não há nenhum registro de Cartão POC com esse Número!',
  };


  async function getCardPOC(values: any) {
    const url = `/poc/cartao/impressao/${values}`;
    setLoading(true);
    ApiWS()
      .get(url)
      .then((resp) => {
        console.log(resp);
        const groupCard = initialPrintCard;

        if (resp.data.length > 0) {
          groupCard.CAB = {
            CC: resp.data[0].CC,
            NUM: resp.data[0].NUM,
            DATA: resp.data[0].DATA,
            AREA: resp.data[0].AREA,
            LIDER: resp.data[0].EQUIPE,
            DESCR_EQUIPE: resp.data[0].DESCR,
            MATRICULA: resp.data[0].MATRICULA,
            OBSERVADOR: resp.data[0].OBSERVADOR,
            DESVIOS: resp.data[0].DESVIOS,
            OBS: resp.data[0].OBS,
            HORACADASTRO: resp.data[0].HORACADASTRO,
          };



          resp.data.forEach((item: any) => {
            if (item.SUCESSOS !== undefined) {
              groupCard.SUCESSOS = item.SUCESSOS;
            }
          });


          resp.data.forEach((item: any) => {
            if (item.REC !== undefined) {
              groupCard.REC = item.REC;
            }
          });


          resp.data.forEach((item: any) => {
            if (item.ID !== undefined) {
              switch (item.CODITEM[0]) {
                case 'A':
                  groupCard.A.push(item);
                  break;
                case 'B':
                  groupCard.B.push(item);
                  break;
                case 'C':
                  groupCard.C.push(item);
                  break;
                case 'D':
                  groupCard.D.push(item);
                  break;
                case 'E':
                  groupCard.E.push(item);
                  break;
                default:
                  break;
              }
            }
          });

          if (resp.data[0].NUM) {
            groupCard.haveData = true;
          }

          setGroupCardPOC(groupCard);
          console.log(groupCard);
        }
      })
      .catch((err) => {
        console.error('getCardPOC', JSON.stringify(err.response, null, 2));
        // return (err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function renderLoadingComponent() {
    return (
      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary} />
      </PrinterContainer>
    );
  }


  function returnTextItem(value: string) {
    switch (value) {
      case 'A1': return (`${value} - Os Epis,  estão sendo utilizados de forma correta, pelo usuário (s)?`); break;
      case 'A2': return (`${value} - Os Epis,estão em boas condições de uso/integridade?`); break;
      case 'A3': return (`${value} - Os Epis, são específicos para proteger o colaborador (es) quanto aos PERIGOS E RISCOS associados atividade?`); break;

      case 'B1': return (`${value} - Colaborador (es) possuem os CONHECIMENTOS MINIMOS  para realizar esta atividade?`); break;
      case 'B2': return (`${value} - Colaborador (es) conhecem os Perigos e Riscos, associados a atividade? Estimulem através de perguntas!`); break;
      case 'B3': return (`${value} - Colaborador (es)  apresentam sinais de ESTRESSE FISICO OU EMOCIONAL, para realizar a tarefa? (Quando a pressão de trabalho ultrapassa o limite individual dos colaboradores)`); break;
      case 'B4': return (`${value} - Para execução da tarefa é necessário liberar uma GRANDE CARGA DE TRABALHO? (Tentar fazer mais de uma tarefa ao mesmo tempo com pensamento consciente)`); break;
      case 'B5': return (`${value} - A tarefa está sendo executada com PRESSÃO DE TEMPO ? (Ter uma agenda apertada para entrega da atividade/Tarefa)`); break;
      case 'B6': return (`${value} - A tarefa foi compreendida pelo (s) colaborador (es) ou HOUVE COMUNICAÇÃO FALHA? (A informação passada não foi clara, ou não foi completa)`); break;
      case 'B7': return (`${value} - A tarefa esta sendo executada com EXCESSO DE CONFIANÇA? (Tendência a não querer melhorar e de que não precisar seguir procedimentos, pois, acredita que sabe tudo)`); break;
      case 'B8': return (`${value} - A tarefa está sendo executada com DISTRAÇÕES? (Algo está tirando a sua atenção, ou dos colegas, para realização da tarefa)`); break;
      case 'B9': return (`${value} - A tarefa esta sendo executada ao FINAL DE UM TURNO OU CICLO DE TRABALHO? (Querer terminar logo a tarefa, cansaço, concentração reduzida)`); break;

      case 'C1': return (`${value} - Análise de Risco  ou Procedimentos de SMS/EHS, estão entendidos e acessíveis para consulta durante execução da tarefa?`); break;
      case 'C2': return (`${value} - Orientação vaga ou precaria para o trabalho (Análise de Risco ou   Procedimento estão atualizados? Livres de erros ou com prazo vencido? Contém termos vagos como "se necessário", "se disponível“?`); break;
      case 'C3': return (`${value} - Os colaboradores conhecem as fontes de energias (quimica, fisica, térmica, hidráulica, mecânica e pneumática) inerentes a tarefa?`); break;
      case 'C4': return (`${value} -  Os treinamentos que HABILITAM os colaborador (es) executar a tarefa, estão válidos?`); break;

      case 'D1': return (`${value} - As Ferramentas e Equipamentos, são adequadas para atividade?`); break;
      case 'D2': return (`${value} - As Ferramentas e Equipamentos, estão em bom estado de conservação?`); break;
      case 'D3': return (`${value} - As ferramentas e Equipametos, são compatíveis e dimensionadas em  nº suficiente para execução da tarefa?`); break;

      case 'E1': return (`${value} - Ambiente de Trabalho, está limpo e organizado?`); break;
      case 'E2': return (`${value} - Isolamento / Sinalização / Identificação da área, estão compátiveis com a  tarefa?`); break;
      case 'E3': return (`${value} - Coleta Seletiva, está sendo atendida e de acordo com os resíduos gerados na tarefa?`); break;
      case 'E4': return (`${value} -  Ambiente de trabalho, está desobstruido e livres de armadilhas?`); break;

      default:
        return null;
        break;
    }
  };


  function returnTextItemSucessCase(value: number) {
    switch (value) {
      case 1: return (`${value} - O líder da atividade participou do DDS Hoje?`); break;
      case 2: return (`${value} - O líder determinou o padrinho para orientar os novos colaboradores da equipe ?`); break;
      case 3: return (`${value} - O líder verificou os pontos de bloqueios (Etiqueta principal e bloqueios individuais), junto a sua equipe de trabalho?`); break;
      case 4: return (`${value} - Existem rotas de fugas seguras (rampas, andaimes, escadas e passarelas)  e são de conhecimento da equipe de trabalho, em caso de evcuação de área?`); break;      case 5: return (`${value} - Durante a execução da tarefa, o líder imediato  orienta os colaboradores o que fazer? como fazer? e quando parar?`); break;

      default:
        return null;
        break;
    }
  };

  useEffect(() => {
    getCardPOC(id);
  }, []);



  return (
    <>
      {!loading && groupCardPoc.haveData &&
        (
          <A4LandscapeContent className="page-a4-landscape text-center">

            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td rowSpan={2} className="image-td">
                    <img
                      alt="Logo"
                      src={intranet_config.Images.logo}
                    />
                  </td>
                  <th rowSpan={2} className="name-printer-td">
                    POC - Programa de Observação Comportamental
                  </th>
                  <th colSpan={2}>ID</th>
                  <td colSpan={2}>{groupCardPoc?.CAB.NUM}</td>
                </tr>

                <tr>
                  <th colSpan={2}>Data</th>
                  <td colSpan={2}>{`${formataData(groupCardPoc?.CAB.DATA)} ${groupCardPoc?.CAB.HORACADASTRO}`}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TablePrinterStyled>
              <tbody>
                <tr>
                  <th>O.S.</th>
                  <th>Área</th>
                  <th>Observador</th>
                  <th>Matrícula</th>
                </tr>

                <tr>
                  <td>{groupCardPoc?.CAB.CC}</td>
                  <td>{groupCardPoc?.CAB.AREA}</td>
                  <td>{groupCardPoc?.CAB.OBSERVADOR}</td>
                  <td>{groupCardPoc?.CAB.MATRICULA}</td>
                </tr>

              </tbody>
            </TablePrinterStyled>


            {groupCardPoc.A.length > 0 &&
              <>
                <TitleSection>{'A - EPI, USO E CONDIÇÕES'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Atende</th>
                      <th>Nº. Colabor. Observ.</th>
                      <th>Melhorias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.A.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.DESCR_EQUIPE}</td>
                        <td>{item.ATENDE}</td>
                        <td>{item.NCOLABORADORES}</td>
                        <td>{item.NMELHORIAS}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPoc.B.length > 0 &&
              <>
                <TitleSection>{'B - Pessoas'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Atende</th>
                      <th>Nº. Colabor. Observ.</th>
                      <th>Melhorias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.B.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.DESCR_EQUIPE}</td>
                        <td>{item.ATENDE}</td>
                        <td>{item.NCOLABORADORES}</td>
                        <td>{item.NMELHORIAS}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }

            {groupCardPoc.C.length > 0 &&
              <>
                <TitleSection>{'C - CONTROLE OPERACIONAL'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Atende</th>
                      <th>Nº. Colabor. Observ.</th>
                      <th>Melhorias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.C.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.DESCR_EQUIPE}</td>
                        <td>{item.ATENDE}</td>
                        <td>{item.NCOLABORADORES}</td>
                        <td>{item.NMELHORIAS}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPoc.D.length > 0 &&
              <>
                <TitleSection>{'D - FERRAMENTAS/EQUIPAMENTOS'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Atende</th>
                      <th>Nº. Colabor. Observ.</th>
                      <th>Melhorias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.D.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.DESCR_EQUIPE}</td>
                        <td>{item.ATENDE}</td>
                        <td>{item.NCOLABORADORES}</td>
                        <td>{item.NMELHORIAS}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPoc.E.length > 0 &&
              <>
                <TitleSection>{'E - CONDIÇÃO DO AMBIENTE'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Atende</th>
                      <th>Nº. Colabor. Observ.</th>
                      <th>Melhorias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.E.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.DESCR_EQUIPE}</td>
                        <td>{item.ATENDE}</td>
                        <td>{item.NCOLABORADORES}</td>
                        <td>{item.NMELHORIAS}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }



            <TitleSection>{'Case de Sucesso SMS/EHS'.toLocaleUpperCase()}</TitleSection>
            <TablePrinterStyled>
              {groupCardPoc.SUCESSOS.length > 0
                ?
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Case</th>
                      <th>Atende</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPoc.SUCESSOS.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItemSucessCase(item.item)}</td>
                        <td>{{
                          'S': 'Sim',
                          'N': 'Não',
                        }[item.atende]}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
                :
                <TablePrinterStyled>
                  <tbody>
                    <tr>
                      <td>
                        Sem cases de sucesso.
                      </td>
                    </tr>
                  </tbody>
                </TablePrinterStyled>
              }
            </TablePrinterStyled>

            <TitleSection>{'Desvio crítico / Risco iminente / Incidente ( Presenciado pelo Observador )'.toUpperCase()}</TitleSection>
            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td>
                    {
                      groupCardPoc.CAB.DESVIOS
                        && (groupCardPoc.CAB.DESVIOS !== undefined
                          && groupCardPoc.CAB.DESVIOS !== 'undefined')
                        && groupCardPoc.CAB.DESVIOS !== ''
                        ? groupCardPoc.CAB.DESVIOS
                        : 'Sem Desvio, Risco ou Incidente.'}
                  </td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TitleSection>{'Reconhecimento de Trabalho Seguro'.toUpperCase()}</TitleSection>
            {groupCardPoc.REC.length > 0 && groupCardPoc.REC[0].matricula !== ('').trim()
              ?
              <TablePrinterStyled>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <span>Matrículas: </span>
                      {groupCardPoc.REC.length > 0 &&
                        groupCardPoc.REC.map((matricula, index) => (
                          <>
                            <strong>
                              {matricula.matricula}
                              {index < groupCardPoc.REC.length - 1 && ', '}
                            </strong>
                          </>
                        ))
                      }
                    </td>
                  </tr>
                </tbody>

              </TablePrinterStyled>
              :
              <TablePrinterStyled>
                <tbody>
                  <tr>
                    <td>
                      Sem reconhecimentos.
                    </td>
                  </tr>
                </tbody>
              </TablePrinterStyled>
            }


            <TitleSection>{'Observações e/ou Sugestões'.toUpperCase()}</TitleSection>
            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td>
                    {groupCardPoc.CAB.OBS ? groupCardPoc.CAB.OBS : 'Sem observações e/ou sugestestões.'}
                  </td>
                </tr>
              </tbody>
            </TablePrinterStyled>

            <div>
              <p style={{ textAlign: 'left', marginTop: '2em' }}><i>*- Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
              <ButtonTableAction
                tooltip={TextTooltip.PRINTER}
                onClick={() => {
                  window.print();
                }}
                icon={
                  <IconPrinter
                    color={Colors?.black}
                    size={72}
                  />
                }
              />
            </div>

          </A4LandscapeContent>
        )
      }

      {loading &&
        renderLoadingComponent()
      }

      {!loading && !groupCardPoc.haveData &&
        <A4LandscapeContent className="page-a4-landscape text-center">

          <TablePrinterStyled>
            <tbody>
              <tr>
                <td rowSpan={2} className="image-td">
                  <img
                    alt="Logo"
                    src={intranet_config.Images.logo}
                  />
                </td>
                <th rowSpan={2} className="name-printer-td">
                  POC - Programa de Observação Comportamental
                </th>
                <th colSpan={2}></th>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </TablePrinterStyled>


          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />


          <div>
            <p style={{ textAlign: 'left', marginTop: '2em' }}><i>*- Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
            <ButtonTableAction
              tooltip={TextTooltip.PRINTER}
              onClick={() => {
                window.print();
              }}
              icon={
                <IconPrinter
                  color={Colors?.black}
                  size={72}
                />
              }
            />
          </div>


        </A4LandscapeContent>
      }
    </>
  );
};

export default POC_ConsultaImpressaoScreen;
