export const POCEquipeModel = {
  CC: '',
};

export const POCEquipeModel2 = {
  CC: '',
  LIDER: '',
  DESCR: '',
};


const model = {
  POCEquipeModel: POCEquipeModel,
  POCEquipeModel2: POCEquipeModel2,
};

export default model;
