import React from 'react';

import {
  Form,
  InputGroup,
} from 'react-bootstrap';

import {
  Field,
} from 'formik';

import {
  Colors,
} from '../../common/constants';

import {
  IconArrowDown,
} from '../Icons';

import {
  InputContainer,
  LabelStyled,
  HelpFeedbackStyled,
  ViewPassStyled,
  OptionStyled,
} from './styled';



interface IProps {
  id?: string;
  type?: string;
  className?: string;

  // required?: boolean;
  editable?: boolean;
  disabled?: boolean;
  defaultValue?: any;

  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;

  // showPass?: boolean;

  dropArray: Array<any>;

  // onSelect?: any;
  onBlur?: any;
  onChange?: any;
}



const DropdownInput: React.FC<IProps> = (props) => {
  return (

    <Field
      disabled={!props.editable || props.disabled}
      name={props.name}
      render={({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (

          <InputContainer
            className={props.className}
            readOnly={!props.editable || props.disabled}
            paddingFeedback={true}>

            <InputGroup>

              {props.label && (
                <LabelStyled>
                  {props.label}
                </LabelStyled>
              )}


              <Form.Control
                {...field}
                id={props.id}
                disabled={props.disabled}
                readOnly={!props.editable || props.disabled}
                // required={props.required}
                type={props.type}
                name={props.name}
                // placeholder={props.placeholder}
                value={props.value}
                defaultValue={props.defaultValue}

                onChange={props.onChange}
                onBlur={props.onBlur}
                as={'select'}

                isValid={!props.disabled && form.touched[field.name] && isValid}
                isInvalid={!props.disabled && isInvalid}
                feedback={!props.disabled && form.errors[field.name]}>

                {props.placeholder && (
                  <OptionStyled label={''} value={''}>
                    {props.placeholder}
                  </OptionStyled>
                )}

                {props.dropArray.map((dropItem) =>
                  dropItem.value && (
                    <OptionStyled label={dropItem.label ? dropItem.label : dropItem.value} value={dropItem.value}>
                      {dropItem.label ? dropItem.label : dropItem.value}
                    </OptionStyled>
                  ),
                )}

              </Form.Control>


              <ViewPassStyled>
                <IconArrowDown
                  color={Colors?.textColor}
                  size={28}
                />
              </ViewPassStyled>


              <HelpFeedbackStyled type="invalid">
                {form.errors[field.name]}
              </HelpFeedbackStyled>

            </InputGroup>

          </InputContainer>

        );
      }}
    />

  );
};



DropdownInput.defaultProps = {
  editable: true,
  disabled: false,
  type: 'text',
};



export default DropdownInput;
