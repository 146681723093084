import React, { useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';



interface IProps {
  show: boolean;
  dadosComp?: any;
  onHide?: any;
  justification?: any;
}



const JustificationDisapproveModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Justificativa sobre a Reprovação'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={
            Yup.object().shape({
              'JUST': Yup.string().required('Campo Obrigatório!'),
            })
          }
          initialValues={{
            JUST: '',
            STATUS: '',
          }}
          onSubmit={() => {
            return null;
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <TextInput
                  disabled={loading}
                  textHeight={100}
                  name={'JUST'}
                  type={'text'}
                  label={'Justificativa'}
                  placeholder={'Descrição'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('JUST', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.gray}
                  text={'Cancelar'}
                  type={'button'}
                  onClick={() => {
                    props.onHide(false);
                  }}
                />


                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.buttonCancel}
                  text={'Reprovação Total'}
                  type={'button'}
                  onClick={() => {
                    if (isValid) {
                      values.STATUS = '50';
                      props.justification(values);

                      setLoading(true);
                    }
                    else {
                      IToast({
                        type: 'warning',
                        message: 'Justificativa é obrigatória!',
                      });
                    }
                  }}
                />


                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.tertiaryDark}
                  text={'Reabrir para Revisão'}
                  type={'button'}
                  onClick={() => {
                    if (isValid) {
                      values.STATUS = '40';
                      props.justification(values);

                      setLoading(true);
                    }
                    else {
                      IToast({
                        type: 'warning',
                        message: 'Justificativa é obrigatória!',
                      });
                    }
                  }}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default JustificationDisapproveModal;
