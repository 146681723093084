import React from 'react';
import { Row } from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import { ColDescr_B, ColDetours_B, ColItems_B, ColLeader_B, ColObsPers_B } from './styled';


interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}


const PICSectionB: React.FC<IProps> = (props) => {
  return (

    <div>
      <TitleGroup>
        {'B - Falta de EPI\'s ou Uso Inadequado'.toUpperCase()}
      </TitleGroup>

      <Row>
        <ColItems_B>Itens</ColItems_B>
        <ColLeader_B><span>Líder</span></ColLeader_B>
        <ColDescr_B><span>Descrição</span></ColDescr_B>
        <ColObsPers_B><span>Pess. Observ.</span></ColObsPers_B>
        <ColDetours_B><span>Desvios</span></ColDetours_B>
      </Row>

      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B1'}
            label={'B1'}
            onBlur={props.handleBlur}
            value={'Cabeça'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B1_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('B1_NPESSOA','');
                props.setFieldValue('B1_NDESVIO','');
              }
              props.setFieldValue('B1_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('B1_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('B1_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.B1_LIDER}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.B1_DESCR}
          />
        </ColDescr_B>


        <ColObsPers_B>
          <TextInput
            disabled={
              props.values.B1_LIDER === ''
              || props.values.B1_LIDER === undefined
              || props.values.B1_LIDER === null
            }
            type={'number'}
            name={'B1_NPESSOA'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B1_NPESSOA}
          />
        </ColObsPers_B>


        <ColDetours_B>
          <TextInput
            disabled={
              props.values.B1_LIDER === ''
              || props.values.B1_LIDER === undefined
              || props.values.B1_LIDER === null
            }
            type={'number'}
            name={'B1_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.B1_NDESVIO}
          />
        </ColDetours_B>

      </Row>


      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B2'}
            label={'B2'}
            onBlur={props.handleBlur}
            value={'Olhos e Rosto'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B2_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione a Equipe.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('B2_NPESSOA','');
                props.setFieldValue('B2_NDESVIO','');
              }
              props.setFieldValue('B2_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('B2_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('B2_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.B2_LIDER}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.B2_DESCR}
          />
        </ColDescr_B>


        <ColObsPers_B>
          <TextInput
            disabled={
              props.values.B2_LIDER === ''
              || props.values.B2_LIDER === undefined
              || props.values.B2_LIDER === null
            }
            type={'number'}
            name={'B2_NPESSOA'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B2_NPESSOA}
          />
        </ColObsPers_B>


        <ColDetours_B>
          <TextInput
            disabled={
              props.values.B2_LIDER === ''
              || props.values.B2_LIDER === undefined
              || props.values.B2_LIDER === null
            }
            type={'number'}
            name={'B2_NDESVIO'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B2_NDESVIO}
          />
        </ColDetours_B>

      </Row>


      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B3'}
            label={'B3'}
            onBlur={props.handleBlur}
            value={'Ouvidos'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B3_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione a Equipe.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('B3_NPESSOA','');
                props.setFieldValue('B3_NDESVIO','');
              }
              props.setFieldValue('B3_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('B3_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('B3_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.B3_LIDER}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.B3_DESCR}
          />
        </ColDescr_B>


        <ColObsPers_B>
          <TextInput
            disabled={
              props.values.B3_LIDER === ''
              || props.values.B3_LIDER === undefined
              || props.values.B3_LIDER === null
            }
            type={'number'}
            name={'B3_NPESSOA'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B3_NPESSOA}
          />
        </ColObsPers_B>


        <ColDetours_B>
          <TextInput
            disabled={
              props.values.B3_LIDER === ''
              || props.values.B3_LIDER === undefined
              || props.values.B3_LIDER === null
            }
            type={'number'}
            name={'B3_NDESVIO'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B3_NDESVIO}
          />
        </ColDetours_B>

      </Row>

      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B4'}
            label={'B4'}
            onBlur={props.handleBlur}
            value={'Mãos e Braços'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B4_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione a Equipe.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('B4_NPESSOA','');
                props.setFieldValue('B4_NDESVIO','');
              }
              props.setFieldValue('B4_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('B4_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('B4_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.B4_LIDER}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B4_DESCR'}  
            placeholder={'Selecione o Líder.'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B4_DESCR}
          />
        </ColDescr_B>


        <ColObsPers_B>
          <TextInput
            disabled={
              props.values.B4_LIDER === ''
              || props.values.B4_LIDER === undefined
              || props.values.B4_LIDER === null
            }
            type={'number'}
            name={'B4_NPESSOA'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B4_NPESSOA}
          />
        </ColObsPers_B>


        <ColDetours_B>
          <TextInput
            disabled={
              props.values.B4_LIDER === ''
              || props.values.B4_LIDER === undefined
              || props.values.B4_LIDER === null
            }
            type={'number'}
            name={'B4_NDESVIO'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B4_NDESVIO}
          />
        </ColDetours_B>

      </Row>


      <Row>

        <ColItems_B>
          <TextInput
            disabled
            type={'text'}
            name={'B5'}
            label={'B5'}
            onBlur={props.handleBlur}
            value={'Pés e Pernas'}
          />
        </ColItems_B>


        <ColLeader_B>
          <DropdownInput
            name={'B5_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione a Equipe.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('B5_NPESSOA','');
                props.setFieldValue('B5_NDESVIO','');
              }
              props.setFieldValue('B5_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('B5_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('B5_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value={props.values.B5_LIDER}
          />
        </ColLeader_B>
        

        <ColDescr_B>
          <TextInput
            disabled
            type={'text'}
            name={'B5_DESCR'}  
            placeholder={'Selecione o Líder.'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B5_DESCR}
          />
        </ColDescr_B>


        <ColObsPers_B>
          <TextInput
            disabled={
              props.values.B5_LIDER === ''
              || props.values.B5_LIDER === undefined
              || props.values.B5_LIDER === null
            }
            type={'number'}
            name={'B5_NPESSOA'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B5_NPESSOA}
          />
        </ColObsPers_B>


        <ColDetours_B>
          <TextInput
            disabled={
              props.values.B5_LIDER === ''
              || props.values.B5_LIDER === undefined
              || props.values.B5_LIDER === null
            }
            type={'number'}
            name={'B5_NDESVIO'}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            value={props.values.B5_NDESVIO}
          />
        </ColDetours_B>

      </Row>

    </div>

  );
};


export default PICSectionB;
