import * as Yup from 'yup';



const LoginValidationSchema = Yup.object().shape({
  user: Yup.string()
    .required('O Usuário é obrigatório')
    .min(3, `Precisa conter pelo menos ${3} caracteres`),

  pass: Yup.string()
    .required('A Senha é obrigatório')
    .min(4, `Precisa conter pelo menos ${4} caracteres`),
});



export default LoginValidationSchema;
