import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
  Images,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalTableSubTitleText,
  ModalTableTitleText,
} from '../../../../../../components/Modals/styled';

import {
  convertCPF,
  convertSexo,
} from '../../../../../../utils/ListUtils';

import {
  validateString,
} from '../../../../../../utils/StringUtils';

import {
  AdmModalDetailHeader,
  AdmModalDetailInfoContent,
  AdmModalDetailBody,
  ItemContent,
  ItemLabel,
  ItemText,
  ItemDetailUserImage,
  ItemContentFull,
  SwitchCheckStyled,
} from './styled';



interface IProps {
  show: boolean;
  data: any;
  onEdit?: any;
  onHide?: any;
}



const PStockEmployeeDetailModal: React.FC<IProps> = (props) => {
  const employee = props.data;



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Detalhes do Funcionário'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          <AdmModalDetailHeader>

            <AdmModalDetailInfoContent>
              <ModalTableTitleText>
                Nome: {validateString(employee.NOME) || '-'}
              </ModalTableTitleText>

              <ModalTableSubTitleText>
                Matrícula: {validateString(employee.MATRICULA) || '-'}
              </ModalTableSubTitleText>

              <ModalTableSubTitleText>
                Função: {validateString(employee.FUNCAO) || '-'}
              </ModalTableSubTitleText>

              <ModalTableSubTitleText>
                Sexo: {validateString(convertSexo(employee.SEXO)) || '-'}
              </ModalTableSubTitleText>
            </AdmModalDetailInfoContent>


            <ItemDetailUserImage
              src={employee.FOTO && employee.FOTO !== '' && employee.FOTO !== undefined
                ? employee.FOTO
                : Images.backgroundAvatar
              }
              alt={employee.MATRICULA}
            />

          </AdmModalDetailHeader>



          <AdmModalDetailBody>

            <ItemContent>
              <ItemLabel>
                CC:
              </ItemLabel>

              <ItemText>
                {validateString(employee.CC) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                CPF:
              </ItemLabel>

              <ItemText>
                {validateString(convertCPF(employee.CPF)) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Telefone:
              </ItemLabel>

              <ItemText>
                {validateString(employee.TEL) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContentFull>
              <ItemLabel>
                Email:
              </ItemLabel>

              <ItemText>
                {validateString(employee.EMAIL) || '-'}
              </ItemText>
            </ItemContentFull>


            <ItemContent>
              <ItemLabel>
                Origem:
              </ItemLabel>

              <ItemText>
                {validateString(employee.Cpf) ? employee.Cpf : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Telefone:
              </ItemLabel>

              <ItemText>
                {validateString(employee.Telefone) ? employee.Telefone : '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Bloqueado:
              </ItemLabel>

              <ItemText>
                <SwitchCheckStyled
                  disabled
                  id={'switch-blocked'}
                  type={'switch'}
                  label={employee.BLOQ === 'S' ? 'Bloqueado' : 'Desbloqueado'}
                  checked={employee.BLOQ === 'S'}
                />
              </ItemText>
            </ItemContent>

          </AdmModalDetailBody>

        </ModalBodyStyled>



        <ModalFooterStyled>
          {/* <IButton
            backgroundColor={Colors?.secondary}
            text={'Editar'}
            onClick={props.onEdit}
          /> */}


          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default PStockEmployeeDetailModal;
