import React from 'react';
import { Row } from 'react-bootstrap';
import { YesNoArray2 } from '../../../../../../../../common/arrays/YesNo';
import { TitleGroup } from '../../../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../../components/TextFields/Input';
import { ColItems_D, ColMeets_D, ColObsColab_D, ColImproviment_D, AreaFieldsSeparate, ColDescr_D, ColLeader_D } from '../../../styled';

interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}

const POCSectionD: React.FC<IProps> = (props) => {
  const { handleBlur, handleChange, values, setFieldValue } = props;

  return (
    <AreaFieldsSeparate>

      <Row>
        <TitleGroup>
          {'D - Ferramentas/Equipamentos'.toUpperCase()}
        </TitleGroup>

        <Row>
          <ColItems_D>Itens</ColItems_D>
          <ColLeader_D><span>Líder</span></ColLeader_D>
          <ColDescr_D><span>Descrição</span></ColDescr_D>
          <ColMeets_D><span>Atende</span></ColMeets_D>
          <ColObsColab_D><span>Colab. Observ.</span></ColObsColab_D>
          <ColImproviment_D><span>Melhorias</span></ColImproviment_D>
        </Row>
      </Row>


      {/* D1 */}
      <Row>

        <ColItems_D>
          <TextInput
            disabled
            type={'text'}
            name={'D1'}
            label={'D1'}
            onBlur={handleBlur}
            value={'As Ferramentas e Equipamentos, são adequadas para atividade?'}
          />
        </ColItems_D>


        <ColLeader_D>
          <DropdownInput
            name={'D1_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('D1_NCOLABORADOR', '');
                setFieldValue('D1_NMELHORIA', '');
                setFieldValue('D1_IDEQUIPE', '');
                setFieldValue('D1_LIDER', '');
                setFieldValue('D1_DESCR', '');
              }
              else {
                setFieldValue('D1_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('D1_LIDER', e.target.value.split(',')[1]);
                setFieldValue('D1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_D>
        
        
        <ColDescr_D>
          <TextInput
            disabled
            type={'text'}
            name={'D1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.D1_DESCR}
          />
        </ColDescr_D>
        
        
        <ColMeets_D>
          <DropdownInput
            name={'D1_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_D>


        <ColObsColab_D>
          <TextInput
            disabled={values.D1_DTENDE === ''}
            type={'number'}
            name={'D1_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D1_NCOLABORADORES}
          />
        </ColObsColab_D>


        <ColImproviment_D>
          <TextInput
            disabled={values.D1_DTENDE === ''}
            type={'number'}
            name={'D1_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D1_NMELHORIAS}
          />
        </ColImproviment_D>

      </Row>


      {/* D2 */}
      <Row>

        <ColItems_D>
          <TextInput
            disabled
            type={'text'}
            name={'D2'}
            label={'D2'}
            onBlur={handleBlur}
            value={'As Ferramentas e Equipamentos, estão em bom estado de conservação?'}
          />
        </ColItems_D>


        <ColLeader_D>
          <DropdownInput
            name={'D2_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('D2_NCOLABORADOR', '');
                setFieldValue('D2_NMELHORIA', '');
                setFieldValue('D2_IDEQUIPE', '');
                setFieldValue('D2_LIDER', '');
                setFieldValue('D2_DESCR', '');
              }
              else {
                setFieldValue('D2_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('D2_LIDER', e.target.value.split(',')[1]);
                setFieldValue('D2_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_D>
        
        
        <ColDescr_D>
          <TextInput
            disabled
            type={'text'}
            name={'D2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.D2_DESCR}
          />
        </ColDescr_D>
        
        
        <ColMeets_D>
          <DropdownInput
            name={'D2_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_D>


        <ColObsColab_D>
          <TextInput
            disabled={values.D2_DTENDE === ''}
            type={'number'}
            name={'D2_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D2_NCOLABORADORES}
          />
        </ColObsColab_D>


        <ColImproviment_D>
          <TextInput
            disabled={values.D2_DTENDE === ''}
            type={'number'}
            name={'D2_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D2_NMELHORIAS}
          />
        </ColImproviment_D>

      </Row>


      {/* D3 */}
      <Row>

        <ColItems_D>
          <TextInput
            disabled
            type={'text'}
            name={'D3'}
            label={'D3'}
            onBlur={handleBlur}
            value={'As ferramentas e Equipametos, são compatíveis e dimensionadas em  nº suficiente para execução da tarefa?'}
          />
        </ColItems_D>


        <ColLeader_D>
          <DropdownInput
            name={'D3_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('D3_NCOLABORADOR', '');
                setFieldValue('D3_NMELHORIA', '');
                setFieldValue('D3_IDEQUIPE', '');
                setFieldValue('D3_LIDER', '');
                setFieldValue('D3_DESCR', '');
              }
              else {
                setFieldValue('D3_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('D3_LIDER', e.target.value.split(',')[1]);
                setFieldValue('D3_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_D>
        
        
        <ColDescr_D>
          <TextInput
            disabled
            type={'text'}
            name={'D3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.D3_DESCR}
          />
        </ColDescr_D>
        
        
        <ColMeets_D>
          <DropdownInput
            name={'D3_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_D>


        <ColObsColab_D>
          <TextInput
            disabled={values.D3_DTENDE === ''}
            type={'number'}
            name={'D3_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D3_NCOLABORADORES}
          />
        </ColObsColab_D>


        <ColImproviment_D>
          <TextInput
            disabled={values.D3_DTENDE === ''}
            type={'number'}
            name={'D3_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.D3_NMELHORIAS}
          />
        </ColImproviment_D>

      </Row>

    </AreaFieldsSeparate>
  );
};


export default POCSectionD;
