import * as Yup from 'yup';


const SmeSolicitacaoAcoesValidationSchema = Yup.object().shape({

  input_utilizacao: Yup.string()
    .required('Campo obrigatório'),

  input_orientacao: Yup.string()
    .required('Campo obrigatório'),

  CP_JUST: Yup.string()
    .when('input_orientacao', {
      is: '07',
      then: Yup.string()
        .required('Campo obrigatório'),
    })
    .when('input_orientacao', {
      is: '04',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),

  input_obs: Yup.string()
    .max(250, 'Máximo de 250 caracteres'),

  input_poduto: Yup.string()
    .required('Campo obrigatório'),

  input_unidade: Yup.string()
    .required('Campo obrigatório'),

  input_quantidade: Yup.number()
    .positive('Deve ser maior que 0')
    .required('Campo obrigatório'),

  input_desc_comp: Yup.string(),

  input_limite: Yup.string()
    .required('Campo obrigatório'),

  input_prazo: Yup.string(),

  input_op: Yup.string(),

  CP_TPMANUT: Yup.string()
    .when('input_orientacao', {
      is: '15',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),


  CP_CLVL: Yup.string()
    .when('input_orientacao', {
      is: '15',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),

  CP_MOTM: Yup.string()
    .when('CP_TPMANUT', {
      is: '01',
      then: Yup.string()
        .required('Campo obrigatório'),
    }),

});



export default SmeSolicitacaoAcoesValidationSchema;
