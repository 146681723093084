const FornecedoresModel = {

  SIT: 'TODOS',

  RAZAO: '',

  COD: '',

  CNPJ: '',

  PAGINA: 0,

  TAMANHO: 0,

  BCO: 'N',
};



export default FornecedoresModel;
