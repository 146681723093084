import * as Yup from 'yup';



const PStockFuncionarioValidationSchema = Yup.object().shape({

  input_matricula: Yup.string(),

  input_nome: Yup.string(),

  input_cpf: Yup.string(),

  input_cc: Yup.string(),

});



export default PStockFuncionarioValidationSchema;
