const PcbAnexosModel = {
  input_tipo: '',
  input_descricao: '',
  input_filial: '',
  input_planilha: '',
  input_contrato: '',
  input_revisao: '',
  input_pcbm: '',
  input_fileAnexo: null,
};



export default PcbAnexosModel;
