import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import EquipeCUDModal from '../../../_Modals/002EquipeCUDModal';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import ApiWS from '../../../../../../../services/api.service';
import { POCEquipeModel } from './POC-Tab_Equipe.model';
import { POCEquipeValidationSchema } from './POC-Tab_Equipe.validation';


const Tab_CadastroEquipeScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listCCDrop, setListCCDrop] = useState([]);


  const [listPocs, setListPocs] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Áreas!',
    description: 'Não há nenhuma área aqui',
  });



  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [stateCCDrop, setStateCCDrop] = useState();


  function postCCDrop() {
    setLoading(true);
    setShowList(false);

    const url = '/poc/equipe/consulta/cc';

    ApiWS()
      .get(url)
      .then((res) => {
        res.data.forEach((cc, index) => {
          setListCCDrop((listCC) => [
            ...listCC,
            {
              key: index,
              label: cc.OS,
              value: cc.OS,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error('postCCDrop:::', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postFetchTeam(values: any) {
    setLoading(true);
    setShowList(false);

    const url = `/poc/equipe/consulta/equipe/${values.CC}`;

    ApiWS()
      .get(url)
      .then((res) => {
        setListPocs(res.data);
      })
      .catch((err) => {
        console.error('postFetchTeam:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  useEffect(() => {
    postCCDrop();
  }, [listPocs]);


  const options = {
    custom: true,
    totalSize: listPocs.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'CC',
      text: 'Centro Custo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'LIDER',
      text: 'Líder',
      sort: true,
      headerStyle: () => {
        return { width: '30%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
      headerStyle: () => {
        return { width: '30%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: '',
      text: 'Editar',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.EDIT}
            icon={
              <IconEdit
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setValuesEdit(row);
              setTimeout(() => {
                setShowModalEdit(true);
              },300);
            }}
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Remover',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.REMOVE}
            icon={
              <IconTrash
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setValuesRemove(row);
              setTimeout(() => {
                setShowModalRemove(true);
              },300);
            }}
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listPocs}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.POC_Consulta,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            {loading && (<LoadingsComponent />)}

            <Formik
              enableReinitialize
              validateOnMount
              validationSchema={POCEquipeValidationSchema}
              initialValues={POCEquipeModel}
              onSubmit={(values) => {
                postFetchTeam(values);
              }}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (

                <FilterContainerForm auto onSubmit={handleSubmit}>
                  <Row>

                    <Col>
                      <DropdownInput
                        disabled={loading}
                        name={'CC'}
                        label={'Centro de Custo'}
                        placeholder={'Selecione ou deixe em branco para todos.'}
                        dropArray={listCCDrop}
                        onChange={(e) => {
                          handleChange(e);
                          setStateCCDrop(e.value);
                        }}
                        onBlur={handleBlur}
                        value={values.CC}
                      />
                    </Col>

                  </Row>



                  <Row>

                    <RowButtonFiltersView>

                      <IButton
                        disabled={listPocs.length === 0}
                        backgroundColor={Colors?.excel}
                        text={'Exportar'}
                        onClick={() => {
                          propsTkProvider.csvProps.onExport();
                        }}
                      />


                      <IButton
                        text={'Limpar'}
                        backgroundColor={Colors?.gray}
                        type={'reset'}
                      />


                      <IButton
                        backgroundColor={Colors?.grayDark}
                        text={'Novo'}
                        onClick={() => {
                          setShowModalNew(true);
                        }}
                      />


                      <IButton
                        disabled={loading}
                        text={'Filtrar'}
                        backgroundColor={Colors?.primary}
                        type={'submit'}
                      />

                    </RowButtonFiltersView>

                  </Row>

                </FilterContainerForm>

              )}
            </Formik>



            {!loading && showList && listPocs.length === 0 && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}



            {!loading && showList && listPocs.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={listPocs.length < 10}
                    data={listPocs}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}
      </ToolkitProvider>



      {showModalNew && (
        <EquipeCUDModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Nova Equipe'}
          type={'NEW'}
          fetchNow={() => postFetchTeam({ CC: stateCCDrop })}
          changeStateCCDrop={(value) => setStateCCDrop(value)}
        />
      )}


      {showModalEdit && (
        <EquipeCUDModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Equipe'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchTeam({ CC: stateCCDrop })}
          changeStateCCDrop={(value) => setStateCCDrop(value)}
        />
      )}


      {showModalRemove && (
        <EquipeCUDModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Equipe'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchTeam({ CC: stateCCDrop })}
          changeStateCCDrop={(value) => setStateCCDrop(value)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_CadastroEquipeScreen;
