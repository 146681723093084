import React from 'react';

// import { Container } from './styles';



const NovoFornecedorScreen: React.FC = () => {
  return (

    <div>
      NovoFornecedorScreen
    </div>

  );
};



export default NovoFornecedorScreen;
