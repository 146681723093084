import * as Yup from 'yup';



const PffConsultaSchema = Yup.object().shape({


});



export default PffConsultaSchema;
