import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  useHistory,
} from 'react-router';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import DropdownInput from '../../../../../components/TextFields/Dropdown';

import NameRoutes from '../../../../../navigation/name';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  dadosParaSM: any;
  onHide?: any;
}



const SMEModeloModal: React.FC<IProps> = (props) => {
  const history = useHistory();


  const [optionsModelo, setOptionsModelo] = useState([]);



  const handleModelo = (values: any) => {
    if (values.cod_select_modelo === '00' || values.cod_select_modelo === '') {
      ApiWS()
        .post('/sm/nova_sm/consulta/modelo', { consulta: values.cod_select_modelo })
        .then((resp) => {
          const modeloSMEObjeto = {
            tipo: 'MODELO',
            solicitante: props.dadosParaSM.usu_nm_usuario,
            os: props.dadosParaSM.cco_vl_centro_custo,
            setor_operacao: props.dadosParaSM.cco_ds_centro_custo,
            filial: props.dadosParaSM.filial,
            funcaoSolicitante: props.dadosParaSM.fun_ds_funcao,
            sme: resp.data,
          };
          history.push(`/${NameRoutes.SME_Nova}`, modeloSMEObjeto);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };



  function renderListGrpDestino(listGrpResp: any) {
    const listGrpTriagemMap = listGrpResp.map((triagem) => {
      const valueTriagem = triagem.CP_MODELO !== '' || triagem.CP_MODELO !== null || triagem.CP_MODELO !== undefined ? triagem.CP_MODELO : null;

      const triagemMapped = {
        'key': triagem.CP_MODELO,
        'label': triagem.CP_MODELO,
        'value': valueTriagem,
      };
      return triagemMapped;
    });

    setOptionsModelo([
      // emptyList,
      ...listGrpTriagemMap,
    ]);
  };



  const findNomeModelos = () => {
    ApiWS()
      .post('/sm/nova_sm/consulta/modelos')
      .then((resp) => {
        renderListGrpDestino(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    findNomeModelos();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {`Modelo SME - ${props.dadosParaSM.cco_vl_centro_custo}`.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          // validationSchema={SmeAtendimentoValidationSchema}
          initialValues={{
            cod_select_modelo: '',
          }}
          onSubmit={(values) => {
            handleModelo(values);
          }}>
          {({
            // values,
            // errors,
            // isValid,
            // isSubmitting,
            handleSubmit,
            // handleChange,
            // handleBlur,
            // setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>
                <DropdownInput
                  name={'cod_select_modelo'}
                  label={'Modelo'}
                  placeholder={'Selecione ...'}
                  dropArray={optionsModelo}
                // value={serviceGroup}
                // onSelect={serviceGroupDropdownFunction}
                />
              </ModalBodyStyled>



              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.accept}
                  text={'Gerar Modelo'}
                  type={'submit'}
                />


                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Fechar'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default SMEModeloModal;
