import React from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import {
  Formik,
  Form,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  ContentStyled,
  Content,
  Screen,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import {
  RP_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import {
  FilterButtonContainer,
  TransferButtonContainer,
} from './RP-triagem.styled';



const RP_TriagemScreen: React.FC = () => {
  return (

    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{}}
      onSubmit={(values) => {
        alert(JSON.stringify(values));
      }}>
      {({
        handleSubmit,
      }) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={5}
              navArray={RP_NavRoutes}
              title={'RP'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>
              <Container>

                <Form onSubmit={handleSubmit}>


                  <TitleGroup>
                    {'RP Triagem - Opções Gerais'.toUpperCase()}
                  </TitleGroup>

                  {/* Linha 1 */}
                  <Row>
                    <Col>
                      <DropdownInput
                        label={'Grupo de Atendimento'}
                        placeholder={'Selecione ...'}
                        dropArray={[
                          {
                            key: '1',
                            label: 'RP Escritório Central - BA',
                          },
                        ]}
                      // value={serviceGroup}
                      // onSelect={serviceGroupDropdownFunction}
                      />
                    </Col>


                    <Col />
                    <Col />

                  </Row>



                  <TitleGroup>
                    {'RP Triagem - Opções de Filtro'.toUpperCase()}
                  </TitleGroup>

                  {/* Linha 2 */}
                  <Row>
                    <Col>
                      <TextInput
                        type={'text'}
                        name={'serviceGroup'}
                        label={'OS'}
                        placeholder={'OS'}
                      // onChange={handleChange}
                      // value={values.user}
                      />
                    </Col>


                    <Col>
                      <TextInput
                        type={'text'}
                        name={'serviceGroup'}
                        label={'Função'}
                        placeholder={'Função'}
                      // onChange={handleChange}
                      // value={values.user}
                      />
                    </Col>


                    <Col>
                      <TextInput
                        type={'text'}
                        name={'serviceGroup'}
                        label={'Solicitante'}
                        placeholder={'Solicitante'}
                      // onChange={handleChange}
                      // value={values.user}
                      />
                    </Col>


                    <Col>
                      <TextInput
                        type={'text'}
                        name={'serviceGroup'}
                        label={'Número'}
                        placeholder={'Número'}
                      // onChange={handleChange}
                      // value={values.user}
                      />
                    </Col>


                    <Col sm={1}>
                      <FilterButtonContainer>

                        <IButton
                          backgroundColor={Colors?.primary}
                          text={'Filtrar'}
                        />

                      </FilterButtonContainer>
                    </Col>

                  </Row>



                  <TitleGroup>
                    {'RP Triagem - Opções de Transferência'.toUpperCase()}
                  </TitleGroup>

                  {/* Linha 3 */}
                  <Row>
                    <Col>
                      <DropdownInput
                        label={'Grupo de Destino'}
                        placeholder={'Selecione ...'}
                        dropArray={[
                          {
                            key: '1',
                            label: 'Grupo 1',
                          },
                        ]}
                      // value={destiny}
                      // onSelect={destinyDropdownFunction}
                      />
                    </Col>


                    <Col>
                      <TransferButtonContainer>

                        <IButton
                          backgroundColor={Colors?.primary}
                          text={'Transferir'}
                        />

                      </TransferButtonContainer>
                    </Col>


                    <Col />

                  </Row>

                </Form>

              </Container>
            </ContentStyled>
          </Content>

        </Screen>
      )}
    </Formik>

  );
};



export default RP_TriagemScreen;
