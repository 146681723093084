import React,
{
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import RegrasCUDGIModal from '../../../_Modals/003RegrasCUDGIModal';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GISistemaModel from './GI-Tab_Regra.model';
import GISistemaValidationSchema from './GI-Tab_Regra.validation';


const Tab_RegraScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listDepartment, setListDepartment] = useState([]);
  const [listSystem, setListSystem] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem pedidos!',
    description: 'Não há nenhum pedido aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  function postFetchSystem(values) {
    setLoading(true);

    const url = 'gi/sistema/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((dep, index) => {
            setListDepartment(
              (list) => [...list, {
                key: index,
                label: dep.DESCR,
                value: dep.ID,
              }],
            );
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchSystem', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postFetchRoule(values, page_iteration: any) {
    setLoading(true);
    setShowList(false);

    const url = 'gi/regra/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListSystem(res.data);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
        }


        if (res.status === 200 && res.data.length === 0) {
          setEmptyObject({
            image: Images.empty,
            title: 'Sem Regras!',
            description: 'Não há nenhuma regra aqui',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchSystem', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchRoule(valuesPayload, page_var);
    setPage(page_var);
  };


  const options = {
    custom: true,
    totalSize: listSystem.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      formatter: (_cell, row) => {
        if (row.TIPO === 'R') {
          return 'RCO / ROP'.toLocaleUpperCase();
        }
        else if (row.TIPO === 'P') {
          return 'Processo'.toLocaleUpperCase();
        }
      },
    },
    {
      dataField: '',
      text: 'Detal./Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.DETAILS}/${TextTooltip.EDIT}`}
          onClick={() => {
            setShowModalEdit(true);
            setValuesEdit({
              ID: row.ID,
              DESCR: row.DESCR,
              IDSIS: row.IDSIS,
              COD: row.COD,
              TIPO: row.TIPO,
            });
          }}
          icon={
            <IconEdit color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove({
                ID: row.ID,
                DESCR: row.DESCR,
                IDSIS: row.IDSIS,
                COD: row.COD,
                TIPO: row.TIPO,
              });
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  useEffect(() => {
    postFetchSystem({ DESCR: '', IDDEP: '' });
  }, []);



  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listSystem}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.PCB_Pedidos,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              validationSchema={GISistemaValidationSchema}
              initialValues={GISistemaModel}
              onSubmit={(values) => {
                postFetchRoule(values, 1);
              }}>
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
              }) => (

                <>

                  {loading && (
                    <LoadingsComponent />
                  )}



                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>

                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'DESCR'}
                          label={'Regra'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'IDSIS'}
                          label={'Sistema'}
                          placeholder={'Todos'}
                          dropArray={listDepartment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.IDSIS || 'TODOS'}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        type={'button'}
                        backgroundColor={Colors?.grayDark}
                        text={'Nova'}
                        onClick={() => {
                          setShowModalNew(true);
                        }}
                      />


                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>

                </>

              )}

            </Formik>



            {!loading && showList && listSystem.length === 0 && (

              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />

            )}


            {!loading && showList && listSystem.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={listSystem.length < 10}
                    data={listSystem}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}

      </ToolkitProvider>



      {showModalNew && (
        <RegrasCUDGIModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Novo Regra'}
          type={'NEW'}
          fetchNow={() => postFetchRoule({ DESCR: '', IDSIS: '' }, 1)}
        />
      )}



      {showModalEdit && (
        <RegrasCUDGIModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Regra'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchRoule({ DESCR: '', IDSIS: '' }, 1)}
        />
      )}



      {showModalRemove && (
        <RegrasCUDGIModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Regra'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchRoule({ DESCR: '', IDSIS: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_RegraScreen;
