import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Images } from '../../../../../common/constants';
import { Content } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import EmptyContent from '../../../../../components/Empty';
import { IconInConstruction, IconScreenFull } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import ApiWS from '../../../../../services/api.service';
import { intranet_config } from '../../../../../utils/Config';
import { AreaButtonStyleNormalizeScreen, CenteredStyled, ContainerIFrameStyle, IFrameStyleNormalizeScreen } from '../style';
import FullScreenModal from './Modals/FullScreenModal';

const Tab_Pessoal: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [permission, setPermission] = useState(false);


  const [showModal, setShowModal] = useState(false);

  const title = 'PG_PESSOAL';
  const src =  intranet_config.Registration.organization === 'MKS_01' &&  'https://app.powerbi.com/view?r=eyJrIjoiOTRiOTE3ODktNDgzMS00NzAwLWFkYTgtNDFmZTJjZmM0NTM3IiwidCI6ImFiYTExMDI4LTJlZTktNDZlYi05YWZlLTk1YmYwODZhMTAxZSJ9'
  || intranet_config.Registration.organization === 'CONORTE_01' && 'https://app.powerbi.com/view?r=eyJrIjoiMjIzMWNiMWEtM2NmYy00NGYwLWFiNDUtOGRlYWJmNzg5MTU5IiwidCI6IjU3NTg0MWJlLTMzYmMtNDQ4Yi05YTYxLWIwMjhhODdmNGNjMyJ9';


  function postAuth() {
    setLoading(true);
    setError(false);

    const url = '/pg/pessoal/permissao';
    const payload = [];


    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setPermission(true);
          setError(false);
        }
        else if (resp.status === 203) {
          setPermission(false);
          setError(false);
        }
      })
      .catch((err) => {
        console.error('postAuth', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        setError(true);
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    postAuth();
  }, []);


  return (

    <Content>

      <Container>

        {loading && (<LoadingsComponent />)}



        {!loading && !error && !permission && (
          <EmptyContent
            image={Images.error401}
            title={'Sem permissão!'}
            description={'Você não tem permissão para acessar essa tela!'}
          />
        )}



        {!loading && error && (
          <EmptyContent
            image={Images.other500}
            title={'Erro 500!'}
            description={'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte.'}
          />
        )}


        {/* MAPAO */}
        {!loading && !error && permission && (
          <>
            <AreaButtonStyleNormalizeScreen>
              <ButtonTableAction
                tooltip={'Tela Cheia'}
                onClick={() => {
                  setShowModal(true);
                  document.body.requestFullscreen();
                }}
                icon={
                  <IconScreenFull size={50} />
                }
              />
            </AreaButtonStyleNormalizeScreen>



            <ContainerIFrameStyle>

              <IFrameStyleNormalizeScreen
                title={title}
                src={src}
              />

            </ContainerIFrameStyle>

          </>
        )}
        

        {/* EM CONSTRUCAO */}
        {!loading && !error && permission && 
          (intranet_config.Registration.organization === '') && (
            
          <CenteredStyled>

            <IconInConstruction size={50} />
            <h1>&nbsp; {' Em construção.'} </h1>

          </CenteredStyled>
        )}


      </Container>

      {showModal && (
        <FullScreenModal
          show={showModal}
          title={title}
          src={src}
          onHide={() => setShowModal(false)}
        />
      )}

    </Content>


  );
};

export default Tab_Pessoal;
