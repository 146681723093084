import React from 'react';
import { Row } from 'react-bootstrap';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import { ColDescr_A, ColDetours_A, ColItems_A, ColLeader_A, ColObsPers_A } from './styled';

interface IProps {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  values: any;
  listLeaders?: any;
}

const PICSectionA: React.FC<IProps> = (props) => {
  return (
    <div>

      <TitleGroup>
        {'A - Pessoas'.toUpperCase()}
      </TitleGroup>

      <Row>
        <ColItems_A>Itens</ColItems_A>
        <ColLeader_A><span>Líder</span></ColLeader_A>
        <ColDescr_A><span>Descrição</span></ColDescr_A>
        <ColObsPers_A><span>Pess. Observ.</span></ColObsPers_A>
        <ColDetours_A><span>Desvios</span></ColDetours_A>
      </Row>

      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A1'}
            label={'A1'}
            onBlur={props.handleBlur}
            value={'Atingidor Por'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A1_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                props.setFieldValue('A1_NPESSOA', '');
                props.setFieldValue('A1_NDESVIO', '');
                props.setFieldValue('A1_IDEQUIPE', '');
                props.setFieldValue('A1_LIDER', '');
                props.setFieldValue('A1_DESCR', '');
              }
              else {
                props.setFieldValue('A1_IDEQUIPE', e.target.value.split(',')[0]);
                props.setFieldValue('A1_LIDER', e.target.value.split(',')[1]);
                props.setFieldValue('A1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={props.handleBlur}
            // value={props.values.A1_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A1_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A1_LIDER === '' 
              || props.values.A1_LIDER === undefined 
              || props.values.A1_LIDER === null
            }
            type={'number'}
            name={'A1_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A1_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A1_LIDER === '' 
              || props.values.A1_LIDER === undefined 
              || props.values.A1_LIDER === null
            }
            type={'number'}
            name={'A1_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A1_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A2'}
            label={'A2'}
            onBlur={props.handleBlur}
            value={'Batida Contra'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A2_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A2_NPESSOA', '');
                props.setFieldValue('A2_NDESVIO', '');
              }
              props.setFieldValue('A2_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A2_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A2_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A2_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A2_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A2_LIDER === '' 
              || props.values.A2_LIDER === undefined 
              || props.values.A2_LIDER === null
            }
            type={'number'}
            name={'A2_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A2_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A2_LIDER === '' 
              || props.values.A2_LIDER === undefined 
              || props.values.A2_LIDER === null
            }
            type={'number'}
            name={'A2_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A2_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A3'}
            label={'A3'}
            onBlur={props.handleBlur}
            value={'Prensamento de Membros'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A3_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A3_NPESSOA', '');
                props.setFieldValue('A3_NDESVIO', '');
              }
              props.setFieldValue('A3_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A3_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A3_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A3_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A3_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A3_LIDER === '' 
              || props.values.A3_LIDER === undefined 
              || props.values.A3_LIDER === null
            }
            type={'number'}
            name={'A3_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A3_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A3_LIDER === '' 
              || props.values.A3_LIDER === undefined 
              || props.values.A3_LIDER === null
            }
            type={'number'}
            name={'A3_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A3_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A4'}
            label={'A4'}
            onBlur={props.handleBlur}
            value={'Postura Inadequada'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A4_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A4_NPESSOA', '');
                props.setFieldValue('A4_NDESVIO', '');
              }
              props.setFieldValue('A4_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A4_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A4_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A4_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A4_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A4_LIDER === '' 
              || props.values.A4_LIDER === undefined 
              || props.values.A4_LIDER === null
            }
            type={'number'}
            name={'A4_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A4_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A4_LIDER === '' 
              || props.values.A4_LIDER === undefined 
              || props.values.A4_LIDER === null
            }
            type={'number'}
            name={'A4_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A4_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A5'}
            label={'A5'}
            onBlur={props.handleBlur}
            value={'Risco de Queda'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A5_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A5_NPESSOA', '');
                props.setFieldValue('A5_NDESVIO', '');
              }
              props.setFieldValue('A5_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A5_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A5_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A5_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A5_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A5_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A5_LIDER === '' 
              || props.values.A5_LIDER === undefined 
              || props.values.A5_LIDER === null
            }
            type={'number'}
            name={'A5_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A5_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A5_LIDER === '' 
              || props.values.A5_LIDER === undefined 
              || props.values.A5_LIDER === null
            }
            type={'number'}
            name={'A5_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A5_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A6'}
            label={'A6'}
            onBlur={props.handleBlur}
            value={'Risco de Choque Elétrico'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A6_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A6_NPESSOA', '');
                props.setFieldValue('A6_NDESVIO', '');
              }
              props.setFieldValue('A6_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A6_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A6_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A6_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A6_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A6_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A6_LIDER === '' 
              || props.values.A6_LIDER === undefined 
              || props.values.A6_LIDER === null
            }
            type={'number'}
            name={'A6_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A6_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A6_LIDER === '' 
              || props.values.A6_LIDER === undefined 
              || props.values.A6_LIDER === null
            }
            type={'number'}
            name={'A6_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A6_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A7'}
            label={'A7'}
            onBlur={props.handleBlur}
            value={'Contato com Contaminantes'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A7_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A7_NPESSOA', '');
                props.setFieldValue('A7_NDESVIO', '');
              }
              props.setFieldValue('A7_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A7_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A7_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A7_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A7_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A7_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A7_LIDER === '' 
              || props.values.A7_LIDER === undefined 
              || props.values.A7_LIDER === null
            }
            type={'number'}
            name={'A7_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A7_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A7_LIDER === '' 
              || props.values.A7_LIDER === undefined 
              || props.values.A7_LIDER === null
            }
            type={'number'}
            name={'A7_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A7_NDESVIO}
          />
        </ColDetours_A>

      </Row>


      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A8'}
            label={'A8'}
            onBlur={props.handleBlur}
            value={'Esforço Inadequado'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A8_LIDER'}
            dropArray={[...props.listLeaders]}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if(e.target.value === ''){
                props.setFieldValue('A8_NPESSOA', '');
                props.setFieldValue('A8_NDESVIO', '');
              }
              props.setFieldValue('A8_IDEQUIPE', e.target.value.split(',')[0]);
              props.setFieldValue('A8_LIDER', e.target.value.split(',')[1]);
              props.setFieldValue('A8_DESCR', e.target.value.split(',')[2]);
            }}
            onBlur={props.handleBlur}
            // value=.values.A8_LIDER}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A8_DESCR'}
            placeholder={'Selecione o Líder.'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A8_DESCR}
          />
        </ColDescr_A>


        <ColObsPers_A>
          <TextInput
            disabled={
              props.values.A8_LIDER === '' 
              || props.values.A8_LIDER === undefined 
              || props.values.A8_LIDER === null
            }
            type={'number'}
            name={'A8_NPESSOA'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A8_NPESSOA}
          />
        </ColObsPers_A>


        <ColDetours_A>
          <TextInput
            disabled={
              props.values.A8_LIDER === '' 
              || props.values.A8_LIDER === undefined 
              || props.values.A8_LIDER === null
            }
            type={'number'}
            name={'A8_NDESVIO'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.A8_NDESVIO}
          />
        </ColDetours_A>

      </Row>

    </div>

  );
};


export default PICSectionA;
