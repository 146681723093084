import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../utils/StringUtils';



const PcbPedidosValidationSchema = Yup.object().shape({

  input_situacao: Yup.string(),



  input_filial: Yup.string()
    // .length(Metrics.input_filial, Messages.input_filial)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

  input_centro_custo: Yup.string(),
  // .length(Metrics.input_centroCusto, Messages.input_centroCusto),



  input_data_inicio: Yup.string(),



  input_data_final: Yup.string(),



  input_numero: Yup.string()
    // .length(Metrics.input_pedido, Messages.input_pedido)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_fornecedor: Yup.string(),



  input_comprador: Yup.string(),



  input_cnpj: Yup.string()
    .length(Metrics.input_cnpj, Messages.input_cnpj)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_sme: Yup.string()
    // .length(Metrics.input_sme, Messages.input_sme)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



});



export default PcbPedidosValidationSchema;
