import React from 'react';



import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToFloat, maskToFloat2, maskToMoney, maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { returnCalcMarginsAndTaxOfLS_Budgeted, returnCalcMarginsAndTaxOfLS_Planned, returnCalcMarginsAndTaxOfLS_Provider } from '../../PFF-Formula';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, RowFieldsGrid, TitleInterGroup } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffReceitasControleModel } from '../PFF-grid.model';



interface IProps {
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_ReceitasControleScreen: React.FC<IProps> = (props) => {
  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[5].RCO;
    let total = 0.00;

    // ORCADO
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      model = {
        ...model,
        BMSI_POSITIVO: { ORCADO: maskToMoney(`${data[0].ORCADO}`) },
        BMSI_NEGATIVO: { ORCADO: `-${maskToMoney(`${data[1].ORCADO}`)}` },
        NOTAS_DE_DEBITOS: { ORCADO: maskToMoney(`${data[2].ORCADO}`) },
        OUTRAS_RECEITAS_CONTR: { ORCADO: maskToMoney(`${data[3].ORCADO}`) },
      };


      total = total + maskToFloat(model.BMSI_POSITIVO.ORCADO);
      total = total + maskToFloat(model.BMSI_NEGATIVO.ORCADO);
      total = total + maskToFloat(model.NOTAS_DE_DEBITOS.ORCADO);
      total = total + maskToFloat(model.OUTRAS_RECEITAS_CONTR.ORCADO);

      model = {
        ...model,
        TOTAL_RECEITAS_CONTR: { ORCADO: maskToMoney2(total) },
      };
    }


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      model = {
        ...model,
        BMSI_POSITIVO: { PLANEJADO: maskToMoney(`${data[0].PLANEJADO}`) },
        BMSI_NEGATIVO: { PLANEJADO: `-${maskToMoney(`${data[1].PLANEJADO}`)}` },
        NOTAS_DE_DEBITOS: { PLANEJADO: maskToMoney(`${data[2].PLANEJADO}`) },
        OUTRAS_RECEITAS_CONTR: { PLANEJADO: maskToMoney(`${data[3].PLANEJADO}`) },
      };


      total = total + maskToFloat(model.BMSI_POSITIVO.PLANEJADO);
      total = total + maskToFloat(model.BMSI_NEGATIVO.PLANEJADO);
      total = total + maskToFloat(model.NOTAS_DE_DEBITOS.PLANEJADO);
      total = total + maskToFloat(model.OUTRAS_RECEITAS_CONTR.PLANEJADO);

      model = {
        ...model,
        TOTAL_RECEITAS_CONTR: { PLANEJADO: maskToMoney2(total) },
      };
    }


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sum_JAN = 0.00;
      let sum_FEV = 0.00;
      let sum_MAR = 0.00;
      let sum_ABR = 0.00;
      let sum_MAI = 0.00;
      let sum_JUN = 0.00;
      let sum_JUL = 0.00;
      let sum_AGO = 0.00;
      let sum_SET = 0.00;
      let sum_OUT = 0.00;
      let sum_NOV = 0.00;
      let sum_DEZ = 0.00;


      data.forEach((account) => {
        if (account.JAN && account.JAN.indexOf(',') !== -1) {
          account.JAN = account.JAN.replaceAll('.', '');
          account.JAN = account.JAN.replaceAll(',', '.');
        }
        if (account.FEV && account.FEV.indexOf(',') !== -1) {
          account.FEV = account.FEV.replaceAll('.', '');
          account.FEV = account.FEV.replaceAll(',', '.');
        }
        if (account.MAR && account.MAR.indexOf(',') !== -1) {
          account.MAR = account.MAR.replaceAll('.', '');
          account.MAR = account.MAR.replaceAll(',', '.');
        }
        if (account.ABR && account.ABR.indexOf(',') !== -1) {
          account.ABR = account.ABR.replaceAll('.', '');
          account.ABR = account.ABR.replaceAll(',', '.');
        }
        if (account.MAI && account.MAI.indexOf(',') !== -1) {
          account.MAI = account.MAI.replaceAll('.', '');
          account.MAI = account.MAI.replaceAll(',', '.');
        }
        if (account.JUN && account.JUN.indexOf(',') !== -1) {
          account.JUN = account.JUN.replaceAll('.', '');
          account.JUN = account.JUN.replaceAll(',', '.');
        }
        if (account.JUL && account.JUL.indexOf(',') !== -1) {
          account.JUL = account.JUL.replaceAll('.', '');
          account.JUL = account.JUL.replaceAll(',', '.');
        }
        if (account.AGO && account.AGO.indexOf(',') !== -1) {
          account.AGO = account.AGO.replaceAll('.', '');
          account.AGO = account.AGO.replaceAll(',', '.');
        }
        if (account.SET && account.SET.indexOf(',') !== -1) {
          account.SET = account.SET.replaceAll('.', '');
          account.SET = account.SET.replaceAll(',', '.');
        }
        if (account.OUT && account.OUT.indexOf(',') !== -1) {
          account.OUT = account.OUT.replaceAll('.', '');
          account.OUT = account.OUT.replaceAll(',', '.');
        }
        if (account.NOV && account.NOV.indexOf(',') !== -1) {
          account.NOV = account.NOV.replaceAll('.', '');
          account.NOV = account.NOV.replaceAll(',', '.');
        }
        if (account.DEZ && account.DEZ.indexOf(',') !== -1) {
          account.DEZ = account.DEZ.replaceAll('.', '');
          account.DEZ = account.DEZ.replaceAll(',', '.');
        }

        // if(account.TIPO !== 'BMSI Negativo'){
        sum_JAN = sum_JAN + maskToFloat2(account.JAN);
        sum_FEV = sum_FEV + maskToFloat2(account.FEV);
        sum_MAR = sum_MAR + maskToFloat2(account.MAR);
        sum_ABR = sum_ABR + maskToFloat2(account.ABR);
        sum_MAI = sum_MAI + maskToFloat2(account.MAI);
        sum_JUN = sum_JUN + maskToFloat2(account.JUN);
        sum_JUL = sum_JUL + maskToFloat2(account.JUL);
        sum_AGO = sum_AGO + maskToFloat2(account.AGO);
        sum_SET = sum_SET + maskToFloat2(account.SET);
        sum_OUT = sum_OUT + maskToFloat2(account.OUT);
        sum_NOV = sum_NOV + maskToFloat2(account.NOV);
        sum_DEZ = sum_DEZ + maskToFloat2(account.DEZ);
        // }
        // else {
        //   sum_JAN = sum_JAN - parseFloat(account.JAN);
        //   sum_FEV = sum_FEV - parseFloat(account.FEV);
        //   sum_MAR = sum_MAR - parseFloat(account.MAR);
        //   sum_ABR = sum_ABR - parseFloat(account.ABR);
        //   sum_MAI = sum_MAI - parseFloat(account.MAI);
        //   sum_JUN = sum_JUN - parseFloat(account.JUN);
        //   sum_JUL = sum_JUL - parseFloat(account.JUL);
        //   sum_AGO = sum_AGO - parseFloat(account.AGO);
        //   sum_SET = sum_SET - parseFloat(account.SET);
        //   sum_OUT = sum_OUT - parseFloat(account.OUT);
        //   sum_NOV = sum_NOV - parseFloat(account.NOV);
        //   sum_DEZ = sum_DEZ - parseFloat(account.DEZ);
        // };
      });

      model = {
        ...model,
        BMSI_POSITIVO: {
          JAN: maskToMoney(`${data[0].JAN}`),
          FEV: maskToMoney(`${data[0].FEV}`),
          MAR: maskToMoney(`${data[0].MAR}`),
          ABR: maskToMoney(`${data[0].ABR}`),
          MAI: maskToMoney(`${data[0].MAI}`),
          JUN: maskToMoney(`${data[0].JUN}`),
          JUL: maskToMoney(`${data[0].JUL}`),
          AGO: maskToMoney(`${data[0].AGO}`),
          SET: maskToMoney(`${data[0].SET}`),
          OUT: maskToMoney(`${data[0].OUT}`),
          NOV: maskToMoney(`${data[0].NOV}`),
          DEZ: maskToMoney(`${data[0].DEZ}`),
        },

        BMSI_NEGATIVO: {
          JAN: `-${maskToMoney(`${data[1].JAN}`)}`,
          FEV: `-${maskToMoney(`${data[1].FEV}`)}`,
          MAR: `-${maskToMoney(`${data[1].MAR}`)}`,
          ABR: `-${maskToMoney(`${data[1].ABR}`)}`,
          MAI: `-${maskToMoney(`${data[1].MAI}`)}`,
          JUN: `-${maskToMoney(`${data[1].JUN}`)}`,
          JUL: `-${maskToMoney(`${data[1].JUL}`)}`,
          AGO: `-${maskToMoney(`${data[1].AGO}`)}`,
          SET: `-${maskToMoney(`${data[1].SET}`)}`,
          OUT: `-${maskToMoney(`${data[1].OUT}`)}`,
          NOV: `-${maskToMoney(`${data[1].NOV}`)}`,
          DEZ: `-${maskToMoney(`${data[1].DEZ}`)}`,
        },
        NOTAS_DE_DEBITOS: {
          JAN: maskToMoney(`${data[2].JAN}`),
          FEV: maskToMoney(`${data[2].FEV}`),
          MAR: maskToMoney(`${data[2].MAR}`),
          ABR: maskToMoney(`${data[2].ABR}`),
          MAI: maskToMoney(`${data[2].MAI}`),
          JUN: maskToMoney(`${data[2].JUN}`),
          JUL: maskToMoney(`${data[2].JUL}`),
          AGO: maskToMoney(`${data[2].AGO}`),
          SET: maskToMoney(`${data[2].SET}`),
          OUT: maskToMoney(`${data[2].OUT}`),
          NOV: maskToMoney(`${data[2].NOV}`),
          DEZ: maskToMoney(`${data[2].DEZ}`),
        },
        OUTRAS_RECEITAS_CONTR: {
          JAN: maskToMoney(`${data[3].JAN}`),
          FEV: maskToMoney(`${data[3].FEV}`),
          MAR: maskToMoney(`${data[3].MAR}`),
          ABR: maskToMoney(`${data[3].ABR}`),
          MAI: maskToMoney(`${data[3].MAI}`),
          JUN: maskToMoney(`${data[3].JUN}`),
          JUL: maskToMoney(`${data[3].JUL}`),
          AGO: maskToMoney(`${data[3].AGO}`),
          SET: maskToMoney(`${data[3].SET}`),
          OUT: maskToMoney(`${data[3].OUT}`),
          NOV: maskToMoney(`${data[3].NOV}`),
          DEZ: maskToMoney(`${data[3].DEZ}`),
        },
        TOTAL_RECEITAS_CONTR: {
          JAN: maskToMoney3(sum_JAN),
          FEV: maskToMoney3(sum_FEV),
          MAR: maskToMoney3(sum_MAR),
          ABR: maskToMoney3(sum_ABR),
          MAI: maskToMoney3(sum_MAI),
          JUN: maskToMoney3(sum_JUN),
          JUL: maskToMoney3(sum_JUL),
          AGO: maskToMoney3(sum_AGO),
          SET: maskToMoney3(sum_SET),
          OUT: maskToMoney3(sum_OUT),
          NOV: maskToMoney3(sum_NOV),
          DEZ: maskToMoney3(sum_DEZ),
        },
      };
    }


    // SALVA MARGEM NO LS PARA CALCULO
    const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));
    margemPorcLocalStorage[2][0] = total;
    localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));

    return model;
  };


  function postCNPJChange(values: any) {
    alert(JSON.stringify(values));
  };

  const releaseList = [
    'Total Receitas Controle',
    'BMSI',
    'BMSI Negativo',
    'Notas de Debitos',
    'Outras',
  ];


  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'BMSI_POSITIVO.ORCADO', label: releaseList[1], placeholder: 'BMSI Positivo Faturamento', value: values.BMSI_POSITIVO.ORCADO },
      { name: 'BMSI_NEGATIVO.ORCADO', label: releaseList[2], placeholder: 'BMSI Negativo', value: values.BMSI_NEGATIVO.ORCADO },
      { name: 'NOTAS_DE_DEBITOS.ORCADO', label: releaseList[3], placeholder: 'Notas de Debitos', value: values.NOTAS_DE_DEBITOS.ORCADO },
      { name: 'OUTRAS_RECEITAS_CONTR.ORCADO', label: releaseList[4], placeholder: 'Outras', value: values.OUTRAS_RECEITAS_CONTR.ORCADO },
    ];
  };

  function calcBudgeted(values: any, setFieldValue: any) {
    let marginReal = 0.00;
    let marginPorc = 0.00;

    marginReal = returnCalcMarginsAndTaxOfLS_Budgeted().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Budgeted().PORC;

    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.ORCADO', marginReal);
    setFieldValue('MARGEM_PORC.ORCADO', marginPorc);
  };

  function saveOnLocalStorageOR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[5].RCO.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        if(invoicing.TIPO !== 'BMSI Negativo') {
          invoicing.ORCADO = e;
        } 
        else {
          invoicing.ORCADO = `-${e}`;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'BMSI_POSITIVO.PLANEJADO', label: releaseList[1], placeholder: 'BMSI Positivo Faturamento', value: values.BMSI_POSITIVO.PLANEJADO },
      { name: 'BMSI_NEGATIVO.PLANEJADO', label: releaseList[2], placeholder: 'BMSI Negativo', value: values.BMSI_NEGATIVO.PLANEJADO },
      { name: 'NOTAS_DE_DEBITOS.PLANEJADO', label: releaseList[3], placeholder: 'Notas de Debitos', value: values.NOTAS_DE_DEBITOS.PLANEJADO },
      { name: 'OUTRAS_RECEITAS_CONTR.PLANEJADO', label: releaseList[4], placeholder: 'Outras', value: values.OUTRAS_RECEITAS_CONTR.PLANEJADO },
    ];
  };

  function calcPlanned(values: any, setFieldValue: any) {
    let marginReal = 0.00;
    let marginPorc = 0.00;


    marginReal = returnCalcMarginsAndTaxOfLS_Planned().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Planned().PORC;


    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.PLANEJADO', marginReal);
    setFieldValue('MARGEM_PORC.PLANEJADO', marginPorc);
  };

  function saveOnLocalStoragePL(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[5].RCO.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        if(invoicing.TIPO !== 'BMSI Negativo'){
          invoicing.PLANEJADO = e;
        }
        else {
          invoicing.PLANEJADO = `-${e}`;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PREVISTO
  const listFieldsProvider = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0].MESREF[0].MES);

    list.push(
      { name: 'TOTAL_RECEITAS_CONTR.JAN', label: 'JAN', value: values.TOTAL_RECEITAS_CONTR.JAN, typeRelease: releaseList[0], month: 0, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.FEV', label: 'FEV', value: values.TOTAL_RECEITAS_CONTR.FEV, typeRelease: releaseList[0], month: 1, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.MAR', label: 'MAR', value: values.TOTAL_RECEITAS_CONTR.MAR, typeRelease: releaseList[0], month: 2, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.ABR', label: 'ABR', value: values.TOTAL_RECEITAS_CONTR.ABR, typeRelease: releaseList[0], month: 3, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.MAI', label: 'MAI', value: values.TOTAL_RECEITAS_CONTR.MAI, typeRelease: releaseList[0], month: 4, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.JUN', label: 'JUN', value: values.TOTAL_RECEITAS_CONTR.JUN, typeRelease: releaseList[0], month: 5, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.JUL', label: 'JUL', value: values.TOTAL_RECEITAS_CONTR.JUL, typeRelease: releaseList[0], month: 6, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.AGO', label: 'AGO', value: values.TOTAL_RECEITAS_CONTR.AGO, typeRelease: releaseList[0], month: 7, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.SET', label: 'SET', value: values.TOTAL_RECEITAS_CONTR.SET, typeRelease: releaseList[0], month: 8, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.OUT', label: 'OUT', value: values.TOTAL_RECEITAS_CONTR.OUT, typeRelease: releaseList[0], month: 9, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.NOV', label: 'NOV', value: values.TOTAL_RECEITAS_CONTR.NOV, typeRelease: releaseList[0], month: 10, disabled: true },
      { name: 'TOTAL_RECEITAS_CONTR.DEZ', label: 'DEZ', value: values.TOTAL_RECEITAS_CONTR.DEZ, typeRelease: releaseList[0], month: 11, disabled: true },

      { name: 'BMSI_POSITIVO.JAN', label: 'JAN', value: values.BMSI_POSITIVO.JAN, typeRelease: releaseList[1], month: 0, disabled: currentMonth >= 1 },
      { name: 'BMSI_POSITIVO.FEV', label: 'FEV', value: values.BMSI_POSITIVO.FEV, typeRelease: releaseList[1], month: 1, disabled: currentMonth >= 2 },
      { name: 'BMSI_POSITIVO.MAR', label: 'MAR', value: values.BMSI_POSITIVO.MAR, typeRelease: releaseList[1], month: 2, disabled: currentMonth >= 3 },
      { name: 'BMSI_POSITIVO.ABR', label: 'ABR', value: values.BMSI_POSITIVO.ABR, typeRelease: releaseList[1], month: 3, disabled: currentMonth >= 4 },
      { name: 'BMSI_POSITIVO.MAI', label: 'MAI', value: values.BMSI_POSITIVO.MAI, typeRelease: releaseList[1], month: 4, disabled: currentMonth >= 5 },
      { name: 'BMSI_POSITIVO.JUN', label: 'JUN', value: values.BMSI_POSITIVO.JUN, typeRelease: releaseList[1], month: 5, disabled: currentMonth >= 6 },
      { name: 'BMSI_POSITIVO.JUL', label: 'JUL', value: values.BMSI_POSITIVO.JUL, typeRelease: releaseList[1], month: 6, disabled: currentMonth >= 7 },
      { name: 'BMSI_POSITIVO.AGO', label: 'AGO', value: values.BMSI_POSITIVO.AGO, typeRelease: releaseList[1], month: 7, disabled: currentMonth >= 8 },
      { name: 'BMSI_POSITIVO.SET', label: 'SET', value: values.BMSI_POSITIVO.SET, typeRelease: releaseList[1], month: 8, disabled: currentMonth >= 9 },
      { name: 'BMSI_POSITIVO.OUT', label: 'OUT', value: values.BMSI_POSITIVO.OUT, typeRelease: releaseList[1], month: 9, disabled: currentMonth >= 10 },
      { name: 'BMSI_POSITIVO.NOV', label: 'NOV', value: values.BMSI_POSITIVO.NOV, typeRelease: releaseList[1], month: 10, disabled: currentMonth >= 11 },
      { name: 'BMSI_POSITIVO.DEZ', label: 'DEZ', value: values.BMSI_POSITIVO.DEZ, typeRelease: releaseList[1], month: 11, disabled: currentMonth >= 12 },

      { name: 'BMSI_NEGATIVO.JAN', label: 'JAN', value: values.BMSI_NEGATIVO.JAN, typeRelease: releaseList[2], month: 0, disabled: currentMonth >= 1 },
      { name: 'BMSI_NEGATIVO.FEV', label: 'FEV', value: values.BMSI_NEGATIVO.FEV, typeRelease: releaseList[2], month: 1, disabled: currentMonth >= 2 },
      { name: 'BMSI_NEGATIVO.MAR', label: 'MAR', value: values.BMSI_NEGATIVO.MAR, typeRelease: releaseList[2], month: 2, disabled: currentMonth >= 3 },
      { name: 'BMSI_NEGATIVO.ABR', label: 'ABR', value: values.BMSI_NEGATIVO.ABR, typeRelease: releaseList[2], month: 3, disabled: currentMonth >= 4 },
      { name: 'BMSI_NEGATIVO.MAI', label: 'MAI', value: values.BMSI_NEGATIVO.MAI, typeRelease: releaseList[2], month: 4, disabled: currentMonth >= 5 },
      { name: 'BMSI_NEGATIVO.JUN', label: 'JUN', value: values.BMSI_NEGATIVO.JUN, typeRelease: releaseList[2], month: 5, disabled: currentMonth >= 6 },
      { name: 'BMSI_NEGATIVO.JUL', label: 'JUL', value: values.BMSI_NEGATIVO.JUL, typeRelease: releaseList[2], month: 6, disabled: currentMonth >= 7 },
      { name: 'BMSI_NEGATIVO.AGO', label: 'AGO', value: values.BMSI_NEGATIVO.AGO, typeRelease: releaseList[2], month: 7, disabled: currentMonth >= 8 },
      { name: 'BMSI_NEGATIVO.SET', label: 'SET', value: values.BMSI_NEGATIVO.SET, typeRelease: releaseList[2], month: 8, disabled: currentMonth >= 9 },
      { name: 'BMSI_NEGATIVO.OUT', label: 'OUT', value: values.BMSI_NEGATIVO.OUT, typeRelease: releaseList[2], month: 9, disabled: currentMonth >= 10 },
      { name: 'BMSI_NEGATIVO.NOV', label: 'NOV', value: values.BMSI_NEGATIVO.NOV, typeRelease: releaseList[2], month: 10, disabled: currentMonth >= 11 },
      { name: 'BMSI_NEGATIVO.DEZ', label: 'DEZ', value: values.BMSI_NEGATIVO.DEZ, typeRelease: releaseList[2], month: 11, disabled: currentMonth >= 12 },

      { name: 'NOTAS_DE_DEBITOS.JAN', label: 'JAN', value: values.NOTAS_DE_DEBITOS.JAN, typeRelease: releaseList[3], month: 0, disabled: currentMonth >= 1 },
      { name: 'NOTAS_DE_DEBITOS.FEV', label: 'FEV', value: values.NOTAS_DE_DEBITOS.FEV, typeRelease: releaseList[3], month: 1, disabled: currentMonth >= 2 },
      { name: 'NOTAS_DE_DEBITOS.MAR', label: 'MAR', value: values.NOTAS_DE_DEBITOS.MAR, typeRelease: releaseList[3], month: 2, disabled: currentMonth >= 3 },
      { name: 'NOTAS_DE_DEBITOS.ABR', label: 'ABR', value: values.NOTAS_DE_DEBITOS.ABR, typeRelease: releaseList[3], month: 3, disabled: currentMonth >= 4 },
      { name: 'NOTAS_DE_DEBITOS.MAI', label: 'MAI', value: values.NOTAS_DE_DEBITOS.MAI, typeRelease: releaseList[3], month: 4, disabled: currentMonth >= 5 },
      { name: 'NOTAS_DE_DEBITOS.JUN', label: 'JUN', value: values.NOTAS_DE_DEBITOS.JUN, typeRelease: releaseList[3], month: 5, disabled: currentMonth >= 6 },
      { name: 'NOTAS_DE_DEBITOS.JUL', label: 'JUL', value: values.NOTAS_DE_DEBITOS.JUL, typeRelease: releaseList[3], month: 6, disabled: currentMonth >= 7 },
      { name: 'NOTAS_DE_DEBITOS.AGO', label: 'AGO', value: values.NOTAS_DE_DEBITOS.AGO, typeRelease: releaseList[3], month: 7, disabled: currentMonth >= 8 },
      { name: 'NOTAS_DE_DEBITOS.SET', label: 'SET', value: values.NOTAS_DE_DEBITOS.SET, typeRelease: releaseList[3], month: 8, disabled: currentMonth >= 9 },
      { name: 'NOTAS_DE_DEBITOS.OUT', label: 'OUT', value: values.NOTAS_DE_DEBITOS.OUT, typeRelease: releaseList[3], month: 9, disabled: currentMonth >= 10 },
      { name: 'NOTAS_DE_DEBITOS.NOV', label: 'NOV', value: values.NOTAS_DE_DEBITOS.NOV, typeRelease: releaseList[3], month: 10, disabled: currentMonth >= 11 },
      { name: 'NOTAS_DE_DEBITOS.DEZ', label: 'DEZ', value: values.NOTAS_DE_DEBITOS.DEZ, typeRelease: releaseList[3], month: 11, disabled: currentMonth >= 12 },

      { name: 'OUTRAS_RECEITAS_CONTR.JAN', label: 'JAN', value: values.OUTRAS_RECEITAS_CONTR.JAN, typeRelease: releaseList[4], month: 0, disabled: currentMonth >= 1 },
      { name: 'OUTRAS_RECEITAS_CONTR.FEV', label: 'FEV', value: values.OUTRAS_RECEITAS_CONTR.FEV, typeRelease: releaseList[4], month: 1, disabled: currentMonth >= 2 },
      { name: 'OUTRAS_RECEITAS_CONTR.MAR', label: 'MAR', value: values.OUTRAS_RECEITAS_CONTR.MAR, typeRelease: releaseList[4], month: 2, disabled: currentMonth >= 3 },
      { name: 'OUTRAS_RECEITAS_CONTR.ABR', label: 'ABR', value: values.OUTRAS_RECEITAS_CONTR.ABR, typeRelease: releaseList[4], month: 3, disabled: currentMonth >= 4 },
      { name: 'OUTRAS_RECEITAS_CONTR.MAI', label: 'MAI', value: values.OUTRAS_RECEITAS_CONTR.MAI, typeRelease: releaseList[4], month: 4, disabled: currentMonth >= 5 },
      { name: 'OUTRAS_RECEITAS_CONTR.JUN', label: 'JUN', value: values.OUTRAS_RECEITAS_CONTR.JUN, typeRelease: releaseList[4], month: 5, disabled: currentMonth >= 6 },
      { name: 'OUTRAS_RECEITAS_CONTR.JUL', label: 'JUL', value: values.OUTRAS_RECEITAS_CONTR.JUL, typeRelease: releaseList[4], month: 6, disabled: currentMonth >= 7 },
      { name: 'OUTRAS_RECEITAS_CONTR.AGO', label: 'AGO', value: values.OUTRAS_RECEITAS_CONTR.AGO, typeRelease: releaseList[4], month: 7, disabled: currentMonth >= 8 },
      { name: 'OUTRAS_RECEITAS_CONTR.SET', label: 'SET', value: values.OUTRAS_RECEITAS_CONTR.SET, typeRelease: releaseList[4], month: 8, disabled: currentMonth >= 9 },
      { name: 'OUTRAS_RECEITAS_CONTR.OUT', label: 'OUT', value: values.OUTRAS_RECEITAS_CONTR.OUT, typeRelease: releaseList[4], month: 9, disabled: currentMonth >= 10 },
      { name: 'OUTRAS_RECEITAS_CONTR.NOV', label: 'NOV', value: values.OUTRAS_RECEITAS_CONTR.NOV, typeRelease: releaseList[4], month: 10, disabled: currentMonth >= 11 },
      { name: 'OUTRAS_RECEITAS_CONTR.DEZ', label: 'DEZ', value: values.OUTRAS_RECEITAS_CONTR.DEZ, typeRelease: releaseList[4], month: 11, disabled: currentMonth >= 12 },

    );

    return list;
  };

  const calcProvider = (values: any, month: any, setFieldValue: any) => {
    let marginReal = 0;
    let marginPorc = 0;

    switch (month) {
      case 0:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JAN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JAN;
        break;
      case 1:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.FEV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.FEV;
        break;
      case 2:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAR;
        break;
      case 3:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.ABR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.ABR;
        break;
      case 4:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAI;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAI;
        break;
      case 5:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUN;
        break;
      case 6:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUL;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUL;
        break;
      case 7:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.AGO;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.AGO;
        break;
      case 8:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.SET;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.SET;
        break;
      case 9:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.OUT;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.OUT;
        break;
      case 10:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.NOV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.NOV;
        break;
      case 11:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.DEZ;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.DEZ;
        break;
    }

    props.marginReal({ month: month, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: month, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue(`MARGEM_REAL.PREVISTO[${month}]`, marginReal);
    setFieldValue(`MARGEM_PORC.PREVISTO[${month}]`, marginPorc);
  };

  function saveOnLocalStoragePR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[5].RCO.forEach((invoicing) => {
      if (invoicing.TIPO === field.typeRelease) {
        switch (field.label) {
          case 'JAN':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.JAN = e;
            }
            else {
              invoicing.JAN = `-${e}`;
            }
            break;
          case 'FEV':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.FEV = e;
            }
            else {
              invoicing.FEV = `-${e}`;
            }
            break;
          case 'MAR':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.MAR = e;
            }
            else {
              invoicing.MAR = `-${e}`;
            }
            break;
          case 'ABR':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.ABR = e;
            }
            else {
              invoicing.ABR = `-${e}`;
            }
            break;
          case 'MAI':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.MAI = e;
            }
            else {
              invoicing.MAI = `-${e}`;
            }
            break;
          case 'JUN':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.JUN = e;
            }
            else {
              invoicing.JUN = `-${e}`;
            }
            break;
          case 'JUL':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.JUL = e;
            }
            else {
              invoicing.JUL = `-${e}`;
            }
            break;
          case 'AGO':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.AGO = e;
            }
            else {
              invoicing.AGO = `-${e}`;
            }
            break;
          case 'SET':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.SET = e;
            }
            else {
              invoicing.SET = `-${e}`;
            }
            break;
          case 'OUT':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.OUT = e;
            }
            else {
              invoicing.OUT = `-${e}`;
            }
            break;
          case 'NOV':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.NOV = e;
            }
            else {
              invoicing.NOV = `-${e}`;
            }
            break;
          case 'DEZ':
            if(invoicing.TIPO !== 'BMSI Negativo') {
              invoicing.DEZ = e;
            }
            else {
              invoicing.DEZ = `-${e}`;
            }
            break;

          default:
            break;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffReceitasControleModel)}
      onSubmit={(values) => {
        postCNPJChange(values);
      }}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>
          <SectionMargin>
            <PffFilterContainerForm auto onSubmit={handleSubmit}>

              {/* LANÇAMENTO ORIGINAL */}
              {(props.releaseType?.COD_LANCAMENTO === 'OR') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsBudgeted(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label.substring(0, 22) + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value !== '') {
                          calcBudgeted(values, setFieldValue);
                          saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                        }
                        else {
                          setFieldValue(field.name, '00');
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          calcBudgeted(values, setFieldValue);
                      }}
                    />
                  ))}

                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_RECEITAS_CONTR.ORCADO'}
                    label={'Total Receitas Contr. R$'}
                    placeholder={'Total Receitas Contr. R$'}
                    value={maskToMoney2(values.TOTAL_RECEITAS_CONTR.ORCADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}


              {/* LANÇAMENTO PLANEJADO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PL') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsPlanned(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value.replaceAll(' ', '') || e.target.value.replaceAll(' ', '') !== '') {
                          calcPlanned(values, setFieldValue);
                          saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          calcPlanned(values, setFieldValue);
                        }
                      }}
                    />
                  ))}

                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_RECEITAS_CONTR.PLANEJADO'}
                    label={'Total Receitas Contr. R$'}
                    placeholder={'Total Receitas Contr. R$'}
                    value={maskToMoney2(values.TOTAL_RECEITAS_CONTR.PLANEJADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}


              {/* LANÇAMENTO PREVISTO */}
              {props.releaseType?.COD_LANCAMENTO === 'PR' && (

                releaseList.map((typeRelease) => (
                  <>

                    <TitleInterGroup titleAlign={'rigth'}>
                      <h1>{typeRelease}</h1>
                    </TitleInterGroup>


                    <ColFieldsGrid xs={12}>

                      {listFieldsProvider(values).map((field) => (

                        typeRelease === field.typeRelease && (

                          <TextInputLabelFloat
                            fontSize={Metrics.fontSizePFFSm}
                            fontSizeLabel={Metrics.fontSizePFFSm}
                            disabled={field.disabled}
                            name={field.name}
                            id={field.name}
                            label={field.label + ' R$'}
                            value={field.value}
                            type={'text'}
                            onChange={(e: any) => {
                              if (e.target.value !== '') {
                                handleChange(e);
                                setFieldValue(field.name, maskToMoney(e.target.value));
                                saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                              }
                            }}

                            onBlur={(e) => {
                              handleBlur(e);
                              if (e.target.value !== '') {
                                saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                calcProvider(values, field.month, setFieldValue);
                              }
                              else {
                                setFieldValue(field.name, '00');
                              }
                            }}

                            onFocus={(e) => {
                              e.target.select();
                            }}

                            onKeyUp={(e) => {
                              if (e.key === 'Enter')
                                calcProvider(values, field.month, setFieldValue);
                            }}
                          />
                        )

                      ))}

                    </ColFieldsGrid>

                  </>
                ))

              )}

            </PffFilterContainerForm>

          </SectionMargin>

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_ReceitasControleScreen;

