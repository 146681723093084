import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Colors } from '../../../../../common/constants';


export const AreaFieldsSeparate = styled.div`
  display: flex;
  flex-direction: column;
  min-height: auto;
  margin-top: 10px;
  padding: 10px;
  background-color: ${Colors?.formBackground};
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;


export const ColItems_A = styled(Col)`
    width: 30%;
    flex: none;
`;

export const ColLeader_A = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColDescr_A = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColMeets_A = styled(Col)`
    width: 10%;
    flex: none;
`;

export const ColObsColab_A = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColImproviment_A = styled(Col)`
    width: 10.5%;
    flex: none;
`;



export const ColItems_B = styled(Col)`
    width: 30%;
    flex: none;
`;

export const ColLeader_B = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColDescr_B = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColMeets_B = styled(Col)`
    width: 10%;
    flex: none;
`;

export const ColObsColab_B = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColImproviment_B = styled(Col)`
    width: 10.5%;
    flex: none;
`;



export const ColItems_C = styled(Col)`
    width: 30%;
    flex: none;
`;

export const ColLeader_C = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColDescr_C = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColMeets_C = styled(Col)`
    width: 10%;
    flex: none;
`;


export const ColObsColab_C = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColImproviment_C = styled(Col)`
    width: 10.5%;
    flex: none;
`;



export const ColItems_D = styled(Col)`
    width: 30%;
    flex: none;
`;

export const ColLeader_D = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColDescr_D = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColMeets_D = styled(Col)`
    width: 10%;
    flex: none;
`;

export const ColObsColab_D = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColImproviment_D = styled(Col)`
    width: 10.5%;
    flex: none;
`;



export const ColItems_E = styled(Col)`
    width: 30%;
    flex: none;
`;

export const ColLeader_E = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColDescr_E = styled(Col)`
    width: 19%;
    flex: none;
`;

export const ColMeets_E = styled(Col)`
    width: 10%;
    flex: none;
`;

export const ColObsColab_E = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColImproviment_E = styled(Col)`
    width: 10.5%;
    flex: none;
`;

export const ColItemsCase = styled(Col)`
    width: 70%;
    flex: none;
`;

export const ColMeetsCase = styled(Col)`
    width: 10%;
    flex: none;
`;
