import * as Yup from 'yup';



const PffLancamentoInicioSchema = Yup.object().shape({

  COD_LANCAMENTO: Yup.string(),

  ANO: Yup.string()
    .required('Obrigatório'),

  NEGOCIO: Yup.string()
    .required('Obrigatório'),

  CC: Yup.string()
    .required('Obrigatório'),

});



export default PffLancamentoInicioSchema;
