const User = {
  id: null,
  login: '',
  user: '',
  email: '',
  name: '',
  iat: '',
  exp: '',
  domain: '',
  registration: '',
  occupation: '',
  photo: '',
};



export default User;
