import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonTableAction from '../../../../../../components/Buttons/ButtonTableAction';
import { IconScreenNormalize } from '../../../../../../components/Icons';
import { AreaButtonStyleFullScreen, ContainerIFrameStyle, IFrameStyleFullScreen } from '../../style';

interface IProps {
  show?: any;
  onHide?: any;
  title?: any;
  src?: any;
}


const FullScreenModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      fullscreen
      centered>
      <>
        <ContainerIFrameStyle>

          <AreaButtonStyleFullScreen>
            <ButtonTableAction
              tooltip={'Tela Normal'}
              onClick={() => {
                props.onHide();
                document.exitFullscreen();
              }}
              icon={<IconScreenNormalize size={50} />}
            />
          </AreaButtonStyleFullScreen>



          <IFrameStyleFullScreen
            title={props.title}
            src={props.src}
          />

        </ContainerIFrameStyle>

      </>

    </Modal>

  );
};



export default FullScreenModal;
