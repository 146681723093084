import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalTableContainer,
  ModalFooterStyled,
  ModalTableTitleText,
} from '../../../../../components/Modals/styled';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  todo: any;
  onHide?: any;
}



const SmeHistoricoModal: React.FC<IProps> = (props) => {
  const [historico, setHistorico] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    findHistoricoItem(props.todo);
  }, []);

  const findHistoricoItem = (item) => {
    setCarregando(true);
    const itemConsulta = {
      'FILIAL': item.CP_FILIAL,
      'ITEMSM': item.CP_ITEM,
      'NUMSM': item.CP_NUM,
    };

    ApiWS()
      .post('/sm/consulta/historico', itemConsulta)
      .then((resp) => {
        setCarregando(false);
        setHistorico(resp.data);
      })
      .catch((err) => {
        setCarregando(false);
        console.log(err);
      });
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Historico de Item da SME'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          <ModalTableTitleText>
            Filial: {props.todo.CP_FILIAL} | Num: {props.todo.CP_NUM}
          </ModalTableTitleText>



          <ModalTableContainer>

            {carregando && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}


            {(!carregando && (!historico || historico === null || historico.length === 0)) && (
              <NoItemsContent />
            )}


            {!carregando && historico && historico.length > 0 && (
              <TableDefaultStyled>
                <thead className="thead-two">
                  <tr>
                    <th>Data - Hora</th>
                    <th>Colaborador</th>
                    <th>Realizou</th>
                    <th>Obs.</th>
                    <th>Obs. Triagem</th>
                  </tr>
                </thead>



                <tbody className="tbody-two">
                  {historico.map((acao, key) => (
                    <tr key={key}>
                      <td>{acao.ZJ_DATA}</td>
                      <td>{acao.ZJ_USUARIO.replace('MKS\\', '')}</td>
                      <td>{acao.ZJ_TIPO}</td>
                      <td>{acao.ZJ_DESCALT}</td>
                      <td>{acao.ZJ_OBS}</td>
                    </tr>
                  ))}
                </tbody>
              </TableDefaultStyled>
            )}
          </ModalTableContainer>

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default SmeHistoricoModal;
