import * as Yup from 'yup';



const PStockFornecedorValidationSchema = Yup.object().shape({

  input_cod: Yup.string(),

  input_razao: Yup.string(),

  input_nreduz: Yup.string(),

  input_cnpj: Yup.string(),

  input_est: Yup.string(),

});



export default PStockFornecedorValidationSchema;
