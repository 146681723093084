import React from 'react';
import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, RowFieldsGrid, TitleInterGroup } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffImpostosDiretosModel } from '../PFF-grid.model';



interface IProps {
  indexTab?: number;
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_ImpostosDiretoScreen: React.FC<IProps> = (props) => {
  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));


    const ISS_var = parseFloat(data[1]?.CAB[0]?.IMP01?.replaceAll(',', '.'));
    const PISCOFINS_var = parseFloat(data[1]?.CAB[0]?.IMP02?.replaceAll(',', '.'));
    const ICMSIPI_var = parseFloat(data[1]?.CAB[0]?.IMP03?.replaceAll(',', '.'));
    const DESONERACAO_var = parseFloat(data[1]?.CAB[0]?.IMP04?.replaceAll(',', '.'));

    // ORCADO
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      let sumRREBudgeted = 0.00;

      data[4].RRE.forEach((RRE: any) => {
        if (typeof RRE.ORCADO === 'string') {
          if (RRE.ORCADO.indexOf(',') !== -1) {
            RRE.ORCADO = RRE.ORCADO.replaceAll('.', '');
            RRE.ORCADO = RRE.ORCADO.replaceAll(',', '.');
          }
        }

        sumRREBudgeted = sumRREBudgeted + parseFloat(RRE.ORCADO);
      });

      model = {
        ...model,
        ISS: { ORCADO: (((sumRREBudgeted - data[4].RRE[5].ORCADO /* <- -Fabricações */) * ISS_var) / 100) },
        PIS_COFINS: { ORCADO: ((sumRREBudgeted * PISCOFINS_var) / 100) },
        ICMS_IPI: { ORCADO: (((sumRREBudgeted - data[4].RRE[3].ORCADO) * ICMSIPI_var /* <- -Serv/Avan */) / 100) },
        DESONERACAO: { ORCADO: ((sumRREBudgeted * DESONERACAO_var) / 100) },
      };

      data[6].IMP[0].ORCADO = `${model.ISS.ORCADO}`;
      data[6].IMP[1].ORCADO = `${model.PIS_COFINS.ORCADO}`;
      data[6].IMP[2].ORCADO = `${model.ICMS_IPI.ORCADO}`;
      data[6].IMP[3].ORCADO = `${model.DESONERACAO.ORCADO}`;


      const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));
      margemPorcLocalStorage[3][0] = (model.ISS.ORCADO + model.PIS_COFINS.ORCADO + model.ICMS_IPI.ORCADO + model.DESONERACAO.ORCADO);
      localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));


      model = {
        ...model,
        TOTAL_IMPOSTOS_DIRETOS: {
          ORCADO: maskToMoney2(model.ISS.ORCADO + model.PIS_COFINS.ORCADO + model.ICMS_IPI.ORCADO + model.DESONERACAO.ORCADO),
        },
      };

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(data));
    }


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      let sumRREBudgeted = 0.00;

      data[4].RRE.forEach((RRE: any) => {
        if (RRE.PLANEJADO.indexOf(',') !== -1) {
          RRE.PLANEJADO = RRE.PLANEJADO.replaceAll('.', '');
          RRE.PLANEJADO = RRE.PLANEJADO.replaceAll(',', '.');
        }

        sumRREBudgeted = sumRREBudgeted + parseFloat(RRE.PLANEJADO);
      });

      model = {
        ...model,
        ISS: { PLANEJADO: (((sumRREBudgeted - data[4].RRE[5].PLANEJADO /* <- -Fabricações */) * ISS_var) / 100) },
        PIS_COFINS: { PLANEJADO: ((sumRREBudgeted * PISCOFINS_var) / 100) },
        ICMS_IPI: { PLANEJADO: (((sumRREBudgeted - data[4].RRE[3].PLANEJADO /* <- -Serv/Avan */) * ICMSIPI_var) / 100) },
        DESONERACAO: { PLANEJADO: ((sumRREBudgeted * DESONERACAO_var) / 100) },
      };

      data[6].IMP[0].PLANEJADO = `${model.ISS.PLANEJADO}`;
      data[6].IMP[1].PLANEJADO = `${model.PIS_COFINS.PLANEJADO}`;
      data[6].IMP[2].PLANEJADO = `${model.ICMS_IPI.PLANEJADO}`;
      data[6].IMP[3].PLANEJADO = `${model.DESONERACAO.PLANEJADO}`;


      const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));
      margemPorcLocalStorage[3][0] = (model.ISS.PLANEJADO + model.PIS_COFINS.PLANEJADO + model.ICMS_IPI.PLANEJADO + model.DESONERACAO.PLANEJADO);
      localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));


      model = {
        ...model,
        TOTAL_IMPOSTOS_DIRETOS: {
          PLANEJADO: maskToMoney2(model.ISS.PLANEJADO + model.PIS_COFINS.PLANEJADO + model.ICMS_IPI.PLANEJADO + model.DESONERACAO.PLANEJADO),
        },
      };

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(data));
    }


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sumRREProvider_JAN = 0.00;
      let sumRREProvider_FEV = 0.00;
      let sumRREProvider_MAR = 0.00;
      let sumRREProvider_ABR = 0.00;
      let sumRREProvider_MAI = 0.00;
      let sumRREProvider_JUN = 0.00;
      let sumRREProvider_JUL = 0.00;
      let sumRREProvider_AGO = 0.00;
      let sumRREProvider_SET = 0.00;
      let sumRREProvider_OUT = 0.00;
      let sumRREProvider_NOV = 0.00;
      let sumRREProvider_DEZ = 0.00;

      let sumTax_JAN = 0.00;
      let sumTax_FEV = 0.00;
      let sumTax_MAR = 0.00;
      let sumTax_ABR = 0.00;
      let sumTax_MAI = 0.00;
      let sumTax_JUN = 0.00;
      let sumTax_JUL = 0.00;
      let sumTax_AGO = 0.00;
      let sumTax_SET = 0.00;
      let sumTax_OUT = 0.00;
      let sumTax_NOV = 0.00;
      let sumTax_DEZ = 0.00;

      data[4].RRE.forEach((RRE: any) => {
        if (RRE.JAN.indexOf(',') !== -1) {
          RRE.JAN = RRE.JAN.replaceAll('.', '');
          RRE.JAN = RRE.JAN.replaceAll(',', '.');
        }
        if (RRE.FEV.indexOf(',') !== -1) {
          RRE.FEV = RRE.FEV.replaceAll('.', '');
          RRE.FEV = RRE.FEV.replaceAll(',', '.');
        }
        if (RRE.MAR.indexOf(',') !== -1) {
          RRE.MAR = RRE.MAR.replaceAll('.', '');
          RRE.MAR = RRE.MAR.replaceAll(',', '.');
        }
        if (RRE.ABR.indexOf(',') !== -1) {
          RRE.ABR = RRE.ABR.replaceAll('.', '');
          RRE.ABR = RRE.ABR.replaceAll(',', '.');
        }
        if (RRE.MAI.indexOf(',') !== -1) {
          RRE.MAI = RRE.MAI.replaceAll('.', '');
          RRE.MAI = RRE.MAI.replaceAll(',', '.');
        }
        if (RRE.JUN.indexOf(',') !== -1) {
          RRE.JUN = RRE.JUN.replaceAll('.', '');
          RRE.JUN = RRE.JUN.replaceAll(',', '.');
        }
        if (RRE.JUL.indexOf(',') !== -1) {
          RRE.JUL = RRE.JUL.replaceAll('.', '');
          RRE.JUL = RRE.JUL.replaceAll(',', '.');
        }
        if (RRE.AGO.indexOf(',') !== -1) {
          RRE.AGO = RRE.AGO.replaceAll('.', '');
          RRE.AGO = RRE.AGO.replaceAll(',', '.');
        }
        if (RRE.SET.indexOf(',') !== -1) {
          RRE.SET = RRE.SET.replaceAll('.', '');
          RRE.SET = RRE.SET.replaceAll(',', '.');
        }
        if (RRE.OUT.indexOf(',') !== -1) {
          RRE.OUT = RRE.OUT.replaceAll('.', '');
          RRE.OUT = RRE.OUT.replaceAll(',', '.');
        }
        if (RRE.NOV.indexOf(',') !== -1) {
          RRE.NOV = RRE.NOV.replaceAll('.', '');
          RRE.NOV = RRE.NOV.replaceAll(',', '.');
        }
        if (RRE.DEZ.indexOf(',') !== -1) {
          RRE.DEZ = RRE.DEZ.replaceAll('.', '');
          RRE.DEZ = RRE.DEZ.replaceAll(',', '.');
        }

        sumRREProvider_JAN = sumRREProvider_JAN + parseFloat(RRE.JAN);
        sumRREProvider_FEV = sumRREProvider_FEV + parseFloat(RRE.FEV);
        sumRREProvider_MAR = sumRREProvider_MAR + parseFloat(RRE.MAR);
        sumRREProvider_ABR = sumRREProvider_ABR + parseFloat(RRE.ABR);
        sumRREProvider_MAI = sumRREProvider_MAI + parseFloat(RRE.MAI);
        sumRREProvider_JUN = sumRREProvider_JUN + parseFloat(RRE.JUN);
        sumRREProvider_JUL = sumRREProvider_JUL + parseFloat(RRE.JUL);
        sumRREProvider_AGO = sumRREProvider_AGO + parseFloat(RRE.AGO);
        sumRREProvider_SET = sumRREProvider_SET + parseFloat(RRE.SET);
        sumRREProvider_OUT = sumRREProvider_OUT + parseFloat(RRE.OUT);
        sumRREProvider_NOV = sumRREProvider_NOV + parseFloat(RRE.NOV);
        sumRREProvider_DEZ = sumRREProvider_DEZ + parseFloat(RRE.DEZ);
      });

      model = {
        ...model,
        ISS: {
          JAN: (((sumRREProvider_JAN - data[4].RRE[5].JAN /* <- -Fabricações */) * ISS_var) / 100),
          FEV: (((sumRREProvider_FEV - data[4].RRE[5].FEV /* <- -Fabricações */) * ISS_var) / 100),
          MAR: (((sumRREProvider_MAR - data[4].RRE[5].MAR /* <- -Fabricações */) * ISS_var) / 100),
          ABR: (((sumRREProvider_ABR - data[4].RRE[5].ABR /* <- -Fabricações */) * ISS_var) / 100),
          MAI: (((sumRREProvider_MAI - data[4].RRE[5].MAI /* <- -Fabricações */) * ISS_var) / 100),
          JUN: (((sumRREProvider_JUN - data[4].RRE[5].JUN /* <- -Fabricações */) * ISS_var) / 100),
          JUL: (((sumRREProvider_JUL - data[4].RRE[5].JUL /* <- -Fabricações */) * ISS_var) / 100),
          AGO: (((sumRREProvider_AGO - data[4].RRE[5].AGO /* <- -Fabricações */) * ISS_var) / 100),
          SET: (((sumRREProvider_SET - data[4].RRE[5].SET /* <- -Fabricações */) * ISS_var) / 100),
          OUT: (((sumRREProvider_OUT - data[4].RRE[5].OUT /* <- -Fabricações */) * ISS_var) / 100),
          NOV: (((sumRREProvider_NOV - data[4].RRE[5].NOV /* <- -Fabricações */) * ISS_var) / 100),
          DEZ: (((sumRREProvider_DEZ - data[4].RRE[5].DEZ /* <- -Fabricações */) * ISS_var) / 100),
        },
        PIS_COFINS: {
          JAN: ((sumRREProvider_JAN * PISCOFINS_var) / 100),
          FEV: ((sumRREProvider_FEV * PISCOFINS_var) / 100),
          MAR: ((sumRREProvider_MAR * PISCOFINS_var) / 100),
          ABR: ((sumRREProvider_ABR * PISCOFINS_var) / 100),
          MAI: ((sumRREProvider_MAI * PISCOFINS_var) / 100),
          JUN: ((sumRREProvider_JUN * PISCOFINS_var) / 100),
          JUL: ((sumRREProvider_JUL * PISCOFINS_var) / 100),
          AGO: ((sumRREProvider_AGO * PISCOFINS_var) / 100),
          SET: ((sumRREProvider_SET * PISCOFINS_var) / 100),
          OUT: ((sumRREProvider_OUT * PISCOFINS_var) / 100),
          NOV: ((sumRREProvider_NOV * PISCOFINS_var) / 100),
          DEZ: ((sumRREProvider_DEZ * PISCOFINS_var) / 100),
        },
        ICMS_IPI: {
          JAN: (((sumRREProvider_JAN - data[4].RRE[3].JAN /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          FEV: (((sumRREProvider_FEV - data[4].RRE[3].FEV /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          MAR: (((sumRREProvider_MAR - data[4].RRE[3].MAR /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          ABR: (((sumRREProvider_ABR - data[4].RRE[3].ABR /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          MAI: (((sumRREProvider_MAI - data[4].RRE[3].MAI /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          JUN: (((sumRREProvider_JUN - data[4].RRE[3].JUN /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          JUL: (((sumRREProvider_JUL - data[4].RRE[3].JUL /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          AGO: (((sumRREProvider_AGO - data[4].RRE[3].AGO /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          SET: (((sumRREProvider_SET - data[4].RRE[3].SET /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          OUT: (((sumRREProvider_OUT - data[4].RRE[3].OUT /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          NOV: (((sumRREProvider_NOV - data[4].RRE[3].NOV /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
          DEZ: (((sumRREProvider_DEZ - data[4].RRE[3].DEZ /* <- -Serv/Avan */) * ICMSIPI_var) / 100),
        },
        DESONERACAO: {
          JAN: ((sumRREProvider_JAN * DESONERACAO_var) / 100),
          FEV: ((sumRREProvider_FEV * DESONERACAO_var) / 100),
          MAR: ((sumRREProvider_MAR * DESONERACAO_var) / 100),
          ABR: ((sumRREProvider_ABR * DESONERACAO_var) / 100),
          MAI: ((sumRREProvider_MAI * DESONERACAO_var) / 100),
          JUN: ((sumRREProvider_JUN * DESONERACAO_var) / 100),
          JUL: ((sumRREProvider_JUL * DESONERACAO_var) / 100),
          AGO: ((sumRREProvider_AGO * DESONERACAO_var) / 100),
          SET: ((sumRREProvider_SET * DESONERACAO_var) / 100),
          OUT: ((sumRREProvider_OUT * DESONERACAO_var) / 100),
          NOV: ((sumRREProvider_NOV * DESONERACAO_var) / 100),
          DEZ: ((sumRREProvider_DEZ * DESONERACAO_var) / 100),
        },
      };

      data[6].IMP[0].JAN = `${model.ISS.JAN}`;
      data[6].IMP[0].FEV = `${model.ISS.FEV}`;
      data[6].IMP[0].MAR = `${model.ISS.MAR}`;
      data[6].IMP[0].ABR = `${model.ISS.ABR}`;
      data[6].IMP[0].MAI = `${model.ISS.MAI}`;
      data[6].IMP[0].JUN = `${model.ISS.JUN}`;
      data[6].IMP[0].JUL = `${model.ISS.JUL}`;
      data[6].IMP[0].AGO = `${model.ISS.AGO}`;
      data[6].IMP[0].SET = `${model.ISS.SET}`;
      data[6].IMP[0].OUT = `${model.ISS.OUT}`;
      data[6].IMP[0].NOV = `${model.ISS.NOV}`;
      data[6].IMP[0].DEZ = `${model.ISS.DEZ}`;

      data[6].IMP[1].JAN = `${model.PIS_COFINS.JAN}`;
      data[6].IMP[1].FEV = `${model.PIS_COFINS.FEV}`;
      data[6].IMP[1].MAR = `${model.PIS_COFINS.MAR}`;
      data[6].IMP[1].ABR = `${model.PIS_COFINS.ABR}`;
      data[6].IMP[1].MAI = `${model.PIS_COFINS.MAI}`;
      data[6].IMP[1].JUN = `${model.PIS_COFINS.JUN}`;
      data[6].IMP[1].JUL = `${model.PIS_COFINS.JUL}`;
      data[6].IMP[1].AGO = `${model.PIS_COFINS.AGO}`;
      data[6].IMP[1].SET = `${model.PIS_COFINS.SET}`;
      data[6].IMP[1].OUT = `${model.PIS_COFINS.OUT}`;
      data[6].IMP[1].NOV = `${model.PIS_COFINS.NOV}`;
      data[6].IMP[1].DEZ = `${model.PIS_COFINS.DEZ}`;

      data[6].IMP[2].JAN = `${model.ICMS_IPI.JAN}`;
      data[6].IMP[2].FEV = `${model.ICMS_IPI.FEV}`;
      data[6].IMP[2].MAR = `${model.ICMS_IPI.MAR}`;
      data[6].IMP[2].ABR = `${model.ICMS_IPI.ABR}`;
      data[6].IMP[2].MAI = `${model.ICMS_IPI.MAI}`;
      data[6].IMP[2].JUN = `${model.ICMS_IPI.JUN}`;
      data[6].IMP[2].JUL = `${model.ICMS_IPI.JUL}`;
      data[6].IMP[2].AGO = `${model.ICMS_IPI.AGO}`;
      data[6].IMP[2].SET = `${model.ICMS_IPI.SET}`;
      data[6].IMP[2].OUT = `${model.ICMS_IPI.OUT}`;
      data[6].IMP[2].NOV = `${model.ICMS_IPI.NOV}`;
      data[6].IMP[2].DEZ = `${model.ICMS_IPI.DEZ}`;

      data[6].IMP[3].JAN = `${model.DESONERACAO.JAN}`;
      data[6].IMP[3].FEV = `${model.DESONERACAO.FEV}`;
      data[6].IMP[3].MAR = `${model.DESONERACAO.MAR}`;
      data[6].IMP[3].ABR = `${model.DESONERACAO.ABR}`;
      data[6].IMP[3].MAI = `${model.DESONERACAO.MAI}`;
      data[6].IMP[3].JUN = `${model.DESONERACAO.JUN}`;
      data[6].IMP[3].JUL = `${model.DESONERACAO.JUL}`;
      data[6].IMP[3].AGO = `${model.DESONERACAO.AGO}`;
      data[6].IMP[3].SET = `${model.DESONERACAO.SET}`;
      data[6].IMP[3].OUT = `${model.DESONERACAO.OUT}`;
      data[6].IMP[3].NOV = `${model.DESONERACAO.NOV}`;
      data[6].IMP[3].DEZ = `${model.DESONERACAO.DEZ}`;

      data[6].IMP.forEach((account) => {
        sumTax_JAN = sumTax_JAN + parseFloat(account.JAN);
        sumTax_FEV = sumTax_FEV + parseFloat(account.FEV);
        sumTax_MAR = sumTax_MAR + parseFloat(account.MAR);
        sumTax_ABR = sumTax_ABR + parseFloat(account.ABR);
        sumTax_MAI = sumTax_MAI + parseFloat(account.MAI);
        sumTax_JUN = sumTax_JUN + parseFloat(account.JUN);
        sumTax_JUL = sumTax_JUL + parseFloat(account.JUL);
        sumTax_AGO = sumTax_AGO + parseFloat(account.AGO);
        sumTax_SET = sumTax_SET + parseFloat(account.SET);
        sumTax_OUT = sumTax_OUT + parseFloat(account.OUT);
        sumTax_NOV = sumTax_NOV + parseFloat(account.NOV);
        sumTax_DEZ = sumTax_DEZ + parseFloat(account.DEZ);

        model = {
          ...model,
          TOTAL_IMPOSTOS_DIRETOS: {
            JAN: maskToMoney3(sumTax_JAN),
            FEV: maskToMoney3(sumTax_FEV),
            MAR: maskToMoney3(sumTax_MAR),
            ABR: maskToMoney3(sumTax_ABR),
            MAI: maskToMoney3(sumTax_MAI),
            JUN: maskToMoney3(sumTax_JUN),
            JUL: maskToMoney3(sumTax_JUL),
            AGO: maskToMoney3(sumTax_AGO),
            SET: maskToMoney3(sumTax_SET),
            OUT: maskToMoney3(sumTax_OUT),
            NOV: maskToMoney3(sumTax_NOV),
            DEZ: maskToMoney3(sumTax_DEZ),
          },
        };
      });

      const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));

      margemPorcLocalStorage[3][0] = (model.ISS.JAN + model.PIS_COFINS.JAN + model.ICMS_IPI.JAN + model.DESONERACAO.JAN);
      margemPorcLocalStorage[3][1] = (model.ISS.FEV + model.PIS_COFINS.FEV + model.ICMS_IPI.FEV + model.DESONERACAO.FEV);
      margemPorcLocalStorage[3][2] = (model.ISS.MAR + model.PIS_COFINS.MAR + model.ICMS_IPI.MAR + model.DESONERACAO.MAR);
      margemPorcLocalStorage[3][3] = (model.ISS.ABR + model.PIS_COFINS.ABR + model.ICMS_IPI.ABR + model.DESONERACAO.ABR);
      margemPorcLocalStorage[3][4] = (model.ISS.MAI + model.PIS_COFINS.MAI + model.ICMS_IPI.MAI + model.DESONERACAO.MAI);
      margemPorcLocalStorage[3][5] = (model.ISS.JUN + model.PIS_COFINS.JUN + model.ICMS_IPI.JUN + model.DESONERACAO.JUN);
      margemPorcLocalStorage[3][6] = (model.ISS.JUL + model.PIS_COFINS.JUL + model.ICMS_IPI.JUL + model.DESONERACAO.JUL);
      margemPorcLocalStorage[3][7] = (model.ISS.AGO + model.PIS_COFINS.AGO + model.ICMS_IPI.AGO + model.DESONERACAO.AGO);
      margemPorcLocalStorage[3][8] = (model.ISS.SET + model.PIS_COFINS.SET + model.ICMS_IPI.SET + model.DESONERACAO.SET);
      margemPorcLocalStorage[3][9] = (model.ISS.OUT + model.PIS_COFINS.OUT + model.ICMS_IPI.OUT + model.DESONERACAO.OUT);
      margemPorcLocalStorage[3][10] = (model.ISS.NOV + model.PIS_COFINS.NOV + model.ICMS_IPI.NOV + model.DESONERACAO.NOV);
      margemPorcLocalStorage[3][11] = (model.ISS.DEZ + model.PIS_COFINS.DEZ + model.ICMS_IPI.DEZ + model.DESONERACAO.DEZ);

      localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));

      localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(data));
    }


    return model;
  };


  function postCNPJChange(values: any, resetForm: any) {
    alert(JSON.stringify(values));
    resetForm();
  };


  const releaseList = [
    'Total Impostos Diretos',
    'ISS',
    'PIS/COFINS',
    'ICMS/IPI',
    'Desoneração',
  ];

  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'ISS.ORCADO', label: releaseList[1], value: values.ISS.ORCADO },
      { name: 'PIS_COFINS.ORCADO', label: releaseList[2], value: values.PIS_COFINS.ORCADO },
      { name: 'ICMS_IPI.ORCADO', label: releaseList[3], value: values.ICMS_IPI.ORCADO },
      { name: 'DESONERACAO.ORCADO', label: releaseList[4], value: values.DESONERACAO.ORCADO },
    ];
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'ISS.PLANEJADO', label: releaseList[1], value: values.ISS.PLANEJADO },
      { name: 'PIS_COFINS.PLANEJADO', label: releaseList[2], value: values.PIS_COFINS.PLANEJADO },
      { name: 'ICMS_IPI.PLANEJADO', label: releaseList[3], value: values.ICMS_IPI.PLANEJADO },
      { name: 'DESONERACAO.PLANEJADO', label: releaseList[4], value: values.DESONERACAO.PLANEJADO },
    ];
  };


  // PREVISTO
  const listFieldsProvider = (values) => {
    const list = [];

    list.push(
      { name: 'TOTAL_IMPOSTOS_DIRETOS.JAN', label: 'JAN', value: values.TOTAL_IMPOSTOS_DIRETOS.JAN, typeRelease: releaseList[0], month: 0, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.FEV', label: 'FEV', value: values.TOTAL_IMPOSTOS_DIRETOS.FEV, typeRelease: releaseList[0], month: 1, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.MAR', label: 'MAR', value: values.TOTAL_IMPOSTOS_DIRETOS.MAR, typeRelease: releaseList[0], month: 2, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.ABR', label: 'ABR', value: values.TOTAL_IMPOSTOS_DIRETOS.ABR, typeRelease: releaseList[0], month: 3, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.MAI', label: 'MAI', value: values.TOTAL_IMPOSTOS_DIRETOS.MAI, typeRelease: releaseList[0], month: 4, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.JUN', label: 'JUN', value: values.TOTAL_IMPOSTOS_DIRETOS.JUN, typeRelease: releaseList[0], month: 5, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.JUL', label: 'JUL', value: values.TOTAL_IMPOSTOS_DIRETOS.JUL, typeRelease: releaseList[0], month: 6, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.AGO', label: 'AGO', value: values.TOTAL_IMPOSTOS_DIRETOS.AGO, typeRelease: releaseList[0], month: 7, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.SET', label: 'SET', value: values.TOTAL_IMPOSTOS_DIRETOS.SET, typeRelease: releaseList[0], month: 8, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.OUT', label: 'OUT', value: values.TOTAL_IMPOSTOS_DIRETOS.OUT, typeRelease: releaseList[0], month: 9, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.NOV', label: 'NOV', value: values.TOTAL_IMPOSTOS_DIRETOS.NOV, typeRelease: releaseList[0], month: 10, disabled: true },
      { name: 'TOTAL_IMPOSTOS_DIRETOS.DEZ', label: 'DEZ', value: values.TOTAL_IMPOSTOS_DIRETOS.DEZ, typeRelease: releaseList[0], month: 11, disabled: true },

      { name: 'ISS.JAN', label: 'JAN', value: values.ISS.JAN, typeRelease: releaseList[1], month: 0, disabled: true },
      { name: 'ISS.FEV', label: 'FEV', value: values.ISS.FEV, typeRelease: releaseList[1], month: 1, disabled: true },
      { name: 'ISS.MAR', label: 'MAR', value: values.ISS.MAR, typeRelease: releaseList[1], month: 2, disabled: true },
      { name: 'ISS.ABR', label: 'ABR', value: values.ISS.ABR, typeRelease: releaseList[1], month: 3, disabled: true },
      { name: 'ISS.MAI', label: 'MAI', value: values.ISS.MAI, typeRelease: releaseList[1], month: 4, disabled: true },
      { name: 'ISS.JUN', label: 'JUN', value: values.ISS.JUN, typeRelease: releaseList[1], month: 5, disabled: true },
      { name: 'ISS.JUL', label: 'JUL', value: values.ISS.JUL, typeRelease: releaseList[1], month: 6, disabled: true },
      { name: 'ISS.AGO', label: 'AGO', value: values.ISS.AGO, typeRelease: releaseList[1], month: 7, disabled: true },
      { name: 'ISS.SET', label: 'SET', value: values.ISS.SET, typeRelease: releaseList[1], month: 8, disabled: true },
      { name: 'ISS.OUT', label: 'OUT', value: values.ISS.OUT, typeRelease: releaseList[1], month: 9, disabled: true },
      { name: 'ISS.NOV', label: 'NOV', value: values.ISS.NOV, typeRelease: releaseList[1], month: 10, disabled: true },
      { name: 'ISS.DEZ', label: 'DEZ', value: values.ISS.DEZ, typeRelease: releaseList[1], month: 11, disabled: true },

      { name: 'PIS_COFINS.JAN', label: 'JAN', value: values.PIS_COFINS.JAN, typeRelease: releaseList[2], month: 0, disabled: true },
      { name: 'PIS_COFINS.FEV', label: 'FEV', value: values.PIS_COFINS.FEV, typeRelease: releaseList[2], month: 1, disabled: true },
      { name: 'PIS_COFINS.MAR', label: 'MAR', value: values.PIS_COFINS.MAR, typeRelease: releaseList[2], month: 2, disabled: true },
      { name: 'PIS_COFINS.ABR', label: 'ABR', value: values.PIS_COFINS.ABR, typeRelease: releaseList[2], month: 3, disabled: true },
      { name: 'PIS_COFINS.MAI', label: 'MAI', value: values.PIS_COFINS.MAI, typeRelease: releaseList[2], month: 4, disabled: true },
      { name: 'PIS_COFINS.JUN', label: 'JUN', value: values.PIS_COFINS.JUN, typeRelease: releaseList[2], month: 5, disabled: true },
      { name: 'PIS_COFINS.JUL', label: 'JUL', value: values.PIS_COFINS.JUL, typeRelease: releaseList[2], month: 6, disabled: true },
      { name: 'PIS_COFINS.AGO', label: 'AGO', value: values.PIS_COFINS.AGO, typeRelease: releaseList[2], month: 7, disabled: true },
      { name: 'PIS_COFINS.SET', label: 'SET', value: values.PIS_COFINS.SET, typeRelease: releaseList[2], month: 8, disabled: true },
      { name: 'PIS_COFINS.OUT', label: 'OUT', value: values.PIS_COFINS.OUT, typeRelease: releaseList[2], month: 9, disabled: true },
      { name: 'PIS_COFINS.NOV', label: 'NOV', value: values.PIS_COFINS.NOV, typeRelease: releaseList[2], month: 10, disabled: true },
      { name: 'PIS_COFINS.DEZ', label: 'DEZ', value: values.PIS_COFINS.DEZ, typeRelease: releaseList[2], month: 11, disabled: true },

      { name: 'ICMS_IPI.JAN', label: 'JAN', value: values.ICMS_IPI.JAN, typeRelease: releaseList[3], month: 0, disabled: true },
      { name: 'ICMS_IPI.FEV', label: 'FEV', value: values.ICMS_IPI.FEV, typeRelease: releaseList[3], month: 1, disabled: true },
      { name: 'ICMS_IPI.MAR', label: 'MAR', value: values.ICMS_IPI.MAR, typeRelease: releaseList[3], month: 2, disabled: true },
      { name: 'ICMS_IPI.ABR', label: 'ABR', value: values.ICMS_IPI.ABR, typeRelease: releaseList[3], month: 3, disabled: true },
      { name: 'ICMS_IPI.MAI', label: 'MAI', value: values.ICMS_IPI.MAI, typeRelease: releaseList[3], month: 4, disabled: true },
      { name: 'ICMS_IPI.JUN', label: 'JUN', value: values.ICMS_IPI.JUN, typeRelease: releaseList[3], month: 5, disabled: true },
      { name: 'ICMS_IPI.JUL', label: 'JUL', value: values.ICMS_IPI.JUL, typeRelease: releaseList[3], month: 6, disabled: true },
      { name: 'ICMS_IPI.AGO', label: 'AGO', value: values.ICMS_IPI.AGO, typeRelease: releaseList[3], month: 7, disabled: true },
      { name: 'ICMS_IPI.SET', label: 'SET', value: values.ICMS_IPI.SET, typeRelease: releaseList[3], month: 8, disabled: true },
      { name: 'ICMS_IPI.OUT', label: 'OUT', value: values.ICMS_IPI.OUT, typeRelease: releaseList[3], month: 9, disabled: true },
      { name: 'ICMS_IPI.NOV', label: 'NOV', value: values.ICMS_IPI.NOV, typeRelease: releaseList[3], month: 10, disabled: true },
      { name: 'ICMS_IPI.DEZ', label: 'DEZ', value: values.ICMS_IPI.DEZ, typeRelease: releaseList[3], month: 11, disabled: true },

      { name: 'DESONERACAO.JAN', label: 'JAN', value: values.DESONERACAO.JAN, typeRelease: releaseList[4], month: 0, disabled: true },
      { name: 'DESONERACAO.FEV', label: 'FEV', value: values.DESONERACAO.FEV, typeRelease: releaseList[4], month: 1, disabled: true },
      { name: 'DESONERACAO.MAR', label: 'MAR', value: values.DESONERACAO.MAR, typeRelease: releaseList[4], month: 2, disabled: true },
      { name: 'DESONERACAO.ABR', label: 'ABR', value: values.DESONERACAO.ABR, typeRelease: releaseList[4], month: 3, disabled: true },
      { name: 'DESONERACAO.MAI', label: 'MAI', value: values.DESONERACAO.MAI, typeRelease: releaseList[4], month: 4, disabled: true },
      { name: 'DESONERACAO.JUN', label: 'JUN', value: values.DESONERACAO.JUN, typeRelease: releaseList[4], month: 5, disabled: true },
      { name: 'DESONERACAO.JUL', label: 'JUL', value: values.DESONERACAO.JUL, typeRelease: releaseList[4], month: 6, disabled: true },
      { name: 'DESONERACAO.AGO', label: 'AGO', value: values.DESONERACAO.AGO, typeRelease: releaseList[4], month: 7, disabled: true },
      { name: 'DESONERACAO.SET', label: 'SET', value: values.DESONERACAO.SET, typeRelease: releaseList[4], month: 8, disabled: true },
      { name: 'DESONERACAO.OUT', label: 'OUT', value: values.DESONERACAO.OUT, typeRelease: releaseList[4], month: 9, disabled: true },
      { name: 'DESONERACAO.NOV', label: 'NOV', value: values.DESONERACAO.NOV, typeRelease: releaseList[4], month: 10, disabled: true },
      { name: 'DESONERACAO.DEZ', label: 'DEZ', value: values.DESONERACAO.DEZ, typeRelease: releaseList[4], month: 11, disabled: true },

    );


    return list;
  };


  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffImpostosDiretosModel)}
      onSubmit={(values, { resetForm }) => {
        postCNPJChange(values, resetForm);
      }}>
      {({
        values,
        handleSubmit,
      }) => (

        <ContainerView>
          <SectionMargin>
            <PffFilterContainerForm auto onSubmit={handleSubmit}>

              {/* LANÇAMENTO ORIGINAL */}
              {(props.releaseType?.COD_LANCAMENTO === 'OR') &&

                <RowFieldsGrid xs={6}>

                  {listFieldsBudgeted(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      disabled
                      name={field.name}
                      label={field.label + ' R$'}
                      value={maskToMoney2(field.value)}
                      type={'text'}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_IMPOSTOS_DIRETOS.ORCADO'}
                    label={'Total Imp. Dir. R$'}
                    placeholder={'Total Imp. Dir. R$'}
                    value={values.TOTAL_IMPOSTOS_DIRETOS.ORCADO}
                    type={'text'}
                  />


                </RowFieldsGrid>

              }



              {/* LANÇAMENTO PLANEJADO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PL') &&

                <RowFieldsGrid xs={6}>

                  {listFieldsPlanned(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      disabled
                      name={field.name}
                      label={field.label + ' R$'}
                      value={maskToMoney2(field.value)}
                      type={'text'}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_IMPOSTOS_DIRETOS.PLANEJADO'}
                    label={'Total Imp. Dir. R$'}
                    placeholder={'Total Imp. Dir. R$'}
                    value={values.TOTAL_IMPOSTOS_DIRETOS.PLANEJADO}
                    type={'text'}
                  />


                </RowFieldsGrid>

              }



              {/* LANÇAMENTO PREVISTO */}
              {props.releaseType?.COD_LANCAMENTO === 'PR' &&
                releaseList.map((typeRelease) => (
                  <>
                    <TitleInterGroup titleAlign={'rigth'}>
                      <h1>{typeRelease}</h1>
                    </TitleInterGroup>


                    <ColFieldsGrid xs={12}>

                      {listFieldsProvider(values).map((field) => (

                        typeRelease === field.typeRelease && (

                          <TextInputLabelFloat
                            fontSize={Metrics.fontSizePFFSm}
                            fontSizeLabel={Metrics.fontSizePFFSm}
                            disabled={field.disabled}
                            name={field.name}
                            id={field.name}
                            label={field.label + ' R$'}
                            value={maskToMoney2(field.value)}
                            type={'text'}
                          />
                        )

                      ))}


                    </ColFieldsGrid>

                  </>
                ))
              }

            </PffFilterContainerForm>

          </SectionMargin>

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_ImpostosDiretoScreen;


