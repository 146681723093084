import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import User from '../../models/User';



const INITIAL_STATE = {
  data: User,
  loading: false,
  loggedIn: false,
};



// Action types & creators
export const INTRANET_CONFIG = '@intranet/config';


export const USER_FETCH_INFO = '@user/FETCH_INFO';
export const USER_RESET = '@user/RESET';
export const USER_UPDATE = '@user/UPDATE';

export const USER_TOKEN = '@user/MKS-TOKEN';

export const userUpdateAction = createAction<object>(USER_UPDATE);
export const userFetchInfoAction = createAction<object>(USER_FETCH_INFO);
export const userResetAction = createAction<object>(USER_RESET);



// Handlers

const updateUser = (state = INITIAL_STATE, action) => ({
  ...state,
  data: { ...state.data, ...action.payload },
});

const setUser = (state = INITIAL_STATE, action) => ({
  ...state,
  data: action.payload,
  loggedIn: true,
});


const resetUser = () => ({
  ...INITIAL_STATE,
});



// Reducers
export default createReducer(INITIAL_STATE, {
  [userUpdateAction.type]: updateUser,
  [userFetchInfoAction.type]: setUser,
  [userResetAction.type]: resetUser,
});
