const PcbAprovarArray = [
  {
    key: '00',
    color: '#007a00',
    label: 'Processo normal',
    value: 'Processo normal'.toLocaleUpperCase(),
  },
  {
    key: '01',
    color: '#2540ef',
    label: 'Fornecedor único',
    value: 'Fornecedor único'.toLocaleUpperCase(),
  },
  {
    key: '02',
    color: '#FFF000',
    label: 'Estratégia comercial',
    value: 'Estratégia comercial'.toLocaleUpperCase(),
  },
  {
    key: '03',
    color: '#e358f9',
    label: 'Negociação alta administração',
    value: 'Negociação alta administração'.toLocaleUpperCase(),
  },
  {
    key: '04',
    color: '#faa200',
    label: 'Definido pelo cliente',
    value: 'Definido pelo cliente'.toLocaleUpperCase(),
  },
  {
    key: '05',
    color: '#58c5e9',
    label: 'Aditivo / Contratos existentes',
    value: 'Aditivo / Contratos existentes'.toLocaleUpperCase(),
  },
  {
    key: '06',
    color: '#000000',
    label: 'Serviços públicos',
    value: 'Serviços públicos'.toLocaleUpperCase(),
  },
  {
    key: '07',
    color: '#fa0000',
    label: 'Compra urgente',
    value: 'Compra urgente'.toLocaleUpperCase(),
  },
  {
    key: '08',
    color: '#c7694d',
    label: 'BM/NF emitido',
    value: 'BM/NF emitido'.toLocaleUpperCase(),
  },
  {
    key: '09',
    color: '#08490d',
    label: 'Alteração de PC aprovado',
    value: 'Alteração de PC aprovado'.toLocaleUpperCase(),
  },
  {
    key: '10',
    color: '#bfbaba',
    label: 'Contrato',
    value: 'Contrato'.toLocaleUpperCase(),
  },
  {
    key: '11',
    color: '#b3c800',
    label: 'Contrato de Parceria',
    value: 'Contrato de Parceria'.toLocaleUpperCase(),
  },
  {
    key: '12',
    color: '#0c8389',
    label: 'Autorização de Entrega',
    value: 'Autorização de Entrega'.toLocaleUpperCase(),
  },
  {
    key: '13',
    color: '#04ff3a',
    label: 'Pedido Mensal',
    value: 'Pedido Mensal'.toLocaleUpperCase(),
  },
  {
    key: '14',
    color: '#55178f',
    label: ' Manutenção de frota',
    value: ' Manutenção de frota'.toLocaleUpperCase(),
  },
];



export default PcbAprovarArray;
