const PcbContratosModel = {
  input_situacao: 'Todos'.toLocaleUpperCase(),
  input_fornecedor: '',
  input_planilha: '',
  input_filial: '',
  input_cnpj: '',
  input_revisao: '',
  input_centro_custo: '',
  input_numero: '',
};



export default PcbContratosModel;
