import * as Yup from 'yup';


const DepositPstockValidationSchema = Yup.object().shape({

  select_deposito: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

});



export default DepositPstockValidationSchema;
