import * as Yup from 'yup';



const GIRegraValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),
  IDSIS: Yup.string(),

});



export default GIRegraValidationSchema;
