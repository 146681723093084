import { intranet_config } from '../../utils/Config';


function returnOrientation(){
  const listSmeOrientacaoArray = [
    {
      key: '1',
      label: 'Processo Normal',
      value: '00',
    },
    {
      key: '2',
      label: 'Compra Urgente',
      value: '07',
    },
    {
      key: '2',
      label: 'Regularização',
      value: '04',
    },
    {
      key: '3',
      label: 'Pedido Mensal',
      value: '13',
    },
    {
      key: '4',
      label: 'Fornecedor Único',
      value: '01',
    },
    {
      key: '5',
      label: 'Emergencias',
      value: '16',
    },
    {
      key: '6',
      label: 'Contratos',
      value: '10',
    },
    {
      key: '4',
      label: 'Atendimento Interno',
      value: '14',
    },
  ];


  return listSmeOrientacaoArray;
}

export const SmeOrientacaoArray = returnOrientation();


export const SmeUtilizacaoArray = [
  {
    key: '1',
    label: 'Administrativo',
    value: '18',
  },
  {
    key: '2',
    label: 'Fabricação',
    value: '14',
  },
  {
    key: '3',
    label: 'Operacional',
    value: '17',
  },
  {
    key: '4',
    label: 'Revenda',
    value: '16',
  },
  {
    key: '5',
    label: 'Serviço',
    value: '15',
  },
];

export const SmeTipoManutencao = [
  {
    key: 0,
    label: 'Corretiva',
    value: '01',
  },
  {
    key: 1,
    label: 'Preventiva',
    value: '02',
  },
];

export const SmeMotivoManutencao = [
  {
    key: 0,
    label: 'Desgaste Natural',
    value: '01',
  },
  {
    key: 1,
    label: 'Mau uso',
    value: '02',
  },
];
