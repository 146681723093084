import * as Yup from 'yup';


const ICencCentroCustoValidationSchema = Yup.object().shape({

  select_situacao: Yup.string(),

  input_un_neg: new Yup.ArraySchema(),

  input_os: new Yup.ArraySchema(),

  input_rro: Yup.string(),

  input_descricao: Yup.string(),

  input_gestor: Yup.string(),

});



export default ICencCentroCustoValidationSchema;
