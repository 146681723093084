import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Modal,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import {
  toast,
} from 'react-toastify';

import {
  Formik, FormikProps,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../../components/Buttons/IButton';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../../services/api.service';

import {
  RootState,
} from '../../../../../../../store';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from './styled';

import UnitCreateModel from './unit.model';
import UnitCreateValidationSchema from './unit.validation';



interface IProps {
  show: boolean;

  negative?: any;
}



const ModalCreateUnit: React.FC<IProps> = (props: IProps) => {
  const user = useSelector((state: RootState) => state.user.data);


  const formik = useRef<FormikProps<any>>(null);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [listCode, setListCode] = useState([]);
  const [listUnit, setListUnit] = useState([]);



  function showSnackBarProps() {
    toast.error('Erro ao obter dados.', {
      position: 'bottom-center',
      autoClose: Metrics.messageAutoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }


  function renderListCode(listResp: any) {
    const listToMap = listResp.map((item) => {
      const listMapped = {
        'key': item?.ID,
        'label': item?.DESCR,
        'value': item?.COD,
      };
      return listMapped;
    });

    setListCode(listToMap);
    formik.current?.setFieldValue('select_code', listToMap[0]?.value);
  };


  async function getCodeData() {
    try {
      const url = '/pstock/parametro/consulta/parametro';
      const response = await ApiWS().post(url);
      return response;
    }
    catch (error: any) {
      console.error('getCodeData', JSON.stringify(error.response, null, 2));
    }
  };


  function renderListUnit(listResp: any) {
    const listToMap = listResp.map((item) => {
      const valueResp = item.DESCR !== '' || item.DESCR !== null || item.DESCR !== undefined ? item.DESCR : null;

      const listMapped = {
        'key': item?.ID,
        'label': item?.ID ? `[${item?.ID}] - ${valueResp}` : item?.ID,
        'value': item?.ID,
      };
      return listMapped;
    });

    setListUnit(listToMap);
    formik.current?.setFieldValue('select_id_unidade', listToMap[0]?.value);
  };


  async function getUnitData() {
    try {
      const url = '/pstock/parametro/lista/unidade';
      const response = await ApiWS().post(url);
      return response;
    }
    catch (error: any) {
      console.error('getUnitData', JSON.stringify(error.response, null, 2));
    }
  };


  async function getFormReservesFunction() {
    try {
      setMounted(false);

      const [codeResponse, unitResponse] = await Promise.all([getCodeData(), getUnitData()]);

      if (codeResponse) {
        renderListCode(codeResponse.data);
      }
      else {
        showSnackBarProps();
      }

      if (unitResponse) {
        renderListUnit(unitResponse.data);
      }
      else {
        showSnackBarProps();
      }
    }
    catch (error: any) {
      console.error(error);

      showSnackBarProps();
    }
    finally {
      setMounted(true);
    }
  };


  async function savePstockUnit(values: typeof UnitCreateModel) {
    const payload = {
      CODPARAMETRO: values.select_code,
      IDUNIDADE: values.select_id_unidade,
      VALOR: values.input_valor,
      IDUSER: user.id,
    };

    try {
      setLoading(true);

      const url = '/pstock/parametro/salva/unidade';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        toast.success('Unidade salva.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.negative(true);
      }
    }
    catch (error) {
      console.error('savePstockUnit', JSON.stringify(error.response, null, 2));

      toast.error('Erro ao salvar unidade.', {
        position: 'bottom-center',
        autoClose: Metrics.messageAutoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    getFormReservesFunction();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <Formik
        enableReinitialize
        validateOnMount
        innerRef={formik}
        initialValues={UnitCreateModel}
        validationSchema={UnitCreateValidationSchema}
        onSubmit={(values) => {
          savePstockUnit(values);
        }}>
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (

          <ModalContainerStyled onSubmit={handleSubmit}>

            <TitleGroup>
              {'Associar parâmetro a unidade'}
            </TitleGroup>



            {!mounted && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}



            {mounted && (
              <ModalBodyStyled>

                <Col>
                  <DropdownInput
                    disabled={loading}
                    name={'select_code'}
                    label={'Parâmetro'}
                    dropArray={listCode}
                    defaultValue={listCode[0]?.value}
                    value={values.select_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>


                <Col>
                  <DropdownInput
                    disabled={loading}
                    name={'select_id_unidade'}
                    label={'Unidade'}
                    dropArray={listUnit}
                    defaultValue={listUnit[0]?.value}
                    value={values.select_id_unidade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>


                <Col>
                  <TextInput
                    disabled={loading}
                    type={'text'}
                    name={'input_valor'}
                    label={'Valor'}
                    placeholder={'Valor'}
                    value={values.input_valor || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

              </ModalBodyStyled>
            )}



            <ModalFooterStyled>
              <IButton
                loading={loading}
                backgroundColor={Colors?.accept}
                text={'Salvar'}
                type={'submit'}
              />


              <IButton
                backgroundColor={Colors?.primary}
                text={'Fechar'}
                onClick={() => {
                  props.negative(false);
                }}
              />
            </ModalFooterStyled>

          </ModalContainerStyled>

        )}
      </Formik>

    </Modal>

  );
};



export default ModalCreateUnit;
