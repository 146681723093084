import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../utils/StringUtils';



const PcbContratosValidationSchema = Yup.object().shape({

  input_situacao: Yup.string(),



  input_fornecedor: Yup.string(),



  input_planilha: Yup.string()
    .length(Metrics.input_planilha, Messages.input_planilha)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),


  input_filial: Yup.string(),
  // .length(Metrics.input_filial, Messages.input_filial)
  // .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_cnpj: Yup.string()
    .length(Metrics.input_cnpj, Messages.input_cnpj)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_revisao: Yup.string()
    // .length(Metrics.input_rev, Messages.input_rev)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_centro_custo: Yup.string(),
  // .max(Metrics.input_centroCusto, Metrics.input_centroCusto),



  input_numero: Yup.string(),
  // .max(Metrics.input_contrato, Messages.input_contrato)
  // .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),

});



export default PcbContratosValidationSchema;
