import React from 'react';
import { Row } from 'react-bootstrap';
import { YesNoArray2 } from '../../../../../../../../common/arrays/YesNo';
import { TitleGroup } from '../../../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../../components/TextFields/Input';
import { ColItems_E, ColMeets_E, ColObsColab_E, ColImproviment_E, AreaFieldsSeparate, ColDescr_E, ColLeader_E } from '../../../styled';

interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}

const POCSectionE: React.FC<IProps> = (props) => {
  const { handleBlur, handleChange, values, setFieldValue } = props;

  return (
    <AreaFieldsSeparate>

      <Row>
        <TitleGroup>
          {'E - Condição do Ambiente'.toUpperCase()}
        </TitleGroup>

        <Row>
          <ColItems_E>Itens</ColItems_E>
          <ColLeader_E><span>Líder</span></ColLeader_E>
          <ColDescr_E><span>Descrição</span></ColDescr_E>
          <ColMeets_E><span>Atende</span></ColMeets_E>
          <ColObsColab_E><span>Colab. Observ.</span></ColObsColab_E>
          <ColImproviment_E><span>Melhorias</span></ColImproviment_E>
        </Row>
      </Row>


      {/* E1 */}
      <Row>

        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E1'}
            label={'E1'}
            onBlur={handleBlur}
            value={'Ambiente de Trabalho, está limpo e organizado?'}
          />
        </ColItems_E>
        

        <ColLeader_E>
          <DropdownInput
            name={'E1_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('E1_NCOLABORADOR', '');
                setFieldValue('E1_NMELHORIA', '');
                setFieldValue('E1_IDEQUIPE', '');
                setFieldValue('E1_LIDER', '');
                setFieldValue('E1_DESCR', '');
              }
              else {
                setFieldValue('E1_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('E1_LIDER', e.target.value.split(',')[1]);
                setFieldValue('E1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_E>
        

        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.E1_DESCR}
          />
        </ColDescr_E>
        
        
        <ColMeets_E>
          <DropdownInput
            name={'E1_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_E>


        <ColObsColab_E>
          <TextInput
            disabled={values.E1_ATENDE === ''}
            type={'number'}
            name={'E1_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E1_NCOLABORADORES}
          />
        </ColObsColab_E>


        <ColImproviment_E>
          <TextInput
            disabled={values.E1_ATENDE === ''}
            type={'number'}
            name={'E1_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E1_NMELHORIAS}
          />
        </ColImproviment_E>

      </Row>


      {/* E2 */}
      <Row>

        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E2'}
            label={'E2'}
            onBlur={handleBlur}
            value={'Isolamento / Sinalização / Identificação da área, estão compátiveis com a  tarefa?'}
          />
        </ColItems_E>
        

        <ColLeader_E>
          <DropdownInput
            name={'E2_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('E2_NCOLABORADOR', '');
                setFieldValue('E2_NMELHORIA', '');
                setFieldValue('E2_IDEQUIPE', '');
                setFieldValue('E2_LIDER', '');
                setFieldValue('E2_DESCR', '');
              }
              else {
                setFieldValue('E2_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('E2_LIDER', e.target.value.split(',')[1]);
                setFieldValue('E2_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_E>
        

        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.E2_DESCR}
          />
        </ColDescr_E>
        
        
        
        <ColMeets_E>
          <DropdownInput
            name={'E2_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_E>


        <ColObsColab_E>
          <TextInput
            disabled={values.E2_ATENDE === ''}
            type={'number'}
            name={'E2_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E2_NCOLABORADORES}
          />
        </ColObsColab_E>


        <ColImproviment_E>
          <TextInput
            disabled={values.E2_ATENDE === ''}
            type={'number'}
            name={'E2_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E2_NMELHORIAS}
          />
        </ColImproviment_E>

      </Row>


      {/* E3 */}
      <Row>

        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E3'}
            label={'E3'}
            onBlur={handleBlur}
            value={'Coleta Seletiva, está sendo atendida e de acordo com os resíduos gerados na tarefa?'}
          />
        </ColItems_E>
        

        <ColLeader_E>
          <DropdownInput
            name={'E3_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('E3_NCOLABORADOR', '');
                setFieldValue('E3_NMELHORIA', '');
                setFieldValue('E3_IDEQUIPE', '');
                setFieldValue('E3_LIDER', '');
                setFieldValue('E3_DESCR', '');
              }
              else {
                setFieldValue('E3_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('E3_LIDER', e.target.value.split(',')[1]);
                setFieldValue('E3_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_E>
        

        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.E3_DESCR}
          />
        </ColDescr_E>
        
        
        <ColMeets_E>
          <DropdownInput
            name={'E3_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_E>


        <ColObsColab_E>
          <TextInput
            disabled={values.E3_ATENDE === ''}
            type={'number'}
            name={'E3_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E3_NCOLABORADORES}
          />
        </ColObsColab_E>


        <ColImproviment_E>
          <TextInput
            disabled={values.E3_ATENDE === ''}
            type={'number'}
            name={'E3_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E3_NMELHORIAS}
          />
        </ColImproviment_E>

      </Row>


      {/* E4 */}
      <Row>

        <ColItems_E>
          <TextInput
            disabled
            type={'text'}
            name={'E4'}
            label={'E4'}
            onBlur={handleBlur}
            value={'Ambiente de trabalho, está desobstruido e livres de armadilhas?'}
          />
        </ColItems_E>
        

        <ColLeader_E>
          <DropdownInput
            name={'E4_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('E4_NCOLABORADOR', '');
                setFieldValue('E4_NMELHORIA', '');
                setFieldValue('E4_IDEQUIPE', '');
                setFieldValue('E4_LIDER', '');
                setFieldValue('E4_DESCR', '');
              }
              else {
                setFieldValue('E4_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('E4_LIDER', e.target.value.split(',')[1]);
                setFieldValue('E4_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_E>
        

        <ColDescr_E>
          <TextInput
            disabled
            type={'text'}
            name={'E4_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.E4_DESCR}
          />
        </ColDescr_E>
        
        
        <ColMeets_E>
          <DropdownInput
            name={'E4_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_E>


        <ColObsColab_E>
          <TextInput
            disabled={values.E4_ATENDE === ''}
            type={'number'}
            name={'E4_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E4_NCOLABORADORES}
          />
        </ColObsColab_E>


        <ColImproviment_E>
          <TextInput
            disabled={values.E4_ATENDE === ''}
            type={'number'}
            name={'E4_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.E4_NMELHORIAS}
          />
        </ColImproviment_E>

      </Row>

    </AreaFieldsSeparate>
  );
};


export default POCSectionE;
