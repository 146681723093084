import styled from 'styled-components';
import { Colors } from '../../common/constants';

interface IProps {
  size:string;
  disabled?: boolean;
}

export const BoxImg = styled.div`
  width: ${(props: IProps) => props.size === 'sm' && '100px'  || props.size === 'md' && '200px' } ;
  height: ${(props: IProps) => props.size === 'sm' && '100px' || props.size === 'md' && '200px'} ;
  border-radius: ${(props: IProps) => props.size === 'sm' && '4rem'  || props.size === 'md' && '50rem' } ;
  background-color: ${Colors?.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors?.grayDark};
  font-size: ${(props: IProps) => props.size === 'sm' && '20px'  || props.size === 'md' && '40px' } ;
`;


export const Image = styled.img`
  width: ${(props: IProps) => props.size === 'sm' && '100px' || props.size === 'md' && '200px'} ;
  height: ${(props: IProps) => props.size === 'sm' && '100px'|| props.size === 'md' && '200px'} ;
  border-radius: ${(props: IProps) => props.size === 'sm' && '20rem'  || props.size === 'md' && '50rem' } ;
  background-color: #fff;
  object-fit: cover;
  ${(props: IProps) => props.disabled && 'filter: opacity(50%)'} ;
`;
