import React, { useState, useEffect } from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';
import { POCEquipeModel2 } from '../POC_Cadastro/POC_Cadastro_Tabs/002POC_Cadastro_Equipe/POC-Tab_Equipe.model';
import { POCEquipeValidationSchema2 } from '../POC_Cadastro/POC_Cadastro_Tabs/002POC_Cadastro_Equipe/POC-Tab_Equipe.validation';


interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
  changeStateCCDrop: any;
}



const EquipeCUDModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listCCDrop, setListCCDrop] = useState([]);


  function postCCDrop() {
    setLoading(true);

    const url = '/poc/equipe/consulta/cc';

    ApiWS()
      .get(url)
      .then((res) => {
        res.data.forEach((cc, index) => {
          setListCCDrop((listCC) => [
            ...listCC,
            {
              key: index,
              label: cc.OS,
              value: cc.OS,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error('postCCDrop:::', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postSaveEquipe(values) {
    setLoading(true);

    let url = '';
    let payload = [values];

    if (props.type === 'NEW') {
      url = 'poc/equipe/insere';
    }

    if (props.type === 'EDIT') {
      url = 'poc/equipe/altera';
      payload = [{ ...values, ID: props.valuesEdit.ID }];
    }

    console.log(payload);

    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: 'Equipe salva com sucesso.',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveEquipe', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  function postRemoveEquipe(values) {
    setLoading(true);

    let url = '';

    url = 'poc/equipe/exclui';

    const payload = [{ ...values, ID: props.valuesRemove.ID }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else if (res.status === 203) {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveEquipe', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    postCCDrop();
  }, []);


  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={
            props?.valuesEdit
            || props?.valuesRemove
            || POCEquipeModel2
          }
          validationSchema={POCEquipeValidationSchema2}
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveEquipe(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveEquipe(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <Row>

                <Col>
                  <DropdownInput
                    disabled={loading || props.type !== 'NEW'}
                    name={'CC'}
                    label={'Centro de Custo'}
                    placeholder={'Selecione ou deixe em branco para todos.'}
                    dropArray={listCCDrop}
                    onChange={(e) => {
                      handleChange(e);
                      props.changeStateCCDrop(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.CC}
                  />
                </Col>

              </Row>


              <Row>

                <Col>
                  <TextInput
                    disabled={loading || props.type === 'REMOVE'}
                    type={'text'}
                    name={'LIDER'}
                    label={'Líder'}
                    placeholder={'Líder'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.LIDER.toLocaleUpperCase()}
                  />
                </Col>


                <Col>
                  <TextInput
                    disabled={loading || props.type === 'REMOVE'}
                    type={'text'}
                    name={'DESCR'}
                    label={'Descrição'}
                    placeholder={'Descrição'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.DESCR.toLocaleUpperCase()}
                  />
                </Col>

              </Row>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default EquipeCUDModal;
