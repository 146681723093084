import * as Yup from 'yup';


const PAVReconhecimentoValidationSchema = Yup.object().shape({

  MAT: Yup.string().required('Obrigatorio'),
  MENSAGEM: Yup.string().required('Obrigatorio'),
  PILAR: Yup.string().required('Obrigatorio'),

});



export default PAVReconhecimentoValidationSchema;
