const POCCartaoModel = {
  CC: '',
  AREA: '',
  OBSERVADOR: '',
  MATRICULA: '',
  DTINI: '',
  DTFIM: '',
};



export default POCCartaoModel;
