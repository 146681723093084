import React,
{
  useState,
  useEffect,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';


import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContentStyled,
  Content,
  Screen,
  SectionMargin,
  TableActionNextCss,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconApproval,
  IconAttachment,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
  IconTrash,
  IconUserCheck,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';

import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../components/Table';

import NameRoutes from '../../../../../navigation/name';

import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';


import {
  DateTimezoneSpliceFormat,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import SmeAnexosModal from '../Modals/AnexoSmeModal';
import SMApproversModal from '../Modals/ApproversModal';
import HistoricoAprovacaoSMEModal from '../Modals/HistoricoAprovacaoSMEModal';
import SmeHistoricoModal from '../Modals/HistoricoSmeModal';



const SME_HomeScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const [carregando, setCarregando] = useState(false);
  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s aqui!',
    description: 'Não há nenhuma SME para você',
  });

  const [solicitacoes, setSolicitacoes] = useState([]);

  const [valorAnexo, setValorAnexo] = useState(null);
  const [telaAnexo, setTelaAnexo] = useState(false);

  const [removeSME, setRemoveSME] = useState(null);
  const [telaRemoveConfirmacao, setTelaRemoveConfirmacao] = useState(false);

  const [showHistoricoAprovacaoSME, setShowHistoricoAprovacaoSME] = useState(false);
  const [valuesHistoricoAprovacaoSME, setValuesHistoricoAprovacaoSME] = useState(null);



  async function findPendentes() {
    try {
      setCarregando(true);

      const url = '/sm/consulta/pendentes/solicitante';
      const response = await ApiWS().get(url);

      setSolicitacoes(response.data);
    }
    catch (error) {
      console.error('SME Pendentes', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject(returnErrorObject(500));
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  async function removeItem(numSm, numFilial) {
    try {
      const url = '/sm/consulta/pendentes/remover';
      const response = await ApiWS().post(url, { numSM: numSm, numFilial: numFilial });

      if (response.status === 200) {
        toast.success('SME Removida com sucesso', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        findPendentes();
      }
      else {
        toast.error('Erro ao tentar deletar SME', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }

      setTelaRemoveConfirmacao(false);
      setTimeout(() => {
        setRemoveSME(null);
      }, 300);
    }
    catch (error) {
      console.error('Deletar Item SME', JSON.stringify(error.response, null, 2));
    }
  };

  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/sme/atendimento/impressao/${values.CP_FILIAL}/${values.SM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if(res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  useEffect(() => {
    findPendentes();
  }, []);



  const columns = [
    {
      dataField: 'CP_FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '4%' };
      },
    },
    {
      dataField: 'OS',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'SM',
      text: 'SME',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'DataEmissao',
      text: 'Data de Emissão',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return DateTimezoneSpliceFormat(cell);
      },
    },
    {
      dataField: 'Solicitante',
      text: 'Solicitante',
      sort: true,
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'X5_DESCRI',
      text: 'Utilização',
      sort: true,
    },
    {
      dataField: 'CP_ORIGPC',
      text: 'Orientação',
      sort: true,
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        const condition = row.hasOwnProperty('CP_OBSERVA') &&
          (row.CP_OBSERVA && row.CP_OBSERVA !== '' && row.CP_OBSERVA !== null && row.CP_OBSERVA !== undefined);

        // Botão Observações
        return (
          <ButtonTableAction
            tooltip={
              condition
                ? row.CP_OBSERVA
                : 'Sem Observações'
            }
            icon={
              <IconInfoSquare
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Impressao
        return (
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            onClick={() => {
              postPrinter(row);
              // window.open(`/${NameRoutes.SME_Impressao}/${row.CP_FILIAL}${row.SM}`, '_blank').focus();
            }}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Modal Anexos
        return (
          <ButtonTableAction
            tooltip={TextTooltip.ATTACHMENT}
            onClick={() => {
              setValorAnexo({
                SM: { FILIAL: row.CP_FILIAL, NUM: row.SM },
                showModal: row.Status === ('Aprovacao OS' || 'Aprovacao CA') ? true : false,
              });
              setTelaAnexo(true);
            }}
            icon={
              <IconAttachment
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Modal Deletar
        return row.Status
          ? (
            <ButtonTableAction
              tooltip={TextTooltip.REMOVE}
              onClick={() => {
                setRemoveSME({
                  SM: row.SM,
                  CP_FILIAL: row.CP_FILIAL,
                });
                setTelaRemoveConfirmacao(true);
              }}
              icon={
                <IconTrash
                  color={Colors?.black}
                  size={16}
                />
              }
            />
          )
          : (
            <ButtonTableAction
              tooltip={TextTooltip.REMOVE}
              icon={
                <IconTrash
                  color={Colors?.gray}
                  size={16}
                />
              }
            />
          );
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Modal Aprovadores
        // return (
        //   <ButtonTableAction
        //     tooltip={TextTooltip.APPROVALS}
        //     onClick={() => {
        //       setShowHistoricoAprovacaoSME(true);
        //       setValuesHistoricoAprovacaoSME({
        //         'FILIAL': row.CP_FILIAL,
        //         'CC': row.OS,
        //         'NUM': row.SM,
        //       });
        //     }}
        //     icon={
        //       <IconApproval
        //         color={Colors?.black}
        //         size={16}
        //       />
        //     }
        //   />
        // );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: solicitacoes.length,
  };


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={SME_NavRoutes}
          title={'SME'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && solicitacoes.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!carregando && solicitacoes.length > 0 && (
            <SectionMargin>
              <TitleGroup>
                {'SME'.toUpperCase()}
              </TitleGroup>


              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={solicitacoes.length < 10}
                    data={solicitacoes}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            </SectionMargin>
          )}

        </ContentStyled>
      </Content>



      {valorAnexo && (
        <SmeAnexosModal
          show={telaAnexo}
          anexar={valorAnexo.showModal}
          sm={valorAnexo.SM}
          onHide={() => {
            setTelaAnexo(false);
            setTimeout(() => {
              setValorAnexo(null);
            }, 300);
          }}
        />
      )}


      {showHistoricoAprovacaoSME && valuesHistoricoAprovacaoSME && (
        <HistoricoAprovacaoSMEModal
          show={showHistoricoAprovacaoSME}
          onHide={() => setShowHistoricoAprovacaoSME(false)}
          value={valuesHistoricoAprovacaoSME}
        />
      )}



      {removeSME && (
        <ConfirmationModal
          show={telaRemoveConfirmacao}
          head={`Deletar SME: ${removeSME.SM}`}
          orient={'Tem certeza que deseja deletar a SM ' + removeSME.SM + '?'}
          positive={() => {
            removeItem(removeSME.SM, removeSME.CP_FILIAL);
          }}
          negative={() => {
            setTelaRemoveConfirmacao(false);
            setTimeout(() => {
              setRemoveSME(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default SME_HomeScreen;
