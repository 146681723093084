import * as Yup from 'yup';


const ParamsCreateValidationSchema = Yup.object().shape({

  input_desc: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_code: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_ajuda: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

});



export default ParamsCreateValidationSchema;
