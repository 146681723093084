import React from 'react';
import { Row } from 'react-bootstrap';
import { YesNoArray2 } from '../../../../../../../../common/arrays/YesNo';
import { TitleGroup } from '../../../../../../../../common/styles/styled.layout';
import DropdownInput from '../../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../../components/TextFields/Input';
import { ColItems_A, ColMeets_A, ColObsColab_A, ColImproviment_A, AreaFieldsSeparate, ColLeader_A, ColDescr_A } from '../../../styled';

interface IProps {
  handleChange?: any;
  handleBlur?: any;
  setFieldValue?: any;
  values?: any;
  listLeaders?: any;
}

const POCSectionA: React.FC<IProps> = (props) => {
  const { handleBlur, handleChange, values, setFieldValue } = props;

  return (
    <AreaFieldsSeparate>

      <Row>
        <TitleGroup>
          {'A - Epi, Uso e Condições'.toUpperCase()}
        </TitleGroup>

        <Row>
          <ColItems_A>Itens</ColItems_A>
          <ColLeader_A><span>Líder</span></ColLeader_A>
          <ColDescr_A><span>Descrição</span></ColDescr_A>
          <ColMeets_A><span>Atende</span></ColMeets_A>
          <ColObsColab_A><span>Colab. Observ.</span></ColObsColab_A>
          <ColImproviment_A><span>Melhorias</span></ColImproviment_A>
        </Row>
      </Row>


      {/* A1 */}
      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A1'}
            label={'A1'}
            onBlur={handleBlur}
            value={'Os Epis,  estão sendo utilizados de forma correta, pelo usuário (s)?'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A1_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('A1_NCOLABORADOR', '');
                setFieldValue('A1_NMELHORIA', '');
                setFieldValue('A1_IDEQUIPE', '');
                setFieldValue('A1_LIDER', '');
                setFieldValue('A1_DESCR', '');
              }
              else {
                setFieldValue('A1_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('A1_LIDER', e.target.value.split(',')[1]);
                setFieldValue('A1_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A1_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.A1_DESCR}
          />
        </ColDescr_A>


        <ColMeets_A>
          <DropdownInput
            name={'A1_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_A>


        <ColObsColab_A>
          <TextInput
            disabled={values.A1_ATENDE === ''}
            type={'number'}
            name={'A1_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A1_NCOLABORADORES}
          />
        </ColObsColab_A>


        <ColImproviment_A>
          <TextInput
            disabled={values.A1_ATENDE === ''}
            type={'number'}
            name={'A1_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A1_NMELHORIAS}
          />
        </ColImproviment_A>

      </Row>


      {/* A2 */}
      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A2'}
            label={'A2'}
            onBlur={handleBlur}
            value={'Os Epis,estão em boas condições de uso/integridade?'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A2_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('A2_NCOLABORADOR', '');
                setFieldValue('A2_NMELHORIA', '');
                setFieldValue('A2_IDEQUIPE', '');
                setFieldValue('A2_LIDER', '');
                setFieldValue('A2_DESCR', '');
              }
              else {
                setFieldValue('A2_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('A2_LIDER', e.target.value.split(',')[1]);
                setFieldValue('A2_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A2_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.A2_DESCR}
          />
        </ColDescr_A>


        <ColMeets_A>
          <DropdownInput
            name={'A2_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_A>


        <ColObsColab_A>
          <TextInput
            disabled={values.A2_ATENDE === ''}
            type={'number'}
            name={'A2_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A2_NCOLABORADORES}
          />
        </ColObsColab_A>


        <ColImproviment_A>
          <TextInput
            disabled={values.A2_ATENDE === ''}
            type={'number'}
            name={'A2_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A2_NMELHORIAS}
          />
        </ColImproviment_A>

      </Row>


      {/* A3 */}
      <Row>

        <ColItems_A>
          <TextInput
            disabled
            type={'text'}
            name={'A3'}
            label={'A3'}
            onBlur={handleBlur}
            value={'Os Epis, são específicos para proteger o colaborador (es) quanto aos PERIGOS E RISCOS associados atividade?'}
          />
        </ColItems_A>


        <ColLeader_A>
          <DropdownInput
            name={'A3_LIDER'}
            dropArray={props.listLeaders}
            placeholder={'Selecione o Líder.'}
            onChange={(e: any) => {
              props.handleChange(e);
              if (e.target.value === '') {
                setFieldValue('A3_NCOLABORADOR', '');
                setFieldValue('A3_NMELHORIA', '');
                setFieldValue('A3_IDEQUIPE', '');
                setFieldValue('A3_LIDER', '');
                setFieldValue('A3_DESCR', '');
              }
              else {
                setFieldValue('A3_IDEQUIPE', e.target.value.split(',')[0]);
                setFieldValue('A3_LIDER', e.target.value.split(',')[1]);
                setFieldValue('A3_DESCR', e.target.value.split(',')[2]);
              }
            }}
            onBlur={handleBlur}
          />
        </ColLeader_A>


        <ColDescr_A>
          <TextInput
            disabled
            type={'text'}
            name={'A3_DESCR'}
            placeholder={'Selecione o Líder.'}
            onBlur={handleBlur}
            value={values.A3_DESCR}
          />
        </ColDescr_A>


        <ColMeets_A>
          <DropdownInput
            name={'A3_ATENDE'}
            dropArray={YesNoArray2}
            placeholder={'N/A'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ColMeets_A>


        <ColObsColab_A>
          <TextInput
            disabled={values.A3_ATENDE === ''}
            type={'number'}
            name={'A3_NCOLABORADORES'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A3_NCOLABORADORES}
          />
        </ColObsColab_A>


        <ColImproviment_A>
          <TextInput
            disabled={values.A3_ATENDE === ''}
            type={'number'}
            name={'A3_NMELHORIAS'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.A3_NMELHORIAS}
          />
        </ColImproviment_A>

      </Row>

    </AreaFieldsSeparate>
  );
};


export default POCSectionA;
