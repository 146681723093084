const YesNoArray = [
  {
    key: '1',
    label: 'Não',
    value: '0',
  },
  {
    key: '2',
    label: 'Sim',
    value: '1',
  },
];


export const YesNoArray2 = [
  {
    key: '1',
    label: 'Não',
    value: 'N',
  },
  {
    key: '2',
    label: 'Sim',
    value: 'S',
  },
];



export default YesNoArray;
