import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContentStyled,
  Content,
  Screen,
  TabsStyled,
  TabViewStyled,
  TabIconStyled,
  TabTextStyled,
} from '../../../../../common/styles/styled.layout';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconColetor,
  IconCompany,
  IconParams,
  IconQrCode,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import {
  PSTOCK_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';

import TabColetor from './TabColetor';
import TabParametros from './TabParametros';
import Tab_QrCodeScreen from './TabQrCode';
import TabUnidade from './TabUnidade';



const PSTOCK_CriacaoScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const [atualTab, setAtualTab] = useState('table_parametros');
  const [accessConfig, setAccessConfig] = useState(false);

  const [loading, setLoading] = useState(false);


  async function getPermissionConfig() {
    try {
      setLoading(true);

      const url = '/pstock/adm/configuracao/permissao';
      const response = await ApiWS().get(url);

      const permissionStatus = response.data.trim().toUpperCase() === 'Negado'.trim().toUpperCase()
        ? false
        : true;

      setAccessConfig(permissionStatus);
    }
    catch (error) {
      console.error('getPermissionConfig', JSON.stringify(error.response, null, 2));
    }
    finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getPermissionConfig();
  }, [user]);



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PSTOCK_NavRoutes}
          title={'PStock'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && !accessConfig && (
            <EmptyContent
              image={Images.error401}
              title={'Sem permissão para esta tela'}
              description={'Para ter acesso a esta tela, entre em contato com o administrador.'}
            />
          )}



          {!loading && accessConfig && (
            <TabsStyled
              id={'control-tab-pstock-adm'}
              activeKey={atualTab}
              onSelect={(tab) => setAtualTab(tab)}>

              <Tab
                eventKey={'table_parametros'}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      <IconParams />
                    </TabIconStyled>

                    <TabTextStyled>
                      Parâmetros
                    </TabTextStyled>
                  </TabViewStyled>
                }>
                {atualTab === 'table_parametros' && (
                  <TabParametros />
                )}
              </Tab>



              <Tab
                eventKey={'table_unidade'}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      <IconCompany />
                    </TabIconStyled>

                    <TabTextStyled>
                      Unidades
                    </TabTextStyled>
                  </TabViewStyled>
                }>
                {atualTab === 'table_unidade' && (
                  <TabUnidade />
                )}
              </Tab>



              <Tab
                eventKey={'table_coletor'}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      <IconColetor />
                    </TabIconStyled>

                    <TabTextStyled>
                      Coletores
                    </TabTextStyled>
                  </TabViewStyled>
                }>
                {atualTab === 'table_coletor' && (
                  <TabColetor />
                )}
              </Tab>



              <Tab
                eventKey={'table_qr'}
                title={
                  <TabViewStyled>
                    <TabIconStyled>
                      <IconQrCode />
                    </TabIconStyled>

                    <TabTextStyled>
                      Qr Code
                    </TabTextStyled>
                  </TabViewStyled>
                }>
                {atualTab === 'table_qr' && (
                  <Tab_QrCodeScreen />
                )}
              </Tab>

            </TabsStyled>
          )}

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default PSTOCK_CriacaoScreen;
