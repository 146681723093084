import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import {
  toast,
} from 'react-toastify';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';



interface IProps {
  show: boolean;

  sm?: any;
  osCopia?: any;
  filialCopia?: any;
  funcaoSolicitanteCopia?: any;

  onHide?: any;
}



const ModeloNovoModal: React.FC<IProps> = (props) => {
  const user = useSelector((state: RootState) => state.user.data);


  const [listItens, setListItens] = useState([]);


  useEffect(() => {
    props.sm.map((item) => (
      setListItens((itensList) => [
        ...itensList,
        {
          CCSOL: props.osCopia,
          CONTA: item.CP_CONTA,
          DATPRF: item.CP_DATPRF,
          DESCOMP: item.CP_DESCOMP,
          DESCRI: item.CP_DESCRI,
          DIAS: item.CP_DIAS,
          EMISSAO: item.CP_EMISSAO,
          FILIAL: props.filialCopia,
          GMOPMKS: item.CP_GMOPMKS,
          GRPENTR: item.CP_GRPENTR,
          GRUPOAT: item.CP_GRUPOAT,
          ITEM: item.CP_ITEM,
          LOCSOL: item.CP_LOCSOL,
          LOCAL: item.CP_LOCAL,
          LOGIN: user.login,
          MAILSOL: user.email,
          MODELO: '',
          OBS: item.CP_OBS,
          OBSERVA: item.CP_OBSERVA,
          ORIGPC: item.CP_ORIGPC,
          OP: item.CP_OP,
          PRECO: item.CP_PRECO,
          PRODUTO: item.CP_PRODUTO,
          QTSOLIC: item.CP_QTSOLIC,
          QUANT: item.CP_QUANT,
          SITEST: item.CP_SITEST,
          SOLICIT: user.name,
          UM: item.CP_UM,
          USER: user.id,
          statusAprovador: props.funcaoSolicitanteCopia,
        },
      ])
    ));
  }, []);


  const handleCriaModelo = (values: any) => {
    const mapListModelo = listItens.map((item) => (
      item = { ...item, MODELO: values.input_nome }
    ));

    setListItens(mapListModelo);

    ApiWS()
      .post('/sm/nova_sm/insere/modelo', listItens)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success(`Modelo ${values.input_nome} criado com sucesso`, {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          props.onHide();
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao criar o Modelo', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Criar novo Modelo de SME'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          // validationSchema={SmeAtendimentoValidationSchema}
          initialValues={{
            input_nome: '',
          }}
          onSubmit={(values) => {
            handleCriaModelo(values);
          }}>
          {({
            isValid,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>
                <TextInput
                  type={'text'}
                  name={'input_nome'}
                  label={'Nome do Modelo'}
                  placeholder={'Nome'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_nome', e.target.value.replace(/\D/, ''));
                  }}
                  onBlur={handleBlur}
                />
              </ModalBodyStyled>



              <ModalFooterStyled>
                <IButton
                  disabled={!isValid}
                  backgroundColor={Colors?.accept}
                  text={'Copiar'}
                  type={'submit'}
                />


                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Fechar'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default ModeloNovoModal;
