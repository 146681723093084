import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Colors } from '../../../../../common/constants';

interface IProps {
  size?: number;
}



export const RowStyled = styled(Row)`
`;

export const ColPhotoEmployee = styled(Col)`
  min-width: 30% !important;
  text-align: center;
  margin-right: -20%;
`;

export const ColDataEmployee = styled(Col)`
  min-width: 40% !important;
  cursor: pointer;
`;

export const LabelFlexTitle = styled.p`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const LabelFlexText = styled.span`
  font-weight: normal;
`;

export const ImgStyled = styled.img`
  margin-top: 10px;
  max-height: 200px;
  max-width: 200px;
`;

export const ColQtdStar = styled(Col)`
  min-width: 30% !important;
`;

export const LabelStyled = styled.div`
`;

export const AreaTextQtdStar = styled.div`
  background-color: ${Colors?.white};
  box-shadow: -3px 3px 7px 1px ${Colors?.gray};
  border-radius: 5px;
  min-height: 100%;
  min-width: 100%;
  margin-top: 2px;
  margin-left: 10px;
  text-align: center;
  color: ${Colors?.primary};
  font-size: ${(props: IProps) => props.size && `${props.size}em` || '1.5em'};
  padding: 10%;
`;

export const FooterRowButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 610px;
`;


