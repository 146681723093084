import React, {
  useEffect,
  useState,
} from 'react';

import {
  Container,
  Modal,
  Row,
  Form,
} from 'react-bootstrap';
import { Formik } from 'formik';


import {
  Colors,
} from '../../../../../common/constants';

import {
  Content,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import { IconClose } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';


import TextInputLabelFloat from '../../../../../components/TextFields/InputLabelFloat';

import ApiWS from '../../../../../services/api.service';
import { maskToMoney3, maskToPorcent2 } from '../../../../../utils/CurrencyUtils';

import { HeaderReleaseColRightOrUp } from '../PFF_Lancamento/PFF_Lancamento_Grid/PFF-grid-styled';
import { PffGridModel } from '../PFF_Lancamento/PFF_Lancamento_Grid/PFF-grid.model';
import { FieldsHaderStyle } from '../PFF_Lancamento/PFF_Lancamento_Realizado/PFF-realizado-styled';
import PFFDetailsScreen from '../PFF_Table-Details';
import JustificationDisapproveModal from './JustificativaReprovarPFFModal';
import { ColButtonApproval, ColButtonReturn } from './ModalStyled';



interface IProps {
  releaseType?: any;
  show?: any;
  onHide?: any;
  values?: any;
  postFetch?: any;
  isApproval?: any;
}



const ConsultaDetalhesPFFModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const [justificationModalShow, setJustificationModalShow] = useState(false);

  const dataLS = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));


  const fieldsHeadersList = () => [
    { type: 'text', name: 'TAXA_ISS', label_place: 'ISS%', value: maskToPorcent2(dataLS[1]?.CAB[0]?.IMP01) },
    { type: 'text', name: 'TAXA_PIS_COFINS', label_place: 'PIS/COFINS%', value: maskToPorcent2(dataLS[1]?.CAB[0]?.IMP02) },
    { type: 'text', name: 'TAXA_ICMS_IPI', label_place: 'ICMS/IPI%', value: maskToPorcent2(dataLS[1]?.CAB[0]?.IMP03) },
    { type: 'text', name: 'TAXA_DESONERACAO', label_place: 'Desoneração%', value: maskToPorcent2(dataLS[1]?.CAB[0]?.IMP04) },
    { type: 'text', name: 'VALOR_ORIGINAL', label_place: 'Vlr. Ori.', value: maskToMoney3(dataLS[1]?.CAB[0]?.VLORIG) },
    { type: 'text', name: 'VALOR_VIGENTE', label_place: 'Vlr. Vig.', value: maskToMoney3(dataLS[1]?.CAB[0]?.VLVIG) },
    { type: 'date', name: 'INICIO', label_place: 'Início', value: dataLS[1]?.CAB[0]?.DTINICIO },
    { type: 'number', name: 'PRAZO', label_place: 'Prazo', value: dataLS[1]?.CAB[0]?.PRAZO },
    { type: 'text', name: 'REV', label_place: 'Revisão', value: dataLS[1]?.CAB[0]?.REV },
    { type: 'text', name: 'DESCSTATUS', label_place: 'Status', value: dataLS[1]?.CAB[0]?.DESCSTATUS },
    { type: 'text', name: 'CC', label_place: 'CC', value: dataLS[1]?.CAB[0]?.CC },
    { type: 'number', name: 'ANO', label_place: 'Ano', value: dataLS[1]?.CAB[0]?.ANO },
  ];


  async function postFetch(values: any) {
    setLoading(true);
    const url = '/pff/consulta/montargrid/';

    switch (values.TIPO) {
      case 'ORÇADO':
        values.TIPO = 'OR';
        break;
      case 'PLANEJADO':
        values.TIPO = 'PL';
        break;
      case 'PREVISTO':
        values.TIPO = 'PR';
        break;
      case 'REALIZADO':
        values.TIPO = 'RE';
        break;
    };

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(resp.data));
        }
      })
      .catch((error) => {
        console.error('postFetch: /pff/montargrid/', JSON.stringify(error.response, null, 2));

        IToast({ type: 'error', message: 'Ocorreu um erro! Contate o suporte.' });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function postApprove(values: any) {
    setLoading(true);

    const url = '/pff/aprovacao/aprovar';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({ type: 'success', message: resp.data });

          setTimeout(() => {
            props.onHide();
            props.postFetch();
          }, 1000);
        }
        else {
          IToast({ type: 'warning', message: resp.data });
        }
      })
      .catch((error) => {
        console.error('postApprove: /pff/aprovacao/aprovar/', JSON.stringify(error.response, null, 2));
        IToast({ type: 'error', message: 'Ocorreu um erro! Contate o suporte.' });
      })
      .finally(() => {
        setLoading(true);
      });
  };



  function postReviewReturn(values) {
    setLoading(true);

    values = {
      ...values,
      EMPRESA: '02',
      NIVEL: props.values.NIVEL,
      NUM: props.values.NUM,
    };

    const url = '/pff/aprovacao/reprovar';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({ type: 'success', message: resp.data });

          setTimeout(() => {
            props.onHide();
            props.postFetch();
          }, 1000);
        }
        else {
          IToast({ type: 'warning', message: resp.data });
        }
      })
      .catch((error) => {
        console.error('postApprove: /pff/aprovacao/aprovar/', JSON.stringify(error.response, null, 2));
        IToast({ type: 'error', message: 'Ocorreu um erro! Contate o suporte.' });
      })
      .finally(() => {
        setLoading(true);
      });
  };



  function postTotalDisapproval(values) {
    setLoading(true);

    values = {
      ...values,
      EMPRESA: '02',
      NIVEL: props.values.NIVEL,
      NUM: props.values.NUM,
    };

    const url = '/pff/aprovacao/reprovar';
    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({ type: 'success', message: resp.data });

          setTimeout(() => {
            props.onHide();
            props.postFetch();
          }, 1000);
        }
        else {
          IToast({ type: 'warning', message: resp.data });
        }
      })
      .catch((error) => {
        console.error('postApprove: /pff/aprovacao/aprovar/', JSON.stringify(error.response, null, 2));
        IToast({ type: 'error', message: 'Ocorreu um erro! Contate o suporte.' });
      })
      .finally(() => {
        setLoading(true);
      });
  };



  useEffect(() => {
    const payload = {
      ANO: props.values?.ANO,
      TIPO: props.values.TIPO,
      CC: props.values.CC,
      STATUS: props.values.STATUS,
    };

    postFetch(payload);
  }, []);


  return (

    <Modal
      {...props}
      show={props.show}
      fullscreen
      centered>

      <>

        <TitleGroup>

          <ColButtonReturn>

            <IButton
              backgroundColor={Colors?.gray}
              text={'Fechar'}
              onClick={props.onHide}
              iconLeft={
                <IconClose
                  color={Colors?.white}
                  size={22}
                />
              }
            />

            {props.isApproval && (

              <ColButtonApproval>

                <IButton
                  disabled={loading}
                  text={'Aprovar'}
                  backgroundColor={Colors?.buttonOk}
                  type={'button'}
                  onClick={() => {
                    postApprove({
                      EMPRESA: '02',
                      NUM: props.values.NUM,
                      NIVEL: props.values.NIVEL,
                    });
                  }}
                />


                <IButton
                  disabled={loading}
                  text={'Reprovar'}
                  backgroundColor={Colors?.buttonCancel}
                  type={'button'}
                  onClick={() => {
                    setJustificationModalShow(true);
                  }}
                />

              </ColButtonApproval>

            )}

          </ColButtonReturn>


          {'Detalhes PFF'.toUpperCase()}


        </TitleGroup>


        <>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && (

            <Content>

              <Container>

                {loading &&
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary} />
                }


                {!loading && (

                  <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={PffGridModel}
                    onSubmit={() => {
                      return null;
                    }}
                  >
                    {() => (
                      <Form>


                        {/* CABECALHO */}
                        <HeaderReleaseColRightOrUp md={12}>

                          <Row md={12}>
                            {fieldsHeadersList().map((field) => (

                              <FieldsHaderStyle>

                                <TextInputLabelFloat
                                  disabled
                                  type={field.type}
                                  name={field.name}
                                  label={field.label_place}
                                  placeholder={field.label_place}
                                  value={field.value} />

                              </FieldsHaderStyle>

                            ))}

                          </Row>

                        </HeaderReleaseColRightOrUp>

                        <Row>

                          <PFFDetailsScreen releaseType={props.releaseType} loading={loading} />

                        </Row>

                      </Form>
                    )}
                  </Formik>
                )}

              </Container>

            </Content>

          )}

        </>

        {justificationModalShow && (
          <JustificationDisapproveModal
            show={justificationModalShow}
            justification={(values: any) => {
              if (values?.STATUS === '50') {
                postTotalDisapproval(values);
              }
              else if (values?.STATUS === '40') {
                postReviewReturn(values);
              }
            }}
            onHide={() => {
              setJustificationModalShow(false);
            }}
          />
        )}

      </>

    </Modal>

  );
};



export default ConsultaDetalhesPFFModal;
