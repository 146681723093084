import React, {
  useEffect, useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContainerView,
  Content,
  ContentStyled,
  Screen,
  SectionMargin,
  TableAccordionStyled, TableViewButton, TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconApproval,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';

import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';

import NameRoutes from '../../../../../navigation/name';

import {
  RP_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  formataData, formataParaInputDate,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import RPHistoricoAprovacaoModal from '../Modals/HistoricoAprovacoesRPModal';

import RPHistoricoModal from '../Modals/HistoricoRPModal';
import JustificativaReprovarModal from '../Modals/JustificativaReprovar';

import {
  FilterRpPendentContainer, TextAreaContainer,
} from './RP-aprovacao.styled';
import './styled.css';



const RP_AprovacaoScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
  });

  const disQtdVaga = [];
  const disDtNeces = [];

  const [carregando, setCarregando] = useState(false);

  const [justificationModalShow, setJustificationModalShow] = useState(false);
  const btnsHabilitados =
    <Row>

      <Col>

        <IButton
          backgroundColor={Colors?.buttonOk}
          text={'Aprovar'}
          onClick={() => {
            onclick_aprovar();
          }}
        />

      </Col>


      <Col>

        <IButton
          backgroundColor={Colors?.buttonCancel}
          text={'Reprovar'}
          onClick={() => {
            setJustificationModalShow(true);
          }}
        />

      </Col>

    </Row>;

  const btnsDesabilitados =
    <Row>

      <Col>

        <IButton
          disabled={true}
          backgroundColor={Colors?.buttonOk}
          text={'Aprovar'}
          onClick={() => {
            onclick_aprovar();
          }}
        />

      </Col>


      <Col>
        <IButton
          disabled={true}
          backgroundColor={Colors?.buttonCancel}
          text={'Reprovar'}
          onClick={() => {
            setJustificationModalShow(true);
          }}
        />

      </Col>

    </Row>;


  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RP\'s pendentes!',
    description: 'Não há nenhuma RP pendente.',
  });

  const [errorObject, setErrorObject] = useState({
    image: Images.empty,
    title: 'Sem RP\'s pendentes!',
    description: 'Não há nenhuma RP pendente.',
  });

  const [error, setError] = useState(false);


  const [telaAprovadores, setTelaHistorico] = useState(false);
  const [valueHistorico, setValueHistorico] = useState(null);

  const [listRP, setListRP] = useState([]);
  const [listAprovador, setListAprovador] = useState([]);

  const [input_qtd, setInput_qtd] = useState('');
  const [input_dtNeces, setInput_dtNeces] = useState('');
  const [textArea_obsRh, setTextArea_obsRh] = useState('');

  const [valueHistoricoAprovacao, setValueHistoricoAprovacao] = useState({});
  const [telaHistoricoAprovacao, setTelaHistoricoAprovacao] = useState(false);

  const [nivelAprovador, setNivelAprovador] = useState(null);
  const [tipoFuncao, setTipoFuncao] = useState(null);


  const findPendentes = async () => {
    setCarregando(true);
    setError(false);

    ApiWS()
      .get('/rp/aprovacao/consulta/')
      .then((resp) => {
        setListRP(resp.data);
        for (let i = 1; i < resp.data.length + 1; i++) {
          disQtdVaga.push(false);
          disDtNeces.push(false);
        }

        setNivelAprovador(resp.data[0].NIVEL);
        setTipoFuncao(resp.data[0].FUNCAO);

        setError(false);
      })
      .catch((error) => {
        console.error('findPendentes', JSON.stringify(error.response, null, 2));
        setError(true);
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const addListAprovador = (rp: any) => {
    setListAprovador((listAprovador) => [...listAprovador, rp]);
    document.querySelector(`#input-qtd-${rp.QS_VAGA}`).setAttribute('disabled', 'disabled');
    document.querySelector(`#input-dtnec-${rp.QS_VAGA}`).setAttribute('disabled', 'disabled');
    document.querySelector(`#textarea-obsrh-${rp.QS_VAGA}`).setAttribute('disabled', 'disabled');
  };

  const removeListAprovador = (rp: any) => {
    listAprovador.forEach((reqPess, index) => {
      (reqPess.QS_VAGA === rp.QS_VAGA)
        && listAprovador.splice(index, 1);
    });
    document.querySelector(`#input-qtd-${rp.QS_VAGA}`).removeAttribute('disabled');
    document.querySelector(`#input-dtnec-${rp.QS_VAGA}`).removeAttribute('disabled');
    if (nivelAprovador === '1') {
      document.querySelector(`#textarea-obsrh-${rp.QS_VAGA}`).removeAttribute('disabled');
    }
  };


  const onclick_aprovar = () => {
    const newListToBack = [];
    listAprovador.forEach((requisicao) => {
      requisicao.NRVAGAOLD = requisicao.NRVAGA;
      requisicao.DTNECESOLD = requisicao.DTNECES;

      newListToBack.push({
        'DTNECES': input_dtNeces !== '' ? input_dtNeces : requisicao.DTNECESOLD,
        'DTNECESOLD': requisicao.DTNECESOLD,
        'NRVAGA': input_qtd !== '' ? parseInt(input_qtd) : requisicao.NRVAGAOLD,
        'NRVAGAOLD': requisicao.NRVAGAOLD,
        'QS_OBSERVR': requisicao.QS_OBSERVR,
        'QS_VAGA': requisicao.QS_VAGA,
        'NIVEL': requisicao.NIVEL,
        'QS_TPRP': requisicao.QS_TPRP,
      });
    });

    setCarregando(true);
    setError(false);

    ApiWS()
      .post('/rp/aprovacao/aprovar', newListToBack)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('RP Aprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

          setError(false);
        }
      })
      .catch((error) => {
        console.error('aprovacaoRP', JSON.stringify(error.response, null, 2));

        setError(true);
      })
      .finally(() => {
        setListAprovador([]);
        findPendentes();
        setCarregando(false);
      });
  };

  const onclick_reprovar = async (listAprovador) => {
    const newListToBack = [];
    listAprovador.forEach((requisicao) => {
      newListToBack.push({
        'QS_VAGA': requisicao.QS_VAGA,
        'NIVEL': requisicao.NIVEL,
        'NRVAGA': requisicao.NRVAGA,
        'JUST': requisicao.JUST,
        // 'statusAprovador': requisicao.statusAprovador,
      });
    });

    // console.log(newListToBack);

    setError(false);
    setCarregando(true);
    ApiWS()
      .post('/rp/aprovacao/reprovar', newListToBack)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('RP Reprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error('reprovacaoRP', JSON.stringify(error.response, null, 2));

        setError(true);
      })
      .finally(() => {
        setListAprovador([]);
        findPendentes();
        setCarregando(false);
      });
  };


  const retornaAprovador = (status: any) => {
    switch (status) {
      case 'RRO':
        return 'APROVADOROS';
      case 'FINAL':
        return 'APROVADORFINAL';
      case 'RH':
        return 'APROVADORRH';
      case 'RROFINAL ':
        return 'APROVADOROSFINAL';
      case 'CA':
        return 'APROVADORCA';
      default:
        return 'ERRO::: SEM STATUS OU DIFERENTE';
    }
  };

  function handleReprovar(justificativa: string) {
    listAprovador.length > 0 &&
      listAprovador.forEach((rp) => {
        rp.JUST = justificativa;
      });

    onclick_reprovar(listAprovador);
  };


  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/rp/impressao/${values.QS_VAGA}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  useEffect(() => {
    findPendentes();
  }, []);


  return (
    <Screen>

      {/* {JSON.stringify(listRP)} */}

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={RP_NavRoutes}
          title={'RP'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && !error && listRP.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}


          {!carregando && !error && listRP.length > 0 && (
            <ContainerView>

              <SectionMargin>
                <TitleGroup>
                  {'RP\'s Pendentes de aprovação'.toUpperCase()}
                </TitleGroup>


                <FilterRpPendentContainer>

                  {listAprovador.length > 0
                    ? (btnsHabilitados)
                    : (btnsDesabilitados)
                  }

                </FilterRpPendentContainer>



                <TableAccordionStyled>
                  <thead>
                    <tr>
                      <th></th>
                      <th>CC</th>
                      <th>RP</th>
                      <th>Tipo</th>
                      <th>Solicitante</th>
                      <th>Função</th>
                      <th>Desd. Função</th>
                      <th>Qtd.</th>
                      <th>Dt. Abertura</th>
                      <th>Dt. Prev.</th>
                      <th>Dt. Neces.</th>
                      <th>Obs. RH</th>
                      {/* <th>Aprovação</th> */}
                      <th colSpan={5}>Ações</th>
                    </tr>
                  </thead>


                  <tbody>
                    {listRP.map((rp, index) => (
                      <>
                        <tr key={index}>
                          <td className="actions">
                            <TableViewButton>
                              <input
                                type='checkbox'
                                name={`chkRp${rp.QS_VAGA}`}
                                onChange={(e) => {
                                  const requisicao = {
                                    'QS_VAGA': rp.QS_VAGA,
                                    'NRVAGA': rp.QS_NRVAGA,
                                    'NRVAGAOLD': rp.QS_NRVAGA,
                                    'DTNECES': rp.QS_DTNECES,
                                    'DTNECESOLD': rp.QS_DTNECES,
                                    'QS_OBSERVR': textArea_obsRh,
                                    'statusAprovador': retornaAprovador(rp.TIPO),
                                    'NIVEL': rp.NIVEL,
                                    'QS_TPRP': rp.QS_TPRP,
                                  };
                                  e.target.checked ? addListAprovador(requisicao) : removeListAprovador(requisicao);
                                }}
                              />
                            </TableViewButton>
                          </td>


                          {/* OS */} <td>{rp.QS_CC}</td>
                          {/* RP */} <td>{rp.QS_VAGA}</td>
                          {/* TIPO */} <td>{rp.QS_TPRP}</td>
                          {/* Solicitante */} <td>{rp.QS_SOLICIT}</td>
                          {/* Função */} <td>{rp.QS_DESCARG}</td>
                          {/* Desd. Função */} <td>{rp.QS_DESCRIC}</td>
                          {/* Qtd. */} <input
                            style={{ marginTop: 2 }}
                            id={`input-qtd-${rp.QS_VAGA}`}
                            type="number"
                            min={1}
                            max={rp.QS_NRVAGA !== undefined ? parseInt(rp.QS_NRVAGA) : 0}
                            defaultValue={rp.QS_NRVAGA}
                            onChange={(e) => {
                              if ((parseInt(e.target.value) > parseInt(rp.QS_NRVAGA) || parseInt(e.target.value) <= 0) && e.target.value !== undefined) {
                                setMessageValue({
                                  head: 'Erro!',
                                  orient: 'A Quantidade para aprovar não pode ser maior que a solicitada ou menor que 1.',
                                });
                                setMessageModal(true);
                                setInput_qtd(rp.QS_NRVAGA);
                                e.target.value = rp.QS_NRVAGA;
                              }
                              else {
                                setInput_qtd(e.target.value);
                              }
                            }}
                            disabled={false}
                          />

                          {/* Dt. Abertura */} <td>{formataData(rp.QS_DTABERT)}</td>
                          {/* Dt. Prev. */} <td>{formataData(rp.QS_DTPREVI)}</td>
                          {/* Dt. Nesc. */} <input
                            id={`input-dtnec-${rp.QS_VAGA}`}
                            type="date"
                            min={formataParaInputDate(rp.QS_DTPREVI)}
                            defaultValue={formataParaInputDate(rp.QS_DTNECES)}
                            disabled={disDtNeces[index]}
                            onBlur={(e) => {
                              const date_dtNeces = new Date(e.target.value);
                              const date_datPrev = new Date(formataParaInputDate(rp.QS_DTPREVI));

                              if (date_dtNeces >= date_datPrev) {
                                setInput_dtNeces(e?.target?.value?.replaceAll('-', ''));
                              }
                              else {
                                setInput_dtNeces(rp.QS_DTNECES);
                                e.target.value = formataParaInputDate(rp.QS_DTNECES);
                                setMessageValue({
                                  head: 'Erro!',
                                  orient: 'A Dt. Neces. deve ser maior ou igual a Dr. Prev.',
                                });
                                setMessageModal(true);
                              }
                            }}
                          />

                          {/* Obs. RH */} <td>
                            <TextAreaContainer
                              disabled={tipoFuncao !== 'RH'}
                              id={`textarea-obsrh-${rp.QS_VAGA}`}
                              rows={1}
                              defaultValue={rp.QS_OBSERVR && rp.QS_OBSERVR}
                              onChange={(e) => {
                                setTextArea_obsRh(e.target.value);
                              }}
                            />
                          </td>

                          {/* Aprovação <td>
                            {{
                              'RRO': 'Aprovação OS',
                              'FINAL': 'Aprovação Final',
                              'RH': 'Aprovação RH',
                              'RROFINAL': 'Aprovação OS e Final',
                              'CA': 'Aprovação CA',
                            }[rp?.TIPO?.replaceAll(' ', '')]}
                          </td>
                          */}

                          {/* Botão Observacao */}
                          <td className={'actions'}>
                            <ButtonTableAction
                              tooltip={
                                rp.QS_OBSERVA
                                  && rp.QS_OBSERVA !== null
                                  && rp.QS_OBSERVA !== ''
                                  && rp.QS_OBSERVA !== undefined
                                  ? rp.QS_OBSERVA
                                  : TextTooltip.NO_OBSERVATION
                              }
                              icon={
                                <IconInfoSquare
                                  color={
                                    rp.QS_OBSERVA
                                      && rp.QS_OBSERVA !== null
                                      && rp.QS_OBSERVA !== ''
                                      && rp.QS_OBSERVA !== undefined
                                      ? Colors?.black
                                      : Colors?.gray
                                  }
                                  size={16}
                                />
                              }
                            />
                          </td>


                          {/* Botão Impressao */}
                          <td className="actions">
                            <ButtonTableAction
                              tooltip={TextTooltip.PRINTER}
                              onClick={() => {
                                postPrinter(rp);
                              }}
                              icon={
                                <IconPrinter
                                  color={Colors?.black}
                                  size={16}
                                />
                              }
                            />
                          </td>


                          {/* Botão Modal Historico */}
                          <td>
                            {
                              <ButtonTableAction
                                tooltip={TextTooltip.HISTORIC}
                                onClick={async () => {
                                  setTimeout(() => {
                                    setValueHistorico(rp);
                                  }, 300);
                                  setTelaHistorico(true);
                                }}
                                icon={
                                  <IconHistory
                                    color={Colors?.black}
                                    size={16}
                                  />
                                }
                              />
                            }
                          </td>


                          {/* Botão Modal Historico Aprovadores */}
                          <td>
                            {
                              <ButtonTableAction
                                tooltip={TextTooltip.APPROVALS}
                                onClick={() => {
                                  setValueHistoricoAprovacao({ CC: rp.QS_CC, NUM: rp.QS_VAGA });
                                  setTelaHistoricoAprovacao(true);
                                }}
                                icon={
                                  <IconApproval
                                    color={Colors?.black}
                                    size={16}
                                  />
                                }
                              />
                            }
                          </td>

                        </tr>
                      </>
                    ))}
                  </tbody>

                </TableAccordionStyled>

              </SectionMargin>

            </ContainerView>
          )}


          {!carregando && error && (
            <>
              <EmptyContent
                image={errorObject.image}
                title={errorObject.title}
                description={errorObject.description}
              />
            </>
          )}

        </ContentStyled>
      </Content>


      {valueHistorico && (
        <RPHistoricoModal
          show={telaAprovadores}
          todo={valueHistorico}
          onHide={() => {
            setTelaHistorico(false);
            setTimeout(() => {
              setValueHistorico(null);
            }, 300);
          }}
        />
      )}


      <ConfirmationModal
        show={messageModal}
        head={messageValue.head}
        orient={messageValue.orient}
        negative={() => {
          setMessageModal(false);
        }}
      />



      {valueHistoricoAprovacao && (
        <RPHistoricoAprovacaoModal
          show={telaHistoricoAprovacao}
          value={valueHistoricoAprovacao}
          onHide={() => {
            setTelaHistoricoAprovacao(false);
            setTimeout(() => {
              setValueHistoricoAprovacao(null);
            }, 300);
          }}
        />
      )}


      {justificationModalShow && (
        <JustificativaReprovarModal
          show={justificationModalShow}
          dadosComp={''}
          onHide={() => setJustificationModalShow(false)}
          justificativa={(just: string) => {
            handleReprovar(just);
          }}
        />
      )}

    </Screen>

  );
};



export default RP_AprovacaoScreen;
