import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import { Colors, Images } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconExcel, IconPrinter } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import NameRoutes from '../../../../../navigation/name';
import { PIC_NavRoutes } from '../../../../../navigation/navs/SGI';
import ApiWS from '../../../../../services/api.service';
import { formataData } from '../../../../../utils/DateUtils';
import { returnErrorObject } from '../../../../../utils/ErrorUtils';
import { ordenaListaRecentePrimeiro } from '../../../../../utils/ListUtils';
import RelatorioPICModal from '../Modals/RelatorioPICModal';
import IPicConsultaModel from './PIC-consulta.model';
import { PICConsultaValidationSchema } from './PIC-consulta.validation';



const PIC_CadastroEquipeScreen: React.FC = () => {
  const [listLoading, setListLoading] = useState(false);
  const [listOs, setListOs] = useState([]);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem PIC!',
    description: 'Não há nenhum registro aqui',
  });

  const [listPics, setListPics] = useState([]);
  const [showList, setShowList] = useState(false);


  const [listReport, setListReport] = useState([]);
  const [showModalReport, setShowModalReport] = useState(false);



  async function getOsFetch() {
    setListLoading(true);
    try {
      const url = '/pic/cartao/consulta/os';
      const resp = await ApiWS().get(url);

      resp.data.map((resp: any, index: any) => (
        setListOs((listOs) => [...listOs, { key: index, label: resp.OS.trim(), value: resp.OS.trim() }])
      ));
    }
    catch (error) {
      console.error('getOsFetch', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
    }
  };


  async function postPicFetch(values: any) {
    if (values.DTINI > values.DTFIM) {
      setMessageValue({
        head: 'Erro!',
        orient: 'A Data Início não pode ser maior que a Data Fim.',
        orient2: 'Por favor revise as Datas!',
      });
      setMessageModal(true);
    }
    else {
      try {
        setListLoading(true);

        const url = '/pic/cartao/consulta';
        const resp = await ApiWS().post(url, values);

        setListPics(ordenaListaRecentePrimeiro(resp.data));
      }
      catch (error) {
        console.error('postPicFilter', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor.',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      }
      finally {
        setListLoading(false);
        setShowList(true);
      }
    }
  };


  useEffect(() => {
    getOsFetch();
  }, []);

  const columns = [
    {
      dataField: 'ID',
      text: 'Nro. PIC',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'AREA',
      text: 'Area',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'AUDITOR',
      text: 'Auditor',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'MATRICULA',
      text: 'Matricula',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell === null ? '' : cell,
    },
    {
      dataField: 'EMISSOR',
      text: 'Cadastrado por',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => formataData(cell),
      formatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: '',
      text: 'Imprimir',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              window.open(`/${NameRoutes.PIC_Consulta_Impressao}/${row.ID}`, '_blank').focus();
            }}
          />
        );
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Relatório',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.EXPORT}
            icon={
              <IconExcel
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setListLoading(true);

              ApiWS()
                .post('/pic/relatorio/consulta/', [{ NUM: row.ID }])
                .then((resp) => {
                  setListReport(resp.data);
                  setShowModalReport(true);
                })
                .catch((error) => {
                  console.error('Erro ao exportar Relatório:::', error);
                  IToast({
                    type: 'error',
                    message: 'Erro ao exportar relatório. Tente novamente, caso o erro persista, contate o suporte!',
                  });
                })
                .finally(() => {
                  setListLoading(false);
                });
            }}
          />
        );
      },
      csvExport: false,
    },

  ];


  const options = {
    custom: true,
    totalSize: listPics.length,
  };



  return (
    <ToolkitProvider
      keyField={'id'}
      data={listPics}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.PIC_Cadastro_Equipe,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (
        <Screen>

          <NavHeader>
            <NavUser backHome />

            <NavComp
              navKey={2}
              navArray={PIC_NavRoutes}
              title={'PIC'}
              subtitle={'Programa de Inspeção Comportamental'}
            />
          </NavHeader>

          <Content>
            <ContentStyled fluid>

              <ContainerView>

                <SectionMargin>
                  <TitleGroup>
                    {'Consultar PIC'.toUpperCase()}
                  </TitleGroup>


                  {listLoading &&
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  }

                  <SectionMargin>
                    <Formik
                      enableReinitialize
                      validateOnMount
                      validationSchema={PICConsultaValidationSchema}
                      initialValues={IPicConsultaModel}
                      onSubmit={(values) => {
                        values.AREA = values.AREA === ''
                          ? 'TODOS'
                          : values.AREA;
                        postPicFetch([{
                          ...values,
                          DTINI: values.DTFIM && values.DTINI.replaceAll('-', ''),
                          DTFIM: values.DTFIM && values.DTFIM.replaceAll('-', ''),
                        }]);
                      }}
                    >
                      {({
                        isValid,
                        values,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        resetForm,
                        setValues,
                      }) => (

                        <FilterContainerForm auto onSubmit={handleSubmit}>

                          <Row>
                            <Col>
                              <DropdownInput
                                disabled={listLoading}
                                name={'CC'}
                                label={'Centro de Custo'}
                                dropArray={[
                                  {
                                    key: '0',
                                    label: 'Selecione o Centro de Custo',
                                    value: ' ',
                                  },
                                  ...listOs,
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.CC}
                              />
                            </Col>
                          </Row>


                          <Row>


                            <Col>
                              <TextInput
                                disabled={listLoading}
                                type={'text'}
                                name={'AREA'}
                                label={'Área/Setor Auditado'}
                                placeholder={'Área/Setor Auditado'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.AREA.toLocaleUpperCase()}
                              />
                            </Col>

                          </Row>


                          <Row>

                            <Col>
                              <TextInput
                                disabled={listLoading}
                                type={'text'}
                                name={'AUDITOR'}
                                label={'Auditor'}
                                placeholder={'Auditor'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.AUDITOR.toLocaleUpperCase()}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={listLoading}
                                type={'text'}
                                name={'MATRICULA'}
                                label={'Mátricula'}
                                placeholder={'Mátricula'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.MATRICULA}
                              />
                            </Col>

                          </Row>


                          <Row>

                            <Col>
                              <TextInput
                                disabled={listLoading}
                                type={'date'}
                                name={'DTINI'}
                                label={'Data Início'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTINI}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={listLoading}
                                type={'date'}
                                name={'DTFIM'}
                                label={'Data Fim'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTFIM}
                              />
                            </Col>

                          </Row>

                          <Row>

                            <RowButtonFiltersView>

                              <IButton
                                disabled={listPics.length === 0}
                                backgroundColor={Colors?.excel}
                                text={'Exportar'}
                                onClick={() => {
                                  propsTkProvider.csvProps.onExport();
                                }}
                              />

                              <IButton
                                text={'Limpar'}
                                backgroundColor={Colors?.gray}
                                type={'reset'}
                                onClick={() => {
                                  resetForm();

                                  setValues(IPicConsultaModel);
                                }}
                              />


                              <IButton
                                disabled={listLoading}
                                text={'Filtrar'}
                                backgroundColor={Colors?.primary}
                                type={'submit'}
                                onClick={() => {
                                  if (!isValid) {
                                    setMessageValue({
                                      head: 'Erro!',
                                      orient: 'Existem campos obrigatórios que não foram preenchidos.',
                                      orient2: 'Por favor revise o formulário!',
                                    });
                                    setMessageModal(true);
                                  }
                                }}
                              />

                            </RowButtonFiltersView>

                          </Row>

                        </FilterContainerForm>

                      )}
                    </Formik>
                  </SectionMargin>



                  <ContainerView>

                    {listLoading && (
                      <LoadingsComponent
                        type={'SCREEN'}
                        color={Colors?.tertiary}
                      />
                    )}


                    {!listLoading && showList && listPics.length === 0 && (
                      <EmptyContent
                        image={emptyObject.image}
                        title={emptyObject.title}
                        description={emptyObject.description}
                      />
                    )}


                    {!listLoading && showList && listPics && listPics.length > 0 && (
                      <PaginationProvider
                        pagination={paginationFactory(options)}>
                        {({
                          paginationProps,
                          paginationTableProps,
                        }) => (
                          <TableCuston
                            noQuantity={listPics.length < 10}
                            data={listPics}
                            columns={columns}
                            baseProps={propsTkProvider.baseProps}
                            paginationProps={paginationProps}
                            paginationTableProps={paginationTableProps}
                          />
                        )}
                      </PaginationProvider>
                    )}


                  </ContainerView>

                </SectionMargin>

              </ContainerView>

            </ContentStyled>
          </Content>

          {messageModal && (
            <ConfirmationModal
              show={messageModal}
              head={messageValue.head}
              orient={messageValue.orient}
              orient2={messageValue.orient2}
              negative={() => {
                setMessageModal(false);
              }}
            />
          )}


          {showModalReport && listReport && listReport.length > 0 && (
            <RelatorioPICModal
              onHide={() => {
                setShowModalReport(false);
                setTimeout(() => {
                  setListReport([]);
                }, 300);
              }}
              show={showModalReport}
              values={listReport}
            />
          )}

        </Screen>

      )}
    </ToolkitProvider>

  );
};



export default PIC_CadastroEquipeScreen;
