/* eslint-disable react/no-deprecated */
import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';

import App from './App';



dotenv.config();
ReactDOM.render(<App /> as any, document.getElementById('root'));
