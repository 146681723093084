import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../utils/StringUtils';



const SmeTriagemFiltrarValidationSchema = Yup.object().shape({

  select_grupo_origem: Yup.string()
    .required('Campo obrigatório'),



  input_os: Yup.string(),
  // .length(Metrics.input_centroCusto, Messages.input_centroCusto),



  input_descricao: Yup.string(),



  input_solicitante: Yup.string(),



  input_num_sm: Yup.string()
    // .length(Metrics.input_sme, Messages.input_sme)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  radio_os_type: Yup.string(),

  select_grupo_produto: Yup.string(),

});


const SmeTriagemTransferirValidationSchema = Yup.object().shape({

  select_grupo_destino: Yup.string()
    .required('Campo obrigatório'),



  input_obs_destino: Yup.string(),

});



export {
  SmeTriagemFiltrarValidationSchema,
  SmeTriagemTransferirValidationSchema,
};
