import styled from 'styled-components';



export const WarningLimitSMEView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff3cd;
  padding: 16px;
  margin: 15px 0;
  border-radius: 0.25rem;
`;

export const WarningLimitTitle = styled.h5`
  margin: 0;
  color: #664d03;
  font-size: 1.25rem;
`;

export const WarningLimitSeparator = styled.div`
  width: 2px;
  height: 100%;
  margin: 0 1rem;
  background-color: #664d03;
`;

export const WarningLimitText = styled.p`
  margin: 0;
  color: #664d03;
  font-size: 1.063rem;
`;
