const PStockFuncionarioModel = {
  input_matricula: '',
  input_nome: '',
  input_cpf: '',
  input_cc: 'Todos'.toLocaleUpperCase(),
};



export default PStockFuncionarioModel;
