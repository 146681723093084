import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router';

import {
  useLocation,
} from 'react-router-dom';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import { SmeMotivoManutencao, SmeOrientacaoArray, SmeTipoManutencao, SmeUtilizacaoArray } from '../../../../../../common/arrays/SmeSolicitacao';
import {
  Colors,
  Metrics,
} from '../../../../../../common/constants';

import { TextTooltip } from '../../../../../../common/constants/TextTooltips';
import {
  Content,
  ContentStyled,
  FilterContainerForm,
  Screen,
  SectionMargin,
  SubTitleGroup,
  TableDefaultStyled,
  TitleGroup,
  RowObservationView,
} from '../../../../../../common/styles/styled.layout';


import ButtonTableAction from '../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../components/Buttons/IButton';
import Warning from '../../../../../../components/Contents/Warning';
import NavHeader from '../../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../../components/Headers/NavUser';

import {
  IconBranch,
  IconEdit,
  IconHistory,
  IconSearch,
  IconStatusNormal,
  IconStatusRegular,
  IconStatusUrgent,
  IconTrash,
} from '../../../../../../components/Icons';

import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../components/Modals/Confirmation';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import INewSMEStateType from '../../../../../../interfaces/new-state-type.interface';

import NameRoutes from '../../../../../../navigation/name';

import ApiWS, { defaultBaseURL } from '../../../../../../services/api.service';

import {
  RootState,
} from '../../../../../../store';

import {
  formataData,
} from '../../../../../../utils/DateUtils';

import SmeAnexosModal from '../../Modals/AnexoSmeModal';
import ModeloNovoModal from '../../Modals/ModeloNovoModal';
import OpModal from '../../Modals/OpModal';
import ProdutosModal from '../../Modals/ProdutosModal';

import { SmeSolicitacaoOP, SmeSolicitacaoObjProduto, SmeSolicitacaoAcoesModel } from './SME-solicitacao.model';
import {
  AreaManutencaoFrotaStyle,
  ButtonCopySMEContainer,
  ButtonFinishSMEContainer,
  GenerateButtonsSMEView,
} from './SME-solicitacao.styled';
import SmeSolicitacaoAcoesValidationSchema from './SME-solicitacao.validation';



const SME_NovaScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const history = useHistory();


  const user = useSelector((state: RootState) => state.user.data);


  const { state } = useLocation<INewSMEStateType>();


  const [carregando, setCarregando] = useState(false);

  const [listItens, setListItens] = useState([]);

  const [funcaoSolicitante, setFuncaoSolicitante] = useState('');

  const [selectCodUtilizacao, setSelectCodUtilizacao] = useState('');
  const [selectCodOrientacao, setSelectCodOrientacao] = useState('');
  const [CP_JUST, setCP_JUST] = useState('');
  const [stateCP_CLVL, setStateCP_CLVL] = useState('');
  const [selectCodCP_TPMANUT, setSelectCodCP_TPMANUT] = useState('');
  const [selectCodCP_MOTM, setSelectCodCP_MOTM] = useState('');
  const [CP_TPMANUT, setCP_TPMANUT] = useState('');
  const [CP_MOTM, setCP_MOTM] = useState('');

  const [codProduto, setCodProduto] = useState('');
  const [inputProduto, setInputProduto] = useState('');
  const [inputUnidade, setInputUnidade] = useState('');
  const [inputLimite, setInputLimite] = useState('');
  const [inputQuantidade, setInputQuantidade] = useState(0);
  const [inputDescComp, setInputDescComp] = useState('');
  const [inputObs, setInputObs] = useState('');
  const [inputPrazo, setInputPrazo] = useState(0);
  const [objProduto, setObjProduto] = useState(SmeSolicitacaoObjProduto);

  const [inputOp, setInputOp] = useState('');
  const [opValue, setOpValue] = useState(null);

  const [produtosModalShow, setProdutosModalShow] = useState(false);

  const [opModalShow, setOpModalShow] = useState(false);

  const [newModelModalShow, setNewModelModalShow] = useState(false);

  const [progressModalShow, setProgressModalShow] = useState(false);
  const [valueProgressModal, setValueProgressModal] = useState(null);

  const [createSMEModalShow, setCreateSMEModalShow] = useState(false);

  const [telaAnexo, setTelaAnexo] = useState(false);
  const [valueAnexo, setValueAnexo] = useState(null);

  const [naoEstaEditando, setNaoEstaEditando] = useState(true);



  const handleAddCar = (values: any) => {
    const numItem = (`0${listItens.length + 1}`).slice(-2);



    let var_op = '';
    if (opValue) {
      var_op = (opValue.C2_NUM + opValue.C2_ITEM + opValue.C2_SEQUEN);
    }
    else {
      var_op = (SmeSolicitacaoOP.C2_NUM + SmeSolicitacaoOP.C2_ITEM + SmeSolicitacaoOP.C2_SEQUEN);
    };


    const { dataAtual, mesAtual, anoAtual } = retornaDataAtual();

    editaOBSERVAItem(values.input_obs);

    setListItens((listItens) => [
      ...listItens,
      {
        CP_CCSOL: state.os,
        CP_CONTA: objProduto.B1_CONTA,
        CP_DATPRF: inputLimite.replaceAll('-', ''),
        CP_DESCOMP: values.input_desc_comp,
        CP_DESCRI: values.input_poduto,
        CP_DIAS: values.input_prazo,
        CP_EMISSAO: anoAtual + '' + mesAtual + '' + dataAtual,
        CP_FILIAL: state.filial,
        CP_GMOPMKS: var_op,
        CP_GRPENTR: objProduto.BM_GRPENTR,
        CP_GRUPOAT: objProduto.B1_GRUPO,
        CP_ITEM: numItem,
        CP_LOCSOL: objProduto.B1_LOCPAD,
        CP_LOCAL: objProduto.B1_LOCPAD,
        CP_LOGIN: user.login,
        CP_MAILSOL: user.email,
        CP_OBS: selectCodUtilizacao,
        CP_OBSERVA: values.input_obs,
        CP_ORIGPC: selectCodOrientacao,
        CP_OP: var_op,
        CP_PRECO: objProduto.B1_UPRC,
        CP_PRODUTO: codProduto,
        CP_QTSOLIC: inputQuantidade,
        CP_QUANT: inputQuantidade,
        CP_SITEST: objProduto.B1_SITEST,
        CP_UM: inputUnidade,
        CP_statusAprovador: funcaoSolicitante,
        CP_JUST: CP_JUST,
        CP_CLVL: stateCP_CLVL,
        CP_TPMANUT: selectCodCP_TPMANUT,
        CP_MOTM: selectCodCP_MOTM,
      },
    ]);
  };


  const handleAddCarCopy = () => {
    setCarregando(true);


    const funcao = setSolicitanteFuncao(state.funcaoSolicitante);
    setFuncaoSolicitante(funcao);

    if (state.tipo !== 'NOVA' && state.sme.length > 0) {
      setSelectCodUtilizacao(state.utilizacao);
      setSelectCodOrientacao(state.orientacao);
      setInputObs(state.sme[0].CP_OBSERVA);
      setInputLimite(handleOrientacaoPrazo(state.orientacao));

      if (state.orientacao === '07' || state.orientacao === '7') {
        setInputPrazo(7);
      }
      else {
        setInputPrazo(15);
      }


      const { dataAtual, mesAtual, anoAtual } = retornaDataAtual();

      console.log(state.sme);
      state.sme.map((item) => (

        setListItens((listItens) => [
          ...listItens,
          {
            CP_CCSOL: state.os,
            CP_CONTA: item.CP_CONTA,
            CP_DATPRF: handleOrientacaoPrazo(state.orientacao).replaceAll('-', ''),
            CP_DESCOMP: item.CP_DESCOMP,
            CP_DESCRI: item.CP_DESCRI,
            CP_DIAS: (state.orientacao === '07' || state.orientacao === '7') ? 7 : 15,
            CP_EMISSAO: anoAtual + '' + mesAtual + '' + dataAtual,
            CP_FILIAL: state.filial,
            CP_GMOPMKS: item.CP_GMOPMKS,
            CP_GRPENTR: item.CP_GRPENTR,
            CP_GRUPOAT: item.CP_GRUPOAT,
            CP_ITEM: item.CP_ITEM,
            CP_LOCSOL: item.CP_LOCSOL,
            CP_LOCAL: item.CP_LOCAL,
            CP_OBS: state.utilizacao,
            CP_OBSERVA: inputObs,
            CP_ORIGPC: inputPrazo,
            CP_OP: item.CP_OP,
            CP_PRECO: item.CP_PRECO,
            CP_PRODUTO: item.CP_PRODUTO,
            CP_QTSOLIC: parseInt(item.CP_QTSOLIC),
            CP_QUANT: parseInt(item.CP_QUANT),
            CP_SITEST: item.CP_SITEST,
            CP_UM: item.CP_UM,
            CP_statusAprovador: funcao,
            CP_JUST: CP_JUST,
            CP_CLVL: stateCP_CLVL,
            CP_TPMANUT: selectCodCP_TPMANUT,
            CP_MOTM: selectCodCP_MOTM,
          },
        ])
      ));
    };

    setCarregando(false);
  };


  const retornaPrazo = (orientacao) => {
    const dt = new Date();
    dt.setMonth(dt.getMonth());
    setInputPrazo(parseInt(orientacao));
    dt.setDate(dt.getDate() + parseInt(orientacao));

    const data = (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate());
    const mes = ((dt.getMonth() + 1) < 10 ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1));
    const ano = dt.getFullYear();

    return { data: data, mes: mes, ano: ano };
  };


  const handleOrientacaoPrazo = (orientacao) => {
    const { data, mes, ano } = retornaPrazo(orientacao);
    return ano + '-' + mes + '-' + data;
  };


  const retornaDataAtual = () => {
    const dt = new Date();
    const data = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    const mes = (dt.getMonth() + 1) < 10 ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1);
    const ano = dt.getFullYear();

    return { dataAtual: data, mesAtual: mes, anoAtual: ano };
  };


  function retornaDataFormatada(data: string) {
    let dataFormatada = '';

    if (data !== '') {
      dataFormatada = data.substring(8, 10);
      dataFormatada += '/' + data.substring(5, 7);
      dataFormatada += '/' + data.substring(0, 4);
    }
    return dataFormatada;
  };


  const handleRemoveItem = (numItem) => {
    const temp = [...listItens];
    temp.splice(numItem, 1);

    temp.forEach((item, index) => {
      item.CP_ITEM = (`0${index + 1}`).slice(-2);
    });

    setListItens(temp);
  };


  const handleEditaItem = (item) => {
    naoEstaEditando
      ? setNaoEstaEditando(false)
      : setNaoEstaEditando(true);

    naoEstaEditando
      ? document.getElementById(`qtd-${item}`).removeAttribute('disabled')
      : document.getElementById(`qtd-${item}`).setAttribute('disabled', 'disabled');

    naoEstaEditando
      ? document.getElementById(`desc-${item}`).removeAttribute('disabled')
      : document.getElementById(`desc-${item}`).setAttribute('disabled', 'disabled');
  };


  const editaOBSERVAItem = (newObserva) => {
    const temp = [...listItens];

    temp.forEach((element) => {
      element.CP_OBSERVA = newObserva;
    });
    setListItens(temp);
  };


  const editaDESCOMPItem = (newDesc, seqItem) => {
    const temp = [...listItens];

    temp.forEach((element) => {
      if (element.CP_ITEM === seqItem) {
        element.CP_DESCOMP = newDesc;
      }
    });
    setListItens(temp);
  };


  const editaQTSOLICItem = (newQtd, seqItem) => {
    const temp = [...listItens];

    temp.forEach((element) => {
      if (element.CP_ITEM === seqItem) {
        element.CP_QTSOLIC = parseInt(newQtd);
        element.CP_QUANT = parseInt(newQtd);
      }
    });
    setListItens(temp);
  };


  const handleGeraSM = async () => {
    setCarregando(true);

    listItens.forEach((item) => {
      item.CP_OBSERVA = inputObs;
      item.CP_ORIGPC = selectCodOrientacao;
    });

    ApiWS()
      .post('/sm/nova_sm/insere', listItens)
      .then((resp) => {
        if (resp.status === 200
          && (resp.data !== '' || resp.data !== null || resp.data !== undefined)) {
          setProgressModalShow(true);
          setValueProgressModal(resp.data);
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao criar SME. Contate o suporte!', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
        setCreateSMEModalShow(false);
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  const setSolicitanteFuncao = (funcaoData: string) => {
    switch (funcaoData) {
      case 'SOL':
        return 'SOLICITANTE';

      case 'RRO':
        return 'APROVADOROS';

      case 'GESTOR':
        return 'APROVADOROSFINAL';

      default:
        return 'Erro FUNCAO SOLICITANTE';
    }
  };

  const [dropUtilizacao, setDropUtilizacao] = useState([]);

  const postDropUtilizacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/utilizacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        setDropUtilizacao((dropUtilizacao) => [...dropUtilizacao, { key: item.X5_CHAVE, label: item.X5_DESCRI, value: item.X5_CHAVE }]);
      });
    }
    catch (error) {
      console.log('postDropUtilizacao', JSON.stringify(error.response, null, 2));
    }
  };


  const [dropOrientacao, setDropOrientacao] = useState([]);

  const postDropOrientacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/orientacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        setDropOrientacao((dropOrientacao) => [...dropOrientacao, { key: item.ID, label: item.DESCR, value: `${String(item.COD)?.trim()};${String(item.PRAZO)?.trim()}` }]);
      });
    }
    catch (error) {
      console.log('postDropOrientacao', JSON.stringify(error.response, null, 2));
    }
  };


  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/sme/atendimento/impressao/${values.FILIAL}/${values.NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  useEffect(() => {
    handleAddCarCopy();
    postDropUtilizacao();
    postDropOrientacao();
  }, []);



  return (

    <Screen>

      <NavHeader>
        <NavUser back />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          <SectionMargin>

            <TitleGroup>
              {state.tipo === 'NOVA' ? 'Criar SME'.toUpperCase() : 'Copiar SME'.toUpperCase()}
            </TitleGroup>

            {!carregando && <>
              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={SmeSolicitacaoAcoesValidationSchema}
                  initialValues={SmeSolicitacaoAcoesModel(
                    selectCodUtilizacao,
                    selectCodOrientacao,
                    CP_JUST,
                    CP_TPMANUT,
                    CP_MOTM,
                    stateCP_CLVL,
                    inputObs,
                    inputProduto,
                    inputUnidade,
                    inputQuantidade,
                    inputDescComp,
                    inputLimite,
                    inputPrazo,
                    inputOp,
                  )}
                  onSubmit={(values) => {
                    handleAddCar(values);
                    setInputProduto('');
                    setInputQuantidade(0);
                    setInputUnidade('');
                    setInputDescComp('');
                  }}>
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    isValid,
                    setFieldValue,
                  }) => (

                    <FilterContainerForm onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <SubTitleGroup>
                            {'SME'.toUpperCase()}
                          </SubTitleGroup>


                          <Row>

                            <Col>

                              <TextInput
                                editable={false}
                                disabled
                                type={'text'}
                                label={'CC'}
                                placeholder={'CC'}
                                value={state.os}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <TextInput
                                editable={false}
                                disabled
                                type={'text'}
                                label={'Emitente'}
                                placeholder={'Emitente'}
                                value={state.solicitante}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                            </Col>



                            <Col>

                              <DropdownInput
                                editable={listItens.length === 0}
                                name={'input_utilizacao'}
                                label={'Utilização'}
                                placeholder={'Selecione ...'}
                                dropArray={dropUtilizacao}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setSelectCodUtilizacao(e.target.value);
                                  setFieldValue('input_utilizacao', selectCodUtilizacao);
                                }}
                                onBlur={handleBlur}
                                value={state.tipo === 'NOVA' ? selectCodUtilizacao : state?.utilizacao}
                              />


                              <DropdownInput
                                editable={listItens.length === 0}
                                name={'input_orientacao'}
                                label={'Orientação'}
                                placeholder={'Selecione ...'}
                                dropArray={dropOrientacao}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setInputLimite(handleOrientacaoPrazo(e.target.value.substring(e.target.value.indexOf(';') + 1, e.target.value.length)));
                                  setFieldValue('input_orientacao', selectCodOrientacao);

                                  setSelectCodOrientacao(e.target.value.substring(0, e.target.value.indexOf(';')));
                                  setInputPrazo(e.target.value.substring(e.target.value.indexOf(';') + 1, e.target.value.length));
                                }}
                                onBlur={handleBlur}
                                value={state.tipo === 'NOVA' ? `${selectCodOrientacao};${inputPrazo}` : state?.orientacao?.trim()}
                              />

                            </Col>

                          </Row>

                          {(selectCodOrientacao === '07' || selectCodOrientacao === '04') && (
                            <TextInput
                              editable={listItens.length === 0}
                              name={'CP_JUST'}
                              type={'text'}
                              label={'Justificativa'}
                              placeholder={'Necessário justificativa para SM de Compra Urgente ou Regularização.'}
                              value={values.CP_JUST}
                              onChange={(e) => {
                                handleChange(e);
                                setCP_JUST(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                          )}


                          {selectCodOrientacao === '15' && (
                            <AreaManutencaoFrotaStyle>

                              <Col xs={6}>
                                <TextInput
                                  editable={listItens.length === 0}
                                  name={'CP_CLVL'}
                                  type={'text'}
                                  label={'Placa'}
                                  placeholder={'Placa'}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setStateCP_CLVL(e.target.value);

                                    setFieldValue('CP_CLVL', e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={state.tipo === 'NOVA' ? values.CP_CLVL : state?.CP_CLVL?.trim()}
                                />
                              </Col>


                              <Col xs={6}>
                                <DropdownInput
                                  editable={listItens.length === 0}
                                  name={'CP_TPMANUT'}
                                  label={'Tipo Manutenção'}
                                  placeholder={'Selecione ...'}
                                  dropArray={SmeTipoManutencao}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSelectCodCP_TPMANUT(e.target.value);

                                    setFieldValue('CP_TPMANUT', e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={state.tipo === 'NOVA' ? selectCodCP_TPMANUT : state?.CP_TPMANUT?.trim()}
                                />
                              </Col>


                              {selectCodCP_TPMANUT === '01' && (
                                <Col xs={12}>
                                  <DropdownInput
                                    editable={listItens.length === 0}
                                    name={'CP_MOTM'}
                                    label={'Motivo Manutenção'}
                                    placeholder={'Selecione ...'}
                                    dropArray={SmeMotivoManutencao}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setSelectCodCP_MOTM(e.target.value);

                                      setFieldValue('CP_MOTM', e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    value={state.tipo === 'NOVA' ? selectCodCP_MOTM : state?.CP_MOTM?.trim()}
                                  />
                                </Col>
                              )}

                            </AreaManutencaoFrotaStyle>
                          )}


                          <RowObservationView>
                            <TextInput
                              editable={false}
                              disabled
                              type={'text'}
                              label={'Setor/ Operação'}
                              placeholder={'Setor/ Operação'}
                              value={state?.setor_operacao}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </RowObservationView>


                          <RowObservationView>
                            <TextInput
                              textHeight={125}
                              type={'text'}
                              name={'input_obs'}
                              label={'Observações'}
                              placeholder={'Observações'}
                              max={250}
                              value={values.input_obs}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('input_obs', e.target.value);
                                setInputObs(e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                          </RowObservationView>

                        </Col>


                        <Col>

                          <SubTitleGroup>
                            {'Itens'.toUpperCase()}
                          </SubTitleGroup>


                          <Row>
                            <Col>
                              <TextInput
                                disabled={false}
                                editable={false}
                                type={'text'}
                                name={'input_poduto'}
                                label={'Produto'}
                                placeholder={'Selecione o produto (Obrigatório)'}
                                buttonIcon={
                                  <IconSearch
                                    color={'black'}
                                    size={20}
                                  />
                                }
                                buttonClick={() => {
                                  setProdutosModalShow(true);
                                }}
                                value={inputProduto}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setFieldValue('input_product', inputProduto);
                                }}
                              />


                              <RowObservationView>
                                <Col>
                                  <TextInput
                                    min={1}
                                    type={'number'}
                                    name={'input_quantidade'}
                                    label={'Quantidade'}
                                    placeholder={'Ex.: 4 (Obrigatório)'}
                                    value={inputQuantidade}
                                    onChange={(e: any) => {
                                      const quantidadeTargetValue = e.target.value;

                                      handleChange(e);
                                      setInputQuantidade(quantidadeTargetValue);
                                      setFieldValue('input_quantidade', inputQuantidade);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Col>


                                <Col>
                                  <TextInput
                                    editable={inputUnidade === ''}
                                    type={'text'}
                                    name={'input_unidade'}
                                    label={'Unidade'}
                                    placeholder={'Unidade'}
                                    value={inputUnidade}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      setFieldValue('input_unidade', inputUnidade);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </RowObservationView>


                              <RowObservationView>
                                <Col>
                                  <TextInput
                                    editable={false}
                                    type={'text'}
                                    name={'input_limite'}
                                    label={'Limite'}
                                    placeholder={'Selecione a orientação (Obrigatório)'}
                                    value={retornaDataFormatada(inputLimite)}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      setFieldValue('input_limite', retornaPrazo(inputLimite));
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Col>


                                <Col>
                                  <TextInput
                                    type={'text'}
                                    name={'input_prazo'}
                                    label={'Prazo (dias)'}
                                    placeholder={'Selecione a orientação (Obrigatório)'}
                                    value={inputPrazo}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </RowObservationView>



                              <RowObservationView>
                                <TextInput
                                  type={'text'}
                                  name={'input_desc_comp'}
                                  label={'Desc. Comp.'}
                                  placeholder={'Ex.: Caneta BIC (Opcional)'}
                                  value={inputDescComp}
                                  onChange={(e: any) => {
                                    const descCompValue = e.target.value;
                                    setInputDescComp(descCompValue);

                                    handleChange(e);
                                    setFieldValue('input_desc_comp', inputDescComp);
                                  }}
                                  onBlur={handleBlur}
                                  max={30}
                                />
                              </RowObservationView>



                              {/* <RowObservationView>
                                {
                                  (
                                    <TextInput
                                      editable={false}
                                      type={'text'}
                                      name={'input_op'}
                                      label={'OP'}
                                      placeholder={'OP'}
                                      buttonIcon={
                                        <IconSearch
                                          color={'black'}
                                          size={20}
                                        />
                                      }
                                      buttonClick={() => {
                                        setOpModalShow(true);
                                      }}
                                      value={inputOp}
                                      onChange={(e: any) => {
                                        const opTargetValue = inputOp;

                                        handleChange(e);
                                        setFieldValue('input_op', opTargetValue);
                                      }}
                                    />
                                  )}
                              </RowObservationView> */}



                              <Warning
                                title={'Atenção'}
                                text={'O limite de Itens da SME é de 50.'}
                              />

                            </Col>
                          </Row>

                        </Col>

                      </Row>



                      <ButtonCopySMEContainer>
                        <IButton
                          disabled={listItens.length === 50}
                          backgroundColor={Colors?.tertiary}
                          type={'submit'}
                          text={'Adicionar Item'}
                          onClick={() => {
                            if (!isValid) {
                              IToast({
                                type: 'warning',
                                message: 'Verifique os campos obrigatórios!',
                              });
                            }
                          }}
                        />
                      </ButtonCopySMEContainer>

                    </FilterContainerForm>

                  )}

                </Formik>

              </SectionMargin>


              <TableDefaultStyled>
                <thead className="thead-two">
                  <tr>
                    <th>Orientação</th>
                    <th>Item</th>
                    <th>Código</th>
                    <th>UN</th>
                    <th>QTD</th>
                    <th>Produto</th>
                    <th>Descrição Complementar</th>
                    <th>Limite</th>
                    <th>Prazo</th>
                    <th>OP</th>
                    <th colSpan={3}>Ações</th>
                  </tr>
                </thead>


                <tbody className="tbody-two">
                  {listItens.map((item, key) => (
                    <tr key={key}>
                      <td>{{
                        '00': (
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.NORMAL_PROCESS}
                            icon={
                              <IconStatusNormal
                                color={Colors?.tertiary}
                                size={16}
                              />
                            }
                            backgroundColor={Colors?.tertiary}
                          />
                        ),
                        '07': (
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.URGENT_BUY}
                            icon={
                              <IconStatusUrgent
                                color={Colors?.danger}
                                size={16}
                              />
                            }
                            backgroundColor={Colors?.danger}
                          />
                        ),
                        '04': (
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.REGULARIZATION}
                            icon={
                              <IconStatusRegular
                                color={Colors?.attention}
                                size={16}
                              />
                            }
                            backgroundColor={Colors?.attention}
                          />
                        ),
                        '13': (
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.MONTHLY_ORDER}
                            icon={
                              <IconHistory
                                color={Colors?.greenLight}
                                size={16}
                              />
                            }
                            backgroundColor={Colors?.greenLight}
                          />
                        ),
                        '14': (
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.INTERNAL_SERVICE}
                            icon={
                              <IconBranch
                                color={Colors?.black}
                                size={16}
                              />
                            }
                            backgroundColor={Colors?.black}
                          />
                        ),
                      }[state.tipo === 'NOVA' ? item.CP_ORIGPC : state.orientacao]}</td> {/* 01 */}

                      <td>{item.CP_ITEM}</td> {/* 02 */}
                      <td>{item.CP_PRODUTO}</td> {/* 03 */}
                      <td>{item.CP_UM}</td> {/* 04 */}

                      <td>
                        <input
                          min={1}
                          name={'input_quantidade'}
                          id={`qtd-${key}`}
                          type='number'
                          value={item.CP_QTSOLIC}
                          onChange={(e) => {
                            if (e.target.valueAsNumber < 1) {
                              e.target.value = item.CP_ITEM;
                            }
                            editaQTSOLICItem(e.target.value, item.CP_ITEM);
                          }}
                          disabled
                        />
                      </td> {/* 05 */}

                      <td>{item.CP_DESCRI}</td> {/* 06 */}

                      <td>
                        <input
                          id={`desc-${key}`}
                          type='text'
                          value={item.CP_DESCOMP}
                          onChange={(e) => {
                            editaDESCOMPItem(e.target.value, item.CP_ITEM);
                          }}
                          maxLength={30}
                          disabled
                        />
                      </td> {/* 07 */}

                      <td>{formataData(item.CP_DATPRF)}</td> {/* 08 */}
                      <td>{item.CP_DIAS} dias</td> {/* 09 */}
                      <td>{item.CP_OP}</td> {/* 10 */}


                      <td className="actions">
                        <ButtonTableAction
                          tooltip={`${TextTooltip.REMOVE} Item: ${key + 1}`}
                          onClick={() => {
                            handleRemoveItem(key);
                          }}
                          icon={
                            <IconTrash
                              color={Colors?.black}
                              size={16}
                            />
                          }
                        />
                      </td> {/* 11 */}

                      <td id={'td-' + key} className="actions">
                        <ButtonTableAction
                          tooltip={`${TextTooltip.EDIT} Item: ${key + 1}`}
                          onClick={() => {
                            handleEditaItem(key);
                          }}
                          icon={
                            <IconEdit
                              color={Colors?.black}
                              size={16}
                            />
                          }
                        />
                      </td> {/* 12 */}

                    </tr>
                  ))}
                </tbody>
              </TableDefaultStyled>


              <ButtonFinishSMEContainer>
                <IButton
                  disabled={listItens.length < 1}
                  backgroundColor={Colors?.primary}
                  text={'Finalizar SME'}
                  onClick={() => {
                    setCreateSMEModalShow(true);
                    // console.log(listItens);
                  }}
                />
              </ButtonFinishSMEContainer>

            </>}


            {carregando &&
              <LoadingsComponent />
            }

          </SectionMargin>
        </ContentStyled>
      </Content>



      <ProdutosModal
        show={produtosModalShow}
        produtoSelected={(prod: any) => {
          const quantityValuePrev = inputQuantidade;
          setInputQuantidade(quantityValuePrev);

          setObjProduto(prod);
          setInputProduto(prod.B1_DESC);
          setInputUnidade(prod.B1_UM);
          setCodProduto(prod.B1_COD);
        }}
        onHide={() => {
          setProdutosModalShow(false);
        }}
      />



      <OpModal
        show={opModalShow}
        opSelected={(op: any) => {
          setOpValue(op);
          setInputOp(op.C2_NUM
            + ' - ' + op.C2_ITEM
            + ' - ' + op.C2_SEQUEN
            + ' - ' + op.C2_PRODUTO,
          );
        }}
        onHide={() => {
          setOpModalShow(false);
        }}
      />



      <ModeloNovoModal
        show={newModelModalShow}
        sm={listItens}
        osCopia={state.os}
        filialCopia={state.filial}
        funcaoSolicitanteCopia={state.funcaoSolicitante}
        onHide={() => {
          setNewModelModalShow(false);
        }}
      />



      <ConfirmationModal
        show={createSMEModalShow}
        head={'Criar SME'}
        orient={!carregando && 'Tem certeza que deseja gerar esta nova SME ?'}
        others={carregando &&
          <LoadingsComponent
            type={'SCREEN'}
            color={Colors?.tertiary}
          />
        }
        buttonDisabled={carregando}
        positive={() => {
          handleGeraSM();
        }}
        negative={() => {
          setCreateSMEModalShow(false);
        }}
      />



      {valueProgressModal && (
        <ConfirmationModal
          show={progressModalShow}
          head="Feito!"
          orient={`SME: ${valueProgressModal.NUM} | FILIAL: ${valueProgressModal.FILIAL} gerada com sucesso`}
          others={
            <GenerateButtonsSMEView>
              <IButton
                backgroundColor={Colors?.tertiary}
                onClick={() => {
                  postPrinter(valueProgressModal);
                }}
                text={'Imprimir'}
              />

              <IButton
                backgroundColor={Colors?.secondary}
                onClick={() => {
                  setValueAnexo(valueProgressModal);
                  setTelaAnexo(true);

                  setProgressModalShow(false);
                  setValueProgressModal(null);
                }}
                text={'Adicionar Anexo'}
              />
            </GenerateButtonsSMEView>
          }
          negative={() => {
            history.goBack();
            setProgressModalShow(false);
            setTimeout(() => {
              setValueProgressModal(null);
            }, 300);
          }}
        />
      )}



      {valueAnexo && (
        <SmeAnexosModal
          show={telaAnexo}
          anexar={true}
          sm={valueAnexo}
          buttonText={'Finalizar e Voltar'}
          onHide={() => {
            history.goBack();
            setTimeout(() => {
              setTelaAnexo(false);
              setValueAnexo(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default SME_NovaScreen;
