import styled from 'styled-components';



export const FilterRpPendentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TextAreaContainer = styled.textarea`
  padding: 4px;
  margin-top: -3.9px;
  height: 39px;
`;
