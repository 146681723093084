import styled from 'styled-components';

import {
  Colors,
} from '../../../../common/constants';



export const DividerText = styled.p`
  position: relative;
  text-align: center;
  margin-top: 17px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 17px;

  :after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${Colors?.grayDark};
    top: 55%;
    left: 0;
    z-index: 1;
  }
`;

export const DividerSpan = styled.span`
  position: relative;
  padding: 0.6rem 2rem;
  background-color: ${Colors?.formBackground};
  color: ${Colors?.black};
  font-size: 1.2rem;
  z-index: 2;
`;
