import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';

import {
  Content,
  Screen, TabIconStyled, TabsStyled, TabTextStyled, TabViewStyled,
} from '../../../../common/styles/styled.layout';

import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import { IconHour } from '../../../../components/Icons';
import Tab_Sintetico from './GHR_Tabs/001GHR_Consulta';



const CHR_IndexScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);


  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconHour />,
        text: '',
      },
      screenTab: <Tab_Sintetico />,
    },
    // {
    //   eventKey: 1,
    //   title: {
    //     icon: <IconHour />,
    //     text: 'Analítico',
    //   },
    //   screenTab: <Tab_Analitico />,
    // },
    // {
    //   eventKey: 2,
    //   title: {
    //     icon: <IconHour />,
    //     text: 'Completo',
    //   },
    //   screenTab: <Tab_Completo />,
    // },

  ];

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          title={'Grade de Horas'}
        />
      </NavHeader>

      <Content>

        <TabsStyled
          id={'control-tab-pcb'}
          activeKey={currentTab}
          onSelect={(tab) => setCurrentTab(parseInt(tab))}
        >

          {tabsList.map((tab) => (
            <Tab
              eventKey={tab.eventKey}
              title={
                <TabViewStyled>

                  <TabIconStyled>
                    {tab.title.icon}
                  </TabIconStyled>



                  <TabTextStyled>
                    {tab.title.text}
                  </TabTextStyled>

                </TabViewStyled>
              }>

              {tab.screenTab}


            </Tab>
          ))}

        </TabsStyled>

      </Content>
    </Screen>

  );
};



export default CHR_IndexScreen;
