import React from 'react';

import {
  OverlayTrigger,
} from 'react-bootstrap';

import {
  TableToltip,
} from '../../../common/styles/styled.layout';
import { TableActionButton } from './styled';

interface IProps {
  key?: any;
  disabled?: boolean;

  tooltip?: any;
  icon?: any;
  placement?: any;
  trigger?: any;
  overlay?: any;

  onClick?: any;
}



const ButtonTablePagination: React.FC<IProps> = (props) => {
  return (

    <OverlayTrigger
      key={props.key || 'left'}
      placement={props.placement || 'left'}
      trigger={props.trigger || ['hover', 'hover']}
      overlay={ props.overlay ||
        <TableToltip className="d-print-none">
          {props.tooltip}
        </TableToltip>
      }>

      <TableActionButton

        onClick={props.onClick}>

        {props.icon}

      </TableActionButton>

    </OverlayTrigger>

  );
};



export default ButtonTablePagination;
