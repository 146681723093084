import React,
{
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  IconAdd,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalContainerStyled,
  ModalBodyFilterStyled,
  ModalFilterView,
  ModalTableContainer,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';
import TextInput from '../../../../../components/TextFields/Input';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  opSelected?: any;
  onHide?: any;
}



const OpModal: React.FC<IProps> = (props) => {
  const [carregando, setCarregando] = useState(false);

  const [allOps, setAllOps] = useState([]);



  const handleConsultaOp = async (values: any) => {
    setCarregando(true);

    ApiWS()
      .post('/sm/nova_sm/consulta/op', { 'consulta': values.input_op })
      .then((resp) => {
        setAllOps(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  const columns = [
    {
      dataField: '',
      text: '',
      sort: false,
      headerStyle: () => {
        return { width: '3%' };
      },
      formatter: (_cell, row) => {
        return (
          <TableActionButton
            onClick={() => {
              props.opSelected(row);
              props.onHide();
            }}>
            <IconAdd
              color={Colors?.black}
              size={16}
            />
          </TableActionButton>
        );
      },
    },
    {
      dataField: 'C2_NUM',
      text: 'Num',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'C2_ITEM',
      text: 'Item',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'C2_SEQUEN',
      text: 'Seq.',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
    },
    {
      dataField: 'C2_PRODUTO',
      text: 'Produto',
      sort: true,
      headerStyle: () => {
        return { width: '17%' };
      },
    },
    {
      dataField: 'SERVICO',
      text: 'Serviço',
      sort: true,
    },
    {
      dataField: 'C2_LOCAL',
      text: 'Armazem',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'C2_EMISSAO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: allOps.length,
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Buscar OP'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          // validationSchema={SmeAtendimentoValidationSchema}
          initialValues={{
            input_op: '',
          }}
          onSubmit={(values) => {
            handleConsultaOp(values);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalFilterView>
                <TextInput
                  className='filter-input-view'
                  type={'text'}
                  name={'input_op'}
                  label={'OP'}
                  placeholder={'Numero OP'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_op', e.target.value);
                  }}
                  onBlur={handleBlur}
                />

                <IButton
                  backgroundColor={Colors?.accept}
                  text={'Consultar'}
                  type={'submit'}
                />
              </ModalFilterView>



              <ModalBodyFilterStyled>

                <ModalTableContainer>

                  {carregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!carregando && allOps.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={allOps.length < 10}
                          data={allOps}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                        />
                      )}
                    </PaginationProvider>
                  )}
                </ModalTableContainer>

              </ModalBodyFilterStyled>



              <ModalFooterStyled>

                <IButton
                  disabled={carregando}
                  backgroundColor={Colors?.primary}
                  text={'Fechar'}
                  onClick={props.onHide}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default OpModal;
