import * as Yup from 'yup';



const POCCartaoValidationSchema = Yup.object().shape({
  
  DESCR: Yup.string(),

});



export default POCCartaoValidationSchema;
