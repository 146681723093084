import React, { useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';

import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const TriaGrupoCRUDSMEModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postSaveGroup(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupo/';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Salvo com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível salvar!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveGroup', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postRemoveGroup(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupo/';
    const payload = [{ ...values,  DEL: '*' }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Removido com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível remover!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveGroup', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            CODIGO: props?.valuesEdit?.CODIGO || props?.valuesRemove?.CODIGO || '',
            DESCRICAO_ATUAL: props.valuesEdit?.DESCRICAO,
            DESCRICAO: props.valuesEdit?.DESCRICAO || props?.valuesRemove?.DESCRICAO || '',
            RESPONSAVEL: props.valuesEdit?.RESPONSAVEL || props?.valuesRemove?.RESPONSAVEL || '',
            GESTOR: props.valuesEdit?.GESTOR || props?.valuesRemove?.GESTOR || '',
            DEL: '',
          }}
          validationSchema={
            Yup.object().shape({
              CODIGO: props.type !== 'REMOVE' ? Yup.string().required('Obrigatório') : Yup.string(),
              DESCRICAO: props.type !== 'REMOVE' ? Yup.string().required('Obrigatório') : Yup.string(),
              RESPONSAVEL: props.type !== 'REMOVE' ? Yup.string().required('Obrigatório') : Yup.string(),
              GESTOR: props.type !== 'REMOVE' ? Yup.string().required('Obrigatório') : Yup.string(),
            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveGroup(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveGroup(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                {props?.type !== 'REMOVE' && (
                  <>
                    {props?.type === 'EDIT' && (
                      <TextInput
                        disabled={true}
                        name={'DESCRICAO_ATUAL'}
                        label={'Grupo Atual'}
                        type={'text'}
                        value={`${values.CODIGO} - ${values.DESCRICAO_ATUAL}`}
                      />
                    )}



                    <TextInput
                      disabled={loading}
                      name={'CODIGO'}
                      label={'Código'}
                      type={'text'}
                      placeholder={'Código'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.CODIGO}
                    />



                    <TextInput
                      disabled={loading}
                      name={'DESCRICAO'}
                      label={'Descrição'}
                      type={'text'}
                      placeholder={'Descrição'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DESCRICAO}
                    />



                    <TextInput
                      disabled={loading}
                      name={'RESPONSAVEL'}
                      label={'Responsável'}
                      type={'text'}
                      placeholder={'Responsável'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.RESPONSAVEL}
                    />



                    <TextInput
                      disabled={loading}
                      name={'GESTOR'}
                      label={'Gestor'}
                      type={'text'}
                      placeholder={'Gestor'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.GESTOR}
                    />


                  </>
                )}


                {props?.type === 'REMOVE' && (
                  <>
                    <TextInput
                      disabled={true}
                      name={'CODIGO'}
                      label={'Grupo'}
                      type={'text'}
                      value={`${values.CODIGO} - ${values.DESCRICAO}`}
                    />
                  </>
                )}


              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default TriaGrupoCRUDSMEModal;
