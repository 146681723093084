import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalTableSubTitleText,
  ModalTableTitleText,
} from '../../../../../../components/Modals/styled';

import {
  validateString,
} from '../../../../../../utils/StringUtils';

import {
  AdmModalDetailHeader,
  AdmModalDetailInfoContent,
  AdmModalDetailBody,
  ItemContent,
  ItemContentFull,
  ItemLabel,
  ItemText,
  SwitchCheckStyled,
} from './styled';



interface IProps {
  show: boolean;
  data: any;
  onEdit?: any;
  onHide?: any;
}



const PStockDetailSupplierModal: React.FC<IProps> = (props) => {
  const supplier = props.data;



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Detalhes do Fornecedor'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          <AdmModalDetailHeader>

            <AdmModalDetailInfoContent>
              <ModalTableTitleText>
                Nome: {validateString(supplier.FANTASIA) || '-'}
              </ModalTableTitleText>

              <ModalTableSubTitleText>
                Razão Social: {validateString(supplier.RAZAO) || '-'}
              </ModalTableSubTitleText>

              {/* TODO: Verificar se é cnpj ou cpf e aplicar a mascara */}
              <ModalTableSubTitleText>
                CPF/CNPJ: {validateString(supplier.CNPJ) || '-'}
              </ModalTableSubTitleText>
            </AdmModalDetailInfoContent>

          </AdmModalDetailHeader>



          <AdmModalDetailBody>

            <ItemContentFull>
              <ItemLabel>
                Endereço:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.ENDE) || '-'}
              </ItemText>
            </ItemContentFull>


            <ItemContent>
              <ItemLabel>
                Município:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.MUNICIPIO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Bairro:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.BAIRRO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Estado:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.EST) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                CEP:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.CEP) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Contato:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.CONTATO) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Telefone:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.TEL) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContentFull>
              <ItemLabel>
                Email:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.EMAIL) || '-'}
              </ItemText>
            </ItemContentFull>


            <ItemContent>
              <ItemLabel>
                Cód.:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.COD) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Ins. Estadual:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.INSCR) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Ins. Municipal:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.INSCRM) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Origem:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.ORIGEM) || '-'}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Data/Hora Cadastro:
              </ItemLabel>

              <ItemText>
                {`${supplier.DTCADASTRO} ${supplier.HORACADASTRO}`}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Data/Hora Atualização:
              </ItemLabel>

              <ItemText>
                {`${supplier.DTATUALIZACAO} ${supplier.HORAATUALIZACAO}`}
              </ItemText>
            </ItemContent>


            <ItemContent>
              <ItemLabel>
                Bloqueado:
              </ItemLabel>

              <ItemText>
                <SwitchCheckStyled
                  disabled
                  id={'switch-blocked'}
                  type={'switch'}
                  label={supplier.Bloqueado === '0' ? 'Bloqueado' : 'Desbloqueado'}
                  checked={supplier.Bloqueado === '0'}
                />
              </ItemText>
            </ItemContent>


            <ItemContentFull>
              <ItemLabel>
                Observação:
              </ItemLabel>

              <ItemText>
                {validateString(supplier.Obs) ? supplier.Obs : '-'}
              </ItemText>
            </ItemContentFull>

          </AdmModalDetailBody>

        </ModalBodyStyled>



        <ModalFooterStyled>
          {/* <IButton
            backgroundColor={Colors?.secondary}
            text={'Editar'}
            onClick={props.onEdit}
          /> */}


          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default PStockDetailSupplierModal;
