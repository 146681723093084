import NameRoutes from '../name';


export const PIC_NavRoutes = [
  {
    key: 1,
    title: 'Cadastro',
    items: [
      {
        key: 11,
        label: 'PIC',
        path:`/${NameRoutes.PIC_Cadastro_Pic}`,
      },
      {
        key: 12,
        label: 'Equipe',
        path:`/${NameRoutes.PIC_Cadastro_Equipe}`,
      },
      {
        key: 13,
        label: 'Área',
        path:`/${NameRoutes.PIC_Cadastro_Area}`,
      },
    ],
  },
  {
    key: 2,
    label: 'Consulta',
    path: `/${NameRoutes.PIC_Consulta}`,
  },
];


export const POC_NavRoutes = [
  {
    key: 1,
    label: 'Cadastros',
    path: `/${NameRoutes.POC_Cadastro}`,
  },
  {
    key: 2,
    label: 'Consultar Cartão',
    path: `/${NameRoutes.POC_Consulta}`,
  },
];
