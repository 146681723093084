import {
  Images,
  Messages,
} from '../common/constants';



export function returnErrorObject(error: number) {
  switch (error) {
    case 401:
      return {
        image: Images.error401,
        title: Messages.Error401,
        description: null,
      };

    case 500:
      return {
        image: Images.other500,
        title: Messages.Other500,
        description: 'Tente Novamente! Se persistir, contate o Administrador do sistema!',
        // buttonText: 'Tentar Novamente',
        // buttonClick: () => window.location.reload(),
      };

    default:
      return {
        image: null,
        title: 'Mensagem',
        description: 'Descrição',
      };
  }
};
