const SmeTriagemFiltroModel = {
  select_grupo_origem: '',
  input_os: '',
  input_descricao: '',
  input_solicitante: '',
  input_num_sm: '',
  radio_os_type: '',
  select_grupo_produto: '',
};


const SmeTriagemTransferirModel = {
  select_grupo_destino: '',
  OBSTRIAGEM: '',
};


export {
  SmeTriagemFiltroModel,
  SmeTriagemTransferirModel,
};
