import React from 'react';

// import { Container } from './styles';



const Tab_TransferenciasScreen: React.FC = () => {
  return <div />;
};



export default Tab_TransferenciasScreen;
