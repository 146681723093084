import React, { useEffect, useState } from 'react';

import {
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../components/Empty';
import { IconAddTag, IconTrash } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const GrupoCUDGIModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingListRoule, setLoadingListRoule] = useState(false);
  const [showList, setShowList] = useState(true);

  const [emptyObject, setEmptyObject] = useState({
    title: 'Sem Regras!',
    description: 'Este grupo não possui regras.',
  });

  const [listSystemDrop, setListSystemDrop] = useState([]);
  const [listPermissionDrop, setListPermissionDrop] = useState([]);
  const [listGroup_x_Roule, setListGroup_x_Roule] = useState([]);

  function postFetchSystemDrop(values) {
    setLoadingListRoule(true);
    setListSystemDrop([]);

    const url = 'gi/sistema/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((group, index) => {
            setListSystemDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListRoule(false);
      });
  };

  function postFetchRouleDrop(values) {
    setLoadingListRoule(true);
    setListPermissionDrop([]);

    const url = 'gi/regra/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((group, index) => {
            setListPermissionDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListRoule(false);
      });
  };


  function postFetchGroup_x_Roule(values) {
    setLoadingListRoule(true);
    setShowList(false);

    const url = 'gi/grupo/regra/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListGroup_x_Roule(res.data);

          postFetchRouleDrop({ DESCR: '', IDSIS: '' });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroup_x_Roule', JSON.stringify(err));

        setEmptyObject({
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });
      })
      .finally(() => {
        setLoadingListRoule(false);
        setShowList(true);
      });
  };

  function postSaveGroup_x_Roule(values, resetForm) {
    setLoadingListRoule(true);
    setShowList(false);

    const url = 'gi/grupo/regra/adicionar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          postFetchGroup_x_Roule({ IDGRUPO: props?.valuesEdit?.ID });

          resetForm();
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveGroup_x_Roule', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListRoule(false);
        setShowList(true);
      });
  };

  function postRemoveGroup_x_Roule(values) {
    setLoadingListRoule(true);
    setShowList(false);

    values = { ...values, IDGRUPO: props.valuesEdit.ID };

    const url = 'gi/grupo/regra/remover';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          postFetchGroup_x_Roule({ IDGRUPO: props?.valuesEdit?.ID });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveGroup_x_Roule', JSON.stringify(err));
      })
      .finally(() => {
        setLoadingListRoule(false);
        setShowList(true);
      });
  };

  function postSaveGroup(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/grupo/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/grupo/alterar';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveGroup', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postRemoveGroup(values) {
    setLoading(true);

    let url = '';

    url = 'gi/grupo/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveGroup', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    if (props.type !== 'NEW') {
      postFetchSystemDrop({ DESCR: '', IDDEP: '' });
      postFetchGroup_x_Roule({ IDGRUPO: props?.valuesEdit?.ID || props?.valuesRemove?.ID });
    }
  }, []);


  const options = {
    custom: true,
    totalSize: listGroup_x_Roule.length,
  };

  const columns = [
    {
      dataField: 'IDREGRA',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'REGRA',
      text: 'Regra',
      sort: true,
      headerStyle: () => {
        return { width: '80%' };
      },
    },
    {
      dataField: '',
      text: props.type !== 'REMOVE' && 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {props.type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.REMOVE}
                icon={
                  <IconTrash color={Colors?.black} size={16} />
                }
                onClick={() => {
                  postRemoveGroup_x_Roule(row);
                }}
              />)}
          </>
        );
      },
    },
  ];


  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}


        {!loading && (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || '',
              IDSIS: '',
              DESCR_ATUAL: props.valuesEdit?.DESCR,
              DESCR: props.valuesEdit?.DESCR || props?.valuesRemove?.DESCR || '',
            }}
            validationSchema={
              Yup.object().shape({
                ID: props.type !== 'NEW' ? Yup.string().required('Obrigatório') : Yup.string(),
                DESCR: Yup.string().required('Obrigatório'),
              })
            }
            onSubmit={(values) => {
              if (props?.type !== 'REMOVE') {
                postSaveGroup(values);
              }
              else if (props?.type === 'REMOVE') {
                postRemoveGroup(values);
              }
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <>

                <Form onSubmit={handleSubmit}>

                  <ModalBodyStyled>

                    {props?.type !== 'REMOVE' && (
                      <>

                        {props?.type === 'EDIT' && (
                          <TextInput
                            disabled={true}
                            name={'DESCR_ATUAL'}
                            label={'Grupo Atual'}
                            type={'text'}
                            value={values.DESCR_ATUAL}
                          />
                        )}



                        <TextInput
                          disabled={loading}
                          name={'DESCR'}
                          label={'Novo Grupo'}
                          type={'text'}
                          placeholder={'Descrição'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DESCR}
                        />
                      </>
                    )}


                    {props?.type === 'REMOVE' && (
                      <>
                        <TextInput
                          disabled={true}
                          name={'DESCR'}
                          label={'Grupo'}
                          type={'text'}
                          value={values.DESCR}
                        />
                      </>
                    )}


                  </ModalBodyStyled>


                  <ModalFooterStyled>

                    <IButton
                      backgroundColor={Colors?.buttonCancel}
                      text={'Cancelar'}
                      onClick={() => props.onHide()}
                    />


                    <IButton
                      backgroundColor={Colors?.buttonOk}
                      text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                      type={'submit'}
                    />

                  </ModalFooterStyled>

                </Form>

              </>

            )}
          </Formik>
        )}


        {props.type !== 'NEW' && (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{
                IDSIS: '',
                IDREGRA: '',
                IDGRUPO: props?.valuesEdit?.ID || props?.valuesRemove?.ID,
              }}
              validationSchema={
                Yup.object().shape({
                  IDSIS: Yup.string(),
                  IDREGRA: Yup.string().required('Obrigatório!'),
                  IDGRUPO: Yup.string().required('Obrigatório!'),
                })
              }
              onSubmit={(values, { resetForm }) => {
                postSaveGroup_x_Roule(values, resetForm);
              }}>
              {({
                values,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (

                <Form onSubmit={handleSubmit}>

                  <TitleGroup>
                    {'Regras'.toUpperCase()}
                  </TitleGroup>

                  {loading && (
                    <LoadingsComponent />
                  )}

                  {!loading && (
                    <>
                      <Row>

                        <Col xs={5}>
                          <DropdownInput
                            disabled={loading || props.type === 'REMOVE'}
                            name={'IDSIS'}
                            label={'Sistema'}
                            placeholder={'Todos'}
                            defaultValue={values.IDSIS}
                            dropArray={listSystemDrop}
                            onChange={(e) => {
                              handleChange(e);
                              postFetchRouleDrop({ DESCR: '', IDSIS: e.target.value });
                            }}
                            onBlur={handleBlur}
                            value={values.IDSIS}
                          />
                        </Col>


                        <Col xs={5}>
                          <DropdownInput
                            disabled={loading || props.type === 'REMOVE'}
                            name={'IDREGRA'}
                            label={'Regra'}
                            placeholder={'Selecione'}
                            defaultValue={values.IDREGRA}
                            dropArray={listPermissionDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.IDREGRA}
                          />
                        </Col>


                        <Col xs={1}>
                          <IButton
                            disabled={loading || props.type === 'REMOVE'}
                            type={'submit'}
                            backgroundColor={Colors?.white}
                            iconLeft={<IconAddTag size={30} color={Colors?.buttonOk} />}
                          />
                        </Col>

                      </Row>

                    </>
                  )}

                </Form>

              )}
            </Formik>

            {loadingListRoule && (
              <LoadingsComponent />
            )}


            {!loadingListRoule && showList && listGroup_x_Roule.length === 0 && (
              <EmptyContent
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )
            }


            {!loadingListRoule && showList && listGroup_x_Roule.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={listGroup_x_Roule.length < 10}
                    data={listGroup_x_Roule}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            )}

          </>
        )}

      </ModalContainerStyled>

    </Modal>

  );
};



export default GrupoCUDGIModal;
