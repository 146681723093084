import styled,
{
  css,
} from 'styled-components';

import {
  Container,
  Popover,
  Row,
  Table,
  Tabs,
  Tooltip,
} from 'react-bootstrap';

import {
  Link,
} from 'react-router-dom';

import {
  Form,
} from 'formik';

import {
  Colors,
} from '../../common/constants';



interface IProps {
  auto?: boolean;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  backgroundColor?: string;
  fontSizeHeader?: number;
}



export const Screen = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props: IProps) => props.backgroundColor || 'white'};
`;


export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

export const ContentStyled = styled(Container)`
  padding: 16px;
  min-height: 100%;
`;



export const ContainerView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;



export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;



export const TitleGroup = styled.h1`
  margin-top: 25px;
  margin-bottom: 30px;
  color: ${Colors?.primary};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  line-height: 2rem;
  letter-spacing: -.04rem;
  border-bottom: 1px solid ${Colors?.primary};
`;

export const SubTitleGroup = styled.h2`
  ${(props: IProps) => !props.noMarginTop && 'margin-top: 25px;'};
  ${(props: IProps) => !props.noMarginBottom && 'margin-bottom: 25px'};
  color: ${Colors?.secondary};
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.8rem;
  letter-spacing: -.04rem;
  border-bottom: 1px solid ${Colors?.secondary};
`;

export const ObsTitleGroup = styled.h5`
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
`;

export const ObsSubTitleGroup = styled.h6`
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const SectionMargin = styled.div`
  padding-bottom: 15px;
`;



export const RowObservationView = styled(Row)`
  margin-top: -10px;
`;



// Separators Style

export const SeparatorButton = styled.div`
  height: 20px;
  width: 20px;
`;



// Tab Style

export const TabsStyled = styled(Tabs)`
  margin-top: 20px;

  .active {
    border-top: 2px solid ${Colors?.primary} !important;

    svg {
      color: ${Colors?.primary} !important;
      fill: ${Colors?.primary} !important;
    }

    span {
      color: ${Colors?.primary} !important;
    }
  }
`;

export const TabViewStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
`;

export const TabIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 12px;

  svg {
    color: ${Colors?.grayDark} !important;
    fill: ${Colors?.grayDark} !important;
  }
`;

export const TabTextStyled = styled.span`
  color: ${Colors?.grayDark} !important;
  font-size: ${(props: IProps) => props.fontSizeHeader || '1.35rem'};
`;



// Filter Container Style

export const FilterContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  ${(props: IProps) => !props.auto && 'min-height: 310px'};
  margin-top: 30px;
  padding: 30px;
  background-color: ${Colors?.formBackground};
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;

export const RowButtonFiltersView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`;



// Table Styles

export const TableTRCss = css`
  border-color: #dee2e6;
  border-style: solid;
  border-width: 1px;
`;

export const TableTDCss = css`
  padding: 6px 4px !important;
  font-size: 1.125rem;
  font-weight: normal;
  text-align: center;
`;



export const TableActionNextCss = {
  width: 37,
};

export const TableCheckBoxNextCss = {
  width: 40,
};


export const TableRotateNextCss = {
  ...TableActionNextCss,
  height: 70,
  fontSize: '1rem',
  transform: 'rotate(90deg) translateX(-5px)',
};

export const TableActionCss = css`
  .actions {
    width: 20px;
    align-items: center;
    justify-content: center;
  }
`;

export const TableTdButtonNextCss = styled.p`
  margin: 0;
  color: ${Colors?.tertiaryDark};
  cursor: pointer;
`;

export const TableTdButtonCss = css`
  .td-button {
    color: ${Colors?.tertiaryDark};
    cursor: pointer;
  }
`;



export const TableTheadCss = css`
  .no-style {
    padding: 0 !important;
    margin: 0 !important;
  }


  thead {

    tr {
      background-color: ${Colors?.secondary};

      th {
        padding: 6px 4px !important;
        color: white;
        font-size: 1.125rem;
        font-weight: 600;
        text-align: center;
        letter-spacing: -.05rem;
      }
    }
  }
`;

export const TableTbodyCss = css`
  .no-horizontal-border {
    border-left-width: 0;
    border-right-width: 0;
  }

  border-top-color: #FFFFFF !important;
  border-top-style: solid;
  border-top-width: 2px;
`;



export const TableDefaultStyled = styled(Table)`
  ${TableTheadCss};


  .thead-two {

    tr {
      background-color: ${Colors?.gray};
    }
  }


  tbody {
    ${TableTbodyCss};

    tr {
      ${TableTRCss};

      ${TableTdButtonCss}
      ${TableActionCss}

      td {
        ${TableTDCss};
      }

      :nth-of-type(odd)>* {
        --bs-table-accent-bg: ${Colors?.secondaryThin};
      }

      :hover:nth-of-type(odd) > *,
      :hover {
        background-color: ${Colors?.secondaryLight};
        --bs-table-accent-bg: ${Colors?.secondaryLight};
      }
    }
  }


  .tbody-two {

    tr {

      :nth-of-type(odd)>* {
        --bs-table-accent-bg: ${Colors?.grayThin};
      }

      :hover:nth-of-type(odd) > *,
      :hover {
        background-color: ${Colors?.grayLight};
        --bs-table-accent-bg: ${Colors?.grayLight};
      }
    }
  }
`;


export const TableAccordionStyled = styled(Table)`
  ${TableTheadCss};


  tbody {
    ${TableTbodyCss};

    tr {
      ${TableTRCss};

      ${TableTdButtonCss}
      ${TableActionCss}

      .visivel {
        display: block;
      }

      td {
        ${TableTDCss};
      }

    }
  }
`;


export const TablePrinterStyled = styled(Table)`

  .image-td {
    width: 150px;
    height: 150px;
    margin-right: 10px;
    /* padding: 10px; */

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .name-printer-td {
    width: 60% !important;
    height: 110px;
    padding: 10px 0 0 15px;
    font-size: 1.75rem;
  }

  .name-printer-td-sm {
    width: 39% !important;
    height: auto;
    padding: 3.5% 0 0 3%;
    font-size: 1.3rem;
  }

`;


export const TablePopover = styled(Popover)`
  padding: 12px !important;
  color: ${Colors?.black} !important;
  font-size: 1.063rem;
  font-weight: bold;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0 , 0.1);
`;


export const TableToltip = styled(Tooltip)`
  font-size: 1.063rem;
`;



// Table Action Styles

export const TableCssActionStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 3px;
`;

export const TableActionStyle = css`
  ${TableCssActionStyle};
  cursor: pointer;

  :hover {

    svg {
      color: ${Colors?.white} !important;
      fill: ${Colors?.white} !important;
    }

  }
`;

export const TableActionLink = styled(Link)`
  ${TableActionStyle};

  :hover {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
  }
`;

export const TableActionButton = styled.a`
  ${TableActionStyle};

  :hover {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
  }
`;

export const TableActionUpButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border-style:none;

  :active,
  :focus {
    background: transparent;
  }
`;

export const TablePointerButton = styled.div`
  ${TableCssActionStyle};
  cursor: pointer;
`;

export const TableViewButton = styled.div`
  ${TableCssActionStyle};
  cursor: default;
`;

export const TableTextButton = styled.a`
  ${TableActionStyle};
  text-decoration: underline;
`;
