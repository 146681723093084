import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';


import IButton from '../../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TextInput from '../../../../../../../components/TextFields/Input';


import ApiWS from '../../../../../../../services/api.service';

import PcbManutencaoResiduoModel from './PBC-manutencao-tab-residuo.model';
import PcbManutencaoResiduoValidationSchema from './PBC-manutencao-tab-residuo.validation';



const Tab_ResiduoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({});
  const [requestCheck, setRequestCheck] = useState(false);



  async function postRequestFetch(values: any) {
    const payload = {
      'FILIAL': values.input_filial,
      'NUM': values.input_pc,
    };
    setLoading(true);

    ApiWS()
      .post('/suprimento/manutencao/consulta/pedido', payload)
      .then((resp) => {
        if(resp.status === 200 && resp.data.length > 0){
          if(resp.data[0].RESIDUO === 'S'){
            setRequestCheck(true);
          }
          else {
            IToast({
              type: 'warning',
              message: 'PC não está em resíduo!',
            });
            setRequestCheck(false);
          }
        }
        else {
          IToast({
            type: 'warning',
            message: 'Filial ou Pedido não encontrado!',
          });
          setRequestCheck(false);
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: `Erro ${error.code}. Contate o suporte!`,
        });
        console.error('fetchAll', JSON.stringify(error.response, null, 2));
        setRequestCheck(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postChangeResiduo(values: any) {
    const payload = {
      'NUM': values.input_pc,
      'FILIAL': values.input_filial,
    };

    setLoading(true);
    ApiWS()
      .post('/suprimento/manutencao/altera/residuo', payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: 'Removido com sucesso!',
          });
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro ao deletar resíduo! Contate o suporte.',
        });

        console.error('postChangeResiduo', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if(requestCheck){
      postChangeResiduo(values);
    }
  }, [requestCheck]);


  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PcbManutencaoResiduoValidationSchema}
      initialValues={PcbManutencaoResiduoModel}
      onSubmit={(values, { resetForm }) => {
        postRequestFetch(values);
        setValues(values);
        resetForm();
      }}>
      {({
        values,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
      }) => (

        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Retirar PC de resíduo'.toUpperCase()}
            </TitleGroup>


            {loading &&
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            }


            <SectionMargin>
              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'input_filial'}
                      label={'Filial'}
                      placeholder={'Filial'}
                      min={2}
                      max={2}
                      value={values.input_filial}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loading}
                      type={'text'}
                      name={'input_pc'}
                      label={'PC'}
                      placeholder={'PC'}
                      min={6}
                      max={6}
                      value={values.input_pc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loading}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();

                      setValues(PcbManutencaoResiduoModel);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    text={'Alterar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>
            </SectionMargin>

          </SectionMargin>

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_ResiduoScreen;
