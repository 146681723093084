import React, { useState, useEffect } from 'react';
import Images from '../../../../../common/constants/Images';
import { ContainerView, Content, Screen, TitleGroup } from '../../../../../common/styles/styled.layout';
import CardDisplay from '../../../../../components/Cards/CardDisplay';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import IPhoto from '../../../../../components/IPhoto';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import { PAV_NavRoutes } from '../../../../../navigation/navs/HumanResources';
import ApiWS from '../../../../../services/api.service';
import { ImgStyled, PAVContentStyled, PAVRowStyled } from './PAV-Destaques.styled';



const PAV_DestaquesScreens: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const emptyObject = {
    image: Images.error401,
    title: 'Erro!',
    description: 'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte.',
  };

  const [listEmployeeMonth, setListEmployeeMonth] = useState([]);
  const [listEmployeeYear, setListEmployeeYear] = useState([]);


  function returnCompetencyImg(competency) {
    let object = { img: '', color: '' };
    switch (competency) {
      case 'Protagonismo':
        object = { img: Images.protagonismoImg, color: 'blue' };
        break;
      case 'Relacionamento Interpessoal':
        object = { img: Images.relacionamentoImg, color: 'yellow' };
        break;
      case 'Liderança Inspiradora':
        object = { img: Images.liderancaImg, color: 'orange' };
        break;
      case 'Foco em Solução':
        object = { img: Images.focoImg, color: 'red' };
        break;
      case 'Organização e Planejamento':
        object = { img: Images.organizacaoImg, color: 'green' };
        break;
    }

    return object;
  }


  function postFetchEmployees() {
    setLoading(true);

    const url = '/pav/destaques';
    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data[0].MES.forEach((detached) => {
            setListEmployeeMonth((list) => [...list, {
              name: detached.NOME,
              office: detached.CARGO,
              register: detached.MATRICULA,
              os: detached.OS,
              photo: detached.FOTO,
              score: detached.PONTOS,
              competencyImg: returnCompetencyImg(detached.PILAR).img,
              competencyColor: returnCompetencyImg(detached.PILAR).color,
            }]);
          });


          resp.data[1].ANO.forEach((detached) => {
            setListEmployeeYear((list) => [...list, {
              name: detached.NOME,
              office: detached.CARGO,
              register: detached.MATRICULA,
              os: detached.OS,
              photo: detached.FOTO,
              score: detached.PONTOS,
              competencyImg: returnCompetencyImg(detached.PILAR).img,
              competencyColor: returnCompetencyImg(detached.PILAR).color,
            }]);
          });
        }
      })
      .catch((err) => {
        console.error('postFetchEmployees:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte!',
        });

        setError(true);
      })
      .finally(() => setLoading(false));
  };



  useEffect(() => {
    postFetchEmployees();
  }, []);


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={1}
          navArray={PAV_NavRoutes}
          title={'PAV'}
        />
      </NavHeader>


      {loading && (
        <LoadingsComponent />
      )}


      {!loading && !error && (

        <Content>
          <ContainerView>

            <PAVRowStyled>

              <PAVContentStyled>

                <TitleGroup>
                  {'Destaques do Mês por Competência'.toUpperCase()}
                </TitleGroup>


                {listEmployeeMonth.map((card) => (

                  <CardDisplay
                    icon={
                      <ImgStyled src={card.competencyImg} />
                    }
                    photo={
                      <IPhoto src={card.photo} alt={card.name} size={'sm'} />
                    }
                    data={[
                      { title: card.name }, 
                      { title: card.office }, 
                      { desc: `Matrícula: ${card.register}` }, 
                      { desc: `OS: ${card.os}` }, 
                      { desc: `Pontuação: ${card.score}` },
                    ]}
                    color={card.competencyColor}
                    size={'md'}
                  />

                ))}

              </PAVContentStyled>



              <PAVContentStyled>

                <TitleGroup>
                  {'Destaques do Ano por Competência'.toUpperCase()}
                </TitleGroup>


                {listEmployeeYear.map((card) => (

                  <CardDisplay
                    icon={
                      <ImgStyled src={card.competencyImg} />
                    }
                    photo={
                      <IPhoto src={card.photo} alt={card.name} size={'sm'} />
                    }
                    data={[
                      { title: card.name }, 
                      { title: card.office }, 
                      { desc: `Matrícula: ${card.register}` }, 
                      { desc: `OS: ${card.os}` }, 
                      { desc: `Pontuação: ${card.score}` },
                    ]}
                    color={card.competencyColor}
                    size={'md'}
                  />

                ))}

              </PAVContentStyled>

            </PAVRowStyled>


          </ContainerView>

        </Content>

      )}
      

      {!loading && error && (

        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />

      )}

    </Screen>

  );
};



export default PAV_DestaquesScreens;
