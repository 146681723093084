import * as Yup from 'yup';


const UnitCreateValidationSchema = Yup.object().shape({

  select_code: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  select_id_unidade: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_valor: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

});



export default UnitCreateValidationSchema;
