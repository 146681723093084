import React, { ReactElement } from 'react';



import {
  TablePrinterStyled,
} from '../../../common/styles/styled.layout';



import { intranet_config } from '../../../utils/Config';
import { maskToMoney3 } from '../../../utils/CurrencyUtils';
import {
  PrinterContainer,
  A4LandscapeContent,
  TitleCenter,
  CellCenter,
  Row,
  RowInfo,
  ColInfo,
  HeaderSpace,
  BodySpace,
  FooterSpace,
  CellLogo,
  ImgLogo,
  TitleGeneralContainer,
  TitleGeneral,
  TitleAfterBody,
  ContentNotes,
  ContentObsv,
  ContentOthers,
} from './styled';


export interface IProps {
  list?: Array<any>;
}



const PCPrintComponent: React.FC<IProps> = (props: IProps) => {
  function createPaginationItens(): ReactElement {
    let qtdItens = 10;

    const allPages: any = [];
    let page = [];
    let endPage = props.list[1]?.ITENS.length <= 10 ? qtdItens = props.list[1]?.ITENS.length : (qtdItens); // 10



    if (props.list[1]?.ITENS.length <= 10) { // Casp a pagina tenha apenas 10 itens
      props.list[1]?.ITENS.forEach((item) => {
        page.push(item);
      });
      allPages.push(page);
    }
    else if (props.list[1]?.ITENS.length > 10) { // Casp a pagina tenha mais de 10 itens
      props.list[1]?.ITENS.forEach((item, index) => {
        if (endPage >= (index+1)) {
          page.push(item);
        }

        if (endPage === (index+1)) {
          allPages.push(page);

          endPage = endPage + qtdItens;
          page = [];

          if (endPage > props.list[1]?.ITENS.length) {
            endPage = props.list[1]?.ITENS.length;
          }
        }
      });
    }


    return (
      <>
        {
          allPages.map((page) => (
            < A4LandscapeContent >

              <HeaderSpace>

                <table>

                  <tbody>

                    <Row>

                      {/* LOGO */}
                      <CellLogo rowSpan={4} className="image-Cell">
                        <ImgLogo
                          alt="Logo"
                          src={intranet_config.Images.logo}
                        />
                      </CellLogo>


                      {/* EMPRESA */}
                      <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                        <TitleGeneral> Pedido de Compra </TitleGeneral>

                        <RowInfo>EMPRESA: {props.list[0]?.CAB[0]?.EMPRESA}</RowInfo>


                        <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO}</RowInfo>


                        <RowInfo>

                          <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE} </ColInfo>


                          <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF} </ColInfo>


                          <ColInfo marginLeftInfo={0}> CEP: {props.list[0]?.CAB[0]?.CEP} </ColInfo>

                        </RowInfo>

                        <RowInfo>

                          <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL} </ColInfo>


                          <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX} </ColInfo>

                        </RowInfo>

                        <RowInfo>
                          <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ} </ColInfo>
                          <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE} </ColInfo>
                        </RowInfo>

                      </TitleGeneralContainer>


                      {/* FORNECEDOR */}
                      <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                        <TitleGeneral> Fornecedor </TitleGeneral>

                        <RowInfo>

                          <ColInfo> EMPRESA: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </ColInfo>


                          <ColInfo> CODIGO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </ColInfo>


                          <ColInfo> LOJA: {props.list[0]?.CAB[0]?.LOJA_FORNECEDOR} </ColInfo>

                        </RowInfo>


                        <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO_FORNECEDOR}</RowInfo>


                        <RowInfo>

                          <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE_FORNECEDOR} </ColInfo>


                          <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF_FORNECEDOR} </ColInfo>


                          <ColInfo marginLeftInfo={7}> CEP: {props.list[0]?.CAB[0]?.CEP_FORNECEDOR} </ColInfo>

                        </RowInfo>

                        <RowInfo>

                          <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL_FORNECEDOR} </ColInfo>


                          <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX_FORNECEDOR} </ColInfo>

                        </RowInfo>

                        <RowInfo>
                          <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ_FORNECEDOR} </ColInfo>
                          <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE_FORNECEDOR} </ColInfo>
                        </RowInfo>

                      </TitleGeneralContainer>


                      {/* PEDIDO */}
                      <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                        <RowInfo>

                          <Row> FILIAL: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </Row>


                          <Row> PEDIDO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </Row>

                        </RowInfo>

                      </TitleGeneralContainer>

                    </Row>


                  </tbody>
                </table>
              </HeaderSpace>


              <TitleAfterBody>Itens</TitleAfterBody>

              <BodySpace>

                <TablePrinterStyled>

                  <tbody>

                    <Row>

                      <TitleCenter>Item</TitleCenter>
                      <TitleCenter>Produto</TitleCenter>
                      <TitleCenter>Descrição</TitleCenter>
                      <TitleCenter>UM</TitleCenter>
                      <TitleCenter>Qtd</TitleCenter>
                      <TitleCenter>Valor</TitleCenter>
                      <TitleCenter>IPI</TitleCenter>
                      <TitleCenter>Total</TitleCenter>
                      <TitleCenter>Entrada</TitleCenter>
                      <TitleCenter>SC</TitleCenter>
                      <TitleCenter>PC</TitleCenter>
                      <TitleCenter>Filial</TitleCenter>

                    </Row>

                    {page.map((item) => (
                      <Row>
                        <CellCenter>{item.ITEM}</CellCenter>
                        <CellCenter>{item.PRODUTO}</CellCenter>
                        <CellCenter>{item.DESCRICAO}</CellCenter>
                        <CellCenter>{item.UM}</CellCenter>
                        <CellCenter>{item.QUANTIDADE}</CellCenter>
                        <CellCenter>{maskToMoney3(item.VALOR)}</CellCenter>
                        <CellCenter>{maskToMoney3(item.IPI)}</CellCenter>
                        <CellCenter>{maskToMoney3(item.TOTAL)}</CellCenter>
                        <CellCenter>{item.DATA_ENT}</CellCenter>
                        <CellCenter>{item.NUMSC}</CellCenter>
                        <CellCenter>{item.PEDIDO}</CellCenter>
                        <CellCenter>{item.FILIAL}</CellCenter>
                      </Row>
                    ))}

                  </tbody>

                </TablePrinterStyled>

                <TablePrinterStyled>

                  <ContentOthers>

                    <TitleGeneral> Outros </TitleGeneral>


                    <RowInfo>Status: {props.list[2]?.RODAPE[0]?.STATUS}</RowInfo>


                    <RowInfo>Obs: {props.list[2]?.RODAPE[0]?.OBS}</RowInfo>


                    <RowInfo>Obs Frete: {props.list[2]?.RODAPE[0]?.OBS_FRETE}</RowInfo>

                  </ContentOthers>

                </TablePrinterStyled>

              </BodySpace>


              <FooterSpace>

                <table>

                  <tbody>

                    <Row>

                      <TitleGeneralContainer width={200} rowSpan={4} className="name-printer-Cell">

                        <TitleGeneral> Entrega </TitleGeneral>


                        <RowInfo>Entrega: {props.list[2]?.RODAPE[0]?.LOCAL_ENTREGA}</RowInfo>


                        <RowInfo> CEP: {props.list[2]?.RODAPE[0]?.CEP_ENTREGA}</RowInfo>


                        <RowInfo> Cidade: {props.list[2]?.RODAPE[0]?.CIDADE_ENTREGA} - {props.list[2]?.RODAPE[0]?.UF_ENTREGA}</RowInfo>



                        <RowInfo>

                          <TitleGeneral> Cobrança </TitleGeneral>


                          <RowInfo>Cobrança: {props.list[2]?.RODAPE[0]?.LOCAL_COBRANCA}</RowInfo>


                          <RowInfo> CEP: {props.list[2]?.RODAPE[0]?.CEP_COBRANCA} </RowInfo>


                          <RowInfo> Cidade: {props.list[2]?.RODAPE[0]?.CIDADE_COBRANCA} - {props.list[2]?.RODAPE[0]?.UF_COBRANCA}</RowInfo>

                        </RowInfo>


                      </TitleGeneralContainer>


                      <TitleGeneralContainer width={200} rowSpan={6} className="name-printer-Cell">

                        <RowInfo>

                          <TitleGeneral> Pagamento </TitleGeneral>


                          <RowInfo>Emissao: {props.list[2]?.RODAPE[0]?.DATA_EMISSAO}</RowInfo>


                          <RowInfo>Condição Pagamento: {props.list[2]?.RODAPE[0]?.COND_PGTO}</RowInfo>


                          <RowInfo>Total Mercadorias: {props.list[2]?.RODAPE[0]?.TOTAL_MERCADORIAS}</RowInfo>


                          <RowInfo>T. Mercad. Impostos: {props.list[2]?.RODAPE[0]?.TOTAL_MERCADORIAS_IMPOSTOS}</RowInfo>


                          <RowInfo>Frete: {props.list[2]?.RODAPE[0]?.FRETE} </RowInfo>


                          <RowInfo>Despesas: {props.list[2]?.RODAPE[0]?.DESPESAS}</RowInfo>


                          <RowInfo> ICMS: {props.list[2]?.RODAPE[0]?.ICMS} </RowInfo>


                          <RowInfo> Seguro: {props.list[2]?.RODAPE[0]?.SEGURO} </RowInfo>


                          <RowInfo> T. Geral: {props.list[2]?.RODAPE[0]?.TOTAL_GERAL}</RowInfo>


                        </RowInfo>

                      </TitleGeneralContainer>


                      <TitleGeneralContainer rowSpan={4} className="name-printer-Cell">
                        <Row>

                          <TitleGeneral> Envolvidos </TitleGeneral>


                          <RowInfo>Comprador: {props.list[2]?.RODAPE[0]?.COMPRADOR}</RowInfo>


                          <RowInfo>Aprovadores: {props.list[2]?.RODAPE[0]?.APROVADORES}</RowInfo>

                        </Row>


                        <RowInfo margin={10}>

                          <TitleGeneral> Pedido </TitleGeneral>


                          <RowInfo>Numero: {props.list[2]?.RODAPE[0]?.PEDIDO}</RowInfo>


                          <RowInfo>Filial: {props.list[2]?.RODAPE[0]?.FILIAL}</RowInfo>

                        </RowInfo>

                      </TitleGeneralContainer>

                    </Row>


                  </tbody>

                </table>

              </FooterSpace>

            </A4LandscapeContent >
          ))
        }
      </>
    );
  };


  function renderComponentNotes(): ReactElement {
    return (
      <A4LandscapeContent>

        <HeaderSpace>

          <table>

            <tbody>

              <Row>

                {/* LOGO */}
                <CellLogo rowSpan={4} className="image-Cell">
                  <ImgLogo
                    alt="Logo"
                    src={intranet_config.Images.logo}
                  />
                </CellLogo>


                {/* EMPRESA */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <TitleGeneral> Pedido de Compra </TitleGeneral>

                  <RowInfo>EMPRESA: {props.list[0]?.CAB[0]?.EMPRESA}</RowInfo>


                  <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO}</RowInfo>


                  <RowInfo>

                    <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE} </ColInfo>


                    <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF} </ColInfo>


                    <ColInfo marginLeftInfo={0}> CEP: {props.list[0]?.CAB[0]?.CEP} </ColInfo>

                  </RowInfo>

                  <RowInfo>

                    <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL} </ColInfo>


                    <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX} </ColInfo>

                  </RowInfo>

                  <RowInfo>
                    <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ} </ColInfo>
                    <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE} </ColInfo>
                  </RowInfo>

                </TitleGeneralContainer>


                {/* FORNECEDOR */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <TitleGeneral> Fornecedor </TitleGeneral>

                  <RowInfo>

                    <ColInfo> EMPRESA: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </ColInfo>


                    <ColInfo> CODIGO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </ColInfo>


                    <ColInfo> LOJA: {props.list[0]?.CAB[0]?.LOJA_FORNECEDOR} </ColInfo>

                  </RowInfo>


                  <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO_FORNECEDOR}</RowInfo>


                  <RowInfo>

                    <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> CEP: {props.list[0]?.CAB[0]?.CEP_FORNECEDOR} </ColInfo>

                  </RowInfo>

                  <RowInfo>

                    <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX_FORNECEDOR} </ColInfo>

                  </RowInfo>

                  <RowInfo>
                    <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ_FORNECEDOR} </ColInfo>
                    <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE_FORNECEDOR} </ColInfo>
                  </RowInfo>

                </TitleGeneralContainer>


                {/* PEDIDO */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <RowInfo>

                    <Row> FILIAL: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </Row>


                    <Row> PEDIDO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </Row>

                  </RowInfo>

                </TitleGeneralContainer>

              </Row>

            </tbody>

          </table>

        </HeaderSpace>


        <TitleAfterBody>{'Notas Gerais'.toUpperCase()}</TitleAfterBody>

        <BodySpace>

          <ContentNotes>

            {props.list[3]?.NOTAS[0]?.NOTAS_GERAIS}

          </ContentNotes>

        </BodySpace>

      </A4LandscapeContent>
    );
  };


  function renderComponentTerm(): ReactElement {
    return (
      <A4LandscapeContent>

        <HeaderSpace>

          <table>

            <tbody>

              <Row>

                {/* LOGO */}
                <CellLogo rowSpan={4} className="image-Cell">
                  <ImgLogo
                    alt="Logo"
                    src={intranet_config.Images.logo}
                  />
                </CellLogo>


                {/* EMPRESA */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <TitleGeneral> Pedido de Compra </TitleGeneral>

                  <RowInfo>EMPRESA: {props.list[0]?.CAB[0]?.EMPRESA}</RowInfo>


                  <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO}</RowInfo>


                  <RowInfo>

                    <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE} </ColInfo>


                    <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF} </ColInfo>


                    <ColInfo marginLeftInfo={0}> CEP: {props.list[0]?.CAB[0]?.CEP} </ColInfo>

                  </RowInfo>

                  <RowInfo>

                    <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL} </ColInfo>


                    <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX} </ColInfo>

                  </RowInfo>

                  <RowInfo>
                    <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ} </ColInfo>
                    <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE} </ColInfo>
                  </RowInfo>

                </TitleGeneralContainer>


                {/* FORNECEDOR */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <TitleGeneral> Fornecedor </TitleGeneral>

                  <RowInfo>

                    <ColInfo> EMPRESA: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </ColInfo>


                    <ColInfo> CODIGO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </ColInfo>


                    <ColInfo> LOJA: {props.list[0]?.CAB[0]?.LOJA_FORNECEDOR} </ColInfo>

                  </RowInfo>


                  <RowInfo> END: {props.list[0]?.CAB[0]?.ENDERECO_FORNECEDOR}</RowInfo>


                  <RowInfo>

                    <ColInfo> CIDADE: {props.list[0]?.CAB[0]?.CIDADE_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> UF: {props.list[0]?.CAB[0]?.UF_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> CEP: {props.list[0]?.CAB[0]?.CEP_FORNECEDOR} </ColInfo>

                  </RowInfo>

                  <RowInfo>

                    <ColInfo> TEL: {props.list[0]?.CAB[0]?.TEL_FORNECEDOR} </ColInfo>


                    <ColInfo marginLeftInfo={7}> FAX: {props.list[0]?.CAB[0]?.FAX_FORNECEDOR} </ColInfo>

                  </RowInfo>

                  <RowInfo>
                    <ColInfo> CNPJ: {props.list[0]?.CAB[0]?.CNPJ_FORNECEDOR} </ColInfo>
                    <ColInfo marginLeftInfo={7}> IE: {props.list[0]?.CAB[0]?.IE_FORNECEDOR} </ColInfo>
                  </RowInfo>

                </TitleGeneralContainer>


                {/* PEDIDO */}
                <TitleGeneralContainer rowSpan={2} className="name-printer-Cell">

                  <RowInfo>

                    <Row> FILIAL: {props.list[0]?.CAB[0]?.RAZAO_FORNECEDOR} </Row>


                    <Row> PEDIDO: {props.list[0]?.CAB[0]?.CODIGO_FORNECEDOR} </Row>

                  </RowInfo>

                </TitleGeneralContainer>

              </Row>


            </tbody>
          </table>
        </HeaderSpace>


        <TitleAfterBody>{'Termos e Condições Gerais Integrantes do Pedido de Compra'.toUpperCase()}</TitleAfterBody>

        <BodySpace>

          <ContentObsv>

            {props.list[4]?.TERMOS[0]?.TERMOS}

          </ContentObsv>

        </BodySpace>

      </A4LandscapeContent>
    );
  };



  return (
    <PrinterContainer>

      {createPaginationItens()}


      {renderComponentNotes()}


      {renderComponentTerm()}


    </PrinterContainer >
  );
};



export default PCPrintComponent;
