import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Colors } from '../../../../../../common/constants';
import { TextTooltip } from '../../../../../../common/constants/TextTooltips';
import { ContentStyled, FilterContainerForm } from '../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../components/Buttons/IButton';
import { IconAdd, IconReturn, IconReturn2, IconSend } from '../../../../../../components/Icons';
import { IToast } from '../../../../../../components/IToast/Toast';
import TextInput from '../../../../../../components/TextFields/Input';
import { RowStyled, FooterRowButton, LabelFlexText } from '../PAV-Reconhecimento.styled';
import { TitleGroupPAV } from './styled';


interface IProps {
  values?: any;
  handleSubmit?: any;
  handleBlur?: any;
  handleChange?: any;
  setFieldValue?: any;
  resetForm?: any;
  isValid?: any;
  setCurrentTab?: any;
  setCompetencySelected?: any;
  postSendCompetency?: any;
}


const Tab_Enviar: React.FC<IProps> = ({ values, handleBlur, handleChange, setCurrentTab, setCompetencySelected, isValid, postSendCompetency }) => {
  const InputTextEmail = (sizeListInputEmail) => (
    <TextInput
      type={'email'}
      name={`EMAIL_ENVIO[${sizeListInputEmail}]`}
      label={`Email ${sizeListInputEmail === 0 ? 1 : sizeListInputEmail}`}
      placeholder={'Digite o e-mail'}
      value={values?.EMAIL_ENVIO[sizeListInputEmail]?.toLowerCase()}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );

  const [listFieldEmail, setListFieldEmail] = useState([InputTextEmail(0)]);

  function addInputRender() {
    setListFieldEmail((list) => [...list, InputTextEmail(listFieldEmail.length)]);
  };



  return (
    <>
      <FilterContainerForm auto >

        <ContentStyled fluid>

          <Container>

            <TitleGroupPAV>
              {'Chegou a hora de enviar a competência.'.toUpperCase()}
            </TitleGroupPAV>

            <RowStyled>
              <LabelFlexText>Se precisar enviar um e-mail para outras pessoas, basta adicionar na lista abaixo.</LabelFlexText>


              {listFieldEmail}

              <Col>

                <ButtonTableAction
                  key={'right'}
                  placement={'right'}
                  tooltip={TextTooltip.EDIT_PHOTO}
                  icon={<IconAdd size={28} color={Colors?.buttonOk} />}
                  onClick={() => {
                    addInputRender();
                  }}
                />

              </Col>


            </RowStyled>


            <FooterRowButton>

              <Col>
                <IButton
                  type={'reset'}
                  backgroundColor={Colors?.gray}
                  text={'Refazer'}
                  iconLeft={
                    <IconReturn2 color={Colors?.white} size={18} />
                  }
                  onClick={() => {
                    setCurrentTab(0);
                    setCompetencySelected(-1);
                  }}
                />
              </Col>



              <Col>
                <IButton
                  type={'button'}
                  backgroundColor={Colors?.gray}
                  text={'Anterior'}
                  iconLeft={
                    <IconReturn />
                  }
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                />
              </Col>



              <Col>
                <IButton
                  type={'button'}
                  backgroundColor={Colors?.primary}
                  text={'Enviar'}
                  iconRigth={
                    <IconSend size={18} color={Colors?.white} />
                  }
                  onClick={() => {
                    if (isValid) {
                      postSendCompetency(values);
                    }

                    if (!isValid) {
                      IToast({
                        type: 'warning',
                        message: 'Existem campos obrigatórios que não foram preenchidos. Por favor revise as informações.',
                      });
                    }
                  }}
                />
              </Col>

            </FooterRowButton>

          </Container>

        </ContentStyled>

      </FilterContainerForm>

    </>

  );
};

export default Tab_Enviar;
