const ActiveDes = [
  {
    key: '1',
    label: 'Desativado',
    value: '0',
  },
  {
    key: '2',
    label: 'Ativo',
    value: '1',
  },
];



export default ActiveDes;
