import React from 'react';
import PFFTableDetails from '../../../PFF_Table-Details';



interface IProps {
  releaseType?: any;
}


const Tab_ResumoScreen: React.FC<IProps> = (props) => {
  return (
    <>
      <PFFTableDetails releaseType={props.releaseType} />
    </>
  );
};



export default Tab_ResumoScreen;
