import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';
import RPInclusao from '../../../../../../common/arrays/RPInclusao';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import {
  IconSearch,
} from '../../../../../../components/Icons';

import EscolaridadeModal from '../../../../../../components/Modals/EscolherItem/Escolaridade';
import FuncaoModal from '../../../../../../components/Modals/EscolherItem/Funcao';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';

import { RootState } from '../../../../../../store';
import { currentDateToInput, formataParaInputDate } from '../../../../../../utils/DateUtils';



const RPSectionRequisicao = ({ handleChange, handleBlur, values, setFieldValue }) => {
  const user = useSelector((state: RootState) => state.user.data);


  const [funcaoModalShow, setFuncaoModalShow] = React.useState(false);
  const [escolaridadeModalShow, setEscolaridadeModalShow] = React.useState(false);

  const [listOs, setListOs] = useState([]);
  const [listTipoRP, setListTipoRP] = useState([]);


  const [descricaoOs, setDescricaoOs] = useState('');

  const [funcao, setFuncao] = useState({ codigo: '', descricao: '' });
  const [cargo, setCargo] = useState({ codigo: '', descricao: '' });
  const [escolaridade, setEscolaridade] = useState({ codigo: '', descricao: '' });

  const [isEmergencial, setIsEmergencial] = useState(false);



  function returnObjDropDownOs(listOs: any) {
    const newList = listOs.map((itemOs: any) => {
      const valueOs = itemOs.cco_ds_centro_custo !== '' || itemOs.cco_ds_centro_custo !== null || itemOs.cco_ds_centro_custo !== undefined ? itemOs.cco_ds_centro_custo : null;

      const groupOsMapped = {
        'key': itemOs.cco_vl_centro_custo,
        'label': itemOs.cco_vl_centro_custo.replaceAll(' ', ''),
        'value': valueOs.replaceAll(' ', ''),
      };
      return groupOsMapped;
    });

    setListOs([
      // emptyList,
      ...newList,
    ]);
  };


  const findOS = async (consulta: any) => {
    try {
      const url = '/rp/nova_rp/consulta/os/';
      const response = await ApiWS().post(url, consulta);

      returnObjDropDownOs(response.data.sort());
    }
    catch (error) {
      console.log('findOS', JSON.stringify(error.response, null, 2));
    }
  };


  const findTipoRP = async () => {
    try {
      const url = '/rp/nova_rp/consulta/prazo';
      const response = await ApiWS().post(url);

      if (response.status === 200) {
        response.data.forEach((prazo) => {
          setListTipoRP((listTipoRP) => [...listTipoRP, {
            key: prazo.ID,
            label: `${prazo.TIPO} - ${prazo.PRAZO} dias`,
            value: `${prazo.ID};${prazo.PRAZO};${prazo.DIAREF}`,
          }]);
        });
      }
    }
    catch (error) {
      console.log('findTipoRP', JSON.stringify(error.response, null, 2));
    }
  };



  useEffect(() => {
    findOS({ 'consulta': user.id });
    findTipoRP();
    setFieldValue('solicitante', user.name);
    setFieldValue('data_abertura', currentDateToInput());
    // setFieldValue('data_prev_atend', currentDateToInput());
  }, []);



  return (

    <div>
      <TitleGroup>
        {'Requisição'.toUpperCase()}
      </TitleGroup>


      <Row>
        <Col>
          <DropdownInput
            name={'requisicao_os'}
            label={'CC'}
            placeholder={'Selecione ...'}
            dropArray={listOs}
            value={descricaoOs}
            onChange={(e: any) => {
              const selectComponentValue = e.target.value;
              const selectComponentTarget = [...e.target.children];
              const optionSelected = selectComponentTarget.filter((item) => item.selected === true);

              handleChange(e);

              setFieldValue('requisicao_os', optionSelected[0].label);

              setDescricaoOs(selectComponentValue);
              setFieldValue('desc_cc', selectComponentValue);
            }}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <DropdownInput
            name={'tiporp'}
            label={'Tipo'}
            placeholder={'Selecione ...'}
            dropArray={listTipoRP}
            value={values.tiporp}
            onChange={(e) => {
              handleChange(e);

              const idTipo = e.target.value.split(';')[0];
              const diasParaSoma = parseInt(e.target.value.split(';')[1]);
              const diaREF = parseInt(e.target.value.split(';')[2]);

              if(diaREF === 0){
                setFieldValue('data_prev_atend', currentDateToInput(diasParaSoma));
              }
              else {
                const date = new Date();
                date.setDate(diaREF + diasParaSoma);

                setFieldValue('data_prev_atend', date.toISOString().split('T')[0]);
              }

              setFieldValue('id_tipo_rp', String(idTipo));

              if (idTipo === '2') {
                setIsEmergencial(true);
              }
              else {
                setIsEmergencial(false);
              }
            }}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            disabled
            type={'text'}
            name={'desc_cc'}
            label={'Desc. CC'}
            placeholder={'Descrição'}
            value={descricaoOs}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'solicitante'}
            label={'Solicitante'}
            value={user.name}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      {isEmergencial && (
        <Row>
          <TextInput
            type={'text'}
            name={'justificativa_tipo'}
            label={'Justificativa Tipo'}
            placeholder={'Caso a RP seja emergencial é necessário justificar.'}
            value={values.justificativa_tipo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Row>
      )}



      <Row>
        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'input_funcao'}
            label={'Função'}
            placeholder={'Buscar...'}
            buttonIcon={
              <IconSearch
                color={'black'}
                size={20}
              />
            }
            buttonClick={() => {
              setFuncaoModalShow(true);
            }}
            value={funcao.codigo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'desc_funcao'}
            label={'Desc. Função'}
            placeholder={'Descrição'}
            value={funcao.descricao}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            editable={false}
            type={'date'}
            name={'data_abertura'}
            label={'Data Abertura'}
            value={currentDateToInput()}
          />
        </Col>


        <Col>
          <TextInput
            type={'date'}
            name={'data_prev_atend'}
            label={'Data Prev.Atend'}
            placeholder={'Descrição'}
            min={currentDateToInput(3)}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.data_prev_atend}
            disabled
          />
        </Col>
      </Row>


      <Row>
        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'input_cargo'}
            label={'Cargo'}
            placeholder={'Cargo'}
            value={cargo.codigo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'desc_cargo'}
            label={'Desc. Cargo'}
            placeholder={'Descrição'}
            value={cargo.descricao}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'date'}
            name={'data_necessidade'}
            label={'Data Necessidade'}
            placeholder={'Descrição'}
            min={currentDateToInput(3)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'number'}
            name={'vagas'}
            label={'Vagas'}
            placeholder={'Número de Vagas'}
            onChange={handleChange}
            onBlur={handleBlur}
            min={1}
          />
        </Col>
      </Row>



      <Row>

        <Col>
          <DropdownInput
            label={'Tipo Contrato'}
            name={'tipo_contrato'}
            placeholder={'Selecione ...'}
            dropArray={[
              {
                key: '01',
                label: 'Efetivo',
                value: '01',
              },
              {
                key: '02',
                label: 'Temporário',
                value: '02',
              },
              {
                key: '03',
                label: 'Estagiário',
                value: '03',
              },
              {
                key: '04',
                label: 'Outros',
                value: '04',
              },
            ]}
            onChange={handleChange}
          />
        </Col>


        <Col>
          <DropdownInput
            label={'Prazo Contrato'}
            name={'prazo_contrato'}
            placeholder={'Selecione ...'}
            dropArray={[
              {
                key: '1',
                label: 'Indeterminado',
                value: '1',
              },
              {
                key: '2',
                label: 'Determinado',
                value: '2',
              },
              {
                key: '3',
                label: 'Intermitente',
                value: '3',
              },
            ]}
            onChange={handleChange}
          />
        </Col>


        <Col>
          <TextInput
            editable={
              values.prazo_contrato === '2'
            }
            type={'date'}
            name={'data_fim_contrato'}
            label={'Data Fim Contrato'}
            placeholder={'Fim Contrato'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'number'}
            name={'valor_salario'}
            label={'Valor Salário R$'}
            placeholder={'Valor'}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>

      </Row>



      <Row>

        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'escolaridade'}
            label={'Escolaridade'}
            placeholder={'Buscar...'}
            buttonIcon={
              <IconSearch
                color={'black'}
                size={20}
              />
            }
            buttonClick={() => {
              setEscolaridadeModalShow(true);
            }}
            value={escolaridade.codigo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            editable={false}
            type={'text'}
            name={'desc_escolaridade'}
            label={'Desc. Escolaridade'}
            placeholder={'Descrição'}
            value={escolaridade.descricao}
            onBlur={handleBlur}
          />
        </Col>


        <Col>
          <TextInput
            type={'text'}
            name={'formacao_profissional'}
            label={'Formação Profissional'}
            placeholder={'Formação Profissional'}
            onChange={handleChange}
            onBlur={handleBlur}
            max={41}
          />
        </Col>

      </Row>



      <FuncaoModal
        show={funcaoModalShow}
        onHide={() => {
          setFuncaoModalShow(false);
        }}
        funcao={async (resultObj) => {
          setFuncao({
            codigo: resultObj.funcao.RJ_FUNCAO,
            descricao: resultObj.funcao.RJ_DESC,
          });

          setCargo({
            codigo: resultObj.cargo[0]?.Q3_CARGO,
            descricao: resultObj.cargo[0]?.Q3_DESCSUM,
          });

          setFieldValue('input_funcao', resultObj.funcao.RJ_FUNCAO);
          setFieldValue('desc_funcao', resultObj.funcao.RJ_DESC);

          setFieldValue('input_cargo', resultObj.cargo[0].Q3_CARGO);
          setFieldValue('desc_cargo', resultObj.cargo[0].Q3_DESCSUM);
        }}
      />



      <EscolaridadeModal
        show={escolaridadeModalShow}
        onHide={() => {
          setEscolaridadeModalShow(false);
        }}
        escolaridade={(escolaridade) => {
          setEscolaridade({
            codigo: escolaridade.X5_CHAVE,
            descricao: escolaridade.X5_DESCRI,
          });

          setFieldValue('escolaridade', escolaridade.X5_CHAVE);
          setFieldValue('desc_escolaridade', escolaridade.X5_DESCRI);
        }}
      />

    </div>

  );
};



export default RPSectionRequisicao;
