import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';

import {
  Screen, TabIconStyled, TabsStyled, TabTextStyled, TabViewStyled,
} from '../../../../common/styles/styled.layout';

import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import { IconBranch, IconEffective, IconMoney, IconTwilight } from '../../../../components/Icons';
import Tab_Faturamento from './PG_Tabs/001PG_FaturamentoTab';
import Tab_Mapao from './PG_Tabs/002PG_MapaoTab';
import Tab_Pessoal from './PG_Tabs/003PG_PessoalTab';
import Tab_PFF from './PG_Tabs/004PG_PFFTab';



const PG_IndexScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);


  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconMoney />,
        text: 'Faturamento',
      },
      screenTab: <Tab_Faturamento />,
    },
    {
      eventKey: 1,
      title: {
        icon: <IconBranch />,
        text: 'Mapão',
      },
      screenTab: <Tab_Mapao />,
    },
    {
      eventKey: 2,
      title: {
        icon: <IconEffective />,
        text: 'Pessoal',
      },
      screenTab: <Tab_Pessoal />,
    },
    {
      eventKey: 3,
      title: {
        icon: <IconTwilight />,
        text: 'PFF',
      },
      screenTab: <Tab_PFF />,
    },

  ];

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          title={'Painel Gerencial'}
        />
      </NavHeader>



      <TabsStyled
        id={'control-tab-pcb'}
        activeKey={currentTab}
        onSelect={(tab) => setCurrentTab(parseInt(tab))}
      >

        {tabsList.map((tab) => (
          <Tab
            eventKey={tab.eventKey}
            title={
              <TabViewStyled>

                <TabIconStyled>
                  {tab.title.icon}
                </TabIconStyled>



                <TabTextStyled>
                  {tab.title.text}
                </TabTextStyled>

              </TabViewStyled>
            }>

            {tab.screenTab}


          </Tab>
        ))}

      </TabsStyled>


    </Screen>

  );
};



export default PG_IndexScreen;
