const GiTipoFunc = [
  {
    key: '1',
    label: 'Funcionário',
    value: 'Funcionario',
  },
  {
    key: '2',
    label: 'Terceiro',
    value: 'Terceiro',
  },
];



export default GiTipoFunc;
