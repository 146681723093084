const PAVReconhecimentoModel = {
  QTDESTRELAS: 0,

  NOME: '',
  CARGO: '',
  OS: '',
  MAT: '',
  PARTICIPA: '',
  RESTRICAO_PRAZO: '',
  RESTRICAO_DEPARTAMENTO: '',
  SETOR: '',
  EMAIL: '',
  EMAIL_ENVIO: [],
  FOTO: '',
  
  PILAR: '',

  MENSAGEM: '',
};
  
  
  
export default PAVReconhecimentoModel;
