import React from 'react';

import {
  EmptyContentView,
  EmptyImage,
  EmptyTitle,
  EmptyDescription,
} from './styled';



export interface IProps {
  image?: any;

  title?: string;
  titleSize?: number;

  description?: string;
  descriptionSize?: number;

  onClick?: any;
}



const EmptyContent: React.FC<IProps> = (props) => {
  return (

    <EmptyContentView onClick={() => {
      if (props.onClick) props.onClick();
    }}>

      {props.image && (
        <EmptyImage
          alt={'Empty Image'}
          src={props.image}
        />
      )}

      {props.title && (
        <EmptyTitle
          style={{
            fontSize: props.titleSize || 14,
          }}>
          {props.title}
        </EmptyTitle>
      )}

      {props.description && (
        <EmptyDescription
          style={{
            fontSize: props.descriptionSize || 13.5,
          }}>
          {props.description}
        </EmptyDescription>
      )}

    </EmptyContentView>

  );
};



export default EmptyContent;
