const ICencCentroCustoModel = {
  select_situacao: 'TODOS',
  input_un_neg: '',
  input_os: '',
  input_rro: '',
  input_descricao: '',
  input_gestor: '',
};



export default ICencCentroCustoModel;
