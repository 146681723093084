import styled from 'styled-components';

import { Col, Dropdown, Row ,
  Form,
} from 'react-bootstrap';
import { Colors, Metrics } from '../../../../../../common/constants';



interface IProps {
  loading?: boolean;
  backgroundColor?: string;
  heightAuto?: boolean;
}

export const FieldAreaRowStyled = styled(Row)`
  padding-bottom: 0.5em;
  margin-left: 2.5em;
`;


export const ButtonAreaColStyled = styled(Col)`
  margin-top: 0.65em;
  margin-left: 1em;
`;


export const ButtonSend = styled(Dropdown.Toggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: ${Metrics.inputHeight}px;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary} !important;
  color: white !important;
  font-size: 1.125rem;
  border: none;
  border-radius: ${Metrics.inputRadius}px !important;
  z-index: 2 !important;

  :hover,
  :active {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.hoverLink} !important;
  }
  :focus {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary} !important;
    box-shadow: none !important;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    pointer-events: none;
    opacity: .65;
  }

  span {
    margin: 0 8px;
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  :active {
    background-color: ${Colors?.primaryDark};
  }
`;


export const CardImportContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props: IProps) => !props.heightAuto && 'height: 160px'};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;

export const CardImportHeader = styled.div`
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
`;

export const CardImportTitle = styled.h3`
  margin-bottom: 3px;
  color: rgb(33, 37, 41);
  font-size: 1.18rem;
`;

export const CardImportBody = styled.h3`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
`;


export const CardImportFormCheck = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  margin-bottom: -5px;
  padding: 5px;

  .form-switch {
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 8px;
  }
`;

export const FormCheckStyled = styled(Form.Check)`
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 1rem;
`;

export const ButtonSyncView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
`;



