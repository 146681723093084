const IRpInclusaoModel = {

  // Requisição

  requisicao_os: '',

  desc_cc: '',

  prazo: '',

  solicitante: '',

  input_funcao: '',

  desc_funcao: '',

  data_abertura: '',

  data_prev_atend: '',

  input_cargo: '',

  desc_cargo: '',

  data_necessidade: '',

  vagas: '',

  tipo_contrato: '',

  prazo_contrato: '',

  data_fim_contrato: '',

  valor_salario: '',

  escolaridade: '',

  desc_escolaridade: '',

  formacao_profissional: '',

  statusAprovador: '',

  justificativa_tipo: '',

  id_tipo_rp: '',


  // Dados Complementares

  motivo_solicitacao: '',

  justificativa: '',

  peric_insal: '',

  beneficios: '',

  ajuda_custo: '',

  desc_sumaria: '',

  espec_conhec: '',

  caracteristicas: '',

  obs_gerais: '',

  grupo_atendimento: '300001',

  desc_grupo_atendimento: 'RP-ESCRITORIO CENTRAL-BA',


  // Soldagem

  soldador: '',

  aco_carbono: '',

  aco_inox: '',

  chapa: '',

  ligas_niquel: '',

  titanio: '',

  aluminio: '',

  materiais_outros: '',

  eletrodo_revestido: '',

  a_tubular: '',

  tig: '',

  mig: '',

  mag: '',

  processos_outros: '',
};



export default IRpInclusaoModel;
