import Metrics from './Metrics';

// Error
export const RETURNS = {

  // Sucesso
  Success201:                       'Criado',
  Success202:                       'Aceito',

  // Erros
  Error400:                         'Requisição inválida. O pedido não pôde ser entregue devido à sintaxe incorreta',
  Error401:                         'Não autorizado',
  Error403:                         'Proibido',
  Error404:                         'Não encontrado',
  Error405:                         'Método não permitido',

  // Outros erros
  Other500:                         'Erro interno do servidor',
  Other501:                         'Não implementado',
  Other502:                         'Bad Gateway',
  Other503:                         'Serviço indisponível',
  Other504:                         'Gateway Time-Out',
};


const limitDefaultMessage = 'Total de caracteres deve ser: ';
export const FIELD_RESTRICTIONS = {
  input_bm:                         `${limitDefaultMessage} ${Metrics.input_bm}`,
  input_centroCusto:                `${limitDefaultMessage} ${Metrics.input_centroCusto}`,
  input_cnpj:                       `${limitDefaultMessage} ${Metrics.input_cnpj}`,
  input_contrato:                   `${limitDefaultMessage} ${Metrics.input_contrato}`,
  input_descAnexo:                  `${limitDefaultMessage} ${Metrics.input_descAnexo}`,
  input_descComp:                   `${limitDefaultMessage} ${Metrics.input_descComp}`,
  input_filial:                     `${limitDefaultMessage} ${Metrics.input_filial}`,
  input_op:                         `${limitDefaultMessage} ${Metrics.input_op}` ,
  input_pedido:                     `${limitDefaultMessage} ${Metrics.input_pedido}`,
  input_planilha:                   `${limitDefaultMessage} ${Metrics.input_planilha}`,
  input_rev:                        `${limitDefaultMessage} ${Metrics.input_rev}`,
  input_sme:                        `${limitDefaultMessage} ${Metrics.input_sme}`,
  input_solicitante:                `${limitDefaultMessage} ${Metrics.input_solicitante}`,
  input_numberOnly:                        'Somente números!',
  input_numberOnlyInteger:                 'Somente números inteiros!',
};


const MESSAGES = {
  ...RETURNS,
  ...FIELD_RESTRICTIONS,
};


export default MESSAGES;
