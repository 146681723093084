import styled from 'styled-components';

import {
  Screen,
} from '../../../../../common/styles/styled.layout';


interface IProps {
  marginLeftInfo?: number;
}


export const PrinterContainer = styled(Screen)`
`;

export const A4LandscapeContent = styled.div`
`;

export const TitleGeneral = styled.th`
  font-size: 1.75rem;
  text-align: start;
`;

export const TitleHeader = styled.th`
`;

export const TitleCenter = styled.th`
  text-align: center;
  vertical-align: bottom !important;
`;

export const Cell = styled.td`
`;

export const RowInfo = styled.div`
  font-size: 12px;
  font-weight: normal !important;
`;

export const ColInfo = styled.span`
  margin-left: ${(props: IProps) => props.marginLeftInfo}px;
`;

export const CellCenter = styled.td`
  text-align: center
`;

export const CellCenterBold = styled.td`
  text-align: center;
  font-weight: bold;
`;

export const Row = styled.tr`
`;

export const ImgLogStyle = styled.img`
  max-width: 17rem;
  max-height: 17rem;
  padding: 2rem 2rem;
`;
