const COLUMNS = { ORCADO: '0.00', ORCADO_ORIGINAL: '0.00', ORCADO_PLANEJADO: '0.00', PREVISTO: '0.00', ANT: '0.00', JAN: '0.00', FEV: '0.00', MAR: '0.00', ABR: '0.00', MAI: '0.00', JUN: '0.00', JUL: '0.00', AGO: '0.00', SET: '0.00', OUT: '0.00', NOV: '0.00', DEZ: '0.00', POS: 0.00 };


export const PffGridModel = {
  ANO: '',
  NEGOCIO: '',
  CC: '',
  COD_LANCAMENTO: 0,
  LANCAMENTO: 0,
  STATUS: '',
  DESCSTATUS: '',
  REV: '000',
  INICIO: '',
  PRAZO: '',
  TAXA_ISS: 0,
  TAXA_PIS_COFINS: 0,
  TAXA_ICMS_IPI: 0,
  TAXA_DESONERACAO: 0,
  VALOR_ORIGINAL: 0,
  VALOR_VIGENTE: 0,

  MARGEM_REAL: COLUMNS,
  MARGEM_PORCENTAGEM: COLUMNS,
};


export const PffFaturamentoModel = {
  DIRETO: COLUMNS,
  CONTRATUAL_FATURAMENTO: COLUMNS,
  REAJUSTE: COLUMNS,
  EXTRA: COLUMNS,
  TOTAL_FATURAMENTO: COLUMNS,
};


export const PffReceitasRecebiveisModel = {
  MOBILIZACAO: COLUMNS,
  INDIRETOS: COLUMNS,
  REVENDA: COLUMNS,
  SERVICO_AVANCO_FISICO: COLUMNS,
  EXTRA_RECEITAS_RECEB: COLUMNS,
  OUTRAS_RECEITAS_RECEB: COLUMNS,
  TOTAL_RECEITAS_RECEB: COLUMNS,
};

export const PffReceitasControleModel = {
  BMSI_POSITIVO: COLUMNS,
  BMSI_NEGATIVO: COLUMNS,
  NOTAS_DE_DEBITOS: COLUMNS,
  OUTRAS_RECEITAS_CONTR: COLUMNS,
  TOTAL_RECEITAS_CONTR: COLUMNS,
};

export const PffImpostosDiretosModel = {
  ISS: COLUMNS,
  PIS_COFINS: COLUMNS,
  ICMS_IPI: COLUMNS,
  DESONERACAO: COLUMNS,
  TOTAL_IMPOSTOS_DIRETOS: COLUMNS,
};

export const PffCustosModelCMD = {
  CMD01_SALARIOS: COLUMNS,
  CMD02_ENCARGOS: COLUMNS,
  CMD03_HORAS_EXTRAS_ADICIONAL: COLUMNS,
  TOTAL_CUSTOS_CMD: COLUMNS,
};
export const PffCustosModelCMI = {
  CMI01_SALARIOS: COLUMNS,
  CMI02_ENCARGOS: COLUMNS,
  CMI03_HORAS_EXTRAS_ADICIONAL: COLUMNS,
  TOTAL_CUSTOS_CMI: COLUMNS,
};
export const PffCustosModelCAP = {
  CAP01_TRANSPORTE: COLUMNS,
  CAP02_ALIMENTACAO: COLUMNS,
  CAP03_EPI_E_UNIFORMES: COLUMNS,
  CAP04_ALOJAMENTO: COLUMNS,
  CAP05_CURSOS_E_TREINAMENTO: COLUMNS,
  CAP06_ASSISTENCIA_MEDICA: COLUMNS,
  CAP07_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CAP: COLUMNS,
};
export const PffCustosModelCMM = {
  CMM01_DESPESAS_COM_VIAGENS: COLUMNS,
  CMM02_FRETES_TRANSPORTES: COLUMNS,
  CMM03_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CMM: COLUMNS,
};
export const PffCustosModelCCE = {
  CCE01_INSTALACAO_CONSTRUCAO: COLUMNS,
  CCE02_MANUTENCAO: COLUMNS,
  CCE03_ALUGUEIS: COLUMNS,
  CCE04_COMUNICACAO: COLUMNS,
  CCE05_INFORMATICA_TI: COLUMNS,
  CCE06_MATERIAIS_DE_ESCRITORIO: COLUMNS,
  CCE07_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CCE: COLUMNS,
};
export const PffCustosModelCEQ = {
  CEQ01_AQUISICAO: COLUMNS,
  CEQ02_LOCACAO: COLUMNS,
  CEQ03_MANUTENCAO: COLUMNS,
  CEQ04_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CEQ: COLUMNS,
};
export const PffCustosModelCMA = {
  CMA01_DIVERSOS: COLUMNS,
  CMA02_CIVIL: COLUMNS,
  CMA03_TUBULACAO: COLUMNS,
  CMA04_CALDEIRARIA_ESTRUTURA: COLUMNS,
  CMA05_ELETRICA_INSTRUMENTACAO: COLUMNS,
  CMA06_ISOLAMENTO: COLUMNS,
  CMA07_PINTURA: COLUMNS,
  TOTAL_CUSTOS_CMA: COLUMNS,
};
export const PffCustosModelCMC = {
  CMC01_MATERIAL_DE_CONSUMO: COLUMNS,
  CMC02_GASES: COLUMNS,
  CMC03_ELETRODOS_E_VARETAS: COLUMNS,
  TOTAL_CUSTOS_CMC: COLUMNS,
};
export const PffCustosModelCMV = {
  CMV01_MERC_VEND: COLUMNS,
  TOTAL_CUSTOS_CMV: COLUMNS,
};
export const PffCustosModelCSB = {
  CSB01_DIVERSOS: COLUMNS,
  CSB02_CIVIL: COLUMNS,
  CSB03_TUBULACAO: COLUMNS,
  CSB04_CALDEIRARIA_ESTRUTURA: COLUMNS,
  CSB05_ELETRICA_INSTRUMENTACAO: COLUMNS,
  CSB06_ISOLAMENTO: COLUMNS,
  CSB07_PINTURA: COLUMNS,
  TOTAL_CUSTOS_CSB: COLUMNS,
};
export const PffCustosModelCDG = {
  CDG01_TAXAS_DIVERSAS: COLUMNS,
  CDG02_PREMIACOES_PATROCINIO: COLUMNS,
  CDG03_SEGUROS_MULTAS_JUROS: COLUMNS,
  CDG04_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CDG: COLUMNS,
};
export const PffCustosModelCRC = {
  CRC01_CUSTOS_DE_MOD: COLUMNS,
  CRC02_CUSTOS_DE_MOI: COLUMNS,
  CRC03_CUSTOS_DE_APOIO: COLUMNS,
  CRC04_CUSTOS_DE_MOBILIZACAO: COLUMNS,
  CRC05_CUSTOS_DE_CANTEIRO: COLUMNS,
  CRC06_CUSTOS_DE_EQUIPAMENTOS: COLUMNS,
  CRC07_CUSTOS_DE_MATERIAIS: COLUMNS,
  CRC08_CUSTOS_DE_SUBCONTRATOS: COLUMNS,
  CRC09_CUSTOS_FINANCEIROS: COLUMNS,
  CRC10_OUTROS: COLUMNS,
  TOTAL_CUSTOS_CRC: COLUMNS,
};
export const PffCustosModel = {
  ...PffCustosModelCMD,
  ...PffCustosModelCMI,
  ...PffCustosModelCAP,
  ...PffCustosModelCMM,
  ...PffCustosModelCCE,
  ...PffCustosModelCEQ,
  ...PffCustosModelCMA,
  ...PffCustosModelCMC,
  ...PffCustosModelCMV,
  ...PffCustosModelCSB,
  ...PffCustosModelCDG,
  ...PffCustosModelCRC,
};

export const PffHhModModel = {
  MOD_HH_NORMAIS: COLUMNS,
  MOD_HH_EXTRAS: COLUMNS,
  TOTAL_HH_MOD: COLUMNS,
};

export const PffHhMoiModel = {
  MOI_HH_NORMAIS: COLUMNS,
  MOI_HH_EXTRAS: COLUMNS,
  TOTAL_HH_MOI: COLUMNS,
};

export const PffHhTerceirosModel = {
  HH_TERCEIROS: COLUMNS,
  TOTAL_HH_TERCEIROS: COLUMNS,
};

export const PffAvancoModel = {
  RECEITA: COLUMNS,
  FISICO: COLUMNS,
  MEDICAO: COLUMNS,
  TOTAL_AVANCO: COLUMNS,
};

export const PffAdiantamentosModel = {
  CONTRATUAL_ADIANT: COLUMNS,
  OUTROS_ADIANTAMENTOS: COLUMNS,
  TOTAL_ADIANTAMENTOS: COLUMNS,
};

export const PffEfetivoModel = {
  EFETIVO: COLUMNS,
  TOTAL_EFETIVO: COLUMNS,
};

// ORCAO //PARA LANCAMENTOS (ORIGINAL OU PLANEJADO)
// ORCADO_ORIGINAL_E_ORCADO_PLANEJADO: QUANDO FOR MONTAR O RESUMO

