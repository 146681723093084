const PffConsultaModel = {
  ANO: '2022',
  CC: 'TODOS',
  NEGOCIO: 'TODOS',
  STATUS: '10',
};



export default PffConsultaModel;
