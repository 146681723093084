import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import ReactToPrint from 'react-to-print';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../common/constants';

import {
  ContainerView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import PStockQrValidationSchema from '../../../../../../common/validations/Supplies/PSTOCK/pstock-qr.validation';

import IButton from '../../../../../../components/Buttons/IButton';

import {
  IconArchiveImport,
} from '../../../../../../components/Icons';

import {
  IToast,
} from '../../../../../../components/IToast/Toast';

import QrCodeComponent from '../../../../../../components/QrCode';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';

import IDropdownItem from '../../../../../../interfaces/dropdown-item.interface';

import IPStockQrModel from '../../../../../../models/Supplies/PSTOCK/pstock-qr.model';

import ApiWS from '../../../../../../services/api.service';

import {
  validateString,
} from '../../../../../../utils/StringUtils';

import {
  CardImportContainer,
  CardImportHeader,
  CardImportTitle,
  CardImportBody,
  DropdownView,
  ButtonSyncView,
} from './styled';



const Tab_QrCodeScreen: React.FC = () => {
  const buttonProduto = useRef(null);
  const buttonFuncionario = useRef(null);
  const buttonDeposito = useRef(null);


  const [loadingConfigList, setLoadingConfigList] = useState(false);

  const [depositoList, setDepositoList] = useState(new Array<IDropdownItem>());
  const [osList, setOsList] = useState(new Array<IDropdownItem>());

  const [qrProdutoList, setQrProdutoList] = useState<Array<any>>([]);
  const [qrFuncionarioList, setQrFuncionarioList] = useState<Array<any>>([]);
  const [qrDepositoList, setQrDepositoList] = useState<Array<any>>([]);



  function renderDepositoList(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.ID !== '' || unit.ID !== null || unit.ID !== undefined ? unit.ID : null;

      const OsMapped: IDropdownItem = {
        'key': String(unit.ID).trim(),
        'label': String(unit.NOME).trim(),
        'value': String(valueOs).trim(),
      };
      return OsMapped;
    });

    setDepositoList([
      ...listOsMap,
    ]);
  };



  function renderOsList(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.CC !== '' || unit.CC !== null || unit.CC !== undefined ? unit.CC : null;

      const OsMapped: IDropdownItem = {
        'key': String(unit.ID).trim(),
        'label': String(unit.CC).trim(),
        'value': String(valueOs).trim(),
      };
      return OsMapped;
    });

    setOsList([
      ...listOsMap,
    ]);
  };



  async function getDepositos() {
    try {
      const url = '/pstock/adm/qrcode/consulta/deposito';
      const response = await ApiWS().post(url, {});

      return response.data;
    }
    catch (error) {
      console.error('getDepositos', JSON.stringify(error.response, null, 2));
    }
  };

  async function getOs() {
    try {
      const url = '/pstock/adm/qrcode/consulta/os';
      const response = await ApiWS().post(url, {});

      return response.data;
    }
    catch (error) {
      console.error('getOs', JSON.stringify(error.response, null, 2));
    }
  };


  async function functionGetListRoutes() {
    try {
      setLoadingConfigList(true);

      const [depositoReturn, osReturn] = await Promise.all([getDepositos(), getOs()]);

      renderDepositoList(depositoReturn);

      renderOsList(osReturn);
    }
    catch (error) {
      console.error('findUnNeg', JSON.stringify(error.response, null, 2));
      if (error.response) {
        IToast({
          message: error.response.status || 'Erro ao carregar listas',
          type: 'error',
        });
      }
    }
    finally {
      setLoadingConfigList(false);
    }
  };


  async function handleGenerateQr(values: typeof IPStockQrModel, typeEndpoint: string) {
    if (!validateString(values.input_qr)) {
      return;
    }

    if (validateString(values.input_qr)) {
      const payload = {
        ID: values.input_qr,
      };

      try {
        setLoadingConfigList(true);

        if (typeEndpoint === 'PRODUTO') {
          const url = '/pstock/adm/qrcode/produto';
          const response = await ApiWS().post(url, payload);

          if (response.data?.length === 0) {
            IToast({
              message: 'Nenhum dado para gerar QR Codes',
              type: 'warning',
            });
            setQrProdutoList([]);
            return;
          }

          setQrProdutoList(response.data);
        }

        if (typeEndpoint === 'FUNCIONARIO') {
          const url = '/pstock/adm/qrcode/funcionario';
          const response = await ApiWS().post(url, payload);

          if (response.data?.length === 0) {
            IToast({
              message: 'Nenhum dado para gerar QR Codes',
              type: 'warning',
            });
            setQrFuncionarioList([]);
            return;
          }

          setQrFuncionarioList(response.data);
        }

        if (typeEndpoint === 'DEPOSITO') {
          const url = '/pstock/adm/qrcode/deposito';
          const response = await ApiWS().post(url, payload);

          if (response.data?.length === 0) {
            IToast({
              message: 'Nenhum dado para gerar QR Codes',
              type: 'warning',
            });
            setQrDepositoList([]);
            return;
          }

          setQrDepositoList(response.data);
        }
      }
      catch (error) {
        console.error('generateProdutoQr', JSON.stringify(error.response, null, 2));
        if (error.response) {
          IToast({
            message: error.response.status || 'Erro ao gerar QRs Codes',
            type: 'error',
          });
        }
      }
      finally {
        setLoadingConfigList(false);
      }
    }
    else {
      IToast({
        message: 'Erro na solicitação',
        type: 'error',
      });
    }
  }



  useEffect(() => {
    functionGetListRoutes();
  }, []);



  return (

    <ContainerView>

      <SectionMargin>
        <TitleGroup>
          {'Gerar Qr Codes'.toUpperCase()}
        </TitleGroup>


        <SectionMargin>

          <Row>

            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={IPStockQrModel}
                validationSchema={PStockQrValidationSchema}
                onSubmit={() => null}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Produtos
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>
                      <DropdownView>
                        <DropdownInput
                          disabled={loadingConfigList}
                          name={'input_qr'}
                          label={'Depósito'}
                          placeholder={'Selecione ...'}
                          dropArray={depositoList}
                          value={values.input_qr || ''}
                          onChange={(e: any) => {
                            handleChange(e);
                            handleGenerateQr({ input_qr: e.target.value }, 'PRODUTO');
                          }}
                          onBlur={handleBlur}
                        />
                      </DropdownView>


                      <ButtonSyncView>
                        <>
                          <ReactToPrint
                            content={() => (buttonProduto as any).current}
                            trigger={() => (
                              <IButton
                                disabled={loadingConfigList || qrProdutoList.length <= 0}
                                backgroundColor={Colors?.secondary}
                                text={'Gerar Qr Code'}
                                iconLeft={
                                  <IconArchiveImport
                                    color={'#FFFFFF'}
                                    size={20}
                                  />
                                }
                              />
                            )}
                          />

                          <QrCodeComponent
                            ref={buttonProduto}
                            type={'MALOTE-PRODUTO'}
                            list={qrProdutoList}
                          />
                        </>
                      </ButtonSyncView>
                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>



            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={IPStockQrModel}
                validationSchema={PStockQrValidationSchema}
                onSubmit={() => null}>
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Funcionários
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>
                      <DropdownView>
                        <DropdownInput
                          disabled={loadingConfigList}
                          name={'input_qr'}
                          label={'Centro de Custo'}
                          placeholder={'Selecione ...'}
                          dropArray={osList}
                          value={values.input_qr || ''}
                          onChange={(e: any) => {
                            handleChange(e);
                            handleGenerateQr({ input_qr: e.target.value }, 'FUNCIONARIO');
                          }}
                          onBlur={handleBlur}
                        />
                      </DropdownView>


                      <ButtonSyncView>
                        <>
                          <ReactToPrint
                            content={() => (buttonFuncionario as any).current}
                            trigger={() => (
                              <IButton
                                disabled={loadingConfigList || qrFuncionarioList.length <= 0}
                                backgroundColor={Colors?.secondary}
                                text={'Gerar Qr Code'}
                                iconLeft={
                                  <IconArchiveImport
                                    color={'#FFFFFF'}
                                    size={20}
                                  />
                                }
                              />
                            )}
                          />

                          <QrCodeComponent
                            ref={buttonFuncionario}
                            type={'MALOTE-FUNCIONARIO'}
                            list={qrFuncionarioList}
                          />
                        </>
                      </ButtonSyncView>
                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>



            <Col>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={IPStockQrModel}
                validationSchema={PStockQrValidationSchema}
                onSubmit={() => null}>
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <CardImportContainer onSubmit={handleSubmit}>

                    <CardImportHeader>
                      <CardImportTitle>
                        Depósitos
                      </CardImportTitle>
                    </CardImportHeader>


                    <CardImportBody>
                      <DropdownView>
                        <DropdownInput
                          disabled={loadingConfigList}
                          name={'input_qr'}
                          label={'Depósito'}
                          placeholder={'Selecione ...'}
                          dropArray={depositoList}
                          value={values.input_qr || ''}
                          onChange={(e: any) => {
                            handleChange(e);
                            handleGenerateQr({ input_qr: e.target.value }, 'DEPOSITO');
                          }}
                          onBlur={handleBlur}
                        />
                      </DropdownView>


                      <ButtonSyncView>
                        <>
                          <ReactToPrint
                            content={() => (buttonDeposito as any).current}
                            trigger={() => (
                              <IButton
                                disabled={loadingConfigList || qrDepositoList.length <= 0}
                                backgroundColor={Colors?.secondary}
                                text={'Gerar Qr Code'}
                                iconLeft={
                                  <IconArchiveImport
                                    color={'#FFFFFF'}
                                    size={20}
                                  />
                                }
                              />
                            )}
                          />

                          <QrCodeComponent
                            ref={buttonDeposito}
                            type={'MALOTE-DEPOSITO'}
                            list={qrDepositoList}
                          />
                        </>
                      </ButtonSyncView>
                    </CardImportBody>

                  </CardImportContainer>
                )}
              </Formik>
            </Col>

          </Row>

        </SectionMargin>

      </SectionMargin>

    </ContainerView>

  );
};



export default Tab_QrCodeScreen;
