import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { Colors } from '../../../../../../common/constants';
import { ContentStyled, FilterContainerForm } from '../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../components/Buttons/IButton';
import { IconNext, IconReturn } from '../../../../../../components/Icons';
import TextInput from '../../../../../../components/TextFields/Input';
import { RowStyled, FooterRowButton } from '../PAV-Reconhecimento.styled';
import { TextHelp, TitleGroupPAV } from './styled';


interface IProps {
  values?: any;
  handleSubmit?: any;
  handleBlur?: any;
  handleChange?: any;
  setFieldValue?: any;
  setCurrentTab?: any;
}


const Tab_Reconhecimento: React.FC<IProps> = ({ values, handleBlur, handleChange, setCurrentTab }) => {
  return (
    <>
      <FilterContainerForm auto >

        <ContentStyled fluid>

          <Container>

            <TitleGroupPAV>
              {'Conte-nos a atitude ou comportamento que fez você escolher essa competência.'.toUpperCase()}
            </TitleGroupPAV>



            {(!values.MENSAGEM || values.MENSAGEM === '') && <TextHelp>Obrigatório</TextHelp>}
            <RowStyled help={!values.MENSAGEM || values.MENSAGEM === ''}>

              <TextInput
                name={'MENSAGEM'}
                placeholder={'Digite aqui a mensagem com no mínimo 500 caracteres.'}
                textHeight={100}
                min={0}
                max={500}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.MENSAGEM}
                totalChar={values?.MENSAGEM?.length}
              />

            </RowStyled>



            <FooterRowButton>
              <Col></Col>

              <Col>
                <IButton
                  type={'button'}
                  backgroundColor={Colors?.gray}
                  text={'Anterior'}
                  iconLeft={
                    <IconReturn />
                  }
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                />
              </Col>


              <Col>
                <IButton
                  disabled={!values.MENSAGEM}
                  type={'button'}
                  backgroundColor={Colors?.primary}
                  text={'Próximo'}
                  iconRigth={
                    <IconNext />
                  }
                  onClick={() => {
                    setCurrentTab(3);
                  }}
                />
              </Col>

            </FooterRowButton>

          </Container>

        </ContentStyled>

      </FilterContainerForm>

    </>

  );
};

export default Tab_Reconhecimento;
