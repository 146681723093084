const PcbManutencaoFilialModel = {
  input_pcbm: '',
  input_filial: '',
  input_filial_antiga: '',
  input_filial_nova: '',
};



export default PcbManutencaoFilialModel;
