import React, { useState } from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { YesNoArray } from '../../../../../../../common/arrays';
import ActiveDes from '../../../../../../../common/arrays/ActiveDes';
import GiTipoFunc from '../../../../../../../common/arrays/GiTipoFunc';
import { Colors } from '../../../../../../../common/constants';
import { Backgrounds } from '../../../../../../../common/constants/Images';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import { IconEditPhoto, IconCancel } from '../../../../../../../components/Icons';
import IPhoto from '../../../../../../../components/IPhoto';
import { IToast } from '../../../../../../../components/IToast/Toast';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { AreaEditPhoto, LabelPhoto, InputFilePhoto, ColPhotoSelfStyled, ModalFooterStyled } from '../../styled';



interface IProps {
  valuesConsult?: any;
  valuesEdit?: any;
  valuesCopy?: any;
  valuesRemove?: any;

  type?: any;

  onHide?: any;
  fetchNow?: any;
}



const UsuarioDataSection: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  function postSaveUser(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/usuario/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/usuario/alterar';
    }

    if (props.type === 'COPY') {
      url = 'gi/usuario/copiar/';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveUser', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  }

  function handleSaveUser(values) {
    setLoading(true);

    if (values.FOTO) {
      const lerArquivo = new FileReader();

      lerArquivo.onload = function (arquivoCarregando) {
        const imagemBase64 = arquivoCarregando.target.result;

        values = { ...values, FOTO: imagemBase64 };

        postSaveUser(values);
      };

      lerArquivo.readAsDataURL(values.FOTO[0]);
    }
    else {
      postSaveUser(values);
    }
  };

  function postRemoveUser(values) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveUser', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || props?.valuesConsult?.ID || props?.valuesCopy?.ID || '',
        MATRICULA: props?.valuesEdit?.MATRICULA || props?.valuesRemove?.MATRICULA || props?.valuesConsult?.MATRICULA || '',
        NOME: props?.valuesEdit?.NOME || props?.valuesRemove?.NOME || props?.valuesConsult?.NOME || '',
        LOGIN: props?.valuesEdit?.LOGIN || props?.valuesRemove?.LOGIN || props?.valuesConsult?.LOGIN || '',
        EMAIL: props?.valuesEdit?.EMAIL || props?.valuesRemove?.EMAIL || props?.valuesConsult?.EMAIL || '',
        TIPO: props?.valuesEdit?.TIPO || props?.valuesRemove?.TIPO || props?.valuesConsult?.TIPO || props?.valuesCopy?.TIPO || '',
        STATUS: props?.valuesEdit?.STATUS || props?.valuesRemove?.STATUS || props?.valuesConsult?.STATUS || props?.valuesCopy?.STATUS || '0',
        CHECKEMAIL: props?.valuesEdit?.CHECKEMAIL || props?.valuesRemove?.CHECKEMAIL || props?.valuesConsult?.CHECKEMAIL || props?.valuesCopy?.CHECKEMAIL || '0',
        FOTO: props?.valuesEdit?.FOTO || props?.valuesRemove?.FOTO || props?.valuesConsult?.FOTO || '',
        IDCOPIA: props?.valuesCopy?.ID || '',
      }}
      validationSchema={
        Yup.object().shape({
          ID: Yup.string(),
          MATRICULA: Yup.string(),
          NOME: Yup.string().required('Obrigatório'),
          LOGIN: Yup.string().required('Obrigatório'),
          EMAIL: Yup.string().required('Obrigatório'),
          TIPO: Yup.string().required('Obrigatório'),
          STATUS: Yup.string().required('Obrigatório'),
          CHECKEMAIL: Yup.string().required('Obrigatório'),
          FOTO: Yup.string(),
        })
      }
      onSubmit={(values) => {
        if (props?.type !== 'REMOVE') {
          handleSaveUser(values);
        }
        else if (props?.type === 'REMOVE') {
          postRemoveUser(values);
        }
      }}>
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (

        <Form onSubmit={handleSubmit}>
          <Row xs={12}>

            <Col xs={3}>
              <TextInput
                disabled
                name={'ID'}
                label={`ID${props.type === 'COPY' ? ' para cópia' : ''}`}
                type={'text'}
                placeholder={'Automático após salvar'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ID}
              />


              <TextInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'MATRICULA'}
                label={'Matrícula'}
                type={'text'}
                placeholder={'Matricula'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.MATRICULA}
              />



              <DropdownInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'STATUS'}
                label={'Status'}
                defaultValue={values.STATUS}
                dropArray={ActiveDes}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.STATUS}
              />



              <DropdownInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'CHECKEMAIL'}
                label={'Workflow'}
                defaultValue={values.CHECKEMAIL}
                dropArray={YesNoArray}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.CHECKEMAIL}
              />
            </Col>



            <Col xs={5}>
              <TextInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'NOME'}
                label={'Nome'}
                type={'text'}
                placeholder={'Nome'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.NOME}
              />



              <TextInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'EMAIL'}
                label={'Email'}
                type={'text'}
                placeholder={'Email'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.EMAIL}
              />



              <TextInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'LOGIN'}
                label={'Login'}
                type={'text'}
                placeholder={'Login'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.LOGIN}
              />



              <DropdownInput
                disabled={loading || props.type === 'REMOVE' || props.type === 'CONSULT'}
                name={'TIPO'}
                label={'Tipo'}
                placeholder={'Selecione'}
                defaultValue={values.TIPO}
                dropArray={GiTipoFunc}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.TIPO}
              />

            </Col>


            {props.type !== 'NEW' && (
              <ColPhotoSelfStyled xs={3}>
                <IPhoto
                  src={
                    !values?.FOTO && Backgrounds.backgroundAvatar
                    || (values?.FOTO && typeof (values?.FOTO) === 'string') && values?.FOTO
                    || (values?.FOTO && typeof (values?.FOTO) === 'object') && URL?.createObjectURL(values?.FOTO[0])
                  }
                  alt={values.NOME}
                  size={'md'}
                />

                {!loading && props.type === 'EDIT' || props.type === 'COPY' && (

                  <AreaEditPhoto>

                    <LabelPhoto htmlFor='photoSelf'>
                      <ButtonTableAction
                        key={'right'}
                        placement={'right'}
                        tooltip={TextTooltip.EDIT_PHOTO}
                        icon={<IconEditPhoto size={25} />}
                      />

                      Editar

                      <InputFilePhoto
                        id='photoSelf'
                        type="file"
                        onChange={(e: any) => {
                          setFieldValue('FOTO', e.target.files);
                        }}
                        formEncType={'multipart/form-data'}
                        accept={'image/png,image/jpeg,image/jpg'}
                      />
                    </LabelPhoto>


                    {values.FOTO && (
                      <>
                        <LabelPhoto margin={'auto -109px auto 50px'}>
                          <ButtonTableAction
                            key={'right'}
                            placement={'right'}
                            tooltip={TextTooltip.CANCEL}
                            icon={<IconCancel size={29} />}
                            onClick={() => {
                              setTimeout(() => {
                                setFieldValue('FOTO', '');
                              }, 300);
                            }}
                          />

                          Limpar
                        </LabelPhoto>
                      </>
                    )}


                  </AreaEditPhoto>

                )}
              </ColPhotoSelfStyled>
            )}

          </Row>


          <ModalFooterStyled>

            <IButton
              backgroundColor={Colors?.buttonCancel}
              text={'Voltar'}
              onClick={() => props.onHide()}
            />

            {props.type !== 'CONSULT' && (

              <IButton
                disabled={loading}
                backgroundColor={Colors?.buttonOk}
                text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                type={'submit'}
              />

            )}

          </ModalFooterStyled>

        </Form>

      )}

    </Formik>

  );
};



export default UsuarioDataSection;
