import { Row } from './../Modules/Supplies/PBC/PBC_Pc_Impressao/PBC-pc-impressao.styled';
import styled from 'styled-components';

import {
  Button,
} from 'react-bootstrap';

import {
  Form,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../common/constants';

import {
  Screen,
} from '../../common/styles/styled.layout';
import { intranet_config } from '../../utils/Config';
import { sl } from 'date-fns/locale';



export const LoginBody = styled(Screen)`
  background-image: url("${intranet_config?.Images?.background_login}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 95%;
`;

export const LoginFormView = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 400px;
  /* padding-top: 30px; */
  padding-left: 40px;
  padding-right: 40px;
  /* padding-bottom: 30px; */
  background-color: #f5f5f5;
  border-radius: ${Metrics.containerRadius}px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
`;

export const LoginView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 350px;
`;

export const LogoImgContent = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 160px;
  margin-top: -45px;
  padding: 5px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
`;

export const LogoImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const LoginTextsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const WelcomeTitle = styled.h1`
  margin: 0;
  color: #000000;
  font-size: 2rem;
  font-weight: 400;
`;

export const WelcomeText = styled.p`
  margin: 5px 1px 0;
  color: #000000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const LoginInputsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0 10px;
`;

export const ButtonLogin = styled(Button)`
  width: 100%;
  margin: 0;
  background-color:${Colors?.primary};
  border: 0;

  :focus,
  :visited,
  :active {
    background-color:${Colors?.primary};
  }

  :hover {
    background-color:${Colors?.hoverLink};
    color: ${Colors?.primary};
  }
`;

export const ContainerImgLogoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 83%;
  right: 0;
  left: 0;
  right: 0;
  margin-top: auto;
`;

export const ImgLogoFooter = styled.img`
  width: 120px;
`;

