import styled,
{
  css,
} from 'styled-components';
import { Colors } from '../../common/constants';



interface IProps {
  type?: string;
  color?: string;
  size?: string;
  fontSize?: string;
}



export const FooterStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const ScreenStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 20vh;
`;

export const FieldStyle = css`
  display: flex;
  flex: 1;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 1vh;
`;

export const LoadingContainer = styled.div`
  ${(props: IProps) => props.type === 'FOOTER' && FooterStyle};
  ${(props: IProps) => props.type === 'SCREEN' && ScreenStyle};
  ${(props: IProps) => props.type === 'FIELD' && FieldStyle};
  display: flex;
  align-items: center;
  flex-direction: column;

  .spinner-border {
    width: ${(props: IProps) => props.size};
    height: ${(props: IProps) => props.size};
    color: ${(props: IProps) => props.color};
  }

  padding: 2rem;

`;

export const AreaIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const AreaText = styled.div`
  /* margin-top: 5px; */
  font-size: ${(props: IProps) => props.fontSize} ;
  font-weight: bold;
  color: ${Colors?.textColor};
  text-align: center;
`;
