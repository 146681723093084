const PcbPedidosArray = [
  {
    key: '1',
    color: '#007a00',
    label: 'Liberado',
    value: 'Liberado'.toLocaleUpperCase(),
  },
  {
    key: '2',
    color: '#fa0000',
    label: 'Aguardando Aprovação',
    value: 'Aguardando Aprovação'.toLocaleUpperCase(),
  },
  {
    key: '6',
    color: '#000000',
    label: 'Possui Itens Atendidos',
    value: 'Possui Itens Atendidos'.toLocaleUpperCase(),
  },
  {
    key: '4',
    color: '#faa200',
    label: 'Possui Itens em Pré Nota',
    value: 'Possui Itens em Pré Nota'.toLocaleUpperCase(),
  },
  {
    key: '5',
    color: '#7d7d7d',
    label: 'Possui Itens em Resíduo',
    value: 'Possui Itens em Resíduo'.toLocaleUpperCase(),
  },
  {
    key: '3',
    color: '#FFF000',
    label: 'Possui Itens Atendido Parcial',
    value: 'Possui Itens Atendido Parcial'.toLocaleUpperCase(),
  },
  {
    key: '7',
    color: '#2540ef',
    label: 'Atendido Total',
    value: 'Atendido Total'.toLocaleUpperCase(),
  },
  {
    key: '8',
    color: '#55178f',
    label: 'Reprovado',
    value: 'Reprovado'.toLocaleUpperCase(),
  },
];

export const PcbTiposArray = [
  {
    key: '1',
    label: 'PC - Pedido de Compra',
    value: 'PC',
  },
  {
    key: '2',
    label: 'BM - Boletim de Medição',
    value: 'BM',
  },
  {
    key: '1',
    label: 'AE - Autorização de Entrega',
    value: 'AE',
  },
  {
    key: '2',
    label: 'MD - Medição Direta',
    value: 'MD',
  },
];

export default PcbPedidosArray;
