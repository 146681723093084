import React, { useState } from 'react';
import { Formik } from 'formik';
import LoadingsComponent from '../../../../../../../components/Loadings';
import { PffFilterTableResume } from '../../PFF-lancamento-styled';
import { TableResume, TrHeader, ThHead, TdBudgeted, ThHeadSub, TdBudgetedDIFF } from './styled';


interface IProps {
  releaseType?: any;
  loading?: any;
}


const Tab_ComparativoScreen: React.FC<IProps> = (props) => {
  const [loading] = useState(false);

  const localStorageFlagPendencia = localStorage.getItem('@pff/LANCAMENTO/FLAG/PENDENCIA');
  const localStorageFlagRealizado = localStorage.getItem('@pff/LANCAMENTO/FLAG/REALIZADO');
  const localStorageData = localStorageFlagRealizado === '1' ? JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA/REALIZADO')) : [];


  return (

    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{}}
      onSubmit={() => {
        console.log('');
      }}>
      {({
        handleSubmit,
      }) => (
        <>
          {(props.loading || loading && localStorageFlagPendencia === '0') && <LoadingsComponent />}


          {((!props.loading || !loading) && localStorageFlagPendencia === '0') &&
            <PffFilterTableResume onSubmit={handleSubmit}>

              <TableResume id={'tabela'} className="table table-bordered table-striped">

                <thead>
                  <TrHeader>

                    <ThHead></ThHead>
                    <ThHead colSpan={3}>JAN</ThHead>
                    <ThHead colSpan={3}>FEV</ThHead>
                    <ThHead colSpan={3}>MAR</ThHead>
                    <ThHead colSpan={3}>ABR</ThHead>
                    <ThHead colSpan={3}>MAI</ThHead>
                    <ThHead colSpan={3}>JUN</ThHead>
                    <ThHead colSpan={3}>JUL</ThHead>
                    <ThHead colSpan={3}>AGO</ThHead>
                    <ThHead colSpan={3}>SET</ThHead>
                    <ThHead colSpan={3}>OUT</ThHead>
                    <ThHead colSpan={3}>NOV</ThHead>
                    <ThHead colSpan={3}>DEZ</ThHead>

                  </TrHeader>

                </thead>

                <thead>
                  <TrHeader>

                    <ThHeadSub></ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                    <ThHeadSub>CG</ThHeadSub>
                    <ThHeadSub>PFF</ThHeadSub>
                    <ThHeadSub>DIF</ThHeadSub>

                  </TrHeader>

                </thead>


                <tbody>

                  {localStorageData.map((os) => (
                    <tr>
                      <TdBudgeted>{os.CC}</TdBudgeted>


                      <TdBudgeted>{os?.JAN[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.JAN[0]?.PFF}</TdBudgeted>
                      {os?.JAN[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.JAN[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.JAN[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.FEV[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.FEV[0]?.PFF}</TdBudgeted>
                      {os?.FEV[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.FEV[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.FEV[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.MAR[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.MAR[0]?.PFF}</TdBudgeted>
                      {os?.MAR[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.MAR[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.MAR[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.ABR[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.ABR[0]?.PFF}</TdBudgeted>
                      {os?.ABR[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.ABR[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.ABR[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.MAI[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.MAI[0]?.PFF}</TdBudgeted>
                      {os?.MAI[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.MAI[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.MAI[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.JUN[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.JUN[0]?.PFF}</TdBudgeted>
                      {os?.JUN[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.JUN[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.JUN[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.JUL[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.JUL[0]?.PFF}</TdBudgeted>
                      {os?.JUL[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.JUL[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.JUL[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.AGO[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.AGO[0]?.PFF}</TdBudgeted>
                      {os?.AGO[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.AGO[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.AGO[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.SET[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.SET[0]?.PFF}</TdBudgeted>
                      {os?.SET[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.SET[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.SET[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.OUT[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.OUT[0]?.PFF}</TdBudgeted>
                      {os?.OUT[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.OUT[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.OUT[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.NOV[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.NOV[0]?.PFF}</TdBudgeted>
                      {os?.NOV[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.NOV[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.NOV[0]?.DIFF}</TdBudgeted>)                      
                      }


                      <TdBudgeted>{os?.DEZ[0]?.CG}</TdBudgeted>
                      <TdBudgeted>{os?.DEZ[0]?.PFF}</TdBudgeted>
                      {os?.DEZ[0]?.DIFF !== 0 ? 
                        (<TdBudgetedDIFF>{os?.DEZ[0]?.DIFF}</TdBudgetedDIFF>)
                        :
                        (<TdBudgeted>{os?.DEZ[0]?.DIFF}</TdBudgeted>)                      
                      }
                    </tr>
                  ))}


                </tbody>

              </TableResume>


            </PffFilterTableResume >
          }
        </>
      )
      }
    </Formik >

  );
};



export default Tab_ComparativoScreen;

