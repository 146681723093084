import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Colors, Metrics } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import { Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconListNew, IconSearch } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import NameRoutes from '../../../../../navigation/name';
import { PIC_NavRoutes } from '../../../../../navigation/navs/SGI';

import ApiWS from '../../../../../services/api.service';
import { RootState } from '../../../../../store';
import { currentDateToInput } from '../../../../../utils/DateUtils';
import { GenerateButtonsSMEView } from '../../../Supplies/SME/SME_Solicitacao/SME_Nova_Copia/SME-solicitacao.styled';
import IPicCadastroModel from './PIC-cadastro.model';
import PICCadastroValidationSchema from './PIC-cadastro.validation';
import PICSectionA from './Sections/PICSectionA';
import PICSectionB from './Sections/PICSectionB';
import PICSectionC from './Sections/PICSectionC';
import PICSectionD from './Sections/PICSectionD';
import PICSectionE from './Sections/PICSectionE';

// import { Container } from './styles';



const PIC_CadastroScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const [formLoading, setFormLoading] = useState(false);
  const [areaLoading, setAreaLoading] = useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);

  const [progressModalShow, setProgressModalShow] = useState(false);
  const [valueProgressModal, setValueProgressModal] = useState(null);



  const [listOs, setListOs] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [listLeaders, setListLeaders] = useState([]);


  const [bloksBacklog, setBloksBacklog] = useState([]);
  const [bloksRecognitions, setBloksRecognitions] = useState([]);


  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });

  async function getOsFetch () {
    setFormLoading(true);
    try {
      const url = '/pic/cartao/consulta/os/';
      const resp = await ApiWS().get(url);

      resp.data.map((resp: any, index: any) => (
        setListOs((listOs) => [
          ...listOs,
          {
            key: index,
            label: resp.OS.trim(),
            value: resp.OS.trim(),
          },
        ])
      ));
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      toast.error('Ocorreu um erro! Contate o suporte.', {
        position: 'bottom-center',
        autoClose: Metrics.messageAutoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
    }
    finally {
      setFormLoading(false);
    }
  };


  async function getAreaFetch  (values: any) {
    setListAreas([]);
    setAreaLoading(true);

    const url = `/pic/cartao/consulta/area/${values}`;
    console.log(url);
    await ApiWS()
      .get(url)

      .then((resp) => {
        resp.data.length === 0 && resp.status === 200 &&
        toast.warning(`Não existem áreas cadastradas neste Centro de Custo: ${values}.`, {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoCloseDouble,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        resp.data.map((resp: any, index: any) => (
          setListAreas((listAreas) => [
            ...listAreas, {
              key: index,
              label: resp.DESCR.trim(),
              value: resp.ID,
            },
          ])
        ));
      })

      .catch((error) => {
        console.error('getAreaFetch', JSON.stringify(error.response, null, 2));

        toast.error('Ocorreu um erro ao consultar as Áreas! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(error);
      })

      .finally(() => {
        setAreaLoading(false);
      });
  };


  async function getLeaderFetch  (values: any) {
    setAreaLoading(true);

    const url = `/pic/equipe/consulta/equipe/${values}`;
    await ApiWS()
      .get(url)

      .then((resp) => {
        setListLeaders([]);

        resp.data.length === 0 && resp.status === 200 &&
          toast.warning(`Não existem equipes cadastradas neste Centro de Custo: ${values}.`, {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoCloseDouble,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

        resp.data.map((leader: any, index: any) => {
          return (

            setListLeaders((listLeaders) => [
              ...listLeaders,
              {
                key: index,
                label: leader.LIDER,
                value:  [leader.ID, leader.LIDER, leader.DESCR],
              },
            ])

          );
        });
      })

      .catch((error) => {
        if (!error.response) {
          console.error('getLeaderFetch', JSON.stringify(error.response, null, 2));
          toast.error('Erro! Contate o suporte!.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
        else {
          toast.error('Erro! Contate o suporte!.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })

      .finally(() => {
        setAreaLoading(false);
      });
  };


  async function postRegistrationFetch(value: any, setFieldValue: any){
    const payload =  { MATRICULA: value };
    const url = '/pic/cartao/auditor';

    if(value.length >= 6 && value !== undefined && value !== '') {
      setRegistrationLoading(true);
      await ApiWS()
        .post(url, payload)

        .then((resp) => {
          if(resp.status === 200 && resp.data.length > 0) {
            setFieldValue('AUDITOR', resp.data[0].NOME);
            setFieldValue('FUNCAO', resp.data[0].FUNCAO);
          }
          else {
            IToast({
              type: 'warning',
              message: 'Verifique se a matrícula está correta.',
            });
          }
        })

        .catch((err) => {
          toast.error('Ocorreu um erro ao consultar registro! Contate o suporte.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          console.error(err);
        })

        .finally(() => {
          setRegistrationLoading(false);
        });
    }

    if(value.length === 0 || value === undefined || value === ''){
      IToast({
        type: 'warning',
        message: 'Digite a matrícula para consultar.',
      });
    }
  };


  function handleBloksBacklog (handleChange: any, handleBlur: any, values: any) {
    setBloksBacklog((bloksBacklog) => [
      ...bloksBacklog,
      <><Row>

        <Col>
          <TextInput
            name={`PENDENCIAS[${bloksBacklog.length + 1}].PENDENCIA`}
            placeholder={'Pendência'}
            textHeight={90}
            type={'text'}
            label={`Pendência ${bloksBacklog.length + 2}`}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.PENDENCIAS[bloksBacklog.length + 1]?.PENDENCIA} />
        </Col>

      </Row><Row>

        <Col>
          {!areaLoading &&
            <TextInput
              name={`PENDENCIAS[${bloksBacklog.length + 1}].RESP`}
              label={`Responsável ${bloksBacklog.length + 2}`}
              placeholder={'Responsável'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.PENDENCIAS[bloksBacklog.length + 1]?.RESP}
            />
          }
        </Col>

      </Row></>,
    ]);
  };


  function handleBloksRecognition (handleChange: any, handleBlur: any, setFieldValue: any) {
    setBloksRecognitions((bloksRecognitions) => [
      ...bloksRecognitions,
      <>
        <Row>
          <Col></Col>
          <Col>
            <TextInput
              type={'text'}
              name={`REC[${bloksRecognitions.length+1}].MAT`}
              label={`Matrícula ${bloksRecognitions.length+2}`}
              placeholder={'Número'}
              onBlur={handleBlur}
              onChange={(e:any) =>{
                handleChange(e);
                setFieldValue(`REC[${bloksRecognitions.length+1}].FUNC`, '');
                setFieldValue(`REC[${bloksRecognitions.length+1}].RECOM`, '');
              }}
            />
          </Col>
          <Col></Col>
        </Row>
      </>,
    ]);
  };


  function handleToPostPayload (values: any) {
    const cardList = [];
    let payload =  { CARTAO: [], PENDENCIAS: [], REC: [] };

    if (values) {
      // --------------- A -----------------
      if(values.A1_IDEQUIPE !== ''
      && values.A1_IDEQUIPE !== undefined
      && values.A1_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A1',
          'IDEQUIPE': parseInt(values.A1_IDEQUIPE),
          'NPESSOA': parseInt(values.A1_NPESSOA),
          'NDESVIO': parseInt(values.A1_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A2_IDEQUIPE !== ''
      && values.A2_IDEQUIPE !== undefined
      && values.A2_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A2',
          'IDEQUIPE': parseInt(values.A2_IDEQUIPE),
          'NPESSOA': parseInt(values.A2_NPESSOA),
          'NDESVIO': parseInt(values.A2_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A3_IDEQUIPE !== ''
      && values.A3_IDEQUIPE !== undefined
      && values.A3_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A3',
          'IDEQUIPE': parseInt(values.A3_IDEQUIPE),
          'NPESSOA': parseInt(values.A3_NPESSOA),
          'NDESVIO': parseInt(values.A3_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A4_IDEQUIPE !== ''
      && values.A4_IDEQUIPE !== undefined
      && values.A4_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A4',
          'IDEQUIPE': parseInt(values.A4_IDEQUIPE),
          'NPESSOA': parseInt(values.A4_NPESSOA),
          'NDESVIO': parseInt(values.A4_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A5_IDEQUIPE !== ''
      && values.A5_IDEQUIPE !== undefined
      && values.A5_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A5',
          'IDEQUIPE': parseInt(values.A5_IDEQUIPE),
          'NPESSOA': parseInt(values.A5_NPESSOA),
          'NDESVIO': parseInt(values.A5_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A6_IDEQUIPE !== ''
      && values.A6_IDEQUIPE !== undefined
      && values.A6_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A6',
          'IDEQUIPE': parseInt(values.A6_IDEQUIPE),
          'NPESSOA': parseInt(values.A6_NPESSOA),
          'NDESVIO': parseInt(values.A6_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A7_IDEQUIPE !== ''
      && values.A7_IDEQUIPE !== undefined
      && values.A7_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A7',
          'IDEQUIPE': parseInt(values.A7_IDEQUIPE),
          'NPESSOA': parseInt(values.A7_NPESSOA),
          'NDESVIO': parseInt(values.A7_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.A8_IDEQUIPE !== ''
      && values.A8_IDEQUIPE !== undefined
      && values.A8_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'A8',
          'IDEQUIPE': parseInt(values.A8_IDEQUIPE),
          'NPESSOA': parseInt(values.A8_NPESSOA),
          'NDESVIO': parseInt(values.A8_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- B -----------------
      if(values.B1_IDEQUIPE !== ''
      && values.B1_IDEQUIPE !== undefined
      && values.B1_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'B1',
          'IDEQUIPE': parseInt(values.B1_IDEQUIPE),
          'NPESSOA': parseInt(values.B1_NPESSOA),
          'NDESVIO': parseInt(values.B1_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.B2_IDEQUIPE !== ''
      && values.B2_IDEQUIPE !== undefined
      && values.B2_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'B2',
          'IDEQUIPE': parseInt(values.B2_IDEQUIPE),
          'NPESSOA': parseInt(values.B2_NPESSOA),
          'NDESVIO': parseInt(values.B2_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.B3_IDEQUIPE !== ''
      && values.B3_IDEQUIPE !== undefined
      && values.B3_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'B3',
          'IDEQUIPE': parseInt(values.B3_IDEQUIPE),
          'NPESSOA': parseInt(values.B3_NPESSOA),
          'NDESVIO': parseInt(values.B3_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.B4_IDEQUIPE !== ''
      && values.B4_IDEQUIPE !== undefined
      && values.B4_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'B4',
          'IDEQUIPE': parseInt(values.B4_IDEQUIPE),
          'NPESSOA': parseInt(values.B4_NPESSOA),
          'NDESVIO': parseInt(values.B4_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.B5_IDEQUIPE !== ''
      && values.B5_IDEQUIPE !== undefined
      && values.B5_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'B5',
          'IDEQUIPE': parseInt(values.B5_IDEQUIPE),
          'NPESSOA': parseInt(values.B5_NPESSOA),
          'NDESVIO': parseInt(values.B5_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- C -----------------
      if(values.C1_IDEQUIPE !== ''
      && values.C1_IDEQUIPE !== undefined
      && values.C1_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'C1',
          'IDEQUIPE': parseInt(values.C1_IDEQUIPE),
          'NPESSOA': parseInt(values.C1_NPESSOA),
          'NDESVIO': parseInt(values.C1_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.C2_IDEQUIPE !== ''
      && values.C2_IDEQUIPE !== undefined
      && values.C2_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'C2',
          'IDEQUIPE': parseInt(values.C2_IDEQUIPE),
          'NPESSOA': parseInt(values.C2_NPESSOA),
          'NDESVIO': parseInt(values.C2_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.C3_IDEQUIPE !== ''
      && values.C3_IDEQUIPE !== undefined
      && values.C3_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'C3',
          'IDEQUIPE': parseInt(values.C3_IDEQUIPE),
          'NPESSOA': parseInt(values.C3_NPESSOA),
          'NDESVIO': parseInt(values.C3_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.C4_IDEQUIPE !== ''
      && values.C4_IDEQUIPE !== undefined
      && values.C4_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'C4',
          'IDEQUIPE': parseInt(values.C4_IDEQUIPE),
          'NPESSOA': parseInt(values.C4_NPESSOA),
          'NDESVIO': parseInt(values.C4_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- D -----------------
      if(values.D1_IDEQUIPE !== ''
      && values.D1_IDEQUIPE !== undefined
      && values.D1_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'D1',
          'IDEQUIPE': parseInt(values.D1_IDEQUIPE),
          'NPESSOA': parseInt(values.D1_NPESSOA),
          'NDESVIO': parseInt(values.D1_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.D2_IDEQUIPE !== ''
      && values.D2_IDEQUIPE !== undefined
      && values.D2_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'D2',
          'IDEQUIPE': parseInt(values.D2_IDEQUIPE),
          'NPESSOA': parseInt(values.D2_NPESSOA),
          'NDESVIO': parseInt(values.D2_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }

      // --------------- E -----------------
      if(values.E1_IDEQUIPE !== ''
      && values.E1_IDEQUIPE !== undefined
      && values.E1_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'E1',
          'IDEQUIPE': parseInt(values.E1_IDEQUIPE),
          'NPESSOA': parseInt(values.E1_NPESSOA),
          'NDESVIO': parseInt(values.E1_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.E2_IDEQUIPE !== ''
      && values.E2_IDEQUIPE !== undefined
      && values.E2_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'E2',
          'IDEQUIPE': parseInt(values.E2_IDEQUIPE),
          'NPESSOA': parseInt(values.E2_NPESSOA),
          'NDESVIO': parseInt(values.E2_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.E3_IDEQUIPE !== ''
      && values.E3_IDEQUIPE !== undefined
      && values.E3_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'E3',
          'IDEQUIPE': parseInt(values.E3_IDEQUIPE),
          'NPESSOA': parseInt(values.E3_NPESSOA),
          'NDESVIO': parseInt(values.E3_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }


      if(values.E4_IDEQUIPE !== ''
      && values.E4_IDEQUIPE !== undefined
      && values.E4_IDEQUIPE !== null) {
        cardList.push({
          'CC': values.CC,
          'IDAREA': parseInt(values.IDAREA),
          'CODITEM': 'E4',
          'IDEQUIPE': parseInt(values.E4_IDEQUIPE),
          'NPESSOA': parseInt(values.E4_NPESSOA),
          'NDESVIO': parseInt(values.E4_NDESVIO),
          'OBS': values.OBS,
          'MAT': values.MAT,
          'AUDITOR': values.AUDITOR,
          'FUNCAO': values.FUNCAO,
          'DESVIOS': values.DESVIOS,
        });
      }
    }

    if(values){
      payload = {
        CARTAO: cardList,
        PENDENCIAS: values.PENDENCIAS,
        REC: values.REC,
      };
    };

    return payload;
  };


  async function postPicInclusion (values: any, resetForm: any, setValues: any) {
    let payload =  { CARTAO: [], PENDENCIAS: [], REC: [] };
    const url = '/pic/cartao/insere';

    payload = handleToPostPayload(values);

    if (payload?.CARTAO.length === 0) {
      setMessageValue({
        head: 'Erro!',
        orient: 'É necessário selecionar pelo menos 1 líder do cartão PIC.',
        orient2: 'Por favor revise o formulário!',
      });
      setMessageModal(true);
      // setFormLoading(false);
    }


    if(payload?.CARTAO.length > 0) {
      setFormLoading(true);
      await ApiWS()
        .post(url, new Array(payload))

        .then((resp) => {
          if(resp.status === 200) {
            resetForm();

            setValues(
              {
                ...IPicCadastroModel,
                DATA: currentDateToInput(),
              },
            );

            toast.success('Registro salvo com sucesso!', {
              position: 'bottom-center',
              autoClose: Metrics.messageAutoClose,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });

            if(resp.status === 200) {
              setProgressModalShow(true);
              setValueProgressModal(resp.data);
            }

            return true;
          }
        })

        .catch((err) => {
          toast.error('Ocorreu um erro ao criar registro! Contate o suporte.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          console.error(err);

          return false;
        })

        .finally(() => {
          setFormLoading(false);
        });
    };
  };


  const renderModalAfterPostInclusion = (values: any) => {
    return (
      <ConfirmationModal
        show={progressModalShow}
        head="Feito!"
        orient={`Cartão PIC ${values.NUMCARD} gerado com sucesso.`}
        others={
          <GenerateButtonsSMEView>
            <IButton
              backgroundColor={Colors?.tertiary}
              onClick={() => {
                window.open(`/${NameRoutes.PIC_Consulta_Impressao}/${values.NUMCARD}`, '_blank').focus();
              }}
              text={'Imprimir'}
            />

          </GenerateButtonsSMEView>
        }
        negative={() => {
          setProgressModalShow(false);
        }}
      />
    );
  };


  useEffect(() => {
    getOsFetch();
  }, []);


  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PICCadastroValidationSchema}
      initialValues={{
        ...IPicCadastroModel,
        USER: user.id,
        DATA: currentDateToInput(),
      }}
      onSubmit={(values, { resetForm, setValues }) => {
        postPicInclusion(values, resetForm, setValues);
      }}
    >
      {({
        isValid,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={1}
              itemKey={11}
              navArray={PIC_NavRoutes}
              title={'PIC'}
              subtitle={'Programa de Inspeção Comportamental'}
            />

          </NavHeader>

          <Content>

            {!formLoading &&

              <ContentStyled fluid >
                <Container>
                  {/* {JSON.stringify(values)} */}
                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <div>
                      <TitleGroup>
                        {'Formulário do PIC'.toUpperCase()}
                      </TitleGroup>

                      <Row>

                        <Col xs={3}>
                          <TextInput
                            type={'date'}
                            name={'DATA'}
                            min={currentDateToInput(-2)}
                            max={currentDateToInput()}
                            label={'Data'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DATA}
                          />
                        </Col>


                        <Col>
                          <DropdownInput
                            name={'CC'}
                            label={'Centro de Custo'}
                            placeholder={'Selecione o Centro de Custo'}
                            dropArray={[...listOs]}
                            onChange={(e: any) => {
                              handleChange(e);
                              getAreaFetch(e.target.value);
                              getLeaderFetch(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values.CC}
                          />
                        </Col>

                        <Col xs={4}>

                          <DropdownInput
                            disabled={areaLoading}
                            name={'IDAREA'}
                            label={'Área'}
                            placeholder={'Selecione a área'}
                            dropArray={[...listAreas]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.IDAREA}
                          />


                          {areaLoading && (
                            <div className="d-flex text-left">
                              <LoadingsComponent
                                size='10px'
                                type={'FIELD'}
                                color={Colors?.primary}
                                text={'Carregando Áreas'}
                              />
                            </div>
                          )}

                        </Col>



                      </Row>


                      <Row>

                        <Col xs={3}>
                          <TextInput
                            type={'text'}
                            name={'MAT'}
                            label={'Matrícula'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.MAT}
                            buttonIcon={
                              <IconSearch
                                color={'black'}
                                size={20}
                              />
                            }
                            buttonClick={() => {
                              postRegistrationFetch(values.MAT, setFieldValue);
                            }}
                          />


                          {registrationLoading && (
                            <div className="d-flex text-left">
                              <LoadingsComponent
                                size='10px'
                                type={'FIELD'}
                                color={Colors?.primary}
                                text={'Carregando Matrícula'}
                              />
                            </div>
                          )}
                        </Col>


                        <Col>
                          <TextInput
                            editable={false}
                            type={'text'}
                            name={'AUDITOR'}
                            label={'Auditor'}
                            placeholder={'Consulte a matrícula.'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.AUDITOR}
                          />
                        </Col>


                        <Col xs={4}>
                          <TextInput
                            editable={false}
                            type={'text'}
                            name={'FUNCAO'}
                            label={'Função'}
                            placeholder={'Consulte a matrícula.'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.FUNCAO}
                          />
                        </Col>


                      </Row>

                    </div>

                    {/* ------------------------ SECTIONS ----------------------------- */}

                    <PICSectionA
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      listLeaders={listLeaders}
                    />


                    <PICSectionB
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      listLeaders={listLeaders}
                    />

                    <PICSectionC
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      listLeaders={listLeaders}
                    />

                    <PICSectionD
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      listLeaders={listLeaders}
                    />

                    <PICSectionE
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      listLeaders={listLeaders}
                    />



                    {/* ------------------------ FIM ----------------------------- */}
                    <div>
                      <TitleGroup>
                        {'Pendências a Serem Tratadas'.toUpperCase()}
                      </TitleGroup>

                      <Row>

                        <Col>
                          <TextInput
                            name={'PENDENCIAS[0].PENDENCIA'}
                            textHeight={90}
                            type={'text'}
                            label={'Pendência 1'}
                            placeholder={'Pendência'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.PENDENCIAS[0].PENDENCIA}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col>
                          <TextInput
                            name={'PENDENCIAS[0].RESP'}
                            label={'Responsável 1'}
                            placeholder={'Responsável'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.PENDENCIAS[0].RESP}
                          />
                        </Col>

                      </Row>


                      {bloksBacklog &&
                        bloksBacklog.map((blok: any) => (
                          blok
                        ))
                      }


                      <Row>
                        <Col></Col>
                        <Col xs={1} style={{ marginTop: 15 }}>
                          <ButtonTableAction
                            onClick={() => handleBloksBacklog(handleChange, handleBlur, values)}
                            tooltip={TextTooltip.ADD_PENDENCY}
                            icon={
                              <IconListNew
                                color={Colors?.quaternary}
                                size={30}
                              />
                            }
                          />
                        </Col>
                        <Col></Col>
                      </Row>

                    </div>


                    <div>
                      <TitleGroup>
                        {'Desvio Crítico / Risco Iminente / Incidente ( Presenciado pelo Auditor )'.toUpperCase()}
                      </TitleGroup>

                      <Row>

                        <Col>
                          <TextInput
                            textHeight={150}
                            type={'text'}
                            name={'DESVIOS'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.DESVIOS}
                          />
                        </Col>

                      </Row>

                    </div>


                    <div>
                      <TitleGroup>
                        {'Reconhecimento de Trabalho Seguro - Matrículas dos Funcionários'.toUpperCase()}
                      </TitleGroup>


                      <Row>
                        <Row>
                          <Col></Col>
                          <Col>
                            <TextInput
                              type={'text'}
                              name={'REC[0].MAT'}
                              label={'Matrícula 1'}
                              placeholder={'Número'}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.REC[0].MAT}
                            />
                          </Col>
                          <Col></Col>

                        </Row>

                        {bloksRecognitions &&
                          bloksRecognitions.map((blok) => (
                            blok
                          ))
                        }

                        <Row>
                          <Col></Col>
                          <Col xs={1} style={{ marginTop: 15 }}>
                            <ButtonTableAction
                              onClick={() => handleBloksRecognition(handleChange, handleBlur, setFieldValue)}
                              tooltip={TextTooltip.ADD_MATRICULATION}
                              icon={
                                <IconListNew
                                  color={Colors?.quaternary}
                                  size={30}
                                />
                              }
                            />
                          </Col>
                          <Col></Col>
                        </Row>

                      </Row>


                    </div>


                    <div>
                      <TitleGroup>
                        {'Observações e Sugestões'.toUpperCase()}
                      </TitleGroup>

                      <Row>

                        <Col>
                          <TextInput
                            textHeight={150}
                            type={'text'}
                            name={'OBS'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.OBS}
                          />
                        </Col>

                      </Row>

                    </div>


                    <TitleGroup></TitleGroup>

                    <Row>

                      <RowButtonFiltersView>

                        <IButton
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() =>{
                            setBloksBacklog([]);
                            setBloksRecognitions([]);
                          }}
                        />


                        <IButton
                          disabled={formLoading}
                          text={'Salvar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                          onClick={() =>{
                            if(!isValid){
                              setMessageValue({
                                head: 'Erro!',
                                orient: 'Campos obrigatórios, não preenchidos e/ou iválidos.',
                                orient2: 'Por favor revise o formulário!',
                              });
                              setMessageModal(true);
                            }
                          }}
                        />

                      </RowButtonFiltersView>

                    </Row>

                  </FilterContainerForm>

                </Container>
              </ContentStyled>}

            {formLoading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}
          </Content>

          {
            messageModal && (
              <ConfirmationModal
                show={messageModal}
                head={messageValue.head}
                orient={messageValue.orient}
                orient2={messageValue.orient2}
                negative={() => {
                  setMessageModal(false);
                }}
              />
            )
          }


          {
            !listAreas || listAreas.length < 0 && (
              <ConfirmationModal
                show={messageModal}
                head={messageValue.head}
                orient={messageValue.orient}
                orient2={messageValue.orient2}
                negative={() => {
                  setMessageModal(false);
                }}
              />
            )
          }

          {progressModalShow &&
            renderModalAfterPostInclusion({ NUMCARD: valueProgressModal })}
        </Screen>
      )}

    </Formik>

  );
};



export default PIC_CadastroScreen;
