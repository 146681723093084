import * as Yup from 'yup';


const SmeCopiaModalValidationSchema = Yup.object().shape({

  input_filial: Yup.string()
    .required('Campo Obrigatório'),
  
  input_num: Yup.string()
    .required('Campo Obrigatório'),
  
  input_utilizacao: Yup.string()
    .required('Campo obrigatório'),
  
  input_orientacao: Yup.string()
    .required('Campo obrigatório'),
  
  
});

export default SmeCopiaModalValidationSchema;
