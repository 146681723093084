import React,
{
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import CCCUDGIModal from '../../../_Modals/005CCustoCUDGIModal';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GICCModel from './GI-Tab_CC.model';
import GICCValidationSchema from './GI-Tab_CC.validation';


const Tab_CCScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listCCDrop, setListCCDrop] = useState([]);
  const [listCCGrid, setListCCGrid] = useState([]);

  const [emptyObject] = useState({
    image: Images.empty,
    title: 'Sem Centros de Custos!',
    description: 'Não há Centro de Custo aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  function postFetchCCDrop(values) {
    setLoading(true);

    const url = 'gi/centrocusto/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((cc, index) => {
            setListCCDrop(
              (list) => [...list, {
                key: index,
                label: cc.CC,
                value: cc.CC,
              }],
            );
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCCDrop', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  function postFetchCCGrid(values, page_iteration) {
    setLoading(true);
    setShowList(false);
    setListCCGrid([]);
    setValuesPayload(values);

    const url = 'gi/centrocusto/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListCCGrid(res.data);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchCCGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchCCGrid(valuesPayload, page_var);
    setPage(page_var);
  };


  const options = {
    custom: true,
    totalSize: listCCGrid.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'NEGOCIO',
      text: 'Negócio',
      sort: true,
      formatter: (_cell, row) => {
        return `${row.NEGOCIO} - ${row.DESCRNEG}`;
      },
    },
    {
      dataField: '',
      text: 'Detal./Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.DETAILS}/${TextTooltip.EDIT}`}
          onClick={() => {
            setShowModalEdit(true);
            setValuesEdit(row);
          }}
          icon={
            <IconEdit color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove({
                ID: row.ID,
                CC: row.CC,
                DESCR: row.DESCR,
                NEGOCIO: row.NEGOCIO,
              });
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  useEffect(() => {
    postFetchCCDrop({ DESCR: '', CC: '' });
  }, []);



  return (
    <ContentStyled fluid>

      <ToolkitProvider
        keyField={'id'}
        data={listCCGrid}
        columns={columns}
        exportCSV={{
          blobType: ExcelConfigs.blobType,
          fileName: ExcelConfigs.PCB_Pedidos,
          onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
          exportAll: ExcelConfigs.exportAll,
          noAutoBOM: false,
          separator: ';',
        }}>
        {(propsTkProvider) => (
          <>
            <Formik
              enableReinitialize
              validateOnMount
              validationSchema={GICCValidationSchema}
              initialValues={GICCModel}
              onSubmit={(values) => {
                postFetchCCGrid(values, 1);
              }}>
              {({
                values,
                handleSubmit,
                handleBlur,
                handleChange,
              }) => (

                <>

                  {loading && (
                    <LoadingsComponent />
                  )}



                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>

                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'CC'}
                          label={'Centro de Custo'}
                          placeholder={'Todos'}
                          dropArray={listCCDrop}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.CC || ''}
                        />
                      </Col>



                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'DESCR'}
                          label={'Descrição'}
                          placeholder={'Deixe em branco para todos.'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DESCR}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      <IButton
                        disabled={loading}
                        type={'button'}
                        backgroundColor={Colors?.grayDark}
                        text={'Novo'}
                        onClick={() => {
                          setShowModalNew(true);
                        }}
                      />


                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>

                </>

              )}

            </Formik>



            {!loading && showList && listCCGrid.length === 0 && (

              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />

            )}


            {!loading && showList && listCCGrid.length > 0 && (

              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={listCCGrid}
                    columns={columns}
                    baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>

            )}

          </>
        )}

      </ToolkitProvider>



      {showModalNew && (
        <CCCUDGIModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Novo Centro de Custo'}
          type={'NEW'}
          fetchNow={() => postFetchCCGrid({ DESCR: '', CC: '' }, 1)}
        />
      )}



      {showModalEdit && (
        <CCCUDGIModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Centro de Custo'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchCCGrid({ DESCR: '', CC: '' }, 1)}
        />
      )}



      {showModalRemove && (
        <CCCUDGIModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Centro de Custo'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchCCGrid({ DESCR: '', CC: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_CCScreen;
