import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import { Colors, Images, Metrics } from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import {
  Content,
  FilterContainerForm,
  Screen, TitleGroup,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';

import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconHistory, IconList } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import { PFF_NavRoutes } from '../../../../../navigation/navs/Corporate';
import ApiWS from '../../../../../services/api.service';
import { maskToMoney2, maskToPorcent } from '../../../../../utils/CurrencyUtils';
import ConsultaDetalhesPFFModal from '../Modals/ConsultaDetalhesPFFModal';
import HistoricoAprovacaoPFFModal from '../Modals/HistoricoAprovacaoPFFModal';
import { RowButtonFiltersView } from './PFF-aprovacao-styled';
import PffConsultaSchema from './PFF-aprovacao-validation';
import PffConsultaModel from './PFF-aprovacao.model';


const PFF_AprovacaoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [valuesFetch, setValuesFetch] = useState(null);

  const [listBusiness, setListBusiness] = useState([]);
  const [listOs, setListOs] = useState([{ key: 0, label: 'Todos', value: 'TODOS' }]);

  const [listData, setListData] = useState([]);
  const [showListaData, setShowListData] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem PFF!',
    description: 'Não há nenhum laçamento de PFF para aprovação aqui.',
  });


  const [modalHistory, setModalHistory] = useState(false);
  const [valueHistory, setValueHistory] = useState({ NUM: '' });

  const [modalDetails, setModalDetails] = useState(false);
  const [valueDetails, setValueDetails] = useState({ 'ANO': '', 'CC': '', 'STATUS': '', 'TIPO': '', 'NIVEL': '', 'NUM': '' });

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postFetchListBusiness() {
    setLoading(true);

    const url = '/pff/lancamento/negoc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((neg, index) => {
            setListBusiness((listBusiness) => [...listBusiness, {
              key: index,
              label: neg.NEGOC,
              value: neg.VALOR,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os Negócios. Contate o suporte!',
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os Negócios. Contate o suporte! ${error.response.status}`,
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchListOS(values) {
    setLoading(true);
    setListOs([]);

    const url = '/pff/lancamento/os';

    ApiWS()
      .post(url, values)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((os, index) => {
            setListOs((listOS) => [...listOS, {
              key: index + 1,
              label: os.OS,
              value: os.OS,
            }],
            );
          });
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os Centros de Custos. Contate o suporte!',
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });

          setListData([]);
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os Centros de Custos. Contate o suporte! ${error.response.status}`,
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  async function postFilter(values, page_iteration: any) {
    setLoading(true);
    setValuesFetch(values);
    setListData([]);
    setValuesPayload(values);

    const url = '/pff/aprovacao/consulta';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setListData(resp.data[0]);

          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
        setShowListData(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFilter(valuesPayload, page_var);
    setPage(page_var);
  };


  const columns = [
    {
      dataField: 'CC',
      text: 'O.S.',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'ANO',
      text: 'Ano',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'USUARIO',
      text: 'Usuário',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'REVISAO',
      text: 'Revisão',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'DATA',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'RECEITA_LIQUIDA',
      text: 'Rec. Liq.',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
      formatter: (cell) => {
        if (cell === 0) {
          cell = 'R$ 0,00';
        }
        return maskToMoney2(cell);
      },
    },
    {
      dataField: 'CUSTOS',
      text: 'Custos',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
      formatter: (cell) => {
        if (cell === 0) {
          cell = 'R$ 0,00';
        }
        return maskToMoney2(cell);
      },
    },
    {
      dataField: 'MARGEM',
      text: 'Margem R$',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
      formatter: (cell) => {
        if (cell === 0) {
          cell = 'R$ 0,00';
        }
        return maskToMoney2(cell);
      },
    },
    {
      dataField: 'MARGEM_PER',
      text: 'Margem %',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
      formatter: (cell) => {
        if (cell === 0) {
          cell = '0,00';
        }
        return maskToPorcent(`${cell}`);
      },
    },
    {
      dataField: '',
      text: 'Detalhe',
      sort: true,
      headerStyle: () => {
        return { width: '2.5%' };
      },
      formatter: (_cell: any, row: any) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.DETAILS}
            onClick={() => {
              setValueDetails({
                'ANO': row.ANO,
                'CC': row.CC,
                'NIVEL': row.NIVEL,
                'NUM': row.NUM,
                'STATUS': '20',
                'TIPO': row.TIPO,
              });
              setModalDetails(true);
            }}
            icon={
              <IconList
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Histórico',
      sort: true,
      headerStyle: () => {
        return { width: '2.5%' };
      },
      formatter: (_cell: any, row: any) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.HISTORIC}
            onClick={() => {
              setValueHistory({ 'NUM': row.NUM });
              setModalHistory(true);
            }}
            icon={
              <IconHistory
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listData.length,
  };

  useEffect(() => {
    postFetchListBusiness();

    localStorage.setItem('@pff/LANCAMENTO/DATA', '[]');
  }, []);


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PFF_NavRoutes}
          title={'PFF'}
        />
      </NavHeader>



      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={PffConsultaSchema}
        initialValues={PffConsultaModel}
        onSubmit={(values) => {
          postFilter(values, 1);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (<>

          <Content>

            <TitleGroup>
              {'Aprovação'.toUpperCase()}
            </TitleGroup>



            <Container>

              {loading && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}


              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col xl={5}>

                    <DropdownInput
                      disabled={loading}
                      name={'NEGOCIO'}
                      label={'Negócio'}
                      dropArray={[
                        {
                          key: 0,
                          label: 'Todos',
                          value: 'TODOS',
                        },
                        ...listBusiness,
                      ]}
                      onChange={(e) => {
                        handleChange(e);

                        if (e.target.value !== 'TODOS') {
                          postFetchListOS({ NEGOC: e.target.value });
                        }
                        else {
                          setListOs([{
                            key: 0,
                            label: 'Todos',
                            value: 'TODOS',
                          }]);
                        }

                        setFieldValue('CC', 'TODOS');
                      }} />

                  </Col>



                  <Col xl={3}>

                    <DropdownInput
                      disabled={loading}
                      name={'CC'}
                      label={'CC'}
                      dropArray={[
                        {
                          key: 0,
                          label: 'Todos',
                          value: 'TODOS',
                        },
                        ...listOs,
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.CC} />

                  </Col>


                  <RowButtonFiltersView>

                    <IButton
                      disabled={loading}
                      text={'Filtrar'}
                      backgroundColor={Colors?.primary}
                      type={'submit'}
                    />

                  </RowButtonFiltersView>


                </Row>

              </FilterContainerForm>

            </Container>


            <Container>

              {!loading && showListaData && listData.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}


              {!loading && showListaData && listData.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (

                    <TableCuston
                      noQuantity={totalPage === 1}
                      data={listData}
                      columns={columns}
                      // baseProps={propsTkProvider.baseProps}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                      actionPrevious={() => handlePagination(0)}
                      actionNext={() => handlePagination(1)}
                      currentPage={page}
                      totalPage={totalPage}
                      totalResults={totalResults}
                    />

                  )}
                </PaginationProvider>
              )}

            </Container>

          </Content>

        </>)}

      </Formik>

      {modalHistory && (
        <HistoricoAprovacaoPFFModal
          show={modalHistory}
          values={valueHistory}
          onHide={() => {
            setModalHistory(false);
            setTimeout(() => {
              setValueHistory(null);
            }, 300);
          }}
        />
      )}



      {modalDetails && (
        <ConsultaDetalhesPFFModal
          show={modalDetails}
          values={valueDetails}
          postFetch={() => {
            postFilter(valuesFetch, 1);
          }}
          onHide={() => {
            setModalDetails(false);
            setTimeout(() => {
              setValueDetails(null);
            }, 300);
          }}
          isApproval={true}
        />
      )}

    </Screen>

  );
};

export default PFF_AprovacaoScreen;
