import * as Yup from 'yup';



const PffRealizadoValidationSchema = Yup.object().shape({

  ANO: Yup.string()
    .required('Obrigatório'),

  MES: Yup.string()
    .required('Obrigatório'),

});



export default PffRealizadoValidationSchema;
