import React, { useState, useEffect } from 'react';

import {
  Container,
} from 'react-bootstrap';
import { Colors } from '../../common/constants';


import {
  Content,
  ContentStyled,
  Screen,
} from '../../common/styles/styled.layout';

import NavComp from '../../components/Headers/NavComp';
import NavHeader from '../../components/Headers/NavHeader';
import NavUser from '../../components/Headers/NavUser';
import { IconArchive, IconCardId, IconCart, IconContract, IconDocuments, IconEyeOpen, IconFolder, IconHardHat, IconHelpOutline, IconListFeatured, IconMailWeb, IconMoney, IconMonitor, IconOrganization, IconPeopleBetween, IconPresentation, IconStock, IconTruck, IconTwilight, IconUserCheck, IconUserPlus, IconUsers } from '../../components/Icons';
import LoadingsComponent from '../../components/Loadings';
import ApiWS, { defaultBaseURL } from '../../services/api.service';
import { intranet_config } from '../../utils/Config';
import { AreaButton, ButtonHelpStyled, FooterHelpStyled, TextButtonStyled, TitleGroupHelp } from './styled';



const USER_HelpScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [listLinks, setListLinks] = useState([]);
  const [listDepartament, setListDepartament] = useState([]);



  function getFetchLinksHelp() {
    setLoading(true);
    const url = '/ajuda';

    ApiWS()
      .get(url)
      .then((resp) => {
        setListLinks(resp.data);

        let currentLink = '';
        resp.data.forEach((link) => {
          if (currentLink !== link.departamento) {
            setListDepartament((departament) => [...departament, { title: link.departamento, color: link.cor }]);
            currentLink = link.departamento;
          }
        });
      })
      .catch((err) => {
        console.error('FetchLinksHelp', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function renderIcon(title: string) {
    let icon = {};

    switch (title) {
      case 'Painel Gerencial':
        icon = <IconEyeOpen color={Colors?.white} />;
        break;
      case 'PFF New Version':
        icon = <IconTwilight color={Colors?.white} />;
        break;
      case 'PFF Old Version':
        icon = <IconArchive color={Colors?.white} />;
        break;
      case 'Relatórios MKS':
        icon = <IconArchive color={Colors?.white} />;
        break;
      case 'BI':
        icon = <IconPresentation color={Colors?.white} />;
        break;
      case 'Custo Gerencial':
        icon = <IconMoney color={Colors?.white} />;
        break;
      case 'Centro de Custo':
        icon = <IconOrganization color={Colors?.white} />;
        break;
      case 'RP':
        icon = <IconUserPlus color={Colors?.white} />;
        break;
      case 'Efetivo':
        icon = <IconUsers color={Colors?.white} />;
        break;
      case 'Meu RH':
        icon = <IconUserCheck color={Colors?.white} />;
        break;
      case 'PAV':
        icon = <IconPeopleBetween color={Colors?.white} />;
        break;
      case 'Não Conformidade':
        icon = <IconDocuments color={Colors?.white} />;
        break;
      case 'PIC':
        icon = <IconHardHat color={Colors?.white} />;
        break;
      case 'GED':
        icon = <IconListFeatured color={Colors?.white} />;
        break;
      case 'Gestão de Identidade':
        icon = <IconCardId color={Colors?.white} />;
        break;
      case 'Siga WEB':
        icon = <IconMonitor color={Colors?.white} />;
        break;
      case 'Help Protheus':
        icon = <IconHelpOutline color={Colors?.white} />;
        break;
      case 'WEB Mail':
        icon = <IconMailWeb color={Colors?.white} />;
        break;
      case 'Compras / Contratos':
        icon = <IconContract color={Colors?.white} />;
        break;
      case 'SME':
        icon = <IconCart color={Colors?.white} />;
        break;
      case 'Fornecedores':
        icon = <IconTruck color={Colors?.white} />;
        break;
      case 'PStock':
        icon = <IconStock color={Colors?.white} />;
        break;

      default:
        icon = <IconFolder color={Colors?.white} />;
        break;
    }

    return icon;
  }


  useEffect(() => {
    getFetchLinksHelp();
  }, []);


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          title={'Ajuda do PSUITE'}
        />
      </NavHeader>



      {loading && (<LoadingsComponent />)}

      {!loading && listLinks.length > 0 && (

        <Content>

          <ContentStyled fluid>

            <TitleGroupHelp color={Colors?.gray}>
              {`Versão: ${intranet_config.Registration.version} - Última atualização: ${intranet_config.Registration.dtUpdate}`}
            </TitleGroupHelp>


            <Container>


              {listDepartament.map((departament) => (
                <>

                  <TitleGroupHelp color={departament.color}>
                    {departament.title}
                  </TitleGroupHelp>


                  <AreaButton>
                    {listLinks.map((data) => (
                      (data.departamento === departament.title) && (

                        <ButtonHelpStyled
                          disabled={data.link === null}
                          color={departament.color}
                          onClick={() => {
                            window.open(`${defaultBaseURL}/manuais/${data.link}`);
                          }}
                        >

                          {renderIcon(data.descr)}


                          <TextButtonStyled>
                            {data.descr}
                          </TextButtonStyled>

                          <TextButtonStyled>
                            {data.versao}
                          </TextButtonStyled>

                        </ButtonHelpStyled>

                      )),
                    )}
                  </AreaButton>

                </>
              ))}

            </Container>

            <FooterHelpStyled>Desenvolvido por Planeja TI - 2022</FooterHelpStyled>

          </ContentStyled>

        </Content>

      )}

    </Screen >

  );
};



export default USER_HelpScreen;
