import styled from 'styled-components';
import { Row } from 'react-bootstrap';



export const PcbAprovarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const FlagsPcbView = styled.div`
  display: flex;
  flex: 1;
  padding-left: 8px;
`;


export const FieldFilterView = styled(Row)`
`;


export const ActionsPcbButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -20px;
`;
