import styled from 'styled-components';



export const RowButtonUnitView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
`;
