import styled,
{
  css,
} from 'styled-components';



export const QrPageFragment = styled.div`
  display: none;
`;



export const QrPageRefContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const QrPageItemPrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 21cm;
  height: 29.7cm;
  min-height: 29.7cm;
  max-height: 29.7cm;
  padding-left: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
`;



export const QrPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.5cm;
  min-height: 2.5cm;
  max-height: 2.5cm;
  padding-top: 1cm;
  margin-top: 0.5cm;
  margin-bottom: 10px;
  padding-bottom: 20px;
`;

export const QrPageLogoHeaderView = styled.div`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  margin-right: 20px;
`;

export const QrPageLogoImage = styled.img`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const QrPageLogoTitleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QrPageLogoTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 3px;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
`;



export const QrAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1px;
  gap: 5px;
`;

export const QrItemsDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const QrDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;



export const QrAuthorTitle = styled.h3`
  margin: 0;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
`;

export const QrAuthorName = styled.p`
  margin: 0;
  color: #000000;
  font-size: 14px;
`;



export const QrPageItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Qr10x10Style = css`
  display: flex;
  flex-direction: column;
`;

export const QrSubpageContent = styled.div`
  ${Qr10x10Style};

  height: 24cm;
  min-height: 24cm;
  max-height: 24cm;
  padding: 5px;
`;



export const QrViewContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 18cm;
  height: 1cm;
  min-height: 1cm;
  max-height: 1cm;
  padding: 2px;
  border-top-color: #000;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const QrTextView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 2;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-left: 3px;
  padding-bottom: 3px;
`;



export const QrTextStyled = css`
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-size: 11px;
  
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1; 
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const QCOD_PRODUTOText = styled.h2`
  ${QrTextStyled};
  width: 15%;
`;

export const QTAGText = styled.h2`
  ${QrTextStyled};
  width: 15%;
`;

export const QrPRODUTOText = styled.h3`
  ${QrTextStyled};
  width: 40%;
`;

export const QrUNText = styled.h2`
  ${QrTextStyled};
  width: 7%;
`;

export const QrQTDText = styled.h2`
  ${QrTextStyled};
  width: 7%;
`;

export const QrVALORText = styled.h2`
  ${QrTextStyled};
  width: 8%;
`;

export const QrTOTALText = styled.h2`
  ${QrTextStyled};
  width: 8%;
`;



export const QrPageFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 1.2cm;
  min-height: 1.2cm;
  max-height: 1.2cm;
`;

export const QrPagerFooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

export const QrPagerFooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const QrPagerFooterRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`;

export const QrPagerFooterTitle = styled.h3`
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
`;

export const QrPagerFooterText = styled.p`
  margin: 0;
  color: #000000;
  font-size: 16px;
`;
