import styled,
{
  css,
} from 'styled-components';

import {
  Button,
  Form,
  FormControl,
  InputGroup,
} from 'react-bootstrap';

import Select from 'react-select';

import {
  Colors,
  Metrics,
} from '../../common/constants';

export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type Position = 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
export type BoxSizing = 'border-box' | 'content-box';
export type OverflowY = '-moz-hidden-unscrollable' | 'auto' | 'clip' | 'hidden' | 'scroll' | 'visible';
export type WhiteSpace = '-moz-pre-wrap' | 'break-spaces' | 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';



interface IProps {
  textHeight?: number;

  readOnly?: boolean;
  disabled?: boolean;

  showPass?: boolean;
  paddingFeedback?: boolean;

  height?: any;
  fontSize?: any;

  fontSizeLabel?: any;

  exceed?: any;
}



export const CommonStyle = css`
	box-shadow: 0 0 10px 1px rgba(0, 0, 0 , 0.1);
  z-index: 0;
`;

export const ColorsCommonStyle = css`
  height: ${Metrics.inputHeight}px;
  background-color: ${Colors?.white};
  color: ${Colors?.textColor};
  font-size: ${Metrics.fontSizeDefault}em;
`;

export const RadiusRightCommonStyle = css`
  border-top-left-radius: 0 !important;
  border-top-right-radius: ${Metrics.inputRadius}px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: ${Metrics.inputRadius}px !important;
`;

export const RadiusLeftStyle = css`
  border-top-left-radius: ${Metrics.inputRadius}px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: ${Metrics.inputRadius}px !important;
  border-bottom-right-radius: 0 !important;
`;

export const RightViewStyled = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  right: 0;
  width: 48px;
  height: ${Metrics.inputHeight}px;
  background-color: transparent;
  border: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  z-index: 1 !important;

  :hover {
    background-color: transparent;
  }

  :active,
  :focus {
    background-color: transparent;
    box-shadow: none !important;
  }
`;



export const InputContainer = styled(Form.Group)`
  margin-top: 10px;
  margin-bottom: 10px;

  .input-group select {
    font-size: ${Metrics.fontSizeDefault}rem;
  }

  .form-control.is-invalid,
  .form-control.is-valid {
    background-position: ${(props: IProps) => props.paddingFeedback
    ? 'right calc(.375em + .1875rem + 40px) center'
    : 'right calc(.375em + .1875rem) center'};
    z-index: 0;
  }

  textarea:disabled,
  input:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }


  select {
    ${CommonStyle}
    ${ColorsCommonStyle}
    ${RadiusRightCommonStyle}
    border: 1px solid ${Colors?.borderColor};
    cursor: ${(props: IProps) => props.readOnly
    ? 'default'
    : 'pointer'};
    pointer-events: ${(props: IProps) => props.readOnly
    ? 'none'
    : 'auto'};
    z-index: 0;

    :active,
    :focus {
      background-color: #FFFFFF;
      color: ${Colors?.textActiveColor};
      box-shadow: none !important;
      z-index: 0 !important;
    }
  }

  .select-multi-custom {
    background-color: ${(props: IProps) => props.disabled ? Colors?.inputDisabled : Colors?.inputBackground} !important;
  }
`;

export const InputGroupComponent = styled(InputGroup)`
`;


// Button Right Style
export const ButtonPassStyled = styled(Button)`
  ${RightViewStyled}

  svg:hover {
    transition: fill .15s ease-in-out;
    fill: ${Colors?.primary} !important;
  }
`;


// Button Right Style
export const ViewPassStyled = styled.div`
  ${RightViewStyled}
  pointer-events: none;
`;


// Label Style

export const LabelStyled = styled(InputGroup.Text)`
  ${CommonStyle};
  ${RadiusLeftStyle};
  height: ${(props: IProps) => props.textHeight ? props.textHeight : Metrics.inputHeight}px !important;

  color: ${Colors?.labelColor};
  font-size: ${Metrics.fontSizeDefault}rem;
`;

export const OptionStyled = styled.option`
  font-size: ${Metrics.fontSizeDefault}rem !important;
`;


// Input Style

export const InputStyled = styled(FormControl)`
  ${CommonStyle};
  ${ColorsCommonStyle};
  ${RadiusRightCommonStyle};
  ${(props: IProps) => props.textHeight && `height: ${props.textHeight}px`};

  display: flex;
  flex: 1;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: ${(props: IProps) => props.showPass ? '48px' : '12px'};
  padding-bottom: 6px;
  cursor: ${(props: IProps) => props.readOnly
    ? 'default'
    : 'auto'};

  ${(props: IProps) => !props.textHeight ? `border: 0.5px solid ${Colors?.borderColor};` : 'border: 0.5px none;  outline: 0'};

  :focus {
    ${(props: IProps) => !props.textHeight ? `border: 0.5px solid ${Colors?.borderColor};` : `border: 0.5px solid ${Colors?.borderColor}; outline: 0`};
    box-shadow: none !important;
    z-index: 0 !important;
  }
`;

export const InputStyledLabelFloat = styled(FormControl)`


  :focus {
    border: 1px solid ${Colors?.borderColor};
    box-shadow: none !important;
    z-index: 0 !important;
  }

  min-height: ${(props: IProps) => props.height}px;
  font-size: ${(props: IProps) => `${props.fontSize}px` || `${Metrics.fontSizeDefault}em`};

`;

export const LabelFloatStyled = styled.label`
  font-size: ${(props: IProps) => `${props.fontSizeLabel}px` || `${Metrics.fontSizeDefault}em`};
`;

// Select Multi Style

export const SelectMultiStyled = styled(Select).attrs({
  styles: {
    container: () => ({
      paddingLeft: 12,
      paddingRight: 2,
      border: '1px solid #ced4da',
      borderTopLeftRadius: '0 !important',
      borderTopRightRadius: '5px !important',
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '5px !important',
      boxShadow: '0 0 10px 1px rgb(0 0 0 / 10%)',
      zIndex: 5,
    }),
    control: () => ({
      position: 'relative' as Position,
      display: 'flex',
      flexWrap: 'wrap' as FlexWrap,
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 38,
      backgroundColor: 'transparent',
      border: 'none',
      boxSizing: 'border-box' as BoxSizing,
      transition: 'all 100ms',
      cursor: 'default',
    }),
    valueContainer: () => ({
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap' as FlexWrap,
      padding: '5px 0',
      position: 'relative' as Position,
      overflow: 'hidden',
      boxSizing: 'border-box' as BoxSizing,
    }),
    // input: () => ({
    //   color: Colors?.labelColor,
    //   flex: '1 1 auto',
    //   display: 'inline-grid',
    //   gridArea: '1/1/2/3',
    //   gridTemplateColumns: '0 min-content',
    //   boxSizing: 'border-box',
    // }),
    // multiValue: () => ({
    //   background-color: hsl(0, 0%, 90%);
    //   border-radius: 2px;
    //   display: -webkit-box;
    //   display: -webkit-flex;
    //   display: -ms-flexbox;
    //   display: flex;
    //   margin: 2px;
    //   min-width: 0;
    //   box-sizing: border-box;
    // }),
    multiValueLabel: () => ({
      overflow: 'hidden',
      padding: 2,
      paddingLeft: 6,
      color: 'hsl(0, 0%, 20%)',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      letterSpacing: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap' as WhiteSpace,
      boxSizing: 'border-box' as BoxSizing,
      borderRadius: 2,
    }),
    menu: () => ({
      overflow: 'hidden',
      position: 'absolute' as Position,
      right: 0,

      width: '100%',
      maxWidth: 300,
      marginTop: 10,
      marginBottom: 10,
      padding: 0,

      backgroundColor: Colors?.inputBackground,
      borderRadius: Metrics.inputRadius,
      boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
    }),
    menuList: () => ({
      overflowY: 'scroll' as OverflowY,
      maxHeight: 250,
      backgroundColor: '#FFFFFF',
      width: '100%',
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   borderBottom: '1px dotted pink',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    // }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // },
  },
})`
  ${CommonStyle}

  ${ColorsCommonStyle}
  height: auto !important;

  ${RadiusRightCommonStyle}

  flex: 1;
  flex-direction: row;
  flex-wrap: none;
  border: 1px solid ${Colors?.borderColor};

`;

// Feedback Style

export const HelpFeedbackStyled = styled(Form.Control.Feedback)`
  width: 100%;
  margin-top: 5px;
  font-size: 0.656rem;
`;

export const AreaCountTextStyled = styled.div`
  display: flex;
  direction: row;
  flex-direction: row-reverse;
  color: ${(props: IProps) => !props.exceed ? Colors?.textActiveColor : Colors?.buttonCancel};
`;

export const CountTextStyled = styled.span`
  font-size: 0.7em;
`;
