import * as Yup from 'yup';


const ColetorCreateValidationSchema = Yup.object().shape({

  select_id_deposit: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_description: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_code: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

  input_identification: Yup.string()
    .min(1)
    .required('Campo obrigatório'),

});



export default ColetorCreateValidationSchema;
