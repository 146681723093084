import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import EmptyContent from '../../../../../components/Empty';

import {
  IconPrinter,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';
import { intranet_config } from '../../../../../utils/Config';

import {
  currentDateFormated,
  formataData,
} from '../../../../../utils/DateUtils';



import {
  PrinterContainer,
  A4LandscapeContent,
  TitleSection,
} from './PIC-consulta-impressao.styled';


const PIC_ConsultaImpressaoScreen: React.FC = () => {
  const initialPrintCard = {
    CAB: {
      CC: '',
      DESCR_CC: '',
      NUM: '',
      DATA: '',
      HORACADASTRO: '',
      AREA: '',
      MATRICULA: '',
      AUDITOR: '',
      DESVIOS: '',
      OBS: '',
      FUNCAO: '',
    },

    A: [],
    B: [],
    C: [],
    D: [],
    E: [],

    PEN: [],

    REC: [],

    haveData: false,
  };

  const user = useSelector((state: RootState) => state.user.data);

  const { id } = useParams() as any;


  const [loading, setLoading] = useState(false);
  const [groupCardPic, setGroupCardPIC] = useState(initialPrintCard);

  const emptyObject = {
    image: Images.empty,
    title: 'Sem registro!',
    description: 'Não há nenhum registro de Cartão PIC com esse Número!',
  };


  async function getCardPIC(values: any) {
    const url = `/pic/cartao/impressao/${values}`;
    setLoading(true);
    await ApiWS()
      .get(url)
      .then((resp) => {
        console.log(resp);
        const groupCard = initialPrintCard;

        if (resp.data.length > 0) {
          groupCard.CAB = {
            CC: resp.data[0].CC,
            DESCR_CC: resp.data[0].DESCR_CC,
            NUM: resp.data[0].NUM,
            DATA: resp.data[0].DATA,
            AREA: resp.data[0].AREA,
            MATRICULA: resp.data[0].MATRICULA,
            AUDITOR: resp.data[0].AUDITOR,
            DESVIOS: resp.data[0].DESVIOS,
            OBS: resp.data[0].OBS,
            HORACADASTRO: resp.data[0].HORACADASTRO,
            FUNCAO: resp.data[0].FUNCAO,
          };


          resp.data.forEach((item: any) => {
            if(item.PEN !== undefined){
              groupCard.PEN = item.PEN;
            }
          });


          resp.data.forEach((item: any) => {
            if(item.REC !== undefined) {
              groupCard.REC = item.REC;
            }
          });


          resp.data.forEach((item: any) => {
            if(item.ID !== undefined) {
              switch (item.CODITEM[0]) {
                case 'A':
                  groupCard.A.push(item);
                  break;
                case 'B':
                  groupCard.B.push(item);
                  break;
                case 'C':
                  groupCard.C.push(item);
                  break;
                case 'D':
                  groupCard.D.push(item);
                  break;
                case 'E':
                  groupCard.E.push(item);
                  break;
                default:
                  break;
              }
            }
          });

          if(resp.data[0].CC){
            groupCard.haveData = true;
          }

          setGroupCardPIC(groupCard);
          console.log(groupCard);
        }
      })
      .catch((err) => {
        console.error('getCardPIC', JSON.stringify(err.response, null, 2));
        // return (err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function renderLoadingComponent() {
    return (
      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary} />
      </PrinterContainer>
    );
  }


  function returnTextItem(value: string) {
    switch (value) {
      case 'A1': return (`${value} - Atingidor Por`); break;
      case 'A2': return (`${value} - Batida Contra`); break;
      case 'A3': return (`${value} - Prensamento de Membros`); break;
      case 'A4': return (`${value} - Postura Inadequada`); break;
      case 'A5': return (`${value} - Risco de Queda`); break;
      case 'A6': return (`${value} - Risco de Choque Elétrico`); break;
      case 'A7': return (`${value} - Contato com Contaminantes`); break;
      case 'A8': return (`${value} - Esforço Inadequado`); break;

      case 'B1': return (`${value} - Cabeça`); break;
      case 'B2': return (`${value} - Olhos e Rosto`); break;
      case 'B3': return (`${value} - Ouvidos`); break;
      case 'B4': return (`${value} - Mãos e Braços`); break;
      case 'B5': return (`${value} - Pés e Pernas`); break;

      case 'C1': return (`${value} - Impróprias para o serviço`); break;
      case 'C2': return (`${value} - Usados incorretamente`); break;
      case 'C3': return (`${value} - Em condição/local inseguro`); break;
      case 'C4': return (`${value} - Equipamento com vazamento ou derramamento`); break;

      case 'D1': return (`${value} - Procedimento Errado`); break;
      case 'D2': return (`${value} - Não há procedimento escrito`); break;

      case 'E1': return (`${value} - Ordem e limpeza deficiente`); break;
      case 'E2': return (`${value} - Falta de Coleta seletiva`); break;
      case 'E3': return (`${value} - Isolamento inexistente ou deficiente`); break;
      case 'E4': return (`${value} - Falta de sinalização ou identificação`); break;

      default:
        return null;
        break;
    }
  };

  useEffect(() => {
    getCardPIC(id);
  }, []);



  return (
    <>
      {!loading && groupCardPic.haveData &&
        (
          <A4LandscapeContent className="page-a4-landscape text-center">

            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td rowSpan={2} className="image-td">
                    <img
                      alt="Logo"
                      src={intranet_config.Images.logo}
                    />
                  </td>
                  <th rowSpan={2} className="name-printer-td">
                    PIC - Programa de Inspeção Comportamental
                  </th>
                  <th colSpan={2}>ID</th>
                  <td colSpan={2}>{groupCardPic?.CAB.NUM}</td>
                </tr>

                <tr>
                  <th colSpan={2}>Data</th>
                  <td colSpan={2}>{`${formataData(groupCardPic?.CAB.DATA)} ${groupCardPic?.CAB.HORACADASTRO}`}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TablePrinterStyled>
              <tbody>
                <tr>
                  <th>O.S.</th>
                  <th>Área</th>
                  <th>Auditor</th>
                  <th>Matrícula</th>
                  <th>Função</th>
                </tr>

                <tr>
                  <td>{`${groupCardPic?.CAB.CC} - ${groupCardPic?.CAB.DESCR_CC}`}</td>
                  <td>{groupCardPic?.CAB.AREA}</td>
                  <td>{groupCardPic?.CAB.AUDITOR}</td>
                  <td>{groupCardPic?.CAB.MATRICULA}</td>
                  <td>{groupCardPic?.CAB.FUNCAO}</td>
                </tr>

              </tbody>
            </TablePrinterStyled>


            {groupCardPic.A.length > 0 &&
              <>
                <TitleSection>{'A - Pessoas'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Nº. Pessoas Observ.</th>
                      <th>Desvio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPic.A.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.EQUIPE}</td>
                        <td>{item.NPESSOA}</td>
                        <td>{item.NDESVIO}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPic.B.length > 0 &&
              <>
                <TitleSection>{'B - Falta de EPI\'s ou Uso Inadequado'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Nº. Pessoas Observ.</th>
                      <th>Desvio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPic.B.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.IDEQUIPE}</td>
                        <td>{item.NPESSOA}</td>
                        <td>{item.NDESVIO}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }

            {groupCardPic.C.length > 0 &&
              <>
                <TitleSection>{'C - Ferramentas e Equipamentos Inadequados'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Nº. Pessoas Observ.</th>
                      <th>Desvio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPic.C.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.IDEQUIPE}</td>
                        <td>{item.NPESSOA}</td>
                        <td>{item.NDESVIO}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPic.D.length > 0 &&
              <>
                <TitleSection>{'D - Procedimento de SMS'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Nº. Pessoas Observ.</th>
                      <th>Desvio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPic.D.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.IDEQUIPE}</td>
                        <td>{item.NPESSOA}</td>
                        <td>{item.NDESVIO}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            {groupCardPic.E.length > 0 &&
              <>
                <TitleSection>{'E - Organização e Limpeza'.toUpperCase()}</TitleSection>
                <TablePrinterStyled>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Líder</th>
                      <th>Descrição</th>
                      <th>Nº. Pessoas Observ.</th>
                      <th>Desvio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupCardPic.E.map((item, index) => (
                      <tr key={index}>
                        <td>{returnTextItem(item.CODITEM)}</td>
                        <td>{item.LIDER}</td>
                        <td>{item.IDEQUIPE}</td>
                        <td>{item.NPESSOA}</td>
                        <td>{item.NDESVIO}</td>
                      </tr>
                    ))}
                  </tbody>
                </TablePrinterStyled>
              </>
            }


            <TitleSection>{'Pendências a serem tratadas'.toUpperCase()}</TitleSection>
            {groupCardPic.PEN.length > 0 && groupCardPic.PEN[0].pendencia !== ('').trim()
              ?
              <TablePrinterStyled>
                <thead>
                  <tr>
                    <td> Pendência </td>
                    <td> Responsável </td>
                  </tr>
                </thead>
                <tbody>
                  {groupCardPic.PEN.map((pendency, index) => (
                    <tr key={index}>
                      <td> {pendency.pendencia} </td>
                      <td> {pendency.resp} </td>
                    </tr>
                  ))}
                </tbody>

              </TablePrinterStyled>
              :
              <TablePrinterStyled>
                <tbody>
                  <tr>
                    <td>
                      Sem pendêcias.
                    </td>
                  </tr>
                </tbody>
              </TablePrinterStyled>
            }


            <TitleSection>{'Desvio crítico / Risco iminente / Incidente ( Presenciado pelo Auditor )'.toUpperCase()}</TitleSection>
            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td>
                    {
                      groupCardPic.CAB.DESVIOS
                      && (groupCardPic.CAB.DESVIOS !== undefined
                      && groupCardPic.CAB.DESVIOS !== 'undefined')
                      && groupCardPic.CAB.DESVIOS !== ''
                        ? groupCardPic.CAB.DESVIOS
                        : 'Sem Desvio, Risco ou Incidente.'}
                  </td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TitleSection>{'Reconhecimento de Trabalho Seguro'.toUpperCase()}</TitleSection>
            {groupCardPic.REC.length > 0 && groupCardPic.REC[0].matricula !== ('').trim()
              ?
              <TablePrinterStyled>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <span>Matrículas: </span>
                      {groupCardPic.REC.length > 0 &&
                       groupCardPic.REC.map((matricula, index) => (
                         <>
                           <strong>
                             {matricula.matricula}
                             {index < groupCardPic.REC.length-1 && ', '}
                           </strong>
                         </>
                       ))
                      }
                    </td>
                  </tr>
                </tbody>

              </TablePrinterStyled>
              :
              <TablePrinterStyled>
                <tbody>
                  <tr>
                    <td>
                      Sem reconhecimentos.
                    </td>
                  </tr>
                </tbody>
              </TablePrinterStyled>
            }


            <TitleSection>{'Observações e/ou Sugestões'.toUpperCase()}</TitleSection>
            <TablePrinterStyled>
              <tbody>
                <tr>
                  <td>
                    {groupCardPic.CAB.OBS ? groupCardPic.CAB.OBS : 'Sem observações e/ou sugestestões.'}
                  </td>
                </tr>
              </tbody>
            </TablePrinterStyled>

            <div>
              <p style={{ textAlign: 'left', marginTop: '2em' }}><i>*- Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
              <ButtonTableAction
                tooltip={TextTooltip.PRINTER}
                onClick={() => {
                  window.print();
                }}
                icon={
                  <IconPrinter
                    color={Colors?.black}
                    size={72}
                  />
                }
              />
            </div>

          </A4LandscapeContent>
        )
      }

      {loading &&
        renderLoadingComponent()
      }

      {!loading && !groupCardPic.haveData &&
        <A4LandscapeContent className="page-a4-landscape text-center">

          <TablePrinterStyled>
            <tbody>
              <tr>
                <td rowSpan={2} className="image-td">
                  <img
                    alt="Logo"
                    src={Images.logoFullApp}
                  />
                </td>
                <th rowSpan={2} className="name-printer-td">
                  PIC - Programa de Inspeção Comportamental
                </th>
                <th colSpan={2}></th>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </TablePrinterStyled>


          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />


          <div>
            <p style={{ textAlign: 'left', marginTop: '2em' }}><i>*- Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
            <ButtonTableAction
              tooltip={TextTooltip.PRINTER}
              onClick={() => {
                window.print();
              }}
              icon={
                <IconPrinter
                  color={Colors?.black}
                  size={72}
                />
              }
            />
          </div>


        </A4LandscapeContent>
      }
    </>
  );
};

export default PIC_ConsultaImpressaoScreen;
