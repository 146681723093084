const ColetorCreateModel = {
  select_id_deposit: '',
  input_description: '',
  input_code: '',
  input_identification: '',
};



export default ColetorCreateModel;
