import {
  validateString,
} from './StringUtils';



export const ordenaListaRecentePrimeiro = (list: any) => {
  return list.sort(
    function (a: any, b: any) {
      return a.RP < b.RP && 1;
      return a.RP > b.RP && -1;
      return 0;
    });
};



export const convertCNPJ = (value: any) => {
  if (validateString(value)) {
    const receivedValue = value.length > 0
      ? value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      : null;

    return validateString(receivedValue);
  }
  else {
    return null;
  }
};



export const convertSexo = (value: any) => {
  if (validateString(value) === 'F') {
    return 'Feminino';
  }
  else if (validateString(value) === 'M') {
    return 'Masculino';
  }
  else {
    return null;
  }
};



export const convertCPF = (value: any) => {
  if (validateString(value)) {
    return value;
  }
  else {
    return null;
  }
};
