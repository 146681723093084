import React, { useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const DepartamentoCUDGIModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postSaveDepartment(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/departamento/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/departamento/alterar';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveSystem', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postRemoveSystem(values) {
    setLoading(true);

    let url = '';

    url = 'gi/departamento/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveSystem', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || '',
            DESCR_ATUAL: props.valuesEdit?.DESCR,
            DESCR: props.valuesEdit?.DESCR || props?.valuesRemove?.DESCR || '',
          }}
          validationSchema={
            Yup.object().shape({
              ID: props.type !== 'NEW' ? Yup.string().required('Obrigatório') : Yup.string(),
              DESCR: Yup.string().required('Obrigatório'),
            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveDepartment(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveSystem(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                {props?.type !== 'REMOVE' && (
                  <>
                    {props?.type === 'EDIT' && (
                      <TextInput
                        disabled={true}
                        name={'DESCR_ATUAL'}
                        label={'Departamento Atual'}
                        type={'text'}
                        value={values.DESCR_ATUAL}
                      />
                    )}



                    <TextInput
                      disabled={loading}
                      name={'DESCR'}
                      label={'Novo Departamento'}
                      type={'text'}
                      placeholder={'Descrição'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.DESCR}
                    />
                  </>
                )}


                {props?.type === 'REMOVE' && (
                  <>
                    <TextInput
                      disabled={true}
                      name={'DESCR'}
                      label={'Departamento'}
                      type={'text'}
                      value={values.DESCR}
                    />
                  </>
                )}


              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default DepartamentoCUDGIModal;
