import * as Yup from 'yup';


export const PICCadastroAreaValidationSchema = Yup.object().shape({
  CC: Yup.string().trim()
    .required('Campo Obrigatório!'),

});

export const PICCadastroNovaAreaValidationSchema = Yup.object().shape({
  CC: Yup.string()
    .required('Campo Obrigatório!'),

  DESCR: Yup.string()
    .required('Campo Obrigatório!'),

});
