import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router';

import NameRoutes from '../../../navigation/name';

import {
  RootState,
} from '../../../store';

import {
  USER_FETCH_INFO,
  USER_RESET,
  USER_TOKEN,
} from '../../../store/reducers/user.store';

import {
  convertDateTodayToExtense,
} from '../../../utils/DateUtils';

import {
  IconBack,
  IconHelpOutline,
  IconLogout,
} from '../../Icons';

import {
  NavbarUserContainer,
  NavbarUser,
  NavbarItemUserLeft,
  BackButton,
  BackIconButton,
  HomeOrBackText,
  NavbarItemUserCenter,
  DateText,
  NavbarItemUserRight,
  UserText,
  LogoutButton,
  LogoutIcon,
  HelpButton,
} from './styled';



interface IProps {
  backHome?: boolean;
  back?: boolean;
}



const NavUser: React.FC<IProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.data);


  const formattedTodayDate = convertDateTodayToExtense(new Date());


  function backFunction() {
    if (props.backHome && !props.back) {
      history.push(`/${NameRoutes.Home}`);
    }
    if (!props.backHome && props.back) {
      history.goBack();
    }
  }


  function backText() {
    if (props.backHome && !props.back) {
      return 'Voltar para o Ínicio';
    }
    if (!props.backHome && props.back) {
      return 'Voltar';
    }
  }

  function clearAll() {
    try {
      dispatch({ type: USER_RESET });
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_FETCH_INFO);
    }
    catch (err) {
      console.log(`Clear Error: ${err}`);
    }
  }


  function logoutFunction() {
    clearAll();
    history.push('/logout');
  }


  function helpFunction() {
    history.push(`/${NameRoutes.USER_Help}`);
  }



  return (

    <NavbarUserContainer>

      <NavbarUser>

        <NavbarItemUserLeft>
          {(props.backHome || props.back) && (
            <BackButton
              onClick={() => {
                backFunction();
              }}>

              <BackIconButton>
                <IconBack
                  color={'white'}
                  size={26}
                />
              </BackIconButton>

              <HomeOrBackText>
                {backText()}
              </HomeOrBackText>

            </BackButton>
          )}


          {!props.backHome && !props.back && (
            <HomeOrBackText>
              PSUITE
            </HomeOrBackText>
          )}
        </NavbarItemUserLeft>


        <NavbarItemUserCenter>
          <DateText>
            {formattedTodayDate}
          </DateText>
        </NavbarItemUserCenter>


        <NavbarItemUserRight>
          <UserText>
            {user.login}
          </UserText>



          <HelpButton
            onClick={() => {
              helpFunction();
            }}>

            <LogoutIcon>
              <IconHelpOutline
                color={'white'}
              />
            </LogoutIcon>

            <UserText>
              Ajuda
            </UserText>

          </HelpButton>



          <LogoutButton
            onClick={() => {
              logoutFunction();
            }}>

            <LogoutIcon>
              <IconLogout
                color={'white'}
              />
            </LogoutIcon>

            <UserText>
              Sair
            </UserText>

          </LogoutButton>

        </NavbarItemUserRight>

      </NavbarUser>

    </NavbarUserContainer>

  );
};



NavUser.defaultProps = {
  backHome: false,
  back: false,
};



export default NavUser;
