import React,
{
  useEffect,
  useState,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';


import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFirstStyled,
  ModalFooterStyled,
  ModalTableContainer,
  ModalTableObsText,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS from '../../../../../services/api.service';

import {
  convertCurrencyBRL,
} from '../../../../../utils/CurrencyUtils';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
}



const ItensContratoModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);


  function fetchItens() {
    const {
      FILIAL,
      CONTRATO,
      REV,
      PLANILHA,
    } = props.values;

    const payload = {
      'FILIAL': FILIAL,
      'CONTRATO': CONTRATO,
      'REV': REV,
      'PLANILHA': PLANILHA,
    };

    setLoading(true);

    ApiWS()
      .post('/suprimento/consulta/contrato/itens', payload)
      .then((resp) => {
        setItems(resp.data);
      })
      .catch((error) => {
        console.error('fetchItens', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    fetchItens();
  }, []);



  return (

    // FIXME: FALTANDO: QTD ATEND - QTD. PEND - SITUACAO
    <ModalFirstStyled
      {...props}
      dialogClassName="modal-first-dialog modal-85"
      backdropClassName="modal-first-backdrop"
      show={props.show}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Itens do Contrato'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && (!items || items === null || items.length === 0) && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Contrato: {props.values.CONTRATO}
              </ModalTableObsText>

              <NoItemsContent />

            </ModalTableContainer>
          )}



          {!loading && items && items.length > 0 && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Contrato: {props.values.CONTRATO}
              </ModalTableObsText>


              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: items.length,
                })}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity
                    data={items}
                    columns={[
                      {
                        dataField: 'ITEM',
                        text: 'Item',
                        sort: true,
                        headerStyle: () => {
                          return { width: '5%' };
                        },
                      },
                      {
                        dataField: 'CODIGO',
                        text: 'Código',
                        sort: true,
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: 'DESCRICAO',
                        text: 'Descrição',
                        sort: true,
                      },
                      {
                        dataField: 'COMPLEMENTO',
                        text: 'Complemento',
                        sort: true,
                      },
                      {
                        dataField: 'UNID',
                        text: 'Und.',
                        sort: true,
                        headerStyle: () => {
                          return { width: '3%' };
                        },
                      },
                      {
                        dataField: 'QTDADE',
                        text: 'Qtd Nova',
                        sort: true,
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'PRECO_UNIT',
                        text: 'P. Unit. Novo',
                        sort: true,
                        headerStyle: () => {
                          return { width: '8%' };
                        },
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                      },
                      {
                        dataField: 'PARCIAL',
                        text: 'Valor Novo',
                        sort: true,
                        headerStyle: () => {
                          return { width: '8.5%' };
                        },
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                      },
                      {
                        dataField: 'VALOR_MEDIDO',
                        text: 'Valor Medido',
                        sort: true,
                        headerStyle: () => {
                          return { width: '8.5%' };
                        },
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                      },
                      {
                        dataField: 'ADITIVO',
                        text: 'Aditivo (R$)',
                        sort: true,
                        headerStyle: () => {
                          return { width: '5%' };
                        },
                      },
                      {
                        dataField: 'SALDO_ATUAL',
                        text: 'Novo Saldo',
                        sort: true,
                        headerStyle: () => {
                          return { width: '8.5%' };
                        },
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                      },
                    ]}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>

            </ModalTableContainer>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </ModalFirstStyled>

  );
};



export default ItensContratoModal;
