import React from 'react';

import {
  InputGroup,
} from 'react-bootstrap';

import {
  Field,
} from 'formik';

import {
  InputContainer,
  LabelStyled,
  SelectMultiStyled,
  HelpFeedbackStyled,
} from './styled';



interface IOption {
  key?: any;
  label: string;
  value: string;
}


interface IProps {
  id?: string;
  className?: string;

  // required?: boolean;
  editable?: boolean;
  disabled?: boolean;
  defaultValue?: any;

  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;

  // showPass?: boolean;

  dropArray: Array<any>; // OptionsType<IOption>;

  // onSelect?: any;
  onBlur?: any;
  onChange?: any;
  onNextChange?: any;
}



const SelectMulti: React.FC<IProps> = (props) => {
  return (

    <Field
      disabled={!props.editable || props.disabled}
      name={props.name}
      render={({ field, form }) => {
        // const isValid = !form.errors[field.name];
        // const isInvalid = form.touched[field.name] && !isValid;


        // const onChange = (option: ValueType<IOption | IOption[]>) => {
        function onChangeSelect(option: any) {
          const optionValue = (option as IOption[]).map((item: IOption) => item.value);

          form.setFieldValue(
            field.name,
            optionValue,
          );

          props.onNextChange && props.onNextChange(optionValue);
        };

        const getValue = () => {
          if (props.dropArray) {
            return props.dropArray.filter((option) => field.value.indexOf(option.value) >= 0);
          }
          else {
            return [];
          }
        };



        return (

          <InputContainer
            className={props.className}
            readOnly={!props.editable}
            disabled={!props.editable || props.disabled}
            paddingFeedback={true}>

            <InputGroup>

              {props.label && (
                <LabelStyled>
                  {props.label}
                </LabelStyled>
              )}


              <SelectMultiStyled
                isMulti
                // hideSelectedOptions={false}
                closeMenuOnSelect={false}

                isDisabled={!props.editable || props.disabled}
                className={`select-multi-custom ${props.className}`}

                name={props.name}
                placeholder={props.placeholder}

                // isOptionSelected={isOptionSelected}
                // options={getOptions()}
                options={props.dropArray}
                defaultValue={props.defaultValue}

                value={getValue()}
                onChange={onChangeSelect}



              // isValid={!props.disabled && form.touched[field.name] && isValid}
              // isInvalid={!props.disabled && isInvalid}
              // feedback={!props.disabled && form.errors[field.name]}
              />


              <HelpFeedbackStyled type="invalid">
                {form.errors[field.name]}
              </HelpFeedbackStyled>

            </InputGroup>

          </InputContainer>

        );
      }}
    />

  );
};



SelectMulti.defaultProps = {
  editable: true,
  disabled: false,
};



export default SelectMulti;
