import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { Content, ContentStyled, Screen, TabIconStyled, TabsStyled, TabTextStyled, TabViewStyled } from '../../../../../common/styles/styled.layout';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconEffective, IconFactory, IconList } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import { POC_NavRoutes } from '../../../../../navigation/navs/SGI';
import Tab_CadastroCartaoScreen from './POC_Cadastro_Tabs/001POC_Cadastro_Cartao';
import Tab_CadastroEquipeScreen from './POC_Cadastro_Tabs/002POC_Cadastro_Equipe';
import Tab_CadastroAreaScreen from './POC_Cadastro_Tabs/003POC_Cadastro_Area';

const POC_CadastroScreen: React.FC = () => {
  const [loading] = useState(false);


  // Fixme: Voltar ->
  // useEffect(() => {
  //   postPermission();
  // }, []);


  const [currentTab, setCurrentTab] = useState(0);

  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconList />,
        text: 'Cartão',
      },
      screenTab: <Tab_CadastroCartaoScreen />,
    },
    {
      eventKey: 1,
      title: {
        icon: <IconEffective />,
        text: 'Equipe',
      },
      screenTab: <Tab_CadastroEquipeScreen />,
    },
    {
      eventKey: 2,
      title: {
        icon: <IconFactory />,
        text: 'Área',
      },
      screenTab: <Tab_CadastroAreaScreen />,
    },

  ];

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={POC_NavRoutes}
          title={'POC'}
        />
      </NavHeader>

      {loading && (
        <LoadingsComponent />
      )}



      {/* Fixme: Voltar -> {!loading && (error || !permission) &&(
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )} */}



      {/* Fixme: Voltar -> {!loading && !error && permission  && ( */}

      <Content>
        <ContentStyled fluid>

          <TabsStyled
            id={'control-tab-pcb'}
            activeKey={currentTab}
            onSelect={(tab) => setCurrentTab(parseInt(tab))}
          >

            {tabsList.map((tab) => (

              <Tab
                eventKey={tab.eventKey}
                title={
                  <TabViewStyled>

                    <TabIconStyled>
                      {tab.title.icon}
                    </TabIconStyled>


                    <TabTextStyled>
                      {tab.title.text}
                    </TabTextStyled>

                  </TabViewStyled>
                }>

                {tab.screenTab}

              </Tab>

            ))}

          </TabsStyled>

        </ContentStyled>

      </Content>

      {/* )} */}

    </Screen>

  );
};

export default POC_CadastroScreen;
