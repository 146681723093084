import styled from 'styled-components';

import {
  Metrics,
} from '../../../common/constants';



export const CardImportInputButton = styled.label`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  height: ${Metrics.inputHeight}px;
  padding: 0 14px;
  margin: 0 5px;
  background-color: #FFFFFF;
  border-radius: ${Metrics.inputRadius}px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border: 1px solid rgba(0,0,0,.125);
  transition: background-color 160ms, color 160ms, box-shadow 160ms, border 160ms;
  cursor: pointer;
`;

export const CardImportInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`;

export const CardImportInputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 4px;
`;

export const CardImportInputLabel = styled.span`
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
`;
