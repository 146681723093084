import styled from 'styled-components';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Colors, Metrics } from '../../../../../common/constants';


interface IProps {
  loading?: boolean;
  backgroundColor?: string;
  auto?: any;
}

export const PffFilterContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  ${(props: IProps) => !props.auto && 'min-height: 360px'};
  padding: 30px;
  background-color: ${Colors?.formBackground};
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;

export const PffFilterTableResume = styled(Form)`
  display: flex;
  flex-direction: column;
  ${(props: IProps) => !props.auto && 'min-height: 310px'};
  padding: 13px 2px 0 2px;
  margin: auto 0px;
  background-color: ${Colors?.formBackgroundSecondary};
  border-radius: 0.25rem;
`;

export const FieldAreaRowStyled = styled(Row)`
  padding-bottom: 0.5em;
  margin-left: 2.5em;
`;


export const ButtonAreaColStyled = styled(Col)`
  margin-top: 0.65em;
  margin-left: 2em;
`;


export const ButtonSend = styled(Dropdown.Toggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: ${Metrics.inputHeight}px;
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
  color: white !important;
  font-size: 1.125rem;
  border: none;
  border-radius: ${Metrics.inputRadius}px !important;
  z-index: 2 !important;

  :hover,
  :active,
  :focus {
    background-color: ${(props: IProps) => props.backgroundColor || Colors?.primary};
    box-shadow: none !important;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    pointer-events: none;
    opacity: .65;
  }

  span {
    margin: 0 8px;
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  :active {
    background-color: ${Colors?.primaryDark};
  }
`;


