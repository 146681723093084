import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Tab,
} from 'react-bootstrap';
import { Images } from '../../../../../common/constants';



import {
  Content,
  ContentStyled,
  Screen,
  TabIconStyled,
  TabsStyled,
  TabTextStyled,
  TabViewStyled,
} from '../../../../../common/styles/styled.layout';
import EmptyContent from '../../../../../components/Empty';


import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';


import { IconNetwork } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import { SME_NavRoutes } from '../../../../../navigation/navs/Supplies';



import ApiWS from '../../../../../services/api.service';
import Tab_TriaGrupoScreen from './SME_Tabs/001SME_TriaGrupo';
import Tab_TriaAcessoScreen from './SME_Tabs/002SME_TriaAcesso';
import Tab_TriaOriDesScreen from './SME_Tabs/003SME_TriaOriDes';



const SME_ManutencaoScreen: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(true);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.error401,
    title: 'Sem Permissão!',
    description: 'Você não tem permissão para acessar essa tela!',
  });


  function getFetchPermission() {
    setLoading(true);


    const url = '/sm/manutencaotriagem/permissao/';


    ApiWS()
      .get(url)
      .then((res) => {
        if(res.status === 200){
          setPermission(true);
        }
        else {
          setPermission(false);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroup', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFetchPermission();
  },[]);


  const tabsList = [
    {
      eventKey: 0,
      title: {
        icon: <IconNetwork />,
        text: 'Grupo',
      },
      screenTab: <Tab_TriaGrupoScreen/>,
    },
    {
      eventKey: 1,
      title: {
        icon: <IconNetwork />,
        text: 'Acesso Usuário',
      },
      screenTab: <Tab_TriaAcessoScreen/>,
    },
    {
      eventKey: 2,
      title: {
        icon: <IconNetwork />,
        text: 'Origem x Destino',
      },
      screenTab: <Tab_TriaOriDesScreen/>,
    },
  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={7}
          navArray={SME_NavRoutes}
          title={'SME'}
        />
      </NavHeader>


      {loading && (
        <LoadingsComponent />
      )}


      {!loading && !permission && (
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}


      {!loading && permission && (

        <Content>
          <ContentStyled fluid>

            <TabsStyled
              id={'control-tab-pcb'}
              activeKey={currentTab}
              onSelect={(tab) => setCurrentTab(parseInt(tab))}
            >

              {tabsList.map((tab) => (

                <Tab
                  eventKey={tab.eventKey}
                  title={
                    <TabViewStyled>

                      <TabIconStyled>
                        {tab.title.icon}
                      </TabIconStyled>


                      <TabTextStyled>
                        {tab.title.text}
                      </TabTextStyled>

                    </TabViewStyled>
                  }>

                  {tab.screenTab}

                </Tab>

              ))}

            </TabsStyled>

          </ContentStyled>

        </Content>

      )}

    </Screen>

  );
};



export default SME_ManutencaoScreen;
