import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors, Metrics,
} from '../../../../../common/constants';

import {
  TableActionButton,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  IconAdd, IconListNew, IconProducts,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import * as Yup from 'yup';

import {
  ModalContainerStyled,
  ModalBodyFilterStyled,
  ModalFilterView,
  ModalTableContainer,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';
import TextInput from '../../../../../components/TextFields/Input';

import ApiWS from '../../../../../services/api.service';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import LinkTableAction from '../../../../../components/Buttons/LinkTableAction';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import EstoqueModal from './EstoqueModal';
import { IToast } from '../../../../../components/IToast/Toast';



interface IProps {
  show: boolean;
  produtoSelected?: any;
  onHide?: any;
}



const ProdutosModal: React.FC<IProps> = (props) => {
  const [carregando, setCarregando] = useState(false);

  const [allProdutos, setAllProdutos] = useState([]);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  const [showModalEstoque, setShowModalEstoque] = useState(false);
  const [showValuesEstoque, setShowValuesEstoque] = useState(null);



  const handleConsultaProdutos = async (values, page_iteration) => {
    setCarregando(true);

    setValuesPayload(values);

    values = {
      'consulta': values.input_product,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    };

    ApiWS()
      .post('/sm/nova_sm/consulta/produtos', values)
      .then((resp) => {
        setAllProdutos(resp.data);



        setAllProdutos(resp.data[0]);
        setTotalResults(resp.data[1][0].TOTAL);
        setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        console.log(err);

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleConsultaProdutos(valuesPayload, page_var);
    setPage(page_var);
  };


  const columns = [
    {
      dataField: '',
      text: '',
      sort: false,
      headerStyle: () => {
        return { width: '3%' };
      },
      formatter: (_cell, row) => {
        return (
          <TableActionButton
            onClick={() => {
              props.produtoSelected(row);
              props.onHide();
            }}>
            <IconAdd
              color={Colors?.black}
              size={16}
            />
          </TableActionButton>
        );
      },
    },
    {
      dataField: 'B1_COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'B1_DESC',
      text: 'Descrição',
      sort: true,
      headerStyle: () => {
        return { width: '35%' };
      },
    },
    {
      dataField: 'B1_UM',
      text: 'UND',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'BM_GRUPO',
      text: 'Grupo',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'BM_DESC',
      text: 'Desc. Grupo',
      sort: true,
      headerStyle: () => {
        return { width: '13%' };
      },
    },
    {
      dataField: '',
      text: 'Estoque',
      sort: true,
      formatter: (cell, row) => {
        return (
          <ButtonTableAction
            key={'right'}
            placement={'right'}
            tooltip={TextTooltip.STOCK}
            icon={
              <IconProducts
                color={Colors?.black}
                size={16}
              />
            }
            backgroundColor={Colors?.primary}
            onClick={() => {
              setShowModalEstoque(true);
              setShowValuesEstoque(row.B1_COD);
            }}
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: allProdutos.length,
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Buscar Produtos'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={Yup.object().shape({ input_product: Yup.string().required('Obrigatório') })}
          initialValues={{ input_product: '' }}
          onSubmit={(values) => {
            handleConsultaProdutos(values, 1);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalFilterView>
                <TextInput
                  className='filter-input-view'
                  type={'text'}
                  name={'input_product'}
                  label={'Produto'}
                  placeholder={'Nome do Produto'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_product', e.target.value);
                  }}
                  onBlur={handleBlur}
                />

                <IButton
                  backgroundColor={Colors?.accept}
                  text={'Consultar'}
                  type={'submit'}
                />
              </ModalFilterView>


              <ModalBodyFilterStyled>

                <ModalTableContainer>

                  {carregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!carregando && allProdutos.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={totalPage === 1}
                          data={allProdutos}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>
                  )}
                </ModalTableContainer>

              </ModalBodyFilterStyled>



              <ModalFooterStyled>

                <IButton
                  disabled={carregando}
                  backgroundColor={Colors?.primary}
                  text={'Fechar'}
                  onClick={props.onHide}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

      {showModalEstoque && (
        <EstoqueModal
          show={showModalEstoque}
          onHide={() => setShowModalEstoque(false)}
          codigoItem={String(showValuesEstoque)}
        />
      )}

    </Modal>

  );
};



export default ProdutosModal;
