import * as Yup from 'yup';



export const POCEquipeValidationSchema = Yup.object().shape({

  CC: Yup.string()
    .required('Campo obrigatório!'),

});


export const POCEquipeValidationSchema2 = Yup.object().shape({

  CC: Yup.string()
    .required('Campo obrigatório!'),
  LIDER: Yup.string()
    .required('Campo obrigatório!'),
  DESCR: Yup.string()
    .required('Campo obrigatório!'),

});

const validations = {
  POCEquipeValidationSchema: POCEquipeValidationSchema,
  POCEquipeValidationSchema2: POCEquipeValidationSchema2,
};

export default validations;

