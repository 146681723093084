import React from 'react';
import { Row } from 'react-bootstrap';

import {
  DisplayCardContainerStyle,
  DisplayCardInfo,
  DisplayCardIcon,
  DisplayCardDescription,
  DisplayCardPhoto,
  DisplayCardAreaData,
  DisplayCardTitle,
} from './styled';



interface IProps {
  disabled?: boolean;
  icon?: any;
  photo?: any;
  data: any;
  color?: string;
  onClick?: any;
  size?: string;
}



const CardDisplay: React.FC<IProps> = (props) => {
  return (

    <DisplayCardContainerStyle
      color={props.color}
      onClick={!props.disabled && props.onClick}
      size={props.size}
    >

      <DisplayCardInfo>

        <DisplayCardPhoto>
          {props.photo}
        </DisplayCardPhoto>


        <DisplayCardAreaData>


          {props.data.map((data) => (
            <Row>

              <DisplayCardTitle>
                {data.title}
              </DisplayCardTitle>

              <DisplayCardDescription>
                {data.desc}
              </DisplayCardDescription>

            </Row>
          ))}
        </DisplayCardAreaData>


        {props?.icon && (
          <DisplayCardIcon>
            {props.icon}
          </DisplayCardIcon>
        )}

      </DisplayCardInfo>



    </DisplayCardContainerStyle>

  );
};



export default CardDisplay;
