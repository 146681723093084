import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Modal,
} from 'react-bootstrap';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../../components/Buttons/IButton';
import LoadingsComponent from '../../../../../../../components/Loadings';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';

import ApiWS from '../../../../../../../services/api.service';

import ColetorCreateModel from './coletor.model';
import ColetorCreateValidationSchema from './coletor.validation';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from './styled';



interface IProps {
  show: boolean;
  negative?: any;
}



const ModalCreateColetor: React.FC<IProps> = (props: IProps) => {
  const formik = useRef<FormikProps<any>>(null);


  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [listDeposit, setListDeposit] = useState([]);



  function showSnackBarProps() {
    toast.error('Erro ao obter dados.', {
      position: 'bottom-center',
      autoClose: Metrics.messageAutoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }


  function renderListDeposit(listResp: any) {
    const listToMap = listResp.map((itemDeposit) => {
      const valueResp = itemDeposit.ID !== '' || itemDeposit.ID !== null || itemDeposit.ID !== undefined ? itemDeposit.ID : null;

      const listMapped = {
        'key': itemDeposit.ID,
        'label': itemDeposit.NOME,
        'value': valueResp,
      };
      return listMapped;
    });

    setListDeposit(listToMap);
    formik.current?.setFieldValue('select_id_deposit', listToMap[0]?.value);
  };


  async function getDepositsSelect() {
    try {
      setMounted(false);

      const url = '/pstock/coletor/lista/deposito';
      const response = await ApiWS().post(url);

      if (response) {
        renderListDeposit(response.data);
      }
      else {
        showSnackBarProps();
      }
    }
    catch (error) {
      console.error('getDepositsSelect', JSON.stringify(error.response, null, 2));

      showSnackBarProps();
    }
    finally {
      setMounted(true);
    }
  };


  async function savePstockColetor(values: typeof ColetorCreateModel) {
    const payload = {
      IDDEPOSITO: values.select_id_deposit,
      DESCR: values.input_description,
      COD: values.input_code,
      IDENTIFICADOR: values.input_identification,
    };

    try {
      setLoading(true);

      const url = '/pstock/coletor/salva';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        toast.success('Coletor salvo.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.negative(true);
      }
    }
    catch (error) {
      console.error('savePstockUnidade', JSON.stringify(error.response, null, 2));

      showSnackBarProps();
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    getDepositsSelect();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <Formik
        enableReinitialize
        validateOnMount
        innerRef={formik}
        initialValues={ColetorCreateModel}
        validationSchema={ColetorCreateValidationSchema}
        onSubmit={(values) => {
          savePstockColetor(values);
        }}>
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (

          <ModalContainerStyled onSubmit={handleSubmit}>

            <TitleGroup>
              {'Criar coletor'}
            </TitleGroup>



            {!mounted && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}



            {mounted && (
              <ModalBodyStyled>

                <Col>
                  <DropdownInput
                    disabled={loading}
                    name={'select_id_deposit'}
                    label={'Depósito'}
                    dropArray={listDeposit}
                    value={values.select_id_deposit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>


                <Col>
                  <TextInput
                    disabled={loading}
                    type={'text'}
                    name={'input_description'}
                    label={'Descrição'}
                    placeholder={'Descrição'}
                    value={values.input_description || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>


                <Col>
                  <TextInput
                    disabled={loading}
                    type={'text'}
                    name={'input_code'}
                    label={'Código'}
                    placeholder={'Código'}
                    value={values.input_code || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>


                <Col>
                  <TextInput
                    disabled={loading}
                    type={'text'}
                    name={'input_identification'}
                    label={'Id Coletor'}
                    placeholder={'Id Coletor'}
                    value={values.input_identification || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

              </ModalBodyStyled>
            )}



            <ModalFooterStyled>
              <IButton
                loading={loading}
                backgroundColor={Colors?.accept}
                text={'Salvar'}
                type={'submit'}
              />


              <IButton
                backgroundColor={Colors?.primary}
                text={'Fechar'}
                onClick={() => {
                  props.negative(false);
                }}
              />
            </ModalFooterStyled>

          </ModalContainerStyled>

        )}
      </Formik>

    </Modal>

  );
};



export default ModalCreateColetor;
