import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../../common/arrays';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  Screen,
  SectionMargin,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import {
  PSTOCK_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS from '../../../../../services/api.service';

import {
  currentDateToInput,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import MonitorPstockModel from './monitor.model';
import MonitorPstockValidationSchema from './monitor.validation';

import {
  RowButtonColetorView,
  RowStatusBadge,
  RowStatusText,
  RowStatusView,
  RowSyncStatusText,
} from './styled';



const PSTOCK_MonitorScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [coletorArray, setColetorArray] = useState([]);
  const [listLoading, setListLoading] = useState(false);



  async function getMonitorData(values: typeof MonitorPstockModel) {
    const payload = {
      'CODCOLETOR': values.input_codColetor,
      'STATUS': values.select_status.toLocaleUpperCase() === 'Todos'.toLocaleUpperCase() ? '' : values.select_status,
      'DATA': values.input_data,
    };

    try {
      setListLoading(true);
      setShowList(false);

      const url = '/pstock/monitor/consulta';
      const response = await ApiWS().post(url, payload);

      setColetorArray(response.data);
    }
    catch (error) {
      console.error('getMonitorData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };

  function renderSyncStatus(status: string) {
    console.log(status);
    return (

      <RowStatusView>
        <RowStatusBadge
          backgroundColor={status === '1' ? 'green' : 'red'}
        />


        <RowSyncStatusText>
          {status === '1' ? 'Sucesso' : 'Erro'}
        </RowSyncStatusText>
      </RowStatusView>

    );
  }

  function renderStatus(status: string) {
    console.log(status);
    return (

      <RowStatusView>
        <RowStatusBadge
          backgroundColor={status.toUpperCase() === 'OK' ? 'green' : 'red'}
        />


        <RowStatusText>
          {status.toUpperCase() === 'OK' ? 'Sincronizado' : 'Erro'}
        </RowStatusText>
      </RowStatusView>

    );
  }



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);



  const columns = [
    {
      dataField: 'ID',
      text: 'Id',
      sort: true,
    },
    {
      dataField: 'CODCOLETOR',
      text: 'Cód. Coletor',
      sort: true,
    },
    {
      dataField: 'DTATUALIZACAO',
      text: 'Data de atualização',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'HORAATUALIZACAO',
      text: 'Hora de atualização',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'PRODUTO',
      text: 'Produto',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.PRODUTO);
      },
    },
    {
      dataField: 'DEPOSITO',
      text: 'Depósito',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.DEPOSITO);
      },
    },
    {
      dataField: 'DEVOLUCAO',
      text: 'Devolução',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.DEVOLUCAO);
      },
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.FORNECEDOR);
      },
    },
    {
      dataField: 'FUNCIONARIO',
      text: 'Funcionário',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.FUNCIONARIO);
      },
    },
    {
      dataField: 'PARAMETRO',
      text: 'Parâmetro',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.PARAMETRO);
      },
    },
    {
      dataField: 'SAIDA',
      text: 'Saída',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return renderSyncStatus(row.SAIDA);
      },
    },
    {
      dataField: 'STATUS',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (_cell: any, row: any) => {
        return renderStatus(row.STATUS);
      },
    },
    {
      dataField: 'OBS',
      text: 'Observação',
      sort: false,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: coletorArray.length,
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={MonitorPstockValidationSchema}
      initialValues={MonitorPstockModel}
      onSubmit={(values) => {
        getMonitorData(values);
      }}>
      {({
        values,
        resetForm,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
      }) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={5}
              navArray={PSTOCK_NavRoutes}
              title={'PStock'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>

              <SectionMargin>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>
                    <Col>
                      <TextInput
                        disabled={loading || listLoading}
                        type={'text'}
                        name={'input_codColetor'}
                        label={'Cód. Coletor'}
                        placeholder={'Código'}
                        value={values.input_codColetor || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>


                    <Col>
                      <DropdownInput
                        disabled={loading || listLoading}
                        name={'select_status'}
                        label={'Situação'}
                        defaultValue={'Todos'.toLocaleUpperCase()}
                        dropArray={[
                          AllItems,
                          {
                            key: '1',
                            label: 'Sincronizado',
                            value: 'OK',
                          },
                          {
                            key: '2',
                            label: 'Erro',
                            value: 'ERROR',
                          },
                        ]}
                        value={values.select_status || 'Todos'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>


                    <Col>
                      <TextInput
                        disabled={loading || listLoading}
                        type={'date'}
                        name={'input_data'}
                        label={'Data'}
                        placeholder={'Data'}
                        min={currentDateToInput(3)}
                        value={values.input_data || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>



                    <Col>
                      <RowButtonColetorView>

                        <IButton
                          disabled={loading || listLoading}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            setValues(MonitorPstockModel);
                          }}
                        />


                        <IButton
                          disabled={!isValid || loading || listLoading}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />

                      </RowButtonColetorView>
                    </Col>
                  </Row>

                </FilterContainerForm>

              </SectionMargin>


              <ContainerView>

                {listLoading && (
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary}
                  />
                )}



                {!loading && !listLoading && showList && coletorArray.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}



                {!loading && !listLoading && showList && coletorArray.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={coletorArray.length < 10}
                        data={coletorArray}
                        columns={columns}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>


            </ContentStyled>

          </Content>

        </Screen>

      )}
    </Formik>

  );
};



export default PSTOCK_MonitorScreen;
