const PAVConfiguracaoModel = {
  EST01: '',
  EST02: '',
  EST03: '',
  PON01: '',
  PON02: '',
  REC01: '',
  REC02: '',
  NAOPARTICIPANTES: [],
};
  
  
  
export default PAVConfiguracaoModel;
