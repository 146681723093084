export const TypeSystem = [
  {
    key: '0',
    label: 'Processo',
    value: 'P',
  },
  {
    key: '1',
    label: 'RCO / ROP',
    value: 'R',
  },
];
