const PcbContratosArray = [
  {
    key: '04',
    color: '#faa200',
    label: 'Em Aprovação',
    value: 'Em Aprovação'.toLocaleUpperCase(),
  },
  {
    key: '01',
    color: '#fa0000',
    label: 'Cancelado',
    value: 'Cancelado'.toLocaleUpperCase(),
  },
  {
    key: '02',
    color: '#FFF000',
    label: 'Em Elaboração',
    value: 'Em Elaboração'.toLocaleUpperCase(),
  },
  {
    key: '03',
    color: '#58c5e9',
    label: 'Emitido',
    value: 'Emitido'.toLocaleUpperCase(),
  },
  {
    key: '08',
    color: '#000000',
    label: 'Finalizado',
    value: 'Finalizado'.toLocaleUpperCase(),
  },
  {
    key: '06',
    color: '#7d7d7d',
    label: 'Paralisado',
    value: 'Paralisado'.toLocaleUpperCase(),
  },
  {
    key: '10',
    color: '#cfcfcf',
    label: 'Revisado',
    value: 'Revisado'.toLocaleUpperCase(),
  },
  {
    key: '09',
    color: '#ffa8b7',
    label: 'Em Revisão',
    value: 'Em Revisão'.toLocaleUpperCase(),
  },
  {
    key: '05',
    color: '#007a00',
    label: 'Vigente',
    value: 'Vigente'.toLocaleUpperCase(),
  },
];



export default PcbContratosArray;
