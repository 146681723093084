import * as Yup from 'yup';



const GIUsuarioValidationSchema = Yup.object().shape({
  
  NOME: Yup.string(),
  EMAIL: Yup.string(),
  LOGIN: Yup.string(),

});



export default GIUsuarioValidationSchema;
