import React, { useEffect, useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  pedidos?: any;
  onHide?: any;
  type: 'write' | 'read';
}



const AnotacaoModal: React.FC<IProps> = (props) => {
  const [carregando, setCarregando] = useState(false);

  const [listAnotacoes, setListAnotacoes] = useState([]);


  function postToWrite(listPcBm: any, values: any) {
    const newList = [];
    setCarregando(true);


    listPcBm.forEach((pcBm) => {
      newList.push({ ...pcBm, 'ANOTACAO': values?.ANOTACAO });
    });


    ApiWS()
      .post('/suprimento/aprovacao/salvaranotacao', newList)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data || 'Anotação realizada com sucesso!',
          });
        }
      })
      .catch((error) => {
        console.error('postToWrite', JSON.stringify(error.response, null, 2));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista entre em contato com o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        props.onHide();
      });
  };


  function postToFetchWrites(pcBm: any) {
    setCarregando(true);

    ApiWS()
      .post('/suprimento/consulta/anotacao', pcBm)
      .then((resp) => {
        if (resp.status === 200) {
          setListAnotacoes(resp.data);
        }
      })
      .catch((error) => {
        console.error('postToWrite', JSON.stringify(error.response, null, 2));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista entre em contato com o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  useEffect(() => {
    if (props.type === 'read') {
      postToFetchWrites(props.pedidos);
    }
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Anotações '.toUpperCase()}
          {props.type === 'read' && (`
          DO
          ${props.pedidos[0].TIPO} -
          FILIAL:  ${props.pedidos[0].FILIAL} |
          NUM:  ${props.pedidos[0].NUM}
        `)}
        </TitleGroup>

        {carregando && (<LoadingsComponent />)}

        {!carregando && props.type === 'write' && (
          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={Yup.object().shape({ ANOTACAO: Yup.string().required('Obrigatório!') })}
            initialValues={{
              ANOTACAO: '',
            }}
            onSubmit={(values) => {
              postToWrite(props.pedidos, values);
            }}>
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              isValid,
            }) => (

              <Form onSubmit={handleSubmit}>

                <ModalBodyStyled>

                  <TextInput
                    textHeight={50}
                    name={'ANOTACAO'}
                    type={'text'}
                    placeholder={'Adicionar anotação...'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ModalBodyStyled>



                <ModalFooterStyled>

                  <IButton
                    backgroundColor={Colors?.buttonCancel}
                    text={'Cancelar'}
                    type={'button'}
                    onClick={props.onHide}
                  />


                  <IButton
                    backgroundColor={Colors?.buttonOk}
                    text={'Enviar'}
                    type={'submit'}
                    onClick={() => {
                      if (!isValid) {
                        IToast({
                          type: 'info',
                          message: 'A anotação é obrigatória!',
                        });
                      }
                    }}
                  />

                </ModalFooterStyled>

              </Form>

            )}
          </Formik>
        )}


        {!carregando && props.type === 'read' && listAnotacoes.length > 0 && (
          <>
            <ModalBodyStyled>
              {listAnotacoes.map((nota) => (
                <div style={{ marginLeft: '5%', width: '90%' }}>

                  <div style={{ fontWeight: 'lighter', fontSize: '14px' }}> {nota.DATA} {nota.HORA}</div>
                  <div style={{ fontWeight: 'bold', margin: '2px auto 10px auto' }}> {nota.APROVADOR} </div>



                  <div style={{ width: '90%', wordWrap: 'break-word' }}> {nota.ANOTACAO} </div>
                  <hr />

                </div>
              ))}

            </ModalBodyStyled>



            <ModalFooterStyled>
              <IButton
                backgroundColor={Colors?.primary}
                text={'Fechar'}
                onClick={props.onHide}
              />

            </ModalFooterStyled>
          </>
        )}



        {!carregando && props.type === 'read' && listAnotacoes.length <= 0 && (
          <>
            <ModalBodyStyled>

              <div style={{ marginLeft: '5%', width: '90%', textAlign: 'center' }}>

                <h5> Não há anotações aqui! </h5>
                <hr />

              </div>

            </ModalBodyStyled>



            <ModalFooterStyled>
              <IButton
                backgroundColor={Colors?.primary}
                text={'Fechar'}
                onClick={props.onHide}
              />

            </ModalFooterStyled>
          </>
        )}

      </ModalContainerStyled>

    </Modal>

  );
};



export default AnotacaoModal;
