import styled from 'styled-components';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Colors } from '../../../../../common/constants';


interface IProps {
  backgroundColor?: any;
}


export const TableResume = styled.table`
  border: solid 1px #B7B7B7;
  font-size: 70%;
`;

export const TrHeader = styled.tr`
  background-color: ${Colors?.primary};
  color: ${Colors?.white};
`;


export const TrRelease = styled.tr`
  background-color: ${Colors?.blueLigth};
  cursor: pointer;
`;


export const ThExpandAll = styled.th`
  text-align: center ;
  cursor: pointer;
`;


export const TdRelease = styled.td`
  font-weight: bold;
  width: 150px;
`;


export const TdBudgeted = styled.td`
  width: 120px;
`;


export const TdProvider = styled.td`
  width: 80px;
`;


export const ContainerExportResume = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 9999;
  opacity: 0.30;
  transition: opacity 0.3s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  min-height: 70px;
  margin: auto 7px;
  padding: 8px 16px;
  background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
  color: white !important;
  font-size: 1.125rem;
  border: none;
  border-radius: 100% 100%!important;
  z-index: 2 !important;

  :hover {
    opacity: 1;
  }
  :active,
  :focus {
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    box-shadow: none !important;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.backgroundColor || 'gray'};
    pointer-events: none;
    opacity: .65;
  }

  span {
    margin: 0 8px;
  }
`;

export const BtnExportResume = styled(ReactHTMLTableToExcel)`
  background-color: transparent;
  border: none;
`;

export const ThHead = styled.th`
  background-color: ${Colors?.primary};
  color: ${Colors?.white};
`;

