import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import { ArrayChrClassificacao, ArrayChrMao, ArrayChrSituacao, ArrayChrTipo } from '../../../../../../common/arrays/ChrConsulta';
import { Colors, Images } from '../../../../../../common/constants';
import ExcelConfigs from '../../../../../../common/excel';
import { FilterContainerForm, RowButtonFiltersView } from '../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../components/Empty';
import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import TableCuston from '../../../../../../components/Table';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';
import { DateTimezoneSpliceFormat } from '../../../../../../utils/DateUtils';
import GHRConsultaModel from './001GHR.model';
import GHRConsultaSchema from './001GHR.validation';

const Tab_Sintetico: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPerm] = useState(false);

  const [error, setError] = useState(false);
  const [permission, setPermission] = useState(true);


  // const [showModal, setShowModal] = useState(false);


  const [showList, setShowList] = useState(false);

  const [listOSDrop, setListOSDrop] = useState([]);
  const [listReasonDrop, setListReasonDrop] = useState([]);
  const [list, setList] = useState([]);


  const [emptyObject] = useState({
    image: Images.empty,
    title: 'Sem dados!',
    description: 'Não há nenhum dado aqui',
  });


  function postFetchOS() {
    setLoading(false);
    setShowList(false);

    const url = '/grade/consulta/os';


    ApiWS()
      .get(url)
      .then((resp) => {
        if (resp.status === 200) {
          setListOSDrop([{ key: '777', label: 'TODOS', value: 'TODOS' }]);
          resp.data.forEach((os: { DESCR: any; OS: string; }, index: any) => {
            setListOSDrop((listOSDrop) => [...listOSDrop, {
              key: index,
              label: os.DESCR,
              value: os?.OS?.trim(),
            }]);
          });
          setError(false);
        }
        else if (resp.status === 203) {
          setPermission(false);
          setError(false);
        }
      })
      .catch((err) => {
        console.error('postFetchOS', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        setError(true);
      })
      .finally(() => setLoading(false));
  };


  function postFetchReason() {
    setLoading(false);
    setShowList(false);

    const url = '/grade/consulta/motivo/';


    ApiWS()
      .get(url)
      .then((resp) => {
        if (resp.status === 200) {
          setListReasonDrop([{
            key: 999,
            label: 'Todos',
            value: 'TODOS',
          }]);
          resp.data.forEach((reason: { DESCR: any; }, index: any) => {
            setListReasonDrop((listReasonDrop) => [...listReasonDrop, {
              key: index,
              label: reason?.DESCR,
              value: reason?.DESCR,
            }]);
          });
          setError(false);
        }
        else if (resp.status === 203) {
          setPermission(false);
          setError(false);
        }
      })
      .catch((err) => {
        console.error('postFetchReason', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        setError(true);
      })
      .finally(() => setLoading(false));
  };

  function postFetch(values: { SITUAC: string; FUNCAO: string; OS: string; MATRICULA: string; NOME: string; MAO: string; CLASSIFICACAO: string; DTINICIO: string; DTFIM: string; MOTIVO: string; TIPO: string; }) {
    setLoading(true);
    setShowList(false);

    const url = '/grade/consulta/';
    const payload = values;


    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setList(resp.data);
          setShowList(true);
          setError(false);
        }
        else if (resp.status === 203) {
          setPermission(false);
          setError(false);
        }
      })
      .catch((err) => {
        console.error('postFetch', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro. Tente novamente! Se o erro persistir contate o suporte.',
        });

        setError(true);
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    postFetchOS();
    postFetchReason();
    // postAuth();
  }, []);


  const fontSizeCell = '10px';


  const options = {
    custom: true,
    totalSize: list.length,
  };

  const columns = [
    {
      dataField: 'CC',
      text: 'CC',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '10%' };
      },
      style: (_: any) => {
        return { fontSize: fontSizeCell };
      },
      formatter: (_cell: any, row: { CC: any; DESCR_CC: string; }) => {
        return (`${row.CC} - ${row.DESCR_CC.trim()}`);
      },
    },
    {
      dataField: 'MATRICULA',
      text: 'Mat.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '10%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'NOME',
      text: 'Nome',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '20%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'FUNCAO',
      text: 'Função',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '10%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'MAO',
      text: 'Mão',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '10%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'SEQ',
      text: 'Seq.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'DATA_APO',
      text: 'Data',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '10%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
      formatter: (_cell: any, row: { DATA_APO: string; }) => {
        return DateTimezoneSpliceFormat(row?.DATA_APO);
      },
    },
    {
      dataField: 'CLASSIFICACAO',
      text: 'Classif.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'COD_EVENTO',
      text: 'Cod. ev.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '15%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'DESCR_EVENTO',
      text: 'Desc. ev.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '15%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'COD_EVENTO_INFO',
      text: 'Cod. ev. Inf.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '15%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'HORAS',
      text: 'Horas',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'TIPOHOR',
      text: 'Tipo Hr.',
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
      hidden: true,
    },
    {
      dataField: 'HORAS_CALC',
      text: 'H. Calc.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'HORAS_CUSTO',
      text: 'H. Custo',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'FATOR',
      text: 'Fator',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'CUSTO',
      text: 'Custo',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'HORAS_INFO',
      text: 'H. Info.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'COD_ABONO',
      text: 'Cod. abono.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'HORAS_ABONADAS',
      text: 'H. Abona',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '6%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'MOTIVO_ABONO',
      text: 'Motivo Abono',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'TIPO_HORA_EXTRA',
      text: 'Tipo. h extra',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'PERC_HORA_EXTRA',
      text: '%h Extra',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '5%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'TIPO_MARC',
      text: 'Tipo. Marc.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'PERICULOSIDADE',
      text: 'Periculos.',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
    {
      dataField: 'SALARIO',
      text: 'Salário',
      headerStyle: () => {
        return { fontSize: fontSizeCell, width: '7%' };
      },
      style: (_: any, _row: any) => {
        return { fontSize: fontSizeCell };
      },
    },
  ];


  return (


    <Container>

      {loadingPerm && <LoadingsComponent />}


      {!loadingPerm && !loading && !error && !permission && (
        <EmptyContent
          image={Images.error401}
          title={'Sem permissão!'}
          description={'Você não tem permissão para acessar essa tela!'}
        />
      )}



      {!loadingPerm && !loading && error && (
        <EmptyContent
          image={Images.other500}
          title={'Erro 500!'}
          description={'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte.'}
        />
      )}


      {loading && (
        <LoadingsComponent />
      )}


      {!loadingPerm && !error && permission && (
        <ToolkitProvider
          keyField={'id'}
          data={list}
          columns={columns}
          exportCSV={{
            blobType: ExcelConfigs.blobType,
            fileName: ExcelConfigs.Horas_Grid,
            onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
            exportAll: ExcelConfigs.exportAll,
            noAutoBOM: false,
            separator: ';',
          }}>
          {(propsTkProvider) => (
            <>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={GHRConsultaModel}
                validationSchema={GHRConsultaSchema}
                onSubmit={(values) => {
                  postFetch(values);
                }}>
                {({
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  isValid,
                  values,
                }) => (


                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>
                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'SITUAC'}
                          label={'Situação'}
                          placeholder={'Selecione'}
                          dropArray={ArrayChrSituacao}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.SITUAC}
                        />
                      </Col>

                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'FUNCAO'}
                          label={'Função'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FUNCAO}
                        />
                      </Col>

                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'OS'}
                          label={'OS'}
                          placeholder={'Selecione'}
                          dropArray={listOSDrop}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.OS}
                        />
                      </Col>



                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'MATRICULA'}
                          label={'Matrícula'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.MATRICULA}
                        />
                      </Col>

                    </Row>



                    <Row>
                      <Col>
                        <TextInput
                          disabled={loading}
                          type={'text'}
                          name={'NOME'}
                          label={'Nome'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.NOME}
                        />
                      </Col>


                      <Col>
                        <Row>
                          <Col>
                            <DropdownInput
                              disabled={loading}
                              name={'MAO'}
                              label={'Mão'}
                              placeholder={'Selecione'}
                              dropArray={ArrayChrMao}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.MAO}
                            />
                          </Col>

                          <Col>
                            <DropdownInput
                              disabled={loading}
                              name={'CLASSIFICACAO'}
                              label={'Classificação'}
                              placeholder={'Selecione'}
                              dropArray={ArrayChrClassificacao}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.CLASSIFICACAO}
                            />
                          </Col>

                        </Row>
                      </Col>


                    </Row>

                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <TextInput
                              disabled={loading}
                              type={'date'}
                              name={'DTINICIO'}
                              label={'Ini'}
                              placeholder={'Data'}
                              min={0}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.DTINICIO}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              disabled={loading}
                              type={'date'}
                              name={'DTFIM'}
                              label={'Fim'}
                              placeholder={'Data'}
                              min={0}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.DTFIM}
                            />
                          </Col>


                          <Col xs={3}>
                            <DropdownInput
                              disabled={loading}
                              name={'TIPO'}
                              label={'Tipo'}
                              placeholder={'Selecione'}
                              dropArray={ArrayChrTipo}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.TIPO}
                            />
                          </Col>
                        </Row>
                      </Col>


                      <Col>
                        <DropdownInput
                          disabled={loading}
                          name={'MOTIVO'}
                          label={'Motivo'}
                          placeholder={'Selecione'}
                          dropArray={listReasonDrop}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.MOTIVO}
                        />
                      </Col>

                    </Row>



                    <RowButtonFiltersView>

                      {list.length > 0 && (
                        <IButton
                          type={'button'}
                          disabled={!isValid || loading}
                          backgroundColor={Colors?.excel}
                          text={'Exportar'}
                          onClick={() => {
                            propsTkProvider.csvProps.onExport();
                          }}
                        />
                      )}


                      <IButton
                        disabled={loading}
                        type={'reset'}
                        backgroundColor={Colors?.gray}
                        text={'Limpar'}
                      />


                      <IButton
                        disabled={loading}
                        type={'submit'}
                        backgroundColor={Colors?.primary}
                        text={'Filtrar'}
                      />

                    </RowButtonFiltersView>

                  </FilterContainerForm>

                )}

              </Formik>



              {!loading && showList && list.length > 0 && (

                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity={list.length < 10}
                      data={list}
                      columns={columns}
                      baseProps={propsTkProvider.baseProps}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                    />
                  )}
                </PaginationProvider>

              )}


              {!loading && showList && list.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}
            </>
          )}
        </ToolkitProvider>
      )}


    </Container>


  );
};

export default Tab_Sintetico;
