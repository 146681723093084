import React from 'react';

import {
  Formik,
} from 'formik';
import { Metrics } from '../../../../../../../common/constants';
import { ContainerView, SectionMargin } from '../../../../../../../common/styles/styled.layout';
import TextInputLabelFloat from '../../../../../../../components/TextFields/InputLabelFloat';
import { maskToFloat, maskToMoney, maskToMoney2, maskToMoney3 } from '../../../../../../../utils/CurrencyUtils';
import { returnCalcMarginsAndTaxOfLS_Budgeted, returnCalcMarginsAndTaxOfLS_Planned, returnCalcMarginsAndTaxOfLS_Provider } from '../../PFF-Formula';
import { PffFilterContainerForm } from '../../PFF-lancamento-styled';
import { ColFieldsGrid, RowFieldsGrid, TitleInterGroup } from '../PFF-grid-styled';
import PffGridValidationSchema from '../PFF-grid-validation';
import { PffReceitasRecebiveisModel } from '../PFF-grid.model';



interface IProps {
  marginReal?: any;
  marginPorc?: any;
  releaseType?: any;
}


const Tab_ReceitasRecebiveisScreen: React.FC<IProps> = (props) => {
  function mountPffModel(model) {
    const data = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[4].RRE;
    let total = 0.00;

    // ORCADO
    if (props.releaseType.COD_LANCAMENTO === 'OR') {
      model = {
        ...model,
        MOBILIZACAO: { ORCADO: maskToMoney(`${data[0].ORCADO}`) },
        INDIRETOS: { ORCADO: maskToMoney(`${data[1].ORCADO}`) },
        REVENDA: { ORCADO: maskToMoney(`${data[2].ORCADO}`) },
        SERVICO_AVANCO_FISICO: { ORCADO: maskToMoney(`${data[3].ORCADO}`) },
        EXTRA_RECEITAS_RECEB: { ORCADO: maskToMoney(`${data[4].ORCADO}`) },
        OUTRAS_RECEITAS_RECEB: { ORCADO: maskToMoney(`${data[5].ORCADO}`) },
      };


      total = total + maskToFloat(model.MOBILIZACAO.ORCADO);
      total = total + maskToFloat(model.INDIRETOS.ORCADO);
      total = total + maskToFloat(model.REVENDA.ORCADO);
      total = total + maskToFloat(model.SERVICO_AVANCO_FISICO.ORCADO);
      total = total + maskToFloat(model.EXTRA_RECEITAS_RECEB.ORCADO);
      total = total + maskToFloat(model.OUTRAS_RECEITAS_RECEB.ORCADO);


      model = {
        ...model,
        TOTAL_RECEITAS_RECEB: { ORCADO: maskToMoney2(total) },
      };
    };


    // PLANEJADO
    if (props.releaseType.COD_LANCAMENTO === 'PL') {
      model = {
        ...model,
        MOBILIZACAO: { PLANEJADO: maskToMoney(`${data[0].PLANEJADO}`) },
        INDIRETOS: { PLANEJADO: maskToMoney(`${data[1].PLANEJADO}`) },
        REVENDA: { PLANEJADO: maskToMoney(`${data[2].PLANEJADO}`) },
        SERVICO_AVANCO_FISICO: { PLANEJADO: maskToMoney(`${data[3].PLANEJADO}`) },
        EXTRA_RECEITAS_RECEB: { PLANEJADO: maskToMoney(`${data[4].PLANEJADO}`) },
        OUTRAS_RECEITAS_RECEB: { PLANEJADO: maskToMoney(`${data[5].PLANEJADO}`) },
      };


      total = total + maskToFloat(model.MOBILIZACAO.PLANEJADO);
      total = total + maskToFloat(model.INDIRETOS.PLANEJADO);
      total = total + maskToFloat(model.REVENDA.PLANEJADO);
      total = total + maskToFloat(model.SERVICO_AVANCO_FISICO.PLANEJADO);
      total = total + maskToFloat(model.EXTRA_RECEITAS_RECEB.PLANEJADO);
      total = total + maskToFloat(model.OUTRAS_RECEITAS_RECEB.PLANEJADO);


      model = {
        ...model,
        TOTAL_RECEITAS_RECEB: { PLANEJADO: maskToMoney2(total) },
      };
    };


    // PREVISTO
    if (props.releaseType.COD_LANCAMENTO === 'PR') {
      let sum_JAN = 0.00;
      let sum_FEV = 0.00;
      let sum_MAR = 0.00;
      let sum_ABR = 0.00;
      let sum_MAI = 0.00;
      let sum_JUN = 0.00;
      let sum_JUL = 0.00;
      let sum_AGO = 0.00;
      let sum_SET = 0.00;
      let sum_OUT = 0.00;
      let sum_NOV = 0.00;
      let sum_DEZ = 0.00;

      data.forEach((account) => {
        if (account.JAN && account.JAN.indexOf(',') !== -1) {
          account.JAN = account.JAN.replaceAll('.', '');
          account.JAN = account.JAN.replaceAll(',', '.');
        }
        if (account.FEV && account.FEV.indexOf(',') !== -1) {
          account.FEV = account.FEV.replaceAll('.', '');
          account.FEV = account.FEV.replaceAll(',', '.');
        }
        if (account.MAR && account.MAR.indexOf(',') !== -1) {
          account.MAR = account.MAR.replaceAll('.', '');
          account.MAR = account.MAR.replaceAll(',', '.');
        }
        if (account.ABR && account.ABR.indexOf(',') !== -1) {
          account.ABR = account.ABR.replaceAll('.', '');
          account.ABR = account.ABR.replaceAll(',', '.');
        }
        if (account.MAI && account.MAI.indexOf(',') !== -1) {
          account.MAI = account.MAI.replaceAll('.', '');
          account.MAI = account.MAI.replaceAll(',', '.');
        }
        if (account.JUN && account.JUN.indexOf(',') !== -1) {
          account.JUN = account.JUN.replaceAll('.', '');
          account.JUN = account.JUN.replaceAll(',', '.');
        }
        if (account.JUL && account.JUL.indexOf(',') !== -1) {
          account.JUL = account.JUL.replaceAll('.', '');
          account.JUL = account.JUL.replaceAll(',', '.');
        }
        if (account.AGO && account.AGO.indexOf(',') !== -1) {
          account.AGO = account.AGO.replaceAll('.', '');
          account.AGO = account.AGO.replaceAll(',', '.');
        }
        if (account.SET && account.SET.indexOf(',') !== -1) {
          account.SET = account.SET.replaceAll('.', '');
          account.SET = account.SET.replaceAll(',', '.');
        }
        if (account.OUT && account.OUT.indexOf(',') !== -1) {
          account.OUT = account.OUT.replaceAll('.', '');
          account.OUT = account.OUT.replaceAll(',', '.');
        }
        if (account.NOV && account.NOV.indexOf(',') !== -1) {
          account.NOV = account.NOV.replaceAll('.', '');
          account.NOV = account.NOV.replaceAll(',', '.');
        }
        if (account.DEZ && account.DEZ.indexOf(',') !== -1) {
          account.DEZ = account.DEZ.replaceAll('.', '');
          account.DEZ = account.DEZ.replaceAll(',', '.');
        }

        sum_JAN = sum_JAN + parseFloat(account.JAN);
        sum_FEV = sum_FEV + parseFloat(account.FEV);
        sum_MAR = sum_MAR + parseFloat(account.MAR);
        sum_ABR = sum_ABR + parseFloat(account.ABR);
        sum_MAI = sum_MAI + parseFloat(account.MAI);
        sum_JUN = sum_JUN + parseFloat(account.JUN);
        sum_JUL = sum_JUL + parseFloat(account.JUL);
        sum_AGO = sum_AGO + parseFloat(account.AGO);
        sum_SET = sum_SET + parseFloat(account.SET);
        sum_OUT = sum_OUT + parseFloat(account.OUT);
        sum_NOV = sum_NOV + parseFloat(account.NOV);
        sum_DEZ = sum_DEZ + parseFloat(account.DEZ);
      });


      model = {
        ...model,
        MOBILIZACAO: {
          JAN: maskToMoney(`${data[0].JAN}`),
          FEV: maskToMoney(`${data[0].FEV}`),
          MAR: maskToMoney(`${data[0].MAR}`),
          ABR: maskToMoney(`${data[0].ABR}`),
          MAI: maskToMoney(`${data[0].MAI}`),
          JUN: maskToMoney(`${data[0].JUN}`),
          JUL: maskToMoney(`${data[0].JUL}`),
          AGO: maskToMoney(`${data[0].AGO}`),
          SET: maskToMoney(`${data[0].SET}`),
          OUT: maskToMoney(`${data[0].OUT}`),
          NOV: maskToMoney(`${data[0].NOV}`),
          DEZ: maskToMoney(`${data[0].DEZ}`),
        },

        INDIRETOS: {
          JAN: maskToMoney(`${data[1].JAN}`),
          FEV: maskToMoney(`${data[1].FEV}`),
          MAR: maskToMoney(`${data[1].MAR}`),
          ABR: maskToMoney(`${data[1].ABR}`),
          MAI: maskToMoney(`${data[1].MAI}`),
          JUN: maskToMoney(`${data[1].JUN}`),
          JUL: maskToMoney(`${data[1].JUL}`),
          AGO: maskToMoney(`${data[1].AGO}`),
          SET: maskToMoney(`${data[1].SET}`),
          OUT: maskToMoney(`${data[1].OUT}`),
          NOV: maskToMoney(`${data[1].NOV}`),
          DEZ: maskToMoney(`${data[1].DEZ}`),
        },

        REVENDA: {
          JAN: maskToMoney(`${data[2].JAN}`),
          FEV: maskToMoney(`${data[2].FEV}`),
          MAR: maskToMoney(`${data[2].MAR}`),
          ABR: maskToMoney(`${data[2].ABR}`),
          MAI: maskToMoney(`${data[2].MAI}`),
          JUN: maskToMoney(`${data[2].JUN}`),
          JUL: maskToMoney(`${data[2].JUL}`),
          AGO: maskToMoney(`${data[2].AGO}`),
          SET: maskToMoney(`${data[2].SET}`),
          OUT: maskToMoney(`${data[2].OUT}`),
          NOV: maskToMoney(`${data[2].NOV}`),
          DEZ: maskToMoney(`${data[2].DEZ}`),
        },

        SERVICO_AVANCO_FISICO: {
          JAN: maskToMoney(`${data[3].JAN}`),
          FEV: maskToMoney(`${data[3].FEV}`),
          MAR: maskToMoney(`${data[3].MAR}`),
          ABR: maskToMoney(`${data[3].ABR}`),
          MAI: maskToMoney(`${data[3].MAI}`),
          JUN: maskToMoney(`${data[3].JUN}`),
          JUL: maskToMoney(`${data[3].JUL}`),
          AGO: maskToMoney(`${data[3].AGO}`),
          SET: maskToMoney(`${data[3].SET}`),
          OUT: maskToMoney(`${data[3].OUT}`),
          NOV: maskToMoney(`${data[3].NOV}`),
          DEZ: maskToMoney(`${data[3].DEZ}`),
        },

        EXTRA_RECEITAS_RECEB: {
          JAN: maskToMoney(`${data[4].JAN}`),
          FEV: maskToMoney(`${data[4].FEV}`),
          MAR: maskToMoney(`${data[4].MAR}`),
          ABR: maskToMoney(`${data[4].ABR}`),
          MAI: maskToMoney(`${data[4].MAI}`),
          JUN: maskToMoney(`${data[4].JUN}`),
          JUL: maskToMoney(`${data[4].JUL}`),
          AGO: maskToMoney(`${data[4].AGO}`),
          SET: maskToMoney(`${data[4].SET}`),
          OUT: maskToMoney(`${data[4].OUT}`),
          NOV: maskToMoney(`${data[4].NOV}`),
          DEZ: maskToMoney(`${data[4].DEZ}`),
        },

        OUTRAS_RECEITAS_RECEB: {
          JAN: maskToMoney(`${data[5].JAN}`),
          FEV: maskToMoney(`${data[5].FEV}`),
          MAR: maskToMoney(`${data[5].MAR}`),
          ABR: maskToMoney(`${data[5].ABR}`),
          MAI: maskToMoney(`${data[5].MAI}`),
          JUN: maskToMoney(`${data[5].JUN}`),
          JUL: maskToMoney(`${data[5].JUL}`),
          AGO: maskToMoney(`${data[5].AGO}`),
          SET: maskToMoney(`${data[5].SET}`),
          OUT: maskToMoney(`${data[5].OUT}`),
          NOV: maskToMoney(`${data[5].NOV}`),
          DEZ: maskToMoney(`${data[5].DEZ}`),
        },
        TOTAL_RECEITAS_RECEB: {
          JAN: maskToMoney3(sum_JAN),
          FEV: maskToMoney3(sum_FEV),
          MAR: maskToMoney3(sum_MAR),
          ABR: maskToMoney3(sum_ABR),
          MAI: maskToMoney3(sum_MAI),
          JUN: maskToMoney3(sum_JUN),
          JUL: maskToMoney3(sum_JUL),
          AGO: maskToMoney3(sum_AGO),
          SET: maskToMoney3(sum_SET),
          OUT: maskToMoney3(sum_OUT),
          NOV: maskToMoney3(sum_NOV),
          DEZ: maskToMoney3(sum_DEZ),
        },
      };
    };


    // SALVA MARGEM NO LS PARA CALCULO
    const margemPorcLocalStorage = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/MARGEM_REAL'));
    margemPorcLocalStorage[1][0] = total;
    localStorage.setItem('@pff/LANCAMENTO/MARGEM_REAL', JSON.stringify(margemPorcLocalStorage));

    return model;
  };

  function postDraftSave(values: any) {
    alert(JSON.stringify(values));
  };


  const releaseList = [
    'Total Receitas Recebíveis',
    'Mobilização',
    'Indiretos',
    'Revenda',
    'Serviços/Avanços Físicos',
    'Extra',
    'Outras',
  ];


  // ORIGINAL
  const listFieldsBudgeted = (values) => {
    return [
      { name: 'MOBILIZACAO.ORCADO', label: releaseList[1], value: values.MOBILIZACAO.ORCADO },
      { name: 'INDIRETOS.ORCADO', label: releaseList[2], value: values.INDIRETOS.ORCADO },
      { name: 'REVENDA.ORCADO', label: releaseList[3], value: values.REVENDA.ORCADO },
      { name: 'SERVICO_AVANCO_FISICO.ORCADO', label: releaseList[4], value: values.SERVICO_AVANCO_FISICO.ORCADO },
      { name: 'EXTRA_RECEITAS_RECEB.ORCADO', label: releaseList[5], value: values.EXTRA_RECEITAS_RECEB.ORCADO },
      { name: 'OUTRAS_RECEITAS_RECEB.ORCADO', label: releaseList[6], value: values.OUTRAS_RECEITAS_RECEB.ORCADO },
    ];
  };

  function calcBudgeted(values: any, setFieldValue: any) {
    let marginReal = 0.00;
    let marginPorc = 0.00;

    marginReal = returnCalcMarginsAndTaxOfLS_Budgeted().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Budgeted().PORC;

    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.ORCADO', marginReal);
    setFieldValue('MARGEM_PORC.ORCADO', marginPorc);
  };

  function saveOnLocalStorageOR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[4].RRE.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.ORCADO = e;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PLANEJADO
  const listFieldsPlanned = (values) => {
    return [
      { name: 'MOBILIZACAO.PLANEJADO', label: releaseList[1], value: values.MOBILIZACAO.PLANEJADO },
      { name: 'INDIRETOS.PLANEJADO', label: releaseList[2], value: values.INDIRETOS.PLANEJADO },
      { name: 'REVENDA.PLANEJADO', label: releaseList[3], value: values.REVENDA.PLANEJADO },
      { name: 'SERVICO_AVANCO_FISICO.PLANEJADO', label: releaseList[4], value: values.SERVICO_AVANCO_FISICO.PLANEJADO },
      { name: 'EXTRA_RECEITAS_RECEB.PLANEJADO', label: releaseList[5], value: values.EXTRA_RECEITAS_RECEB.PLANEJADO },
      { name: 'OUTRAS_RECEITAS_RECEB.PLANEJADO', label: releaseList[6], value: values.OUTRAS_RECEITAS_RECEB.PLANEJADO },
    ];
  };

  function calcPlanned(values: any, setFieldValue: any) {
    let marginReal = 0.00;
    let marginPorc = 0.00;

    marginReal = returnCalcMarginsAndTaxOfLS_Planned().REAL;
    marginPorc = returnCalcMarginsAndTaxOfLS_Planned().PORC;

    props.marginReal({ month: 0, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: 0, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue('MARGEM_REAL.PLANEJADO', marginReal);
    setFieldValue('MARGEM_PORC.PLANEJADO', marginPorc);
  };

  function saveOnLocalStoragePL(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[4].RRE.forEach((invoicing) => {
      if (invoicing.TIPO === field.label) {
        invoicing.PLANEJADO = e;
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };


  // PREVISTO
  const listFieldsProvider = (values) => {
    const list = [];
    const currentMonth = parseInt(JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'))[0]?.MESREF[0]?.MES);

    list.push(
      { name: 'TOTAL_RECEITAS_RECEB.JAN', label: 'JAN', value: values.TOTAL_RECEITAS_RECEB.JAN, typeRelease: releaseList[0], month: 0, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.FEV', label: 'FEV', value: values.TOTAL_RECEITAS_RECEB.FEV, typeRelease: releaseList[0], month: 1, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.MAR', label: 'MAR', value: values.TOTAL_RECEITAS_RECEB.MAR, typeRelease: releaseList[0], month: 2, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.ABR', label: 'ABR', value: values.TOTAL_RECEITAS_RECEB.ABR, typeRelease: releaseList[0], month: 3, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.MAI', label: 'MAI', value: values.TOTAL_RECEITAS_RECEB.MAI, typeRelease: releaseList[0], month: 4, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.JUN', label: 'JUN', value: values.TOTAL_RECEITAS_RECEB.JUN, typeRelease: releaseList[0], month: 5, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.JUL', label: 'JUL', value: values.TOTAL_RECEITAS_RECEB.JUL, typeRelease: releaseList[0], month: 6, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.AGO', label: 'AGO', value: values.TOTAL_RECEITAS_RECEB.AGO, typeRelease: releaseList[0], month: 7, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.SET', label: 'SET', value: values.TOTAL_RECEITAS_RECEB.SET, typeRelease: releaseList[0], month: 8, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.OUT', label: 'OUT', value: values.TOTAL_RECEITAS_RECEB.OUT, typeRelease: releaseList[0], month: 9, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.NOV', label: 'NOV', value: values.TOTAL_RECEITAS_RECEB.NOV, typeRelease: releaseList[0], month: 10, disabled: true },
      { name: 'TOTAL_RECEITAS_RECEB.DEZ', label: 'DEZ', value: values.TOTAL_RECEITAS_RECEB.DEZ, typeRelease: releaseList[0], month: 11, disabled: true },

      { name: 'MOBILIZACAO.JAN', label: 'JAN', value: values.MOBILIZACAO.JAN, typeRelease: releaseList[1], month: 0, disabled: currentMonth >= 1 },
      { name: 'MOBILIZACAO.FEV', label: 'FEV', value: values.MOBILIZACAO.FEV, typeRelease: releaseList[1], month: 1, disabled: currentMonth >= 2 },
      { name: 'MOBILIZACAO.MAR', label: 'MAR', value: values.MOBILIZACAO.MAR, typeRelease: releaseList[1], month: 2, disabled: currentMonth >= 3 },
      { name: 'MOBILIZACAO.ABR', label: 'ABR', value: values.MOBILIZACAO.ABR, typeRelease: releaseList[1], month: 3, disabled: currentMonth >= 4 },
      { name: 'MOBILIZACAO.MAI', label: 'MAI', value: values.MOBILIZACAO.MAI, typeRelease: releaseList[1], month: 4, disabled: currentMonth >= 5 },
      { name: 'MOBILIZACAO.JUN', label: 'JUN', value: values.MOBILIZACAO.JUN, typeRelease: releaseList[1], month: 5, disabled: currentMonth >= 6 },
      { name: 'MOBILIZACAO.JUL', label: 'JUL', value: values.MOBILIZACAO.JUL, typeRelease: releaseList[1], month: 6, disabled: currentMonth >= 7 },
      { name: 'MOBILIZACAO.AGO', label: 'AGO', value: values.MOBILIZACAO.AGO, typeRelease: releaseList[1], month: 7, disabled: currentMonth >= 8 },
      { name: 'MOBILIZACAO.SET', label: 'SET', value: values.MOBILIZACAO.SET, typeRelease: releaseList[1], month: 8, disabled: currentMonth >= 9 },
      { name: 'MOBILIZACAO.OUT', label: 'OUT', value: values.MOBILIZACAO.OUT, typeRelease: releaseList[1], month: 9, disabled: currentMonth >= 10 },
      { name: 'MOBILIZACAO.NOV', label: 'NOV', value: values.MOBILIZACAO.NOV, typeRelease: releaseList[1], month: 10, disabled: currentMonth >= 11 },
      { name: 'MOBILIZACAO.DEZ', label: 'DEZ', value: values.MOBILIZACAO.DEZ, typeRelease: releaseList[1], month: 11, disabled: currentMonth >= 12 },

      { name: 'INDIRETOS.JAN', label: 'JAN', value: values.INDIRETOS.JAN, typeRelease: releaseList[2], month: 0, disabled: currentMonth >= 1 },
      { name: 'INDIRETOS.FEV', label: 'FEV', value: values.INDIRETOS.FEV, typeRelease: releaseList[2], month: 1, disabled: currentMonth >= 2 },
      { name: 'INDIRETOS.MAR', label: 'MAR', value: values.INDIRETOS.MAR, typeRelease: releaseList[2], month: 2, disabled: currentMonth >= 3 },
      { name: 'INDIRETOS.ABR', label: 'ABR', value: values.INDIRETOS.ABR, typeRelease: releaseList[2], month: 3, disabled: currentMonth >= 4 },
      { name: 'INDIRETOS.MAI', label: 'MAI', value: values.INDIRETOS.MAI, typeRelease: releaseList[2], month: 4, disabled: currentMonth >= 5 },
      { name: 'INDIRETOS.JUN', label: 'JUN', value: values.INDIRETOS.JUN, typeRelease: releaseList[2], month: 5, disabled: currentMonth >= 6 },
      { name: 'INDIRETOS.JUL', label: 'JUL', value: values.INDIRETOS.JUL, typeRelease: releaseList[2], month: 6, disabled: currentMonth >= 7 },
      { name: 'INDIRETOS.AGO', label: 'AGO', value: values.INDIRETOS.AGO, typeRelease: releaseList[2], month: 7, disabled: currentMonth >= 8 },
      { name: 'INDIRETOS.SET', label: 'SET', value: values.INDIRETOS.SET, typeRelease: releaseList[2], month: 8, disabled: currentMonth >= 9 },
      { name: 'INDIRETOS.OUT', label: 'OUT', value: values.INDIRETOS.OUT, typeRelease: releaseList[2], month: 9, disabled: currentMonth >= 10 },
      { name: 'INDIRETOS.NOV', label: 'NOV', value: values.INDIRETOS.NOV, typeRelease: releaseList[2], month: 10, disabled: currentMonth >= 11 },
      { name: 'INDIRETOS.DEZ', label: 'DEZ', value: values.INDIRETOS.DEZ, typeRelease: releaseList[2], month: 11, disabled: currentMonth >= 12 },

      { name: 'REVENDA.JAN', label: 'JAN', value: values.REVENDA.JAN, typeRelease: releaseList[3], month: 0, disabled: currentMonth >= 1 },
      { name: 'REVENDA.FEV', label: 'FEV', value: values.REVENDA.FEV, typeRelease: releaseList[3], month: 1, disabled: currentMonth >= 2 },
      { name: 'REVENDA.MAR', label: 'MAR', value: values.REVENDA.MAR, typeRelease: releaseList[3], month: 2, disabled: currentMonth >= 3 },
      { name: 'REVENDA.ABR', label: 'ABR', value: values.REVENDA.ABR, typeRelease: releaseList[3], month: 3, disabled: currentMonth >= 4 },
      { name: 'REVENDA.MAI', label: 'MAI', value: values.REVENDA.MAI, typeRelease: releaseList[3], month: 4, disabled: currentMonth >= 5 },
      { name: 'REVENDA.JUN', label: 'JUN', value: values.REVENDA.JUN, typeRelease: releaseList[3], month: 5, disabled: currentMonth >= 6 },
      { name: 'REVENDA.JUL', label: 'JUL', value: values.REVENDA.JUL, typeRelease: releaseList[3], month: 6, disabled: currentMonth >= 7 },
      { name: 'REVENDA.AGO', label: 'AGO', value: values.REVENDA.AGO, typeRelease: releaseList[3], month: 7, disabled: currentMonth >= 8 },
      { name: 'REVENDA.SET', label: 'SET', value: values.REVENDA.SET, typeRelease: releaseList[3], month: 8, disabled: currentMonth >= 9 },
      { name: 'REVENDA.OUT', label: 'OUT', value: values.REVENDA.OUT, typeRelease: releaseList[3], month: 9, disabled: currentMonth >= 10 },
      { name: 'REVENDA.NOV', label: 'NOV', value: values.REVENDA.NOV, typeRelease: releaseList[3], month: 10, disabled: currentMonth >= 11 },
      { name: 'REVENDA.DEZ', label: 'DEZ', value: values.REVENDA.DEZ, typeRelease: releaseList[3], month: 11, disabled: currentMonth >= 12 },

      { name: 'SERVICO_AVANCO_FISICO.JAN', label: 'JAN', value: values.SERVICO_AVANCO_FISICO.JAN, typeRelease: releaseList[4], month: 0, disabled: currentMonth >= 1 },
      { name: 'SERVICO_AVANCO_FISICO.FEV', label: 'FEV', value: values.SERVICO_AVANCO_FISICO.FEV, typeRelease: releaseList[4], month: 1, disabled: currentMonth >= 2 },
      { name: 'SERVICO_AVANCO_FISICO.MAR', label: 'MAR', value: values.SERVICO_AVANCO_FISICO.MAR, typeRelease: releaseList[4], month: 2, disabled: currentMonth >= 3 },
      { name: 'SERVICO_AVANCO_FISICO.ABR', label: 'ABR', value: values.SERVICO_AVANCO_FISICO.ABR, typeRelease: releaseList[4], month: 3, disabled: currentMonth >= 4 },
      { name: 'SERVICO_AVANCO_FISICO.MAI', label: 'MAI', value: values.SERVICO_AVANCO_FISICO.MAI, typeRelease: releaseList[4], month: 4, disabled: currentMonth >= 5 },
      { name: 'SERVICO_AVANCO_FISICO.JUN', label: 'JUN', value: values.SERVICO_AVANCO_FISICO.JUN, typeRelease: releaseList[4], month: 5, disabled: currentMonth >= 6 },
      { name: 'SERVICO_AVANCO_FISICO.JUL', label: 'JUL', value: values.SERVICO_AVANCO_FISICO.JUL, typeRelease: releaseList[4], month: 6, disabled: currentMonth >= 7 },
      { name: 'SERVICO_AVANCO_FISICO.AGO', label: 'AGO', value: values.SERVICO_AVANCO_FISICO.AGO, typeRelease: releaseList[4], month: 7, disabled: currentMonth >= 8 },
      { name: 'SERVICO_AVANCO_FISICO.SET', label: 'SET', value: values.SERVICO_AVANCO_FISICO.SET, typeRelease: releaseList[4], month: 8, disabled: currentMonth >= 9 },
      { name: 'SERVICO_AVANCO_FISICO.OUT', label: 'OUT', value: values.SERVICO_AVANCO_FISICO.OUT, typeRelease: releaseList[4], month: 9, disabled: currentMonth >= 10 },
      { name: 'SERVICO_AVANCO_FISICO.NOV', label: 'NOV', value: values.SERVICO_AVANCO_FISICO.NOV, typeRelease: releaseList[4], month: 10, disabled: currentMonth >= 11 },
      { name: 'SERVICO_AVANCO_FISICO.DEZ', label: 'DEZ', value: values.SERVICO_AVANCO_FISICO.DEZ, typeRelease: releaseList[4], month: 11, disabled: currentMonth >= 12 },

      { name: 'EXTRA_RECEITAS_RECEB.JAN', label: 'JAN', value: values.EXTRA_RECEITAS_RECEB.JAN, typeRelease: releaseList[5], month: 0, disabled: currentMonth >= 1 },
      { name: 'EXTRA_RECEITAS_RECEB.FEV', label: 'FEV', value: values.EXTRA_RECEITAS_RECEB.FEV, typeRelease: releaseList[5], month: 1, disabled: currentMonth >= 2 },
      { name: 'EXTRA_RECEITAS_RECEB.MAR', label: 'MAR', value: values.EXTRA_RECEITAS_RECEB.MAR, typeRelease: releaseList[5], month: 2, disabled: currentMonth >= 3 },
      { name: 'EXTRA_RECEITAS_RECEB.ABR', label: 'ABR', value: values.EXTRA_RECEITAS_RECEB.ABR, typeRelease: releaseList[5], month: 3, disabled: currentMonth >= 4 },
      { name: 'EXTRA_RECEITAS_RECEB.MAI', label: 'MAI', value: values.EXTRA_RECEITAS_RECEB.MAI, typeRelease: releaseList[5], month: 4, disabled: currentMonth >= 5 },
      { name: 'EXTRA_RECEITAS_RECEB.JUN', label: 'JUN', value: values.EXTRA_RECEITAS_RECEB.JUN, typeRelease: releaseList[5], month: 5, disabled: currentMonth >= 6 },
      { name: 'EXTRA_RECEITAS_RECEB.JUL', label: 'JUL', value: values.EXTRA_RECEITAS_RECEB.JUL, typeRelease: releaseList[5], month: 6, disabled: currentMonth >= 7 },
      { name: 'EXTRA_RECEITAS_RECEB.AGO', label: 'AGO', value: values.EXTRA_RECEITAS_RECEB.AGO, typeRelease: releaseList[5], month: 7, disabled: currentMonth >= 8 },
      { name: 'EXTRA_RECEITAS_RECEB.SET', label: 'SET', value: values.EXTRA_RECEITAS_RECEB.SET, typeRelease: releaseList[5], month: 8, disabled: currentMonth >= 9 },
      { name: 'EXTRA_RECEITAS_RECEB.OUT', label: 'OUT', value: values.EXTRA_RECEITAS_RECEB.OUT, typeRelease: releaseList[5], month: 9, disabled: currentMonth >= 10 },
      { name: 'EXTRA_RECEITAS_RECEB.NOV', label: 'NOV', value: values.EXTRA_RECEITAS_RECEB.NOV, typeRelease: releaseList[5], month: 10, disabled: currentMonth >= 11 },
      { name: 'EXTRA_RECEITAS_RECEB.DEZ', label: 'DEZ', value: values.EXTRA_RECEITAS_RECEB.DEZ, typeRelease: releaseList[5], month: 11, disabled: currentMonth >= 12 },

      { name: 'OUTRAS_RECEITAS_RECEB.JAN', label: 'JAN', value: values.OUTRAS_RECEITAS_RECEB.JAN, typeRelease: releaseList[6], month: 0, disabled: currentMonth >= 1 },
      { name: 'OUTRAS_RECEITAS_RECEB.FEV', label: 'FEV', value: values.OUTRAS_RECEITAS_RECEB.FEV, typeRelease: releaseList[6], month: 1, disabled: currentMonth >= 2 },
      { name: 'OUTRAS_RECEITAS_RECEB.MAR', label: 'MAR', value: values.OUTRAS_RECEITAS_RECEB.MAR, typeRelease: releaseList[6], month: 2, disabled: currentMonth >= 3 },
      { name: 'OUTRAS_RECEITAS_RECEB.ABR', label: 'ABR', value: values.OUTRAS_RECEITAS_RECEB.ABR, typeRelease: releaseList[6], month: 3, disabled: currentMonth >= 4 },
      { name: 'OUTRAS_RECEITAS_RECEB.MAI', label: 'MAI', value: values.OUTRAS_RECEITAS_RECEB.MAI, typeRelease: releaseList[6], month: 4, disabled: currentMonth >= 5 },
      { name: 'OUTRAS_RECEITAS_RECEB.JUN', label: 'JUN', value: values.OUTRAS_RECEITAS_RECEB.JUN, typeRelease: releaseList[6], month: 5, disabled: currentMonth >= 6 },
      { name: 'OUTRAS_RECEITAS_RECEB.JUL', label: 'JUL', value: values.OUTRAS_RECEITAS_RECEB.JUL, typeRelease: releaseList[6], month: 6, disabled: currentMonth >= 7 },
      { name: 'OUTRAS_RECEITAS_RECEB.AGO', label: 'AGO', value: values.OUTRAS_RECEITAS_RECEB.AGO, typeRelease: releaseList[6], month: 7, disabled: currentMonth >= 8 },
      { name: 'OUTRAS_RECEITAS_RECEB.SET', label: 'SET', value: values.OUTRAS_RECEITAS_RECEB.SET, typeRelease: releaseList[6], month: 8, disabled: currentMonth >= 9 },
      { name: 'OUTRAS_RECEITAS_RECEB.OUT', label: 'OUT', value: values.OUTRAS_RECEITAS_RECEB.OUT, typeRelease: releaseList[6], month: 9, disabled: currentMonth >= 10 },
      { name: 'OUTRAS_RECEITAS_RECEB.NOV', label: 'NOV', value: values.OUTRAS_RECEITAS_RECEB.NOV, typeRelease: releaseList[6], month: 10, disabled: currentMonth >= 11 },
      { name: 'OUTRAS_RECEITAS_RECEB.DEZ', label: 'DEZ', value: values.OUTRAS_RECEITAS_RECEB.DEZ, typeRelease: releaseList[6], month: 11, disabled: currentMonth >= 12 },

    );


    return list;
  };

  const calcProvider = (values: any, month: any, setFieldValue: any) => {
    let marginReal = 0;
    let marginPorc = 0;

    switch (month) {
      case 0:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JAN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JAN;
        break;
      case 1:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.FEV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.FEV;
        break;
      case 2:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAR;
        break;
      case 3:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.ABR;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.ABR;
        break;
      case 4:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.MAI;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.MAI;
        break;
      case 5:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUN;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUN;
        break;
      case 6:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.JUL;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.JUL;
        break;
      case 7:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.AGO;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.AGO;
        break;
      case 8:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.SET;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.SET;
        break;
      case 9:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.OUT;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.OUT;
        break;
      case 10:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.NOV;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.NOV;
        break;
      case 11:
        marginReal = returnCalcMarginsAndTaxOfLS_Provider().REAL.DEZ;
        marginPorc = returnCalcMarginsAndTaxOfLS_Provider().PORC.DEZ;
        break;
    }

    props.marginReal({ month: month, value: marginReal, releaseType: props.releaseType.COD_LANCAMENTO });
    props.marginPorc({ month: month, value: marginPorc, releaseType: props.releaseType.COD_LANCAMENTO });

    setFieldValue(`MARGEM_REAL.PREVISTO[${month}]`, marginReal);
    setFieldValue(`MARGEM_PORC.PREVISTO[${month}]`, marginPorc);
  };

  function saveOnLocalStoragePR(field: any, e: any) {
    const localData = JSON.parse(localStorage.getItem('@pff/LANCAMENTO/DATA'));

    localData[4].RRE.forEach((invoicing) => {
      if (invoicing.TIPO === field.typeRelease) {
        switch (field.label) {
          case 'JAN':
            invoicing.JAN = e;
            break;
          case 'FEV':
            invoicing.FEV = e;
            break;
          case 'MAR':
            invoicing.MAR = e;
            break;
          case 'ABR':
            invoicing.ABR = e;
            break;
          case 'MAI':
            invoicing.MAI = e;
            break;
          case 'JUN':
            invoicing.JUN = e;
            break;
          case 'JUL':
            invoicing.JUL = e;
            break;
          case 'AGO':
            invoicing.AGO = e;
            break;
          case 'SET':
            invoicing.SET = e;
            break;
          case 'OUT':
            invoicing.OUT = e;
            break;
          case 'NOV':
            invoicing.NOV = e;
            break;
          case 'DEZ':
            invoicing.DEZ = e;
            break;

          default:
            break;
        }
        localStorage.setItem('@pff/LANCAMENTO/DATA', JSON.stringify(localData));
      }
    });
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PffGridValidationSchema}
      initialValues={mountPffModel(PffReceitasRecebiveisModel)}
      onSubmit={(values) => {
        postDraftSave(values);
      }}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>
            <PffFilterContainerForm auto onSubmit={handleSubmit}>

              {/* LANÇAMENTO ORIGINAL */}
              {(props.releaseType?.COD_LANCAMENTO === 'OR') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsBudgeted(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label.substring(0, 22) + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value !== '') {
                          calcBudgeted(values, setFieldValue);
                          saveOnLocalStorageOR(field, maskToMoney(e.target.value));
                        }
                        else {
                          setFieldValue(field.name, '00');
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          calcBudgeted(values, setFieldValue);
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_RECEITAS_RECEB.ORCADO'}
                    label={'Total Receitas Receb. R$'}
                    placeholder={'Total Receitas Receb. R$'}
                    value={maskToMoney2(values.TOTAL_RECEITAS_RECEB.ORCADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}



              {/* LANÇAMENTO PLANEJADO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PL') && (

                <RowFieldsGrid xs={6}>

                  {listFieldsPlanned(values).map((field) => (

                    <TextInputLabelFloat
                      fontSize={Metrics.fontSizePFFSm}
                      fontSizeLabel={Metrics.fontSizePFFSm}
                      name={field.name}
                      label={field.label.substring(0, 22) + ' R$'}
                      value={field.value}
                      type={'text'}
                      onChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange(e);
                          setFieldValue(field.name, maskToMoney(e.target.value));
                          saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                        }
                      }}

                      onBlur={(e) => {
                        handleBlur(e);
                        if (e.target.value !== '') {
                          calcPlanned(values, setFieldValue);
                          saveOnLocalStoragePL(field, maskToMoney(e.target.value));
                        }
                        else {
                          setFieldValue(field.name, '00');
                        }
                      }}

                      onFocus={(e) => {
                        e.target.select();
                      }}

                      onKeyUp={(e) => {
                        if (e.key === 'Enter')
                          calcPlanned(values, setFieldValue);
                      }}
                    />
                  ))}


                  <TextInputLabelFloat
                    fontSize={Metrics.fontSizePFFSm}
                    fontSizeLabel={Metrics.fontSizePFFSm}
                    disabled
                    name={'TOTAL_RECEITAS_RECEB.PLANEJADO'}
                    label={'Total Receitas Receb. R$'}
                    placeholder={'Total Receitas Receb. R$'}
                    value={maskToMoney2(values.TOTAL_RECEITAS_RECEB.PLANEJADO)}
                    type={'text'}
                  />


                </RowFieldsGrid>

              )}



              {/* LANÇAMENTO PREVISTO */}
              {(props.releaseType?.COD_LANCAMENTO === 'PR') && (

                releaseList.map((typeRelease) => (
                  <>

                    <TitleInterGroup titleAlign={'rigth'}>
                      <h1>{typeRelease}</h1>
                    </TitleInterGroup>


                    <ColFieldsGrid xs={12}>

                      {listFieldsProvider(values).map((field) => (

                        typeRelease === field.typeRelease && (

                          <TextInputLabelFloat
                            fontSize={Metrics.fontSizePFFSm}
                            fontSizeLabel={Metrics.fontSizePFFSm}
                            disabled={field.disabled}
                            name={field.name}
                            id={field.name}
                            label={field.label + ' R$'}
                            value={field.value}
                            type={'text'}
                            onChange={(e: any) => {
                              if (e.target.value !== '') {
                                handleChange(e);
                                setFieldValue(field.name, maskToMoney(e.target.value));
                                saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                              }
                            }}

                            onBlur={(e) => {
                              handleBlur(e);
                              if (e.target.value !== '') {
                                saveOnLocalStoragePR(field, maskToMoney(e.target.value));
                                calcProvider(values, field.month, setFieldValue);
                              }
                              else {
                                setFieldValue(field.name, '00');
                              }
                            }}

                            onFocus={(e) => {
                              e.target.select();
                            }}

                            onKeyUp={(e) => {
                              if (e.key === 'Enter')
                                calcProvider(values, field.month, setFieldValue);
                            }}
                          />
                        )

                      ))}

                    </ColFieldsGrid>

                  </>
                ))
              )}

            </PffFilterContainerForm>

          </SectionMargin>

        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_ReceitasRecebiveisScreen;
