import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalSecondStyled,
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import ApiWS from '../../../../../services/api.service';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
}



const CotacaoPcbModal: React.FC<IProps> = (props) => {
  const [carregando, setLoading] = useState(false);

  const [cotacao, setCotacao] = useState([]);

  const fetchCotacao = async () => {
    setLoading(true);

    const consult = props.values;

    ApiWS()
      .post('/suprimento/consulta/pedido/cotacao', consult)
      .then((resp) => {
        setCotacao(resp.data);
      })
      .catch((error) => {
        console.error('findCotacao', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCotacao();
  }, []);


  return (

    <ModalSecondStyled
      {...props}
      dialogClassName="modal-second-dialog modal-70"
      backdropClassName="modal-second-backdrop"
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Cotação'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}

          {!carregando && (!cotacao || cotacao === null || cotacao.length === 0) && (
            <h5>Sem Cotações</h5>
          )}


          {!carregando && cotacao && cotacao.length > 0 && (
            <TableDefaultStyled>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Produto</th>
                  <th>Fornecedor</th>
                  <th>UN</th>
                  <th>Qtd</th>
                  <th>Preço</th>
                  <th>Total</th>
                  <th>Prazo</th>
                  <th>Cond. Pag</th>
                </tr>
              </thead>

              <tbody>
                {cotacao.map((cot, key) => (
                  <tr key={key}>
                    <td>{cot.ITEM}</td>
                    <td>{cot.PRODUTO.replaceAll(' ', '')}</td>
                    <td>{cot.FORNEC.replaceAll(' ', '')}</td>
                    <td>{cot.UN}</td>
                    <td>{cot.QUANT}</td>
                    <td>{convertCurrencyBRL(cot.PRECO)}</td>
                    <td>{convertCurrencyBRL(cot.TOTAL)}</td>
                    <td>{cot.PRAZO}</td>
                    <td>{cot.CONDPG}</td>
                  </tr>
                ))}
              </tbody>
            </TableDefaultStyled>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </ModalSecondStyled>

  );
};



export default CotacaoPcbModal;
