import * as Yup from 'yup';
import { Messages, Metrics } from '../../../../../common/constants';
import { numberOnlyAccept } from '../../../../../utils/StringUtils';



const SmeAtendimentoValidationSchema = Yup.object().shape({

  select_os: Yup.string().trim()
    .required('Campo obrigatório'),



  select_situacao: Yup.string()
    .required('Campo obrigatório'),



  select_grp_triagem: Yup.string()
    .required('Campo obrigatório'),



  select_grp: Yup.string()
    .required('Campo obrigatório'),



  input_op: Yup.string()
    // .length(Metrics.input_op, Messages.input_op)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_de: Yup.string(),



  input_ate: Yup.string(),



  input_num_sm: Yup.string()
    // .length(Metrics.input_sme, Messages.input_sme)
    .test('', Messages.input_numberOnlyInteger, (value) => numberOnlyAccept(value)),



  input_descricao: Yup.string(),



  input_emitente: Yup.string()
    .max(Metrics.input_solicitante, Messages.input_solicitante),

});



export default SmeAtendimentoValidationSchema;
