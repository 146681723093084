import * as Yup from 'yup';



const PStockQrValidationSchema = Yup.object().shape({

  input_qr: Yup.string()
    .required('Campo obrigatório'),

});



export default PStockQrValidationSchema;
