import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  Screen,
  SectionMargin,
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import Grafico from '../../../../../components/Charts';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS from '../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';



const SME_MonitorScreen: React.FC = () => {
  const [carregando, setCarregando] = useState(false);
  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s aqui!',
    description: 'Não há nenhuma SME para você',
  });

  const [listData, setListData] = useState([]);

  const [listCompradoresGraf, setListCompradoresGraf] = useState([['Triagem de', 'Qtd']]);
  const [listTriagensGraf, setListTriagensGraf] = useState([['Triagem de', 'Qtd']]);
  const [listAlmoxarifadosGraf, setListAlmoxarifadosGraf] = useState([['Triagem de', 'Qtd']]);

  const [listCompradores, setListCompradores] = useState([]);
  const [listTriagens, setListTriagens] = useState([]);
  const [listAlmoxarifados, setListAlmoxarifados] = useState([]);
  const [listTotal, setListTotal] = useState([]);



  async function findData() {
    try {
      setCarregando(true);

      const url = '/sm/monitor';
      const response = await ApiWS().get(url);
      setListData(response.data);
    }
    catch (error) {
      console.error('findData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };



  useEffect(() => {
    findData();
  }, []);


  useEffect(() => {
    listData.forEach((data) => {
      const grupoCompleto = String(data.ZU_DESGRTR);
      const grupo = grupoCompleto.substring(0, grupoCompleto.indexOf(' '));

      switch (grupo) {
        case '.EM':
          setListAlmoxarifados((listAlmoxarifados) => [...listAlmoxarifados, data]);
          setListAlmoxarifadosGraf((listAlmoxarifadosGraf) =>
            [...listAlmoxarifadosGraf, [data.ZU_DESGRTR, data.URGENTE]],
          );
          break;
        case '.COMPRAS':
          setListCompradores((listCompradores) => [...listCompradores, data]);
          setListCompradoresGraf((listCompradoresGraf) =>
            [...listCompradoresGraf, [data.ZU_DESGRTR, data.URGENTE]],
          );
          break;
        case '.TRIAGEM':
          setListTriagens((listTriagens) => [...listTriagens, data]);
          setListTriagensGraf((listTriagensGraf) =>
            [...listTriagensGraf, [data.ZU_DESGRTR, data.URGENTE]],
          );
          break;
        default: break;
      }
      if (data.ZU_DESGRTR === 'TOTAL GERAL') {
        setListTotal((listTotal) => [...listTotal, data]);
      }
    });
  }, [listData]);



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={6}
          navArray={SME_NavRoutes}
          title={'SME'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && listData.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}


          {!carregando && listData.length > 0 && (
            <ContainerView>

              {/* Dashboard */}
              <SectionMargin>

                <TitleGroup>
                  {'Indicadores'.toUpperCase()}
                </TitleGroup>


                <div className="row">
                  <div className="col-md-4">
                    <div className="bg-white border rounded-3">
                      <h5 className="text-center">Compradores</h5>
                      <p className="text-center">{'Itens SME\'s Urgência'}</p>
                      <Grafico
                        dado={listCompradoresGraf}
                      />
                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="bg-white border rounded-3">
                      <h5 className="text-center">Grupos de Triagem</h5>
                      <p className="text-center">{'Itens SME\'s Urgência'}</p>
                      <Grafico
                        dado={listTriagensGraf}
                      />
                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="bg-white border rounded-3">
                      <h5 className="text-center">Em Recebimento Almx.</h5>
                      <p className="text-center">{'Itens SME\'s Urgência'}</p>
                      <Grafico
                        dado={listAlmoxarifadosGraf}
                      />
                    </div>
                  </div>
                </div>

              </SectionMargin>



              {/* Tabela Compradores */}
              <SectionMargin>

                <TitleGroup>
                  {'Compradores'.toUpperCase()}
                </TitleGroup>


                <TableDefaultStyled>
                  <thead>
                    <tr>
                      <th>Grupo</th>
                      <th>{'Qtd. Total Itens SME\'s'}</th>
                      <th>{'Itens SME\'s Urgência'}</th>
                      <th>{'Itens SME Dentro do Prazo'}</th>
                      <th>{'Itens SME <= 5 dias'}</th>
                      <th>{'Itens SME > 5 dias'}</th>
                      <th>{'Itens SME > 10 dias'}</th>
                      <th>{'Itens SME > 15 dias'}</th>
                    </tr>
                  </thead>


                  <tbody>
                    {listCompradores.map((comp) => (
                      <tr>
                        <td className="col-md-3">{comp.ZU_DESGRTR}</td>
                        <td>{comp.TOTAL}</td>
                        <td>{comp.URGENTE}</td>
                        <td>{comp.DENTRO_PRAZO}</td>
                        <td>{comp.DIAS5}</td>
                        <td>{comp.DIAS10}</td>
                        <td>{comp.DIAS15}</td>
                        <td>{comp.DIAS4}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableDefaultStyled>
              </SectionMargin>



              {/* Tabela Grupo Triagem */}
              <SectionMargin>

                <TitleGroup>
                  {'Grupo Triagens'.toUpperCase()}
                </TitleGroup>


                <TableDefaultStyled>
                  <thead>
                    <tr>
                      <th>Grupo</th>
                      <th>{'Qtd. Total Itens SME\'s'}</th>
                      <th>{'Itens SME\'s Urgência'}</th>
                      <th>{'Itens SME Dentro do Prazo'}</th>
                      <th>{'Itens SME <= 5 dias'}</th>
                      <th>{'Itens SME > 5 dias'}</th>
                      <th>{'Itens SME > 10 dias'}</th>
                      <th>{'Itens SME > 15 dias'}</th>
                    </tr>
                  </thead>


                  <tbody>
                    {listTriagens.map((triagem) => (
                      <tr>
                        <td className="col-md-3">{triagem.ZU_DESGRTR}</td>
                        <td>{triagem.TOTAL}</td>
                        <td>{triagem.URGENTE}</td>
                        <td>{triagem.DENTRO_PRAZO}</td>
                        <td>{triagem.DIAS5}</td>
                        <td>{triagem.DIAS10}</td>
                        <td>{triagem.DIAS15}</td>
                        <td>{triagem.DIAS4}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableDefaultStyled>
              </SectionMargin>



              {/* Tabela em Recebimento Almox */}
              <SectionMargin>

                <TitleGroup>
                  {'Grupo Em Recebimento Almox'.toUpperCase()}
                </TitleGroup>


                <TableDefaultStyled>
                  <thead>
                    <tr>
                      <th>Grupo</th>
                      <th>{'Qtd. Total Itens SME\'s'}</th>
                      <th>{'Itens SME\'s Urgência'}</th>
                      <th>{'Itens SME Dentro do Prazo'}</th>
                      <th>{'Itens SME <= 5 dias'}</th>
                      <th>{'Itens SME > 5 dias'}</th>
                      <th>{'Itens SME > 10 dias'}</th>
                      <th>{'Itens SME > 15 dias'}</th>
                    </tr>
                  </thead>


                  <tbody>
                    {listAlmoxarifados.map((almox) => (
                      <tr>
                        <td className="col-md-3">{almox.ZU_DESGRTR}</td>
                        <td>{almox.TOTAL}</td>
                        <td>{almox.URGENTE}</td>
                        <td>{almox.DENTRO_PRAZO}</td>
                        <td>{almox.DIAS5}</td>
                        <td>{almox.DIAS10}</td>
                        <td>{almox.DIAS15}</td>
                        <td>{almox.DIAS4}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableDefaultStyled>
              </SectionMargin>



              {/* Tabela Total */}
              <SectionMargin>

                <TitleGroup>
                  {'Total Geral'.toUpperCase()}
                </TitleGroup>


                <TableDefaultStyled>
                  <thead className="thead-two">
                    <tr>
                      <th></th>
                      <th>{'Qtd. Total Itens SME\'s'}</th>
                      <th>{'Itens SME\'s Urgência'}</th>
                      <th>{'Itens SME Dentro do Prazo'}</th>
                      <th>{'Itens SME <= 5 dias'}</th>
                      <th>{'Itens SME > 5 dias'}</th>
                      <th>{'Itens SME > 10 dias'}</th>
                      <th>{'Itens SME > 15 dias'}</th>
                    </tr>
                  </thead>


                  <tbody className="tbody-two">
                    {listTotal.map((total) => (
                      <tr>
                        <td className="col-md-3"><b>{total.ZU_DESGRTR}</b></td>
                        <td>{total.TOTAL}</td>
                        <td>{total.URGENTE}</td>
                        <td>{total.DENTRO_PRAZO}</td>
                        <td>{total.DIAS4}</td>
                        <td>{total.DIAS5}</td>
                        <td>{total.DIAS10}</td>
                        <td>{total.DIAS15}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableDefaultStyled>
              </SectionMargin>

            </ContainerView>
          )}

        </ContentStyled>
      </Content>

    </Screen >

  );
};



export default SME_MonitorScreen;
