import styled from 'styled-components';

import {
  Colors,
} from '../../common/constants';



export const EmptyContentView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
`;

export const EmptyImage = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

export const EmptyTitle = styled.h1`
  margin-top: 30px;
  margin-bottom: 10px;
  color: ${Colors?.black};
  font-size: 1.875rem !important;
  text-align: center;
`;

export const EmptyDescription = styled.p`
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  color: ${Colors?.gray};
  font-size: 1.125rem !important;
  font-weight: 100;
  text-align: center;
`;
