import styled from 'styled-components';
import { Button, Row } from 'react-bootstrap';
import { Colors } from '../../common/constants';
import { TitleGroup } from '../../common/styles/styled.layout';

interface IProps {
  color?: any;
}

export const AreaButton = styled(Row)`
  text-align: center;
  justify-content: center;
`;

export const TitleGroupHelp = styled(TitleGroup)`
  color: ${(props: IProps) => props.color};
  border-bottom: 1px solid ${(props: IProps) => props.color};
  margin: 15px auto;
`;

export const ButtonHelpStyled = styled(Button)`
  background-color: ${(props: IProps) => props.color};
  border: none;
  border-radius: 0;
  border-top: 10px;
  width: 150px;
  box-shadow: -3px 4px 6px 0px grey;
  margin: 0 10px;

  :hover,
  :active,
  :focus {
    background-color: ${(props: IProps) => props.color || 'gray'}D0;
    box-shadow: -3px 4px 6px 0px grey;
  }

  :disabled {
    color: #fff;
    background-color: ${(props: IProps) => props.color || 'gray'}D0;
    pointer-events: none;
    opacity: .65;
  }
`;

export const TextButtonStyled = styled.p`
  margin: 1px;
`;

export const FooterHelpStyled = styled(Row)`
  border-top: 1px solid ${Colors?.primary};
  background-color: ${Colors?.primary};
  color: ${Colors?.white};
  margin: 25px -10px;
  text-align: center;
  justify-content: center;
`;


