import React from 'react';

// import { Container } from './styles';



const Tab_ExternoScreen: React.FC = () => {
  return <div />;
};



export default Tab_ExternoScreen;
