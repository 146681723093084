import React, { useEffect, useState } from 'react';
import { RawData, RawDataRealizado } from '../../../../../assets/raw/PFF_Lancamento';

import {
  Content, Screen,
} from '../../../../../common/styles/styled.layout';

import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import { PFF_NavRoutes } from '../../../../../navigation/navs/Corporate';
import PFF_GridScreen from './PFF_Lancamento_Grid';
import PFF_InicioScreen from './PFF_Lancamento_Inicio';
import PFF_RealizadoScreen from './PFF_Lancamento_Realizado';

// import { Container } from './styles';


const PFF_OrcadoScreen: React.FC = () => {
  const [loading] = useState(false);
  /* FIXME: Comentar linha 23 e descomentar linha 24 para subir homolo/prod */
  // const [releaseType, setReleaseType] = useState({ COD_LANCAMENTO: 'RE', LANCAMENTO: 'REALIZADO' });
  const [releaseType, setReleaseType] = useState({ COD_LANCAMENTO: 'ALL', LANCAMENTO: 'REALIZADO' });


  useEffect(() => {
    localStorage
      .setItem(
        '@pff/LANCAMENTO/MARGEM_REAL',
        JSON.stringify([
        // J  F  M  A  M  J  J  A  S  O  N  D <- Meses | Lançamentos ! J pode ser Orçado ou Planejado tbm
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Faturamento  
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Receit. Recebiv.
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Receit. Controle 
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Impost. Diretos
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Custos
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Hh MOD
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Hh MOI
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Hh Terceiros
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Avanço
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //       Adiantamentos
        ]),
      );

    localStorage
      .setItem(
        '@pff/LANCAMENTO/MARGEM_PORC',
        JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
      );

    localStorage.setItem('@pff/LANCAMENTO/DATA', '');
    localStorage.setItem('@pff/LANCAMENTO/DATA/REALIZADO', '');
    localStorage.setItem('@pff/LANCAMENTO/FLAG/REALIZADO', '0');
    localStorage.setItem('@pff/LANCAMENTO/FLAG/PENDENCIA', '-1');

    if(releaseType.COD_LANCAMENTO === 'SOJESUSNACAUSA'){
      localStorage.setItem(RawData.key, RawData.value);
      localStorage.setItem(RawDataRealizado.key, RawDataRealizado.value);
    }
  }, []);



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={PFF_NavRoutes}
          title={'PFF'}
        />
      </NavHeader>

      <Content>


        {loading &&
          <LoadingsComponent />}


        {!loading &&

          {
            'ALL': <PFF_InicioScreen releaseType={(release) => setReleaseType(release)} />,
            'OR': <PFF_GridScreen releaseType={releaseType} />,
            'PL': <PFF_GridScreen releaseType={releaseType} />,
            'PR': <PFF_GridScreen releaseType={releaseType} />,
            'RE': <PFF_RealizadoScreen releaseType={releaseType}/>,
            'SOJESUSNACAUSA': <PFF_RealizadoScreen releaseType={{ COD_LANCAMENTO: 'RE', LANCAMENTO: 'REALIZADO' }}/>,
          }[releaseType?.COD_LANCAMENTO]

        }


      </Content>

    </Screen >

  );
};



export default PFF_OrcadoScreen;
