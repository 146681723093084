import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Colors, Images } from '../../../../../../common/constants';
import { ContentStyled, FilterContainerForm } from '../../../../../../common/styles/styled.layout';
import IButton from '../../../../../../components/Buttons/IButton';
import { IconHardHappy as IconHappy, IconNext, IconSearch } from '../../../../../../components/Icons';
import IPhoto from '../../../../../../components/IPhoto';
import TextInput from '../../../../../../components/TextFields/Input';
import EmployeeModal from '../../Modals/ColaboradorModal';
import { LabelStyled, LabelFlexText, LabelFlexTitle, RowStyled, ColDataEmployee, ColPhotoEmployee, ColQtdStar, AreaTextQtdStar, FooterRowButton } from '../PAV-Reconhecimento.styled';
import { TitleGroupPAV } from './styled';

interface IProps {
  values?: any;
  handleSubmit?: any;
  handleBlur?: any;
  handleChange?: any;
  setFieldValue?: any;
  setCurrentTab?: any;
}

const Tab_Colaborador: React.FC<IProps> = ({ values, handleBlur, handleChange, setFieldValue, setCurrentTab }) => {
  const [showModalEmployee, setShowModalEmployee] = useState(false);


  return (
    <>
      <FilterContainerForm auto >

        <ContentStyled fluid>

          <Container>

            <TitleGroupPAV>
              {'Selecione o colaborador para enviar o reconhecimento.'.toUpperCase()}
            </TitleGroupPAV>

            <RowStyled>

              <ColPhotoEmployee>

                <IPhoto alt={values?.NOME || 'Imagem Vazia'} src={values?.FOTO || (!values?.NOME && Images.backgroundAvatar)} size={'md'} />

              </ColPhotoEmployee>



              <ColDataEmployee>

                <TextInput
                  editable={false}
                  type={'text'}
                  name={'NOME'}
                  label={'Colaborador'}
                  placeholder={'Buscar...'}
                  buttonIcon={
                    <IconSearch
                      color={'black'}
                      size={20}
                    />
                  }
                  buttonClick={() => setShowModalEmployee(true)}
                  value={values?.NOME}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />


                <LabelStyled>
                  <LabelFlexTitle> Cargo: <LabelFlexText> {values?.CARGO[1]} </LabelFlexText> </LabelFlexTitle>
                </LabelStyled>


                <LabelStyled>
                  <LabelFlexTitle> OS: <LabelFlexText> {values?.OS} </LabelFlexText> </LabelFlexTitle>
                </LabelStyled>


                <LabelStyled>
                  <LabelFlexTitle> Setor: <LabelFlexText> {values?.SETOR} </LabelFlexText> </LabelFlexTitle>
                </LabelStyled>


                <LabelStyled>
                  <LabelFlexTitle> Matricula: <LabelFlexText> {values?.MAT} </LabelFlexText> </LabelFlexTitle>
                </LabelStyled>


                <LabelStyled>
                  <LabelFlexTitle> Email: <LabelFlexText> {values?.EMAIL} </LabelFlexText> </LabelFlexTitle>
                </LabelStyled>

              </ColDataEmployee>



              <ColQtdStar>

                <AreaTextQtdStar>
                  <p><IconHappy color={Colors?.primary} /></p>

                  <p>Você possui estrelas para enviar.</p>

                </AreaTextQtdStar>

              </ColQtdStar>

            </RowStyled>


            <FooterRowButton>
              <Col></Col>
              <Col></Col>
              <Col>
                <IButton
                  disabled={!values.NOME}
                  type={'button'}
                  backgroundColor={Colors?.primary}
                  text={'Próximo'}
                  iconRigth={
                    <IconNext />
                  }
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                />
              </Col>

            </FooterRowButton>

          </Container>

        </ContentStyled>

      </FilterContainerForm>



      {showModalEmployee && (
        <EmployeeModal
          show={showModalEmployee}
          data={(result) => {
            setFieldValue('NOME', result?.NOME);
            setFieldValue('CARGO', result?.CARGO);
            setFieldValue('SETOR', result?.SETOR);
            setFieldValue('OS', result?.OS);
            setFieldValue('MAT', result?.MAT);
            setFieldValue('EMAIL', result?.EMAIL);
            setFieldValue('FOTO', result?.FOTO);
          }}
          onHide={() => setShowModalEmployee(false)}
        />
      )}
    </>
  );
};

export default Tab_Colaborador;
