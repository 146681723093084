import NameRoutes from '../../../../../../navigation/name';

const businessArray =[
  {
    key: 1,
    label: 'Adm. Neg. Serviços Especializados',
    value: 'Adm. Neg. Serviços Especializados',
  },
  {
    key: 2,
    label: 'Caldeiraria',
    value: 'Caldeiraria',
  },
  {
    key: 3,
    label: 'Corporativo',
    value: 'Corporativo',
  },
  {
    key: 4,
    label: 'Despesas Financeiras',
    value: 'Despesas Financeiras',
  },
  {
    key: 5,
    label: 'Fabricas',
    value: 'Fabricas',
  },
  {
    key: 6,
    label: 'Fabrica BA',
    value: 'Fabrica BA',
  },
  {
    key: 7,
    label: 'Fabrica PE',
    value: 'Fabrica PE',
  },
  {
    key: 8,
    label: 'Manutenção',
    value: 'Manutenção',
  },
  {
    key: 9,
    label: 'Obras',
    value: 'Obras',
  },
  {
    key: 10,
    label: 'Parada',
    value: 'Parada',
  },
  {
    key: 11,
    label: 'Serviço Especializado',
    value: 'Serviço Especializado',
  },
];

const releaseTypeList = [
  {
    header: 'Orçado',
  },
  {
    cod: 'OR',
    label: 'Orçado Original',
    path: `/${NameRoutes.PFF_Orcado_Original}`,
  },
  {
    cod: 'PL',
    label: 'Orçado Executivo',
    path: `/${NameRoutes.PFF_Orcado_Planejado}`,
  },
  {
    div: true,
  },


  {
    header: 'Futuro',
  },
  {
    cod: 'PR',
    label: 'Previsto',
    path: `/${NameRoutes.PFF_Previsto}`,
  },
  {
    cod: 'IMP',
    label: 'Importação',
    path: '',
  },
  {
    div: true,
  },
  
  
  {
    header: 'Passado',
  },
  {
    cod: 'RE',
    label: 'Realizado',
    path: `/${NameRoutes.PFF_Lancamento_Realizado}`,
  },

];

export {
  businessArray as statusArray,
  releaseTypeList,
};
