import React,
{
  useState,
} from 'react';
import {
  Form,
  Modal,
} from 'react-bootstrap';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { SmeOrientacaoArray } from '../../../../../common/arrays/SmeSolicitacao';

import {
  Colors,
} from '../../../../../common/constants';

import {
  ObsSubTitleGroup,
  ObsTitleGroup,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  IconWarning,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';
import DropdownInput from '../../../../../components/TextFields/Dropdown';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  anexar?: boolean;
  buttonText?: string;
  onHide?: any;

  fetchNow?: any;

  sm?: any;
}



const SMEAlteraOrientacaoModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postChangeOrientation(values) {
    setLoading(true);

    const url = '/sm/triagem/alterarorientacao/';
    const payload = values;


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Salvo com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível salvar!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postChangeOrientation', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      // size={'md'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Alterar Orientação da SM'.toUpperCase()}
        </TitleGroup>

        {
          loading &&
          <LoadingsComponent fontSize={'12px'} size={'0.9rem'} />
        }


        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            FILIAL: props?.sm?.FILIAL,
            NUM: props?.sm?.NUM,
            ORIENTOLD: props?.sm?.ORIENTOLD,
            ORIENTNEW: props?.sm?.ORIENTNEW,
          }}
          validationSchema={
            Yup.object().shape({
              FILIAL: Yup.string().required('Obrigatório'),
              NUM: Yup.string().required('Obrigatório'),
              ORIENTOLD: Yup.string().required('Obrigatório'),
              ORIENTNEW: Yup.string()
                .required('Obrigatório')
                .notOneOf([Yup.ref('ORIENTOLD')], 'A orientação nova deve ser diferente da atual!'),
            })
          }
          onSubmit={(values) => {
            postChangeOrientation(values);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <>
                  <ObsTitleGroup>
                    <IconWarning size={35} />
                    <p>Atenção</p>
                  </ObsTitleGroup>

                  <ObsSubTitleGroup>
                    <p>Você está prestes a alterar a orientação de todos os itens da SM.</p>
                  </ObsSubTitleGroup>

                  <ObsTitleGroup>
                    {`Filial: ${props.sm.FILIAL} | SM: ${props.sm.NUM}`}
                  </ObsTitleGroup>


                  <DropdownInput
                    disabled
                    name={'ORIENTOLD'}
                    label={'Atual'}
                    placeholder={'Atual'}
                    dropArray={SmeOrientacaoArray}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ORIENTOLD}
                  />


                  <DropdownInput
                    disabled={loading}
                    name={'ORIENTNEW'}
                    label={'Nova'}
                    placeholder={'Selecione.'}
                    dropArray={SmeOrientacaoArray}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ORIENTNEW}
                  />

                </>


              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.gray}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Alterar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



SMEAlteraOrientacaoModal.defaultProps = {
  anexar: true,
  buttonText: 'Fechar',
};



export default SMEAlteraOrientacaoModal;
