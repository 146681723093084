import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { ModalBodyStyled } from '../../../../../components/Modals/styled';

interface IProps {
  margin?: any;
}

export const AreaEditPhoto = styled.div`
    color: rgba(0, 0, 0, 0.816);
    margin-left: 25px;
`;

export const LabelPhoto = styled.label`
  margin: ${((props: IProps) => props.margin)};
`;

export const InputFilePhoto = styled.input`
  display:none;
`;


export const GIModalBodyStyled = styled(ModalBodyStyled)`
  margin: 0%;
  padding: 2%;
  max-height: 90%;
`;

export const ColPhotoSelfStyled = styled(Col)`
  margin-left: 100px;
  margin-bottom: 10px;
`;

export const GIModalContainerStyled = styled.div`
  padding: 0 1rem 1.3rem;
`;


export const GIModalBodyStyled2 = styled.div`
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 1.5rem 0;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
`;
