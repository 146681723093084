import * as Yup from 'yup';


const IGercCustoGerencialValidationSchema = Yup.object().shape({

  input_data_inicial: Yup.string()
    .required('Campo obrigatório'),

  input_data_final: Yup.string()
    .required('Campo obrigatório'),

  input_un_neg: Yup.array()
    .min(1)
    .required(),

  input_os: Yup.array()
    .min(1)
    .required(),

});



export default IGercCustoGerencialValidationSchema;
