import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';


import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';


import IButton from '../../../../../../../components/Buttons/IButton';
import { IconSearch } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TextInput from '../../../../../../../components/TextFields/Input';


import ApiWS from '../../../../../../../services/api.service';



import PcbManutencaoFilialModel from './PBC-manutencao-tab-filial.model';

import PcbManutencaoFilialValidationSchema from './PBC-manutencao-tab-filial.validation';



const Tab_FilialScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const[consultCheckd, setConsultCheckd] = useState(false);


  function postRequestFetch(values: any, setFieldValue: any) {
    const payload = {
      'FILIAL': values.input_filial,
      'NUM': values.input_pcbm,
    };

    setLoading(true);

    ApiWS()
      .post('/suprimento/manutencao/consulta/pedido', payload)
      .then((resp) => {
        if(resp.status === 200 && resp.data.length > 0){
          setConsultCheckd(true);
          setFieldValue('input_filial_antiga', resp.data[0].FILIALENT.trim());
        }
        else {
          setConsultCheckd(false);
          IToast({
            type: 'warning',
            message: 'Filial ou Pedido não encontrado!',
          });
        }
      })
      .catch((error) => {
        IToast({
          message: 'Ocorreu um erro ao consultar pedido! Contate o suporte.',
          type: 'error',
        });
        console.error(postRequestFetch.name, JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function postChangeBranch(values: any, resetForm: any) {
    const payloadChangeBranch = {
      'FILIAL': values.input_filial_antiga,
      'NFILIAL': values.input_filial_nova,
      'NUM': values.input_pcbm,
    };


    setLoading(true);
    ApiWS()
      .post('/suprimento/manutencao/altera/filial', payloadChangeBranch)
      .then(async (resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: 'Filial alterada com sucesso!',
          });
          resetForm();
          setConsultCheckd(false);
        }
      })
      .catch((error) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro ao alterar a filial! Contate o suporte.',
        });
        console.error(postChangeBranch.name, JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={PcbManutencaoFilialValidationSchema}
      initialValues={PcbManutencaoFilialModel}
      onSubmit={(values, { resetForm }) => {
        postChangeBranch(values, resetForm);
      }}>
      {({
        values,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
      }) => (

        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Alterar Filial de Entrega'.toUpperCase()}
            </TitleGroup>


            {loading &&
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            }



            <SectionMargin>
              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Row>

                    <Col>

                      <Row>

                        <Col>
                          <TextInput
                            disabled={loading || consultCheckd}
                            type={'text'}
                            name={'input_filial'}
                            label={'Filial'}
                            placeholder={'Filial PC/BM'}
                            value={values.input_filial || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading || consultCheckd}
                            type={'text'}
                            name={'input_pcbm'}
                            label={'PC/BM'}
                            placeholder={'Número do PC/BM'}
                            value={values.input_pcbm || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            buttonIcon={
                              <IconSearch
                                color={'black'}
                                size={20}
                              />
                            }
                            buttonClick={() => {
                              if(values.input_filial.length === 2
                                && values.input_pcbm.length === 6
                              ){
                                postRequestFetch(values, setFieldValue);
                              }
                              else {
                                IToast({
                                  type: 'warning',
                                  message: 'Filial e Pedido devem ser preenchidos corretamente!',
                                });
                              }
                            }}
                          />
                        </Col>

                      </Row>
                    </Col>


                    <Col>
                    </Col>
                  </Row>


                  <Row>

                    <Col>

                      <Row>

                        <Col>
                          <TextInput
                            disabled={true}
                            type={'text'}
                            name={'input_filial_antiga'}
                            label={'Filial de Entrega Antiga'}
                            placeholder={'Consulte o Pedido.'}
                            value={values.input_filial_antiga || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>

                      </Row>

                    </Col>


                    <Col>

                      <Row>

                        <Col>
                          <TextInput
                            disabled={loading || !consultCheckd}
                            type={'text'}
                            name={'input_filial_nova'}
                            label={'Filial de Entrega Nova'}
                            placeholder={'Consulte o PC/BM'}
                            value={values.input_filial_nova || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>

                      </Row>

                    </Col>

                  </Row>

                </Row>

                <Row>
                  <Col>

                  </Col>


                  <Col>

                  </Col>



                  <Col>

                  </Col>
                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loading}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();

                      setValues(PcbManutencaoFilialModel);

                      setConsultCheckd(false);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    text={'Alterar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>
            </SectionMargin>

          </SectionMargin>


        </ContainerView>

      )}
    </Formik>

  );
};



export default Tab_FilialScreen;
