import * as Yup from 'yup';
import { currentDateToInput } from '../../../../../../../utils/DateUtils';



const POCCartaoValidationSchema = Yup.object().shape({

  DATA: Yup.date()
    .required('Campo Obrigatório')
    .min(currentDateToInput(-2), 'Registro deve estar entre hoje e 2 dias anteriores')
    .max(currentDateToInput(), 'Registro deve estar entre hoje e 2 dias anteriores'),

  CC: Yup.string()
    .required('Campo Obrigatório'),
  IDAREA: Yup.string()
    .required('Campo Obrigatório'),
  TAREFA: Yup.string()
    .required('Campo Obrigatório'),
  OBSERVADOR: Yup.string()
    .required('Campo Obrigatório'),
  MAT: Yup.string()
    .required('Campo Obrigatório'),


  A1_ATENDE: Yup.string(),
  A1_NCOLABORADORES: Yup.number()
    .when('A1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A1_NMELHORIAS: Yup.number()
    .when('A1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('A1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  A2_ATENDE: Yup.string(),
  A2_NCOLABORADORES: Yup.number()
    .when('A2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A2_NMELHORIAS: Yup.number()
    .when('A2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('A2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  A3_ATENDE: Yup.string(),
  A3_NCOLABORADORES: Yup.number()
    .when('A3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  A3_NMELHORIAS: Yup.number()
    .when('A3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('A3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('A3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('A3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B1_ATENDE: Yup.string(),
  B1_NCOLABORADORES: Yup.number()
    .when('B1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B1_NMELHORIAS: Yup.number()
    .when('B1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B2_ATENDE: Yup.string(),
  B2_NCOLABORADORES: Yup.number()
    .when('B2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B2_NMELHORIAS: Yup.number()
    .when('B2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B3_ATENDE: Yup.string(),
  B3_NCOLABORADORES: Yup.number()
    .when('B3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B3_NMELHORIAS: Yup.number()
    .when('B3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B4_ATENDE: Yup.string(),
  B4_NCOLABORADORES: Yup.number()
    .when('B4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B4_NMELHORIAS: Yup.number()
    .when('B4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B5_ATENDE: Yup.string(),
  B5_NCOLABORADORES: Yup.number()
    .when('B5_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B5_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B5_NMELHORIAS: Yup.number()
    .when('B5_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B5_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B5_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B5_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B5_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B5_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B6_ATENDE: Yup.string(),
  B6_NCOLABORADORES: Yup.number()
    .when('B6_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B6_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B6_NMELHORIAS: Yup.number()
    .when('B6_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B6_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B6_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B6_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B6_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B6_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B7_ATENDE: Yup.string(),
  B7_NCOLABORADORES: Yup.number()
    .when('B7_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B7_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B7_NMELHORIAS: Yup.number()
    .when('B7_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B7_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B7_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B7_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B7_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B7_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B8_ATENDE: Yup.string(),
  B8_NCOLABORADORES: Yup.number()
    .when('B8_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B8_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B8_NMELHORIAS: Yup.number()
    .when('B8_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B8_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B8_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B8_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B8_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B8_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  B9_ATENDE: Yup.string(),
  B9_NCOLABORADORES: Yup.number()
    .when('B9_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B9_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  B9_NMELHORIAS: Yup.number()
    .when('B9_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B9_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('B9_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B9_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('B9_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('B9_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  C1_ATENDE: Yup.string(),
  C1_NCOLABORADORES: Yup.number()
    .when('C1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C1_NMELHORIAS: Yup.number()
    .when('C1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('C1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  C2_ATENDE: Yup.string(),
  C2_NCOLABORADORES: Yup.number()
    .when('C2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C2_NMELHORIAS: Yup.number()
    .when('C2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('C2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  C3_ATENDE: Yup.string(),
  C3_NCOLABORADORES: Yup.number()
    .when('C3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C3_NMELHORIAS: Yup.number()
    .when('C3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('C3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  C4_ATENDE: Yup.string(),
  C4_NCOLABORADORES: Yup.number()
    .when('C4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  C4_NMELHORIAS: Yup.number()
    .when('C4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('C4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('C4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('C4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  D1_ATENDE: Yup.string(),
  D1_NCOLABORADORES: Yup.number()
    .when('D1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  D1_NMELHORIAS: Yup.number()
    .when('D1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('D1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  D2_ATENDE: Yup.string(),
  D2_NCOLABORADORES: Yup.number()
    .when('D2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  D2_NMELHORIAS: Yup.number()
    .when('D2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('D2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  D3_ATENDE: Yup.string(),
  D3_NCOLABORADORES: Yup.number()
    .when('D3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  D3_NMELHORIAS: Yup.number()
    .when('D3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('D3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('D3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('D3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  E1_ATENDE: Yup.string(),
  E1_NCOLABORADORES: Yup.number()
    .when('E1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E1_NMELHORIAS: Yup.number()
    .when('E1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E1_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('E1_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E1_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  E2_ATENDE: Yup.string(),
  E2_NCOLABORADORES: Yup.number()
    .when('E2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E2_NMELHORIAS: Yup.number()
    .when('E2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E2_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('E2_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E2_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  E3_ATENDE: Yup.string(),
  E3_NCOLABORADORES: Yup.number()
    .when('E3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E3_NMELHORIAS: Yup.number()
    .when('E3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E3_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('E3_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E3_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),


  E4_ATENDE: Yup.string(),
  E4_NCOLABORADORES: Yup.number()
    .when('E4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    }),
  E4_NMELHORIAS: Yup.number()
    .when('E4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .required('Campo obrigatório.'),
    })
    .when('E4_ATENDE', {
      is: 'S',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    })
    .when('E4_ATENDE', {
      is: 'N',
      then: Yup.number()
        .min(0, 'Deve ser 0 ou superior.')
        .max(Yup.ref('E4_NCOLABORADORES'), 'Não pode ser maior que Nº de Colab. Observ.')
        .required('Campo obrigatório.'),
    }),



  CASE_SUCESSO_1: Yup.string(),
  CASE_SUCESSO_2: Yup.string(),
  CASE_SUCESSO_3: Yup.string(),
  CASE_SUCESSO_4: Yup.string(),
  CASE_SUCESSO_5: Yup.string(),


  DESVIO: Yup.string(),
  RECONHECIMENTO: Yup.string(),
  OBSERVACOES: Yup.string(),

});



export default POCCartaoValidationSchema;
