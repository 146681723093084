const CHRConsultaModel = {
  SITUAC: 'TODOS',  // *
  FUNCAO: '', // 
  OS: 'TODOS', // *
  MATRICULA: '', // 
  NOME: '', // 
  MAO: 'TODOS', // *
  CLASSIFICACAO: 'TODOS', // * 
  DTINICIO: '', // 
  DTFIM: '', // 
  MOTIVO: 'TODOS', // 
  TIPO: 'H', // *
};



export default CHRConsultaModel;
