import styled from 'styled-components';

import {
  Form,
} from 'formik';



export interface IProps {
  heightAuto?: boolean;
}



export const CardImportContainer = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;  
  ${(props: IProps) => !props.heightAuto && 'height: 180px'};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
`;

export const CardImportHeader = styled.div`
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
`;

export const CardImportTitle = styled.h3`
  margin-bottom: 3px;
  color: rgb(33, 37, 41);
  font-size: 1.18rem;
`;

export const CardImportBody = styled.h3`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
`;

export const DropdownView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
`;

export const ButtonSyncView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  margin-top: 5px;
`;

