import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../common/constants';



export const NavbarUserContainer = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${Colors?.primary};
`;

export const NavbarUser = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
`;


export const ItemStyle = css`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const NavbarItemUserLeft = styled.div`
  ${ItemStyle};
  justify-content: flex-start;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const BackIconButton = styled.div`
  margin-right: 8px;
`;

export const HomeOrBackText = styled.p`
  margin: 0 0 2px 0;
  color: white;
  font-size: 1rem;
  font-weight: bold;
`;


export const NavbarItemUserCenter = styled.div`
  ${ItemStyle};
  justify-content: center;
`;


export const NavbarItemUserRight = styled.div`
  ${ItemStyle};
  justify-content: flex-end;
`;


export const DateText = styled.p`
  margin: 0 0 2px 0;
  color: white;
  font-size: 1.22rem;
  font-weight: 400;
`;


export const UserText = styled.p`
  margin: 0 0 2px 0;
  color: white;
  font-size: 1.063rem;
  font-weight: bold;
`;

export const LogoutButton = styled.a`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  cursor: pointer;
`;

export const HelpButton = styled.a`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  cursor: pointer;
`;

export const LogoutIcon = styled.div`
  width: 30px;
  height: 30px;
`;
