import React from 'react';
import { BoxImg, Image } from './styled';


interface IProps {
  src?: any;
  alt?: any;
  size?: string;
  disabled?: boolean;
}

const IPhoto: React.FC<IProps> = (props) => {
  return (

    <BoxImg size={props.size}>
      
      {props.src && (
        
        <Image
          disabled={props.disabled}
          alt={props.alt}
          src={props.src}
          size={props.size}
        />

      )}



      {!props.src && (
        <p> {props.alt[0].toUpperCase()} </p>
      )}

    </BoxImg>

  );
};


export default IPhoto;
