import React,
{
  useState,
} from 'react';

import {
  Images,
} from '../../../../../common/constants';

import {
  Content,
  Screen,
} from '../../../../../common/styles/styled.layout';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  PSTOCK_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

// import { Container } from './styles';



const PSTOCK_HomeScreen: React.FC = () => {
  const [emptyObject] = useState({
    image: Images.empty,
    title: 'Sem itens!',
    description: 'Não há nada aqui',
  });



  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={PSTOCK_NavRoutes}
          title={'PStock'}
        />
      </NavHeader>



      <Content>
        <EmptyContent
          image={emptyObject.image}
          title={emptyObject.title}
          description={emptyObject.description}
        />
      </Content>

    </Screen>

  );
};



export default PSTOCK_HomeScreen;
