const GIUsuarioModel = {
  ID: '',
  MATRICULA: '',
  NOME: '',
  LOGIN: '',
  EMAIL: '',
  TIPO: '',
  STATUS: '',
  CHECKEMAIL: '',
  FOTO: '',
};



export default GIUsuarioModel;
