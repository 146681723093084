const NovoFornecedoresModel = {
  razao_social: '',
  nome: '',
  endereco: '',
  cep: '',
  cidade: '',
  uf: '',
  cnpj: '',
  contato: '',
  telefone: '',
  email: '',
  bco: '',
  agencia: '',
  conta: '',
  tipopix: '',
  chavepix: '',

};



export default NovoFornecedoresModel;
