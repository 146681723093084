import React, {
  useState,
} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import { Colors } from '../../common/constants';
import { TextTooltip } from '../../common/constants/TextTooltips';
import ButtonTablePagination from '../Buttons/ButtonTablePagination';
import { IconNext, IconReturn } from '../Icons';

import {
  TableControlContainer, TableFooterControlContainer, TableNextStyled, TableTotalSpan,
} from './styled';



interface IProps {
  data: any;
  columns: any;

  noQuantity?: boolean;

  baseProps?: any;
  paginationProps: any;
  paginationTableProps: any;

  actionPrevious?: any;
  actionNext?: any;
  currentPage?: number;
  totalPage?: number;
  totalResults?: number;
}



const TableCuston: React.FC<IProps> = (props) => {
  return (

    <TableNextStyled>

      {!props.noQuantity && (
        <TableControlContainer>

          <TableFooterControlContainer>

            <TableTotalSpan className="react-bootstrap-table-pagination-total">
              Mostrando página {props.currentPage} de {props.totalPage} páginas. Total de resultados: {props.totalResults}
            </TableTotalSpan>

          </TableFooterControlContainer>

          {props.currentPage === 1 && (
            <ButtonTablePagination
              tooltip={TextTooltip.LIMIT}
              icon={
                <IconReturn
                  color={Colors?.gray}
                  size={36}
                />
              }
            />
          )}


          {props.currentPage > 1 && (
            <ButtonTablePagination
              tooltip={TextTooltip.PREVIOUS}
              icon={
                <IconReturn
                  color={Colors?.primary}
                  size={36}
                />
              }
              onClick={props.actionPrevious}
            />
          )}


          {props.currentPage !== props.totalPage && (
            <ButtonTablePagination
              tooltip={TextTooltip.NEXT}
              icon={
                <IconNext
                  color={Colors?.primary}
                  size={36}
                />
              }
              onClick={props.actionNext}
            />
          )}


          {props.currentPage === props.totalPage && (
            <ButtonTablePagination
              tooltip={TextTooltip.LIMIT}
              icon={
                <IconNext
                  color={Colors?.gray}
                  size={36}
                />
              }
            />
          )}

        </TableControlContainer>
      )}



      <BootstrapTable
        bootstrap4
        hover
        bordered={false}
        keyField={'id'}
        data={props.data}
        columns={props.columns}
        {...props.baseProps}
        {...props.paginationTableProps}
      />



      <TableControlContainer>
        {/* <PaginationListStandalone
          {...props.paginationProps}
        /> */}
      </TableControlContainer>



    </TableNextStyled>

  );


  // return (

  //   <TableNextStyled>

  //     {!props.noQuantity && (
  //       <TableControlContainer>

  //         {props.paginationProps.sizePerPageList.map((quantity) => {
  //           const isSelect = quantity === selected;

  //           return (
  //             <TableButtonControl
  //               key={quantity}
  //               type={'button'}
  //               selected={isSelect}
  //               onClick={() => {
  //                 setSelected(quantity);
  //                 handleSizePerPage(props.paginationProps, quantity);
  //               }}>

  //               <TableSpanControl>
  //                 {quantity}
  //               </TableSpanControl>

  //             </TableButtonControl>
  //           );
  //         })}


  //         <TableFooterControlContainer>
  //           <PaginationTotalStandalone
  //             {...options}
  //           />
  //         </TableFooterControlContainer>


  //         <PaginationListStandalone
  //           {...props.paginationProps}
  //         />

  //       </TableControlContainer>
  //     )
  //     }



  //     <BootstrapTable
  //       bootstrap4
  //       hover
  //       bordered={false}
  //       keyField={'id'}
  //       data={props.data}
  //       columns={props.columns}
  //       {...props.baseProps}
  //       {...props.paginationTableProps}
  //     />



  //     <TableControlContainer>
  //       <PaginationListStandalone
  //         {...props.paginationProps}
  //       />
  //     </TableControlContainer>

  //   </TableNextStyled >

  // );
};



TableCuston.defaultProps = {
  noQuantity: false,
};



export default TableCuston;
