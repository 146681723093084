import React,
{
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  TablePointerButton,
  TableToltip,
  TitleGroup,
} from '../../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../../components/Buttons/IButton';
import NavHeader from '../../../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../../../components/Headers/NavUser';

import {
  IconSequenceNumber,
} from '../../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../../components/Loadings';

import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import { FormCheckStyled } from '../../TabConfiguracoes/styled';

// import { Container } from './styles';



const NovoProdutoScreen: React.FC = () => {
  const [carregando] = useState(false);



  return (

    <Screen>

      <NavHeader>
        <NavUser back />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && (
            <ContainerView>

              <SectionMargin>
                <TitleGroup>
                  {false
                    ? 'Editar Produto'.toUpperCase()
                    : 'Novo Produto'.toUpperCase()
                  }
                </TitleGroup>


                <SectionMargin>
                  <Formik
                    enableReinitialize
                    validateOnMount
                    // validationSchema={IGercCustoGerencialValidationSchema}
                    initialValues={[{}]}
                    onSubmit={() => {
                      // handleCustoGerecialFunction(values);
                    }}>
                    {({
                      resetForm,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>
                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Nome'}
                              placeholder={'Nome'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Descrição'}
                              placeholder={'Descrição'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>



                        <Row>
                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Cód. Comercial'}
                              placeholder={'Cód. Comercial'}
                              buttonIcon={
                                <OverlayTrigger
                                  key={'left'}
                                  placement={'top'}
                                  trigger={['hover', 'hover']}
                                  overlay={
                                    <TableToltip>
                                      Gerar Cód. Comercial Automático
                                    </TableToltip>
                                  }>
                                  <TablePointerButton
                                    onClick={() => {
                                      // setEmployeeDetails(row);
                                      // setShowModalDetails(true);
                                    }}>
                                    <IconSequenceNumber
                                      color={'black'}
                                      size={20}
                                    />
                                  </TablePointerButton>
                                </OverlayTrigger>
                              }
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Cód. Protheus'}
                              placeholder={'Cód. Protheus'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Cód. Patrimonial'}
                              placeholder={'Cód. Patrimonial'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>



                        <Row>
                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Ncm'}
                              placeholder={'Ncm'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Grupo'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Categoria'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'SubCategoria'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>



                        <Row>
                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Modelo'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Marca'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Cor'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>



                        <Row>
                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Tamanho'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              // disabled={carregando || listaCarregando}
                              name={'select_situacao'}
                              label={'Un. Medida'}
                              dropArray={[
                                {
                                  key: '1',
                                  label: 'Ativa',
                                  value: 'Ativa',
                                },
                                {
                                  key: '2',
                                  label: 'Encerrada',
                                  value: 'Encerrada',
                                },
                              ]}
                              // value={values.select_situacao || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>



                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Lote'}
                              placeholder={'Lote'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>



                        <Row>
                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Estoque'}
                              placeholder={'Estoque'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              // disabled={loadingCGConfirmModal}
                              name={'input_data_inicial'}
                              label={'Preço'}
                              placeholder={'Preço'}
                              // value={values.input_data_inicial || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>



                          <Col>
                            <FormCheckStyled
                              type={'switch'}
                              id={'switch-tabela-produtos'}
                              name={'switch_tabela_produtos'}
                              label={'Bloqueado'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>



                        {/* TODO: Colocar Imagem */}



                        <RowButtonFiltersView>
                          <IButton
                            // disabled={loadingEmployeeList}
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();

                              // setValues(PStockFuncionarioModel);
                            }}
                          />


                          <IButton
                            // disabled={loadingEmployeeList}
                            text={'Salvar'}
                            backgroundColor={Colors?.primary}
                            type={'submit'}
                          />
                        </RowButtonFiltersView>


                        <RowButtonFiltersView>
                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}
                  </Formik>
                </SectionMargin>

              </SectionMargin>

            </ContainerView>
          )}

        </ContentStyled>

      </Content>

    </Screen>

  );
};



export default NovoProdutoScreen;
